import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { restApiPath } from 'utils/app.props';
import { getStateDataByTblName } from 'layouts/linelist/getChangesComman';
import { setStateDataByTblName } from 'layouts/linelist/getChangesComman';
import { getUnsentCount } from 'layouts/linelist/getChangesComman';
import { setUnsentCount } from 'layouts/linelist/getChangesComman';

const CustomAutocompleteEditor = (props) => {
    const { value, data, api, table_name } = props;
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(value || '');
    const [isLoading, setIsLoading] = useState(true);
    const stateData = getStateDataByTblName(table_name);
    let unsentCount = getUnsentCount(table_name);

    useEffect(() => {
        const fetchOptions = async () => {
            const cacheKey = `${data["Department"]}-${data["Category"]}-${data["Subcategory"]}-${data["Style Description"]}`;

            setIsLoading(true);
            // Check if the data is already cached
            if (localStorage.getItem(cacheKey)) {
                const data = JSON.parse(localStorage.getItem(cacheKey));
                const columnName = props.colDef?.headerName;

                if (data && data[columnName]) {
                    const fetchedOptions = data[columnName];
                    if (fetchedOptions) setOptions(fetchedOptions);
                }
                setIsLoading(false);
                return;
            }



            const url1 = `${restApiPath}callPGFunction`;
            const jsonReq = {
                "param_body": {
                    "user_group_val": sessionStorage.getItem("user_type"),
                    "data_table_name": "xl_faherty_linelist",
                    "Department": data["Department"],
                    "Category": data["Category"],
                    "Subcategory": data["Subcategory"],
                    "Style Description": data["Style Description"],
                },
                param_function: "xl_fb_get_suggestion_data_value"
            };

            const requestbody = {
                method: "POST",
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonReq),
            };
            const response = await fetch(url1, requestbody);
            const result = await response.json();

            const jsonString = result.data || "";
            if (jsonString) {
                const data = JSON.parse(jsonString);
                if (data && Object.keys(data).length > 0) {
                    const columnName = props.colDef?.headerName;
                    if (data[columnName]) {
                        const fetchedOptions = data[columnName];

                        // Cache the result
                        localStorage.setItem(cacheKey, JSON.stringify(data));
                        setOptions(fetchedOptions);
                    }
                }
            }
            setIsLoading(false);
        };

        fetchOptions();
    }, [data["Department"], data["Category"], data["Subcategory"], data["Style Description"]]);  // Only re-fetch when category/subcategory change

    const handleChange = (event, newValue) => {
        setInputValue(newValue);
        if (stateData) {
            const find = stateData.find((dt) => dt.row_id === data.row_id);
            const columnName = props.colDef?.headerName;
            if (find && data[columnName] !== newValue) {
                find[columnName] = newValue;

                unsentCount++;
                setStateDataByTblName(table_name, stateData);
                setUnsentCount(table_name, unsentCount);
            }
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue && !options.includes(newInputValue)) {
            handleChange(event, newInputValue);
        }
    };

    return (
        <>
            {
                isLoading ? (
                    <CircularProgress size="1rem" color="info" />
                ) : (
                    <Autocomplete
                        value={inputValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        freeSolo
                        options={options.map((option) => option)}
                        style={{ padding: '0px', fontSize: '12px', fontFamily: "Inter" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                sx={{ '& .MuiInputBase-input': { fontSize: '12px', fontFamily: "Inter" } }}
                                style={{ padding: '0px', paddingBottom: "7px", textAlign: "top" }}
                            />
                        )}
                    />)
            }
        </>
    );
}

export default CustomAutocompleteEditor;
