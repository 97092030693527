import { restApiPath } from "utils/app.props";
import { CH1, CH2, CH3, ExcelDateToJsDate, checkHeihlight, doubleQuote, formatDateToFullString, formatDateToMmDdYyyyHhMmSsAmPm, getColumnDefByTblName, getColumnDefination, getHighlightByTblName } from "./XL_Utils";
import moment from "moment";
import { store } from "store";
import { setDropDown } from 'layouts/linelist/lovFunctions';

const ignore_columns_in_sync_compare = ['user_inserted', 'inserted_on', 'user_modified', 'modified_on']
let highlightCells = []
let mpAllTabsChangedRowIdsColNames = {}
let data = []
let metaData = []

export function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
export function GetSortOrderRowId(prop) {
    return function (a, b) {
        if (parseInt(a[prop]) > parseInt(b[prop])) {
            return 1;
        } else if (parseInt(a[prop]) < parseInt(b[prop])) {
            return -1;
        }
        return 0;
    }
}
export function setStateDataByTblName(tbl_name, myData, sort = true) {
    if (tbl_name === "xl_faherty_linelist") {
        let sortdata = JSON.parse(JSON.stringify(myData));
        if (sort) {
            sortdata = sortdata.sort(GetSortOrder("Style Activity"));
        }
        store.dispatch({ type: "SET_LINELIST_DATA", data: sortdata });
    } else if (tbl_name === "xl_faherty_season") {
        store.dispatch({ type: "SET_SEASON_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_chase") {
        store.dispatch({ type: "SET_CHASE_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_dtc_buy_sheet") {
        store.dispatch({ type: "SET_DTC_BY_SHT_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_whsl_buy_sheet") {
        store.dispatch({ type: "SET_WHSL_BY_SHT_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_whsl_buy_sheet_nord_rp") {
        store.dispatch({ type: "SET_WHSL_BY_SHT_NRD_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_sizing") {
        store.dispatch({ type: "SET_SIZING_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_buy_sheet_master_sizing_percentage") {
        store.dispatch({ type: "BUY_SHT_SIZE_PER_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_buy_sheet_master_data_charts") {
        store.dispatch({ type: "BUY_SHT_SIZE_CHART_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_comp_aps_master_stores_ecomm") {
        store.dispatch({ type: "COMP_APS_MASTER_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_freight_kg_rates") {
        store.dispatch({ type: "FRIGHT_MASTER_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_freight_by_category") {
        store.dispatch({ type: "FRIGHT_BY_CAT_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_delivery_master") {
        store.dispatch({ type: "DELIVERY_MASTER_DATA", data: myData });
    } else if (tbl_name === "xl_faherty_fb_fty_database") {
        store.dispatch({ type: "FB_FTY_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_size_scales") {
        store.dispatch({ type: "SIZE_SCALE_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_shopify_product_type_mapping") {
        store.dispatch({ type: "SHOPIFY_TYPE_MAP_DATA", data: myData });
    } else if (tbl_name === "xl_faherty_season_inquiry") {
        store.dispatch({ type: "SEASON_INQUIRY_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_master_lov") {
        let sortdata = JSON.parse(JSON.stringify(myData));
        if (sort) {
            sortdata = sortdata.sort(GetSortOrderRowId("row_id"));
        }
        store.dispatch({ type: "MASTER_LOV_DATA", data: sortdata });
    } else if (tbl_name === "xl_action_item_details") {
        store.dispatch({ type: "ACTION_ITEM_DATA", data: JSON.parse(JSON.stringify(myData)) })
    } else if (tbl_name === "xl_pd_initial_costing") {
        store.dispatch({ type: "SET_PDInitialCosting_DATA", data: JSON.parse(JSON.stringify(myData)) })
    } else if (tbl_name === "xl_faherty_merch_assortment_sandbox") {
        store.dispatch({ type: "Set_Merch_Assortment_Sandbox_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_data_transfer_rules") store.dispatch({ type: "dataTransferRules", data: JSON.parse(JSON.stringify(myData)) });
    else if (tbl_name === "app_user") {
        store.dispatch({ type: "SET_ManageUser_DATA", data: myData });
    } else if (tbl_name === "xl_user_data_access") {
        store.dispatch({ type: "SET_ManagePermission_DATA", data: myData });
    }else if (tbl_name === "xl_faherty_airtable_mapping_data") {
        store.dispatch({ type: "AIR_TABLE_DATA", data: myData });
    }
}
export function setSessionDataByTblName(tbl_name, myData) {
    if (tbl_name === "xl_faherty_linelist") {
        let sortdata = JSON.parse(JSON.stringify(myData));
        sortdata = sortdata.sort(GetSortOrder("Style Activity"));
        store.dispatch({ type: "SET_LINELIST_SSN_DATA", data: sortdata });
    } else if (tbl_name === "xl_faherty_season") {
        store.dispatch({ type: "SET_SEASON_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_chase") {
        store.dispatch({ type: "SET_CHASE_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_dtc_buy_sheet") {
        store.dispatch({ type: "SET_DTC_BY_SHT_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_whsl_buy_sheet") {
        store.dispatch({ type: "SET_WHSL_BY_SHT_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_whsl_buy_sheet_nord_rp") {
        store.dispatch({ type: "SET_WHSL_BY_SHT_NRD_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_sizing") {
        store.dispatch({ type: "SET_SIZING_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_buy_sheet_master_sizing_percentage") {
        store.dispatch({ type: "BUY_SHT_SIZE_PER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_buy_sheet_master_data_charts") {
        store.dispatch({ type: "BUY_SHT_SIZE_CHART_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_comp_aps_master_stores_ecomm") {
        store.dispatch({ type: "COMP_APS_MASTER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_freight_kg_rates") {
        store.dispatch({ type: "FRIGHT_MASTER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_freight_by_category") {
        store.dispatch({ type: "FRIGHT_BY_CAT_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_delivery_master") {
        store.dispatch({ type: "DELIVERY_MASTER_SSN_DATA", data: myData });
    } else if (tbl_name === "xl_faherty_fb_fty_database") {
        store.dispatch({ type: "FB_FTY_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_size_scales") {
        store.dispatch({ type: "SIZE_SCALE_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_shopify_product_type_mapping") {
        store.dispatch({ type: "SHOPIFY_TYPE_MAP_SSN_DATA", data: myData });
    } else if (tbl_name === "xl_faherty_season_inquiry") {
        store.dispatch({ type: "SEASON_INQUIRY_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_master_lov") {
        store.dispatch({ type: "MASTER_LOV_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_action_item_details") {
        store.dispatch({ type: "ACTION_ITEM_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) })
    } else if (tbl_name === "xl_pd_initial_costing") {
        store.dispatch({ type: "SET_PDInitialCosting_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) })
    } else if (tbl_name === "xl_faherty_merch_assortment_sandbox") {
        store.dispatch({ type: "Set_Merch_Assortment_Sandbox_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "Summary_Report") {
        store.dispatch({ type: "SUMMARY_REPORT_AGG_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
    }else if (tbl_name === "app_user") {
        store.dispatch({ type: "SET_ManageUser_SSN_DATA", data: myData });
    } else if (tbl_name === "xl_user_data_access") {
        store.dispatch({ type: "SET_ManagePermission_SSN_DATA", data: myData });
    }else if (tbl_name === "xl_faherty_airtable_mapping_data") {
        store.dispatch({ type: "AIR_TABLE_SSN_DATA", data: myData });
    }
}
function setStateByTblName(tbl_name, myData, highlightCells, count) {
    // console.log(highlightCells)
    if (tbl_name === "xl_faherty_season") {
        store.dispatch({ type: "SET_SEASON_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SEASON_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SEASON_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_linelist") {
        let sortdata = JSON.parse(JSON.stringify(myData));
        sortdata = sortdata.sort(GetSortOrder("Style Activity"));
        store.dispatch({ type: "SET_LINELIST_SSN_DATA", data: sortdata });
        store.dispatch({ type: "SET_LINELIST_DATA", data: sortdata });
        store.dispatch({ type: "SET_LINELIST_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_chase") {
        store.dispatch({ type: "SET_CHASE_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_CHASE_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_CHASE_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_dtc_buy_sheet") {
        store.dispatch({ type: "SET_DTC_BY_SHT_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_DTC_BY_SHT_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_DTC_BUY_SHT_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_whsl_buy_sheet") {
        store.dispatch({ type: "SET_WHSL_BY_SHT_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_WHSL_BY_SHT_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_WHSL_BUY_SHT_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_whsl_buy_sheet_nord_rp") {
        store.dispatch({ type: "SET_WHSL_BY_SHT_NRD_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_WHSL_BY_SHT_NRD_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_WHSL_BUY_SHT_NRD_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_sizing") {
        store.dispatch({ type: "SET_SIZING_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SIZING_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SIZING_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_buy_sheet_master_sizing_percentage") {
        store.dispatch({ type: "BUY_SHT_SIZE_PER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "BUY_SHT_SIZE_PER_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_BUYSHT_MASTER_PER_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_buy_sheet_master_data_charts") {
        store.dispatch({ type: "BUY_SHT_SIZE_CHART_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "BUY_SHT_SIZE_CHART_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_BUYSHT_MASTER_CHART_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_comp_aps_master_stores_ecomm") {
        store.dispatch({ type: "COMP_APS_MASTER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "COMP_APS_MASTER_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_COMP_MASTER_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_freight_kg_rates") {
        store.dispatch({ type: "FRIGHT_MASTER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "FRIGHT_MASTER_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_FRIEGHT_MASTER_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_freight_by_category") {
        store.dispatch({ type: "FRIGHT_BY_CAT_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "FRIGHT_BY_CAT_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_FRIEGHT_BY_CAT_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_delivery_master") {
        store.dispatch({ type: "DELIVERY_MASTER_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "DELIVERY_MASTER_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_DELIVERY_MASTER_HIGHLIGHT_CELL", data: highlightCells });
        store.dispatch({ type: "SET_DELIVERY_MASTER_UNSENT_COUNT", count: count });
    } else if (tbl_name === "xl_faherty_fb_fty_database") {
        store.dispatch({ type: "FB_FTY_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "FB_FTY_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_FBFTY_DATABASE_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_size_scales") {
        store.dispatch({ type: "SIZE_SCALE_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SIZE_SCALE_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SIZE_SCALE_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_shopify_product_type_mapping") {
        store.dispatch({ type: "SHOPIFY_TYPE_MAP_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SHOPIFY_TYPE_MAP_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SHOPIFY_PRODUCT_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_season_inquiry") {
        store.dispatch({ type: "SEASON_INQUIRY_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SEASON_INQUIRY_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_SEASON_INQUIRY_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_faherty_master_lov") {
        store.dispatch({ type: "MASTER_LOV_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "MASTER_LOV_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_MASTER_LOV_HIGHLIGHT_CELL", data: highlightCells });
    } else if (tbl_name === "xl_action_item_details") {
        store.dispatch({ type: "ACTION_ITEM_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "ACTION_ITEM_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_pd_initial_costing") {
        store.dispatch({ type: "SET_PDInitialCosting_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "SET_PDInitialCosting_DATA", data: JSON.parse(JSON.stringify(myData)) });
    } else if (tbl_name === "xl_faherty_merch_assortment_sandbox") {
        store.dispatch({ type: "Set_Merch_Assortment_Sandbox_SSN_DATA", data: JSON.parse(JSON.stringify(myData)) });
        store.dispatch({ type: "Set_Merch_Assortment_Sandbox_DATA", data: JSON.parse(JSON.stringify(myData)) });
    }
    const reduxState = store.getState();
    setDropDown(tbl_name, reduxState.mpAllTblsColsDataSource, JSON.parse(JSON.stringify(myData)), reduxState.DropDownMapping);
}
export const setHighlightByTblName = (reqTbl, highlightCells, from = "") => {
    if (from === "audit trail") store.dispatch({ type: "SET_AUDIT_TRAIL_ACTION", isAuditTrailAction: true });
    else store.dispatch({ type: "SET_AUDIT_TRAIL_ACTION", isAuditTrailAction: false });
    let tblType = ""
    if (reqTbl === "xl_faherty_season") tblType = "SET_SEASON_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_linelist") tblType = "SET_LINELIST_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_chase") tblType = "SET_CHASE_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_dtc_buy_sheet") tblType = "SET_DTC_BUY_SHT_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_whsl_buy_sheet") tblType = "SET_WHSL_BUY_SHT_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_whsl_buy_sheet_nord_rp") tblType = "SET_WHSL_BUY_SHT_NRD_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_sizing") tblType = "SET_SIZING_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_buy_sheet_master_sizing_percentage") tblType = "SET_BUYSHT_MASTER_PER_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_buy_sheet_master_data_charts") tblType = "SET_BUYSHT_MASTER_CHART_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_comp_aps_master_stores_ecomm") tblType = "SET_COMP_MASTER_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_freight_kg_rates") tblType = "SET_FRIEGHT_MASTER_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_freight_by_category") tblType = "SET_FRIEGHT_BY_CAT_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_delivery_master") tblType = "SET_DELIVERY_MASTER_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_fb_fty_database") tblType = "SET_FBFTY_DATABASE_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_size_scales") tblType = "SET_SIZE_SCALE_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_shopify_product_type_mapping") tblType = "SET_SHOPIFY_PRODUCT_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_season_inquiry") tblType = "SET_SEASON_INQUIRY_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_master_lov") tblType = "SET_MASTER_LOV_HIGHLIGHT_CELL";
    else if (reqTbl === "app_user") tblType = "SET_ManageUser_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_user_data_access") tblType = "SET_ManagePermission_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_merch_assortment_sandbox") tblType = "Set_Merch_Assortment_Sandbox_HIGHLIGHT_CELL";
    else if (reqTbl === "xl_faherty_airtable_mapping_data") tblType = "SET_AIR_TABLE_HIGHLIGHT_CELL";
    store.dispatch({ type: tblType, data: highlightCells });
}
export const setSortByTblName = (reqTbl, columnState) => {
    let tblType = ""
    if (reqTbl === "xl_faherty_season") tblType = "SET_SEASON_SORTING";
    else if (reqTbl === "xl_faherty_linelist") tblType = "SET_LINELIST_SORTING";
    else if (reqTbl === "xl_faherty_chase") tblType = "SET_CHASE_SORTING";
    else if (reqTbl === "xl_faherty_dtc_buy_sheet") tblType = "SET_DTC_BY_SHT_SORTING";
    else if (reqTbl === "xl_faherty_whsl_buy_sheet") tblType = "SET_WHSL_BY_SHT_SORTING";
    else if (reqTbl === "xl_faherty_whsl_buy_sheet_nord_rp") tblType = "SET_WHSL_BY_SHT_NRD_SORTING";
    else if (reqTbl === "xl_faherty_sizing") tblType = "SET_SIZING_SORTING";
    else if (reqTbl === "xl_faherty_buy_sheet_master_sizing_percentage") tblType = "SET_BUY_SHT_SIZE_PER_SORTING";
    else if (reqTbl === "xl_faherty_buy_sheet_master_data_charts") tblType = "SET_BUY_SHT_SIZE_CHART_SORTING";
    else if (reqTbl === "xl_faherty_comp_aps_master_stores_ecomm") tblType = "SET_COMP_APS_MASTER_SORTING";
    else if (reqTbl === "xl_faherty_freight_kg_rates") tblType = "SET_FRIGHT_MASTER_SORTING";
    else if (reqTbl === "xl_faherty_freight_by_category") tblType = "SET_FRIGHT_BY_CAT_SORTING";
    else if (reqTbl === "xl_faherty_delivery_master") tblType = "SET_DELIVERY_MASTER_SORTING";
    else if (reqTbl === "xl_faherty_fb_fty_database") tblType = "SET_FB_FTY_SORTING";
    else if (reqTbl === "xl_faherty_size_scales") tblType = "SET_SIZE_SCALE_SORTING";
    else if (reqTbl === "xl_faherty_shopify_product_type_mapping") tblType = "SET_SHOPIFY_TYPE_MAP_SORTING";
    else if (reqTbl === "xl_faherty_season_inquiry") tblType = "SET_SEASON_INQUIRY_SORTING";
    else if (reqTbl === "xl_faherty_master_lov") tblType = "SET_MASTER_LOV_SORTING";
    else if (reqTbl === "app_user") tblType = "SET_ManageUser_SHORTING";
    else if (reqTbl === "xl_user_data_access") tblType = "SET_ManagePermission_SHORTING";
    else if (reqTbl === "xl_pd_initial_costing") tblType = "SET_PDInitialCosting_SORTING";
    else if (reqTbl === "xl_faherty_merch_assortment_sandbox") tblType = "SET_Merch_Assortment_Sandbox_SORTING";
    else if (reqTbl === "xl_faherty_airtable_mapping_data") tblType = "SET_AIR_TABLE_SORTING";

    store.dispatch({ type: tblType, data: columnState });
}
export const setFilterByTblName = (reqTbl, filterModel) => {
    let tblType = ""
    if (reqTbl === "xl_faherty_season") tblType = "SET_SEASON_FILTERS";
    else if (reqTbl === "xl_faherty_linelist") tblType = "SET_LINELIST_FILTERS";
    else if (reqTbl === "xl_faherty_chase") tblType = "SET_CHASE_FILTERS";
    else if (reqTbl === "xl_faherty_dtc_buy_sheet") tblType = "SET_DTC_BY_SHT_FILTERS";
    else if (reqTbl === "xl_faherty_whsl_buy_sheet") tblType = "SET_WHSL_BY_SHT_FILTERS";
    else if (reqTbl === "xl_faherty_whsl_buy_sheet_nord_rp") tblType = "SET_WHSL_BY_SHT_NRD_FILTERS";
    else if (reqTbl === "xl_faherty_sizing") tblType = "SET_SIZING_FILTERS";
    else if (reqTbl === "xl_faherty_buy_sheet_master_sizing_percentage") tblType = "SET_BUY_SHT_SIZE_PER_FILTERS";
    else if (reqTbl === "xl_faherty_buy_sheet_master_data_charts") tblType = "SET_BUY_SHT_SIZE_CHART_FILTERS";
    else if (reqTbl === "xl_faherty_comp_aps_master_stores_ecomm") tblType = "SET_COMP_APS_MASTER_FILTERS";
    else if (reqTbl === "xl_faherty_freight_kg_rates") tblType = "SET_FRIGHT_MASTER_FILTERS";
    else if (reqTbl === "xl_faherty_freight_by_category") tblType = "SET_FRIGHT_BY_CAT_FILTERS";
    else if (reqTbl === "xl_faherty_delivery_master") tblType = "SET_DELIVERY_MASTER_FILTERS";
    else if (reqTbl === "xl_faherty_fb_fty_database") tblType = "SET_FB_FTY_FILTERS";
    else if (reqTbl === "xl_faherty_size_scales") tblType = "SET_SIZE_SCALE_FILTERS";
    else if (reqTbl === "xl_faherty_shopify_product_type_mapping") tblType = "SET_SHOPIFY_TYPE_MAP_FILTERS";
    else if (reqTbl === "xl_faherty_season_inquiry") tblType = "SET_SEASON_INQUIRY_FILTERS";
    else if (reqTbl === "xl_faherty_master_lov") tblType = "SET_MASTER_LOV_FILTERS";
    else if (reqTbl === "app_user") tblType = "SET_ManageUser_FILTERS";
    else if (reqTbl === "xl_user_data_access") tblType = "SET_ManagePermission_FILTERS";
    else if (reqTbl === "xl_pd_initial_costing") tblType = "SET_PDInitialCosting_FILTERS";
    else if (reqTbl === "xl_faherty_merch_assortment_sandbox") tblType = "SET_Merch_Assortment_Sandbox_FILTERS";
    else if (reqTbl === "xl_faherty_airtable_mapping_data") tblType = "SET_AIR_TABLE_FILTERS";

    store.dispatch({ type: tblType, data: filterModel });
}
export const compareAndHighLightDataChanges = async (result, rng, req_tbl, colDef, gridApi, changeValue, hc, mp, tm) => {
    //  // console.log('compareAndHighLightDataChanges -> colNamesArray -> result' + JSON.stringify(result))
    //  // console.log('grid API')
    //  // console.log(gridApi)
    // console.log('compareAndHighLightDataChanges -> colNamesArray -> changeValue')
    // console.log(changeValue)
    console.log('compareAndHighLightDataChanges -> colNamesArray -> changeValue')

    let arr = []
    let isNew = false

    highlightCells = hc
    mpAllTabsChangedRowIdsColNames = mp
    metaData = tm

    if (result.error === "invalid_token") {
        return { "status": false, msg: "invalid_token" }
    } else {
        if (result.response === true) {
            sessionStorage.setItem("GetChangesDone", false)
            let IsDataChangeDetected = false
            let rowNodesCollection = []
            let finaldata = []

            // if (result.data.trim() !== JSON.stringify(colDef)) {
            if (JSON.parse(result.data)["jsondata"] !== "No changes found") {


                IsDataChangeDetected = true
                let newData = JSON.parse(result.data)["jsondata"]
                let oneRowData = colDef[0]
                let colNamesArray = new Array()

                Object.keys(oneRowData).map(cn => {
                    colNamesArray.push(cn.replace(doubleQuote, ''))
                })

                // console.log('compareAndHighLightDataChanges -> colNamesArray -> ' + colNamesArray)
                let rowIndex = 0
                if (req_tbl === "xl_lov")
                    rowIndex = gridApi.getDisplayedRowCount()
                // kept below commented code for the reference -- Hardipsinh 
                // newData.forEach(nd => {
                //     let isFound = false
                //     gridApi.forEachNode((rowNode, index) => {
                //         if (nd.row_id === rowNode.data.row_id) {
                //             isFound = true
                //             colNamesArray.forEach(function (caItem, caIndex) {
                //                 //check if value is date
                //                 if (moment(rowNode.data[caItem], "YYYY/MM/DD", true).isValid()) {
                //                     // console.log(ExcelDateToJsDate(nd[caItem]))
                //                     //// console.log(rowNode.data[caItem])
                //                     if (ExcelDateToJsDate(nd[caItem]) !== rowNode.data[caItem]) {
                //                         rowNode.setDataValue(req_tbl + CH1 + caItem, nd[caItem])
                //                         if (ignore_columns_in_sync_compare.includes(caItem) === false) {
                //                             if (caItem !== "modified_on") {
                //                                 let key = req_tbl + "|" + caItem + "|" + rowNode.rowIndex
                //                                 arr.push({ [key]: 1 })
                //                             }
                //                             rowNodesCollection.push(rowNode)
                //                         }
                //                     }
                //                 } else if (nd[caItem] !== rowNode.data[caItem]) {
                //                     rowNode.setDataValue(req_tbl + CH1 + caItem, nd[caItem])
                //                     if (ignore_columns_in_sync_compare.includes(caItem) === false) {
                //                         if (caItem !== "modified_on") {
                //                             let key = req_tbl + "|" + caItem + "|" + rowNode.rowIndex
                //                             arr.push({ [key]: 1 })
                //                         }
                //                         rowNodesCollection.push(rowNode)
                //                     }
                //                 }
                //             })
                //         }
                //     })

                //     if (!isFound) {
                //         isNew = true
                //         //highlight new data
                //         colNamesArray.forEach(function (caItem, caIndex) {
                //             if (ignore_columns_in_sync_compare.includes(caItem) === false) {
                //                 if (caItem !== "modified_on") {
                //                     let key = req_tbl + "|" + caItem + "|" + rowIndex;
                //                     // console.log("rowIndex...." + key);
                //                     arr.push({ [key]: 1 })
                //                 }
                //             }
                //         })
                //         rowIndex++
                //     }
                // })
                newData.forEach(nd => {
                    let isFound = false
                    let colNamesArray = new Array()

                    gridApi.forEachNode((rowNode, index) => {
                        if (nd.r === rowNode.data.row_id && nd.o === 'd') {
                            // console.log("delete data row")
                            let deletedRow = rowNode.data;
                            gridApi.applyTransaction({ remove: [deletedRow] })
                        }
                        if (nd.r === rowNode.data.row_id && nd.o !== 'd') {
                            isFound = true
                            let jdata = nd.d
                            colNamesArray = new Array()
                            Object.keys(nd["d"]).map(cn => {
                                colNamesArray.push(cn.replace(doubleQuote, ''))
                            })
                            colNamesArray.forEach(function (caItem, caIndex) {
                                //check if value is date

                                if (moment(rowNode.data[caItem], "YYYY/MM/DD", true).isValid()) {
                                    //// console.log(rowNode.data[caItem])
                                    if (ExcelDateToJsDate(jdata[caItem]) !== rowNode.data[caItem]) {
                                        if (changeValue.includes(caItem + "|" + rowNode.data.row_id) !== true) { rowNode.setDataValue(req_tbl + CH1 + caItem, jdata[caItem]) }
                                        if (ignore_columns_in_sync_compare.includes(caItem) === false) {
                                            if (caItem !== "modified_on") {
                                                let key = req_tbl + "|" + caItem + "|" + rowNode.data.row_id
                                                if (changeValue.includes(caItem + "|" + rowNode.data.row_id) !== true) {
                                                    arr.push({ [key]: 1 })
                                                }
                                            }
                                            rowNodesCollection.push(rowNode)
                                        }
                                    }
                                } else if (jdata[caItem] !== rowNode.data[caItem]) {
                                    // console.log(`${jdata[caItem]} <------> ${rowNode.data[caItem]})`)
                                    if (changeValue && changeValue.includes(caItem + "|" + rowNode.data.row_id) !== true) { rowNode.setDataValue(req_tbl + CH1 + caItem, jdata[caItem]) }

                                    if (ignore_columns_in_sync_compare.includes(caItem) === false) {

                                        if (caItem !== "modified_on") {
                                            let key = req_tbl + "|" + caItem + "|" + rowNode.data.row_id
                                            // console.log("Key --> "+ caItem + "|" + rowNode.data.row_id)
                                            if (changeValue && changeValue.includes(caItem + "|" + rowNode.data.row_id) !== true) {
                                                arr.push({ [key]: 1 })
                                            }
                                        }

                                        rowNodesCollection.push(rowNode)
                                    }
                                }
                            })
                        }

                    })

                    if (!isFound && nd.o === 'i') {
                        let newrow = {}
                        newrow = nd.d
                        newrow["row_id"] = nd.r
                        finaldata.push(nd.d)
                        rowNodesCollection.push(nd.d)
                        if (nd.o === 'i') {
                            Object.keys(nd["d"]).map(cn => {
                                colNamesArray.push(cn.replace(doubleQuote, ''))
                            })
                        }

                        isNew = true
                        //highlight new data

                        colNamesArray.forEach(function (caItem, caIndex) {
                            if (ignore_columns_in_sync_compare.includes(caItem) === false) {
                                if (caItem !== "modified_on") {
                                    let key = req_tbl + "|" + caItem + "|" + nd.r;
                                    // console.log("rowIndex...." + key);
                                    arr.push({ [key]: 1 })
                                }
                            }
                        })
                        rowIndex++
                    }
                })
                // finaldata = [ ...ssnData]                
                // sessionStorage.setItem(rng, result.data)

                // let TabsChanges = mpAllTabsChangedRowIdsColNames                
                let TabsChanges = mpAllTabsChangedRowIdsColNames




                // TabsChanges.delete(req_tbl + '_RowIds')
                // TabsChanges.delete(req_tbl + '_ColNames')

                gridApi.forEachNode((rowNode, index) => {
                    finaldata.push(rowNode.data)
                })
                // console.log("finaldata")
                // console.log(finaldata)
                // setMpAllTabsChangedRowIdsColNames(TabsChanges)
                mpAllTabsChangedRowIdsColNames = TabsChanges
                // const data_=JSON.parse(JSON.stringify(finaldata))
                data = JSON.parse(JSON.stringify(finaldata)); //finaldata.slice() //newData
                // console.log("rng -> " + rng)
                // console.log("Final Data :- " )
                // console.log(JSON.parse(JSON.stringify(finaldata)))
                setStateByTblName(req_tbl, JSON.parse(JSON.stringify(finaldata)))

                if (arr.length > 0) {
                    if (isNew) {
                        highlightCells = arr
                        gridApi.refreshCells({ force: true })
                    } else {
                        highlightCells = arr
                        gridApi.refreshCells({ force: true, rowNodes: rowNodesCollection })
                    }
                }

                let ret_val = {};
                ret_val.status = true;
                ret_val.msg = "";
                ret_val.highlightCells = highlightCells;
                ret_val.data = JSON.parse(JSON.stringify(finaldata));
                ret_val.mpAllTabsChangedRowIdsColNames = mpAllTabsChangedRowIdsColNames;
                ret_val.rowNodesCollection = rowNodesCollection;
                // GettingRequestedData(req_tbl)
                var aa = await GettingRequestedData(req_tbl);
                console.log("aa");
                console.log(aa);
                if (aa !== undefined && aa !== null) {
                    ret_val.status = aa.status;
                    ret_val.msg = aa.msg;
                }
                return (ret_val)
            } else {
                console.log("no changes found")
                return { "status": false, msg: "Get Changes : No changes found." }
            }
        } else {
            console.log("There is an error while getting changes")
            return { "status": false, msg: "There is an error while getting changes." }
        }
    }
};
export const GettingRequestedData = async (req_tbl) => {
    let postbody = '{"table_name":"' + req_tbl + '"}'
    var response = await fetch(restApiPath + 'getLatestTx', {
        method: 'POST',
        headers: {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: (postbody)
    })

    var json = await response.json()
    sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id', json.data)

    let start_tx = sessionStorage.getItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download')
    let end_tx = sessionStorage.getItem(req_tbl + '_' + 'Latest_Tx_Id')
    if (start_tx === end_tx) {
        return ({ "status": false, msg: "Get Changes : No changes found." })
    } else {
        GetEvolutionStatisticsByTx(start_tx, end_tx, req_tbl, data)
    }
}
const GetEvolutionStatisticsByTx = (start_tx, end_tx, req_tbl, newData) => {
    //alert('GetEvolutionStatisticsByTx : ' + req_tbl);
    let dl_criteria_param = sessionStorage.getItem('asRequiredDownloadQuery')

    if (dl_criteria_param !== null && dl_criteria_param !== undefined && dl_criteria_param !== "") {
        while (dl_criteria_param.includes(CH1)) {
            dl_criteria_param = dl_criteria_param.replace(CH1, "\\u0001")
        }
        while (dl_criteria_param.includes(CH2)) {
            dl_criteria_param = dl_criteria_param.replace(CH2, "\\u0002")
        }
        while (dl_criteria_param.includes(CH3)) {
            dl_criteria_param = dl_criteria_param.replace(CH3, "\\u0003")
        }
    }

    //// console.log(dl_criteria_param)

    if (dl_criteria_param === null || dl_criteria_param === undefined || dl_criteria_param === "null") {
        dl_criteria_param = ""
    }

    let tmData = metaData
    let dlType = ''
    if (tmData.length > 0) {
        tmData.map(row => {
            if (row.table_name === req_tbl) {
                dlType = row.download_type
            }
        })
    }

    if (dlType.toLowerCase() !== "Ondemand".toLowerCase()) {
        dl_criteria_param = ""
    }

    let postbody = '{"start_tx":"' + start_tx + '","end_tx":"' + end_tx + '","param_user_id":"' + sessionStorage.getItem("email") + '","param_agent_vendor":"","table_name_param":"' + req_tbl + '","dl_criteria_param":"' + dl_criteria_param + '"}'

    fetch(restApiPath + 'getEvolutionStatisticsByTx', {
        method: 'POST',
        headers: {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: (postbody)
    })
        .then(result => result.json())
        .then(data => SetEvolutionStatisticsByTx(data, req_tbl))
}
const SetEvolutionStatisticsByTx = (result, req_tbl) => {
    if (result.response === true) {
        let duration = GetTimeDurationDifference(req_tbl)
        let LatestOnDemandTxId = sessionStorage.getItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download')
        let LatestTxId = sessionStorage.getItem(req_tbl + '_' + 'Latest_Tx_Id')
        if (LatestOnDemandTxId !== LatestTxId) {
            // console.log('result.data')
            // console.log(result.data)
            // console.log('SetEvolutionStatisticsByTx -> result.data.length -> ' + result.data.length)

            if (result.data === null || result.data === undefined || result.data.length === 0 || result.data === '[]' || result.data.length === 0) {
                return { "status": false, msg: "Get Changes : No changes found." }
            } else if (result.data[0].no_of_txs === null || result.data[0].no_of_txs === undefined) {
                return { "status": false, msg: "Get Changes : No changes found." }
            } else {
                sessionStorage.setItem(req_tbl + '_' + 'Duration', duration)

                if (result.data[0].no_of_txs === null || result.data[0].no_of_txs === undefined) {
                    sessionStorage.setItem(req_tbl + '_' + 'Transactions', '')
                } else {
                    sessionStorage.setItem(req_tbl + '_' + 'Transactions', result.data[0].no_of_txs)
                }

                if (result.data[0].no_of_users === null || result.data[0].no_of_users === undefined) {
                    sessionStorage.setItem(req_tbl + '_' + 'Users', '')
                } else {
                    sessionStorage.setItem(req_tbl + '_' + 'Users', result.data[0].no_of_users)
                }

                if (result.data[0].no_of_insert_op === null || result.data[0].no_of_insert_op === undefined) {
                    sessionStorage.setItem(req_tbl + '_' + 'New_Rows', '')
                } else {
                    sessionStorage.setItem(req_tbl + '_' + 'New_Rows', result.data[0].no_of_insert_op)
                }

                if (result.data[0].no_of_update_op === null || result.data[0].no_of_update_op === undefined) {
                    sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates', '')
                } else {
                    sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates', result.data[0].no_of_update_op)
                }

                if (result.data[0].no_of_delete_op === null || result.data[0].no_of_delete_op === undefined) {
                    sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows', '')
                } else {
                    sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows', result.data[0].no_of_delete_op)
                }
                sessionStorage.setItem(req_tbl + "_Last_Update_Time", formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download', LatestTxId)
                sessionStorage.setItem(req_tbl + '_' + 'On_Demand_Download_Time', new Date())
                sessionStorage.setItem("GetChangesDone", true)
            }

        } else {
            sessionStorage.setItem("GetChangesDone", true)
            sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download', LatestTxId)
            sessionStorage.setItem(req_tbl + '_' + 'On_Demand_Download_Time', new Date())
            return { "status": false, msg: "Get Changes : No changes found." }
        }
    }
}

const GetTimeDurationDifference = (req_tbl) => {
    let OnDemandDlTime = sessionStorage.getItem(req_tbl + '_' + 'On_Demand_Download_Time')
    if (OnDemandDlTime !== null && OnDemandDlTime !== undefined && OnDemandDlTime !== '') {
        let CurrentDateTime = new Date()
        let duration = ''

        let days = 0
        days = CurrentDateTime.getDate() - new Date(OnDemandDlTime).getDate()
        days = ("0" + days).slice(-2)

        // console.log('days -> ' + days)

        if (days === 0) {
            duration = ''
        } else if (days === 1) {
            duration = days + ' day, '
        } else {
            duration = days + ' days, '
        }

        let hours = 0
        hours = CurrentDateTime.getHours() - new Date(OnDemandDlTime).getHours()

        if (hours < 10) {
            hours = ("0" + hours).slice(-2)
        }

        // console.log('hours -> ' + hours)

        let minutes = 0
        minutes = CurrentDateTime.getMinutes() - new Date(OnDemandDlTime).getMinutes()

        if (minutes < 10) {
            minutes = ("0" + minutes).slice(-2)
        }

        // console.log('minutes -> ' + minutes)

        let seconds = 0
        seconds = CurrentDateTime.getSeconds() - new Date(OnDemandDlTime).getSeconds()

        if (seconds < 10) {
            seconds = ("0" + seconds).slice(-2)
        }

        // console.log('seconds -> ' + seconds)

        duration = duration + hours + ':' + minutes + ':' + seconds + ' hours'

        // console.log('duration -> ' + duration)

        return duration
    }
}
export const getSessionDataByTblName = (req_tbl) => {
    const reduxState = store.getState();
    if (req_tbl === "xl_faherty_linelist") { return reduxState.LinelistDataSSN; }
    else if (req_tbl === "xl_faherty_chase") { return reduxState.ChaseDataSSN; }
    else if (req_tbl === "xl_faherty_dtc_buy_sheet") { return reduxState.DtcBuySheetDataSSN; }
    else if (req_tbl === "xl_faherty_whsl_buy_sheet") { return reduxState.WhslBuySheetDataSSN; }
    else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp") { return reduxState.WhslBuySheetNordRpDataSSN; }
    else if (req_tbl === "xl_faherty_sizing") { return reduxState.SizingDataSSN; }
    else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage") { return reduxState.BuySheetMasterSizingPercentageDataSSN; }
    else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts") { return reduxState.BuyShtMasterDataChartsDataSSN; }
    else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm") { return reduxState.CompApsMasterStoreEcomDataSSN; }
    else if (req_tbl === "xl_faherty_freight_kg_rates") { return reduxState.FreightMasterDataSSN; }
    else if (req_tbl === "xl_faherty_freight_by_category") { return reduxState.FreightByCategoryDataSSN; }
    else if (req_tbl === "xl_faherty_delivery_master") { return reduxState.DeliveryMasterDataSSN; }
    else if (req_tbl === "xl_faherty_fb_fty_database") { return reduxState.FbFtyDatabaseDataSSN; }
    else if (req_tbl === "xl_faherty_size_scales") { return reduxState.SizeScalesDataSSN; }
    else if (req_tbl === "xl_faherty_shopify_product_type_mapping") { return reduxState.ShopifyProductTypeMappingDataSSN; }
    else if (req_tbl === "xl_faherty_season_inquiry") { return reduxState.SeasonInquiryDataSSN; }
    else if (req_tbl === "xl_faherty_master_lov") { return reduxState.MasterLovDataSSN; }
    else if (req_tbl === "xl_faherty_season") { return reduxState.SeasonDataSSN; }
    else if (req_tbl === "xl_action_item_details") { return reduxState.ActionItemDataSSN; }
    else if (req_tbl === "app_user") { return reduxState.ManageUserDataSSN; }
    else if (req_tbl === "xl_user_data_access") { return reduxState.ManagePermissionDataSSN; }
    else if (req_tbl === "xl_pd_initial_costing") { return reduxState.PDInitialCostingDataSSN; }
    else if (req_tbl === "xl_faherty_merch_assortment_sandbox") { return reduxState.MerchAssortmentSandboxDataSSN; }
    else if (req_tbl === "xl_faherty_airtable_mapping_data") { return reduxState.AirtableMappingDataSSN; }
}
export const getStateDataByTblName = (req_tbl) => {
    const reduxState = store.getState();
    if (req_tbl === "xl_faherty_linelist") { return reduxState.LinelistData; }
    else if (req_tbl === "xl_faherty_chase") { return reduxState.ChaseData; }
    else if (req_tbl === "xl_faherty_dtc_buy_sheet") { return reduxState.DtcBuySheetData; }
    else if (req_tbl === "xl_faherty_whsl_buy_sheet") { return reduxState.WhslBuySheetData; }
    else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp") { return reduxState.WhslBuySheetNordRpData; }
    else if (req_tbl === "xl_faherty_sizing") { return reduxState.SizingData; }
    else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage") { return reduxState.BuySheetMasterSizingPercentageData; }
    else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts") { return reduxState.BuyShtMasterDataChartsData; }
    else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm") { return reduxState.CompApsMasterStoreEcomData; }
    else if (req_tbl === "xl_faherty_freight_kg_rates") { return reduxState.FreightMasterData; }
    else if (req_tbl === "xl_faherty_freight_by_category") { return reduxState.FreightByCategoryData; }
    else if (req_tbl === "xl_faherty_delivery_master") { return reduxState.DeliveryMasterData; }
    else if (req_tbl === "xl_faherty_fb_fty_database") { return reduxState.FbFtyDatabaseData; }
    else if (req_tbl === "xl_faherty_size_scales") { return reduxState.SizeScalesData; }
    else if (req_tbl === "xl_faherty_shopify_product_type_mapping") { return reduxState.ShopifyProductTypeMappingData; }
    else if (req_tbl === "xl_faherty_season_inquiry") { return reduxState.SeasonInquiryData; }
    else if (req_tbl === "xl_faherty_master_lov") { return reduxState.MasterLovData; }
    else if (req_tbl === "xl_faherty_season") { return reduxState.SeasonData; }
    else if (req_tbl === "xl_action_item_details") { return reduxState.ActionItemData; }
    else if (req_tbl === "app_user") { return reduxState.ManageUserData; }
    else if (req_tbl === "xl_user_data_access") { return reduxState.ManagePermissionData; }
    else if (req_tbl === "xl_pd_initial_costing") { return reduxState.PDInitialCostingData; }
    else if (req_tbl === "xl_faherty_merch_assortment_sandbox") { return reduxState.MerchAssortmentSandboxData; }
    else if (req_tbl === "xl_data_transfer_rules") { return reduxState.dataTransferRules; }
    else if (req_tbl === "xl_faherty_airtable_mapping_data") { return reduxState.AirtableMappingData; }
}
const checkYearSeasonDept = (ColDefinations, row) => {
    let isYear = false;
    let isSeason = false;
    let isDept = false;

    let yearIndex = 0
    let seasonIndex = 0
    let deptIndex = 0

    ColDefinations.map((ColDefination, index) => {
        if (ColDefination.headerName.toLowerCase() === 'year') { isYear = true; yearIndex = index; }
        if (ColDefination.headerName.toLowerCase().includes('season')) { isSeason = true; seasonIndex = index; }
        if (ColDefination.headerName.toLowerCase() === 'department') {
            isDept = true;
            deptIndex = index;
            return
        }
    })

    const asRequiredDownloadQuery = sessionStorage.getItem("asRequiredDownloadQuery") ? sessionStorage.getItem("asRequiredDownloadQuery") : ""
    let isConditionMatched = true
    if (isYear && !asRequiredDownloadQuery.includes(row[yearIndex])) isConditionMatched = false
    if (isSeason && !asRequiredDownloadQuery.includes(row[seasonIndex])) isConditionMatched = false
    if (isDept && !asRequiredDownloadQuery.includes(row[deptIndex])) isConditionMatched = false
    return isConditionMatched
}
export const resetUnsentCount = (reqTbl) => {
    store.dispatch({ type: "SET_AUDIT_TRAIL_ACTION", isAuditTrailAction: false });
    if (reqTbl === "xl_faherty_linelist") {
        store.dispatch({ type: "SET_LINELIST_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_LINELIST_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_chase") {
        store.dispatch({ type: "SET_CHASE_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_CHASE_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_dtc_buy_sheet") {
        store.dispatch({ type: "SET_DTC_BUY_SHT_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_DTC_BUY_SHT_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_whsl_buy_sheet") {
        store.dispatch({ type: "SET_WHSL_BUY_SHT_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_WHSL_BUY_SHT_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_freight_by_category") {
        store.dispatch({ type: "SET_FRIEGHT_BY_CAT_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_FRIEGHT_BY_CAT_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_delivery_master") {
        store.dispatch({ type: "SET_DELIVERY_MASTER_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_DELIVERY_MASTER_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_whsl_buy_sheet_nord_rp") {
        store.dispatch({ type: "SET_WHSL_BUY_SHT_NRD_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_WHSL_BUY_SHT_NRD_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_sizing") {
        store.dispatch({ type: "SET_SIZING_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_SIZING_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_master_lov") {
        store.dispatch({ type: "SET_MASTER_LOV_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_MASTER_LOV_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_fb_fty_database") {
        store.dispatch({ type: "SET_FBFTY_DATABASE_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_FBFTY_DATABASE_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_season") {
        store.dispatch({ type: "SET_SEASON_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_SEASON_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_buy_sheet_master_sizing_percentage") {
        store.dispatch({ type: "SET_BUYSHT_MASTER_PER_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_BUYSHT_MASTER_PER_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_buy_sheet_master_data_charts") {
        store.dispatch({ type: "SET_BUYSHT_MASTER_CHART_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_BUYSHT_MASTER_CHART_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_comp_aps_master_stores_ecomm") {
        store.dispatch({ type: "SET_COMP_MASTER_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_COMP_MASTER_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_freight_kg_rates") {
        store.dispatch({ type: "SET_FRIEGHT_MASTER_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_FRIEGHT_MASTER_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_shopify_product_type_mapping") {
        store.dispatch({ type: "SET_SHOPIFY_PRODUCT_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_SHOPIFY_PRODUCT_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_season_inquiry") {
        store.dispatch({ type: "SET_SEASON_INQUIRY_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_SEASON_INQUIRY_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_size_scales") {
        store.dispatch({ type: "SET_SIZE_SCALE_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_SIZE_SCALE_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_pd_initial_costing") {
        store.dispatch({ type: "SET_PDInitialCosting_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_PDInitialCosting_HIGHLIGHT_CELL", data: [] });
    } else if (reqTbl === "xl_faherty_merch_assortment_sandbox") {
        store.dispatch({ type: "Set_Merch_Assortment_Sandbox_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "Set_Merch_Assortment_Sandbox_HIGHLIGHT_CELL", data: [] });
    }else if (reqTbl === "xl_faherty_airtable_mapping_data") {
        store.dispatch({ type: "SET_AIR_TABLE_UNSENT_COUNT", count: 0 });
        store.dispatch({ type: "SET_AIR_TABLE_HIGHLIGHT_CELL", data: [] });
    }
};
export const getUnsentCount = (req_tbl) => {
    const reduxState = store.getState();
    if (req_tbl === "xl_faherty_linelist") { return reduxState.LineListUnsentCount; }
    else if (req_tbl === "xl_faherty_chase") { return reduxState.ChaseUnsentCount; }
    else if (req_tbl === "xl_faherty_dtc_buy_sheet") { return reduxState.DtcButSheetUnsentCount; }
    else if (req_tbl === "xl_faherty_whsl_buy_sheet") { return reduxState.WhslBuySheetUnsentCount; }
    else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp") { return reduxState.WhslBuySheetNordUnsentCount; }
    else if (req_tbl === "xl_faherty_sizing") { return reduxState.SizingUnsentCount; }
    else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage") { return reduxState.BuySheetMasterSizingPercentageUnsentCount; }
    else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts") { return reduxState.BuyShtMasterDataChartsUnsentCount; }
    else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm") { return reduxState.CompApsMasterStoreEcomUnsentCount; }
    else if (req_tbl === "xl_faherty_freight_kg_rates") { return reduxState.FreightMasterUnsentCount; }
    else if (req_tbl === "xl_faherty_freight_by_category") { return reduxState.FreightByCategoryUnsentCount; }
    else if (req_tbl === "xl_faherty_delivery_master") { return reduxState.DeliveryMasterUnsentCount; }
    else if (req_tbl === "xl_faherty_fb_fty_database") { return reduxState.FbFtyDatabaseUnsentCount; }
    else if (req_tbl === "xl_faherty_size_scales") { return reduxState.SizeScalesUnsentCount; }
    else if (req_tbl === "xl_faherty_shopify_product_type_mapping") { return reduxState.ShopifyProductTypeMappingUnsentCount; }
    else if (req_tbl === "xl_faherty_season_inquiry") { return reduxState.SeasonInquiryUnsentCount; }
    else if (req_tbl === "xl_faherty_master_lov") { return reduxState.MasterLovUnsentCount; }
    else if (req_tbl === "xl_faherty_season") { return reduxState.SeasonUnsentCount; }
    else if (req_tbl === "xl_pd_initial_costing") { return reduxState.PDInitialCostingUnsentCount; }
    else if (req_tbl === "xl_faherty_merch_assortment_sandbox") { return reduxState.MerchAssortmentSandboxUnsentCount; }
    else if (req_tbl === "xl_faherty_airtable_mapping_data") { return reduxState.AirtableMappingUnsentCount; }
}
export const setUnsentCount = (reqTbl, cnt) => {
    if (reqTbl === "xl_faherty_linelist") store.dispatch({ type: "SET_LINELIST_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_chase") store.dispatch({ type: "SET_CHASE_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_dtc_buy_sheet") store.dispatch({ type: "SET_DTC_BUY_SHT_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_whsl_buy_sheet") store.dispatch({ type: "SET_WHSL_BUY_SHT_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_freight_by_category") store.dispatch({ type: "SET_FRIEGHT_BY_CAT_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_delivery_master") { store.dispatch({ type: "SET_DELIVERY_MASTER_UNSENT_COUNT", count: cnt }); }
    else if (reqTbl === "xl_faherty_whsl_buy_sheet_nord_rp") store.dispatch({ type: "SET_WHSL_BUY_SHT_NRD_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_sizing") store.dispatch({ type: "SET_SIZING_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_master_lov") store.dispatch({ type: "SET_MASTER_LOV_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_fb_fty_database") store.dispatch({ type: "SET_FBFTY_DATABASE_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_size_scales") store.dispatch({ type: "SET_SIZE_SCALE_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_season") store.dispatch({ type: "SET_SEASON_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_buy_sheet_master_sizing_percentage") store.dispatch({ type: "SET_BUYSHT_MASTER_PER_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_buy_sheet_master_data_charts") store.dispatch({ type: "SET_BUYSHT_MASTER_CHART_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_comp_aps_master_stores_ecomm") store.dispatch({ type: "SET_COMP_MASTER_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_freight_kg_rates") store.dispatch({ type: "SET_FRIEGHT_MASTER_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_shopify_product_type_mapping") store.dispatch({ type: "SET_SHOPIFY_PRODUCT_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_season_inquiry") store.dispatch({ type: "SET_SEASON_INQUIRY_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_pd_initial_costing") store.dispatch({ type: "SET_PDInitialCosting_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_merch_assortment_sandbox") store.dispatch({ type: "Set_Merch_Assortment_Sandbox_UNSENT_COUNT", count: cnt });
    else if (reqTbl === "xl_faherty_airtable_mapping_data") store.dispatch({ type: "SET_AIR_TABLE_UNSENT_COUNT", count: cnt });
}

const insertDataFunc = (data, tbl_name, stateData, downloadType, arr, ssnData) => {
    //get insert changes
    try {
        const ch1 = data.split(CH2)
        const insertData = ch1[0].split(CH2)
        const insrt = insertData[0].split(CH1)

        let status = false;
        const tmp = [];
        let newData = stateData;
        let ssnNewData = ssnData ? ssnData : [];

        const in1 = insrt[1] ? parseInt(insrt[1]) : 0;
        const in2 = insrt[2] ? parseInt(insrt[2]) : 0;

        if (in1 > 0 && in2 > 0) { //add cell
            const myData = JSON.parse(insrt[3])
            // console.log("myData", myData)
            const ColDefination = getColumnDefByTblName(tbl_name)
            myData.map((row) => {
                const element = {}
                if (ColDefination && row.length === ColDefination.length && (checkYearSeasonDept(ColDefination, row) || downloadType.toLowerCase() === "default")) {
                    for (var i = 0; i < row.length; i++) {
                        const col = ColDefination[i].headerName
                        if (col === "modified_on" || col === "inserted_on") {
                            element[col] = formatDateToFullString(new Date(row[i]))
                        } else element[col] = row[i]
                        const key = tbl_name + "|" + col + "|" + row[0]
                        arr.push({ [key]: 1 })
                    }
                    tmp.push(element)
                }
            })
            //merge newdata with session data
            if (tmp.length > 0) {
                status = true
                newData = [...tmp, ...newData]
                ssnNewData = [...tmp, ...ssnNewData]
            }
        }
        return { "status": status, "newData": newData, "arr": arr , "ssnData": ssnNewData}
    } catch (error) {
        console.log("auto get changes insertion fail", error)
        return { "status": false, "data": [], "arr": [] }
    }
}
const updateDataFunc = (data, gridApi, newData, ssnData, ColDefination, count, tbl_name, cellValueChanged, arr) => {
    //get update changes
    try {
        const ch1 = data.split(CH2)
        let status = false;

        let cnt = count;
        let showChangesDlg = []
        const myValues = []
        const updateData = ch1[1].split(CH2)
        const updt = updateData[0].split(CH1)
        const up1 = updt[1] ? parseInt(updt[1]) : 0;
        const up2 = updt[2] ? parseInt(updt[2]) : 0;
        const newSsnData = ssnData;

        if (up1 > 0 && up2 > 0) { //update cell
            const myData = JSON.parse(updt[3])
            let gridRowIds = new Array();
            gridApi.forEachNode(nd => gridRowIds.push(nd.data.row_id));
            myData.map((arr1) => {
                gridApi.forEachNode((rowNode) => {
                    const col = arr1[1]
                    if (arr1[0] === rowNode.data.row_id) {
                        status = true;
                        const key1 = col + "|" + arr1[0]

                        if (cellValueChanged.includes(key1) && arr1[2] !== rowNode.data[col]) {
                            showChangesDlg.push(arr1)
                            myValues.push({ "col": col, "row_id": rowNode.data.row_id, "data": rowNode.data[col] })
                        } else {
                            if (col === "modified_on") {
                                arr1[2] = formatDateToFullString(new Date(arr1[2]));
                                rowNode.setDataValue(tbl_name + CH1 + col, formatDateToFullString(new Date(arr1[2])));
                            } else {
                                rowNode.setDataValue(tbl_name + CH1 + col, arr1[2]);
                            }
                            const key = tbl_name + "|" + col + "|" + rowNode.data.row_id
                            const obj = checkHeihlight(key, arr)
                            if (obj.status) {
                                arr = arr.map((hg) => {
                                    if (hg[key]) {
                                        hg[key] = 1
                                        cnt--;
                                    }
                                    return hg;
                                })
                            } else arr.push({ [key]: 1 })

                            newData.map((element) => {
                                if (element.row_id === arr1[0]) {
                                    element[col] = arr1[2]
                                }
                                return element
                            })
                            ssnData.map((element) => {
                                if (element.row_id === arr1[0]) {
                                    element[col] = arr1[2]
                                }
                                return element
                            })
                        }
                    }
                })
                //Handling: When new row arrived and columns also updated then row_id does not present.
                if (gridRowIds.includes(arr1[0]) === false) {
                    newData.map((element) => {
                        if (element.row_id === arr1[0]) {
                            console.log("NOT FOUND -> arr1[0] -> ", arr1[0], " -> arr1[1] -> ", arr1[1])
                            if (arr1[1] === "modified_on") {
                                arr1[2] = formatDateToFullString(new Date(arr1[2]))
                                element[arr1[1]] = formatDateToFullString(new Date(arr1[2]))
                            } else {
                                element[arr1[1]] = arr1[2]
                            }
                        }
                        return element
                    })
                    ssnData.map((element) => {
                        if (element.row_id === arr1[0]) {
                            console.log("NOT FOUND -> arr1[0] -> ", arr1[0], " -> arr1[1] -> ", arr1[1])
                            if (arr1[1] === "modified_on") {
                                arr1[2] = formatDateToFullString(new Date(arr1[2]))
                                element[arr1[1]] = formatDateToFullString(new Date(arr1[2]))
                            } else {
                                element[arr1[1]] = arr1[2]
                            }
                        }
                        return element
                    })
                }
            })
        }
        console.log("showChangesDlg after", showChangesDlg)
        const jsonData = []
        if (showChangesDlg.length > 0) {
            showChangesDlg.map((dt1) => {
                const col = dt1[1]
                const row_id = dt1[0]
                let data = dt1[2]
                const colDef = ColDefination.find((element) => {
                    return element.headerName === col;
                })
                const oldVal = myValues.find((element) => {
                    return element.col === col && element.row_id === row_id
                })
                let oldData = oldVal.data;
                //format data according to type
                if (colDef.type === 'date' && !data.includes("/")) {
                    data = ExcelDateToJsDate(data)
                    oldData = ExcelDateToJsDate(oldData)
                }
                else if (colDef.type === 'price' && data && data !== "") {
                    data = "$" + parseFloat(data).toFixed(2)
                    oldData = "$" + parseFloat(oldData).toFixed(2)
                }
                else if (colDef.type === 'percentage' && data && data !== "") {
                    data = parseFloat(data).toFixed(2) + "%"
                    oldData = parseFloat(oldData).toFixed(2) + "%"
                } else if (colDef.type === 'decimal' && data && data !== "") {
                    data = parseFloat(data).toFixed(2)
                    oldData = parseFloat(oldData).toFixed(2)
                }

                jsonData.push({ "row_id": dt1[0], "Column Name": col, "Your Unsent Changes": oldData, "New Changes": data })
            })
        }
        return { "status": status, "newData": newData, "ssnData": ssnData, "arr": arr, "jsonData": jsonData, "unsentCount": cnt }
    } catch (error) {
        console.log("auto get changes updation fail", error)
        return { "status": false, "data": [] }
    }
}
const updateDataForHiddenTabFunc = (data, stateData, tbl_name, arr, cnt) => {
    //get insert changes
    try {
        const ch1 = data.split(CH2)
        const updateData = ch1[1].split(CH2)
        const updt = updateData[0].split(CH1)

        let status = false;
        let newData = stateData;

        const up1 = updt[1] ? parseInt(updt[1]) : 0;
        const up2 = updt[2] ? parseInt(updt[2]) : 0;

        if (up1 > 0 && up2 > 0) { //update cell
            const myData = JSON.parse(updt[3]);
            myData.map((arr1) => {
                const col = arr1[1]
                newData.map((element) => {
                    if (element.row_id === arr1[0]) {
                        status = true;
                        if (col === "modified_on") {
                            element[col] = formatDateToFullString(new Date(arr1[2]))
                        } else element[col] = arr1[2]

                        const key = tbl_name + "|" + col + "|" + arr1[0]
                        let isKeyExist = false;
                        arr = arr.map((item) => {
                            if (item[key] === 4) {
                                isKeyExist = true
                                item[key] = 1
                                if (cnt > 0) cnt--
                            }
                            return item
                        })
                        if (!isKeyExist) arr.push({ [key]: 1 })
                    }
                    return element
                })
            })
        }
        return { "status": status, "newData": newData, "arr": arr, "unsentCount": cnt }
    } catch (error) {
        console.log("auto get changes update from hidden fail", error)
        return { "status": false, "data": [], "arr": [], "unsentCount": 0 }
    }
}
const deleteDataFunc = (data, cnt, newData, ssnData, arr) => {
    //get delete changes
    try {
        const ch1 = data.split(CH2)
        let status = false;

        //get delete changes
        const deleteData = ch1[2].split(CH2)
        const delt = deleteData[0].split(CH1)
        let delCount = 0
        const del1 = delt[1] ? parseInt(delt[1]) : 0;
        const del2 = delt[2] ? parseInt(delt[2]) : 0;

        if (del1 > 0 && del2 > 0) { //delete cell
            const myData = JSON.parse(delt[3])

            let tmp = ssnData
            let tmp1 = newData
            myData.map((row_id) => {
                arr = arr.map((item) => {
                    let isKeyExist = false
                    let myKey = ""
                    Object.keys(item).map((key) => {
                        if (key.includes(row_id)) {
                            isKeyExist = true
                            myKey = key
                        }
                    })
                    if (isKeyExist) {
                        status = true
                        item[myKey] = 0
                        cnt--
                    }
                    return item
                })
                if (status === false) {
                    const find = tmp1.find((item) => item.row_id.trim() === row_id.toString().trim())
                    if (find) {
                        status = true
                    }
                }
                tmp = tmp.filter((item) => item.row_id.trim() !== row_id.toString().trim());
                tmp1 = tmp1.filter((item) => item.row_id.trim() !== row_id.toString().trim());
                delCount++;
            })
            //merge newdata with session data
            newData = tmp1
            ssnData = tmp
        }
        return { "status": status, "newData": newData, "ssnData": ssnData, "unsentCount": cnt, "delCount": delCount, "arr": arr }
    } catch (error) {
        console.log("auto get changes deletion fail", error)
        return { "status": false, "data": [] }
    }
}
export const GetAutoChanges = (changeData, highlightCells, gridApi, cellValueChanged, tbl_name, downloadType) => {
    let arr = highlightCells
    if (!arr) arr = []
    //get data from redux
    let ssnData = getSessionDataByTblName(tbl_name)
    let newData = getStateDataByTblName(tbl_name);
    const ColDefination = getColumnDefByTblName(tbl_name);
    let cnt = getUnsentCount(tbl_name)
    let jsonData = [];
    let status = false;
    let delCount = 0;

    if (!ssnData) {
        return { "status": false, "delCount": 0, "msg": "Please download data from Season tab." }
    }
    const changes = changeData.split(CH3);

    changes.map((data) => {
        const insertedData = insertDataFunc(data, tbl_name, newData, downloadType, arr, ssnData)
        if (insertedData && insertedData.status) {
            console.log("insertedData", insertedData);
            status = insertedData.status;
            newData = insertedData.newData;
            arr = insertedData.arr
            ssnData = insertedData.ssnData;
        }

        const updatedData = updateDataFunc(data, gridApi, newData, ssnData, ColDefination, cnt, tbl_name, cellValueChanged, arr)
        if (updatedData && updatedData.status) {
            console.log("updatedData", updatedData);
            status = updatedData.status;
            newData = updatedData.newData;
            arr = [...arr, ...updatedData.arr];
            jsonData = updatedData.jsonData;
            cnt = updatedData.unsentCount;
            ssnData = insertedData.ssnData;
        }

        const deletedData = deleteDataFunc(data, cnt, newData, ssnData, arr)
        if (deletedData && deletedData.status) {
            console.log("deletedData", deletedData);
            status = deletedData.status;
            newData = deletedData.newData;
            arr = deletedData.arr;
            cnt = deletedData.unsentCount;
            delCount = deletedData.delCount;
            ssnData = insertedData.ssnData;
        }
        console.log("data after deletion", newData)
    })

    if (status) {
        setStateDataByTblName(tbl_name, newData);
        setSessionDataByTblName(tbl_name, ssnData);
        setHighlightByTblName(tbl_name, arr);
        setUnsentCount(tbl_name, cnt);
    }
    return { "highlightCells": arr, "jsonData": jsonData, "delCount": delCount }
}
export const GetAutoChangesForHiddenTabs = (changeData, tbl_name, downloadType) => {
    let arr = getHighlightByTblName(tbl_name);
    if (!arr) arr = []
    // resetUnsentCount(tbl_name);

    //get data from redux
    let cnt = getUnsentCount(tbl_name)
    const ssnData = getSessionDataByTblName(tbl_name);
    let status = false;
    let newData = ssnData;
    let delCount = 0;

    const changes = changeData.split(CH3);

    changes.map((data) => {
        const insertedData = insertDataFunc(data, tbl_name, newData, downloadType, arr)
        if (insertedData && insertedData.status) {
            console.log("insertedData", insertedData);
            status = insertedData.status;
            newData = insertedData.newData;
            arr = insertedData.arr
        }

        //get update changes
        const updatedData = updateDataForHiddenTabFunc(data, newData, tbl_name, arr, cnt)
        if (updatedData && updatedData.status) {
            console.log("updatedData", updatedData);
            status = updatedData.status;
            newData = updatedData.newData;
            arr = [...arr, ...updatedData.arr];
            cnt = updatedData.unsentCount;
        }

        //get delete changes
        const deletedData = deleteDataFunc(data, cnt, newData, ssnData, arr)
        if (deletedData && deletedData.status) {
            console.log("deletedData", deletedData);
            status = deletedData.status;
            newData = deletedData.newData;
            arr = deletedData.arr;
            cnt = deletedData.unsentCount;
            delCount = deletedData.delCount;
        }
    })
    if (status) setStateByTblName(tbl_name, newData, arr, cnt)

    return { "status": status, "delCount": delCount }
}