import { applicationTitle } from "utils/app.props";
import { Divider, Tab, Tabs, Badge } from "@mui/material";
import { functionalityAccess } from "layouts/linelist/customFunctions";
import { isSideBarEnabled, restApiPath } from "utils/app.props";
import { navbarDesktopMenu, navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import { setCurrentTab, setDefault, setMiniSidenav, setSideNavBtnClick } from "context";
import { store } from "store";
import { toastMessage } from "layouts/linelist/XL_Utils";
import { useDispatch } from "react-redux";
import { useEffect, useImperativeHandle, useState } from "react";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Menu from "@mui/material/Menu";
import NotificationItem from "examples/Items/NotificationItem";
import { forwardRef } from "react";
import { styled } from '@mui/material/styles';
import { Box, Typography, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Buffer } from 'buffer';
import MDButton from "components/MDButton";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// function DefaultNavbar({
const DefaultNavbar = forwardRef(({
  assortBtnCall,
  btnCarryOver,
  btnSavePreference,
  calculateSizesBtnCall,
  costingViewBtnCall,
  defaultCostingViewBtnCall,
  downloadBtn,
  downloadLinelistBtnCall,
  finalCostingView,
  generateItemUpload,
  getChangesBtnCall,
  highlightStyle,
  importDataBtnCall,
  light,
  onQuickFilterChange,
  quickFilterInputRef,
  quickFilterText,
  redownloadTabBtnCall,
  sendChangeMemoBtnCall,
  sendChangesBtnCall,
  ShowChangesBtnCall,
  transparent,
  upload_btn_click,
  summaryDlgOpen,
  ShowGenie,
  ShowHelpPopup,
  activeStyleActivityFilterBtnCall,
  droppedStyleActivityFilterBtnCall,
  allStyleActivityFilterBtnCall
}, ref) => {
  const [controller, dispatchMui] = useMaterialUIController();
  const userRole = sessionStorage.getItem("user_type");
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [clickedMsg, setClickedMsg] = useState({});
  const [openSkuDlg, setOpenSkuDlg] = useState(false);
  const handleSkuOpen = () => {
    setOpenSkuDlg(true);
  };
  const handleSkuClose = () => {
    setOpenSkuDlg(false);
    setOpenNotificationsMenu(false);
  };
  const [actionMsg, setActionMsg] = useState([]);
  const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { miniSidenav, transparentNavbar, darkMode, currentTab } = controller;
  const redux = store.getState();
  const nevigate = useNavigate();
  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
  const dispatch = useDispatch();
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };
  const checkForAppLevelAccess = () => {
    let flag = false;
    const buttons = ["btnDownloadData"];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForTabLevelAccess = () => {
    let flag = false;
    const buttons = ["btnRedownloadTab", "btnSendChanges", "btnAssort", "btnCarryOver"];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForDownloadLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnDownloadLinelist",
      "btnDownloadDtc",
      "btnDownloadWholesale",
      "btnDownloadChase",
      "btnDownloadSizing",
      "btnDownloadManageUser"
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForUtilityLevelAccess = () => {
    let flag = false;
    const buttons = ["btnShowChanges", "btnSavePreference", "btnImportData"];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForOprationLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnSendChangeMemo",
      "btnGenerateItemUpload",
      "btnHighlightStyle",
      "btnCalculateSizes",
      "btnDocumentUpload",
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForCostingViewLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnDefaultCostingView",
      "btnCostingView",
      "btnFinalCostingView",
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForStleActivityViewLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnStyleActivityView",
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const [chkAppLevel, setAppTabLevel] = useState(checkForAppLevelAccess);
  const [chkTabLevel, setChkTabLevel] = useState(checkForTabLevelAccess);
  const [chkDownloadLevel, setChkDownloadLevel] = useState(checkForDownloadLevelAccess);
  const [chkUtilityLevel, setChkUtilityLevel] = useState(checkForUtilityLevelAccess);
  const [chkOperationLevel, setChkOperationLevel] = useState(checkForOprationLevelAccess);
  const [chkCostingView, setCostingView] = useState(checkForCostingViewLevelAccess);
  const [chkStyleActivityView, setStyleActivityView] = useState(checkForStleActivityViewLevelAccess);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }
    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  useEffect(() => {
    setAppTabLevel(checkForAppLevelAccess);
    setChkTabLevel(checkForTabLevelAccess);
    setChkDownloadLevel(checkForDownloadLevelAccess);
    setChkUtilityLevel(checkForUtilityLevelAccess);
    setChkOperationLevel(checkForOprationLevelAccess);
    setCostingView(checkForCostingViewLevelAccess);
    setStyleActivityView(checkForStleActivityViewLevelAccess);
  }, [currentTab, redux.appMetaData]);
  const handelLogout = () => {
    dispatch({ type: "SET_PLEASE_WAIT", data: true });
    fetch(restApiPath + "userLogout", {
      method: "POST",
      headers: {
        Authorization: "bearer " + sessionStorage.getItem("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: sessionStorage.getItem("access_token"),
    })
      .then((result) => result.json())
      .then((result) => {
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
        if (result.error === "invalid_token") {
          localStorage.clear();
          sessionStorage.clear();
          setDefault(dispatchMui);
          nevigate("/login");
          return;
        }
        if (result && result.response) {
          sessionStorage.clear();
          setCurrentTab(dispatchMui, "");
          setSideNavBtnClick(dispatchMui, false);
          setMiniSidenav(dispatchMui, true);
          setDefault(dispatchMui);
          nevigate("/login");
        } else {
          toastMessage("Opps. Something went wrong. ", "error");
        }
      });
  };
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenNotificationsMenu = (event) => setOpenNotificationsMenu(event.currentTarget);
  const handleCloseNotificationsMenu = () => setOpenNotificationsMenu(false);
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });
  const handleMiniSidenav = () => {
    if (userRole && userRole.toLowerCase() === "vendor") return;
    if (
      sessionStorage.getItem("isDownloadLinelistPerformed") === false ||
      sessionStorage.getItem("isDownloadLinelistPerformed") === "false"
    ) {
      toastMessage("Please download Linelist from Season tab.");
      return;
    }
    const chartTabs = ["Linelist", "Seasons", "DTC_Buy_Sheet", "Whsl_Buy_Sheet", "Sizing", "Chase"];
    if (!chartTabs.includes(currentTab)) {
      toastMessage(`Charts are not available for ${currentTab}. tab.`);
      return;
    }
    setSideNavBtnClick(dispatchMui, true);
    setMiniSidenav(dispatchMui, !miniSidenav);
  };
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>account_circle</Icon>}
        title={sessionStorage.getItem("email")}
      />
      {/* <NotificationItem icon={<Icon>tips_and_updates</Icon>} title="Help/Tips" onClick={ShowHelpPopup} /> */}
      <NotificationItem icon={<img class="myimg" src="buddy.gif" width="25px"></img>} title="Hey, Buddy" onClick={ShowGenie} />
      <NotificationItem icon={<Icon>logout_circle</Icon>} title="Logout" onClick={handelLogout} />
    </Menu>
  );
  useImperativeHandle(ref, () => ({
    handleMerchToPdMsg1: (msg) => {
      // console.log("DefaultNavbar -> handleMerchToPdMsg1 -> msg -> ", msg);
      // const message = JSON.parse(msg)
      // const fd = JSON.parse(message.message);
      // const preMsgs = [ ...actionMsg ]
      // preMsgs.push(fd);
      const preMsgs = [...actionMsg]
      // preMsgs.push(msg);
      if (Array.isArray(msg)) {
        preMsgs.unshift(...msg);
      } else {
        preMsgs.unshift(msg);
      }
      console.log("preMsgs -> ", preMsgs);
      setActionMsg(preMsgs);
    }
  }));
  const removeNotification = (id) => {
    setActionMsg(actionMsg.filter(am => am.id !== id));
  };
  const onNotificationClick = (id) => {
    const item = actionMsg.find(am => am.id === id);
    if (item) {
      setClickedMsg(item)
      // setClickedMsg(Buffer.from(item.cell_changes, 'base64').toString('utf8'));
      // console.log(Buffer.from(item.cell_changes, 'base64').toString('utf8'));
    }
    // setClickedMsg(actionMsg.filter(am => am.id === id));
    handleSkuOpen(true)
    setActionMsg(actionMsg.filter(am => am.id !== id));
    // console.log("onNotificationClick -> id -> ", id);
    // console.log(actionMsg.filter(am => am.id === id));
  };
  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxWidth: 400,
    margin: theme.spacing(2),
  }));
  const renderNotificationMenu = () => {
    if (actionMsg && actionMsg.length > 0) {
      return (
        <Menu
          anchorEl={openNotificationsMenu}
          anchorReference={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={Boolean(openNotificationsMenu)}
          onClose={handleCloseNotificationsMenu}
          sx={{ mt: 2 }}
        >
          {actionMsg.map(am => (
            <div key={am.id}>
              <NotificationItem
                // icon={<Icon>mark_unread_chat_alt</Icon>}
                // title={am.msg + " : " + (JSON.parse(Buffer.from(am.cell_changes, 'base64').toString('utf8')))[0]["SKU"]}
                title={am.msg + " : " + decodeAndReturnField(am.cell_changes, "SKU")}
                // onClick={() => removeNotification(am.id)}
                onClick={() => onNotificationClick(am.id)}
              >
                {am.follow_up_actions}
              </NotificationItem>
            </div>
          ))}
        </Menu>
      )
    } else {
      return null;
    }
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 0,
      top: 2,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      fontWeight: 400,
      fontSize: "10px"
    },
  }));
  const decodeAndReturnField = (dt, cl) => {
    if (dt) {
      // console.log("decodeAndReturnField -> dt -> ", dt)
      return JSON.parse(Buffer.from(dt, 'base64').toString('utf8'))[0][cl]
    }
  }
  return (
    <AppBar sx={{ size: "small" }}>
      <MDBox
        id="TOPBUTTONS"
        my={3}
        mx={3}
        width="calc(100% - 10px)"
        marginTop="3px"
        marginLeft="5px"
        marginRight="5px"
        marginBottom="3px"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position="absolute"
        left={0}
        zIndex={99}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            py={transparent ? 1.5 : 0.75}
            lineHeight={1}
            pl={{ xs: 0, lg: 1 }}
            sx={{ whiteSpace: "nowrap" }}
          >
            <MDBox
              component="img"
              src="/Faherty-Brand-Mini.png"
              alt="name"
              maxWidth="150px"
              maxHeight="150px"
              position="relative"
              zIndex={1}
            />
            <MDTypography
              variant="button"
              fontWeight="bold"
              color={light ? "white" : "dark"}
              sx={{ marginLeft: "5px" }}
            >
              {applicationTitle}
            </MDTypography>
            {/* {userRole && userRole.toLowerCase() !== "vendor" && isSideBarEnabled ? (
              <IconButton
                sx={navbarDesktopMenu}
                onClick={handleMiniSidenav}
                size="small"
                disableRipple
                style={{ width: "30px", height: "30px", marginLeft: "15px" }}
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            ) : null} */}
          </MDBox>
          <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
            <MDBox display="flex" justifyContent="center" width={windowDimensions.width - 380}>
              <Tabs variant="scrollable" scrollButtons={true} centered
                sx={{ "& .MuiTabs-indicator": { display: "none" } }}
              >
                {chkAppLevel ? (
                  <>
                    <div style={{ justifyContent: "center", minWidth: "max-content" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>App Level</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        {functionalityAccess("btnDownloadData", currentTab) ? (
                          <Tab
                            icon={<Icon>file_download</Icon>}
                            label="Download Data"
                            onClick={downloadLinelistBtnCall}
                            className="custom-tab"
                          />
                        ) : null}
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "7px", marginLeft: "7px" }}
                    />
                  </>
                ) : null}
                {chkTabLevel ? (
                  <>
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Tab Level</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "row", minWidth: "max-content" }}
                        >
                          {functionalityAccess("btnRedownloadTab", currentTab) ? (
                            <Tab
                              icon={<Icon>download_for_offline</Icon>}
                              label="Redownload"
                              onClick={redownloadTabBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnSendChanges", currentTab) ? (
                            <Tab
                              icon={<Icon>arrow_upward</Icon>}
                              label="Send Changes"
                              onClick={sendChangesBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnAssort", currentTab) ? (
                            <Tab
                              icon={<Icon>swap_horiz</Icon>}
                              label="Assort"
                              onClick={assortBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnCarryOver", currentTab) ? (
                            <Tab
                              icon={<Icon>swap_horiz</Icon>}
                              label="Carry Over"
                              onClick={btnCarryOver}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnSummaryView", currentTab) ? (
                            <Tab
                              icon={<Icon>dashboard</Icon>}
                              label="Dashboard"
                              onClick={summaryDlgOpen}
                              className="custom-tab"
                            />
                          ) : null}

                          {/* {buttonAccess("summaryView", userRole) ? <Tab icon={<Dashboard />} label="Summary" onClick={summaryDlgOpen} /> : null} */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkDownloadLevel ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "7px", marginLeft: "7px" }}
                    />
                    <div style={{ justifyContent: "center", minWidth: "max-content" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>On-Demand Download</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {functionalityAccess("btnDownloadLinelist", currentTab) ? (
                            <Tab
                              icon={<Icon>system_update_alt</Icon>}
                              label="Linelist"
                              onClick={() => downloadBtn("Linelist")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadDtc", currentTab) ? (
                            <Tab
                              icon={<Icon>system_update_alt</Icon>}
                              label="DTC Buy"
                              onClick={() => downloadBtn("DTC_Buy_Sheet")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadWholesale", currentTab) ? (
                            <Tab
                              icon={<Icon>system_update_alt</Icon>}
                              label="Wholesale Buy"
                              onClick={() => downloadBtn("Wholesale")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadChase", currentTab) ? (
                            <Tab
                              icon={<Icon>system_update_alt</Icon>}
                              label="Chase"
                              onClick={() => downloadBtn("Chase")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadSizing", currentTab) ? (
                            <Tab
                              icon={<Icon>system_update_alt</Icon>}
                              label="Sizing"
                              onClick={() => downloadBtn("Sizing")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadManageUser", currentTab) ? (
                            <Tab
                              icon={<Icon>system_update_alt</Icon>}
                              label="Manage User"
                              onClick={() => downloadBtn("Manage User")}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkUtilityLevel ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "7px", marginLeft: "7px" }}
                    />
                    <div style={{ justifyContent: "center", minWidth: "max-content" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>Utilities</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {functionalityAccess("btnShowChanges", currentTab) ? (
                            <Tab
                              icon={<Icon>update</Icon>}
                              label="Show Changes"
                              onClick={ShowChangesBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnSavePreference", currentTab) ? (
                            <Tab
                              icon={<Icon>star</Icon>}
                              label="Preferences"
                              onClick={btnSavePreference}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnImportData", currentTab) ? (
                            <Tab
                              icon={<Icon>import_contacts</Icon>}
                              label="Import"
                              onClick={importDataBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkOperationLevel ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "7px", marginLeft: "7px" }}
                    />
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Operations</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", minWidth: "max-content"}}>
                          {functionalityAccess("btnSendChangeMemo", currentTab) ? (
                            <Tab
                              icon={<Icon>forward_to_inbox</Icon>}
                              label="Change Memo"
                              onClick={sendChangeMemoBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnGenerateItemUpload", currentTab) ? (
                            <Tab
                              icon={<Icon>note_add</Icon>}
                              label="Item Upload"
                              onClick={generateItemUpload}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnHighlightStyle", currentTab) ? (
                            <Tab
                              icon={<Icon>format_color_text</Icon>}
                              label="Highlight Style"
                              onClick={highlightStyle}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnCalculateSizes", currentTab) ? (
                            <Tab
                              icon={<Icon>calculate_outlined</Icon>}
                              label="Calculate Sizes"
                              onClick={calculateSizesBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDocumentUpload", currentTab) ? (
                            <Tab
                              icon={<Icon>attach_file</Icon>}
                              label="Attachment"
                              onClick={upload_btn_click}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkCostingView ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "7px", marginLeft: "7px" }}
                    />
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Costing View</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row",minWidth: "max-content" }}>
                        {functionalityAccess("btnDefaultCostingView", currentTab) ? (
                            <Tab
                              icon={<Icon>calendar_view_month</Icon>}
                              label="Default"
                              onClick={defaultCostingViewBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnCostingView", currentTab) ? (
                            <Tab
                              icon={<Icon>calendar_view_month</Icon>}
                              label="Costing"
                              onClick={costingViewBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnFinalCostingView", currentTab) ? (
                            <Tab
                              icon={<Icon>calendar_view_month</Icon>}
                              label="Final Costing"
                              onClick={finalCostingView}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkStyleActivityView ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "7px", marginLeft: "7px" }}
                    />
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Style Activity View</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" ,minWidth: "max-content"}}>
                          {functionalityAccess("btnStyleActivityView", currentTab) ? (
                            <Tab
                              icon={<Icon>calendar_view_month</Icon>}
                              label="Active"
                              onClick={activeStyleActivityFilterBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnStyleActivityView", currentTab) ? (
                            <Tab
                              icon={<Icon>calendar_view_month</Icon>}
                              label="Dropped"
                              onClick={droppedStyleActivityFilterBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnStyleActivityView", currentTab) ? (
                            <Tab
                              icon={<Icon>calendar_view_month</Icon>}
                              label="All"
                              onClick={allStyleActivityFilterBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </Tabs>
            </MDBox>
          </MDBox>
          <div
            className="quick-filter-container"
            style={{ display: "flex", width: "50%", alignItems: "stretch" }}
          >
            <input
              type="text"
              placeholder="Quick search in active tab...."
              value={quickFilterText}
              onChange={onQuickFilterChange}
              ref={quickFilterInputRef}
              style={{ padding: "2px", marginTop: "3px", fontSize: "10px", width: "100%" }}
            />
          </div>
          {/* <MDBox color={light ? "white" : "inherit"} style={{ marginRight: "5px" }}>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenNotificationsMenu}
            >
              <StyledBadge
                badgeContent={(actionMsg && actionMsg.length > 0) ? (<span>{actionMsg.length}</span>) : null}
                size="small"
                color="info"
              >
                <Icon sx={iconsStyle}>notifications_none_icon</Icon>
              </StyledBadge>
            </IconButton>
            {renderNotificationMenu()}
          </MDBox> */}
          <MDBox color={light ? "white" : "inherit"}>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={handleOpenMenu}
            >
              <Icon sx={iconsStyle}>account_circle</Icon>
            </IconButton>
            {renderMenu()}
          </MDBox>
          <MDBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </MDBox>
        </MDBox>
        <MDBox
          bgColor={transparent ? "white" : "transparent"}
          shadow={transparent ? "lg" : "none"}
          borderRadius="md"
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <DefaultNavbarMobile
              assortBtnCall={assortBtnCall}
              btnCarryOver={btnCarryOver}
              btnSavePreference={btnSavePreference}
              calculateSizesBtnCall={calculateSizesBtnCall}
              costingViewBtnCall={costingViewBtnCall}
              defaultCostingViewBtnCall={defaultCostingViewBtnCall}
              downloadBtn={downloadBtn}
              downloadLinelistBtnCall={downloadLinelistBtnCall}
              finalCostingView={finalCostingView}
              generateItemUpload={generateItemUpload}
              getChangesBtnCall={getChangesBtnCall}
              highlightStyle={highlightStyle}
              importDataBtnCall={importDataBtnCall}
              onQuickFilterChange={onQuickFilterChange}
              open={mobileNavbar}
              quickFilterInputRef={quickFilterInputRef}
              quickFilterText={quickFilterText}
              redownloadTabBtnCall={redownloadTabBtnCall}
              sendChangeMemoBtnCall={sendChangeMemoBtnCall}
              sendChangesBtnCall={sendChangesBtnCall}
              ShowChangesBtnCall={ShowChangesBtnCall}
              upload_btn_click={upload_btn_click}
            />
          )}
        </MDBox>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Report Date Range"
        content={notificationMessage}
        dateTime=""
        open={showNotification}
        onClose={() => setShowNotification(false)}
        close={() => setShowNotification(false)}
      />
      <Dialog
        open={openSkuDlg}
      >
        <DialogTitle sx={{ paddingTop: "5px", paddingBottom: "5px", textAlign: "center" }}>{"Linelist : " + clickedMsg.msg}</DialogTitle>
        <DialogContent sx={{ paddingBottom: "12px" }}>
          <TableContainer component={Paper} sx={{ maxWidth: 400, margin: 'auto', marginTop: 2 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Season Code</TableCell>
                  <TableCell>{decodeAndReturnField(clickedMsg.cell_changes, "Season Code")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Department</TableCell>
                  <TableCell>{decodeAndReturnField(clickedMsg.cell_changes, "Department")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>{decodeAndReturnField(clickedMsg.cell_changes, "Category")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>{decodeAndReturnField(clickedMsg.cell_changes, "SKU")}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "5px" }}>
            <span>{clickedMsg.follow_up_actions}</span>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: "8px" }}>
          <MDButton
            size="small" variant="gradient" color="info" sx={{ textTransform: "none" }}
            onClick={() => handleSkuClose()}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
});
DefaultNavbar.defaultProps = {
  brand: "Material Dashboard PRO",
  transparent: false,
  light: false,
};
export default DefaultNavbar;
