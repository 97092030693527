/**
=========================================================
* Competitor Analytics - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Competitor Analytics components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, LinearProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { afterOtpVerifiedNevigateTo, applicationTitle, customerBrandLogoPath, restApiPath } from "utils/app.props";
// import pageRoutes from "page.routes";

function Otp() {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpHelperText, setOtpHelterText] = useState("");
    const [showError, setShowError] = useState(false);
    const nevigate = useNavigate();
    const [diableOtp, setDisableOtp] = useState(false);
    const [diableCancel, setDisableCancel] = useState(false);
    const [diableResend, setDisableResend] = useState(true);
    const [showPassword, setShowPassword] = useState(true);

    const onSetResult = (result) => {
        const res = [result];
        const response = res[0];
        if (response.response === false) {
            setOtpHelterText(response.message);
            setShowError(true);
            setDisableOtp(false);
            setLoading(false);
            setDisableCancel(false);
            if (response.message === "OTP is expired. Please try again.") {
                setDisableOtp(true);
                setDisableResend(false);
                setOtp("");
                // setShowError(false);
            }else if (response.message !== "Verification code not matched.") {
                setTimeout(() => {
                    nevigate("/login");
                    setLoading(false);
                }, 5000);
            }
        } else {
            sessionStorage.setItem("isVerified", "yes");
            setLoading(false);

            nevigate("/" + afterOtpVerifiedNevigateTo, { replace: true });
        }
    };

    const verifyOtp = () => {
        if (otp.length === 0) {
            setOtpHelterText("OTP required");
            setShowError(true);
            setLoading(false);
            return;
        }
        if (showError) {
            return;
        }
        setLoading(true);
        setDisableOtp(true);
        setDisableCancel(true);

        fetch(`${restApiPath}verifyOTP`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                otpCode: otp.trim(),
                username: sessionStorage.getItem("email"),
                processName: sessionStorage.getItem("otpmode"),
            }),
        })
            .then((response) => response.json())
            .then((result) => onSetResult(result));
    };

    const resendOtp = () => {
        setLoading(true);
        setDisableOtp(true);
        setDisableCancel(true);

        fetch(`${restApiPath}setOTP`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: sessionStorage.getItem("email"),
                processName: "login",
                filter: applicationTitle,
            }),
        })
            .then((response1) => response1.json())
            .then((result1) => {
                const response = [result1];
                if (response[0].response === false) {
                    setOtpHelterText(response.message);
                    setShowError(true);
                } else if (response[0].response === true) {
                    setDisableOtp(false);
                    setDisableResend(true);
                    setDisableCancel(false);
                }
                setLoading(false);
            });
    }

    const onCancel = () => {
        sessionStorage.clear();
        nevigate("/login");
    }
    const onOtpChange = (evt) => {
        const txt = evt.target.value;
        setShowError(false);
        setOtp(txt);
    };

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                verifyOtp();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [otp]);

    return (
        <CoverLayout>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography mt={1}>
                        <img src={customerBrandLogoPath} height="50px" alt={applicationTitle} />
                        <MDTypography display="block" variant="h4" fontWeight="medium" color="white" my={1}>
                            {applicationTitle}
                        </MDTypography>
                    </MDTypography>
                    {/* <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Please Enter Verification Code
                    </MDTypography> */}
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        Please Enter Verification Code
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                inputRef={input => input && input.focus()}
                                error={showError}
                                success={!showError}
                                label="OTP"
                                value={otp}
                                variant="standard"
                                fullWidth
                                placeholder=""
                                InputLabelProps={{ shrink: true }}
                                helperText={otpHelperText}
                                onChange={onOtpChange}
                                onPaste={() => setShowError(false)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MDBox>
                        <MDBox mt={4} mb={1} ml={5} display="flex" justifyContent="space-between" width="80%">
                            <MDButton
                                variant="gradient"
                                color="info"

                                onClick={verifyOtp}
                                disabled={diableOtp}
                            >
                                Verify
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="secondary"
                                onClick={onCancel}
                                disabled={diableCancel}
                            >
                                Cancel
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="info"
                                onClick={resendOtp}
                                disabled={diableResend}
                            >
                                Resend
                            </MDButton>
                        </MDBox>
                        <MDBox sx={{ width: "100px", position: "absolute", left: "30%" }}>
                            {loading ? <LinearProgress color="info" variant="indeterminate" /> : null}
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default Otp;