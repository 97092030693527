import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import Icon from "@mui/material/Icon";
// import MDBox from "components/MDBox";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { setMiniSidenav /* , setOpenConfigurator */, setSidenavColor, useMaterialUIController } from "context";
import Sidenav from "examples/Sidenav";
import 'react-toastify/dist/ReactToastify.css';
import routes from "routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isSideBarEnabled } from "utils/app.props";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    layout,
    /* openConfigurator, */
    sidenavColor,
    darkMode,
  } = controller;

  const { pathname } = useLocation();

  // Cache for the rtl
  useEffect(() => {
    setMiniSidenav(dispatch, true);
  }, []);

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    setSidenavColor(dispatch, "white")
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  /* const configsButton = (
     <MDBox
       display="flex"
       justifyContent="center"
       alignItems="center"
       width="3.25rem"
       height="3.25rem"
       bgColor="white"
       shadow="sm"
       borderRadius="50%"
       position="fixed"
       right="2rem"
       bottom="2rem"
       zIndex={99}
       color="dark"
       sx={{ cursor: "pointer" }}
       onClick={handleConfiguratorOpen}
     >
       <Icon fontSize="small" color="inherit">
         settings
       </Icon>
     </MDBox>
   ); */

return(
  <ThemeProvider theme={darkMode ? themeDark : theme}>
    <CssBaseline />
    {layout === "dashboard" && isSideBarEnabled && (
      <>
        <Sidenav
          color={sidenavColor}
        />
      </>
    )}
    <ToastContainer />
    <Routes>
      {getRoutes(routes)}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  </ThemeProvider>
);
}
