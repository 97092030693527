import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import PropTypes from "prop-types";
const DashboardCard = ({ count,title, subTitle }) => {

    return (
        <DefaultStatisticsCard
            title={title}
            count={count}
            percentage={{
                color: "success",
                value: "",
                label: subTitle,
            }}
        />
    )
}
DashboardCard.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired
};
export default DashboardCard;