import MDBox from "components/MDBox";
import PropTypes from "prop-types";

function SendDataStatistics({table_name}) {

    return (
        <MDBox>
            <div style={{ color: 'black' }}>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                    <div style={{ width: '33%', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ whiteSpace: 'nowrap', backgroundColor: 'black', color: 'white', paddingRight: '10px', paddingLeft: '10px' }}>Updated Cells</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Cell_Updates_send')}</span>
                        </div>
                    </div>
                    <div style={{ width: '33%', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ whiteSpace: 'nowrap', backgroundColor: 'black', color: 'white', paddingRight: '10px', paddingLeft: '10px' }}>New Rows</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'New_Rows_send')}</span>
                        </div>
                    </div>
                    <div style={{ width: '33%', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ whiteSpace: 'nowrap', backgroundColor: 'black', color: 'white', paddingRight: '10px', paddingLeft: '10px' }}>Deleted Rows</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Delete_Rows_send')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </MDBox>
    )
}
SendDataStatistics.prototype = {
    table_name: PropTypes.string.isRequired,
}
export default SendDataStatistics;
