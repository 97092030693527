// Charts.jsx
import React from 'react';

const Charts = ({ value, maxValue, minValue,color }) => {
    let barPercentage = 0;
    let redVisible = "hidden"
    let grVisible = "hidden"
    
    if (value > 0){
        barPercentage = Math.abs(value / maxValue * 100)
        redVisible = "hidden"
        grVisible = "visible"
    }else{
        barPercentage = Math.abs(value / minValue * 100)
        grVisible = "hidden"
        redVisible = "visible"
    }
    // console.log(barPercentage+"...."+maxValue+"..."+minValue)
    return (
        <div className="bar-container" style={{ width: '100%', display: "flex" }}>
            <div style={{ float: "left", width: "50%", textAlign: "right" }}>
                <div
                    className="bar"
                    style={{
                        width: `${barPercentage}%`,
                        backgroundColor: color,
                        height: '22px',
                        borderRadius: '0px',
                        alignItems: 'center',
                        visibility:redVisible
                    }}
                >
                    <div style={{ zIndex: "100", textAlign: "left",visibility:redVisible }}>
                        {value}
                    </div>
                </div>
            </div>
            <div style={{ float: "right", width: "50%", textAlign: "left" }}>
                <div
                    className="bar"
                    style={{
                        width: `${barPercentage}px`,
                        backgroundColor: color,
                        height: '22px',
                        borderRadius: '0px',
                        alignItems: 'center',
                        visibility:grVisible
                    }}
                >
                    <div style={{ zIndex: "100", textAlign: "left",visibility:grVisible }}>
                        {value}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Charts;