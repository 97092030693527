import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Search } from '@mui/icons-material';
import { Autocomplete, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { AgGridReact } from "ag-grid-react";
import { CH1 } from 'layouts/linelist/XL_Utils';
import { CH3 } from 'layouts/linelist/XL_Utils';
import { CH2 } from 'layouts/linelist/XL_Utils';
import { getDatePicker, getExcelStyle, toastMessage } from 'layouts/linelist/XL_Utils';
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { restApiPath } from 'utils/app.props';

const tbl_name = "xl_faherty_linelist";

const CarryOver = ({ setCarryOverIdsSeason, removeCarryOverIdsSeason }) => {
    const dispatch = useDispatch(); //to update redux state variable
    const [downloadedSeasons, setDownloadedSeasons] = useState([]);
    const [downloadedDepartment, setDownloadedDepartment] = useState("");
    const [selectedSeason, setSelectedSeason] = useState("");
    
    // const colors = ['#8fecf0', '#e6ccff', '#ccccff', '#ffcccc', '#ffffe6'];

    const colors = ['#e5f9fc', '#f9f3ff', '#e0e0ff', '#fff3f3', '#ffffe6'];

    let colorIndex = 0;

    const [CarryOverData, setCarryOverData] = useState([]);
    const [CarryOverColDef, setCarryOverColDef] = useState([
        { field: "row_id", hide: true },
        { field: "Season Code", headerName: "Season Code", rowDrag: true, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, editable: true, cellStyle: { 'background-color': "#FFFFFF" } },
        {
            field: "Year", headerName: "Year",
            cellStyle: params => {

                return { 'background-color': params.node.data.color }
            },
            filter: "agSetColumnFilter"
        },
        {
            field: "SKU", headerName: "SKU", cellStyle: params => {

                return { 'background-color': params.node.data.color }
            }, filter: "agSetColumnFilter"
        },
        {
            field: "Style Description", headerName: "Style Description", cellStyle: params => {

                return { 'background-color': params.node.data.color }
            }, filter: "agSetColumnFilter"
        },
        {
            field: "Color Description", headerName: "Color Description", cellStyle: params => {

                return { 'background-color': params.node.data.color }
            }, filter: "agSetColumnFilter"
        },
        {
            field: "Department", headerName: "Department", cellStyle: params => {

                return { 'background-color': params.node.data.color }
            }, filter: "agSetColumnFilter"
        },
        {
            field: "Category", headerName: "Category", cellStyle: params => {

                return { 'background-color': params.node.data.color }
            }, filter: "agSetColumnFilter"
        },
        { field: "color", hide: true },
    ]);

    const [isGridReady, setIsGridReady] = useState(false);
    const gridApi = useRef({});
    const eLeftGrid = useRef(null);
    const eRightGrid = useRef(null);
    const nevigate = useNavigate();

    const [LinelistData, setLineListData] = useState([]);
    const [LinelistColDef, setLinelistColDef] = useState([
        { field: "row_id", hide: true },
        { field: "Year", hide: true },
        { field: "Season Code", headerName: "Most Recent Season", rowDrag: true, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, cellStyle: { 'background-color': '#F0F0F0' }, filter: "agSetColumnFilter" },
        { field: "SKU", headerName: "SKU", cellStyle: { 'background-color': '#F0F0F0' }, filter: "agSetColumnFilter" },
        { field: "Style Description", headerName: "Style Description", cellStyle: { 'background-color': '#F0F0F0' }, filter: "agSetColumnFilter" },
        { field: "Color Description", headerName: "Color Description", cellStyle: { 'background-color': '#F0F0F0' }, filter: "agSetColumnFilter" },
        { field: "Department", headerName: "Department", cellStyle: { 'background-color': '#F0F0F0' }, filter: "agSetColumnFilter" },
        { field: "Category", headerName: "Category", cellStyle: { 'background-color': '#F0F0F0' }, filter: "agSetColumnFilter" },
        { field: "Carry Over", headerName: "Carry Over", cellStyle: { 'background-color': '#F0F0F0' }, hide: true },
    ]);

    const gridApiRight = useRef({});
    const [isGridReadyRight, setIsGridReadyRight] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");
    const [enableSearch, setEnableSearch] = useState(true);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    useEffect(() => {
        window.history.pushState(null, "Linelist", window.location.href);
        window.addEventListener("popstate", () => {
            window.history.pushState(null, document.title, window.location.href);
        });

        function handleResize() { setWindowDimensions(getWindowDimensions()); }
        window.addEventListener('resize', handleResize);

        const asRequiredDownloadQuery = sessionStorage.getItem("asRequiredDownloadQuery");
        const seasons = [];
        const dept = [];
        if (asRequiredDownloadQuery && asRequiredDownloadQuery !== "") {
            const qry = asRequiredDownloadQuery.split(CH3);
            qry.map((row) => {
                const rw = row.split(CH2);
                const season = rw[1].split(CH1);
                const department = rw[2].split(CH1);

                console.log("department", department)
                seasons.push(season[1]);
                dept.push(department[1]);
            })

            setDownloadedSeasons(Array.from(new Set(seasons)));
            setDownloadedDepartment(Array.from(new Set(dept)));
        }
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onGridReady = (params) => {
        gridApi.current = params.api

        params.api.autoSizeAllColumns();
        setIsGridReady(true)
    }

    const suppressHeaderKeyboardEvent = ({ event }) => {
        if (!event) return false;
        if (event.ctrlKey && event.key === "ArrowLeft") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
            const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
            if (element) element.focus();
            return true;
        } else if (event.ctrlKey && event.key === "ArrowRight") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const lastColumn = cols[cols.length - 1];
            // gridApi.current.setFocusedCell(0,lastColumn.colId,false)
            gridApi.current.ensureColumnVisible(lastColumn.colId);
            const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
            if (element) {
                element.focus();
                return true;
            }
        } else if (event.ctrlKey && event.key === "ArrowDown") {
            const column = tmpColDef.filter((col) => col.headerName === event.srcElement.innerText);
            gridApi.current.ensureIndexVisible(CarryOverData.length - 1);
            gridApi.current.setFocusedCell(CarryOverData.length - 1, column[0].colId);
            return true;
        }
        return false;
    };

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent' },
                // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
                // { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
            ],
        };
    }, []);
    const defaultColDef = useMemo(() => {
        return { sortable: true, menuTabs: ['filterMenuTab', 'generalMenuTab'], wrapHeaderText: true, suppressHeaderKeyboardEvent }
    }, []);

    const gridOptions = useMemo(() => {
        return {
            processCellForClipboard: (params) => {
                let ct = params.column.colDef.myType || "";
                if (ct === "") return params.value
                else if (ct === "date" && !params.value.includes('/')) return ExcelDateToJsDate(params.value)
                else return params.value ? params.value.toString().replace(/[%$]/g, "") : params.value;
            },
        }
    }, []);

    const excelStyles = useMemo(() => { return getExcelStyle() }, []);
    const getRowId = (params) => String(params.data.row_id);

    const addRecordToGrid = (side, data) => {
        // if data missing or data has no it, do nothing
        if (!data || data.row_id == null) {
            return;
        }
        performCarryOver(data, "drag");
    };
    const addGridDropZone = (side, api) => {

        const dropSide = side === 'Left' ? 'Right' : 'Left';
        const dropZone = {
            getContainer: () => (dropSide === 'Right' ? eRightGrid.current : eLeftGrid.current),
            onDragStop: (dragParams) => {
                if (side === 'Left') addRecordToGrid(dropSide.toLowerCase(), dragParams.node.data)
                else if (side === 'Right') performCarryOverRevert(dragParams.node.data, "drag")
            },
        };
        api.addRowDropZone(dropZone);
    };
    const onGridReadyRight = (params) => {
        gridApiRight.current = params.api;
        params.api.autoSizeAllColumns();

        setIsGridReadyRight(true)
    }
    useEffect(() => {
        if (isGridReady && isGridReadyRight) {
            addGridDropZone('Right', gridApiRight.current);
            addGridDropZone('Left', gridApi.current);
        }
    });

    const rightGridChange = (event) => {
        const oldValue = event.oldValue || "";
        if (event.newValue === "" || !event.newValue) {
            const data = event.node.data;
            data["Year"] = "";
            data["Carry Over"] = "";
            console.log("data", data)
            removeCarryOverIdsSeason([data]);

            gridApiRight.current.refreshCells({ force: true, rowNodes: [event.node] });
            return;
        }
        if (event.newValue !== oldValue) {
            const data = event.node.data;
            const gridData = [];

            //add year
            const season = data["Season Code"];
            if (season && season !== "") {
                const lastDig = season.slice(-2);

                if (isNaN(lastDig)) {
                    toastMessage("Please enter a valid season.", "warning")
                    return;
                }
                data["Year"] = "20" + lastDig;
            }
            gridApiRight.current.forEachNode((node) => gridData.push(node.data))
            //check for duplicate in same
            const find = gridData.find((dt) => dt.row_id !== data.row_id && dt["Year"] === data["Year"] && dt["Season Code"] === data["Season Code"] &&
                dt["SKU"] === data["SKU"] && dt["Department"] === data["Department"] && dt["Category"] === data["Category"]);
            if (find) {
                const col = event.colDef.headerName;
                event.data[col] = event.oldValue;
                toastMessage("Duplicate Year Season Code found. Please enter unique values.", "warning")
                return;
            }
            if (data["Season Code"] !== "") {
                const findLl = LinelistData.find((dt) => dt.row_id === data.row_id);
                if (findLl) {
                    findLl["Carry Over"] = "Yes|" + data["Season Code"] + "|" + data["Year"];
                    setCarryOverIdsSeason(findLl);
                }
            } else {
                removeCarryOverIdsSeason([data]);
            }
            gridApiRight.current.refreshCells({ force: true, rowNodes: [event.node] });
        }
    }

    const performCarryOver = (data, from = "") => {
        // console.log("data", data)
        const api = gridApiRight.current;
        // do nothing if row is already in the grid, otherwise we would have duplicates

        const selectedNodes = gridApi.current.getSelectedRows();
        const transData = [];
        const updateData = [];

        if (selectedNodes.length === 0 && from === "") {
            toastMessage("Please select a row from left grid.", "warning");
            return;
        }

        const mData = [];
        gridApiRight.current.forEachNode((node) => mData.push(node.data))
        let transaction;

        if (selectedNodes && selectedNodes.length > 0) {
            selectedNodes.map((data) => {
                // const { data } = node;
                const check = mData.find((dt) => dt.row_id === data.row_id || (dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                    dt["Category"] === data["Category"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"]))

                if (check) {
                    toastMessage("Duplicate data detected.", "warning")
                    return;
                } else {
                    const transfetData = {};
                    LinelistColDef.map((col) => { if (col.headerName) transfetData[col.headerName] = "" })

                    data["Carry Over"] = "Yes";

                    transfetData["row_id"] = data["row_id"];
                    transfetData["SKU"] = data["SKU"];
                    transfetData["Season Code"] = "";
                    transfetData["Year"] = "";
                    transfetData["Department"] = data["Department"];
                    transfetData["Category"] = data["Category"];
                    transfetData["Style Description"] = data["Style Description"];
                    transfetData["Color Description"] = data["Color Description"];
                    transfetData["color"] = colors[colorIndex];

                    transData.push(transfetData);
                    updateData.push(data);
                    // rowIds.push({ "row_id": data.row_id })
                }
            })
        } else if (data) {
            const check = mData.find((dt) => dt.row_id === data.row_id || (dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                dt["Category"] === data["Category"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"]))
            if (check) {
                toastMessage("Duplicate data detected.", "warning")
                return;
            }
            const transfetData = {};
            LinelistColDef.map((col) => transfetData[col.headerName] = "")

            data["Carry Over"] = "Yes";

            transfetData["row_id"] = data["row_id"];
            transfetData["SKU"] = data["SKU"];
            transfetData["Season Code"] = data["Season Code"];
            transfetData["Department"] = data["Department"];
            transfetData["Category"] = data["Category"];
            transfetData["Style Description"] = data["Style Description"];
            transfetData["Color Description"] = data["Color Description"];
            transfetData["color"] = colors[colorIndex];

            transData.push(transfetData);
            updateData.push(data);
            // rowIds.push({ "row_id": data.row_id })
        }

        if (transData.length > 0) {
            colorIndex++;
            if (colorIndex > 4) colorIndex = 0;

            transaction = {
                add: transData,
                addIndex: 0
            };

            api.applyTransaction(transaction);
            gridApi.current.applyTransaction({ update: updateData });

        }
        gridApi.current.deselectAll();
    }
    const performCarryOverRevert = (data, from) => {
        // console.log("data", data)
        const api = gridApi.current;
        // do nothing if row is already in the grid, otherwise we would have duplicates

        const selectedNodes = gridApiRight.current.getSelectedRows();
        const transData = [];
        const updateData = [];

        if (selectedNodes.length === 0 && from === "") {
            toastMessage("Please select a row from right grid.", "warning");
            return;
        }

        const mData = [];
        gridApi.current.forEachNode((node) => mData.push(node.data))
        let transaction;
        let status = true;

        if (selectedNodes && selectedNodes.length > 0) {
            selectedNodes.map((data) => {
                // const { data } = node;
                const check = mData.find((dt) => dt.row_id === data.row_id)

                if (check) {
                    const transfetData = check;
                    transfetData["Carry Over"] = "";

                    transData.push(transfetData);
                    updateData.push(data);

                } else {
                    status = false;
                }
            })
        } else if (data) {
            const check = mData.find((dt) => dt.row_id === data.row_id)

            if (check) {
                const transfetData = check;
                transfetData["Carry Over"] = "";
                transData.push(transfetData);
                updateData.push(data);
            } else {
                status = false;
            }
        }
        if (!status) {
            toastMessage("No matches found for the selected data.", "warning")
        } else if (transData.length > 0) {
            transaction = {
                update: transData,
            };
            removeCarryOverIdsSeason(updateData)
            api.applyTransaction(transaction);
            gridApiRight.current.applyTransaction({ remove: updateData });
        }
        gridApiRight.current.deselectAll();
    }
    const onChangeSearch = (e) => {
        console.log("value", e.target.value)
        setSearchQuery(e.target.value);
        if (e.target.value !== "") setEnableSearch(false)
        else setEnableSearch(true)
    }
    const getSearchedData = async () => {
        if (searchQuery === "") {
            toastMessage("Please enter some input in search bar.", "warning")
            return;
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const colList = [];
        LinelistColDef.map((col) => col.field !== "Carry Over" ? colList.push('"' + col.field + '"') : "");
        const postBody = {
            "param_function": "ca_search_in_table",
            "param_body": {
                "column_list": colList.join(","),
                "table_name": tbl_name,
                "search_text": searchQuery.replaceAll(" ", ","),
                "department": downloadedDepartment.length > 0 ? downloadedDepartment.join(","): ""
            }
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();

            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                const data = JSON.parse(json.data);
                setLineListData(data);
                setCarryOverData([])
                // toastMessage("Pd_Initial_Costing to Merch_Assort_Sandbox assortment completed successfully!", "success");
            } else {
                toastMessage("There is an error while getting data.", "warning");
            }
            setTimeout(() => {
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }, 1000);

        } catch (exception) {
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
        }
    }
    const onChangeSeason = (event, newValue) => {
        if (gridApiRight.current.getDisplayedRowCount() === 0) {
            toastMessage("Please move rows from the left panel first.", "warning");
            setSelectedSeason("");
            return;
        }

        gridApiRight.current.forEachNode((node) => {
            node.setDataValue("Season Code", newValue)
        })
        setSelectedSeason(newValue);
    }
    const tabPanelHeight = 280;
    return (
        <>
            <Grid container alignItems="center" >
                <Grid item xs={5.5} mb={1} mt={1}>
                    <TextField
                        id="search-bar"
                        fullWidth
                        className="text"
                        onInput={(e) => {
                            onChangeSearch(e)
                        }}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                getSearchedData();
                            }
                        }}
                        label="Search"
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        helperText="Use space for multi search. Press Enter to Search."
                        sx={{
                            // Root class for the input field
                            "& .MuiOutlinedInput-root": {
                                // Class for the border around the input field
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#8E8E8E",
                                    borderWidth: "2px",
                                },
                            },
                        }}
                    />
                </Grid>

                <Grid item xs={0.5} pb={2}>
                    <IconButton type="submit" aria-label="search" disabled={enableSearch} color='info' onClick={getSearchedData}>
                        <Search />
                    </IconButton>
                </Grid>
                <Grid item xs={4.5} pb={2}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={downloadedSeasons}
                        value={selectedSeason}
                        onChange={onChangeSeason}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Carry Over Season Code" size="small" />}
                    />
                </Grid>
                <Grid item xs={5.8}>
                    <div className="ag-theme-quartz" id="agGridStl" ref={eLeftGrid} style={{ height: windowDimensions.height - tabPanelHeight }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            statusBar={statusBar}
                            suppressFieldDotNotation
                            onGridReady={(event) => onGridReady(event)}
                            rowSelection="multiple"
                            enableFillHandle={true}
                            allowContextMenuWithControlKey={true}
                            gridOptions={gridOptions}
                            enableColResize={true}
                            columnDefs={LinelistColDef}
                            rowData={LinelistData}
                            suppressRowClickSelection={false}
                            enterNavigatesVertically={true}
                            enterNavigatesVerticallyAfterEdit={true}
                            components={{ Datepicker: getDatePicker() }}
                            suppressHorizontalScroll={false}
                            suppressMenuHide={true}
                            // headerHeight={HeaderHeight}
                            defaultColDef={defaultColDef}
                            excelStyles={excelStyles}
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            rowDragMultiRow={true}
                            getRowId={getRowId}
                            rowMultiSelectWithClick={true}
                            enableCheckBox={true}
                        />
                    </div>
                </Grid>
                <Grid item xs={0.3}>
                    <Tooltip title="Carry Over season from Linelist">
                        <IconButton onClick={() => performCarryOver(null)}> <KeyboardDoubleArrowRight fontSize='large' /> </IconButton>
                    </Tooltip>
                    <Tooltip title="Revert">
                        <IconButton onClick={() => performCarryOverRevert(null)}> <KeyboardDoubleArrowLeft fontSize='large' /> </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={5.88} >
                    <div className="ag-theme-quartz" id="agGridStl1" ref={eRightGrid} style={{ height: windowDimensions.height - tabPanelHeight }}>
                        <AgGridReact
                            enableRangeSelection={true}
                            suppressFieldDotNotation
                            onGridReady={(event) => onGridReadyRight(event)}
                            rowSelection="multiple"
                            onCellValueChanged={rightGridChange}
                            columnDefs={CarryOverColDef}
                            rowData={CarryOverData}
                            suppressRowClickSelection={false}
                            enterNavigatesVertically={true}
                            enterNavigatesVerticallyAfterEdit={true}
                            suppressHorizontalScroll={false}
                            suppressMenuHide={true}
                            enableFillHandle={true}
                            defaultColDef={defaultColDef}
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            statusBar={statusBar}
                            getRowId={getRowId}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}
export default CarryOver;