import { executeDataVerification, toastMessage } from "layouts/linelist/XL_Utils";
import { GetAutoChanges, compareAndHighLightDataChanges } from "layouts/linelist/getChangesComman";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomAgGrid from "../CustomAgGrid";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";

const tbl_name = "xl_faherty_delivery_master";
const rng_name = "Delivery_Master_Rng";

const DeliveryMasterTab = forwardRef(({ setCurrentGridApi, setActionItemDataFun }, ref) => {
  const dispatch = useDispatch();
  const DeliveryMaster = useSelector((s) => s.DeliveryMasterData);
  const DeliveryMasterCache = useSelector((s) => s.DeliveryMasterDataSSN);
  const varHighlistCell = useSelector((s) => s.DeliveryMasterHighlistCell);
  const varColDefs = useSelector((s) => s.DeliveryMasterColDef || []);
  const varSorting = useSelector((s) => s.DeliveryMasterSorting || []);
  const varFilters = useSelector((s) => s.DeliveryMasterFilters || {});
  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);
  const [autoChangeState, setAutoChangeState] = useState(false);
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const [isGridReady, setIsGridReady] = useState(false);
  const setResetFilter = (s) => {
    gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
    gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!DeliveryMaster) return false;
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return res;
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return { status: true };
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(
        masterData,
        rng_name,
        tbl_name,
        DeliveryMasterColDef,
        gridApi.current,
        cellValueChanged.current,
        highlightCells.current,
        metaData
      );
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        dispatch({ type: "DELIVERY_MASTER_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true });
      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(
        data,
        highlightCells.current,
        gridApi.current,
        cellValueChanged.current,
        tbl_name,
        downloadType
      );
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    },
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) {
      gridApi.current.refreshCells({ force: true });
    }
  };
  useEffect(() => {
    if (isGridReady) {
      setTimeout(() => {
        if (varSorting !== null && varSorting !== undefined) {
          gridApi.current.applyColumnState({ state: varSorting });
        }
        if (varFilters !== null && varFilters !== undefined) {
          gridApi.current.setFilterModel(varFilters);
        }
      }, 500);
    }
  }, [isGridReady]);
  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    setIsGridReady(true);
  };
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  };
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_DELIVERY_MASTER_SORTING", data: savedSortState });
  };
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_DELIVERY_MASTER_FILTERS", data: savedFilterModel });
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  };
  return (
    <CustomAgGrid
      autoChangeState={autoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      columnDefs={varColDefs}
      filters={varFilters}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      resteAutoChangeState={resteAutoChangeState}
      rowData={DeliveryMaster}
      setActionItemDataFun={setActionItemDataFun}
      setCellValueChanged={setCellValueChanged}
      setCurrentGridApi={onGridReady}
      SSNData={DeliveryMasterCache}
      tab_name="Delivery_Master"
      tbl_name={tbl_name}
      tblHighlightCells={varHighlistCell}
    />
  );
});

DeliveryMasterTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  setActionItemDataFun: PropTypes.func.isRequired,
};

export default DeliveryMasterTab;
