import { xlRound } from 'layouts/linelist/XL_Calculation';
import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_whsl_buy_sheet_nord_rp";
const rng_name = "Whsl_Buy_Sheet_Nord_RP_Rng";

const WhslBuySheetNordRpTab = forwardRef(({ setCurrentGridApi, settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {
  const dispatch = useDispatch();
  // let allCol = useSelector(s => s.testColLst[0][tbl_name]);
  const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
  const [isGridReady, setIsGridReady] = useState(false);
  const WhslBuySheetNordRpData = useSelector(state => state.WhslBuySheetNordRpData);
  const SSNData = useSelector((s) => s.WhslBuySheetNordRpDataSSN);
  const WhslBuySheetNordHighlistCell = useSelector(state => state.WhslBuySheetNordHighlistCell);

  const tmpColDef = useSelector(state => state.WhslBuySheetNordRpColDef ? state.WhslBuySheetNordRpColDef : []);

  const WhslBuySheetNordRpSorting = useSelector(state => state.WhslBuySheetNordRpSorting ? state.WhslBuySheetNordRpSorting : []);
  const WhslBuySheetNordRpFilters = useSelector(state => state.WhslBuySheetNordRpFilters ? state.WhslBuySheetNordRpFilters : {});

  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);

  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

  const setResetFilter = (setReset) => {
    let sorting = WhslBuySheetNordRpSorting
    let filtering = WhslBuySheetNordRpFilters
    if (setReset === "reset") {
      sorting = null
      filtering = null
    }
    gridApi.current.applyColumnState({ state: sorting })
    gridApi.current.setFilterModel(filtering);
  }
  //Required for all tabs (For before send data functionalities)
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!WhslBuySheetNordRpData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      cellValueChanged.current = [];
      removeHeihlight();
      return ({ status: true })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells
        dispatch({ type: "SET_WHSL_BY_SHT_NRD_SSN_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");
      }
      return res
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
  };

  useEffect(() => {
    if (isGridReady) {
      if (WhslBuySheetNordRpSorting !== null && WhslBuySheetNordRpSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: WhslBuySheetNordRpSorting
        })
      }
      if (WhslBuySheetNordRpFilters !== null && WhslBuySheetNordRpFilters !== undefined) {
        gridApi.current.setFilterModel(WhslBuySheetNordRpFilters);
      }
    }
  }, [isGridReady])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params)
    calculateAllFormulas();

    setIsGridReady(true)
  }

  const calculateWhslGmPercent = (event) => {
    const WHLS = parseFloat(event.data["WHLS"] || 0);
    const LDP = parseFloat(event.data["LDP"] || 0);
    return WHLS === 0 ? 0 : xlRound(((WHLS - LDP) / WHLS).toFixed(10), 6);
  };
  const calculateRpUsTotalUnits = (event) => {
    return xlRound(parseFloat(event.data["RP US Nords Doors"] || 0) * parseFloat(event.data["Avg Per Door - US"] || 0), 0);
  };
  const calculateRpIntTotalUnits = (event) => {
    return xlRound(parseFloat(event.data["RP International Nords Doors"] || 0) * parseFloat(event.data["Avg Per Door - Int"] || 0), 0);
  };
  const calculateTotalNordsReplenUnits = (event) => {
    return parseInt(event.data["RP US Total Units"] || 0) + parseInt(event.data["RP Int Total Units"] || 0);
  };
  const calculateTotalRpUsReceipts = (event) => {
    return xlRound((parseFloat(event.data["WHLS"] || 0) * parseFloat(event.data["RP US Total Units"] || 0)).toFixed(10), 6);
  };
  const calculateTotalRpIntReceipts = (event) => {
    return xlRound((parseFloat(event.data["WHLS"] || 0) * parseFloat(event.data["RP Int Total Units"] || 0)).toFixed(10), 6);
  };
  const calculateTotalRpReceiptsWhsl = (event) => {
    return xlRound((parseFloat(event.data["Total RP US Receipts"] || 0) + parseFloat(event.data["Total RP Int. Receipts"] || 0)).toFixed(10), 6);
  };
  const calculateTotalRpReceiptsMsrp = (event) => {
    return xlRound((parseFloat(event.data["MSRP"] || 0) * parseFloat(event.data["Total Nords Replen Units"] || 0)).toFixed(10), 6);
  };
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    fxcalc("WHSL GM%") ? event.data["WHSL GM%"] = calculateWhslGmPercent(event) : null;
    fxcalc("RP US Total Units") ? event.data["RP US Total Units"] = calculateRpUsTotalUnits(event) : null;
    fxcalc("RP Int Total Units") ? event.data["RP Int Total Units"] = calculateRpIntTotalUnits(event) : null;
    fxcalc("Total Nords Replen Units") ? event.data["Total Nords Replen Units"] = calculateTotalNordsReplenUnits(event) : null;
    fxcalc("Total RP US Receipts") ? event.data["Total RP US Receipts"] = calculateTotalRpUsReceipts(event) : null;
    fxcalc("Total RP Int. Receipts") ? event.data["Total RP Int. Receipts"] = calculateTotalRpIntReceipts(event) : null;
    fxcalc("Total RP Receipts WHSL") ? event.data["Total RP Receipts WHSL"] = calculateTotalRpReceiptsWhsl(event) : null;
    fxcalc("Total RP Receipts MSRP") ? event.data["Total RP Receipts MSRP"] = calculateTotalRpReceiptsMsrp(event) : null;
  };

  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_WHSL_BY_SHT_NRD_SORTING", data: savedSortState });
  }
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_WHSL_BY_SHT_NRD_FILTERS", data: savedFilterModel });
  }
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      filters={WhslBuySheetNordRpFilters}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="Whsl_Buy_Sheet_Nord_RP"
      tbl_name={tbl_name}
      columnDefs={tmpColDef}
      rowData={WhslBuySheetNordRpData}
      SSNData={SSNData}
      tblHighlightCells={WhslBuySheetNordHighlistCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      isGrouppedHeader={true}
    />
  )
})

WhslBuySheetNordRpTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,
};
export default WhslBuySheetNordRpTab;