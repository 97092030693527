import AssessmentIcon from '@mui/icons-material/Assessment';
import TableViewIcon from '@mui/icons-material/TableView';
import { Autocomplete, Badge, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Grow, InputLabel, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Client } from "@stomp/stompjs";
import { AgCharts } from "ag-charts-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { Buffer } from 'buffer';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { setAsRequiredDoenloadQuery, setCurrentTab, setOpenConfigurator, useMaterialUIController } from "context";
import Configurator from "examples/Configurator";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { restApiPath, utcDifference, webScoketUrl } from "utils/app.props";
import "../linelist/styleGrid.css";
import { xlRound } from './XL_Calculation';
import {
    CH1,
    CH2,
    CH3,
    CH4,
    CH5,
    CH6,
    CH7,
    GetRowFilterBasedOnTableName,
    GetTableDataLocale,
    JsDateToExcelDateNumber,
    createAsRequiredDownloadQuery,
    dateFilterParams,
    dateValueFormatter,
    doubleQuote,
    editableCriteriaColumns,
    executeDataVerification,
    formatDateToMmDdYyyyHhMmSsAmPm,
    getColumnDefByTblName,
    getColumnDropDownValue,
    getHighlightByTblName,
    getPivotConfig,
    getTableAsRequired,
    getUserData,
    ignoreColumnsInCacheCompare,
    prepareSummaryReportData,
    toastMessage
} from "./XL_Utils";
import ActionItemTab from "./components/ActionItemTab";
import Airtable_Mapping_Data from "./components/Airtable_Mapping_Data";
import AuditTrailList from "./components/AuditTrailList";
import buttonAccess from "./components/Buttons/buttonAccess";
import BuySheetMasterSizingPercentageTab from "./components/BuySheetMasterSizingPercentageTab";
import BuyShtMasterDataChartsTab from "./components/BuyShtMasterDataChartsTab";
import CarryOver from "./components/CarryOver";
import ChaseTab from "./components/ChaseTab";
import CompApsMasterStoreEcomTab from "./components/CompApsMasterStoreEcomTab";
import DeliveryMasterTab from "./components/DeliveryMasterTab";
import DtcBuySheetTab from "./components/DtcBuySheetTab";
import FbFtyDatabaseTab from "./components/FbFtyDatabaseTab";
import FreightByCategoryTab from "./components/FreightByCategoryTab";
import FreightMasterTab from "./components/FreightMasterTab";
import GetChangesStatistics from "./components/GetChangesStatistics";
import ImportData from "./components/ImportData";
import LinelistTab from "./components/LinelistTab";
import SummarizedProductionData from "./components/LinelistTab/SummarizedProductionData";
import ManagePermissionTab from "./components/ManagePermissionTab";
import ManageUsersTab from "./components/ManageUsersTab";
import MasterLovTab from "./components/MasterLovTab";
import MerchAssortmentSandbox from "./components/MerchAssortmentSandbox";
import Merch_Linelist_Assortment_Workflow from "./components/Merch_Linelist_Assortment_Workflow";
import PDInitialCostingTab from "./components/PDInitialCostingTab";
import PD_Assortment_Workflow from "./components/PD_Assortment_Workflow";
import PivotTab from "./components/PivotTab";
import SeasonInquiryTab from "./components/SeasonInquiryTab";
import SeasonTab from "./components/SeasonTab";
import SendDataStatistics from "./components/SendDataStatistics";
import ShopifyProductTypeMappingTab from "./components/ShopifyProductTypeMappingTab";
import SizeScalesTab from "./components/SizeScalesTab";
import SizingTab from "./components/SizingTab";
import SummaryReportTab from "./components/SummaryReportTab";
import TabPanel, { a11yProps } from "./components/TabPanel";
import WhslBuySheetNordRpTab from "./components/WhslBuySheetNordRpTab";
import WhslBuySheetTab from "./components/WhslBuySheetTab";
import { getFromMetaData } from "./customFunctions";
import { GetAutoChangesForHiddenTabs, GetSortOrder, GettingRequestedData, getSessionDataByTblName, getStateDataByTblName, resetUnsentCount, setHighlightByTblName, setSessionDataByTblName, setStateDataByTblName } from "./getChangesComman";
import DashboardLayoutBasic from "../linelist/helps"

// const aid_info_mp = new Map([["Tab_Name", "Action Item"], ["Table_Name", "xl_action_item_details"], ["Rng_Name", "Action_Item_Rng"]])
const ssn_mp = new Map([["Tab_Name", "Seasons"], ["Table_Name", "xl_faherty_season"], ["Rng_Name", "Seasons_Rng"]])
const linelist_mp = new Map([["Tab_Name", "Linelist"], ["Table_Name", "xl_faherty_linelist"], ["Rng_Name", "Linelist_Rng"]])
const pd_initial_costing_mp = new Map([["Tab_Name", "PD_Initial_Costing"], ["Table_Name", "xl_pd_initial_costing"], ["Rng_Name", "PD_Initial_Costing_Rng"]])
const chase_mp = new Map([["Tab_Name", "Chase"], ["Table_Name", "xl_faherty_chase"], ["Rng_Name", "Chase_Rng"]])
const dtc_buy_sheet_mp = new Map([["Tab_Name", "DTC_Buy_Sheet"], ["Table_Name", "xl_faherty_dtc_buy_sheet"], ["Rng_Name", "Dtc_Buy_Sheet_Rng"]])
const whsl_buy_sheet_mp = new Map([["Tab_Name", "Whsl_Buy_Sheet"], ["Table_Name", "xl_faherty_whsl_buy_sheet"], ["Rng_Name", "Whsl_Buy_Sheet_Rng"]])
const whsl_buy_sheet_nord_rp_mp = new Map([["Tab_Name", "Whsl_Buy_Sheet_Nord_RP"], ["Table_Name", "xl_faherty_whsl_buy_sheet_nord_rp"], ["Rng_Name", "Whsl_Buy_Sheet_Nord_RP_Rng"]])
const sizing_mp = new Map([["Tab_Name", "Sizing"], ["Table_Name", "xl_faherty_sizing"], ["Rng_Name", "Sizing_Rng"]])
const buy_sheet_master_sizing_percentage_mp = new Map([["Tab_Name", "Buy_Sheet_Master_Sizing_%"], ["Table_Name", "xl_faherty_buy_sheet_master_sizing_percentage"], ["Rng_Name", "Buy_Sheet_Master_Sizing_Rng"]])
const buySht_master_data_charts_mp = new Map([["Tab_Name", "BuySht_Master_Data_Charts"], ["Table_Name", "xl_faherty_buy_sheet_master_data_charts"], ["Rng_Name", "BuySht_Master_Data_Charts_Rng"]])
const comp_aps_master_storeecom_mp = new Map([["Tab_Name", "Comp_APS_Master_StoreEcom"], ["Table_Name", "xl_faherty_comp_aps_master_stores_ecomm"], ["Rng_Name", "Comp_APS_Master_StoreEcom_Rng"]])
const freight_master_mp = new Map([["Tab_Name", "Freight_Master"], ["Table_Name", "xl_faherty_freight_kg_rates"], ["Rng_Name", "Freight_Master_Rng"]])
const freight_by_category_mp = new Map([["Tab_Name", "Freight_by_Category"], ["Table_Name", "xl_faherty_freight_by_category"], ["Rng_Name", "Freight_by_Category_Rng"]])
const delivery_master_mp = new Map([["Tab_Name", "Delivery_Master"], ["Table_Name", "xl_faherty_delivery_master"], ["Rng_Name", "Delivery_Master_Rng"]])
const fb_fty_database_mp = new Map([["Tab_Name", "FB_Fty_Database"], ["Table_Name", "xl_faherty_fb_fty_database"], ["Rng_Name", "Fb_Fty_Database_Rng"]])
const size_scales_mp = new Map([["Tab_Name", "Size_Scales"], ["Table_Name", "xl_faherty_size_scales"], ["Rng_Name", "Size_Scales_Rng"]])
const shopify_product_type_mapping_mp = new Map([["Tab_Name", "Shopify_Product_Type_Map"], ["Table_Name", "xl_faherty_shopify_product_type_mapping"], ["Rng_Name", "Shopify_Product_Type_Mapping_Rng"]])
const season_inquiry_mp = new Map([["Tab_Name", "Season_Inquiry"], ["Table_Name", "xl_faherty_season_inquiry"], ["Rng_Name", "Season_Inquiry_Rng"]])
const master_lov_mp = new Map([["Tab_Name", "Master_LOV"], ["Table_Name", "xl_faherty_master_lov"], ["Rng_Name", "Master_Lov_Rng"]])
const aid_info_mp = new Map([["Tab_Name", "Action_Item"], ["Table_Name", "xl_action_item_details"], ["Rng_Name", "Action_Item_Rng"]])
const sr_info_mp = new Map([["Tab_Name", "Summary_Report"], ["Table_Name", "Summary_Report"], ["Rng_Name", "Summary_Report_Rng"]])
const pvt_info_mp = new Map([["Tab_Name", "Pivot"], ["Table_Name", "Pivot"], ["Rng_Name", "Pivot_Rng"]])
const manage_user = new Map([["Tab_Name", "Manage_User"], ["Table_Name", "app_user"], ["Rng_Name", "User_Rng"]])
const manage_permission = new Map([["Tab_Name", "Manage_Permission"], ["Table_Name", "xl_user_data_access"], ["Rng_Name", "User_Rng"]])
const ll_import_mp = new Map([["Tab_Name", "Linelist_Import"], ["Table_Name", "Linelist_Import"], ["Rng_Name", "Linelist_Import_Rng"]])
const sz_import_mp = new Map([["Tab_Name", "Sizing_Import"], ["Table_Name", "Sizing_Import"], ["Rng_Name", "Sizing_Import_Rng"]])
const merch_assortment_sandbox_mp = new Map([["Tab_Name", "Merch_Assortment_Sandbox"], ["Table_Name", "xl_faherty_merch_assortment_sandbox"], ["Rng_Name", "Merch_Assortment_Sandbox_Rng"]])
const pd_in_cost_import_mp = new Map([["Tab_Name", "PD_Initial_Costing_Import"], ["Table_Name", "PD_Initial_Costing_Import"], ["Rng_Name", "PD_Initial_Costing_Import_Rng"]])
const comp_aps_import_mp = new Map([["Tab_Name", "Comp_APS_Master_StoreEco_Import"], ["Table_Name", "Comp_APS_Master_StoreEco_Import"], ["Rng_Name", "Comp_APS_Master_StoreEco_Import_Rng"]])
const merch_assortment_sandbox_import_mp = new Map([["Tab_Name", "Merch_Assortment_Sandbox_Import"], ["Table_Name", "Merch_Assortment_Sandbox_Import"], ["Rng_Name", "Merch_Assortment_Sandbox_Import_Rng"]])
const air_table_mapping_mp = new Map([["Tab_Name", "Airtable_Mapping_Data"], ["Table_Name", "xl_faherty_airtable_mapping_data"], ["Rng_Name", "Airtable_Mapping_Data_Rng"]])

const tabList = [
    [ssn_mp.get("Tab_Name"), ssn_mp.get("Table_Name"), ssn_mp.get("Rng_Name")],
    [pd_initial_costing_mp.get("Tab_Name"), pd_initial_costing_mp.get("Table_Name"), pd_initial_costing_mp.get("Rng_Name")],
    [merch_assortment_sandbox_mp.get("Tab_Name"), merch_assortment_sandbox_mp.get("Table_Name"), merch_assortment_sandbox_mp.get("Rng_Name")],
    [linelist_mp.get("Tab_Name"), linelist_mp.get("Table_Name"), linelist_mp.get("Rng_Name")],
    [chase_mp.get("Tab_Name"), chase_mp.get("Table_Name"), chase_mp.get("Rng_Name")],
    [dtc_buy_sheet_mp.get("Tab_Name"), dtc_buy_sheet_mp.get("Table_Name"), dtc_buy_sheet_mp.get("Rng_Name")],
    [whsl_buy_sheet_mp.get("Tab_Name"), whsl_buy_sheet_mp.get("Table_Name"), whsl_buy_sheet_mp.get("Rng_Name")],
    [whsl_buy_sheet_nord_rp_mp.get("Tab_Name"), whsl_buy_sheet_nord_rp_mp.get("Table_Name"), whsl_buy_sheet_nord_rp_mp.get("Rng_Name")],
    [sizing_mp.get("Tab_Name"), sizing_mp.get("Table_Name"), sizing_mp.get("Rng_Name")],
    [sr_info_mp.get("Tab_Name"), sr_info_mp.get("Table_Name"), sr_info_mp.get("Rng_Name")],
    [buy_sheet_master_sizing_percentage_mp.get("Tab_Name"), buy_sheet_master_sizing_percentage_mp.get("Table_Name"), buy_sheet_master_sizing_percentage_mp.get("Rng_Name")],
    [buySht_master_data_charts_mp.get("Tab_Name"), buySht_master_data_charts_mp.get("Table_Name"), buySht_master_data_charts_mp.get("Rng_Name")],
    [comp_aps_master_storeecom_mp.get("Tab_Name"), comp_aps_master_storeecom_mp.get("Table_Name"), comp_aps_master_storeecom_mp.get("Rng_Name")],
    [freight_master_mp.get("Tab_Name"), freight_master_mp.get("Table_Name"), freight_master_mp.get("Rng_Name")],
    [freight_by_category_mp.get("Tab_Name"), freight_by_category_mp.get("Table_Name"), freight_by_category_mp.get("Rng_Name")],
    [delivery_master_mp.get("Tab_Name"), delivery_master_mp.get("Table_Name"), delivery_master_mp.get("Rng_Name")],
    [fb_fty_database_mp.get("Tab_Name"), fb_fty_database_mp.get("Table_Name"), fb_fty_database_mp.get("Rng_Name")],
    [size_scales_mp.get("Tab_Name"), size_scales_mp.get("Table_Name"), size_scales_mp.get("Rng_Name")],
    [shopify_product_type_mapping_mp.get("Tab_Name"), shopify_product_type_mapping_mp.get("Table_Name"), shopify_product_type_mapping_mp.get("Rng_Name")],
    [season_inquiry_mp.get("Tab_Name"), season_inquiry_mp.get("Table_Name"), season_inquiry_mp.get("Rng_Name")],
    [master_lov_mp.get("Tab_Name"), master_lov_mp.get("Table_Name"), master_lov_mp.get("Rng_Name")],
    [air_table_mapping_mp.get("Tab_Name"), air_table_mapping_mp.get("Table_Name"), air_table_mapping_mp.get("Rng_Name")],
    [aid_info_mp.get("Tab_Name"), aid_info_mp.get("Table_Name"), aid_info_mp.get("Rng_Name")],
    [pvt_info_mp.get("Tab_Name"), pvt_info_mp.get("Table_Name"), pvt_info_mp.get("Rng_Name")],
    [manage_user.get("Tab_Name"), manage_user.get("Table_Name"), manage_user.get("Rng_Name")],
    [manage_permission.get("Tab_Name"), manage_permission.get("Table_Name"), manage_permission.get("Rng_Name")],
    [ll_import_mp.get("Tab_Name"), ll_import_mp.get("Table_Name"), ll_import_mp.get("Rng_Name")],
    [pd_in_cost_import_mp.get("Tab_Name"), pd_in_cost_import_mp.get("Table_Name"), pd_in_cost_import_mp.get("Rng_Name")],
    [sz_import_mp.get("Tab_Name"), sz_import_mp.get("Table_Name"), sz_import_mp.get("Rng_Name")],
    [comp_aps_import_mp.get("Tab_Name"), comp_aps_import_mp.get("Table_Name"), comp_aps_import_mp.get("Rng_Name")],
    [merch_assortment_sandbox_import_mp.get("Tab_Name"), merch_assortment_sandbox_import_mp.get("Table_Name"), merch_assortment_sandbox_import_mp.get("Rng_Name")],
];
const tabForDropDown = [master_lov_mp.get("Tab_Name"), fb_fty_database_mp.get("Tab_Name"), ssn_mp.get("Tab_Name")];
const importDataTabs = [ll_import_mp.get("Table_Name"), pd_in_cost_import_mp.get("Table_Name"), sz_import_mp.get("Table_Name"), comp_aps_import_mp.get("Table_Name"), merch_assortment_sandbox_import_mp.get("Table_Name")];
let myTabValue = 0;
const tabPadding = { paddingRight: "15px", paddingLeft: "15px" }
const closeXcss = { background: "none", border: "none", paddingLeft: "10px" };

function Linelist() {
    //reduct state to get state variable
    const reduxState = useSelector((state) => state);
    const dispatch = useDispatch(); //to update redux state variable
    // const [, dispatchMui] = useMaterialUIController();
    const [controller, dispatchMui] = useMaterialUIController();
    const { openConfigurator, currentTab } = controller;
    // const MasterLOVData = useSelector(state => state.MasterLOVData);
    // const FbFtyDatabaseData = useSelector(state => state.FbFtyDatabaseData);
    const DeliveryMasterUnsentCount = useSelector(state => state.DeliveryMasterUnsentCount);
    const refDtcBuySheet = useSelector(state => state.DtcBuySheetData);
    const refWhslBuySheet = useSelector(state => state.WhslBuySheetData);
    const refBuySheetMasterSizingPercentage = useSelector(state => state.BuySheetMasterSizingPercentageData);
    const LinelistColDef = useSelector(state => state.LinelistColDef);
    const LinelistData = useSelector(state => state.LinelistData);
    const SizingData = useSelector(state => state.SizingData);
    const nevigate = useNavigate();
    const [tabValue, setValue] = useState(0);
    const [mySavePrefAlert, setMySavePrefAlert] = useState(false);
    const [custColPref, setCustColPref] = useState([]);
    const ref = useRef();
    const [blSendDataStatistics, setBlSendDataStatistics] = useState(false);
    const [uploadDocument, setUploadDocument] = useState(false);
    const [blReceiveDataStatistics, setBlReceiveDataStatistics] = useState(false);
    const [ActiveUserData, setActiveUserData] = useState([]);
    const [srcForAiData, setSrcForAiData] = useState([]);
    const [blActionItem, setBlActionItem] = useState(false);
    const [emailActionItem, setEmailActionItem] = useState("");
    const [aiTxtTask, setAiTxtTask] = useState("");
    // const [isActionItemUpdate, setisActionItemUpdate] = useState(false);
    const [actionItemAssigned, setActionItemAssigned] = useState([]);
    const [actionItemRowId, setActionItemRowId] = useState("");
    // const [createNewActionItem, setCreateNewActionItem] = useState(false);
    const [actionItemAssignedTasklist, setActionItemAssignedTasklist] = useState({});
    const [actionItemTaskTitlelist, setActionItemTaskTitlelist] = useState({});
    const actionItemTabName = useRef("");
    const [dataImportOpen, setDataImportOpen] = useState(false);
    const [importDataFromSheet, setImportDataFromSheet] = useState("");
    const [openHelpDialog, setOpenHelpDialog] = useState(false);
    const [showGenie, setShowGenie] = useState(false);
    const [openTTDDialog, setOpenTTDDialog] = useState(false);
    const [summaryView, setSummaryView] = useState(false);
    const ttdData = useRef([]);
    const ttdPopupDetail = useRef("");
    const [ttdPopupData, setTtdPopupData] = useState([]);
    const [ttdCards, setTtdCards] = useState([]);
    const [ttdView, setTtdView] = useState([]);
    const [DropDownColumn, setDropDownColumn] = useState([]);
    const [DQField, setDQField] = useState([]);
    const [DQColDropdown, setDQColDropdown] = useState({ "user_id": sessionStorage.getItem("email"), "table_name": "" });
    const [DQView, setDQView] = useState([]);
    const [DQData, setDQData] = useState([]);
    const [openAssortDialog, setOpenAssortDialog] = useState(false);
    const [pdAssortedRowIds, setPdAssortedRowIds] = useState([]);
    const [pdRevertedRowIds, setPdRevertedRowIds] = useState([]);
    const [openLlAssortDialog, setOpenLlAssortDialog] = useState(false);
    const [llAssortedRowIds, setllAssortedRowIds] = useState([]);
    const [openCarryOverDg, setOpenCarryOverDg] = useState(false);
    const [carryOverData, setCarryOverData] = useState([]);
    const ActionItemData = useSelector((state) => state.ActionItemData);
    const isAiAssignee = useRef(false);
    const gridApi = useRef({});
    const gridColumnApi = useRef({})
    const myRef = useRef();
    const merchToPdMsg1Ref = useRef();
    const [notificationData, setNotificationData] = useState([]);
    const createNewActionItem = useRef(false);
    const isActionItemUpdate = useRef(false);
    const cntDl = useRef(0);
    const myShipmentRef = useRef();
    const metaData = useRef([]);
    const [metaDataDownloaded, setMetaDataDownloaded] = useState(false);
    const [columnMetaData, setColumnMetaData] = useState([]);
    const [actionItemTableName, setActionItemTableName] = useState("");
    // const isDownloadLinelistPerformed = useRef(false);
    const [priorityValue, setpriorityValue] = useState('High');
    const [priorityError, setpriorityError] = useState(false);
    const [statusValue, setStatusValue] = useState('Open');
    const [remarksValue, setremarkValue] = useState('');
    const [aiTitleValue, setaiTitleValue] = useState("");
    const [aiStartDateValue, setaiStartDateValue] = useState("");
    const [aiEndDateValue, setaiEndDateValue] = useState("");
    const ll_gridApi = useRef({});
    //show changes variales
    const [closeShowChanges, setCloseShowChanges] = useState('none')
    const [activeTab, setActiveTab] = useState(tabList[0][0])
    const [resetAuditTrail, setResetAuditTrail] = useState(false);
    const [showChase, setShowChase] = useState(false);
    const [showSizing, setShowSizing] = useState(false);
    const [showLinelist, setShowLinelist] = useState(false);
    const [showDtc, setShowDtc] = useState(false);
    const [showWhsl, setShowWhsl] = useState(false);
    const [showManageUser, setShowManageUser] = useState(false);
    const llTabPvtData = useSelector(s => s.llTabPvtData);
    const [quickFilterText, setQuickFilterText] = useState('');
    const quickFilterInputRef = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [visiblerows, setVisiblerows] = useState('');

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    useEffect(() => {
        window.history.pushState(null, "Linelist", window.location.href);
        window.addEventListener("popstate", () => {
            window.history.pushState(null, document.title, window.location.href);
        });
        function handleResize() { setWindowDimensions(getWindowDimensions()); }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 2,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
            fontWeight: 400,
            fontSize: "10px"
        },
    }));
    const isConnected = useRef(false)
    const SOCKET_URL = webScoketUrl + 'ws-message?access_token=' + sessionStorage.getItem("access_token");;
    const getTabNameByTblName = (tbl_name) => {
        let tabName = ""
        tabList.map((element) => {
            if (element[1] === tbl_name) tabName = element[0]
        })
        // console.log(tabName)
        return tabName
    }
    const onConnected = () => {
        isConnected.current = true
        console.log("Connected!!")
        client.subscribe("/user/" + sessionStorage.getItem("email") + "/userAssistantLevel1/merchToPdMsg1", (greeting) => {
            const res = greeting.body;
            // console.log("userAssistantLevel1/merchToPdMsg1 -> JSON.parse(res) -> ", JSON.parse(res));
            const message = JSON.parse(res);
            const nd = JSON.parse(message.message);
            console.log("userAssistantLevel1/merchToPdMsg1 -> nd -> ", nd);
            // merchToPdMsg1Ref.current.handleMerchToPdMsg1(nd);
            setNotificationData(nd);
        });
        client.subscribe("/user/" + sessionStorage.getItem("email") + "/topic/message", (greeting) => {
            console.log("changes from server")
            const res = greeting.body;
            if (res) {
                const changes = JSON.parse(res);
                const msg = changes.message;
                const content = JSON.parse(msg)
                const { table_name } = content
                let { cell_changes } = content
                const tab_name = tabList[myTabValue][0];
                const checkAccess = tab_name ? tabPermission(tab_name) : false;
                const data = Buffer.from(cell_changes, 'base64').toString('utf8')
                console.log("table: " + table_name + "...." + tabList[myTabValue][1] + "..." + checkAccess)
                console.log(data)
                const downloadType = getDataFromMetaData(table_name, 'download_type')
                // console.log("downloadType: " + downloadType)
                if (checkAccess && (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') && downloadType.toLowerCase() !== "default") {
                    console.log("Please download linelist first")
                    // toastMessage("Auto Changes detected. Please Download Linelist from Season tab.");
                    return
                }
                setResetAuditTrail(false)
                // set data for hidden tabs
                if (tabList[myTabValue][1].trim() !== table_name.trim()) {
                    const tabName = getTabNameByTblName(table_name)
                    if (!checkTabPermission(tabName)) return;
                    const result = GetAutoChangesForHiddenTabs(data, table_name, downloadType)

                    if (result.status && table_name !== "xl_action_item_details" && checkAccess) {
                        toastMessage("Auto Changes detected for the " + tabName + " Tab. Please refer tab for changes.");
                    }
                } else {
                    setResetAuditTrail(true)
                    if (myRef.current && checkAccess) myRef.current.getAutoChanges(data, downloadType)
                }
            }
        });
    };
    const onDisconnected = () => {
        console.log("Disconnected!!")
        isConnected.current = false
    };
    const client = new Client({
        brokerURL: SOCKET_URL,
        reconnectDelay: 5000,
        heartbeatIncoming: 2000,
        heartbeatOutgoing: 2000,
        onConnect: onConnected,
        onDisconnect: onDisconnected
    });
    const setCurrentGridApi = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        if (quickFilterText) {
            gridApi.current.setGridOption("quickFilterText", quickFilterText);
        }
    };
    const myValueFormatter = function (params, fldNm, ct) {
        if (!params?.node?.data) {
            // console.log("params ", params)
            return
        }
        let value = params.value
        if (fldNm === "Department" && value !== undefined && value !== null && value.trim() !== "") return ProperCase(value);
        else if (fldNm === "Season Code" && value && value !== "") return value.toUpperCase();
        if (params.node.data.row_id === "total" && typeof (value) === "number") return parseFloat(value).toFixed(2) + "%";
        else if (
            ct !== null &&
            ct !== undefined &&
            ct !== "" &&
            params.value !== null &&
            params.value !== undefined &&
            params.value !== "" &&
            params.value !== true &&
            params.value !== false &&
            params.value.toString().toLowerCase() !== "true" &&
            params.value.toString().toLowerCase() !== "false" &&
            params.value.toString().toLowerCase() !== "na") {
            if (ct === "price") {
                const formattedNumber = parseFloat(params.value).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                return "$" + formattedNumber;
            } else if (ct === "qty") {
                let num = 0.0
                num = Math.round(params.value);
                return num.toLocaleString();
            } else if (ct.includes("decimal")) {
                let decimalPoints = 2;
                if (ct === "1decimal") decimalPoints = 1;
                else if (ct === "4decimal") decimalPoints = 4;
                else if (ct === "0decimal") decimalPoints = 0;
                else if (ct === "7decimal") decimalPoints = 7;
                const num = parseFloat(params.value).toFixed(decimalPoints)
                return num.toLocaleString();
            } else if (ct === "percentage") {
                if (value.toString().includes(".")) return parseFloat(params.value * 100).toFixed(2) + "%";
                else {
                    if (params.value > 0.99) return parseFloat(params.value * 100).toFixed(2) + "%";
                    else return parseFloat(params.value).toFixed(2) + "%";
                }
            }
            else return params.value;
        }
        return value;
    };
    const myValueParser = function (params, ct) {
        let newVal = params.newValue
        if (ct !== null &&
            ct !== undefined &&
            ct !== "" &&
            params.newValue !== null &&
            params.newValue !== undefined &&
            params.newValue !== "" &&
            params.newValue !== true &&
            params.newValue !== false &&
            params.newValue.toString().toLowerCase() !== "true" &&
            params.newValue.toString().toLowerCase() !== "false" &&
            params.newValue.toString().toLowerCase() !== "na") {
            if (ct === "percentage") {
                if (params.oldValue !== newVal) return newVal / 100 + ""; // return (parseFloat(newVal) / 100).toFixed(6) + ""
                else return params.oldValue;
            } else return newVal;
        } else return newVal;
    };
    function ProperCase(str) {
        if (!str) return ""
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    };
    const saveSortingAndFiltering = () => {
        let req_tbl = tabList[tabValue][1]
        // console.log("saveSortingAndFiltering -> req_tbl")
        // console.log(req_tbl)
        if (req_tbl === null || req_tbl === undefined || req_tbl.trim() === "") {
            return
        }
        if (req_tbl === sr_info_mp.get("Table_Name") || req_tbl === pvt_info_mp.get("Table_Name")) {
            return
        }
        const colState = gridApi.current.getColumnState();
        if (!colState) return;
        let savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        const savedFilterModel = gridApi.current.getFilterModel();
        if (ssn_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SEASON_SORTING", data: savedSortState });
            dispatch({ type: "SET_SEASON_FILTERS", data: savedFilterModel });
        } else if (linelist_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_LINELIST_SORTING", data: savedSortState });
            dispatch({ type: "SET_LINELIST_FILTERS", data: savedFilterModel });
            dispatch({ type: "llTabPvtData", data: llTabPvtData });
        } else if (master_lov_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_MASTER_LOV_SORTING", data: savedSortState });
            dispatch({ type: "SET_MASTER_LOV_FILTERS", data: savedFilterModel });
        } else if (season_inquiry_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SEASON_INQUIRY_SORTING", data: savedSortState });
            dispatch({ type: "SET_SEASON_INQUIRY_FILTERS", data: savedFilterModel });
        } else if (shopify_product_type_mapping_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SHOPIFY_TYPE_MAP_SORTING", data: savedSortState });
            dispatch({ type: "SET_SHOPIFY_TYPE_MAP_FILTERS", data: savedFilterModel });
        } else if (size_scales_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SIZE_SCALE_SORTING", data: savedSortState });
            dispatch({ type: "SET_SIZE_SCALE_FILTERS", data: savedFilterModel });
        } else if (fb_fty_database_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_FB_FTY_SORTING", data: savedSortState });
            dispatch({ type: "SET_FB_FTY_FILTERS", data: savedFilterModel });
        } else if (chase_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_CHASE_SORTING", data: savedSortState });
            dispatch({ type: "SET_CHASE_FILTERS", data: savedFilterModel });
        } else if (dtc_buy_sheet_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_DTC_BY_SHT_SORTING", data: savedSortState });
            dispatch({ type: "SET_DTC_BY_SHT_FILTERS", data: savedFilterModel });
        } else if (whsl_buy_sheet_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_WHSL_BY_SHT_SORTING", data: savedSortState });
            dispatch({ type: "SET_WHSL_BY_SHT_FILTERS", data: savedFilterModel });
        } else if (whsl_buy_sheet_nord_rp_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_WHSL_BY_SHT_NRD_SORTING", data: savedSortState });
            dispatch({ type: "SET_WHSL_BY_SHT_NRD_FILTERS", data: savedFilterModel });
        } else if (sizing_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SIZING_SORTING", data: savedSortState });
            dispatch({ type: "SET_SIZING_FILTERS", data: savedFilterModel });
        } else if (buy_sheet_master_sizing_percentage_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_BUY_SHT_SIZE_PER_SORTING", data: savedSortState });
            dispatch({ type: "SET_BUY_SHT_SIZE_PER_FILTERS", data: savedFilterModel });
        } else if (delivery_master_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_DELIVERY_MASTER_SORTING", data: savedSortState });
            dispatch({ type: "SET_DELIVERY_MASTER_FILTERS", data: savedFilterModel });
        } else if (freight_by_category_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_FRIGHT_BY_CAT_SORTING", data: savedSortState });
            dispatch({ type: "SET_FRIGHT_BY_CAT_FILTERS", data: savedFilterModel });
        } else if (freight_master_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_FRIGHT_MASTER_SORTING", data: savedSortState });
            dispatch({ type: "SET_FRIGHT_MASTER_FILTERS", data: savedFilterModel });
        } else if (comp_aps_master_storeecom_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_COMP_APS_MASTER_SORTING", data: savedSortState });
            dispatch({ type: "SET_COMP_APS_MASTER_FILTERS", data: savedFilterModel });
        } else if (buySht_master_data_charts_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_BUY_SHT_SIZE_CHART_SORTING", data: savedSortState });
            dispatch({ type: "SET_BUY_SHT_SIZE_CHART_FILTERS", data: savedFilterModel });
        } else if (manage_user.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_ManageUser_FILTERS", data: savedFilterModel });
            dispatch({ type: "SET_ManageUser_SHORTING", data: savedSortState });
        } else if (manage_permission.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_ManagePermission_FILTERS", data: savedFilterModel });
            dispatch({ type: "SET_ManagePermission_SHORTING", data: savedSortState });
        }
    };
    const getVisibleRows = () => {
        const api = gridApi.current;
        if (api) {
            const visibleRowNodes = api.getRenderedNodes();
            const visibleRows = visibleRowNodes.map(node => node.data.row_id);
            const visibleRowsString = visibleRows.join('|');
            return visibleRowsString;
        }
        return "";
    };

    const handleChange = (event, newValue) => {
        saveSortingAndFiltering();
        myTabValue = newValue
        const tab_name = tabList[newValue][0];
        if (tab_name === aid_info_mp.get("Tab_Name") || tab_name === sr_info_mp.get("Tab_Name") || tab_name === pvt_info_mp.get("Tab_Name")) setCloseShowChanges('none')
        setActiveTab(tab_name)
        setCurrentTab(dispatchMui, tab_name);
        setValue(newValue);
    };
    const changeTab = (tab) => {
        saveSortingAndFiltering();
        //get tab index
        const index = tabList.findIndex((tb) => tb[0] === tab)
        myTabValue = index
        setActiveTab(tab)
        setValue(index);
    };
    const upload_btn_click = () => {
        let req_tbl = tabList[tabValue][1]
        if (req_tbl === null || req_tbl === undefined || req_tbl.trim() === "") {
            return
        }
        if (chase_mp.get("Table_Name") !== req_tbl) {
            toastMessage("Please perform Attachment from 'Chase' Tab.");
            return
        }
        setUploadDocument(true)
    };
    const importDataBtnCall = () => {
        // console.log('IN -> importDataBtnCall')
        const curTab = tabList.filter((tab) => tab[0] === activeTab)
        // console.log("curTab -> ", curTab)
        const tab_name = curTab[0][0]
        const req_tbl = curTab[0][1]
        // console.log("tab_name -> ", tab_name)
        // console.log("req_tbl -> ", req_tbl)
        const tmpVal = getDataFromMetaData(req_tbl, 'transfer_data_from_sheet')
        // console.log("tmpVal -> ", tmpVal)
        if (tmpVal === null || tmpVal === undefined || tmpVal.trim() === "") {
            toastMessage(("Import is disabled for '" + tab_name + "' tab."), "info")
            return
        }
        setImportDataFromSheet(tmpVal)
        setDataImportOpen(true)
    };
    const downloadLinelistBtnCall = () => {
        if (tabValue !== 0) {
            toastMessage("Please perform Download Linelist from 'Season' tab.");
            return
        }
        if (!gridApi.current) {
            toastMessage("Opps! Something went wrong.", "error");
            return;
        }
        let selectedRows = gridApi.current.getSelectedRows()
        if (selectedRows === null || selectedRows === undefined || selectedRows.length === 0) {
            toastMessage("Please select atleast 1 row to download data.");
        }
        else {
            dispatch({ type: "SET_PLEASE_WAIT", data: true });
            if (buttonAccess("btnDownloadSizing", storedUseType))
                closeDownlodedTab("Sizing");
            if (buttonAccess("btnDownloadLinelist", storedUseType))
                closeDownlodedTab("Linelist");
            if (buttonAccess("btnDownloadDtc", storedUseType))
                closeDownlodedTab("DTC_Buy_Sheet");
            if (buttonAccess("btnDownloadWholesale", storedUseType))
                closeDownlodedTab("Wholesale");
            closeDownlodedTab("Chase");
            let asRequiredDownloadQuery = createAsRequiredDownloadQuery(selectedRows)
            sessionStorage.setItem('asRequiredDownloadQuery', asRequiredDownloadQuery)
            sessionStorage.setItem("isDownloadLinelistPerformed", true)
            SetTablesData("Ondemand", asRequiredDownloadQuery);
            setAsRequiredDoenloadQuery(dispatchMui, asRequiredDownloadQuery);
        }
    };
    function GetSortOrderRowId(prop) {
        return function (a, b) {
            if (parseInt(a[prop]) > parseInt(b[prop])) {
                return 1;
            } else if (parseInt(a[prop]) < parseInt(b[prop])) {
                return -1;
            }
            return 0;
        }
    }
    const SetTablesData = (downloadType, qry, downloadFor = "") => {
        let defaultDownloadTab = [];
        let showMessage = true;
        if (downloadFor === "dropdown") {
            showMessage = false;
            defaultDownloadTab = metaData.current.filter((tm) => tm.download_type === downloadType && tabForDropDown.includes(tm.sheet_name))
        } else defaultDownloadTab = metaData.current.filter((tm) => tm.download_type === downloadType && !tabForDropDown.includes(tm.sheet_name))
        let count = 0;
        // console.log("defaultDownloadTab", defaultDownloadTab)
        cntDl.current = defaultDownloadTab.length;
        if (downloadType === "Default") {
            for (let i = 0; i < importDataTabs.length; i++) {
                defaultDownloadTab.push({ "table_name": importDataTabs[i] })
            }
        }
        const downloadBtnList = [{ "xl_faherty_linelist": "btnDownloadLinelist" }, { "xl_faherty_dtc_buy_sheet": "btnDownloadDtc" }, { "xl_faherty_whsl_buy_sheet": "btnDownloadWholesale" },
        { "xl_faherty_sizing": "btnDownloadSizing" }, { "xl_faherty_chase": "btnDownloadChase" }, { "xl_faherty_whsl_buy_sheet_nord_rp": "btnDownloadWholesale" },
        { "app_user": "btnDownloadManageUser" }, { "xl_user_data_access": "btnDownloadManageUser" }];
        const userRole = sessionStorage.getItem("user_type");
        defaultDownloadTab.map((defTab) => {
            const table_name = defTab.table_name;
            const tab = tabList.filter((tb) => (tb[1] === table_name || importDataTabs.includes(tb[1])));
            if (tab.length === 0) {
                cntDl.current--;
                return;
            } //no tab configured
            //get config for tab
            const columnData = columnMetaData.filter((col) => col.table_name === table_name);
            if (columnData.length === 0) {
                cntDl.current--;
                return; //no column config found for tab
            }
            //prepare column defination as per column config
            count++;
            const columnDefination = [];
            columnData.map((column) => {
                const dataType = column.change_type;
                const column_name = column.column_name;
                let mpCol = {
                    field: column_name,
                    headerName: column_name,
                    colId: table_name + CH1 + column_name,
                    editable: column.is_editable,
                    width: column.min_width && column.min_width !== "" ? column.min_width : 125,
                    hide: column.hide && column.hide.toLocaleLowerCase() === "true" ? true : false,
                    validation: column.validation,
                    column_data_source: column.column_data_source,
                    is_formula_col: column.is_formula_col,
                    ignore_column_in_cache_compare: column.ignore_column_in_cache_compare,
                    conditional_format: column.conditional_format,
                    format: column.format,
                    row_highlight_rules: getDataFromMetaData(table_name, "row_highlight_rules"),
                    insert_editable: column.insert_editable && column.insert_editable !== "" ? true : false
                };
                //prepare dropdown if datasource available
                const { column_data_source } = column;
                if ((column.is_editable || column.insert_editable) && column_data_source && column_data_source !== "") {
                    const dropdown = {
                        type: "dropdown",
                        cellEditor: "agRichSelectCellEditor",
                        cellEditorPopup: true,
                        cellEditorParams: params => {
                            return {
                                values: getColumnDropDownValue(column_data_source),
                                filterList: true,
                                searchType: "match",
                                allowTyping: true,
                            }
                        },
                    };
                    Object.assign(mpCol, dropdown);
                } else if (dataType && dataType === "date") {
                    const datePicker = {
                        filter: 'agMultiColumnFilter',
                        filterParams: dateFilterParams,
                        cellEditor: "Datepicker",
                        type: dataType,
                        valueFormatter: dateValueFormatter,
                    }
                    Object.assign(mpCol, datePicker);
                } else {
                    const formatters = {
                        valueParser: params => myValueParser(params, dataType),
                        valueFormatter: params => myValueFormatter(params, column_name, dataType),
                        type: dataType
                    }
                    Object.assign(mpCol, formatters);
                }
                if (dataType === "qty" || dataType === "price" || dataType === "percentage" || dataType.includes("decimal")) {
                    mpCol = {
                        ...mpCol,
                        filter: 'agMultiColumnFilter',
                        filterParams: {
                            buttons: ['reset', 'apply'],
                            filters: [
                                {
                                    filter: 'agNumberColumnFilter',
                                },
                                {
                                    filter: 'agSetColumnFilter',
                                    filterParams: {
                                        valueFormatter: params => {
                                            let value = params.value;
                                            if (value === null || value === undefined || value === "") return value;
                                            else if (isNaN(value) || value === 'NaN') return "";
                                            else {
                                                if (dataType === "price") {
                                                    const formattedNumber = parseFloat(value).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    });
                                                    return "$" + formattedNumber;
                                                    // let num = 0.0
                                                    // num = value;
                                                    // return ("$" + parseFloat(num.toLocaleString()).toFixed(2));
                                                } else if (dataType === "qty") {
                                                    let num = 0.0
                                                    num = Math.round(value);
                                                    return num.toLocaleString();
                                                } else if (dataType.includes("decimal")) {
                                                    let decimalPoints = 2;
                                                    if (dataType === "1decimal") decimalPoints = 1;
                                                    else if (dataType === "4decimal") decimalPoints = 4;
                                                    else if (dataType === "0decimal") decimalPoints = 0;
                                                    else if (dataType === "7decimal") decimalPoints = 7;
                                                    const num = parseFloat(value).toFixed(decimalPoints)
                                                    return num.toLocaleString();
                                                } else if (dataType === "percentage") {
                                                    if (value.toString().includes(".")) return parseFloat(params.value * 100).toFixed(2) + "%"
                                                    else {
                                                        if (params.value > 0.99) return parseFloat(params.value * 100).toFixed(2) + "%"
                                                        else return parseFloat(params.value).toFixed(2) + "%"
                                                    }
                                                } else return value.toString();
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    }
                } else if (dataType === null || dataType === "" || dataType === "dropdown") {
                    mpCol = {
                        ...mpCol,
                        filter: 'agMultiColumnFilter',
                        filterParams: {
                            filters: [
                                {
                                    filter: 'agTextColumnFilter',
                                },
                                {
                                    filter: 'agSetColumnFilter',
                                    // filterParams: {}
                                }
                            ]
                        }
                    }
                }
                mpCol = {
                    ...mpCol,
                    suppressKeyboardEvent: params => {
                        if (params.event.ctrlKey && params.event.key === "ArrowUp") {
                            // console.log(params)
                            gridApi.current.ensureIndexVisible(0, "top");
                            const element = document.querySelector("div[col-id='" + params.column.colId + "']");
                            if (element) {
                                element.setAttribute('tabindex', '1');
                                element.focus();
                                const colElement1 = element.querySelector(".ag-header-cell-comp-wrapper");
                                const colElement2 = colElement1.querySelector(".ag-cell-label-container");
                                if (colElement2) {
                                    colElement2.focus()
                                    return true;
                                }
                            }
                            return true
                        } else if (!params.editing && editableCriteriaColumns.includes(column_name)) {
                            let isBackspaceKey = params.event.keyCode === 8
                            let isDeleteKey = params.event.keyCode === 46
                            if (isBackspaceKey || isDeleteKey) {
                                let colId = params.colDef.colId
                                let rowNode = params.node
                                rowNode.setDataValue(colId, "")
                                return true
                            }
                        }
                        return false
                    }
                }
                columnDefination.push(mpCol);
            })
            //set column defination as per table
            let varType = ""
            if (table_name === linelist_mp.get("Table_Name")) varType = "SET_LINELIST_COL_DEF";
            else if (table_name === chase_mp.get("Table_Name")) varType = "SET_CHASE_COL_DEF";
            else if (table_name === dtc_buy_sheet_mp.get("Table_Name")) varType = "SET_DTC_BY_SHT_COL_DEF";
            else if (table_name === whsl_buy_sheet_mp.get("Table_Name")) varType = "SET_WHSL_BY_SHT_COL_DEF";
            else if (table_name === whsl_buy_sheet_nord_rp_mp.get("Table_Name")) varType = "SET_WHSL_BY_SHT_NRD_COL_DEF";
            else if (table_name === sizing_mp.get("Table_Name")) varType = "SET_SIZING_COL_DEF";
            else if (table_name === buy_sheet_master_sizing_percentage_mp.get("Table_Name")) varType = "BUY_SHT_SIZE_PER_COL_DEF";
            else if (table_name === buySht_master_data_charts_mp.get("Table_Name")) varType = "BUY_SHT_SIZE_CHART_COL_DEF";
            else if (table_name === comp_aps_master_storeecom_mp.get("Table_Name")) varType = "COMP_APS_MASTER_COL_DEF";
            else if (table_name === freight_master_mp.get("Table_Name")) varType = "FRIGHT_MASTER_COL_DEF";
            else if (table_name === freight_by_category_mp.get("Table_Name")) varType = "FRIGHT_BY_CAT_COL_DEF";
            else if (table_name === delivery_master_mp.get("Table_Name")) varType = "DELIVERY_MASTER_COL_DEF";
            else if (table_name === ssn_mp.get("Table_Name")) varType = "SET_SEASON_COL_DEF";// default
            else if (table_name === fb_fty_database_mp.get("Table_Name")) varType = "FB_FTY_COL_DEF";
            else if (table_name === size_scales_mp.get("Table_Name")) varType = "SIZE_SCALE_COL_DEF";
            else if (table_name === shopify_product_type_mapping_mp.get("Table_Name")) varType = "SHOPIFY_TYPE_MAP_COL_DEF";
            else if (table_name === season_inquiry_mp.get("Table_Name")) varType = "SEASON_INQUIRY_COL_DEF";
            else if (table_name === master_lov_mp.get("Table_Name")) varType = "MASTER_LOV_COL_DEF";
            else if (table_name === aid_info_mp.get("Table_Name")) varType = "ACTION_ITEM_COL_DEF";
            else if (table_name === pd_initial_costing_mp.get("Table_Name")) varType = "SET_PDInitialCosting_COL_DEF";
            else if (table_name === ll_import_mp.get("Table_Name")) varType = "SET_Linelist_Import_COL_DEF";
            else if (table_name === pd_in_cost_import_mp.get("Table_Name")) varType = "SET_PD_Initial_Costing_Import_COL_DEF";
            else if (table_name === sz_import_mp.get("Table_Name")) varType = "SET_Sizing_Import_COL_DEF";
            else if (table_name === comp_aps_import_mp.get("Table_Name")) varType = "SET_Comp_APS_Master_StoreEco_Import_COL_DEF";
            else if (table_name === merch_assortment_sandbox_mp.get("Table_Name")) varType = "Set_Merch_Assortment_Sandbox_COL_DEF";
            else if (table_name === merch_assortment_sandbox_import_mp.get("Table_Name")) varType = "SET_MAS_Import_COL_DEF";
            else if (table_name === air_table_mapping_mp.get("Table_Name")) varType = "AIR_TABLE_COL_DEF";

            if (varType !== "") dispatch({ type: varType, colDef: columnDefination });
            const checkTabInDownloadBtnList = downloadBtnList.find((list) => buttonAccess(list[table_name], userRole));
            if (checkTabInDownloadBtnList) return;
            if (!importDataTabs.includes(table_name) || table_name === pd_initial_costing_mp.get("Table_Name")) {
                downloadData(table_name, qry, count, showMessage);
            }
        });
    }
    const downloadData = (table_name, qry, count, showMessage) => {
        let row_filter = GetRowFilterBasedOnTableName(metaData.current, table_name)
        if (row_filter === null || row_filter === undefined || row_filter === "") {
            row_filter = 'no'
        }
        const postBody = {
            "param_email": sessionStorage.getItem("email"),
            "param_table_name": table_name,
            "param_is_row_level": row_filter,
            "param_where": qry,
            "param_locale_diff": utcDifference
        };
        GetTableDataLocale(JSON.stringify(postBody)).then(res => {
            if (res === "invalid_token") {
                toastMessage("Session Expired", "warning");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                sessionStorage.clear()
                setTimeout(() => {
                    nevigate("/login");
                }, 5000)
            } else {
                if (res) {
                    const ugVl = sessionStorage.getItem("user_type")
                    if (ugVl.toLowerCase() === "Admin".toLowerCase() || ugVl.toLowerCase() === "Product Development".toLowerCase()) {
                        if (table_name === linelist_mp.get("Table_Name") && linelist_mp.get("Table_Name") === tabList[tabValue][1]) {
                            defaultCostingViewBtnCall()
                        }
                    }
                    resetUnsentCount(table_name)
                    if (res.data) {
                        setStateDataByTblName(table_name, JSON.parse(res.data))
                        setSessionDataByTblName(table_name, JSON.parse(res.data))
                    }
                }
                if (!showMessage) return;
                sessionStorage.setItem(table_name + "_Last_Update_Time", formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                // sessionStorage.setItem(req_rng, res)// abhi
                // GetLatestTX_AsRequiredDownload(table_name, count)
                if (res && res.response === true) {
                    sessionStorage.setItem(table_name + '_' + 'Latest_Tx_Id', res.latestTxsId)
                    sessionStorage.setItem(table_name + '_' + 'Latest_Tx_Id_On_Demand_Download', res.latestTxsId)
                    sessionStorage.setItem(table_name + '_' + 'On_Demand_Download_Time', new Date())
                } else {
                    sessionStorage.removeItem(table_name + '_' + 'Latest_Tx_Id')
                    sessionStorage.removeItem(table_name + '_' + 'Latest_Tx_Id_On_Demand_Download')
                    sessionStorage.removeItem(table_name + '_' + 'On_Demand_Download_Time')
                }
                if (table_name === linelist_mp.get("Table_Name")) {
                    //set active criteria = ACTIVE for vender user
                    // if (sessionStorage.getItem("user_type") === "vendor") activeStyleActivityFilterBtnCall()
                    console.log("Calling SetTablesData -> prepareSummaryReportData -> S -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                    let filterMp = new Map()
                    filterMp.set("Season Code", [])
                    filterMp.set("Department", [])
                    filterMp.set("Category", [])
                    filterMp.set("Factory", [])
                    filterMp.set("Shipping Terms (FOB/DDP/LDP)", [])
                    let retVal = [];
                    try {
                        retVal = prepareSummaryReportData(JSON.parse(res.data), filterMp)
                    } catch (e) {
                        console.log(e);
                        dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    }
                    // console.log("retVal")
                    // console.log(retVal)
                    setSessionDataByTblName("Summary_Report", retVal)
                    console.log("Called SetTablesData -> prepareSummaryReportData -> E -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                } else dispatch({ type: "SET_PLEASE_WAIT", data: false });
                // console.log(count + "..." + cntDl.current)
                if (count === cntDl.current) {
                    toastMessage("Data downloaded successfully. ", "success");
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    cntDl.current = 0;
                }
            }
        });
    }
    const redownloadTabBtnCall = () => {
        const req_tbl = tabList[tabValue][1]
        const tabName = tabList[tabValue][0]
        const isAllow = getDataFromMetaData(req_tbl, 'download_data')
        const download_type = getDataFromMetaData(req_tbl, 'download_type')
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false && download_type.toLowerCase() !== "default") {
            toastMessage("Please Download Linelist from Season tab.");
            return
        } else if (isAllow.toLowerCase() !== 'Yes'.toLowerCase()) {
            toastMessage("Redownload functionality is not available for this tab.");
            // setLoading(false);
            return
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let tmData = metaData.current
        let dlType = ''
        if (tmData.length > 0) {
            tmData.map(row => {
                if (row.table_name === req_tbl) {
                    dlType = row.download_type
                }
            })
        }
        let qry = ""
        if (dlType.toLowerCase() === "ondemand".toLowerCase()) {
            qry = sessionStorage.getItem('asRequiredDownloadQuery')
            if (qry === null || qry === undefined) {
                toastMessage("Please first download data for '" + tabName + "' tab.");
                // setLoading(false);
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
        }
        let req_rng = tabList[tabValue][2]
        let row_filter = GetRowFilterBasedOnTableName(tmData, req_tbl)
        if (row_filter === null || row_filter === undefined || row_filter === "") {
            row_filter = 'no'
        }
        if (qry === null || qry === undefined) {
            qry = ""
        }
        let savedFilterModel = gridApi.current.getFilterModel();
        // console.log("redownloadTabBtnCall -> savedFilterModel")
        // console.log(savedFilterModel)
        let colState = gridApi.current.getColumnState();
        let savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        // console.log("redownloadTabBtnCall -> savedSortState")
        // console.log(savedSortState)
        // let reqParam = sessionStorage.getItem("email") + CH5 + req_tbl + CH5 + qry + CH5 + row_filter
        const postBody = {
            "param_email": sessionStorage.getItem("email"),
            "param_table_name": req_tbl,
            "param_is_row_level": row_filter,
            "param_where": qry,
            "param_locale_diff": utcDifference
        };
        GetTableDataLocale(JSON.stringify(postBody))
            .then(res => {
                if (res === "invalid_token") {
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    toastMessage("Session Expired", "warning");
                    sessionStorage.clear()
                    // sleep(3000).then(() => { window.location.reload() })
                    setTimeout(() => {
                        nevigate("/login");
                    }, 5000)
                } else {
                    const ugVl = sessionStorage.getItem("user_type")
                    if (ugVl.toLowerCase() === "Admin".toLowerCase() || ugVl.toLowerCase() === "Product Development".toLowerCase()) {
                        if (req_tbl === linelist_mp.get("Table_Name")) {
                            defaultCostingViewBtnCall()
                        }
                    }
                    resetUnsentCount(req_tbl)
                    setStateDataByTblName(req_tbl, JSON.parse(res.data))
                    setSessionDataByTblName(req_tbl, JSON.parse(res.data))
                    // GetLatestTX_AsRequiredDownload(req_tbl, 1)
                    if (res.response === true) {
                        sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id', res.latestTxsId)
                        sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download', res.latestTxsId)
                        sessionStorage.setItem(req_tbl + '_' + 'On_Demand_Download_Time', new Date())
                    } else {
                        sessionStorage.removeItem(req_tbl + '_' + 'Latest_Tx_Id')
                        sessionStorage.removeItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download')
                        sessionStorage.removeItem(req_tbl + '_' + 'On_Demand_Download_Time')
                    }
                    if (req_tbl === linelist_mp.get("Table_Name")) {
                        console.log("Calling redownloadTabBtnCall -> prepareSummaryReportData -> S -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                        let filterMp = new Map()
                        filterMp.set("Season Code", [])
                        filterMp.set("Department", [])
                        filterMp.set("Category", [])
                        filterMp.set("Factory", [])
                        filterMp.set("Shipping Terms (FOB/DDP/LDP)", [])
                        let retVal = prepareSummaryReportData(JSON.parse(res.data), filterMp)
                        // console.log("retVal")
                        // console.log(retVal)
                        setSessionDataByTblName(retVal, "Summary_Report")
                        console.log("Called redownloadTabBtnCall -> prepareSummaryReportData -> E -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                    }
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }
            })
            .then(() => {
                if (savedSortState !== null && savedSortState !== undefined) {
                    // console.log("redownloadTabBtnCall -> savedSortState")
                    gridApi.current.applyColumnState({
                        state: savedSortState
                    })
                }
                if (savedFilterModel !== null && savedFilterModel !== undefined) {
                    // console.log("redownloadTabBtnCall -> savedFilterModel")
                    gridApi.current.setFilterModel(savedFilterModel);
                }
                sessionStorage.setItem(req_tbl + "_Last_Update_Time", formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                toastMessage("Data downloaded successfully.", "success");
            })
    }
    const getDataFromMetaData = (table_name, column_name) => {
        // console.log("inside get menifest")
        let return_val = ""
        // console.log("metaData.current: " + table_name)
        // console.log(metaData.current)
        metaData.current.forEach(tm => {
            if (tm.table_name === table_name) {
                return_val = tm[column_name]
            }
        })
        return return_val
    };
    function columnDataType(fieldNm, colDef) {
        let retVal = ""
        if (colDef !== null && colDef !== undefined && colDef.length > 0) {
            for (let i = 0; i < colDef.length; i++) {
                let row = colDef[i]
                if (row.field === fieldNm)
                    retVal = row.type
            }
        }
        return retVal
    };
    // no need to switch tab to perform send changes
    const sendDataChangesBackend = async (req_tbl, showMessage = true, from = "") => {
        let rng = tabList[tabValue][2]
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows_send', 0)
        let is_allow = getDataFromMetaData(req_tbl, 'send_data')
        if (is_allow.toLowerCase() !== 'Yes'.toLowerCase() && showMessage) {
            toastMessage("Send Changes functionality is not available for this tab.");
            return false
        }
        const stateData = getStateDataByTblName(req_tbl);
        const ssnData = getSessionDataByTblName(req_tbl);

        //check validation for assortion process
        if (from === "merch_assort") {
            const highlightCells = getHighlightByTblName(req_tbl);
            const res = executeDataVerification(null, req_tbl, highlightCells, gridApi.current, from);
            console.log("res", res);
            if (res.status === false) {
                toastMessage(res.msg, "warning");

                setOpenLlAssortDialog(false);
                setllAssortedRowIds([]);
                setTimeout(() => {
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }, 1000);

                return;
            }
        }

        let newData = [];
        stateData.map(data => {
            if (data.row_id === null || data.row_id === undefined || data.row_id === "") {
                newData.push(data)
            }
        })
        if (showMessage && (ssnData === null || ssnData === undefined || ssnData.length === 0)) {
            if (newData === null || newData === undefined || newData.length === 0) {
                toastMessage("Send Changes : No data found in session to compare.", "warning");
                return false
            }
        }
        let reply = {};
        if (req_tbl !== manage_permission.get("Table_Name")) {
            reply = myRef.current.validateSendData();
            if (!reply.status && showMessage) {
                if (reply.msg) {
                    toastMessage(reply.msg, "warning");
                }
                return false
            }
        }
        const colDef = getColumnDefByTblName(req_tbl);
        let oneRowData = ssnData[0]
        let colNamesArray = new Array()
        if ((oneRowData === null || oneRowData === undefined) && (newData !== null && newData !== undefined && newData.length > 0)) {
            Object.keys(newData[0]).map(cn => {
                colNamesArray.push(cn.replace(doubleQuote, ''))
            })
        } else if ((oneRowData !== null && oneRowData !== undefined)) {
            Object.keys(oneRowData).map(cn => {
                colNamesArray.push(cn.replace(doubleQuote, ''))
            })
        }
        let AddJson = ''
        let UpdateJson = ''
        let DeleteJson = ''
        let updatedcellcount = 0
        ssnData.forEach(sd => {
            stateData.map((data) => {
                if (sd.row_id === data.row_id) {
                    let UpdateJsonData = ''
                    colNamesArray.forEach(function (caItem) {
                        if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                            if ((sd[caItem] === undefined ||
                                sd[caItem] === null ||
                                sd[caItem] === "") &&
                                (data[caItem] === null ||
                                    data[caItem] === undefined ||
                                    data[caItem] === "")) {
                            } else {
                                if (sd[caItem] !== data[caItem]) {
                                    let cellNewVal = data[caItem];
                                    let cellOldVal = sd[caItem];
                                    if (cellNewVal === null || cellNewVal === undefined) cellNewVal = "";
                                    if (cellOldVal === null || cellOldVal === undefined) cellOldVal = "";
                                    if (isColumnHasDatePicker(caItem, colDef) === true) cellNewVal = JsDateToExcelDateNumber(data[caItem]);
                                    if (cellOldVal.toString() !== cellNewVal.toString()) {
                                        const colDataType = columnDataType(caItem, colDef)
                                        if (colDataType === "qty" || colDataType === "price" || colDataType === "percentage") {
                                            // if (truncateToDecimals(cellOldVal).toString() !== truncateToDecimals(cellNewVal).toString()) {
                                            // let deci = parseFloat(cellOldVal).toString().split(".")[1].length;
                                            if (parseFloat(cellOldVal).toFixed(7) !== parseFloat(cellNewVal).toFixed(7)) {
                                                updatedcellcount++;
                                                UpdateJsonData = UpdateJsonData + CH6 + caItem + CH7 + CH1 + CH6 + cellNewVal + CH7 + CH4
                                            }
                                        } else {
                                            updatedcellcount++;
                                            UpdateJsonData = UpdateJsonData + CH6 + caItem + CH7 + CH1 + CH6 + cellNewVal + CH7 + CH4
                                        }
                                    }
                                }
                                // }
                            }
                        }
                    });
                    if (UpdateJsonData.length > 0) {
                        UpdateJsonData = UpdateJsonData.substring(0, UpdateJsonData.length - 1)
                        UpdateJson = UpdateJson + CH2 + data.row_id + CH3 + UpdateJsonData
                    }
                }
            })
            // }
        })
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', updatedcellcount)
        if (newData !== null && newData !== undefined && newData.length > 0) {
            newData.forEach(nd => {
                let AddJsonData = ""
                colNamesArray.forEach(function (caItem, caIndex) {
                    if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                        let colVal = nd[caItem]
                        colVal = (colVal === undefined || colVal === null) ? "" : colVal
                        AddJsonData = AddJsonData + CH6 + caItem + CH7 + CH1 + CH6 + colVal + CH7 + CH4
                    }
                })
                if (AddJsonData.length > 0) {
                    AddJsonData = AddJsonData.substring(0, AddJsonData.length - 1)
                    AddJsonData = AddJsonData + CH2
                    AddJson = AddJson + AddJsonData
                }
            })
        }
        let ssnRowIdsArr = new Array()
        ssnData.forEach(ssnData => {
            if (ssnData.row_id !== null && ssnData.row_id !== undefined && ssnData.row_id !== "") {
                ssnRowIdsArr.push(ssnData.row_id)
            }
        })
        let gridRowIdsArr = new Array()
        stateData.map((data) => {
            if (data.row_id !== null && data.row_id !== undefined && data.row_id !== "") {
                gridRowIdsArr.push(data.row_id)
            }
        })
        let deletedRowIdsArr = new Array()
        for (let m = 0; m < ssnRowIdsArr.length; m++) {
            if (gridRowIdsArr.includes(ssnRowIdsArr[m]) === false) {
                if (deletedRowIdsArr.includes(ssnRowIdsArr[m]) === false) {
                    deletedRowIdsArr.push(ssnRowIdsArr[m])
                }
            }
        }
        if (deletedRowIdsArr !== null && deletedRowIdsArr !== undefined && deletedRowIdsArr.length > 0) {
            DeleteJson = deletedRowIdsArr.join(CH2)
        }
        if (AddJson.length > 0) {
            AddJson = AddJson.substring(0, AddJson.length - 1)
        }
        if (UpdateJson.length > 0) {
            UpdateJson = UpdateJson.substring(1, UpdateJson.length)
        }
        if (AddJson === null || AddJson.trim() === '') {
            AddJson = "NoData"
        }
        if (UpdateJson === null || UpdateJson.trim() === '') {
            UpdateJson = "NoData"
        }
        if (DeleteJson === null || DeleteJson.trim() === '') {
            DeleteJson = "NoData"
        }
        let FinalStr = ''
        if (AddJson === "NoData" && UpdateJson === "NoData" && DeleteJson === "NoData") {
            FinalStr = "NoAnyDataChangesFound"
        } else {
            if (req_tbl === "xl_faherty_air_sea_split") {
                FinalStr = "WebRequest" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
            } else {
                FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
            }
        }
        console.log("FinalStr sendchanges backend", FinalStr);
        // return;
        if (FinalStr === "NoAnyDataChangesFound" && showMessage) {
            // removeHeihlight();
            toastMessage("Send Changes : No data changes found.", "success");
            return;
        } else {
            let apiNm = "SendUserDataOptimized"
            fetch(restApiPath + apiNm, {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (
                    FinalStr
                )
            })
                .then(result => result.json())
                .then(resData => processingSendUserDataForHiddenTab(resData, req_tbl, false, from))
        }
    };
    const processingSendUserDataForHiddenTab = (result, req_tbl, showPopUp = true, from = "") => {
        if (result.error === "invalid_token") {
            toastMessage("Session Expired", "warning");
            sessionStorage.clear();
            // sleep(3000).then(() => { window.location.reload() })
            setTimeout(() => {
                nevigate("/login");
            }, 5000);
        } else {
            if (result.response === true) {
                const stateData = getStateDataByTblName(req_tbl);
                let resData = result.data;
                let resDataArray = new Array();
                resDataArray = resData.split('#');
                if (resDataArray.length > 0) {
                    sessionStorage.setItem('Send_Data_Tx_Id_Rng', resDataArray[0])
                    if (resDataArray.length === 2) {
                        let rwIds = resDataArray[1];
                        if (rwIds !== null && rwIds !== undefined && rwIds.trim() !== "") {
                            let arrIds = rwIds.split('|')
                            //remove form temp ids
                            // let temp = []
                            // this.setState({ newRowIds: temp })
                            let cnt = 0
                            stateData.map(data => {
                                if (data.row_id === null || data.row_id === "") {
                                    data.row_id = arrIds[cnt]
                                    cnt = cnt + 1
                                }
                            })
                            sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', arrIds.length)
                        }
                    }
                }
                setSessionDataByTblName(req_tbl, stateData);
                resetUnsentCount(req_tbl);
                setResetAuditTrail(true)
                // sessionStorage.removeItem(rng)
                // sessionStorage.setItem(rng, JSON.stringify(gridRowData))// abhi
                let sdCounts = JSON.parse(result.count);
                console.log("processingSendUserDataForHiddenTab -> sdCounts -> ", sdCounts)
                sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download', sessionStorage.getItem("Send_Data_Tx_Id_Rng"))
                sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id', sessionStorage.getItem("Send_Data_Tx_Id_Rng"))
                sessionStorage.setItem(req_tbl + '_' + 'On_Demand_Download_Time', new Date())
                sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', sdCounts[0].updated_cells_cnt)
                sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', sdCounts[0].new_rows_cnt)
                sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows_send', sdCounts[0].deleted_rows_cnt)
                sessionStorage.setItem(req_tbl + "_Last_Send_Time", formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                if (req_tbl === pd_initial_costing_mp.get("Table_Name")) {
                    getChangesWithoutBtn_(req_tbl)
                    getChangesWithoutBtn_(merch_assortment_sandbox_mp.get("Table_Name"), from);
                } else if (req_tbl === merch_assortment_sandbox_mp.get("Table_Name")) {
                    getChangesWithoutBtn_(req_tbl)
                    getChangesWithoutBtn_(linelist_mp.get("Table_Name"), from);
                } else if (req_tbl === linelist_mp.get("Table_Name")) {
                    getChangesWithoutBtn_(req_tbl, from);
                }
                if (from === "pd_assort") {
                    toastMessage("PD_Inital_Costing to Merch_Assortment_Sandbox assortment process completed successfullt!", "success");
                } else if (from === "merch_assort") {
                    toastMessage("Merch_Assortment_Sandbox to Linelist assortment process completed successfullt!", "success");
                } else if (from === "carry_over") {
                    toastMessage("Carry Over process completed successfullt!", "success");
                } else dispatch({ type: "SET_PLEASE_WAIT", data: false });
                if (showPopUp === true) {
                    toastMessage("Data sent successfully.", "success");
                    setBlSendDataStatistics(true)
                }
            } else if (result.response === false) {
                if (result.message === null || result.message === undefined || result.message === "") {
                    toastMessage("There is an error while sending changes.", "warning");
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                } else {
                    toastMessage("Send Changes : " + result.message, "warning");
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }
            } else {
                toastMessage("There is an error while sending changes.", "warning");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
            if (from === "pd_assort") {
                setOpenAssortDialog(false);
                setPdAssortedRowIds([]);
                setPdRevertedRowIds([]);
                setTimeout(() => {
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }, 1000);
            } else if (from === "merch_assort") {
                setOpenLlAssortDialog(false);
                setllAssortedRowIds([]);
                setTimeout(() => {
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }, 1000);
            } else if (from === "carry_over") {
                setOpenCarryOverDg(false);
                setCarryOverData([]);
                setTimeout(() => {
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }, 1000);
            } else dispatch({ type: "SET_PLEASE_WAIT", data: false });
        }

    };
    const sendChangesBtnCall = () => {
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let tabName = tabList[tabValue][0]
        let req_tbl = tabList[tabValue][1]
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows_send', 0)
        let is_allow = getDataFromMetaData(req_tbl, 'send_data')
        if (is_allow.toLowerCase() !== 'Yes'.toLowerCase()) {
            toastMessage("Send Changes functionality is not available for this tab.");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
            return
        }
        const downloadType = getDataFromMetaData(tabList[myTabValue][1], 'download_type')
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false && downloadType.toLowerCase() !== "default") {
            toastMessage("Please first download data for '" + tabName + "' tab.");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
            return
        }

        let newData = []
        gridApi.current.forEachNode(node => {
            if (node.data.row_id === null || node.data.row_id === undefined || node.data.row_id === "") {
                newData.push(node.data)
            }
        })
        const ssnData = getSessionDataByTblName(req_tbl);
        // console.log(ssnData)
        if (ssnData === null || ssnData === undefined || ssnData.length === 0) {
            if (newData === null || newData === undefined || newData.length === 0) {
                toastMessage("Send Changes : No data found in session to compare.", "warning");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                return
            }
        }
        let reply = {}
        reply = myRef.current.validateSendData();
        // console.log(reply)
        if (!reply.status) {
            toastMessage(reply.msg, "warning");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
            return
        }
        // console.log("Send");
        // dispatch({ type: "SET_PLEASE_WAIT", data: false });
        // return;
        const colDef = getColumnDefByTblName(req_tbl);
        let oneRowData = ssnData[0]
        let colNamesArray = new Array()
        if ((oneRowData === null || oneRowData === undefined) && (newData !== null && newData !== undefined && newData.length > 0)) {
            Object.keys(newData[0]).map(cn => {
                colNamesArray.push(cn.replace(doubleQuote, ''))
            })
        } else {
            Object.keys(oneRowData).map(cn => {
                colNamesArray.push(cn.replace(doubleQuote, ''))
            })
        }
        let AddJson = ''
        let UpdateJson = ''
        let DeleteJson = ''
        //get ignore columns in cache compare
        const getIgnoreColumnFilter = _.filter(colDef, obj => { return obj.ignore_column_in_cache_compare === 'YES'; });
        const ignore_column_in_cache_compare = _.map(getIgnoreColumnFilter, "headerName");
        // console.log("ssnData", ssnData)
        let updatedcellcount = 0
        ssnData.forEach(sd => {
            gridApi.current.forEachNode((rowNode, index) => {
                if (sd.row_id === rowNode.data.row_id) {
                    let UpdateJsonData = ''
                    colNamesArray.forEach(function (caItem, caIndex) {
                        if (ignore_column_in_cache_compare.includes(caItem) === false) {
                            if ((sd[caItem] === undefined || sd[caItem] === null || sd[caItem] === "") && (rowNode.data[caItem] === null || rowNode.data[caItem] === undefined)) {
                            } else {
                                if (sd[caItem] !== rowNode.data[caItem]) {
                                    let cellNewVal = rowNode.data[caItem]
                                    let cellOldVal = sd[caItem]
                                    if (isColumnHasDatePicker(caItem, colDef) === true) {
                                        cellNewVal = JsDateToExcelDateNumber(rowNode.data[caItem])
                                    }
                                    if (cellNewVal === null || cellNewVal === undefined || cellNewVal === 'null') cellNewVal = ""
                                    if (cellOldVal === null || cellOldVal === undefined || cellOldVal === 'null') cellOldVal = ""
                                    if (cellOldVal.toString() !== cellNewVal.toString()) {
                                        updatedcellcount++;
                                        UpdateJsonData = UpdateJsonData + CH6 + caItem + CH7 + CH1 + CH6 + cellNewVal + CH7 + CH4
                                    }
                                }
                            }
                        }
                    });
                    if (UpdateJsonData.length > 0) {
                        UpdateJsonData = UpdateJsonData.substring(0, UpdateJsonData.length - 1)
                        UpdateJson = UpdateJson + CH2 + rowNode.data.row_id + CH3 + UpdateJsonData
                    }
                }
            })
        })
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', updatedcellcount)
        if (newData !== null && newData !== undefined && newData.length > 0) {
            newData.forEach(nd => {
                let AddJsonData = ""
                colNamesArray.forEach(function (caItem, caIndex) {
                    if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                        let colVal = nd[caItem]
                        // console.log(colVal)
                        colVal = (colVal === undefined || colVal === null) ? "" : colVal
                        AddJsonData = AddJsonData + CH6 + caItem + CH7 + CH1 + CH6 + colVal + CH7 + CH4
                    }
                })
                if (AddJsonData.length > 0) {
                    AddJsonData = AddJsonData.substring(0, AddJsonData.length - 1)
                    AddJsonData = AddJsonData + CH2
                    AddJson = AddJson + AddJsonData
                }
            })
        }
        let ssnRowIdsArr = new Array()
        ssnData.forEach(ssnData => {
            if (ssnData.row_id !== null && ssnData.row_id !== undefined && ssnData.row_id !== "") {
                ssnRowIdsArr.push(ssnData.row_id)
            }
        })
        let gridRowIdsArr = new Array()
        gridApi.current.forEachNode((rowNode, index) => {
            if (rowNode.data.row_id !== null && rowNode.data.row_id !== undefined && rowNode.data.row_id !== "") {
                gridRowIdsArr.push(rowNode.data.row_id)
            }
        })
        let deletedRowIdsArr = new Array()
        const deletedRows = [];
        for (let m = 0; m < ssnRowIdsArr.length; m++) {
            if (gridRowIdsArr.includes(ssnRowIdsArr[m]) === false) {
                if (deletedRowIdsArr.includes() === false) {
                    deletedRowIdsArr.push(ssnRowIdsArr[m])
                    if (req_tbl === merch_assortment_sandbox_mp.get("Table_Name")) {
                        const search = ssnData.find((dt) => dt.row_id === ssnRowIdsArr[m] && (!dt["Upload To Linelist Check"] || dt["Upload To Linelist Check"] === ""));
                        if (search) deletedRows.push(search);
                    }
                }
            }
        }
        if (deletedRowIdsArr !== null && deletedRowIdsArr !== undefined && deletedRowIdsArr.length > 0) {
            DeleteJson = deletedRowIdsArr.join(CH2)
        }
        if (AddJson.length > 0) {
            AddJson = AddJson.substring(0, AddJson.length - 1)
        }
        if (UpdateJson.length > 0) {
            UpdateJson = UpdateJson.substring(1, UpdateJson.length)
        }
        if (AddJson === null || AddJson.trim() === '') {
            AddJson = "NoData"
        }
        if (UpdateJson === null || UpdateJson.trim() === '') {
            UpdateJson = "NoData"
        }
        if (DeleteJson === null || DeleteJson.trim() === '') {
            DeleteJson = "NoData"
        }
        let FinalStr = ''
        if (AddJson === "NoData" && UpdateJson === "NoData" && DeleteJson === "NoData") {
            FinalStr = "NoAnyDataChangesFound"
        } else {
            FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
        }
        if (FinalStr === "NoAnyDataChangesFound") {
            toastMessage("Send Changes : No data changes found.", "success");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
        } else {
            let apiNm = "SendUserDataOptimized"  // Uncomment blow to enable send.
            fetch(restApiPath + apiNm, {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (
                    FinalStr
                )
            })
                .then(result => result.json())
                .then(resData => processingSendUserData(resData, req_tbl, true, null, deletedRows))
        }
    };
    const sendDataChangesWithoutBtn = (mp) => {
        let tabName = mp.get("Tab_Name")
        let req_tbl = mp.get("Table_Name")
        let rng = mp.get("Rng_Name")
        let Send_Data_Process_Info = mp.get("Send_Data_Process_Info")
        // console.log(mp)
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows_send', 0)
        let is_allow = getDataFromMetaData(req_tbl, 'send_data')
        if (is_allow.toLowerCase() !== 'Yes'.toLowerCase()) {
            toastMessage("Send Changes functionality is not available for this tab.");
            setUploadDocument(false)
            return
        }
        let stateData = mp.get("Data")
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false && (stateData === null || stateData === undefined || stateData.length === 0)) {
            setIsLoadingSend(false)
            toastMessage("Please first download data for '" + tabName + "' tab.");
            setUploadDocument(false)
            return
        }
        let newData = []
        gridApi.current.forEachNode(node => {
            if (node.data.row_id === null || node.data.row_id === undefined || node.data.row_id === "") {
                newData.push(node.data)
            }
        })
        const ssnData = getSessionDataByTblName(req_tbl);
        if (ssnData === null || ssnData === undefined || ssnData.length === 0) {
            if (newData === null || newData === undefined || newData.length === 0) {
                setUploadDocument(false)
                toastMessage("Send Changes : No data found in session to compare.", "warning");
                return
            }
        }
        let oneRowData = ssnData[0]
        let colNamesArray = new Array()
        if ((oneRowData === null || oneRowData === undefined) && (newData !== null && newData !== undefined && newData.length > 0)) {
            Object.keys(newData[0]).map(cn => {
                colNamesArray.push(cn.replace(doubleQuote, ''))
            })
        } else {
            Object.keys(oneRowData).map(cn => {
                colNamesArray.push(cn.replace(doubleQuote, ''))
            })
        }
        let AddJson = ''
        let UpdateJson = ''
        let DeleteJson = ''
        let colDef = mp.get("ColDef")
        // console.log("sendDataChanges -> IN Check Update")
        let updatedcellcount = 0
        ssnData.forEach(sd => {
            gridApi.current.forEachNode((rowNode, index) => {
                if (sd.row_id === rowNode.data.row_id) {
                    let UpdateJsonData = ''
                    colNamesArray.forEach(function (caItem, caIndex) {
                        if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                            // if (colNamesUpdated.includes(caItem) && ssnData[caItem] !== rowNode.data[caItem]) {
                            if ((sd[caItem] === undefined || sd[caItem] === null || sd[caItem] === "") && (rowNode.data[caItem] === null || rowNode.data[caItem] === undefined || rowNode.data[caItem] === "")) {
                            } else {
                                if (sd[caItem] !== rowNode.data[caItem]) {
                                    let cellNewVal = rowNode.data[caItem]
                                    let cellOldVal = sd[caItem]
                                    if (isColumnHasDatePicker(caItem, colDef) === true) {
                                        cellNewVal = JsDateToExcelDateNumber(rowNode.data[caItem])
                                    }
                                    if (cellOldVal !== cellNewVal) {
                                        updatedcellcount++;
                                        UpdateJsonData = UpdateJsonData + CH6 + caItem + CH7 + CH1 + CH6 + cellNewVal + CH7 + CH4
                                    }
                                }
                            }
                            // }
                        }
                    });
                    if (UpdateJsonData.length > 0) {
                        UpdateJsonData = UpdateJsonData.substring(0, UpdateJsonData.length - 1)
                        UpdateJson = UpdateJson + CH2 + rowNode.data.row_id + CH3 + UpdateJsonData
                    }
                }
            })
        })
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', updatedcellcount)
        if (newData !== null && newData !== undefined && newData.length > 0) {
            newData.forEach(nd => {
                let AddJsonData = ""
                colNamesArray.forEach(function (caItem, caIndex) {
                    if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                        let colVal = nd[caItem]
                        colVal = (colVal === undefined || colVal === null) ? "" : colVal
                        AddJsonData = AddJsonData + CH6 + caItem + CH7 + CH1 + CH6 + colVal + CH7 + CH4
                    }
                })
                if (AddJsonData.length > 0) {
                    AddJsonData = AddJsonData.substring(0, AddJsonData.length - 1)
                    AddJsonData = AddJsonData + CH2
                    AddJson = AddJson + AddJsonData
                }
            })
        }
        let ssnRowIdsArr = new Array()
        ssnData.forEach(ssnData => {
            if (ssnData.row_id !== null && ssnData.row_id !== undefined && ssnData.row_id !== "") {
                ssnRowIdsArr.push(ssnData.row_id)
            }
        })
        let gridRowIdsArr = new Array()
        gridApi.current.forEachNode((rowNode, index) => {
            if (rowNode.data.row_id !== null && rowNode.data.row_id !== undefined && rowNode.data.row_id !== "") {
                gridRowIdsArr.push(rowNode.data.row_id)
            }
        })
        let deletedRowIdsArr = new Array()
        for (let m = 0; m < ssnRowIdsArr.length; m++) {
            if (gridRowIdsArr.includes(ssnRowIdsArr[m]) === false) {
                if (deletedRowIdsArr.includes() === false) {
                    deletedRowIdsArr.push(ssnRowIdsArr[m])
                }
            }
        }
        if (deletedRowIdsArr !== null && deletedRowIdsArr !== undefined && deletedRowIdsArr.length > 0) {
            DeleteJson = deletedRowIdsArr.join(CH2)
        }
        if (AddJson.length > 0) {
            AddJson = AddJson.substring(0, AddJson.length - 1)
        }
        if (UpdateJson.length > 0) {
            UpdateJson = UpdateJson.substring(1, UpdateJson.length)
        }
        if (AddJson === null || AddJson.trim() === '') {
            AddJson = "NoData"
        }
        if (UpdateJson === null || UpdateJson.trim() === '') {
            UpdateJson = "NoData"
        }
        if (DeleteJson === null || DeleteJson.trim() === '') {
            DeleteJson = "NoData"
        }
        if (Send_Data_Process_Info === null || Send_Data_Process_Info === undefined || Send_Data_Process_Info.trim() === "") {
            Send_Data_Process_Info = "t1"
        }
        let FinalStr = ''
        if (AddJson === "NoData" && UpdateJson === "NoData" && DeleteJson === "NoData") {
            FinalStr = "NoAnyDataChangesFound"
            Send_Data_Process_Info = ""
        } else {
            // FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
            FinalStr = Send_Data_Process_Info + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
            Send_Data_Process_Info = ""
        }
        // console.log('sendDataChangesWithoutBtn2 -> FinalStr2 -> ' + FinalStr)
        setUploadDocument(false)
        if (FinalStr === "NoAnyDataChangesFound") {
            toastMessage("Send Changes : No data changes found.", "success");
        } else {
            let apiNm = "SendUserDataOptimized"   // Uncomment blow to enable send.
            setResetAuditTrail(false)
            fetch(restApiPath + apiNm, {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (
                    FinalStr
                )
            })
                .then(result => result.json())
                .then(resData => processingSendUserData(resData, req_tbl, false, mp))
        }
    };
    const processingSendUserData = (result, req_tbl, showPopUp = true, mp, deletedRows = []) => {
        if (result.error === "invalid_token") {
            toastMessage("Session Expired", "warning");
            sessionStorage.clear()
            // sleep(3000).then(() => { window.location.reload() })
            setTimeout(() => {
                nevigate("/login");
            }, 5000);
        } else {
            if (result.response === true) {
                let savedFilterModel = gridApi.current.getFilterModel();
                // console.log("processingSendUserData -> savedFilterModel")
                // console.log(savedFilterModel)
                let colState = gridApi.current.getColumnState();
                let savedSortState = colState
                    .filter(function (s) {
                        return s.sort != null;
                    })
                    .map(function (s) {
                        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
                    });
                // console.log("processingSendUserData -> savedSortState")
                // console.log(savedSortState)
                let resData = result.data
                let resDataArray = new Array()
                resDataArray = resData.split('#')
                // console.log("processingSendUserData -> resDataArray")
                // console.log(resDataArray)
                if (resDataArray.length > 0) {
                    sessionStorage.setItem('Send_Data_Tx_Id_Rng', resDataArray[0])
                    if (resDataArray.length === 2) {
                        let rwIds = resDataArray[1]
                        // console.log("processingSendUserData -> rwIds")
                        // console.log(rwIds)
                        if (rwIds !== null && rwIds !== undefined && rwIds.trim() !== "") {
                            let arrIds = rwIds.split('|')
                            // console.log("processingSendUserData -> arrIds")
                            // console.log(arrIds)
                            //remove form temp ids
                            // let temp = []
                            // this.setState({ newRowIds: temp })
                            let cnt = 0
                            gridApi.current.forEachNode(node => {
                                // console.log("node", node);
                                if (node.data.row_id === null || node.data.row_id === "") {
                                    // console.log("arrIds[cnt] ---> ",arrIds[cnt])
                                    node.data.row_id = arrIds[cnt]
                                    cnt = cnt + 1
                                }
                            })
                            gridApi.current.refreshCells({ force: true });
                            sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', arrIds.length)
                        }
                    }
                }
                let gridRowData = []
                gridApi.current.forEachNode(node => gridRowData.push(node.data));
                console.log(gridRowData)
                setResetAuditTrail(true)
                resetUnsentCount(req_tbl)
                setSessionDataByTblName(req_tbl, JSON.parse(JSON.stringify(gridRowData)));
                setStateDataByTblName(req_tbl, JSON.parse(JSON.stringify(gridRowData)))
                let sdCounts = JSON.parse(result.count);
                console.log("processingSendUserData -> sdCounts -> ", sdCounts)
                sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id_On_Demand_Download', sessionStorage.getItem("Send_Data_Tx_Id_Rng"))
                sessionStorage.setItem(req_tbl + '_' + 'Latest_Tx_Id', sessionStorage.getItem("Send_Data_Tx_Id_Rng"))
                sessionStorage.setItem(req_tbl + '_' + 'On_Demand_Download_Time', new Date())
                sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', sdCounts[0].updated_cells_cnt)
                sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', sdCounts[0].new_rows_cnt)
                sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows_send', sdCounts[0].deleted_rows_cnt)
                sessionStorage.setItem(req_tbl + "_Last_Send_Time", formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
                const usertype = sessionStorage.getItem("user_type").toLowerCase();
                if (linelist_mp.get("Table_Name") === req_tbl) {
                    if (["admin", "accessories", "global planning", "merch", "planning", "wholesale"].includes(usertype)) {
                        getChangesWithoutBtn_(linelist_mp.get("Table_Name"))
                    }
                    if (["merch", "admin", "global planning", "planning", "product development"].includes(usertype)) {
                        getChangesWithoutBtn_(pd_initial_costing_mp.get("Table_Name"))
                    }
                    if (usertype !== "Wholesale".toLowerCase() &&
                        usertype !== "Product Development".toLowerCase() &&
                        usertype !== "Vendor".toLowerCase() && showDtc) {
                        getChangesWithoutBtn_(dtc_buy_sheet_mp.get("Table_Name"))
                    }
                    if (usertype !== "Merch".toLowerCase() &&
                        usertype !== "Planning".toLowerCase() &&
                        usertype !== "Product Development".toLowerCase() &&
                        usertype !== "Accessories".toLowerCase() &&
                        usertype !== "Vendor".toLowerCase() && showWhsl) {
                        getChangesWithoutBtn_(whsl_buy_sheet_mp.get("Table_Name"))
                    }
                    if (showSizing) {
                        getChangesWithoutBtn_(sizing_mp.get("Table_Name"))
                    }
                    if (showChase) {
                        getChangesWithoutBtn_(chase_mp.get("Table_Name"))
                    }
                    let filterMp = new Map()
                    filterMp.set("Season Code", [])
                    filterMp.set("Department", [])
                    filterMp.set("Category", [])
                    filterMp.set("Factory", [])
                    filterMp.set("Shipping Terms (FOB/DDP/LDP)", [])
                    let retVal = prepareSummaryReportData(JSON.parse(JSON.stringify(gridRowData)), filterMp)
                    setSessionDataByTblName("Summary_Report", retVal)
                } else if (dtc_buy_sheet_mp.get("Table_Name") === req_tbl) {
                    if (["merch", "admin", "global planning", "planning", "accessories"].includes(usertype)) {
                        if (showLinelist) getChangesWithoutBtn_(linelist_mp.get("Table_Name"))
                        if (showDtc) getChangesWithoutBtn_(sizing_mp.get("Table_Name"))
                    }
                } else if (whsl_buy_sheet_mp.get("Table_Name") === req_tbl) {
                    if (["merch", "admin", "planning", "wholesale"].includes(usertype)) {
                        if (showLinelist) getChangesWithoutBtn_(linelist_mp.get("Table_Name"))
                        if (showSizing) getChangesWithoutBtn_(sizing_mp.get("Table_Name"))
                        if (showWhsl) getChangesWithoutBtn_(whsl_buy_sheet_nord_rp_mp.get("Table_Name"))
                        if (showDtc) getChangesWithoutBtn_(dtc_buy_sheet_mp.get("Table_Name"))
                    }
                } else if (merch_assortment_sandbox_mp.get("Table_Name") === req_tbl) {
                    //on row deletion revert pd_initial_costing
                    if (deletedRows.length > 0) {
                        const pd_table = pd_initial_costing_mp.get("Table_Name");
                        const pdData = getStateDataByTblName(pd_table)
                        deletedRows.map((data) => {
                            const check = pdData.find((dt) => dt["Year"] === data["Year"] && dt["Season Code"] === data["Season Code"] && dt["Department"] === data["Department"] &&
                                dt["Category"] === data["Category"] && dt["Style"] === data["Style"] && dt["Style Description"] === data["Style Description"] && dt["Color Description"] === data["Color Description"])
                            if (check)
                                check["Assort"] = "";
                        })
                        setStateDataByTblName(pd_table, JSON.parse(JSON.stringify(pdData)));
                        sendDataChangesBackend(pd_table, false);
                    }
                    getChangesWithoutBtn_(merch_assortment_sandbox_mp.get("Table_Name"))
                    if (tabPermission(linelist_mp.get("Tab_Name")) && showLinelist) {
                        getChangesWithoutBtn_(linelist_mp.get("Table_Name"))
                    }
                    if (tabPermission(pd_initial_costing_mp.get("Tab_Name"))) {
                        getChangesWithoutBtn_(pd_initial_costing_mp.get("Table_Name"))
                    }
                } else if (pd_initial_costing_mp.get("Table_Name") === req_tbl) {
                    if (tabPermission(linelist_mp.get("Tab_Name")) && showLinelist) {
                        getChangesWithoutBtn_(linelist_mp.get("Table_Name"))
                    }
                    if (tabPermission(merch_assortment_sandbox_mp.get("Tab_Name"))) {
                        getChangesWithoutBtn_(merch_assortment_sandbox_mp.get("Table_Name"))
                    }
                    getChangesWithoutBtn_(pd_initial_costing_mp.get("Table_Name"))
                } else if (chase_mp.get("Table_Name") === req_tbl) {
                    if (showLinelist) {
                        getChangesWithoutBtn_(linelist_mp.get("Table_Name"))
                    }
                    if (showDtc) {
                        getChangesWithoutBtn_(dtc_buy_sheet_mp.get("Table_Name"))
                    }
                    if (showWhsl) {
                        getChangesWithoutBtn_(whsl_buy_sheet_mp.get("Table_Name"))
                    }
                } else if (sizing_mp.get("Table_Name") === req_tbl) {
                    if (showWhsl) {
                        getChangesWithoutBtn_(whsl_buy_sheet_mp.get("Table_Name"))
                    }
                }
                if (showPopUp === true) {
                    toastMessage("Data sent successfully.", "success");
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    setBlSendDataStatistics(true)
                    // console.log("show dailog: " + tabList[tabValue][1])
                }
                if (savedSortState !== null && savedSortState !== undefined) {
                    // console.log("processingSendUserData -> savedSortState")
                    gridApi.current.applyColumnState({
                        state: savedSortState
                    })
                }
                if (savedFilterModel !== null && savedFilterModel !== undefined) {
                    // console.log("processingSendUserData -> savedFilterModel")
                    gridApi.current.setFilterModel(savedFilterModel);
                }
                if (req_tbl === linelist_mp.get("Table_Name")) {
                    if (mp !== null && mp !== undefined && mp.size > 0) {
                        const mpForMemo = new Map([
                            ['param_user_id', sessionStorage.getItem("email")],
                            ['mail_subject', "Linelist Tool : " + mp.get("scVal") + " : Change Memo Details"],
                            ['param_table_name', req_tbl],
                            ['param_season_code', mp.get("scVal")],
                            ['param_send_data_tx_id', sessionStorage.getItem("Send_Data_Tx_Id_Rng")]
                        ]);
                        const jsnMemo = JSON.stringify(Object.fromEntries(mpForMemo));
                        // console.log("jsnMemo")
                        // console.log(jsnMemo)
                        fetch(restApiPath + 'getFbLlChangeMemoStatusReport', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: (jsnMemo)
                        })
                            .then(result => result.json())
                            .then(resData => afterChangeMemoStatusReport(resData, mp))
                    }
                }
                const ugVl = sessionStorage.getItem("user_type")
                if (ugVl.toLowerCase() === "Admin".toLowerCase() || ugVl.toLowerCase() === "Product Development".toLowerCase()) {
                    if (req_tbl === linelist_mp.get("Table_Name")) {
                        defaultCostingViewBtnCall()
                    }
                }
                gridApi.current.clearFocusedCell()
            } else if (result.response === false) {
                // console.log(result.message)
                if (result.message === null || result.message === undefined || result.message === "") {
                    toastMessage("There is an error while sending changes.", "warning");
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                } else {
                    toastMessage("Send Changes : " + result.message, "warning");
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }
            } else {
                toastMessage("There is an error while sending changes.", "warning");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
        }
    };
    const afterChangeMemoStatusReport = (result, mp) => {
        // console.log("afterChangeMemoStatusReport -> result")
        // console.log(result)
        // console.log("afterChangeMemoStatusReport -> mp")
        // console.log(mp)
        if (result.response === null || result.response === undefined) {
            toastMessage("There was an error while preparing Change Memo, please contact system admin.", "warning");
        } else {
            if (result.response === true) {
                toastMessage("Change Memo performed successfully.", "success");
                getChangesWithoutBtn_(linelist_mp.get("Table_Name"));
            }
        }
    };
    function isColumnHasDatePicker(fieldNm, colDef) {
        let retVal = false
        if (colDef !== null && colDef !== undefined && colDef.length > 0) {
            for (let i = 0; i < colDef.length; i++) {
                let row = colDef[i]
                if (row.field === fieldNm && row.cellEditor === "Datepicker") {
                    // console.log(row)
                    retVal = true
                }
            }
        }
        return retVal
    };
    const getChangesBtnCall = () => {
        let tabName = tabList[tabValue][0]
        let req_tbl = tabList[tabValue][1]
        let isReceivable = getDataFromMetaData(req_tbl, 'sync_data')
        let paramwhere = "";
        if (getDataFromMetaData(req_tbl, 'download_type').toLocaleLowerCase() !== "default") {
            paramwhere = sessionStorage.getItem('asRequiredDownloadQuery') === null ? "" : sessionStorage.getItem('asRequiredDownloadQuery')
        }
        let postbodyJson = {
            "param_email": sessionStorage.getItem("email"),
            "param_table_name": req_tbl,
            "param_tx_id": sessionStorage.getItem(req_tbl + '_Latest_Tx_Id'),//6870
            "param_where": paramwhere,
            "param_row_access": getDataFromMetaData(req_tbl, 'filter_row_access'),
            "param_locale_diff": utcDifference
        }
        if (isReceivable !== 'Yes') {
            toastMessage("Get Changes functionality is not available for this tab.");
            return
        }
        const downloadType = getDataFromMetaData(tabList[myTabValue][1], 'download_type')
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false && downloadType.toLowerCase() !== "default") {
            toastMessage("Please first download data for '" + tabName + "' tab.");
            return
        }
        if (req_tbl === manage_user.get("Table_Name") || req_tbl === manage_permission.get("Table_Name")) {
            myRef.current.getChangesFunc();
            return;
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let data = metaData.current
        let AsRequiredTblRow = getTableAsRequired(data)
        if (AsRequiredTblRow !== null && AsRequiredTblRow !== undefined && AsRequiredTblRow !== "") {
            let row_filter = GetRowFilterBasedOnTableName(data, req_tbl)
            if (row_filter === null || row_filter === undefined || row_filter === "") {
                row_filter = 'no'
            }
            fetch(restApiPath + 'getCellChangesXL', {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (JSON.stringify(postbodyJson))
            })
                .then(result => result.json())
                .then(masterData => callGetChanges(masterData, req_tbl, null))
        }
    };
    const getChangesWithoutBtn = (mp) => {
        const req_tbl = mp.get("Table_Name")
        let data = metaData.current
        let postbodyJson = {
            "param_email": sessionStorage.getItem("email"),
            "param_table_name": req_tbl,
            "param_tx_id": sessionStorage.getItem(req_tbl + '_Latest_Tx_Id'),//6870
            "param_where": "",
            "param_row_access": getDataFromMetaData(req_tbl, 'filter_row_access'),
            "param_locale_diff": utcDifference
        }
        let AsRequiredTblRow = getTableAsRequired(data)
        if (AsRequiredTblRow !== null && AsRequiredTblRow !== undefined && AsRequiredTblRow !== "") {
            let row_filter = GetRowFilterBasedOnTableName(data, req_tbl)
            if (row_filter === null || row_filter === undefined || row_filter === "") {
                row_filter = 'no'
            }
            let downloadType = getDataFromMetaData(req_tbl, 'download_type')
            let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
            let postBodyStr = ''
            if (downloadType === "Default") {
                postBodyStr = sessionStorage.getItem("email") + CH5 + req_tbl + CH5 + CH5 + row_filter
            } else {
                postBodyStr = sessionStorage.getItem("email") + CH5 + req_tbl + CH5 + asRequiredDownloadQuery + CH5 + row_filter
            }
            fetch(restApiPath + 'getCellChangesXL', {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (JSON.stringify(postbodyJson))
            })
                .then(result => result.json())
                .then(masterData => callGetChanges(masterData, req_tbl, mp))
        }
    };
    const callGetChanges = async (result, req_tbl, mp) => {
        let savedFilterModel = gridApi.current.getFilterModel();
        // console.log("callGetChanges -> savedFilterModel")
        // console.log(savedFilterModel)
        let colState = gridApi.current.getColumnState();
        // console.log("callGetChanges -> colState")
        // console.log(colState)
        // console.log("callGetChanges -> colState")
        let savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        // console.log("callGetChanges -> savedSortState")
        // console.log(savedSortState)
        let reply = {}
        reply = await myRef.current.getChangesFunc(result, metaData.current)
        // console.log("reply")
        // console.log(reply)
        // if (reply.status) setBlReceiveDataStatistics(true)
        if (mp === null || mp === undefined || mp.size === 0) {
            const tran = sessionStorage.getItem(req_tbl + '_' + 'Transactions')
            // console.log(tran)
            if (reply.status && (tran && tran !== "")) {
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                setBlReceiveDataStatistics(true)
            }
            else {
                // toastMessage("GD Get Changes : No changes found.");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
        } else {
            if (mp.get("processName") && mp.get("processName") !== "") toastMessage(mp.get("processName") + " performed successfully.", "success");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
        }
        if (savedSortState !== null && savedSortState !== undefined) {
            // console.log("callGetChanges -> savedSortState")
            gridApi.current.applyColumnState({
                state: savedSortState
            })
        }
        if (savedFilterModel !== null && savedFilterModel !== undefined) {
            // console.log("callGetChanges -> savedFilterModel")
            gridApi.current.setFilterModel(savedFilterModel);
        }
        const ugVl = sessionStorage.getItem("user_type")
        if (ugVl.toLowerCase() === "Admin".toLowerCase() || ugVl.toLowerCase() === "Product Development".toLowerCase()) {
            if (req_tbl === linelist_mp.get("Table_Name")) {
                defaultCostingViewBtnCall()
            }
        }
        if (req_tbl === linelist_mp.get("Table_Name")) {
            console.log("Calling callGetChanges -> prepareSummaryReportData -> S -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
            let gridRowData = new Array();
            gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
            // console.log("gridRowData");
            // console.log(gridRowData);
            let filterMp = new Map()
            filterMp.set("Season Code", [])
            filterMp.set("Department", [])
            filterMp.set("Category", [])
            filterMp.set("Factory", [])
            filterMp.set("Shipping Terms (FOB/DDP/LDP)", [])
            let retVal = prepareSummaryReportData(gridRowData, filterMp)
            // console.log("retVal")
            // console.log(retVal)
            setSessionDataByTblName("Summary_Report", retVal)
            console.log("Called callGetChanges -> prepareSummaryReportData -> E -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
        }
    };
    const copyAndDropRowBtnCall = () => {
        // console.log("In -> copyAndDropRowBtnCall");
        let req_tbl = tabList[tabValue][1]
        if (req_tbl === null || req_tbl === undefined || req_tbl.trim() === "") {
            return
        }
        let tabName = tabList[tabValue][0]
        if (req_tbl !== linelist_mp.get("Table_Name")) {
            toastMessage("'" + tabName + "' has no copy row functionality.");
            return;
        }
        let gridRowData = new Array();
        gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
        // console.log("gridRowData");
        // console.log(gridRowData);
        if (gridRowData === null || gridRowData === undefined || gridRowData.length === 0) {
            toastMessage("No data rows found in '" + tabName + "' tab to copy.");
            return;
        }
        const focusedCell = gridApi.current.getFocusedCell();
        // console.log("focusedCell");
        // console.log(focusedCell);
        if (focusedCell === null || focusedCell === undefined) {
            toastMessage("Please select atleast 1 row to perform Copy And Drop Row functionality.");
            return;
        }
        const row = gridApi.current.getDisplayedRowAtIndex(focusedCell.rowIndex)
        // console.log("row");
        // console.log(row);
        const OneRowData = row.data;
        // console.log("OneRowData")
        // console.log(OneRowData)
        if (OneRowData === null || OneRowData === undefined) {
            return;
        }
        if (linelist_mp.get("Table_Name") !== req_tbl) {
            toastMessage("'Copy And Drop Row' functionality will work only for 'Linelist' tab.");
            return;
        }
        let colsToClearSt = new Set();
        colsToClearSt.add("Change Memo - Status")
        colsToClearSt.add("Change Memo - Notes")
        colsToClearSt.add("Change Memo - Date")
        colsToClearSt.add("Item Upload Status")
        colsToClearSt.add("Delivery Status")
        colsToClearSt.add("DTC Confirmation")
        colsToClearSt.add("Wholesale Confirmation")
        // console.log("colsToClearSt")
        // console.log(colsToClearSt)
        let oneClVl = new Object()
        Object.entries(OneRowData).forEach(en => {
            const [k, v] = en;
            // console.log(k, v);
            if (k === "row_id" || k === "inserted_on" || k === "modified_on" || k === "user_inserted" || k === "user_modified") {
                oneClVl["" + k + ""] = ""
            } else if (k === "Style Activity") {
                oneClVl["" + k + ""] = "DROPPED"
            } else if (colsToClearSt.has(k)) {
                oneClVl["" + k + ""] = ""
            } else {
                oneClVl["" + k + ""] = v
            }
        });
        // console.log("oneClVl")
        // console.log(oneClVl)
        let newData = [];
        newData = [oneClVl, ...gridRowData]
        // console.log("newData")
        // console.log(newData)
        if (linelist_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_LINELIST_DATA", data: JSON.parse(JSON.stringify(newData)) })
        }
    }
    const copyRowBtnCall = () => {
        // console.log("In -> copyRowBtnCall");
        let req_tbl = tabList[tabValue][1]
        // console.log("req_tbl");
        // console.log(req_tbl);
        if (req_tbl === null || req_tbl === undefined || req_tbl.trim() === "") {
            return
        }
        let tabName = tabList[tabValue][0]
        let isAllow = getDataFromMetaData(req_tbl, 'copy_row')
        // console.log("isAllow");
        // console.log(isAllow);
        if (isAllow === null || isAllow === undefined || isAllow.trim() === "" || isAllow.toLowerCase() !== 'Yes'.toLowerCase()) {
            toastMessage("'" + tabName + "' has no copy row functionality.");
            return;
        }
        let gridRowData = new Array();
        gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
        // console.log("gridRowData");
        // console.log(gridRowData);
        if (gridRowData === null || gridRowData === undefined || gridRowData.length === 0) {
            toastMessage("No data rows found in '" + tabName + "' tab to copy.");
            return;
        }
        const focusedCell = gridApi.current.getFocusedCell();
        // console.log("focusedCell");
        // console.log(focusedCell);
        if (focusedCell === null || focusedCell === undefined) {
            toastMessage("Please select atleast 1 row to perform Copy Row functionality.");
            return;
        }
        const row = gridApi.current.getDisplayedRowAtIndex(focusedCell.rowIndex)
        // console.log("row");
        // console.log(row);
        const OneRowData = row.data;
        // console.log("OneRowData")
        // console.log(OneRowData)
        if (OneRowData === null || OneRowData === undefined) {
            return;
        }
        let oneClVl = new Object()
        Object.entries(OneRowData).forEach(en => {
            const [k, v] = en;
            // console.log(k, v);
            if (k === "row_id" || k === "inserted_on" || k === "modified_on" || k === "user_inserted" || k === "user_modified") {
                oneClVl["" + k + ""] = ""
            } else {
                oneClVl["" + k + ""] = v
            }
        });
        // console.log("oneClVl")
        // console.log(oneClVl)
        let newData = new Array()
        newData = [oneClVl, ...gridRowData]
        // console.log("newData")
        // console.log(newData)
        if (ssn_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SEASON_DATA", data: newData });
        } else if (linelist_mp.get("Table_Name") === req_tbl) {
            let sortdata = newData;
            sortdata = sortdata.sort(GetSortOrder("Style Activity"));
            dispatch({ type: "SET_LINELIST_DATA", data: sortdata });
        } else if (master_lov_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "MASTER_LOV_DATA", data: newData });
        } else if (season_inquiry_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SEASON_INQUIRY_DATA", data: newData });
        } else if (shopify_product_type_mapping_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SHOPIFY_TYPE_MAP_DATA", data: newData });
        } else if (size_scales_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SIZE_SCALE_DATA", data: newData });
        } else if (fb_fty_database_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "FB_FTY_DATA", data: newData });
        } else if (chase_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_CHASE_DATA", data: newData });
        } else if (dtc_buy_sheet_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_DTC_BY_SHT_DATA", data: newData });
        } else if (whsl_buy_sheet_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_WHSL_BY_SHT_DATA", data: newData });
        } else if (whsl_buy_sheet_nord_rp_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_WHSL_BY_SHT_NRD_DATA", data: newData });
        } else if (sizing_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "SET_SIZING_DATA", data: newData });
        } else if (buy_sheet_master_sizing_percentage_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "BUY_SHT_SIZE_PER_DATA", data: newData });
        } else if (delivery_master_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "DELIVERY_MASTER_DATA", data: newData });
        } else if (freight_by_category_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "FRIGHT_BY_CAT_DATA", data: newData });
        } else if (freight_master_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "FRIGHT_MASTER_DATA", data: newData });
        } else if (comp_aps_master_storeecom_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "COMP_APS_MASTER_DATA", data: newData });
        } else if (buySht_master_data_charts_mp.get("Table_Name") === req_tbl) {
            dispatch({ type: "BUY_SHT_SIZE_CHART_DATA", data: newData });
        }
    };
    const sendChangeMemoBtnCall = () => {
        // console.log("In -> sendChangeMemoBtnCall");
        const req_tbl = tabList[tabValue][1];
        // console.log("req_tbl -> " + req_tbl);
        if (req_tbl === null || req_tbl === undefined || req_tbl.trim() === "") {
            return;
        }
        const odQryVl = sessionStorage.getItem('asRequiredDownloadQuery');
        // console.log("odQryVl -> " + odQryVl);
        if (odQryVl === null || odQryVl === undefined || odQryVl.trim() === "") {
            toastMessage("Please first perform Download Linelist operation to perform Change Memo functionality.");
            return;
        }
        if (linelist_mp.get("Table_Name") !== req_tbl) {
            toastMessage("Please perform Change Memo from 'Linelist' Tab.");
            return;
        }
        const ugVl = sessionStorage.getItem("user_type");
        // console.log("ugVl -> " + ugVl);
        if (ugVl.toLowerCase() !== "merch".toLowerCase() && ugVl.toLowerCase() !== "admin".toLowerCase()) {
            toastMessage("This functionality is not available for " + ugVl);
            return;
        }
        let gridRowData = new Array();
        gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
        // console.log("gridRowData");
        // console.log(gridRowData);
        if (gridRowData === null || gridRowData === undefined || gridRowData.length === 0) {
            toastMessage("No data found in Linelist tab.");
            return;
        }
        const focusedCell = gridApi.current.getFocusedCell();
        if (focusedCell === null || focusedCell === undefined) {
            toastMessage("Please select atleast 1 row to perform Change Memo functionality.");
            return
        }
        const rIdx = focusedCell.rowIndex
        let selectedRowData = [];
        if (rIdx !== null && rIdx !== undefined) {
            selectedRowData = gridApi.current.getRowNode(rIdx);
        } else {
            toastMessage("Please select atleast 1 row to perform Change Memo functionality.");
            return;
        }
        const rowId = selectedRowData.data['row_id'];
        // console.log("rowId")
        // console.log(rowId)
        if (rowId === null || rowId === undefined || rowId.trim() === "") { }
        let kys = Object.keys(selectedRowData.data);
        // console.log("kys");
        // console.log(kys);
        if (selectedRowData === null || selectedRowData === undefined || kys.length === 0) {
            toastMessage("Please select atleast 1 row to perform Change Memo functionality.");
            return;
        }
        const scVal = selectedRowData.data["Season Code"];
        let cntReadyToSend = 0;
        if (scVal) {
            gridApi.current.forEachNode(nd => {
                if (nd.data["Change Memo - Status"] && nd.data["Season Code"] && scVal) {
                    if (nd.data["Change Memo - Status"].toLowerCase() === "Ready to Send".toLowerCase() && nd.data["Season Code"].toLowerCase() === scVal.toLowerCase()) {
                        cntReadyToSend = cntReadyToSend + 1;
                    }
                }
            });
        }
        // console.log("cntReadyToSend -> " + cntReadyToSend);
        if (cntReadyToSend === 0) {
            toastMessage("No 'Change Memo - Status' = 'Ready to Send' rows found for 'Season Code' = '" + scVal + "' in 'Linelist' tab.");
            return;
        }
        let sendChangesOk = false;
        let llMap = linelist_mp;
        llMap.set("Send_Data_Process_Info", "Send_Change_Memo");
        llMap.set("scVal", scVal);
        llMap.set("processName", "Send Change Memo"); // DON'T change processName due to backend dependency
        // console.log("llMap")
        // console.log(llMap)
        // console.log("myRef.current.validateSendData()");
        // console.log(myRef.current.validateSendData());
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        sendChangesOk = sendDataChangesWithoutBtn(llMap);
    };
    const bulk_import_btn_click = () => {
        let req_tbl = tabList[tabValue][1]
        // console.log("current tab: " + req_tbl)
        if (req_tbl !== chase_mp.get("Table_Name")) {
            toastMessage("Please perform Import from 'Chase' tab.");
            return
        }
        myShipmentRef.current.bulkImport()
    };
    const getTalkToDataTableFunction = async (i) => {
        ttdPopupDetail.current = i;
        const postBody = {
            "param_function": i.function,
            "param_body": {
                "param_user_id": sessionStorage.getItem("email"),
                "param_query": sessionStorage.getItem("asRequiredDownloadQuery") ? sessionStorage.getItem("asRequiredDownloadQuery") : ""
            },
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();
            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                let resJsonData = JSON.parse(json.data);
                // convert ChartData formatter text to react function.
                if (i.view_type === 'chart') {
                    if (resJsonData) {
                        resJsonData.axes.forEach((axis) => {
                            if (axis.label && typeof axis.label.formatter === 'string') {
                                try {
                                    axis.label.formatter = eval(`(${axis.label.formatter})`);
                                } catch (error) {
                                    console.error('Error converting formatter string to function', error);
                                }
                            }
                        });
                    }

                }

                setTtdPopupData(resJsonData);
            }
        } catch (exception) { }
    };
    useEffect(() => {
        const index = tabList.findIndex((tab) => tab[0] === currentTab);
        console.log("tab", index)
        if (index >= 0 && tabValue !== index) {
            setValue(index);
            setActiveTab(currentTab)
        }
    }, [currentTab])
    const getTalkToDataTable = async () => {
        const postBody = {
            "param_function": "ttd_maintable",
            "param_body": {
                "table_name": "app_metadata",
                "user_type": sessionStorage.getItem('user_type')
            }
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();
            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                const resJsonData = JSON.parse(json.data);
                ttdData.current = resJsonData;
                let aa = []
                setTimeout(() => {
                    aa.push(<Grid item>
                        <MDButton
                            size="small"
                            style={{ backgroundColor: 'purple', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                            onClick={() => { }}
                        >
                            🚀Create Your Own Reports🚀
                        </MDButton>
                    </Grid>);
                    if (ttdData.current.length > 0) {
                        ttdData.current.map((i) => {
                            aa.push(
                                <Grid item>
                                    <Tooltip title={i.sub_title} placement="bottom">
                                        <MDButton
                                            startIcon={i.view_type === "table" ? <TableViewIcon /> : <AssessmentIcon />}
                                            size="small"
                                            variant="outlined"
                                            color="dark"
                                            onClick={() => getTalkToDataTableFunction(i)}
                                        >
                                            {i.title}
                                        </MDButton>
                                    </Tooltip>
                                </Grid>
                            )
                        })
                    }
                    //  else {
                    //     aa.push(
                    //         <Grid item>
                    //             Hooray! No suggestions yet!
                    //         </Grid>
                    //     )
                    // }
                    setTtdCards(aa);
                }, 2000)
            }
        } catch (exception) { }
    };
    const getmetaData = async () => {
        sessionStorage.setItem("curr_tab", 0)

        const postBody = {
            "param_function": "sys_get_sys_table_data",
            "param_body": {
                "table_name": "app_metadata",
                "user_role": sessionStorage.getItem('user_type')
            }
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();

            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");


                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                const resJsonData = JSON.parse(json.data);

                metaData.current = resJsonData;
                setMetaDataDownloaded(true)
                dispatch({ type: "SET_APP_METADATA", data: resJsonData });

                // //get master lov data
                // if(tabPermission(master_lov_mp.get("Tab_Name"))){
                //     downloadData(master_lov_mp.get("Table_Name"), "", 0, false);
                // }
                // if(tabPermission(fb_fty_database_mp.get("Tab_Name"))){
                //     downloadData(fb_fty_database_mp.get("Table_Name"), "", 0, false);
                // }
            } else {
                toastMessage("There is an error while getting data.", "warning");
                setMetaDataDownloaded(false)
            }
        } catch (exception) {
            metaData.current = [];
            setMetaDataDownloaded(false)
        }
    };
    const getAppMetaData = async () => {
        const postBody = {
            "param_function": "sys_get_sys_table_data",
            "param_body": {
                "table_name": "app_column_metadata",
                "user_role": sessionStorage.getItem('user_type')
            }
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();
            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                const resJsonData = JSON.parse(json.data);
                setColumnMetaData(resJsonData);
                dispatch({ type: "setColumnMetaData", data: resJsonData });
                // AKD 2024_06_25
                let fxcalc = [{}];
                let allCol = [{}];
                tabList.map((i) => {
                    let app_column_metadata = resJsonData.filter((item) => item.table_name === i[1] && item.formula !== "" && item.formula != null);
                    let key = i[1];
                    if (app_column_metadata.length !== 0) {
                        let value = app_column_metadata.map(item => item.column_name);
                        fxcalc[0][key] = value;
                    } else fxcalc[0][key] = [];
                    // let listofcol = resJsonData.filter((item) => item.table_name === i[1]);
                    // allCol[0][key] = listofcol.map(item => item.column_name);
                });
                dispatch({ type: "setFormulaCalcColLst", data: fxcalc });
                // dispatch({ type: "setTestColLst", data: allCol });
                // ------
            } else {
                toastMessage("There is an error while getting data.", "warning");
            }
        } catch (exception) {
            setColumnMetaData([]);
        }
    };
    const tabPermission = (curSheetName) => {
        let retValue = false;
        metaData.current.map((item) => {
            if (item.sheet_name === curSheetName) {
                item.hide_sheet && item.hide_sheet.toLowerCase() === 'yes' ? retValue = false : retValue = true;
            }
        });
        // console.log(metaData.current)
        // console.log(curSheetName+"...."+retValue)
        return retValue;
    };
    const setPivotTabsNames = () => {
        let mp = new Map()
        if (metaData.current !== null && metaData.current !== undefined && metaData.current.length > 0) {
            for (let i = 0; i < metaData.current.length; i++) {
                let el = metaData.current[i];
                if (el["hide_sheet"] === null || el["hide_sheet"] === undefined || el["hide_sheet"].trim() === "" || el["hide_sheet"].toLowerCase() === "No".toLowerCase()) {
                    if (el["pivot_enabled"] !== null && el["pivot_enabled"] !== undefined && el["pivot_enabled"].trim() !== "" && el["pivot_enabled"].toLowerCase() === "Yes".toLowerCase()) {
                        if (mp.has(el["table_name"]) === false) {
                            for (let j = 0; j < tabList.length; j++) {
                                if (tabList[j][1] === el["table_name"]) {
                                    mp.set(tabList[j][0], el["table_name"])
                                }
                            }
                        }
                    }
                }
                el = null
            }
        }
        dispatch({ type: "mpForTabs", data: mp })
    }
    useEffect(() => {
        if (openTTDDialog) getTalkToDataTable();
        else setTtdCards([]);
    }, [openTTDDialog])
    function onFirstDataRendered(params) {
        params.api.createRangeChart({
            chartContainer: document.querySelector("#myChart"),
            cellRange: {
                columns: [
                    "Year", "Season Code", "Department", "Style", "MSRP"
                ],
            },
            suppressChartRanges: true,
            chartType: "groupedColumn",
            aggFunc: "sum",
            chartThemeOverrides: {
                common: {
                    animation: {
                        enabled: false,
                    },
                },
            },
        });
    }
    useEffect(() => {
        if (ttdPopupData.length !== 0) {
            if (ttdPopupDetail.current.view_type === "table") {
                let colDef = Object.keys(ttdPopupData[0]).map(key => ({ editable: true, field: key, filter: true }));
                let sadsf = <>
                    <div className="ag-theme-quartz" id="agGridStl">
                        <AgGridReact
                            columnDefs={colDef}
                            rowData={ttdPopupData}
                            statusBar={statusBar}
                            enableCharts={true}
                            enableRangeSelection={true}
                            rowSelection="multiple"
                        // onFirstDataRendered={onFirstDataRendered}
                        // pivotMode={true}
                        // columnTypes={{
                        //     measure: {
                        //         chartDataType: "series",
                        //         cellClass: "number",
                        //         // valueFormatter: numberCellFormatter,
                        //         // cellRenderer: "agAnimateShowChangeCellRenderer",
                        //     }
                        // }}
                        />
                    </div>
                </>;
                setTtdView(sadsf);
            }
            else {
                let sadsf = <>
                    <div className="ag-theme-quartz" id="agGridStl">
                        <AgCharts options={ttdPopupData} />
                    </div>
                </>;
                setTtdView(sadsf);
            }
        }
        else setTtdView([])

    }, [ttdPopupData])
    useEffect(() => {
        window.history.pushState(null, "LineList", window.location.href);
        window.addEventListener("popstate", () => {
            window.history.pushState(null, document.title, window.location.href);
        });
        if (!isConnected.current) {
            client.activate();
        }
        sessionStorage.setItem("isDownloadLinelistPerformed", false)
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        getmetaData();
        getAppMetaData();
        getColPref();
        getUserNotifications();
        setTimeout(() => {
            setShowGenie(true);
        }, 5000);
        if (sessionStorage.getItem("user_type") === "admin") setShowSizing(true)

        return () => {
            // Clean up or deactivate the client if needed
            if (client.connected) {
                client.deactivate();
            }
        };
    }, [])
    useEffect(() => {
        if (metaDataDownloaded && columnMetaData.length > 0) {
            setShowLinelist(checkTabPermission("Linelist"))
            setShowDtc(checkTabPermission("DTC_Buy_Sheet"))
            setShowWhsl(checkTabPermission("Whsl_Buy_Sheet"))
            setShowChase(checkTabPermission("Chase"))
            setShowSizing(checkTabPermission("Sizing"))
            SetTablesData("Default", "", "dropdown");
            sessionStorage.setItem("asRequiredDownloadQuery", "");
            setTimeout(() => {
                SetTablesData("Default", "");
            }, 3000);
            setPivotTabsNames();
            getPivotConfig().then(res => {
                dispatch({ type: "pvtsData", data: res })
            })
        }
    }, [metaDataDownloaded, columnMetaData]);
    const finalCostingView = () => {
        const asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
        if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
            toastMessage("Please first perform Download Linelist to perform this operation.")
            return
        }
        if (linelist_mp.get("Table_Name") !== tabList[tabValue][1]) {
            toastMessage("'" + tabList[tabValue][0] + "' has no Default View functionality.")
            return
        }
        if (LinelistData === null || LinelistData === undefined || LinelistData.length === 0) {
            return
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const colsToDisplay = ["Year", "Season Code", "Department", "Style", "Style Description", "Color Description", "MSRP", "WHLS", "Style Activity", "Costing Status", "Previous FOB", "Placeholder/Flash FOB", "Initial Cost",
            "Bulk Cost", "Faherty Target FOB", "Factory Counter", "Final FOB", "Duty Rate", "LDP", "RTL IMU%", "WHLS IMU%", "Brand Units Total", "Brand Total Including Chase",
            "PD Owner", "Factory", "Corporate/Agent", "COO", "Fabric Content", ""
        ];
        const colsVisible = LinelistColDef.filter((col) => colsToDisplay.includes(col.headerName));
        const colsHide = LinelistColDef.filter((col) => !colsToDisplay.includes(col.headerName));
        colsVisible.map((col) => {
            gridApi.current.setColumnVisible(col.colId, true)
        });
        colsHide.map((col) => {
            gridApi.current.setColumnVisible(col.colId, false)
        });
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    }
    const defaultCostingViewBtnCall = () => {
        const email = sessionStorage.getItem('email')
        if (email === null || email === undefined || email.trim() === "") {
            toastMessage("Please first sign in to perform this operation.")
            return
        }
        let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
        if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
            toastMessage("Please first perform Download Linelist to perform this operation.")
            return
        }
        if (linelist_mp.get("Table_Name") !== tabList[tabValue][1]) {
            toastMessage("'" + tabList[tabValue][0] + "' has no Default View functionality.")
            return
        }
        if (LinelistData === null || LinelistData === undefined || LinelistData.length === 0) {
            return
        }
        const ugVl = sessionStorage.getItem("user_type")
        if (ugVl === null || ugVl === undefined || ugVl.trim() === "") {
            return
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let Clm_List_Str = null
        if (ugVl.toLowerCase() !== "product development".toLowerCase()) {
            Clm_List_Str = "Style Season|Delivery Month|Delivery Period|Delivery Date/In DC|In Store|Half Year|Color Code|Seasonal Life Cycle" +
                "|Store Tier|Weeks Bought|Commit Date|Ship Mode|Channels Purchasing|DTC Chase|Wholesale Chase|Size Scale" +
                "|Line Grouping|Collection|Fabric Program|Dev Category|Change Memo - Status|Change Memo - Notes" +
                "|Change Memo - Date|Item Upload Status|Total DTC SLS $|Total WHSL SLS $|Total Brand SLS $" +
                "|300 - FOB|500 - FOB|1000 - FOB|Merch Fee|Harbor Maintenance Fee|Section 301|Duty Total|Agent Commission Rate" +
                "|Air Freight|Sea Freight|Air %|300 - LDP|500 - LDP|1000 - LDP|300 - IMU%|500 - IMU%|1000 - IMU%|DTC Projections" +
                "|WHSL Projections|Total Projections|DTC BOP|DTC BOP + DTC Total Units|Stores|Ecomm|DTC Units Total|International Wholesale Units" +
                "|Wholesale Units Total|DTC Chase Units|Wholesale Chase Units|Brand Chase Units|DTC Units Total + DTC Chase Units" +
                "|Wholesale Units Total + Wholesale Chase Units|Brand Total Including Chase|DTC Confirmation|Wholesale Confirmation" +
                "|Production Lock|Change Flag|Change Type|SKU - Lock|Stores - Lock|Ecomm - Lock|DTC Units Total - Lock|Wholesale Units Total - Lock" +
                "|Brand Units Total - Lock|Locked Date|DTC SMS Units|Wholesale SMS Units|Sales Tier|Factory Type" +
                "|Brand|Mill|Shipping Terms (FOB/DDP/LDP)|Knit or Woven|Special Characteristic" +
                "|Fish and Wildlife|Production COO|Material Mill COO|Fabric / Yarn COO|HTS Code|MOQ|Mandatory MOQ|Delivery Status|X-fty Date" +
                "|Target X-fty Date|Day of Week|Transit Days|CFS Cut Off (Day of week)|In DC Date (Ocean)|Days Late|Global PO Units|Global vs Final" +
                "|Faherty Internal Merch|Faherty Internal PD|Faherty Internal Wholesale|Faherty Internal Planning|SKU Count|Linelist User Assigned 1" +
                "|Linelist User Assigned 2|Linelist User Assigned 3|Linelist User Assigned 4|Linelist User Assigned 5|Linelist User Assigned 6" +
                "|Linelist User Assigned 7|Linelist User Assigned 8|Linelist User Assigned 9|Linelist User Assigned 10"
        } else {
            Clm_List_Str = "Style Season|Delivery Month|Delivery Period|Delivery Date/In DC|In Store|Half Year|Color Code|Seasonal Life Cycle" +
                "|Store Tier|Weeks Bought|Commit Date|Ship Mode|Channels Purchasing|DTC Chase|Wholesale Chase|Size Scale" +
                "|Line Grouping|Collection|Fabric Program|Dev Category|Change Memo - Status|Change Memo - Notes" +
                "|Change Memo - Date|Item Upload Status|Total DTC SLS $|Total WHSL SLS $|Total Brand SLS $" +
                "|Merch Fee|Harbor Maintenance Fee|Section 301|Duty Total|Agent Commission Rate" +
                "|Air Freight|Sea Freight|Air %|DTC Projections|WHSL Projections|Total Projections|DTC BOP" +
                "|DTC BOP + DTC Total Units|Stores|Ecomm|DTC Units Total|International Wholesale Units" +
                "|Wholesale Units Total|DTC Chase Units|Wholesale Chase Units|Brand Chase Units|DTC Units Total + DTC Chase Units" +
                "|Wholesale Units Total + Wholesale Chase Units|Brand Total Including Chase|DTC Confirmation|Wholesale Confirmation" +
                "|Production Lock|Change Flag|Change Type|SKU - Lock|Stores - Lock|Ecomm - Lock|DTC Units Total - Lock|Wholesale Units Total - Lock" +
                "|Brand Units Total - Lock|Locked Date|DTC SMS Units|Wholesale SMS Units|Sales Tier|Factory Type" +
                "|Brand|Mill|Shipping Terms (FOB/DDP/LDP)|Knit or Woven|Special Characteristic" +
                "|Fish and Wildlife|Production COO|Material Mill COO|Fabric / Yarn COO|HTS Code|MOQ|Mandatory MOQ|Delivery Status|X-fty Date" +
                "|Target X-fty Date|Day of Week|Transit Days|CFS Cut Off (Day of week)|In DC Date (Ocean)|Days Late|Global PO Units|Global vs Final" +
                "|Faherty Internal Merch|Faherty Internal PD|Faherty Internal Wholesale|Faherty Internal Planning|SKU Count|Linelist User Assigned 1" +
                "|Linelist User Assigned 2|Linelist User Assigned 3|Linelist User Assigned 4|Linelist User Assigned 5|Linelist User Assigned 6" +
                "|Linelist User Assigned 7|Linelist User Assigned 8|Linelist User Assigned 9|Linelist User Assigned 10"
        }
        const arrCols = Clm_List_Str.split("|")
        if (arrCols !== null && arrCols !== undefined && arrCols.length > 0) {
            for (let i = 0; i < arrCols.length; i++) {
                const el = arrCols[i]
                for (let j = 0; j < LinelistColDef.length; j++) {
                    const lc = LinelistColDef[j]
                    if (el === lc.field) {
                        gridApi.current.setColumnVisible(lc.colId, true)
                    }
                }
            }
        }
        const hideCols = getDataFromMetaData(linelist_mp.get("Table_Name"), 'hide_columns')
        if (hideCols !== null && hideCols !== undefined && hideCols !== "") {
            let lstCls = hideCols.split("|")
            if (lstCls !== null && lstCls !== undefined && lstCls.length > 0) {
                for (let i = 0; i < lstCls.length; i++) {
                    const el = lstCls[i]
                    for (let j = 0; j < LinelistColDef.length; j++) {
                        const lc = LinelistColDef[j]
                        if (el === lc.field) {
                            gridApi.current.setColumnVisible(lc.colId, false)
                        }
                    }
                }
            }
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    }
    const costingViewBtnCall = () => {
        const email = sessionStorage.getItem('email')
        if (email === null || email === undefined || email.trim() === "") {
            toastMessage("Please first sign in to perform this operation.")
            return
        }
        let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
        if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
            toastMessage("Please first perform Download Linelist to perform this operation.")
            return
        }
        if (linelist_mp.get("Table_Name") !== tabList[tabValue][1]) {
            toastMessage("'" + tabList[tabValue][0] + "' has no Costing View functionality.")
            return
        }
        if (LinelistData === null || LinelistData === undefined || LinelistData.length === 0) {
            return
        }
        const ugVl = sessionStorage.getItem("user_type")
        if (ugVl === null || ugVl === undefined || ugVl.trim() === "") {
            return
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let Clm_List_Str = null
        if (ugVl.toLowerCase() !== "product development".toLowerCase()) {
            Clm_List_Str = "Style Season|Delivery Month|Delivery Period|Delivery Date/In DC|In Store|Half Year|Color Code|Seasonal Life Cycle" +
                "|Store Tier|Weeks Bought|Commit Date|Ship Mode|Channels Purchasing|DTC Chase|Wholesale Chase|Size Scale" +
                "|Line Grouping|Collection|Fabric Program|Dev Category|Change Memo - Status|Change Memo - Notes" +
                "|Change Memo - Date|Item Upload Status|Total DTC SLS $|Total WHSL SLS $|Total Brand SLS $" +
                "|300 - FOB|500 - FOB|1000 - FOB|Merch Fee|Harbor Maintenance Fee|Section 301|Duty Total|Agent Commission Rate" +
                "|Air Freight|Sea Freight|Air %|300 - LDP|500 - LDP|1000 - LDP|300 - IMU%|500 - IMU%|1000 - IMU%|DTC Projections" +
                "|WHSL Projections|Total Projections|DTC BOP|DTC BOP + DTC Total Units|Stores|Ecomm|DTC Units Total|International Wholesale Units" +
                "|Wholesale Units Total|DTC Chase Units|Wholesale Chase Units|Brand Chase Units|DTC Units Total + DTC Chase Units" +
                "|Wholesale Units Total + Wholesale Chase Units|Brand Total Including Chase|DTC Confirmation|Wholesale Confirmation" +
                "|Production Lock|Change Flag|Change Type|SKU - Lock|Stores - Lock|Ecomm - Lock|DTC Units Total - Lock|Wholesale Units Total - Lock" +
                "|Brand Units Total - Lock|Locked Date|DTC SMS Units|Wholesale SMS Units|Sales Tier|Factory Type" +
                "|Brand|Mill|Shipping Terms (FOB/DDP/LDP)|Knit or Woven|Special Characteristic" +
                "|Fish and Wildlife|Production COO|Material Mill COO|Fabric / Yarn COO|HTS Code|MOQ|Mandatory MOQ|Delivery Status|X-fty Date" +
                "|Target X-fty Date|Day of Week|Transit Days|CFS Cut Off (Day of week)|In DC Date (Ocean)|Days Late|Global PO Units|Global vs Final" +
                "|Faherty Internal Merch|Faherty Internal PD|Faherty Internal Wholesale|Faherty Internal Planning|SKU Count|Linelist User Assigned 1" +
                "|Linelist User Assigned 2|Linelist User Assigned 3|Linelist User Assigned 4|Linelist User Assigned 5|Linelist User Assigned 6" +
                "|Linelist User Assigned 7|Linelist User Assigned 8|Linelist User Assigned 9|Linelist User Assigned 10"
        } else {
            Clm_List_Str = "Style Season|Delivery Month|Delivery Period|Delivery Date/In DC|In Store|Half Year|Color Code|Seasonal Life Cycle" +
                "|Store Tier|Weeks Bought|Commit Date|Ship Mode|Channels Purchasing|DTC Chase|Wholesale Chase|Size Scale" +
                "|Line Grouping|Collection|Fabric Program|Dev Category|Change Memo - Status|Change Memo - Notes" +
                "|Change Memo - Date|Item Upload Status|Total DTC SLS $|Total WHSL SLS $|Total Brand SLS $" +
                "|Merch Fee|Harbor Maintenance Fee|Section 301|Duty Total|Agent Commission Rate" +
                "|Air Freight|Sea Freight|Air %|DTC Projections|WHSL Projections|Total Projections|DTC BOP" +
                "|DTC BOP + DTC Total Units|Stores|Ecomm|DTC Units Total|International Wholesale Units" +
                "|Wholesale Units Total|DTC Chase Units|Wholesale Chase Units|Brand Chase Units|DTC Units Total + DTC Chase Units" +
                "|Wholesale Units Total + Wholesale Chase Units|Brand Total Including Chase|DTC Confirmation|Wholesale Confirmation" +
                "|Production Lock|Change Flag|Change Type|SKU - Lock|Stores - Lock|Ecomm - Lock|DTC Units Total - Lock|Wholesale Units Total - Lock" +
                "|Brand Units Total - Lock|Locked Date|DTC SMS Units|Wholesale SMS Units|Sales Tier|Factory Type" +
                "|Brand|Mill|Shipping Terms (FOB/DDP/LDP)|Knit or Woven|Special Characteristic" +
                "|Fish and Wildlife|Production COO|Material Mill COO|Fabric / Yarn COO|HTS Code|MOQ|Mandatory MOQ|Delivery Status|X-fty Date" +
                "|Target X-fty Date|Day of Week|Transit Days|CFS Cut Off (Day of week)|In DC Date (Ocean)|Days Late|Global PO Units|Global vs Final" +
                "|Faherty Internal Merch|Faherty Internal PD|Faherty Internal Wholesale|Faherty Internal Planning|SKU Count|Linelist User Assigned 1" +
                "|Linelist User Assigned 2|Linelist User Assigned 3|Linelist User Assigned 4|Linelist User Assigned 5|Linelist User Assigned 6" +
                "|Linelist User Assigned 7|Linelist User Assigned 8|Linelist User Assigned 9|Linelist User Assigned 10"
        }
        const arrCols = Clm_List_Str.split("|")
        if (arrCols !== null && arrCols !== undefined && arrCols.length > 0) {
            for (let i = 0; i < arrCols.length; i++) {
                const el = arrCols[i]
                for (let j = 0; j < LinelistColDef.length; j++) {
                    const lc = LinelistColDef[j]
                    if (el === lc.field) {
                        gridApi.current.setColumnVisible(lc.colId, false)
                    }
                }
            }
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    }
    const activeStyleActivityFilterBtnCall = () => {
        const tbl_name = tabList[tabValue][1];
        let column = tbl_name + CH1 + "Style Activity";
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: ["ACTIVE"],
                    filterType: "set",
                },
            ],
        };
        const filters = {};
        filters[column] = newFilterItem;
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    };
    const droppedStyleActivityFilterBtnCall = () => {
        const tbl_name = tabList[tabValue][1];
        let column = tbl_name + CH1 + "Style Activity";
        let newFilterItem = {
            filterType: "multi",
            filterModels: [
                null,
                {
                    values: ["DROPPED"],
                    filterType: "set",
                },
            ],
        };
        const filters = {};
        filters[column] = newFilterItem;
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    };
    const allStyleActivityFilterBtnCall = () => {
        const tbl_name = tabList[tabValue][1];
        const filters = gridApi.current.getFilterModel();
        let column = tbl_name + CH1 + "Style Activity";
        delete filters[column];
        gridApi.current.setFilterModel(filters);
        gridApi.current.onFilterChanged();
    };
    const Calculate_Sizes = async (tabName) => {
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        await Calculate_Sizes_Logic(tabName)
    }
    const Calculate_Sizes_Logic = async (tabName) => {
        try {
            let req_tbl = tabList[tabValue][1]
            let gridRowData = new Array();
            if (req_tbl === null || req_tbl === undefined || req_tbl.trim() === "") {
                return;
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
            const odQryVl = sessionStorage.getItem('asRequiredDownloadQuery');
            if (odQryVl === null || odQryVl === undefined || odQryVl.trim() === "") {
                toastMessage("Please first perform Download Linelist operation to perform Calculate Sizes functionality.");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                return;
            }
            if (sizing_mp.get("Table_Name") !== req_tbl && chase_mp.get("Table_Name") !== req_tbl) {
                toastMessage("Please perform Calculate Sizes from '" + sizing_mp.get("Tab_Name") + "' or '" + chase_mp.get("Tab_Name") + "' tab.");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                return;
            }
            else {
                dispatch({ type: "SET_PLEASE_WAIT", data: true });
                if (sizing_mp.get("Table_Name") === req_tbl) {
                    if (tabName !== sizing_mp.get("Tab_Name")) {
                        // toastMessage("Please perform Calculate Sizes from '" + sizing_mp.get("Tab_Name") + "' tab.");
                        toastMessage('Please perform Calculate Sizes from Sizing tab.');
                        dispatch({ type: "SET_PLEASE_WAIT", data: false });
                        return;
                    }
                    let apl_sz_frml_cnt = 0;
                    gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
                    if (gridRowData === null || gridRowData === undefined || gridRowData.length === 0) {
                        toastMessage("No data found in Sizing tab.");
                        dispatch({ type: "SET_PLEASE_WAIT", data: false });
                        return;
                    }
                    let a = await getChangesWithoutBtn_(dtc_buy_sheet_mp.get("Table_Name"));
                    let b = await getChangesWithoutBtn_(whsl_buy_sheet_mp.get("Table_Name"));
                    let c = await getChangesWithoutBtn_(buy_sheet_master_sizing_percentage_mp.get("Table_Name"));
                    for (let i = 0; i < gridRowData.length; i++) {
                        if (gridRowData[i]["Apply Sizing Formula"].trim() === "YES") {
                            apl_sz_frml_cnt += 1;
                        }
                    }
                    console.log("Size : Calculate Sizing Start Time --> ", new Date().toLocaleTimeString())
                    if (gridRowData.length > 0) {
                        if (apl_sz_frml_cnt > 0) {
                            Calculate_Sizing_Sizes(gridRowData);

                            gridApi.current.refreshCells({ force: true });
                            console.log("Size : Calculate Sizing End Time --> ", new Date().toLocaleTimeString())
                        } else { return; }
                    } else { return; }
                }
                else if (chase_mp.get("Table_Name") === req_tbl) {
                    // calculate chase sizing code here
                    if (tabName !== chase_mp.get("Tab_Name")) {
                        toastMessage('Please perform Calculate Sizes from Chase tab."');
                        //  + chase_mp.get("Tab_Name") + "' tab."
                        dispatch({ type: "SET_PLEASE_WAIT", data: false });
                        return;
                    }
                    else {
                        gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
                        if (gridRowData === null || gridRowData === undefined || gridRowData.length === 0) {
                            toastMessage("No data found in Chase tab.");
                            dispatch({ type: "SET_PLEASE_WAIT", data: false });
                            return;
                        }

                        console.log("Chase : Calculate Sizing Start Time --> ", new Date().toLocaleTimeString())
                        gridApi.current.forEachNode(nd => gridRowData.push(nd.data));
                        Calculate_chase_Sizes(gridRowData);
                        gridApi.current.refreshCells({ force: true });
                        console.log("Chase : Calculate Sizing End Time --> ", new Date().toLocaleTimeString())
                        // dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    }
                }
            }
        }
        catch (error) {
            console.error("Error in Calculate_Sizes_Logic:", error);
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
        }
    }
    const Calculate_Sizing_Sizes = async (gridRowData) => {
        console.log("... inside Calculate_Sizing_Sizes ... ")
        let dtcbysht = refDtcBuySheet;
        let whslbysht = refWhslBuySheet;
        let byshtmsper = refBuySheetMasterSizingPercentage;
        let refsizing = SizingData;
        let size_list = "2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_"
        let size_list_arr = size_list.split("|")
        console.log("... inside Calculate_chase_Sizes ... ")
        // primaryKey: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat",
        //Customer / account type = Store / ECOM
        // const aggregationInfoC1 = {
        //     primaryKey: "Year|Season Code|Department|Category|Size Scale|Location",
        //     sumColumns: "2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_",
        // };
        // console.log("SizingData --> ", SizingData)
        // let [aggregatedDataC1, countDataC1] = aggregateData(SizingData, aggregationInfoC1);
        // const groupMapInfo = {
        //     primaryKey: "Year|Season Code|Department|Category|Size Scale|Location",
        //     sumColumns: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat|2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_",
        // };
        // let aggmap1 = transposeJSONToMap(aggregatedDataC1, groupMapInfo)
        // let cntmap1 = transposeJSONToMap(countDataC1, groupMapInfo)
        // console.log("aggmap1 --> ", aggmap1)
        // console.log("cntmap1 --> ", cntmap1)
        const bsmpr_aggregationInfo = {
            primaryKey: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat",
            sumColumns: "2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_",
        };
        let [aggregatedData, countData] = aggregateData(refBuySheetMasterSizingPercentage, bsmpr_aggregationInfo);
        const bsmpr_groupMapInfo = {
            primaryKey: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat",
            sumColumns: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat|2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_",
        };
        let bsmpr_aggmap = transposeJSONToMap(aggregatedData, bsmpr_groupMapInfo)
        let bsmpr_cntmap = transposeJSONToMap(countData, bsmpr_groupMapInfo)
        // console.log("bsmpr_aggmap --> ", bsmpr_aggmap)
        // console.log("bsmpr_cntmap --> ", bsmpr_cntmap)
        const whsl_buy_aggregationInfo = {
            primaryKey: "Season Code|Department|Category|SKU|Working Line Status",
            sumColumns: "Ttl Active Acc / Specialty|Ttl Key Acct|Ttl Corp|Ttl Intr|Wholesale Total Unit Override|Ttl Nord|Ttl Nord RP|Evereve|ATS",
        };
        let [whsl_aggregatedData, whsl_countData] = aggregateData(refWhslBuySheet, whsl_buy_aggregationInfo);
        let whsl_aggmap = transposeJSONToMap(whsl_aggregatedData, whsl_buy_aggregationInfo)
        let whsl_cntmap = transposeJSONToMap(whsl_countData, whsl_buy_aggregationInfo)
        const dtc_buy_aggregationInfo = {
            primaryKey: "Season Code|Department|Size Scale|Category|SKU|Working Line Status",
            sumColumns: "Store Final Buy|Final Buy - Ecomm",
        };
        let [dtc_aggregatedData, dtc_countData] = aggregateData(refDtcBuySheet, dtc_buy_aggregationInfo);
        let dtc_aggmap = transposeJSONToMap(dtc_aggregatedData, dtc_buy_aggregationInfo)
        let dtc_cntmap = transposeJSONToMap(dtc_countData, dtc_buy_aggregationInfo)
        // console.log("dtc_aggmap --> ", dtc_aggmap)
        // console.log("dtc_aggmap1 --> ", dtc_aggmap1)
        // gridRowData[i]["Apply Sizing Formula"].trim() === "YES"
        for (let i = 0; i < gridRowData.length; i++) {
            if (gridRowData[i]["Apply Sizing Formula"].trim() === "YES") {
                // Sizing Params Chase Unit Wholesale
                let year = gridRowData[i]["Year"], ssn_cd = gridRowData[i]["Season Code"], dprt = gridRowData[i]["Department"],
                    sz_scale = gridRowData[i]["Size Scale"], category = gridRowData[i]["Category"],
                    subcategory = gridRowData[i]["Subcategory"], location = gridRowData[i]["Location"],
                    sku = gridRowData[i]["SKU"], collection = gridRowData[i]["Collection"],
                    apply_sizing_formula = gridRowData[i]["Apply Sizing Formula"],
                    is_sizeing_override = gridRowData[i]["Sizing Override"]; //Chase Unit Ecomm
                if (year === null || year === undefined || year === "" || year.toString() === "NaN") { year = "" } else { year = year.trim() }
                if (ssn_cd === null || ssn_cd === undefined || ssn_cd === "" || ssn_cd.toString() === "NaN") { ssn_cd = "" } else { ssn_cd = ssn_cd.trim() }
                if (dprt === null || dprt === undefined || dprt === "" || dprt.toString() === "NaN") { dprt = "" } else { dprt = dprt.trim() }
                if (sz_scale === null || sz_scale === undefined || sz_scale === "" || sz_scale.toString() === "NaN") { sz_scale = "" } else { sz_scale = sz_scale.trim() }
                if (category === null || category === undefined || category === "" || category.toString() === "NaN") { category = "" } else { category = category.trim() }
                if (subcategory === null || subcategory === undefined || subcategory === "" || subcategory.toString() === "NaN") { subcategory = "" } else { subcategory = subcategory.trim() }
                if (location === null || location === undefined || location === "" || location.toString() === "NaN") { location = "" } else { location = location.trim() }
                if (sku === null || sku === undefined || sku === "" || sku.toString() === "NaN") { sku = "" } else { sku = sku.trim() }
                if (collection === null || collection === undefined || collection === "" || collection.toString() === "NaN") { collection = "" } else { collection = collection.trim() }
                if (is_sizeing_override === null || is_sizeing_override === undefined || is_sizeing_override === "" || is_sizeing_override.toString() === "NaN") { is_sizeing_override = "" } else { is_sizeing_override = is_sizeing_override.trim() }
                if (apply_sizing_formula === null || apply_sizing_formula === undefined || apply_sizing_formula === "" || apply_sizing_formula.toString() === "NaN") { apply_sizing_formula = "" } else { apply_sizing_formula = apply_sizing_formula.trim() }
                // console.log("key -> ", `${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`)
                // if (sku === 'WTF2431-DIW') {   
                for (let k = 0; k < size_list_arr.length; k++) {
                    let size_value = size_list_arr[k];
                    // ##########Set/ReSet All Parameters#############
                    let szNewColRngPkSubCatColl_str_cnt = 0, szNewColRngPkSubCatColl_ecm_cnt = 0, szNewColRngPkSubCatColl_str_sum = 0, szNewColRngPkSubCatColl_ecm_sum = 0;
                    let szNewColRngPkColl_str_cnt = 0, szNewColRngPkColl_ecm_cnt = 0, szNewColRngPkColl_str_sum = 0, szNewColRngPkColl_ecm_sum = 0;
                    let szNewColRngPkSubCat_str_cnt = 0, szNewColRngPkSubCat_ecm_cnt = 0, szNewColRngPkSubCat_str_sum = 0, szNewColRngPkSubCat_ecm_sum = 0;
                    let szNewColRngPk_str_cnt = 0, szNewColRngPk_ecm_cnt = 0, szNewColRngPk_str_sum = 0, szNewColRngPk_ecm_sum = 0;
                    let szNewColRngPkSubCatColl_gnrc_cnt = 0, szNewColRngPkSubCatColl_gnrc_sum = 0
                    let szNewColRngPkColl_gnrc_cnt = 0, szNewColRngPkColl_gnrc_sum = 0;
                    let szNewColRngPkSubCat_gnrc_cnt = 0, szNewColRngPkSubCat_gnrc_sum = 0;
                    let szNewColRngPk_gnrc_cnt = 0, szNewColRngPk_gnrc_sum = 0;
                    let cstmer_acc_type_str_value = 0, cstmer_acc_type_ecm_value = 0;
                    let cstmer_acc_type_gnrc_value = 0;
                    let rslt_true_cnd = 0, rslt_flase_cnd = 0;
                    // Sizing Formula Variables ---
                    let dtc_final_buy_agg = 0, dtc_final_buy_cnt = 0;
                    let dtc_final_buy_ecom_agg = 0, dtc_final_buy_ecom_cnt = 0;
                    /*
                    Parameters List
                    Sizing
                    szNewColRngPk = "Year|Season Code|Department|Category|Size Scale|Location"
                    szNewColRngPkSubCatColl = "Year|Season Code|Department|Category|Size Scale|Location|Subcategory|Collection"
                    szNewColRngPkSubCat = "Year|Season Code|Department|Category|Size Scale|Location|Subcategory"
                    szNewColRngPkColl = "Year|Season Code|Department|Category|Size Scale|Location|Collection"
                    
                    Buy_Sheet_Master_Sizing_%
                    msNewColRngPkSubCatColl = "Year|Season Code|Department|Category|Size Scale|Channel|Subcat|Collection"
                    ms_pr5 = Range("bmsz_pr_customer_account_type") [Customer / account type]
                    ms_scnm = "bmsz_pr_" & Particular size name
                                        */
                    // BuySheetMasterPer Params
                    let byshtmsper_year = "", byshtmsper_ssn_cd = "", byshtmsper_dprt = "", byshtmsper_category = "", byshtmsper_sz_scale = "", byshtmsper_chnl = "", byshtmsper_subcat = "",
                        byshtmsper_coll = "", byshtmsper_cust_acc_type = "";
                    // ###############################################
                    // SUMIFS(dtc_buy_store_final_buy,@sizing_season_code,@sizing_department,@sizing_size_scale,@sizing_category,@sizing_sku,dtc_buy_working_line_status,"Original"
                    // Start Calculation Logic
                    // dtc map key sequence - : "Season Code|Department|Size Scale|Category|SKU|Working Line Status",
                    // Sizing
                    // szNewColRngPk = "Year|Season Code|Department|Category|Size Scale|Location"
                    // szNewColRngPkSubCatColl = "Year|Season Code|Department|Category|Size Scale|Location|Subcategory|Collection"
                    // szNewColRngPkSubCat = "Year|Season Code|Department|Category|Size Scale|Location|Subcategory"
                    // szNewColRngPkColl = "Year|Season Code|Department|Category|Size Scale|Location|Collection"
                    // Buy_Sheet_Master_Sizing_%
                    // msNewColRngPkSubCatColl = "Year|Season Code|Department|Category|Size Scale|Channel|Subcat|Collection"
                    if (location === "DTC") {
                        // console.log("KEY -> ", (`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase())
                        if (dtc_cntmap.get((`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            dtc_final_buy_cnt = dtc_cntmap.get((`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase())["Store Final Buy"]
                            dtc_final_buy_agg = dtc_aggmap.get((`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase())["Store Final Buy"]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (dtc_cntmap.get((`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            dtc_final_buy_ecom_cnt = dtc_cntmap.get((`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase())["Final Buy - Ecomm"]
                            dtc_final_buy_ecom_agg = dtc_aggmap.get((`${ssn_cd}${dprt}${sz_scale}${category}${sku}Original`).toLowerCase())["Final Buy - Ecomm"]
                            // dtc_final_buy_ecom_agg = dtc_final_buy_ecom_agg === "" ? 0 : dtc_final_buy_ecom_agg 
                        }
                        //  "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_str_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_str_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_str_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_str_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_str_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_str_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_str_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                            szNewColRngPk_str_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_ecm_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_ecm_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_ecm_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_ecm_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_ecm_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_ecm_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_ecm_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                            szNewColRngPk_ecm_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                        }
                        if (szNewColRngPkSubCatColl_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkSubCatColl_str_sum }
                        else {
                            if (szNewColRngPkColl_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkColl_str_sum }
                            else {
                                if (szNewColRngPkSubCat_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkSubCat_str_sum }
                                else {
                                    if (szNewColRngPk_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPk_str_sum }
                                    else { cstmer_acc_type_str_value = 0 }
                                }
                            }
                        }
                        if (szNewColRngPkSubCatColl_ecm_cnt > 0) {
                            //  console.log("cstmer_acc_type_ecm_value 1 " , szNewColRngPkSubCatColl_ecm_sum)
                            cstmer_acc_type_ecm_value = szNewColRngPkSubCatColl_ecm_sum
                        }
                        else {
                            if (szNewColRngPkColl_ecm_cnt > 0) {
                                // console.log("cstmer_acc_type_ecm_value 2 : ", szNewColRngPkColl_ecm_sum)
                                cstmer_acc_type_ecm_value = szNewColRngPkColl_ecm_sum
                            }
                            else {
                                if (szNewColRngPkSubCat_ecm_cnt > 0) {
                                    // console.log("cstmer_acc_type_ecm_value 3 ", szNewColRngPkSubCat_ecm_sum)
                                    cstmer_acc_type_ecm_value = szNewColRngPkSubCat_ecm_sum
                                }
                                else {
                                    if (szNewColRngPk_ecm_cnt > 0) {
                                        // console.log("cstmer_acc_type_ecm_value 4 ", szNewColRngPk_ecm_sum )
                                        cstmer_acc_type_ecm_value = szNewColRngPk_ecm_sum
                                    }
                                    else {
                                        // console.log("cstmer_acc_type_ecm_value not found")
                                        cstmer_acc_type_ecm_value = 0
                                    }
                                }
                            }
                        }
                        // console.log("dtc_final_buy_agg --> " + dtc_final_buy_agg)
                        // console.log("cstmer_acc_type_str_value --> " + cstmer_acc_type_str_value)
                        // console.log("cstmer_acc_type_ecm_value --> " + cstmer_acc_type_ecm_value)
                        // console.log("dtc_final_buy_ecom_agg --> " + dtc_final_buy_ecom_agg)
                        // console.log("parseFloat(dtc_final_buy_agg) * parseFloat(cstmer_acc_type_str_value) --> " + (parseFloat(dtc_final_buy_agg) * parseFloat(cstmer_acc_type_str_value)).toString() )
                        // console.log("parseFloat(dtc_final_buy_ecom_agg) * parseFloat(cstmer_acc_type_ecm_value) --> " + (parseFloat(dtc_final_buy_ecom_agg) * parseFloat(cstmer_acc_type_ecm_value)).toString() )
                        rslt_true_cnd = xlRound((parseFloat(dtc_final_buy_agg) * parseFloat(cstmer_acc_type_str_value)) + (parseFloat(dtc_final_buy_ecom_agg) * parseFloat(cstmer_acc_type_ecm_value)), 0);
                        if (rslt_true_cnd === null || rslt_true_cnd === undefined || rslt_true_cnd === "" || rslt_true_cnd.toString() === "NaN") { rslt_true_cnd = 0 }
                        gridRowData[i][size_value] = rslt_true_cnd;
                    }
                    else {
                        // primaryKey: "Season Code|Department|Category|SKU|Working Line Status",
                        // sumColumns: "Ttl Active Acc / Specialty|Ttl Key Acct|Ttl Corp|Ttl Intr|Wholesale Total Unit Override|Ttl Nord|Ttl Nord RP|Evereve|ATS",
                        // whsl_aggmap
                        let whsl_ttl_unt_overrirde = 0, whsl_buy_ttl_active_acc_specialty = 0, whsl_buy_ttl_key_acct = 0
                        let whsl_buy_ttl_corp = 0, whsl_buy_ttl_intr = 0, whsl_buy_ttl_nord = 0
                        let whsl_buy_ttl_nord_rp = 0, whsl_buy_evereve = 0, whsl_buy_ats = 0
                        // let szNewColRngPkSubCatColl_gnrc_cnt = 0, szNewColRngPkSubCatColl_gnrc_sum = 0
                        // let szNewColRngPkColl_gnrc_cnt = 0, szNewColRngPkColl_gnrc_sum = 0;
                        // let szNewColRngPkSubCat_gnrc_cnt = 0, szNewColRngPkSubCat_gnrc_sum = 0;
                        // let szNewColRngPk_gnrc_cnt = 0, szNewColRngPk_gnrc_sum = 0;
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_ttl_unt_overrirde = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Wholesale Total Unit Override"]
                            // console.log("whsl_ttl_unt_overrirde --> ", whsl_ttl_unt_overrirde)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ttl_active_acc_specialty = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Ttl Active Acc / Specialty"]
                            // console.log("whsl_buy_ttl_active_acc_specialty --> ", whsl_buy_ttl_active_acc_specialty)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ttl_key_acct = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Ttl Key Acct"]
                            // console.log("whsl_ttl_unt_overrirde --> ", whsl_buy_ttl_key_acct)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ttl_corp = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Ttl Corp"]
                            // console.log("whsl_buy_ttl_corp --> ", whsl_buy_ttl_corp)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ttl_intr = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Ttl Intr"]
                            // console.log("whsl_buy_ttl_intr --> ", whsl_buy_ttl_intr)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ttl_nord = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Ttl Nord"]
                            // console.log("whsl_buy_ttl_nord --> ", whsl_buy_ttl_nord)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ttl_nord_rp = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Ttl Nord RP"]
                            // console.log("whsl_buy_ttl_nord_rp --> ", whsl_buy_ttl_nord_rp)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_evereve = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["Evereve"]
                            // console.log("whsl_buy_evereve --> ", whsl_buy_evereve)
                        }
                        if (whsl_cntmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase()) !== undefined) {
                            whsl_buy_ats = whsl_aggmap.get((`${ssn_cd}${dprt}${category}${sku}Original`).toLowerCase())["ATS"]
                            // console.log("whsl_buy_evereve --> ", whsl_buy_ats)
                        }
                        //  "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_gnrc_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_gnrc_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_gnrc_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_gnrc_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_gnrc_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase())[size_value]
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_gnrc_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_gnrc_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_gnrc_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_gnrc_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase())[size_value]
                            szNewColRngPk_gnrc_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (szNewColRngPkSubCatColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCatColl_gnrc_sum }
                        else {
                            if (szNewColRngPkColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkColl_gnrc_sum }
                            else {
                                if (szNewColRngPkSubCat_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCat_gnrc_sum }
                                else {
                                    if (szNewColRngPk_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPk_gnrc_sum }
                                    else { cstmer_acc_type_gnrc_value = 0 }
                                }
                            }
                        }
                        // Nords Standard
                        let szNewColRngPkSubCatColl_nrdstd_cnt = 0, szNewColRngPkSubCatColl_nrdstd_sum = 0
                        let szNewColRngPkColl_nrdstd_cnt = 0, szNewColRngPkColl_nrdstd_sum = 0;
                        let szNewColRngPkSubCat_nrdstd_cnt = 0, szNewColRngPkSubCat_nrdstd_sum = 0;
                        let szNewColRngPk_nrdstd_cnt = 0, szNewColRngPk_nrdstd_sum = 0;
                        let cstmer_acc_type_nrdstd_value = 0;
                        //  "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_nrdstd_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_nrdstd_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_nrdstd_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_nrdstd_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_nrdstd_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}`).toLowerCase())[size_value]
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_nrdstd_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_nrdstd_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_nrdstd_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_nrdstd_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}`).toLowerCase())[size_value]
                            szNewColRngPk_nrdstd_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords Standard${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (szNewColRngPkSubCatColl_nrdstd_cnt > 0) { cstmer_acc_type_nrdstd_value = szNewColRngPkSubCatColl_nrdstd_sum }
                        else {
                            if (szNewColRngPkColl_nrdstd_cnt > 0) { cstmer_acc_type_nrdstd_value = szNewColRngPkColl_nrdstd_sum }
                            else {
                                if (szNewColRngPkSubCat_nrdstd_cnt > 0) { cstmer_acc_type_nrdstd_value = szNewColRngPkSubCat_nrdstd_sum }
                                else {
                                    if (szNewColRngPk_nrdstd_cnt > 0) { cstmer_acc_type_nrdstd_value = szNewColRngPk_nrdstd_sum }
                                    else { cstmer_acc_type_nrdstd_value = 0 }
                                }
                            }
                        }
                        // Nords RP
                        let szNewColRngPkSubCatColl_nrdrp_cnt = 0, szNewColRngPkSubCatColl_nrdrp_sum = 0
                        let szNewColRngPkColl_nrdrp_cnt = 0, szNewColRngPkColl_nrdrp_sum = 0;
                        let szNewColRngPkSubCat_nrdrp_cnt = 0, szNewColRngPkSubCat_nrdrp_sum = 0;
                        let szNewColRngPk_nrdrp_cnt = 0, szNewColRngPk_nrdrp_sum = 0;
                        let cstmer_acc_type_nrdrp_value = 0;
                        //  "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_nrdrp_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_nrdrp_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_nrdrp_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_nrdrp_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_nrdrp_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}`).toLowerCase())[size_value]
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_nrdrp_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_nrdrp_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_nrdrp_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_nrdrp_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}`).toLowerCase())[size_value]
                            szNewColRngPk_nrdrp_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Nords RP${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (szNewColRngPkSubCatColl_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPkSubCatColl_nrdrp_sum }
                        else {
                            if (szNewColRngPkColl_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPkColl_nrdrp_sum }
                            else {
                                if (szNewColRngPkSubCat_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPkSubCat_nrdrp_sum }
                                else {
                                    if (szNewColRngPk_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPk_nrdrp_sum }
                                    else { cstmer_acc_type_nrdrp_value = 0 }
                                }
                            }
                        }
                        // Evereve
                        let szNewColRngPkSubCatColl_evereve_cnt = 0, szNewColRngPkSubCatColl_evereve_sum = 0
                        let szNewColRngPkColl_evereve_cnt = 0, szNewColRngPkColl_evereve_sum = 0;
                        let szNewColRngPkSubCat_evereve_cnt = 0, szNewColRngPkSubCat_evereve_sum = 0;
                        let szNewColRngPk_evereve_cnt = 0, szNewColRngPk_evereve_sum = 0;
                        let cstmer_acc_type_evereve_value = 0;
                        //  "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_evereve_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_evereve_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_evereve_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_evereve_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_evereve_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}`).toLowerCase())[size_value]
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_evereve_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_evereve_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_evereve_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_evereve_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}`).toLowerCase())[size_value]
                            szNewColRngPk_evereve_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Evereve${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (szNewColRngPkSubCatColl_evereve_cnt > 0) { cstmer_acc_type_evereve_value = szNewColRngPkSubCatColl_evereve_sum }
                        else {
                            if (szNewColRngPkColl_evereve_cnt > 0) { cstmer_acc_type_evereve_value = szNewColRngPkColl_evereve_sum }
                            else {
                                if (szNewColRngPkSubCat_evereve_cnt > 0) { cstmer_acc_type_evereve_value = szNewColRngPkSubCat_evereve_sum }
                                else {
                                    if (szNewColRngPk_evereve_cnt > 0) { cstmer_acc_type_evereve_value = szNewColRngPk_evereve_sum }
                                    else { cstmer_acc_type_evereve_value = 0 }
                                }
                            }
                        }
                        // ATS
                        let szNewColRngPkSubCatColl_ats_cnt = 0, szNewColRngPkSubCatColl_ats_sum = 0
                        let szNewColRngPkColl_ats_cnt = 0, szNewColRngPkColl_ats_sum = 0;
                        let szNewColRngPkSubCat_ats_cnt = 0, szNewColRngPkSubCat_ats_sum = 0;
                        let szNewColRngPk_ats_cnt = 0, szNewColRngPk_ats_sum = 0;
                        let cstmer_acc_type_ats_value = 0;
                        //  "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_ats_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_ats_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_ats_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_ats_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_ats_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}`).toLowerCase())[size_value]
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_ats_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_ats_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_ats_sum)
                        }
                        if (bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_ats_cnt = bsmpr_cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}`).toLowerCase())[size_value]
                            szNewColRngPk_ats_sum = bsmpr_aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}ATS${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (szNewColRngPkSubCatColl_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPkSubCatColl_ats_sum }
                        else {
                            if (szNewColRngPkColl_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPkColl_ats_sum }
                            else {
                                if (szNewColRngPkSubCat_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPkSubCat_ats_sum }
                                else {
                                    if (szNewColRngPk_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPk_ats_sum }
                                    else { cstmer_acc_type_ats_value = 0 }
                                }
                            }
                        }
                        //  whsl_buy_ttl_active_acc_specialty whsl_buy_ttl_key_acct whsl_buy_ttl_corp whsl_buy_ttl_intr
                        if (whsl_ttl_unt_overrirde > 0) {
                            rslt_flase_cnd = xlRound((parseInt(whsl_ttl_unt_overrirde) * parseFloat(cstmer_acc_type_gnrc_value)), 0);
                        }
                        else {
                            rslt_flase_cnd = xlRound(
                                (
                                    (
                                        parseFloat(whsl_buy_ttl_active_acc_specialty)
                                        + parseFloat(whsl_buy_ttl_key_acct)
                                        + parseFloat(whsl_buy_ttl_corp)
                                        + parseFloat(whsl_buy_ttl_intr)
                                    )
                                    * parseFloat(cstmer_acc_type_gnrc_value)
                                )
                                + (parseFloat(whsl_buy_ttl_nord) * parseFloat(cstmer_acc_type_nrdstd_value))
                                + (parseFloat(whsl_buy_ttl_nord_rp) * parseFloat(cstmer_acc_type_nrdrp_value))
                                + (parseFloat(whsl_buy_evereve) * parseFloat(cstmer_acc_type_evereve_value))
                                + (parseFloat(whsl_buy_ats) * parseFloat(cstmer_acc_type_ats_value))
                                , 0
                            );
                        }
                        // rslt_flase_cnd = xlRound((parseInt(chase_unit_whsl) * parseFloat(cstmer_acc_type_gnrc_value)), 0);
                        if (rslt_flase_cnd === null || rslt_flase_cnd === undefined || rslt_flase_cnd === "" || rslt_flase_cnd.toString() === "NaN") { rslt_flase_cnd = 0 }
                        gridRowData[i][size_value] = rslt_flase_cnd;
                    }
                }
            }
        }
        setStateDataByTblName(sizing_mp.get("Table_Name"), gridRowData)
        toastMessage("Calculate Sizes completed successfully for '" + sizing_mp.get("Tab_Name") + "' tab.");
    }
    const Calculate_Sizing_Sizes_old = (gridRowData) => {
        console.log("... inside Calculate_Sizing_Sizes ... ")
        let dtcbysht = refDtcBuySheet;
        let whslbysht = refWhslBuySheet;
        let byshtmsper = refBuySheetMasterSizingPercentage;
        let size_list = "2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_"
        let size_list_arr = size_list.split("|")
        for (let i = 0; i < gridRowData.length; i++) {
            // Sizing Params
            let year = gridRowData[i]["Year"], ssn_cd = gridRowData[i]["Season Code"], dprt = gridRowData[i]["Department"], sz_scale = gridRowData[i]["Size Scale"],
                category = gridRowData[i]["Category"], subcategory = gridRowData[i]["Subcategory"], location = gridRowData[i]["Location"], sku = gridRowData[i]["SKU"],
                collection = gridRowData[i]["Collection"], apl_sizing_frml = gridRowData[i]["Apply Sizing Formula"];
            if (year === null || year === undefined || year === "" || year.toString() === "NaN") { year = "" } else { year = year.trim() }
            if (ssn_cd === null || ssn_cd === undefined || ssn_cd === "" || ssn_cd.toString() === "NaN") { ssn_cd = "" } else { ssn_cd = ssn_cd.trim() }
            if (dprt === null || dprt === undefined || dprt === "" || dprt.toString() === "NaN") { dprt = "" } else { dprt = dprt.trim() }
            if (sz_scale === null || sz_scale === undefined || sz_scale === "" || sz_scale.toString() === "NaN") { sz_scale = "" } else { sz_scale = sz_scale.trim() }
            if (category === null || category === undefined || category === "" || category.toString() === "NaN") { category = "" } else { category = category.trim() }
            if (subcategory === null || subcategory === undefined || subcategory === "" || subcategory.toString() === "NaN") { subcategory = "" } else { subcategory = subcategory.trim() }
            if (location === null || location === undefined || location === "" || location.toString() === "NaN") { location = "" } else { location = location.trim() }
            if (sku === null || sku === undefined || sku === "" || sku.toString() === "NaN") { sku = "" } else { sku = sku.trim() }
            if (collection === null || collection === undefined || collection === "" || collection.toString() === "NaN") { collection = "" } else { collection = collection.trim() }
            if (apl_sizing_frml === null || apl_sizing_frml === undefined || apl_sizing_frml === "" || apl_sizing_frml.toString() === "NaN") { apl_sizing_frml = "" } else { apl_sizing_frml = apl_sizing_frml.trim() }
            if (apl_sizing_frml === "YES") {
                for (let k = 0; k < size_list_arr.length; k++) {
                    let size_value = size_list_arr[k];
                    // ##########Set/ReSet All Parameters#############
                    let szNewColRngPkSubCatColl_str_cnt = 0, szNewColRngPkSubCatColl_ecm_cnt = 0, szNewColRngPkSubCatColl_str_sum = 0, szNewColRngPkSubCatColl_ecm_sum = 0;
                    let szNewColRngPkColl_str_cnt = 0, szNewColRngPkColl_ecm_cnt = 0, szNewColRngPkColl_str_sum = 0, szNewColRngPkColl_ecm_sum = 0;
                    let szNewColRngPkSubCat_str_cnt = 0, szNewColRngPkSubCat_ecm_cnt = 0, szNewColRngPkSubCat_str_sum = 0, szNewColRngPkSubCat_ecm_sum = 0;
                    let szNewColRngPk_str_cnt = 0, szNewColRngPk_ecm_cnt = 0, szNewColRngPk_str_sum = 0, szNewColRngPk_ecm_sum = 0;
                    let szNewColRngPkSubCatColl_gnrc_cnt = 0, szNewColRngPkSubCatColl_nrdstnd_cnt = 0, szNewColRngPkSubCatColl_nrdrp_cnt = 0, szNewColRngPkSubCatColl_evrve_cnt = 0, szNewColRngPkSubCatColl_ats_cnt = 0,
                        szNewColRngPkSubCatColl_gnrc_sum = 0, szNewColRngPkSubCatColl_nrdstnd_sum = 0, szNewColRngPkSubCatColl_nrdrp_sum = 0, szNewColRngPkSubCatColl_evrve_sum = 0, szNewColRngPkSubCatColl_ats_sum = 0;
                    let szNewColRngPkColl_gnrc_cnt = 0, szNewColRngPkColl_nrdstnd_cnt = 0, szNewColRngPkColl_nrdrp_cnt = 0, szNewColRngPkColl_evrve_cnt = 0, szNewColRngPkColl_ats_cnt = 0,
                        szNewColRngPkColl_gnrc_sum = 0, szNewColRngPkColl_nrdstnd_sum = 0, szNewColRngPkColl_nrdrp_sum = 0, szNewColRngPkColl_evrve_sum = 0, szNewColRngPkColl_ats_sum = 0;
                    let szNewColRngPkSubCat_gnrc_cnt = 0, szNewColRngPkSubCat_nrdstnd_cnt = 0, szNewColRngPkSubCat_nrdrp_cnt = 0, szNewColRngPkSubCat_evrve_cnt = 0, szNewColRngPkSubCat_ats_cnt = 0,
                        szNewColRngPkSubCat_gnrc_sum = 0, szNewColRngPkSubCat_nrdstnd_sum = 0, szNewColRngPkSubCat_nrdrp_sum = 0, szNewColRngPkSubCat_evrve_sum = 0, szNewColRngPkSubCat_ats_sum = 0;
                    let szNewColRngPk_gnrc_cnt = 0, szNewColRngPk_nrdstnd_cnt = 0, szNewColRngPk_nrdrp_cnt = 0, szNewColRngPk_evrve_cnt = 0, szNewColRngPk_ats_cnt = 0,
                        szNewColRngPk_gnrc_sum = 0, szNewColRngPk_nrdstnd_sum = 0, szNewColRngPk_nrdrp_sum = 0, szNewColRngPk_evrve_sum = 0, szNewColRngPk_ats_sum = 0;
                    let cstmer_acc_type_str_value = 0, cstmer_acc_type_ecm_value = 0;
                    let cstmer_acc_type_gnrc_value = 0, cstmer_acc_type_nrdstnd_value = 0, cstmer_acc_type_nrdrp_value = 0, cstmer_acc_type_evrve_value = 0, cstmer_acc_type_ats_value = 0;
                    let rslt_true_cnd = 0, rslt_flase_cnd = 0;
                    let dtc_buy_store_final_buy = 0, dtc_buy_final_buy_ecomm = 0;
                    let whsl_buy_wholesale_total_unit_override = 0, whsl_buy_ttl_active_acc_specialty = 0, whsl_buy_ttl_key_acct = 0, whsl_buy_ttl_corp = 0,
                        whsl_buy_ttl_intr = 0, whsl_buy_ttl_nord = 0, whsl_buy_ttl_nord_rp = 0, whsl_buy_evereve = 0, whsl_buy_ats = 0;
                    // DtcBuySheet Params
                    let dtcbysht_ssn_cd = "", dtcbysht_dprt = "", dtcbysht_sz_scale = "", dtcbysht_category = "", dtcbysht_sku = "", dtcbysht_wrk_line_sts = "";
                    // BuySheetMasterPer Params
                    let byshtmsper_year = "", byshtmsper_ssn_cd = "", byshtmsper_dprt = "", byshtmsper_category = "", byshtmsper_sz_scale = "", byshtmsper_chnl = "", byshtmsper_subcat = "",
                        byshtmsper_coll = "", byshtmsper_cust_acc_type = "";
                    // WhslBuySheet Params
                    let whslbysht_ssn_cd = "", whslbysht_dprt = "", whslbysht_category = "", whslbysht_sku = "", whslbysht_wrk_line_sts = "";
                    // ###############################################
                    // Start Calculation Logic
                    if (location === "DTC") {
                        // refDtcBuySheet
                        for (let j = 0; j < dtcbysht.length; j++) {
                            dtcbysht_ssn_cd = dtcbysht[j]["Season Code"], dtcbysht_dprt = dtcbysht[j]["Department"], dtcbysht_sz_scale = dtcbysht[j]["Size Scale"];
                            dtcbysht_category = dtcbysht[j]["Category"], dtcbysht_sku = dtcbysht[j]["SKU"], dtcbysht_wrk_line_sts = dtcbysht[j]["Working Line Status"];
                            if (dtcbysht_ssn_cd === null || dtcbysht_ssn_cd === undefined || dtcbysht_ssn_cd === "" || dtcbysht_ssn_cd.toString() === "NaN") { dtcbysht_ssn_cd = "" } else { dtcbysht_ssn_cd = dtcbysht_ssn_cd.trim() }
                            if (dtcbysht_dprt === null || dtcbysht_dprt === undefined || dtcbysht_dprt === "" || dtcbysht_dprt.toString() === "NaN") { dtcbysht_dprt = "" } else { dtcbysht_dprt = dtcbysht_dprt.trim() }
                            if (dtcbysht_sz_scale === null || dtcbysht_sz_scale === undefined || dtcbysht_sz_scale === "" || dtcbysht_sz_scale.toString() === "NaN") { dtcbysht_sz_scale = "" } else { dtcbysht_sz_scale = dtcbysht_sz_scale.trim() }
                            if (dtcbysht_category === null || dtcbysht_category === undefined || dtcbysht_category === "" || dtcbysht_category.toString() === "NaN") { dtcbysht_category = "" } else { dtcbysht_category = dtcbysht_category.trim() }
                            if (dtcbysht_sku === null || dtcbysht_sku === undefined || dtcbysht_sku === "" || dtcbysht_sku.toString() === "NaN") { dtcbysht_sku = "" } else { dtcbysht_sku = dtcbysht_sku.trim() }
                            if (dtcbysht_wrk_line_sts === null || dtcbysht_wrk_line_sts === undefined || dtcbysht_wrk_line_sts === "" || dtcbysht_wrk_line_sts.toString() === "NaN") { dtcbysht_wrk_line_sts = "" } else { dtcbysht_wrk_line_sts = dtcbysht_wrk_line_sts.trim() }
                            // if (dtcbysht_ssn_cd === ssn_cd && dtcbysht_dprt === dprt && dtcbysht_sz_scale === sz_scale
                            //     && dtcbysht_category === category && dtcbysht_sku === sku && dtcbysht_wrk_line_sts === "Original") {
                            //     dtc_buy_store_final_buy += parseInt(dtcbysht[j]["Store Final Buy"] ? dtcbysht[j]["Store Final Buy"] : "0")
                            //     dtc_buy_final_buy_ecomm += parseInt(dtcbysht[j]["Final Buy - Ecomm"] ? dtcbysht[j]["Final Buy - Ecomm"] : "0")
                            // }
                            if ((dtcbysht_ssn_cd + dtcbysht_dprt + dtcbysht_sz_scale + dtcbysht_category + dtcbysht_sku + dtcbysht_wrk_line_sts).toLowerCase() ===
                                (ssn_cd + dprt + sz_scale + category + sku + "Original").toLowerCase()) {
                                dtc_buy_store_final_buy += parseInt(dtcbysht[j]["Store Final Buy"] ? dtcbysht[j]["Store Final Buy"] : "0")
                                dtc_buy_final_buy_ecomm += parseInt(dtcbysht[j]["Final Buy - Ecomm"] ? dtcbysht[j]["Final Buy - Ecomm"] : "0")
                            }
                        }
                        // refBuySheetMasterSizingPercentage
                        for (let m = 0; m < byshtmsper.length; m++) {
                            byshtmsper_year = byshtmsper[m]["Year"], byshtmsper_ssn_cd = byshtmsper[m]["Season Code"], byshtmsper_dprt = byshtmsper[m]["Department"], byshtmsper_category = byshtmsper[m]["Category"];
                            byshtmsper_sz_scale = byshtmsper[m]["Size Scale"], byshtmsper_chnl = byshtmsper[m]["Channel"], byshtmsper_subcat = byshtmsper[m]["Subcat"];
                            byshtmsper_coll = byshtmsper[m]["Collection"], byshtmsper_cust_acc_type = byshtmsper[m]["Customer / account type"];
                            if (byshtmsper_year === null || byshtmsper_year === undefined || byshtmsper_year === "" || byshtmsper_year.toString() === "NaN") { byshtmsper_year = "" } else { byshtmsper_year = byshtmsper_year.trim() }
                            if (byshtmsper_ssn_cd === null || byshtmsper_ssn_cd === undefined || byshtmsper_ssn_cd === "" || byshtmsper_ssn_cd.toString() === "NaN") { byshtmsper_ssn_cd = "" } else { byshtmsper_ssn_cd = byshtmsper_ssn_cd.trim() }
                            if (byshtmsper_dprt === null || byshtmsper_dprt === undefined || byshtmsper_dprt === "" || byshtmsper_dprt.toString() === "NaN") { byshtmsper_dprt = "" } else { byshtmsper_dprt = byshtmsper_dprt.trim() }
                            if (byshtmsper_category === null || byshtmsper_category === undefined || byshtmsper_category === "" || byshtmsper_category.toString() === "NaN") { byshtmsper_category = "" } else { byshtmsper_category = byshtmsper_category.trim() }
                            if (byshtmsper_sz_scale === null || byshtmsper_sz_scale === undefined || byshtmsper_sz_scale === "" || byshtmsper_sz_scale.toString() === "NaN") { byshtmsper_sz_scale = "" } else { byshtmsper_sz_scale = byshtmsper_sz_scale.trim() }
                            if (byshtmsper_chnl === null || byshtmsper_chnl === undefined || byshtmsper_chnl === "" || byshtmsper_chnl.toString() === "NaN") { byshtmsper_chnl = "" } else { byshtmsper_chnl = byshtmsper_chnl.trim() }
                            if (byshtmsper_subcat === null || byshtmsper_subcat === undefined || byshtmsper_subcat === "" || byshtmsper_subcat.toString() === "NaN") { byshtmsper_subcat = "" } else { byshtmsper_subcat = byshtmsper_subcat.trim() }
                            if (byshtmsper_coll === null || byshtmsper_coll === undefined || byshtmsper_coll === "" || byshtmsper_coll.toString() === "NaN") { byshtmsper_coll = "" } else { byshtmsper_coll = byshtmsper_coll.trim() }
                            if (byshtmsper_cust_acc_type === null || byshtmsper_cust_acc_type === undefined || byshtmsper_cust_acc_type === "" || byshtmsper_cust_acc_type.toString() === "NaN") { byshtmsper_cust_acc_type = "" } else { byshtmsper_cust_acc_type = byshtmsper_cust_acc_type.trim() }
                            // ["Customer / account type"]= "Stores" conditions
                            // szNewColRngPkSubCatColl                           
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat.toLowerCase() === subcategory.toLowerCase()
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "Stores") {
                            //     szNewColRngPkSubCatColl_str_cnt += 1;
                            //     szNewColRngPkSubCatColl_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "Stores").toLowerCase()) {
                                szNewColRngPkSubCatColl_str_cnt += 1;
                                szNewColRngPkSubCatColl_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "Stores") {
                            //     szNewColRngPkColl_str_cnt += 1;
                            //     szNewColRngPkColl_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "Stores").toLowerCase()) {
                                szNewColRngPkColl_str_cnt += 1;
                                szNewColRngPkColl_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "Stores") {
                            //     szNewColRngPkSubCat_str_cnt += 1;
                            //     szNewColRngPkSubCat_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "Stores").toLowerCase()) {
                                szNewColRngPkSubCat_str_cnt += 1;
                                szNewColRngPkSubCat_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "Stores") {
                            //     szNewColRngPk_str_cnt += 1;
                            //     szNewColRngPk_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "Stores").toLowerCase()) {
                                szNewColRngPk_str_cnt += 1;
                                szNewColRngPk_str_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // ["Customer / account type"]= "Ecomm" conditions
                            // szNewColRngPkSubCatColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat.toLowerCase() === subcategory.toLowerCase()
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "Ecomm") {
                            //     szNewColRngPkSubCatColl_ecm_cnt += 1;
                            //     szNewColRngPkSubCatColl_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "Ecomm").toLowerCase()) {
                                szNewColRngPkSubCatColl_ecm_cnt += 1;
                                szNewColRngPkSubCatColl_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "Ecomm") {
                            //     szNewColRngPkColl_ecm_cnt += 1;
                            //     szNewColRngPkColl_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "Ecomm").toLowerCase()) {
                                szNewColRngPkColl_ecm_cnt += 1;
                                szNewColRngPkColl_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "Ecomm") {
                            //     szNewColRngPkSubCat_ecm_cnt += 1;
                            //     szNewColRngPkSubCat_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "Ecomm").toLowerCase()) {
                                szNewColRngPkSubCat_ecm_cnt += 1;
                                szNewColRngPkSubCat_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "Ecomm") {
                            //     szNewColRngPk_ecm_cnt += 1;
                            //     szNewColRngPk_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "Ecomm").toLowerCase()) {
                                szNewColRngPk_ecm_cnt += 1;
                                szNewColRngPk_ecm_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                        }
                        // ["Customer / account type"]= "Store" conditions
                        if (szNewColRngPkSubCatColl_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkSubCatColl_str_sum }
                        else {
                            if (szNewColRngPkColl_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkColl_str_sum }
                            else {
                                if (szNewColRngPkSubCat_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkSubCat_str_sum }
                                else {
                                    if (szNewColRngPk_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPk_str_sum }
                                    else { cstmer_acc_type_str_value = 0 }
                                }
                            }
                        }
                        // ["Customer / account type"]= "Ecomm" conditions
                        if (szNewColRngPkSubCatColl_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPkSubCatColl_ecm_sum }
                        else {
                            if (szNewColRngPkColl_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPkColl_ecm_sum }
                            else {
                                if (szNewColRngPkSubCat_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPkSubCat_ecm_sum }
                                else {
                                    if (szNewColRngPk_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPk_ecm_sum }
                                    else { cstmer_acc_type_ecm_value = 0 }
                                }
                            }
                        }
                        // rslt_true_cnd = Math.round(parseInt(dtc_buy_store_final_buy) * parseFloat(cstmer_acc_type_str_value) + parseInt(dtc_buy_final_buy_ecomm) * parseFloat(cstmer_acc_type_ecm_value));
                        rslt_true_cnd = xlRound((parseInt(dtc_buy_store_final_buy) * parseFloat(cstmer_acc_type_str_value) + parseInt(dtc_buy_final_buy_ecomm) * parseFloat(cstmer_acc_type_ecm_value)), 0);
                        if (rslt_true_cnd === null || rslt_true_cnd === undefined || rslt_true_cnd === "" || rslt_true_cnd.toString() === "NaN") { rslt_true_cnd = 0 }
                        gridRowData[i][size_value] = rslt_true_cnd;
                    }
                    else {
                        // refWhslBuySheet
                        for (let n = 0; n < whslbysht.length; n++) {
                            whslbysht_ssn_cd = whslbysht[n]["Season Code"], whslbysht_dprt = whslbysht[n]["Department"];
                            whslbysht_category = whslbysht[n]["Category"], whslbysht_sku = whslbysht[n]["SKU"], whslbysht_wrk_line_sts = whslbysht[n]["Working Line Status"];
                            if (whslbysht_ssn_cd === null || whslbysht_ssn_cd === undefined || whslbysht_ssn_cd === "" || whslbysht_ssn_cd.toString() === "NaN") { whslbysht_ssn_cd = "" } else { whslbysht_ssn_cd = whslbysht_ssn_cd.trim() }
                            if (whslbysht_dprt === null || whslbysht_dprt === undefined || whslbysht_dprt === "" || whslbysht_dprt.toString() === "NaN") { whslbysht_dprt = "" } else { whslbysht_dprt = whslbysht_dprt.trim() }
                            if (whslbysht_category === null || whslbysht_category === undefined || whslbysht_category === "" || whslbysht_category.toString() === "NaN") { whslbysht_category = "" } else { whslbysht_category = whslbysht_category.trim() }
                            if (whslbysht_sku === null || whslbysht_sku === undefined || whslbysht_sku === "" || whslbysht_sku.toString() === "NaN") { whslbysht_sku = "" } else { whslbysht_sku = whslbysht_sku.trim() }
                            if (whslbysht_wrk_line_sts === null || whslbysht_wrk_line_sts === undefined || whslbysht_wrk_line_sts === "" || whslbysht_wrk_line_sts.toString() === "NaN") { whslbysht_wrk_line_sts = "" } else { whslbysht_wrk_line_sts = whslbysht_wrk_line_sts.trim() }
                            // if (whslbysht_ssn_cd === ssn_cd && whslbysht_dprt === dprt && whslbysht_category === category
                            //     && whslbysht_sku === sku && whslbysht_wrk_line_sts === "Original") {
                            //     whsl_buy_wholesale_total_unit_override += parseInt(whslbysht[n]["Wholesale Total Unit Override"] ? whslbysht[n]["Wholesale Total Unit Override"] : "0")
                            //     whsl_buy_ttl_active_acc_specialty += parseInt(whslbysht[n]["Ttl Active Acc / Specialty"] ? whslbysht[n]["Ttl Active Acc / Specialty"] : "0")
                            //     whsl_buy_ttl_key_acct += parseInt(whslbysht[n]["Ttl Key Acct"] ? whslbysht[n]["Ttl Key Acct"] : "0")
                            //     whsl_buy_ttl_corp += parseInt(whslbysht[n]["Ttl Corp"] ? whslbysht[n]["Ttl Corp"] : "0")
                            //     whsl_buy_ttl_intr += parseInt(whslbysht[n]["Ttl Intr"] ? whslbysht[n]["Ttl Intr"] : "0")
                            //     whsl_buy_ttl_nord += parseInt(whslbysht[n]["Ttl Nord"] ? whslbysht[n]["Ttl Nord"] : "0")
                            //     whsl_buy_ttl_nord_rp += parseInt(whslbysht[n]["Ttl Nord RP"] ? whslbysht[n]["Ttl Nord RP"] : "0")
                            //     whsl_buy_evereve += parseInt(whslbysht[n]["Evereve"] ? whslbysht[n]["Evereve"] : "0")
                            //     whsl_buy_ats += parseInt(whslbysht[n]["ATS"] ? whslbysht[n]["ATS"] : "0")
                            // }
                            if ((whslbysht_ssn_cd + whslbysht_dprt + whslbysht_category + whslbysht_sku + whslbysht_wrk_line_sts).toLowerCase() ===
                                (ssn_cd + dprt + category + sku + "Original").toLowerCase()) {
                                whsl_buy_wholesale_total_unit_override += parseInt(whslbysht[n]["Wholesale Total Unit Override"] ? whslbysht[n]["Wholesale Total Unit Override"] : "0")
                                whsl_buy_ttl_active_acc_specialty += parseInt(whslbysht[n]["Ttl Active Acc / Specialty"] ? whslbysht[n]["Ttl Active Acc / Specialty"] : "0")
                                whsl_buy_ttl_key_acct += parseInt(whslbysht[n]["Ttl Key Acct"] ? whslbysht[n]["Ttl Key Acct"] : "0")
                                whsl_buy_ttl_corp += parseInt(whslbysht[n]["Ttl Corp"] ? whslbysht[n]["Ttl Corp"] : "0")
                                whsl_buy_ttl_intr += parseInt(whslbysht[n]["Ttl Intr"] ? whslbysht[n]["Ttl Intr"] : "0")
                                whsl_buy_ttl_nord += parseInt(whslbysht[n]["Ttl Nord"] ? whslbysht[n]["Ttl Nord"] : "0")
                                whsl_buy_ttl_nord_rp += parseInt(whslbysht[n]["Ttl Nord RP"] ? whslbysht[n]["Ttl Nord RP"] : "0")
                                whsl_buy_evereve += parseInt(whslbysht[n]["Evereve"] ? whslbysht[n]["Evereve"] : "0")
                                whsl_buy_ats += parseInt(whslbysht[n]["ATS"] ? whslbysht[n]["ATS"] : "0")
                            }
                        }
                        // refBuySheetMasterSizingPercentage
                        for (let m = 0; m < byshtmsper.length; m++) {
                            byshtmsper_year = byshtmsper[m]["Year"], byshtmsper_ssn_cd = byshtmsper[m]["Season Code"], byshtmsper_dprt = byshtmsper[m]["Department"], byshtmsper_category = byshtmsper[m]["Category"];
                            byshtmsper_sz_scale = byshtmsper[m]["Size Scale"], byshtmsper_chnl = byshtmsper[m]["Channel"], byshtmsper_subcat = byshtmsper[m]["Subcat"];
                            byshtmsper_coll = byshtmsper[m]["Collection"], byshtmsper_cust_acc_type = byshtmsper[m]["Customer / account type"];
                            if (byshtmsper_year === null || byshtmsper_year === undefined || byshtmsper_year === "" || byshtmsper_year.toString() === "NaN") { byshtmsper_year = "" } else { byshtmsper_year = byshtmsper_year.trim() }
                            if (byshtmsper_ssn_cd === null || byshtmsper_ssn_cd === undefined || byshtmsper_ssn_cd === "" || byshtmsper_ssn_cd.toString() === "NaN") { byshtmsper_ssn_cd = "" } else { byshtmsper_ssn_cd = byshtmsper_ssn_cd.trim() }
                            if (byshtmsper_dprt === null || byshtmsper_dprt === undefined || byshtmsper_dprt === "" || byshtmsper_dprt.toString() === "NaN") { byshtmsper_dprt = "" } else { byshtmsper_dprt = byshtmsper_dprt.trim() }
                            if (byshtmsper_category === null || byshtmsper_category === undefined || byshtmsper_category === "" || byshtmsper_category.toString() === "NaN") { byshtmsper_category = "" } else { byshtmsper_category = byshtmsper_category.trim() }
                            if (byshtmsper_sz_scale === null || byshtmsper_sz_scale === undefined || byshtmsper_sz_scale === "" || byshtmsper_sz_scale.toString() === "NaN") { byshtmsper_sz_scale = "" } else { byshtmsper_sz_scale = byshtmsper_sz_scale.trim() }
                            if (byshtmsper_chnl === null || byshtmsper_chnl === undefined || byshtmsper_chnl === "" || byshtmsper_chnl.toString() === "NaN") { byshtmsper_chnl = "" } else { byshtmsper_chnl = byshtmsper_chnl.trim() }
                            if (byshtmsper_subcat === null || byshtmsper_subcat === undefined || byshtmsper_subcat === "" || byshtmsper_subcat.toString() === "NaN") { byshtmsper_subcat = "" } else { byshtmsper_subcat = byshtmsper_subcat.trim() }
                            if (byshtmsper_coll === null || byshtmsper_coll === undefined || byshtmsper_coll === "" || byshtmsper_coll.toString() === "NaN") { byshtmsper_coll = "" } else { byshtmsper_coll = byshtmsper_coll.trim() }
                            if (byshtmsper_cust_acc_type === null || byshtmsper_cust_acc_type === undefined || byshtmsper_cust_acc_type === "" || byshtmsper_cust_acc_type.toString() === "NaN") { byshtmsper_cust_acc_type = "" } else { byshtmsper_cust_acc_type = byshtmsper_cust_acc_type.trim() }
                            // ["Customer / account type"]= "Generic" conditions
                            // szNewColRngPkSubCatColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "Generic") {
                            //     szNewColRngPkSubCatColl_gnrc_cnt += 1;
                            //     szNewColRngPkSubCatColl_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "Generic").toLowerCase()) {
                                szNewColRngPkSubCatColl_gnrc_cnt += 1;
                                szNewColRngPkSubCatColl_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "Generic") {
                            //     szNewColRngPkColl_gnrc_cnt += 1;
                            //     szNewColRngPkColl_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "Generic").toLowerCase()) {
                                szNewColRngPkColl_gnrc_cnt += 1;
                                szNewColRngPkColl_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "Generic") {
                            //     szNewColRngPkSubCat_gnrc_cnt += 1;
                            //     szNewColRngPkSubCat_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "Generic").toLowerCase()) {
                                szNewColRngPkSubCat_gnrc_cnt += 1;
                                szNewColRngPkSubCat_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "Generic") {
                            //     szNewColRngPk_gnrc_cnt += 1;
                            //     szNewColRngPk_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "Generic").toLowerCase()) {
                                szNewColRngPk_gnrc_cnt += 1;
                                szNewColRngPk_gnrc_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // ["Customer / account type"]= "Nords Standard" conditions
                            // szNewColRngPkSubCatColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "Nords Standard") {
                            //     szNewColRngPkSubCatColl_nrdstnd_cnt += 1;
                            //     szNewColRngPkSubCatColl_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "Nords Standard").toLowerCase()) {
                                szNewColRngPkSubCatColl_nrdstnd_cnt += 1;
                                szNewColRngPkSubCatColl_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "Nords Standard") {
                            //     szNewColRngPkColl_nrdstnd_cnt += 1;
                            //     szNewColRngPkColl_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "Nords Standard").toLowerCase()) {
                                szNewColRngPkColl_nrdstnd_cnt += 1;
                                szNewColRngPkColl_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "Nords Standard") {
                            //     szNewColRngPkSubCat_nrdstnd_cnt += 1;
                            //     szNewColRngPkSubCat_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "Nords Standard").toLowerCase()) {
                                szNewColRngPkSubCat_nrdstnd_cnt += 1;
                                szNewColRngPkSubCat_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "Nords Standard") {
                            //     szNewColRngPk_nrdstnd_cnt += 1;
                            //     szNewColRngPk_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "Nords Standard").toLowerCase()) {
                                szNewColRngPk_nrdstnd_cnt += 1;
                                szNewColRngPk_nrdstnd_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // ["Customer / account type"]= "Nords RP" conditions
                            // szNewColRngPkSubCatColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "Nords RP") {
                            //     szNewColRngPkSubCatColl_nrdrp_cnt += 1;
                            //     szNewColRngPkSubCatColl_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "Nords RP").toLowerCase()) {
                                szNewColRngPkSubCatColl_nrdrp_cnt += 1;
                                szNewColRngPkSubCatColl_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "Nords RP") {
                            //     szNewColRngPkColl_nrdrp_cnt += 1;
                            //     szNewColRngPkColl_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "Nords RP").toLowerCase()) {
                                szNewColRngPkColl_nrdrp_cnt += 1;
                                szNewColRngPkColl_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "Nords RP") {
                            //     szNewColRngPkSubCat_nrdrp_cnt += 1;
                            //     szNewColRngPkSubCat_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "Nords RP").toLowerCase()) {
                                szNewColRngPkSubCat_nrdrp_cnt += 1;
                                szNewColRngPkSubCat_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "Nords RP") {
                            //     szNewColRngPk_nrdrp_cnt += 1;
                            //     szNewColRngPk_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "Nords RP").toLowerCase()) {
                                szNewColRngPk_nrdrp_cnt += 1;
                                szNewColRngPk_nrdrp_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // ["Customer / account type"]= "Evereve" conditions
                            // szNewColRngPkSubCatColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "Evereve") {
                            //     szNewColRngPkSubCatColl_evrve_cnt += 1;
                            //     szNewColRngPkSubCatColl_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "Evereve").toLowerCase()) {
                                szNewColRngPkSubCatColl_evrve_cnt += 1;
                                szNewColRngPkSubCatColl_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "Evereve") {
                            //     szNewColRngPkColl_evrve_cnt += 1;
                            //     szNewColRngPkColl_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "Evereve").toLowerCase()) {
                                szNewColRngPkColl_evrve_cnt += 1;
                                szNewColRngPkColl_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "Evereve") {
                            //     szNewColRngPkSubCat_evrve_cnt += 1;
                            //     szNewColRngPkSubCat_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "Evereve").toLowerCase()) {
                                szNewColRngPkSubCat_evrve_cnt += 1;
                                szNewColRngPkSubCat_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "Evereve") {
                            //     szNewColRngPk_evrve_cnt += 1;
                            //     szNewColRngPk_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "Evereve").toLowerCase) {
                                szNewColRngPk_evrve_cnt += 1;
                                szNewColRngPk_evrve_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // ["Customer / account type"]= "ATS" conditions
                            // szNewColRngPkSubCatColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_coll === collection && byshtmsper_cust_acc_type === "ATS") {
                            //     szNewColRngPkSubCatColl_ats_cnt += 1;
                            //     szNewColRngPkSubCatColl_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + collection + "ATS").toLowerCase()) {
                                szNewColRngPkSubCatColl_ats_cnt += 1;
                                szNewColRngPkSubCatColl_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkColl
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_coll === collection
                            //     && byshtmsper_cust_acc_type === "ATS") {
                            //     szNewColRngPkColl_ats_cnt += 1;
                            //     szNewColRngPkColl_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_coll + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + collection + "ATS").toLowerCase()) {
                                szNewColRngPkColl_ats_cnt += 1;
                                szNewColRngPkColl_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPkSubCat
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_subcat === subcategory
                            //     && byshtmsper_cust_acc_type === "ATS") {
                            //     szNewColRngPkSubCat_ats_cnt += 1;
                            //     szNewColRngPkSubCat_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_subcat + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + subcategory + "ATS").toLowerCase()) {
                                szNewColRngPkSubCat_ats_cnt += 1;
                                szNewColRngPkSubCat_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                            // szNewColRngPk
                            // if (byshtmsper_year === year && byshtmsper_ssn_cd === ssn_cd && byshtmsper_dprt === dprt && byshtmsper_category === category
                            //     && byshtmsper_sz_scale === sz_scale && byshtmsper_chnl === location && byshtmsper_cust_acc_type === "ATS") {
                            //     szNewColRngPk_ats_cnt += 1;
                            //     szNewColRngPk_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            // }
                            if ((byshtmsper_year + byshtmsper_ssn_cd + byshtmsper_dprt + byshtmsper_category + byshtmsper_sz_scale + byshtmsper_chnl + byshtmsper_cust_acc_type).toLowerCase() ===
                                (year + ssn_cd + dprt + category + sz_scale + location + "ATS").toLowerCase()) {
                                szNewColRngPk_ats_cnt += 1;
                                szNewColRngPk_ats_sum += parseFloat(byshtmsper[m][size_value] ? byshtmsper[m][size_value] : "0")
                            }
                        }
                        if (whsl_buy_wholesale_total_unit_override > 0) {
                            // ["Customer / account type"]= "Generic" conditions
                            if (szNewColRngPkSubCatColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCatColl_gnrc_sum }
                            else {
                                if (szNewColRngPkColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkColl_gnrc_sum }
                                else {
                                    if (szNewColRngPkSubCat_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCat_gnrc_sum }
                                    else {
                                        if (szNewColRngPk_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPk_gnrc_sum }
                                        else { cstmer_acc_type_gnrc_value = 0 }
                                    }
                                }
                            }
                            // rslt_flase_cnd = Math.round(parseInt(whsl_buy_wholesale_total_unit_override) * parseFloat(cstmer_acc_type_gnrc_value));
                            rslt_flase_cnd = xlRound((parseInt(whsl_buy_wholesale_total_unit_override) * parseFloat(cstmer_acc_type_gnrc_value)), 0);
                            if (rslt_flase_cnd === null || rslt_flase_cnd === undefined || rslt_flase_cnd === "" || rslt_flase_cnd.toString() === "NaN") { rslt_flase_cnd = 0 }
                            gridRowData[i][size_value] = rslt_flase_cnd;
                        }
                        else {
                            // ["Customer / account type"]= "Generic" conditions
                            if (szNewColRngPkSubCatColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCatColl_gnrc_sum }
                            else {
                                if (szNewColRngPkColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkColl_gnrc_sum }
                                else {
                                    if (szNewColRngPkSubCat_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCat_gnrc_sum }
                                    else {
                                        if (szNewColRngPk_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPk_gnrc_sum }
                                        else { cstmer_acc_type_gnrc_value = 0 }
                                    }
                                }
                            }
                            // ["Customer / account type"]= "Nords Standard" conditions
                            if (szNewColRngPkSubCatColl_nrdstnd_cnt > 0) { cstmer_acc_type_nrdstnd_value = szNewColRngPkSubCatColl_nrdstnd_sum }
                            else {
                                if (szNewColRngPkColl_nrdstnd_cnt > 0) { cstmer_acc_type_nrdstnd_value = szNewColRngPkColl_nrdstnd_sum }
                                else {
                                    if (szNewColRngPkSubCat_nrdstnd_cnt > 0) { cstmer_acc_type_nrdstnd_value = szNewColRngPkSubCat_nrdstnd_sum }
                                    else {
                                        if (szNewColRngPk_nrdstnd_cnt > 0) { cstmer_acc_type_nrdstnd_value = szNewColRngPk_nrdstnd_sum }
                                        else { cstmer_acc_type_nrdstnd_value = 0 }
                                    }
                                }
                            }
                            // ["Customer / account type"]= "Nords RP" conditions
                            if (szNewColRngPkSubCatColl_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPkSubCatColl_nrdrp_sum }
                            else {
                                if (szNewColRngPkColl_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPkColl_nrdrp_sum }
                                else {
                                    if (szNewColRngPkSubCat_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPkSubCat_nrdrp_sum }
                                    else {
                                        if (szNewColRngPk_nrdrp_cnt > 0) { cstmer_acc_type_nrdrp_value = szNewColRngPk_nrdrp_sum }
                                        else { cstmer_acc_type_nrdrp_value = 0 }
                                    }
                                }
                            }
                            // ["Customer / account type"]= "Evereve" conditions
                            if (szNewColRngPkSubCatColl_evrve_cnt > 0) { cstmer_acc_type_evrve_value = szNewColRngPkSubCatColl_evrve_sum }
                            else {
                                if (szNewColRngPkColl_evrve_cnt > 0) { cstmer_acc_type_evrve_value = szNewColRngPkColl_evrve_sum }
                                else {
                                    if (szNewColRngPkSubCat_evrve_cnt > 0) { cstmer_acc_type_evrve_value = szNewColRngPkSubCat_evrve_sum }
                                    else {
                                        if (szNewColRngPk_evrve_cnt > 0) { cstmer_acc_type_evrve_value = szNewColRngPk_evrve_sum }
                                        else { cstmer_acc_type_evrve_value = 0 }
                                    }
                                }
                            }
                            // ["Customer / account type"]= "ATS" conditions
                            if (szNewColRngPkSubCatColl_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPkSubCatColl_ats_sum }
                            else {
                                if (szNewColRngPkColl_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPkColl_ats_sum }
                                else {
                                    if (szNewColRngPkSubCat_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPkSubCat_ats_sum }
                                    else {
                                        if (szNewColRngPk_ats_cnt > 0) { cstmer_acc_type_ats_value = szNewColRngPk_ats_sum }
                                        else { cstmer_acc_type_ats_value = 0 }
                                    }
                                }
                            }
                            // rslt_flase_cnd = Math.round((parseInt(whsl_buy_ttl_active_acc_specialty) + parseInt(whsl_buy_ttl_key_acct) + parseInt(whsl_buy_ttl_corp) + parseInt(whsl_buy_ttl_intr)) * parseFloat(cstmer_acc_type_gnrc_value)
                            //     + parseInt(whsl_buy_ttl_nord) * parseFloat(cstmer_acc_type_nrdstnd_value) + parseInt(whsl_buy_ttl_nord_rp) * parseFloat(cstmer_acc_type_nrdrp_value)
                            //     + parseInt(whsl_buy_evereve) * parseFloat(cstmer_acc_type_evrve_value) + parseInt(whsl_buy_ats) * parseFloat(cstmer_acc_type_ats_value));
                            rslt_flase_cnd = xlRound(((parseInt(whsl_buy_ttl_active_acc_specialty) + parseInt(whsl_buy_ttl_key_acct) + parseInt(whsl_buy_ttl_corp) + parseInt(whsl_buy_ttl_intr)) * parseFloat(cstmer_acc_type_gnrc_value)
                                + parseInt(whsl_buy_ttl_nord) * parseFloat(cstmer_acc_type_nrdstnd_value) + parseInt(whsl_buy_ttl_nord_rp) * parseFloat(cstmer_acc_type_nrdrp_value)
                                + parseInt(whsl_buy_evereve) * parseFloat(cstmer_acc_type_evrve_value) + parseInt(whsl_buy_ats) * parseFloat(cstmer_acc_type_ats_value)), 0);
                            if (rslt_flase_cnd === null || rslt_flase_cnd === undefined || rslt_flase_cnd === "" || rslt_flase_cnd.toString() === "NaN") { rslt_flase_cnd = 0 }
                            gridRowData[i][size_value] = rslt_flase_cnd;
                        }
                    }
                }
            }
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    }
    function aggregateData(data, aggregationInfo) {
        const primaryKeyColumns = aggregationInfo.primaryKey.split('|');
        const sumColumns = aggregationInfo.sumColumns.split('|');
        // console.log("data")
        // console.log(data)
        const aggregatedData = {};
        const countData = {};
        data.forEach((row) => {
            const key = primaryKeyColumns.map((col) => row[col].toLowerCase()).join('|');
            // console.log(" key *************> ", key)
            if (!aggregatedData[key]) {
                aggregatedData[key] = {};
                countData[key] = {}
                primaryKeyColumns.forEach((col) => {
                    aggregatedData[key][col] = row[col] ? row[col].toLowerCase() : "";
                    countData[key][col] = row[col].toLowerCase()
                });
                sumColumns.forEach((col) => {
                    let colval = 0;
                    if (row[col]) {
                        if (row[col].toString().trim() !== "" && row[col] !== null && row[col] !== undefined) {
                            colval = parseFloat(row[col]);
                        }
                    }
                    aggregatedData[key][col] = colval.toString().trim() === "" ? 0 : colval;
                    aggregatedData[key][col] = aggregatedData[key][col] === "" ? 0 : aggregatedData[key][col]
                    // aggregatedData[key][col] = row[col] ? parseFloat(row[col]) : 0;                    
                    countData[key][col] = 1
                });
            } else {
                sumColumns.forEach((col) => {
                    let colval2 = 0;
                    if (row[col]) {
                        if (row[col].toString().trim() !== "" && row[col] !== null && row[col] !== undefined) {
                            colval2 = parseFloat(row[col]);
                        }
                    }
                    aggregatedData[key][col] += colval2.toString().trim() === "" ? 0 : colval2;
                    aggregatedData[key][col] = aggregatedData[key][col] === "" ? 0 : aggregatedData[key][col]
                    // aggregatedData[key][col] += row[col] ? parseFloat(row[col]) : 0;
                    countData[key][col] += 1
                });
            }
        });
        return [Object.values(aggregatedData), Object.values(countData)];
    }
    function transposeJSONToMap(jsonData, aggregationInfo) {
        const primaryKey = aggregationInfo.primaryKey.split('|');
        const valueColumns = aggregationInfo.sumColumns.split('|');
        const transposedMap = new Map();
        jsonData.forEach((item) => {
            const key = primaryKey.map((col) => item[col]).join('');
            const values = valueColumns.reduce((acc, col) => {
                if (item[col] === null || item[col] === undefined || item[col] === "") {
                    acc[col] = ""; // Assign "" for null, undefined, or ""
                } else {
                    acc[col] = item[col]; // Preserve numeric 0 and other non-empty values
                }
                return acc;
            }, {});
            transposedMap.set(key, values);
        });
        return transposedMap;
    }
    const Calculate_chase_Sizes = (gridRowData) => {
        // console.log("... inside Calculate_chase_Sizes ... ")
        const aggregationInfo = {
            primaryKey: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat",
            sumColumns: "2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_",
        };
        let [aggregatedData, countData] = aggregateData(refBuySheetMasterSizingPercentage, aggregationInfo);
        const groupMapInfo = {
            primaryKey: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat",
            sumColumns: "Year|Season Code|Department|Category|Size Scale|Customer / account type|Channel|Subcat|2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_",
        };
        let aggmap = transposeJSONToMap(aggregatedData, groupMapInfo)
        let cntmap = transposeJSONToMap(countData, groupMapInfo)
        console.log("aggmap --> ", aggmap)
        console.log("cntmap --> ", cntmap)
        let dtcbysht = refDtcBuySheet;
        let whslbysht = refWhslBuySheet;
        let byshtmsper = refBuySheetMasterSizingPercentage;
        let size_list = "2T|3T|4T|5|6|8|10|OS|XXS|XS|S|M|L|XL|XXL|XXXL|XS/S|S/M|M/L|L/XL|MT|LT|XLT|XXLT|00_|0|2|4|6_|8_|10_|12|14|16|24|25|26|27|28|29|30|31|32|33|34|35|36|38|40|42|30T|32T|34T|36T|38T|40T|28WX30L|29WX30L|30WX30L|31WX30L|32WX30L|33WX30L|34WX30L|35WX30L|36WX30L|38WX30L|40WX30L|42WX30L|28WX32L|29WX32L|30WX32L|31WX32L|32WX32L|33WX32L|34WX32L|35WX32L|36WX32L|38WX32L|40WX32L|42WX32L|28WX34L|29WX34L|30WX34L|31WX34L|32WX34L|33WX34L|34WX34L|35WX34L|36WX34L|38WX34L|40WX34L|42WX34L|1XL|2XL|3XL|4XL|5XL|1XLT|2XLT|3XLT|4XLT|40W36L|42W32L|42W34L|44W30L|44W32L|44W34L|46W30L|46W32L|48W30L|48W32L|50W30L|52W30L|36_|38_|40_|42_|44_|46_|48_|50_"
        let size_list_arr = size_list.split("|")
        let mpbyshtmspercount = {}
        for (let i = 0; i < gridRowData.length; i++) {
            // Sizing Params Chase Unit Wholesale
            let year = gridRowData[i]["Year"], ssn_cd = gridRowData[i]["Season Code"], dprt = gridRowData[i]["Department"],
                sz_scale = gridRowData[i]["Size Scale"], category = gridRowData[i]["Category"],
                subcategory = gridRowData[i]["Subcategory"], location = gridRowData[i]["Location"],
                sku = gridRowData[i]["SKU"], collection = gridRowData[i]["Collection"],
                chaseUnitStores = gridRowData[i]["Chase Unit Stores"], chase_unit_ecomm = gridRowData[i]["Chase Unit Ecomm"],
                is_sizeing_override = gridRowData[i]["Sizing Override"], chase_unit_whsl = gridRowData[i]["Chase Unit Wholesale"]; //Chase Unit Ecomm
            if (year === null || year === undefined || year === "" || year.toString() === "NaN") { year = "" } else { year = year.trim() }
            if (ssn_cd === null || ssn_cd === undefined || ssn_cd === "" || ssn_cd.toString() === "NaN") { ssn_cd = "" } else { ssn_cd = ssn_cd.trim() }
            if (dprt === null || dprt === undefined || dprt === "" || dprt.toString() === "NaN") { dprt = "" } else { dprt = dprt.trim() }
            if (sz_scale === null || sz_scale === undefined || sz_scale === "" || sz_scale.toString() === "NaN") { sz_scale = "" } else { sz_scale = sz_scale.trim() }
            if (category === null || category === undefined || category === "" || category.toString() === "NaN") { category = "" } else { category = category.trim() }
            if (subcategory === null || subcategory === undefined || subcategory === "" || subcategory.toString() === "NaN") { subcategory = "" } else { subcategory = subcategory.trim() }
            if (location === null || location === undefined || location === "" || location.toString() === "NaN") { location = "" } else { location = location.trim() }
            if (sku === null || sku === undefined || sku === "" || sku.toString() === "NaN") { sku = "" } else { sku = sku.trim() }
            if (collection === null || collection === undefined || collection === "" || collection.toString() === "NaN") { collection = "" } else { collection = collection.trim() }
            if (is_sizeing_override === null || is_sizeing_override === undefined || is_sizeing_override === "" || is_sizeing_override.toString() === "NaN") { is_sizeing_override = "" } else { is_sizeing_override = is_sizeing_override.trim() }
            if (chaseUnitStores === null || chaseUnitStores === undefined || chaseUnitStores === "" || chaseUnitStores.toString() === "NaN") { chaseUnitStores = "0" } else { chaseUnitStores = chaseUnitStores.trim() }
            if (chase_unit_ecomm === null || chase_unit_ecomm === undefined || chase_unit_ecomm === "" || chase_unit_ecomm.toString() === "NaN") { chase_unit_ecomm = "0" } else { chase_unit_ecomm = chase_unit_ecomm.trim() }
            if (chase_unit_whsl === null || chase_unit_whsl === undefined || chase_unit_whsl === "" || chase_unit_whsl.toString() === "NaN") { chase_unit_whsl = "0" } else { chase_unit_whsl = chase_unit_whsl.trim() }
            if (is_sizeing_override !== "YES") {
                //if (parseInt(chaseUnitStores) !==) {
                for (let k = 0; k < size_list_arr.length; k++) {
                    let size_value = size_list_arr[k];
                    // ##########Set/ReSet All Parameters#############
                    let szNewColRngPkSubCatColl_str_cnt = 0, szNewColRngPkSubCatColl_ecm_cnt = 0, szNewColRngPkSubCatColl_str_sum = 0, szNewColRngPkSubCatColl_ecm_sum = 0;
                    let szNewColRngPkColl_str_cnt = 0, szNewColRngPkColl_ecm_cnt = 0, szNewColRngPkColl_str_sum = 0, szNewColRngPkColl_ecm_sum = 0;
                    let szNewColRngPkSubCat_str_cnt = 0, szNewColRngPkSubCat_ecm_cnt = 0, szNewColRngPkSubCat_str_sum = 0, szNewColRngPkSubCat_ecm_sum = 0;
                    let szNewColRngPk_str_cnt = 0, szNewColRngPk_ecm_cnt = 0, szNewColRngPk_str_sum = 0, szNewColRngPk_ecm_sum = 0;
                    let szNewColRngPkSubCatColl_gnrc_cnt = 0, szNewColRngPkSubCatColl_gnrc_sum = 0
                    let szNewColRngPkColl_gnrc_cnt = 0, szNewColRngPkColl_gnrc_sum = 0;
                    let szNewColRngPkSubCat_gnrc_cnt = 0, szNewColRngPkSubCat_gnrc_sum = 0;
                    let szNewColRngPk_gnrc_cnt = 0, szNewColRngPk_gnrc_sum = 0;
                    let cstmer_acc_type_str_value = 0, cstmer_acc_type_ecm_value = 0;
                    let cstmer_acc_type_gnrc_value = 0;
                    let rslt_true_cnd = 0, rslt_flase_cnd = 0;
                    // BuySheetMasterPer Params
                    let byshtmsper_year = "", byshtmsper_ssn_cd = "", byshtmsper_dprt = "", byshtmsper_category = "", byshtmsper_sz_scale = "", byshtmsper_chnl = "", byshtmsper_subcat = "",
                        byshtmsper_coll = "", byshtmsper_cust_acc_type = "";
                    // ###############################################
                    // Start Calculation Logic
                    if (location === "DTC") {
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_str_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_str_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_str_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_str_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_str_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_str_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_str_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                            szNewColRngPk_str_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Stores${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_ecm_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_ecm_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkColl_ecm_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                            szNewColRngPkColl_ecm_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_ecm_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_ecm_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}${subcategory}`).toLowerCase())[size_value]
                            // console.log(szNewColRngPkSubCatColl_str_sum)
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPk_ecm_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                            szNewColRngPk_ecm_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Ecomm${location}`).toLowerCase())[size_value]
                        }
                        if (szNewColRngPkSubCatColl_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkSubCatColl_str_sum }
                        else {
                            if (szNewColRngPkColl_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkColl_str_sum }
                            else {
                                if (szNewColRngPkSubCat_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPkSubCat_str_sum }
                                else {
                                    if (szNewColRngPk_str_cnt > 0) { cstmer_acc_type_str_value = szNewColRngPk_str_sum }
                                    else { cstmer_acc_type_str_value = 0 }
                                }
                            }
                        }
                        if (szNewColRngPkSubCatColl_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPkSubCatColl_ecm_sum }
                        else {
                            if (szNewColRngPkColl_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPkColl_ecm_sum }
                            else {
                                if (szNewColRngPkSubCat_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPkSubCat_ecm_sum }
                                else {
                                    if (szNewColRngPk_ecm_cnt > 0) { cstmer_acc_type_ecm_value = szNewColRngPk_ecm_sum }
                                    else { cstmer_acc_type_ecm_value = 0 }
                                }
                            }
                        }
                        rslt_true_cnd = xlRound((parseInt(chaseUnitStores) * parseFloat(cstmer_acc_type_str_value)
                            + parseInt(chase_unit_ecomm) * parseFloat(cstmer_acc_type_ecm_value)), 0);
                        if (rslt_true_cnd === null || rslt_true_cnd === undefined || rslt_true_cnd === "" || rslt_true_cnd.toString() === "NaN") { rslt_true_cnd = 0 }
                        gridRowData[i][size_value] = rslt_true_cnd;
                    }
                    else {
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_gnrc_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_gnrc_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCatColl_gnrc_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase())[size_value]
                            szNewColRngPkSubCatColl_gnrc_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}`).toLowerCase())[size_value]
                        }
                        if (cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase()) !== undefined) {
                            szNewColRngPkSubCat_gnrc_cnt = cntmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                            szNewColRngPkSubCat_gnrc_sum = aggmap.get((`${year}${ssn_cd}${dprt}${category}${sz_scale}Generic${location}${subcategory}`).toLowerCase())[size_value]
                        }
                        if (szNewColRngPkSubCatColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCatColl_gnrc_sum }
                        else {
                            if (szNewColRngPkColl_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkColl_gnrc_sum }
                            else {
                                if (szNewColRngPkSubCat_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPkSubCat_gnrc_sum }
                                else {
                                    if (szNewColRngPk_gnrc_cnt > 0) { cstmer_acc_type_gnrc_value = szNewColRngPk_gnrc_sum }
                                    else { cstmer_acc_type_gnrc_value = 0 }
                                }
                            }
                        }
                        rslt_flase_cnd = xlRound((parseInt(chase_unit_whsl) * parseFloat(cstmer_acc_type_gnrc_value)), 0);
                        if (rslt_flase_cnd === null || rslt_flase_cnd === undefined || rslt_flase_cnd === "" || rslt_flase_cnd.toString() === "NaN") { rslt_flase_cnd = 0 }
                        gridRowData[i][size_value] = rslt_flase_cnd;
                    }
                }
            }
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
        setStateDataByTblName(chase_mp.get("Table_Name"), gridRowData)
        toastMessage("Calculate Sizes completed successfully for '" + chase_mp.get("Tab_Name") + "' tab.");
    }
    const setActionItemDataFun = (data, gridApi, table_name) => {
        // async function setActionItemDataFun (data, gridApi.current) {
        setActionItemTableName(table_name)
        //aid_info_mp
        //  console.log(jsUser)
        ll_gridApi.current = gridApi;
        actionItemTabName.current = table_name;
        // setActionItemRowId("")
        setaiTitleValue("")
        setremarkValue("")
        setAiTxtTask("")
        setStatusValue("Open")
        setaiStartDateValue("")
        setaiEndDateValue("")
        setpriorityValue("High")
        // setCreateNewActionItem(false)
        createNewActionItem.current = true
        isActionItemUpdate.current = false
        isAiAssignee.current = true
        let rng = "Action_Item_Rng"
        let apTableSessionData = getSessionDataByTblName(table_name);
        //setAiTxtTask(event.target.value)
        let useremail = sessionStorage.getItem('email')
        let assigendArray = []
        let tasklistarray = []
        let taskTitlearray = []
        let check_row_id = undefined
        let rid = ""
        // getAllActiveUserData().then(res => {
        getUserData(data?.row_id, table_name).then(res => {
            if (res === "invalid_token") {
                toastMessage("Session Expired", "warning");
                sessionStorage.clear()
                setTimeout(() => {
                    nevigate("/login");
                }, 5000)
            } else {
                //  console.log("setActionItemDataFun -> getAllActiveUserData -> res")
                //  console.log(res)
                if (res === null || res === undefined || res === "") {
                } else {
                    ActiveUserData.current = res;
                    if (table_name === 'xl_action_item_details') {
                        setSrcForAiData(data)
                        isAiAssignee.current = false
                        // console.log("<--- nd -->")
                        // console.log(taskDetails["TaskTitle"] + "<--->" + nd["Assigned To"])
                        if (data["user_inserted"] === useremail) {
                            isAiAssignee.current = true
                        }
                        let userAssigned = data["Assigned To"].split(";")
                        let jsonString = ""
                        let tasktitle = ""
                        tasktitle = data["Task Title"]
                        // jsonString = `{"${tasktitle}":${JSON.stringify("")}}`
                        rid = data["row_id"].toString()
                        jsonString = `{"${data}":${JSON.stringify(data)}}`
                        tasklistarray.push(JSON.parse(jsonString))
                        // jsonString = `{ "TaskTitle":"${tasktitle}","AssignedTo":"${data["Assigned To"]}","row_id":${nd["row_id"]}}`
                        jsonString = `{ "TaskTitle":"${tasktitle}","AssignedTo":"${data["Assigned To"]}","row_id":${data["row_id"]}}`
                        taskTitlearray.push(JSON.parse(jsonString))
                        // taskTitlearray[`"${tasktitle}"`]=JSON.parse(jsonString)
                        setAiTxtTask(data["Task Description"])
                        setaiTitleValue(data["Task Title"])
                        setremarkValue(data["Comments"])
                        setStatusValue(data["Status"])
                        setaiStartDateValue(data["Start Date"])
                        setaiEndDateValue(data["Finish Date"])
                        setpriorityValue(data["Priority"])
                        setActionItemRowId(data["row_id"].toString())
                        check_row_id = data["row_id"].toString()
                        // setisActionItemUpdate(true)
                        isActionItemUpdate.current = true
                        // if (nd["row_id"] === check_row_id){ 
                        // console.log("from here...>")
                        data["Assigned To"].split(";").forEach(username => {
                            ActiveUserData.current.forEach(userdata => {

                                if (userdata.email === username) {
                                    assigendArray.push(userdata)

                                }
                            });
                        });
                        // }
                        setActionItemAssigned(assigendArray)
                        setActionItemTaskTitlelist(taskTitlearray)
                        setActionItemAssignedTasklist(tasklistarray)
                        setBlActionItem(true)
                    }
                    else {
                        // setActiveUserData(res)
                        ActiveUserData.current = res;
                        setSrcForAiData(data)
                        // const [actionItemAssignedTasklist, setActionItemAssignedTasklist] = useState([]);
                        // const [actionItemTaskTitlelist, setActionItemTaskTitlelist] = useState([]);
                        // console.log("apTableSessionData -> ", new Date())
                        // console.log(apTableSessionData)
                        apTableSessionData.forEach(nd => {
                            // console.log(nd)
                            // if (nd["Assigned To"] === useremail && nd["data_table_row_id"] === data["row_id"]) {
                            if (nd["data_table_row_id"] === data["row_id"] && table_name === nd['tab_name']) {
                                let userAssigned = nd["Assigned To"]?.split(";")
                                if (userAssigned && userAssigned.indexOf(useremail) > -1 || nd["user_inserted"] === useremail) {
                                    // console.log("######## ROW ID MATCHES ############")
                                    // console.log("nd[user_inserted] -> ", nd["user_inserted"])
                                    // console.log("useremail -> ", useremail)
                                    // if (nd["user_inserted"] === useremail)
                                    // {
                                    //     isAiAssignee.current = true
                                    // }
                                    // console.log("isAiAssignee -> ", isAiAssignee.current)
                                    createNewActionItem.current = false;
                                    let jsonString = ""
                                    let tasktitle = ""
                                    tasktitle = nd["Task Title"]
                                    rid = nd["row_id"].toString()
                                    jsonString = `{"${rid}":${JSON.stringify(nd)}}`
                                    // jsonString = `{"${tasktitle}":${JSON.stringify(nd)}}`
                                    tasklistarray.push(JSON.parse(jsonString))
                                    // jsonString = `{ "TaskTitle":"${tasktitle}","AssignedTo":"${nd["Assigned To"]},"row_id":${nd["row_id"]}}`
                                    jsonString = `{ "TaskTitle":"${tasktitle}","AssignedTo":"${nd["Assigned To"]}","row_id":${nd["row_id"]}}`
                                    taskTitlearray.push(JSON.parse(jsonString))
                                    // taskTitlearray[`"${tasktitle}"`]=JSON.parse(jsonString)
                                    setAiTxtTask(nd["Task Description"])
                                    setaiTitleValue(nd["Task Title"])
                                    setremarkValue(nd["Comments"])
                                    setStatusValue(nd["Status"])
                                    setaiStartDateValue(nd["Start Date"])
                                    setaiEndDateValue(nd["Finish Date"])
                                    setpriorityValue(nd["Priority"])
                                    // console.log("### row_id ####")
                                    // console.log(nd["row_id"])
                                    setActionItemRowId(nd["row_id"].toString())
                                    // setisActionItemUpdate(true)
                                    isActionItemUpdate.current = true
                                    // ActiveUserData.current.forEach(userdata => {
                                    //     if (userdata.userName === nd["Assigned To"]) {
                                    //         console.log(" ##### assigendArray. ###### ")
                                    //         assigendArray.push(userdata)

                                    //     }
                                    // });
                                }
                            }
                        })
                        // console.log("taskDetails.... retrived -> ", new Date())
                        // console.log(taskTitlearray)
                        let taskDetails = taskTitlearray[0]
                        // console.log("taskDetails ---> ")
                        // console.log("assigendArray........")
                        // console.log(assigendArray)
                        // console.log(taskDetails["Task Title"])
                        // console.log("tasklistarray...")
                        // console.log(tasklistarray[taskDetails["Task Title"]])
                        tasklistarray.forEach(ndr => {
                            let nd = ndr[Object.keys(ndr)[0]]
                            // console.log("nd[Task Title] -->  nd -> ", new Date())
                            // console.log("nd " , nd)                            
                            if (nd["Task Title"] === taskDetails["TaskTitle"] && nd["Assigned To"] === taskDetails["AssignedTo"] && taskDetails["row_id"].toString() === nd["row_id"].toString()) {
                                isAiAssignee.current = false
                                // console.log("<--- nd -->")
                                // console.log(taskDetails["TaskTitle"] + "<--->" + nd["Assigned To"])
                                check_row_id = nd["row_id"].toString()
                                if (nd["user_inserted"] === useremail) {
                                    // console.log(nd["user_inserted"])
                                    isAiAssignee.current = true
                                }
                                setAiTxtTask(nd["Task Description"])
                                setaiTitleValue(nd["Task Title"])
                                setremarkValue(nd["Comments"])
                                setStatusValue(nd["Status"])
                                setaiStartDateValue(nd["Start Date"])
                                setaiEndDateValue(nd["Finish Date"])
                                setpriorityValue(nd["Priority"])
                                // setisActionItemUpdate(true)
                                isActionItemUpdate.current = true
                                setActionItemRowId(nd["row_id"].toString())
                                // assigendArray=nd["Assigned To"].split(";")
                                if (nd["row_id"] === check_row_id) {
                                    nd["Assigned To"].split(";").forEach(username => {
                                        // console.log(" looped username:- ", new Date())
                                        ActiveUserData.current.forEach(userdata => {

                                            if (userdata.email === username) {
                                                assigendArray.push(userdata)

                                            }
                                        });
                                    });
                                }
                            }
                        });
                        // console.log(" loop over:- ", new Date())
                        // let newString =`{ "TaskTitle":"<< Create New Task >>","AssignedTo":""}`
                        // taskTitlearray.push(JSON.parse(newString))                    
                        setActionItemAssigned(assigendArray)
                        setActionItemTaskTitlelist(taskTitlearray)
                        setActionItemAssignedTasklist(tasklistarray)
                        // console.log("ActionItemRowId")
                        // console.log(actionItemRowId)
                        // console.log("<###-- ActionItemAssignedTasklist ###--> ")
                        // console.log(assigendArray)
                        setBlActionItem(true)
                        // console.log(" variables assigned:- ", new Date())
                    }
                }
            }
            // console.log("statusValue:-  ")
            // console.log(statusValue)
            // // console.log(actionItemAssigned)
        });
    }
    const handelTaskTitleChange = (event, newValue) => {
        isAiAssignee.current = false
        if (newValue === null || newValue === undefined) {
            return;
        }
        let taskDetails = newValue //taskTitlearray[0]        
        let assigendArray = []
        let actionItemAssignedTasklistTmp = actionItemAssignedTasklist
        let useremail = sessionStorage.getItem('email')
        actionItemAssignedTasklist.forEach(ndr => {
            let nd = ndr[newValue["row_id"]]
            let taskTitlearray = []
            let check_row_id = newValue["row_id"]
            if (nd === null || nd === undefined || nd === "") {
                return;
            }
            if (nd["Task Title"] === taskDetails["TaskTitle"] && nd["Assigned To"] === taskDetails["AssignedTo"] || ndr["row_id"] === newValue["row_id"]) {
                isAiAssignee.current = false
                if (nd["user_inserted"] === useremail) {

                    isAiAssignee.current = true
                }
                setAiTxtTask(nd["Task Description"])
                setaiTitleValue(nd["Task Title"])
                setremarkValue(nd["Comments"])
                setStatusValue(nd["Status"])
                setaiStartDateValue(nd["Start Date"])
                setaiEndDateValue(nd["Finish Date"])
                setpriorityValue(nd["Priority"])
                // setisActionItemUpdate(true)
                isActionItemUpdate.current = true
                setActionItemRowId(nd["row_id"])
                // assigendArray=nd["Assigned To"].split(";")                
                if (nd["row_id"].toString() === check_row_id.toString()) {
                    nd["Assigned To"].split(";").forEach(username => {
                        ActiveUserData.current.forEach(userdata => {
                            if (userdata.email === username) {
                                assigendArray.push(userdata)
                            }
                        });
                    });
                }
                setActionItemAssigned(assigendArray)
                // console.log("ActionItemRowId")
                // console.log(ActionItemRowId)
            }
        })
    };
    const handleAssignOK = () => {
        if (aiTxtTask === null || aiTxtTask === undefined || aiTxtTask === "") {
            toastMessage("Please input task details.");
            return
        } else if (actionItemAssigned.length <= 0 || actionItemAssigned === undefined) {
            toastMessage("Please Select User to Assign.");
            return
        }
        let oneRwVl = new Object()
        let updRow_ID = actionItemRowId
        oneRwVl["data_table_row_id"] = actionItemTableName === 'xl_action_item_details' ? srcForAiData["data_table_row_id"] : srcForAiData["row_id"]
        oneRwVl["Year"] = srcForAiData["Year"]
        oneRwVl["Season Code"] = srcForAiData["Season Code"]
        oneRwVl["Department"] = srcForAiData["Department"]
        oneRwVl["Style"] = srcForAiData["STYLE"] ? srcForAiData["STYLE"] : srcForAiData["Style"]
        oneRwVl["Factory"] = srcForAiData["Factory"]
        oneRwVl["Assigned To"] = actionItemAssigned.map(item => item.email).join(';'); //"hardipsinh@sohum.tech"
        oneRwVl["Assigned Type"] = "Individual"
        oneRwVl["Task Title"] = aiTitleValue
        oneRwVl["Task Description"] = aiTxtTask
        oneRwVl["Status"] = statusValue
        oneRwVl["Start Date"] = aiStartDateValue
        oneRwVl["Finish Date"] = aiEndDateValue
        oneRwVl["Priority"] = priorityValue
        oneRwVl["Comments"] = remarksValue //remarksValue?remarksValue:""
        oneRwVl["tab_name"] = actionItemTableName === 'xl_action_item_details' ? srcForAiData["tab_name"] : actionItemTableName
        if (!oneRwVl["tab_name"]) {
            oneRwVl["tab_name"] = actionItemTabName.current
        }
        if (oneRwVl["tab_name"]) {
            if (oneRwVl === null || oneRwVl === undefined || oneRwVl === "") {
                toastMessage("There is an issue in creating an Action Item. Please try again.", "error");
                setAiTxtTask("");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                setActionItemAssigned([]);
                setBlActionItem(false);
                return
            }
        }
        if (!oneRwVl["data_table_row_id"] || oneRwVl["data_table_row_id"] === "" || oneRwVl["data_table_row_id"] === undefined || !oneRwVl["tab_name"] || oneRwVl["tab_name"] === "" || oneRwVl["tab_name"] === undefined || !oneRwVl["Assigned To"] || oneRwVl["Assigned To"] === "" || oneRwVl["Assigned To"] === undefined) {
            console.log("inside not oneRwvl..")
            toastMessage("There is an issue in creating an Action Item. Please try again.", "error");
            setAiTxtTask("");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
            setActionItemAssigned([]);
            setBlActionItem(false);
            return
        }
        //ActionItemData
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let tempActionItemData = ActionItemData.filter(item => item.row_id === updRow_ID);
        tempActionItemData = tempActionItemData[0]
        // console.log("updRow_ID --> ", updRow_ID)
        // console.log("tempActionItemData *:--> ", tempActionItemData )
        // return;
        let AddJson = ""
        let UpdateJson = ""
        let DeleteJson = ""
        let colNamesArray = ["data_table_row_id", "Year", "Season Code", "Department", "Style", "Factory", "Assigned To", "Assigned Type", "Task Title", "Task Description", "Priority", "Start Date", "Finish Date", "Status", "Comments", "tab_name"]
        let AddJsonData = ""
        colNamesArray.forEach(function (caItem, caIndex) {
            if (updRow_ID === null || updRow_ID === undefined || updRow_ID === '') {
                if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                    let colVal = oneRwVl[caItem]
                    colVal = (colVal === undefined || colVal === null) ? "" : colVal
                    AddJsonData = AddJsonData + CH6 + caItem + CH7 + CH1 + CH6 + colVal + CH7 + CH4
                }
            }
            else {
                if (ignoreColumnsInCacheCompare.includes(caItem) === false) {
                    if (tempActionItemData[caItem] !== oneRwVl[caItem]) {
                        let colVal = oneRwVl[caItem]
                        colVal = (colVal === undefined || colVal === null) ? "" : colVal
                        AddJsonData = AddJsonData + CH6 + caItem + CH7 + CH1 + CH6 + colVal + CH7 + CH4
                    }
                }
            }
        })
        // console.log("AddJsonData:- ", AddJsonData)
        if (AddJsonData.length > 0) {
            AddJsonData = AddJsonData.substring(0, AddJsonData.length - 1)
            AddJsonData = AddJsonData + CH2
            AddJson = AddJson + AddJsonData
        }
        if (AddJson.length > 0) {
            AddJson = AddJson.substring(0, AddJson.length - 1)
        }
        if (AddJson === null || AddJson.trim() === '') {
            AddJson = "NoData"
        }
        let updateJsonData = ""
        // console.log("isActionItemUpdate --> ")
        // console.log(isActionItemUpdate)
        if (isActionItemUpdate.current) {
            AddJson = "NoData"
            if (AddJsonData !== '' && AddJsonData !== null && AddJsonData !== undefined) {
                AddJsonData = updRow_ID + CH3 + AddJsonData
                UpdateJson = AddJsonData.substring(0, AddJsonData.length - 1)
            }
            else { UpdateJson = "NoData" }
        }
        else { UpdateJson = "NoData" }
        DeleteJson = "NoData"
        let req_tbl = "xl_action_item_details"
        let rng = "Action_Item_Rng"
        let FinalStr = ""
        if (AddJson === "NoData" && UpdateJson === "NoData") {
            FinalStr = "NoAnyDataChangesFound"
        } else {
            FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
        }
        // console.log('handleAssignOK -> AddJsonData -> ' + AddJsonData)
        // console.log('handleAssignOK -> FinalStr -> ' + FinalStr)
        // FinalStr = "NoAnyDataChangesFound"
        if (FinalStr === "NoAnyDataChangesFound") {
            console.log("no data submitted")
        } else {
            fetch(restApiPath + "SendUserDataOptimized", {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (FinalStr)
            })
                .then(result => result.json())
                .then(resData => postActionItemSend(resData, rng, req_tbl, true, null))
            // .then( a => dispatch({ type: "SET_PLEASE_WAIT", data: false }))
        }
        setActionItemRowId("");
    };
    const handleDeleteClick = () => {
        if (aiTxtTask === null || aiTxtTask === undefined || aiTxtTask === "") {
            toastMessage("Please select task to delete.");
            return
        }
        let updRow_ID = actionItemRowId
        let AddJson = ""
        let UpdateJson = ""
        let DeleteJson = updRow_ID
        AddJson = "NoData"
        UpdateJson = "NoData"
        let req_tbl = "xl_action_item_details"
        let rng = "Action_Item_Rng"
        let FinalStr = ""
        FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + AddJson + CH5 + UpdateJson + CH5 + DeleteJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
        if (FinalStr === "NoAnyDataChangesFound") {
            // console.log("no data submitted")
        } else {
            fetch(restApiPath + "SendUserDataOptimized", {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (FinalStr)
            })
                .then(result => result.json())
                .then(resData => postActionItemSend(resData, rng, req_tbl, true, null))
        }
        setActionItemRowId("");
    }
    const handelAssigned = (event, newValue) => {
        setActionItemAssigned(newValue)
    };
    const handelNewCancelClick = (event) => {
        if (actionItemTableName === 'xl_action_item_details') {
            return
        }
        let useremail = sessionStorage.getItem('email')
        isAiAssignee.current = true;
        let check_row_id = "";
        if (createNewActionItem.current === false || !actionItemTaskTitlelist[0]) {
            setAiTxtTask("")
            setaiTitleValue("")
            setremarkValue("")
            setStatusValue("Open")
            setaiStartDateValue("")
            setaiEndDateValue("")
            setpriorityValue("High")
            // setisActionItemUpdate(false)
            isActionItemUpdate.current = false
            setActionItemRowId("")
            setActionItemAssigned([])
            // setCreateNewActionItem(false)
            createNewActionItem.current = true
            return
        }
        else {
            let taskDetails = actionItemTaskTitlelist[0]
            let assigendArray = []
            actionItemAssignedTasklist.forEach(ndr => {
                let nd = ndr[Object.keys(ndr)[0]]
                // console.log("nd[Task Title] -->  nd")
                // console.log(nd)
                if (nd["Task Title"] === taskDetails["TaskTitle"] && nd["Assigned To"] === taskDetails["AssignedTo"] && taskDetails["row_id"].toString() === nd["row_id"].toString()) {
                    // console.log("<--- nd -->")
                    isAiAssignee.current = false
                    if (nd["user_inserted"] === useremail) {
                        // console.log("<--- nd updated-->")
                        // console.log(useremail)
                        // console.log(nd["user_inserted"])
                        isAiAssignee.current = true
                    }
                    // console.log(taskDetails["TaskTitle"] + "<--->" + nd["Assigned To"])
                    setAiTxtTask(nd["Task Description"])
                    setaiTitleValue(nd["Task Title"])
                    setremarkValue(nd["Comments"])
                    setStatusValue(nd["Status"])
                    setaiStartDateValue(nd["Start Date"])
                    setaiEndDateValue(nd["Finish Date"])
                    setpriorityValue(nd["Priority"])
                    // setisActionItemUpdate(true)
                    isActionItemUpdate.current = true
                    setActionItemRowId(nd["row_id"])
                    // assigendArray=nd["Assigned To"].split(";")
                    // console.log(assigendArray)
                    nd["Assigned To"].split(";").forEach(username => {
                        ActiveUserData.current.forEach(userdata => {
                            if (userdata.email === username) {
                                assigendArray.push(userdata)

                            }
                        });
                    });
                    setActionItemAssigned(assigendArray)
                }
            });
            // setCreateNewActionItem(true)
            createNewActionItem.current = false
        }
    };
    const handleTaskInputChange = (event) => {
        // console.log("handleTaskInputChange -> " + event.target.value)
        setAiTxtTask(event.target.value)
    }
    const postActionItemSend = (result, rng, req_tbl, showPopUp = true, mp) => {
        if (result.error === "invalid_token") {
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
            toastMessage("Session Expired", "warning");
            sessionStorage.clear()
            setTimeout(() => {
                nevigate("/login");
            }, 5000);
        } else {
            if (result.response === true) {
                setBlActionItem(false)
                setSrcForAiData([])
                setEmailActionItem("")
                // getChangesWithoutBtn(aid_info_mp);
                setAiTxtTask("")
                let resData = result.data
                console.log("postActionItemSend -> hardip")
                // console.log(resData)
                getChangesWithoutBtn_('xl_action_item_details')
                toastMessage("Action Item performed successfully.", "success");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
        }
    };
    const handlePriorityRadioChange = (event) => {
        // console.log(event.target.value)
        setpriorityValue(event.target.value);
        // setHelperText(' ');
        setpriorityError(false);
    };
    const handleStausRadioChange = (event) => {
        // console.log(event.target.value)
        setStatusValue(event.target.value);
        // setHelperText(' ');
        // setpriorityError(false);
    };
    const closeSendDataSt = () => {
        const req_tbl = tabList[tabValue][1]
        setBlSendDataStatistics(false)
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'New_Rows_send', 0)
        sessionStorage.setItem(req_tbl + '_' + 'Delete_Rows_send', 0)
    }
    const getChangesWithoutBtn_ = async (req_tbl, from = "") => {
        // console.log("getChangesWithoutBtn -> req_tbl -> " + req_tbl)
        let data = metaData.current
        let paramwhere = ""
        if (getDataFromMetaData(req_tbl, 'download_type').toLocaleLowerCase() !== "default") {
            paramwhere = sessionStorage.getItem('asRequiredDownloadQuery') === null ? "" : sessionStorage.getItem('asRequiredDownloadQuery')
        }
        let postbodyJson = {
            "param_email": sessionStorage.getItem("email"),
            "param_table_name": req_tbl,
            "param_tx_id": sessionStorage.getItem(req_tbl + '_Latest_Tx_Id'),//6870
            "param_where": paramwhere,
            "param_row_access": getDataFromMetaData(req_tbl, 'filter_row_access'),
            "param_locale_diff": utcDifference
        }
        let AsRequiredTblRow = getTableAsRequired(data)
        if (AsRequiredTblRow !== null && AsRequiredTblRow !== undefined && AsRequiredTblRow !== "") {
            let row_filter = GetRowFilterBasedOnTableName(data, req_tbl)
            if (row_filter === null || row_filter === undefined || row_filter === "") {
                row_filter = 'no'
            }
            let downloadType = getDataFromMetaData(req_tbl, 'download_type')
            let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
            let postBodyStr = ''
            if (downloadType === "Default") {
                postBodyStr = sessionStorage.getItem("email") + CH5 + req_tbl + CH5 + CH5 + row_filter
            } else {
                postBodyStr = sessionStorage.getItem("email") + CH5 + req_tbl + CH5 + asRequiredDownloadQuery + CH5 + row_filter
            }
            fetch(restApiPath + 'getCellChangesXL', {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: (JSON.stringify(postbodyJson))
            })
                .then(result => result.json())
                .then(masterData => testGetChanges(masterData, req_tbl, from))
        }
    };
    const testGetChanges = (result, req_tbl, from) => {
        console.log("testGetChanges -> req_tbl -> " + req_tbl)
        // console.log("testGetChanges -> result")
        // console.log(result)
        // console.log(JSON.parse(result.data))
        let ssnData = getSessionDataByTblName(req_tbl)
        let reduxStateData = getStateDataByTblName(req_tbl)
        let reduxStateColDef = getColumnDefByTblName(req_tbl)
        if (!reduxStateColDef || !ssnData || !reduxStateData) {
            console.log("No data found for " + req_tbl)
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
            return
        }
        // console.log("ssn", ssnData);
        ssnData = ssnData.sort(GetSortOrderRowId("row_id"))
        reduxStateData = reduxStateData.sort(GetSortOrderRowId("row_id"))
        let rowIdWithIndexMap = new Map()
        for (let i = 0; i < ssnData.length; i++) {
            const el = ssnData[i];
            rowIdWithIndexMap.set(el.row_id, i)
        }
        let arr = []
        let isNew = false
        if (result.error === "invalid_token") {
            return { "status": false, msg: "invalid_token" }
        } else {
            if (result.response === true) {
                sessionStorage.setItem("GetChangesDone", false)
                let IsDataChangeDetected = false
                if (JSON.parse(result.data)["jsondata"] !== "No changes found") {
                    IsDataChangeDetected = true
                    const highlight = getHighlightByTblName(req_tbl);
                    let newData = JSON.parse(result.data)["jsondata"]
                    console.log('testGetChanges -> newData-> table: ' + req_tbl)
                    console.log(newData)

                    const allColNamesArray = [];

                    reduxStateColDef.map((col) => allColNamesArray.push(col.headerName));

                    console.log('1 -> testGetChanges -> colNamesArray')
                    console.log(allColNamesArray);

                    let lstDeletedRows = []
                    newData.forEach(nd => {
                        let isFound = false
                        let colNamesArray = new Array()
                        for (let i = 0; i < reduxStateData.length; i++) {
                            const el = reduxStateData[i];
                            // console.log("testGetChanges -> el")
                            // console.log(el)
                            if (nd.r === el.row_id && nd.o === 'd') {
                                // console.log("Deleted el")
                                // console.log(el)
                                // console.log("Deleted el.row_id -> " + el.row_id)
                                lstDeletedRows.push(el.row_id)
                            }
                            if (nd.r === el.row_id && nd.o !== 'd') {
                                const elCache = ssnData[rowIdWithIndexMap.get(el.row_id)]
                                // console.log("testGetChanges -> elCache")
                                // console.log(elCache)
                                isFound = true
                                let jdata = nd.d
                                colNamesArray = new Array()
                                Object.keys(nd["d"]).map(cn => {
                                    colNamesArray.push(cn.replace(doubleQuote, ''))
                                })
                                // console.log('2 -> testGetChanges -> colNamesArray')
                                // console.log(colNamesArray)
                                // console.log(colNamesArray)
                                colNamesArray.forEach(function (caItem, caIndex) {
                                    // console.log("caItem: " + caItem)
                                    // console.log("el[caItem]" + el[caItem])
                                    // console.log("elCache[caItem]: " + elCache[caItem])
                                    // console.log("..........................")
                                    // console.log(req_tbl + "::" + tabList[tabValue][1] + "jdata[" + caItem + "]" + jdata[caItem] + " > el[caItem]: " + el[caItem] + " > elCache[caItem]: " + elCache[caItem])
                                    if ((el && elCache && el[caItem] === elCache[caItem]) || req_tbl === tabList[tabValue][1]) {
                                        if (jdata[caItem] !== el[caItem]) {
                                            if (reduxStateData[i]) reduxStateData[i][caItem] = jdata[caItem]
                                            if (ssnData[rowIdWithIndexMap.get(el.row_id)]) ssnData[rowIdWithIndexMap.get(el.row_id)][caItem] = jdata[caItem]
                                        }
                                    }
                                    // black hoi tya sync data ni value set nathi kari sakhtu
                                    // if ((el[caItem] && elCache[caItem] && el[caItem] === elCache[caItem]) || req_tbl === tabList[tabValue][1]) {
                                    //     if (jdata[caItem] !== el[caItem]) {                                    
                                    //         reduxStateData[i][caItem] = jdata[caItem]
                                    //         ssnData[rowIdWithIndexMap.get(el.row_id)][caItem] = jdata[caItem]                                    
                                    //     }
                                    // }
                                })
                            }
                        }
                        if (!isFound && nd.o === 'i') {
                            const newRow = nd.d
                            newRow["row_id"] = nd.r
                            if (nd.o === 'i') {
                                // console.log("newRow", newRow);
                                allColNamesArray.map((col) => {
                                    if (newRow[col] && (from === "merch_assort" || from === "carry_over" || from === "pd_assort")) {
                                        const key = req_tbl + "|" + col + "|" + nd.r;
                                        highlight.push({ [key]: 1 });
                                    } else {
                                        newRow[col] = "";
                                    }
                                })
                            }
                            isNew = true
                            reduxStateData.push(newRow);
                            ssnData.push(newRow);
                        }
                    })
                    // console.log("lstDeletedRows Final")
                    // console.log(lstDeletedRows)
                    // console.log("reduxStateData.length -> " + reduxStateData.length)
                    // console.log("ssnData.length -> " + ssnData.length)
                    if (lstDeletedRows !== null && lstDeletedRows !== undefined && lstDeletedRows.length > 0) {
                        for (let i = 0; i < lstDeletedRows.length; i++) {
                            for (let j = 0; j < reduxStateData.length; j++) {
                                const ele = reduxStateData[j];
                                if (reduxStateData[j]["row_id"] === lstDeletedRows[i]) {
                                    reduxStateData.splice(j, 1)
                                }
                            }
                        }
                        for (let i = 0; i < lstDeletedRows.length; i++) {
                            for (let j = 0; j < ssnData.length; j++) {
                                const ele = ssnData[j];
                                if (ssnData[j]["row_id"] === lstDeletedRows[i]) {
                                    ssnData.splice(j, 1)
                                }
                            }
                        }
                    }
                    reduxStateData = reduxStateData.sort(GetSortOrderRowId("row_id"));
                    // console.log("2 -> testGetChanges -> reduxStateData: " + req_tbl + "..." + isRefreshWipShipment.current)
                    // console.log(reduxStateData)
                    if (from === "merch_assort" || from === "carry_over" || from === "pd_assort") {
                        setHighlightByTblName(req_tbl, JSON.parse(JSON.stringify(highlight)));
                    }
                    // console.log("reduxStateData", reduxStateData)
                    // console.log("ssnData", ssnData)
                    setSessionDataByTblName(req_tbl, ssnData);
                    setStateDataByTblName(req_tbl, JSON.parse(JSON.stringify(reduxStateData)));
                    GettingRequestedData(req_tbl);
                } else {
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                    return { "status": false, msg: "Get Changes : No changes found." }
                }
            } else {
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
                return { "status": false, msg: "There is an error while getting changes." }
            }
        }
    };
    const closeShowChangesBtn = () => {
        setCloseShowChanges("none")
    }
    const ShowChangesBtnCall = () => {
        setResetAuditTrail(false)
        const curTab = tabList.filter((tab) => tab[0] === activeTab)
        const tab_name = curTab[0][0];
        if (tab_name === aid_info_mp.get("Tab_Name") || tab_name === sr_info_mp.get("Tab_Name") || tab_name === pvt_info_mp.get("Tab_Name")) {
            toastMessage("Show Changes functionality is not available for " + tab_name + " tab", "success");
        } else {
            let data = metaData.current;
            let rowFilter = GetRowFilterBasedOnTableName(data, curTab[0][1]);
            if (rowFilter === null || rowFilter === undefined || rowFilter === "") {
                rowFilter = 'no';
            }
            sessionStorage.setItem("rowFilter", rowFilter);
            setCloseShowChanges("block");
        }
    }
    const showNotificationMsg = (msg, color) => {
        toastMessage(msg, color);
    }
    const btnAddRow = () => {
        const reqTbl = tabList[tabValue][1];
        const downloadType = getDataFromMetaData(reqTbl, 'download_type')
        // console.log("downloadType: " + downloadType)
        if ((sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') && downloadType.toLowerCase() !== "default") {
            toastMessage("Please download Linelist from Season tab to perform add row.");
            return;
        }
        let is_allow = getDataFromMetaData(reqTbl, 'insert_row');
        if (is_allow === null || is_allow === undefined || is_allow === "" || is_allow === 'No' || is_allow.length === 0) {
            toastMessage("Insert row functionality is not available for this tab.");
            return;
        } else {
            let oneClVl = new Object();
            gridApi.current.getColumnDefs().forEach(od => {
                oneClVl["" + od.field + ""] = "";
            })
            let lstVal = [];
            lstVal.push(oneClVl);
            const stateData = getStateDataByTblName(reqTbl);
            if (stateData) {
                let myData = [oneClVl, ...stateData];
                setStateDataByTblName(reqTbl, JSON.parse(JSON.stringify(myData)))
            }
            return "";
        }
    }
    const btnDeleteRow = () => {
        const reqTbl = tabList[tabValue][1];
        const downloadType = getDataFromMetaData(reqTbl, 'download_type')
        if ((sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') && downloadType.toLowerCase() !== "default") {
            toastMessage("Please download Linelist from Season tab to perform delete row.");
            return;
        }
        let is_allow = getDataFromMetaData(reqTbl, 'delete_row');
        if (is_allow === null || is_allow === undefined || is_allow === "" || is_allow === 'No' || is_allow.length === 0) {
            toastMessage("Delete Row functionality is not available for this tab.");
            return;
        } else {
            let focusedCell = gridApi.current.getFocusedCell()
            if (focusedCell === null || focusedCell === undefined) {
                toastMessage("Please first click on cell in table.");
                return
            }
            let oneRowData;
            gridApi.current.forEachNode((rowNode, index) => {
                if (rowNode.rowIndex === focusedCell.rowIndex) oneRowData = rowNode;
            });
            if (oneRowData !== null && oneRowData !== undefined && oneRowData.data !== null && oneRowData.data !== undefined) {
                if (oneRowData.data.row_id !== null && oneRowData.data.row_id !== "") {
                    toastMessage("Please perform send data to commit changes.");
                }
            }
            const tmpMyData = getStateDataByTblName(reqTbl);
            let myData = []
            if (tmpMyData) {
                myData = tmpMyData.filter(item => item !== oneRowData.data);
                setStateDataByTblName(reqTbl, JSON.parse(JSON.stringify(myData)))
            }
            return "";
        }
    }
    const highlightStyle = () => {
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
            toastMessage("Please download Linelist from Season tab to perform Highlight Style.");
            return;
        }
        const reqTbl = tabList[tabValue][1];
        if (reqTbl !== linelist_mp.get("Table_Name")) {
            toastMessage("Highlight Style functionality is not available for this tab.");
            return;
        }
        if (sessionStorage.getItem("user_type") !== "merch") {
            return;
        }
        const selectedData = [];
        const ranges = gridApi.current.getCellRanges();
        if (!ranges || ranges.length === 0) {
            toastMessage("Please Select cells to perform Highlight Style.");
            return;
        }
        ranges.forEach((range) => {
            for (let rowIndex = range.startRow.rowIndex; rowIndex <= range.endRow.rowIndex; rowIndex++) {
                const rowNode = gridApi.current.getDisplayedRowAtIndex(rowIndex);
                const rowData = rowNode.data;
                if (!rowData["row_id"] || rowData["row_id"] === "") {
                    toastMessage("Please first perform 'Send Changes' for newly inserted rows to perform Highlight Style", "warning");
                    return;
                } else {
                    if (!rowData["Merch Highlight"] || rowData["Merch Highlight"] === "")
                        rowData["Merch Highlight"] = 'true';
                    else
                        rowData["Merch Highlight"] = '';
                    selectedData.push(rowData);
                }
            }
        });
        if (selectedData.length > 0) {
            const data = getStateDataByTblName(reqTbl);
            const updatedData = _.map(data, (item) => {
                const matchingItem = _.find(selectedData, { row_id: item.row_id });
                if (matchingItem) {
                    return { ...item, ...matchingItem };
                }
                return item;
            });
            setStateDataByTblName(reqTbl, updatedData)
            toastMessage("Highlight Style performed successfully. Please perform send to save your changes.", "success")
        }
    }
    const generateItemUpload = () => {
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
            toastMessage("Please download Linelist from Season tab to perform Item Upload.", "warning");
            return;
        }
        const reqTbl = tabList[tabValue][1];
        if (reqTbl !== linelist_mp.get("Table_Name")) {
            toastMessage("Item Upload functionality is not available for this tab.", "warning");
            return;
        }
    }
    const onImportDataCloseClick = () => {
        // if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
        //     toastMessage("Please download Linelist from Season tab to perform Item Upload.", "warning");
        //     return;
        // }
        setDataImportOpen(false)
    }
    const assortBtnCall = () => {
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
            toastMessage("Please download Linelist from Season tab to perform Assortment.", "warning");
            return;
        }
        const activeTab = tabList[tabValue][0];
        if (activeTab === pd_initial_costing_mp.get("Tab_Name"))
            setOpenAssortDialog(true);
        else if (activeTab === merch_assortment_sandbox_mp.get("Tab_Name"))
            setOpenLlAssortDialog(true);
        else
            toastMessage("Assortemnt process is not available for this tab.", "warning");
    }
    const setAssortedRowIds = (row_ids, remove = "") => {
        if (remove !== "") {
            const tmp = [...pdAssortedRowIds];
            const final = tmp.filter(e => !row_ids.includes(e))
            setPdAssortedRowIds(final)
        } else setPdAssortedRowIds(oldArray => [...oldArray, ...row_ids]);
    }
    const setRevertedRowIds = (row_ids) => {
        setPdRevertedRowIds(oldArray => [...oldArray, ...row_ids]);
    }
    const performAssortMent = async () => {
        if (pdAssortedRowIds.length === 0 && pdRevertedRowIds.length === 0) {
            toastMessage("Please first move rows from Pd_Initial_Costing to Merch_Assort_Sandbox to perform assortment process.", "warning");
            return;
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const tbl_name = pd_initial_costing_mp.get("Table_Name");
        const stateData = getStateDataByTblName(tbl_name);
        if (pdAssortedRowIds.length > 0) {
            const filtered = stateData.filter((dt) => pdAssortedRowIds.includes(dt.row_id));
            filtered.map((data) => {
                data["Assort"] = "Yes";
            })
        }
        if (pdRevertedRowIds.length > 0) {
            const filtered = stateData.filter((dt) => pdRevertedRowIds.find((fd) => fd.pd === dt.row_id));
            filtered.map((data) => {
                data["Assort"] = "";
            })
        }
        console.log("stateData", stateData)
        setStateDataByTblName(tbl_name, stateData);
        sendDataChangesBackend(tbl_name, false, "pd_assort");
    }
    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
        if (gridApi.current)
            gridApi.current.setGridOption("quickFilterText", event.target.value,);
    };
    const getUserNotifications = async () => {
        const url1 = `${restApiPath}callPGFunction`;
        const jsonReq = {
            "param_body": {
                "param_email": sessionStorage.getItem("email"),
                "param_user_type": sessionStorage.getItem("user_type"),
                "setting_from": "react",
            },
            param_function: "get_ual1_notifications"
        };
        const requestbody = {
            method: "POST",
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Accept': "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonReq),
        };
        try {
            const response = await fetch(url1, requestbody);
            const json = await response.json();
            if (json.response) {
                // console.log("getUserNotifications -> json -> ", json.data);
                const resVal = JSON.parse(json.data);
                // console.log("getUserNotifications -> resVal -> ", resVal);
                if (resVal.status && resVal.status.trim().toUpperCase() === "SUCCESS") {
                    if (resVal.data && resVal.data.trim().toUpperCase() !== "NoData".toUpperCase()) {
                        const fv = JSON.parse(Buffer.from(resVal.data, 'base64').toString('utf8'));
                        // console.log("fv -> ", fv);
                        setNotificationData(fv);
                        // merchToPdMsg1Ref.current.handleMerchToPdMsg1(fv);
                    }
                }
            } else {
                console.log("getUserNotifications -> else -> ", json);
            }
        } catch (error) {
            console.log("getUserNotifications -> catch -> ", error);
        }
    };
    const getColPref = async () => {
        const url1 = `${restApiPath}callPGFunction`;
        const jsonReq = {
            "param_body": {
                "user_id": sessionStorage.getItem("email"),
                "setting_from": "react",
            },
            param_function: "sys_get_user_custom_table_setting"
        };
        const requestbody = {
            method: "POST",
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Accept': "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonReq),
        };
        try {
            const response = await fetch(url1, requestbody);
            const json = await response.json();
            if (json.response) {
                setCustColPref(JSON.parse(json.data));
            }
        } catch (error) {
            setCustColPref([]);
        }
    }
    useEffect(() => {
        for (let i = 0; i < custColPref.length; i++) {
            let el = custColPref[i];
            const reqTbl = el["table_name"];
            if (reqTbl === ssn_mp.get("Tab_Name")) {
                dispatch({ type: "SeasonColPref", data: el["column_setting"] });
            } else if (reqTbl === linelist_mp.get("Tab_Name")) {
                dispatch({ type: "LinelistColPref", data: el["column_setting"] });
                dispatch({ type: "LinelistGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === pd_initial_costing_mp.get("Tab_Name")) {
                dispatch({ type: "PDInitialCostingColPref", data: el["column_setting"] });
            } else if (reqTbl === chase_mp.get("Tab_Name")) {
                dispatch({ type: "ChaseColPref", data: el["column_setting"] });
                dispatch({ type: "ChaseGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === dtc_buy_sheet_mp.get("Tab_Name")) {
                dispatch({ type: "DTCBuySheetColPref", data: el["column_setting"] });
                dispatch({ type: "DTCBuySheetGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === whsl_buy_sheet_mp.get("Tab_Name")) {
                dispatch({ type: "WhslBuySheetColPref", data: el["column_setting"] });
                dispatch({ type: "WhslBuySheetGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === whsl_buy_sheet_nord_rp_mp.get("Tab_Name")) {
                dispatch({ type: "WhslBuySheetNordRPColPref", data: el["column_setting"] });
                dispatch({ type: "WhslBuySheetNordRPGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === sizing_mp.get("Tab_Name")) {
                dispatch({ type: "SizingColPref", data: el["column_setting"] });
                dispatch({ type: "SizingGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === buy_sheet_master_sizing_percentage_mp.get("Tab_Name")) {
                dispatch({ type: "BuySheetMasterSizingColPref", data: el["column_setting"] });
                dispatch({ type: "BuySheetMasterSizingGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === buySht_master_data_charts_mp.get("Tab_Name")) {
                dispatch({ type: "BuyShtMasterDataChartsColPref", data: el["column_setting"] });
                dispatch({ type: "BuyShtMasterDataChartsGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === comp_aps_master_storeecom_mp.get("Tab_Name")) {
                dispatch({ type: "CompAPSMasterStoreEcomColPref", data: el["column_setting"] });
            } else if (reqTbl === freight_master_mp.get("Tab_Name")) {
                dispatch({ type: "FreightMasterColPref", data: el["column_setting"] });
                dispatch({ type: "FreightMasterGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === freight_by_category_mp.get("Tab_Name")) {
                dispatch({ type: "FreightbyCategoryColPref", data: el["column_setting"] });
            } else if (reqTbl === delivery_master_mp.get("Tab_Name")) {
                dispatch({ type: "DeliveryMasterColPref", data: el["column_setting"] });
            } else if (reqTbl === fb_fty_database_mp.get("Tab_Name")) {
                dispatch({ type: "FBFtyDatabaseColPref", data: el["column_setting"] });
            } else if (reqTbl === size_scales_mp.get("Tab_Name")) {
                dispatch({ type: "SizeScalesColPref", data: el["column_setting"] });
            } else if (reqTbl === shopify_product_type_mapping_mp.get("Tab_Name")) {
                dispatch({ type: "ShopifyProductTypeMapColPref", data: el["column_setting"] });
            } else if (reqTbl === season_inquiry_mp.get("Tab_Name")) {
                dispatch({ type: "SeasonInquiryColPref", data: el["column_setting"] });
            } else if (reqTbl === master_lov_mp.get("Tab_Name")) {
                dispatch({ type: "MasterLOVColPref", data: el["column_setting"] });
                dispatch({ type: "MasterLOVGrpColPref", data: el["column_grouping_setting"] });
            } else if (reqTbl === aid_info_mp.get("Tab_Name")) {
                dispatch({ type: "ActionColPref", data: el["column_setting"] });
            } else if (reqTbl === manage_user.get("Tab_Name")) {
                dispatch({ type: "ManageUserColPref", data: el["column_setting"] });
            } else if (reqTbl === manage_permission.get("Tab_Name")) {
                dispatch({ type: "ManagePermissionColPref", data: el["column_setting"] });
            } else if (reqTbl === merch_assortment_sandbox_mp.get("Tab_Name")) {
                dispatch({ type: "MerchAssortmentSandboxColPref", data: el["column_setting"] });
            }
        }
    }, [custColPref]);
    const btnSavePreference = () => { setMySavePrefAlert(true); }
    const SavePreference = async (typeOf) => {
        let reqTbl = tabList[tabValue][0];
        if (typeOf === 'reset') gridApi.current.resetColumnState();
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        let colState = gridApi.current.getColumnState();
        if (colState.length !== 0) {
            let grpColState = gridApi.current.getColumnGroupState();
            const url1 = `${restApiPath}callPGFunction`;
            const jsonReq = {
                "param_body": {
                    "user_id": sessionStorage.getItem("email"),
                    "table_name": reqTbl,
                    "column_setting": JSON.stringify(colState),
                    "column_grouping_setting": JSON.stringify(grpColState),
                    "setting_from": "react",
                    "set_type": typeOf,
                },
                param_function: "sys_set_user_custom_table_setting"
            };
            const requestbody = {
                method: "POST",
                headers: {
                    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                    'Accept': "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonReq),
            };
            try {
                const response = await fetch(url1, requestbody);
                const json = await response.json();
                if (json.response) {
                    setMySavePrefAlert(false);
                    toastMessage(`Column Preference Saved for ${reqTbl} Tab`);
                    if (typeOf === 'reset') {
                        colState = [];
                        grpColState = [];
                    }
                    if (reqTbl === ssn_mp.get("Tab_Name")) {
                        dispatch({ type: "SeasonColPref", data: colState });
                    } else if (reqTbl === linelist_mp.get("Tab_Name")) {
                        dispatch({ type: "LinelistColPref", data: colState });
                        dispatch({ type: "LinelistGrpColPref", data: grpColState });
                    } else if (reqTbl === pd_initial_costing_mp.get("Tab_Name")) {
                        dispatch({ type: "PDInitialCostingColPref", data: colState });
                    } else if (reqTbl === chase_mp.get("Tab_Name")) {
                        dispatch({ type: "ChaseColPref", data: colState });
                        dispatch({ type: "ChaseGrpColPref", data: grpColState });
                    } else if (reqTbl === dtc_buy_sheet_mp.get("Tab_Name")) {
                        dispatch({ type: "DTCBuySheetColPref", data: colState });
                        dispatch({ type: "DTCBuySheetGrpColPref", data: grpColState });
                    } else if (reqTbl === whsl_buy_sheet_mp.get("Tab_Name")) {
                        dispatch({ type: "WhslBuySheetColPref", data: colState });
                        dispatch({ type: "WhslBuySheetGrpColPref", data: grpColState });
                    } else if (reqTbl === whsl_buy_sheet_nord_rp_mp.get("Tab_Name")) {
                        dispatch({ type: "WhslBuySheetNordRPColPref", data: colState });
                        dispatch({ type: "WhslBuySheetNordRPGrpColPref", data: grpColState });
                    } else if (reqTbl === sizing_mp.get("Tab_Name")) {
                        dispatch({ type: "SizingColPref", data: colState });
                        dispatch({ type: "SizingGrpColPref", data: grpColState });
                    } else if (reqTbl === buy_sheet_master_sizing_percentage_mp.get("Tab_Name")) {
                        dispatch({ type: "BuySheetMasterSizingColPref", data: colState });
                        dispatch({ type: "BuySheetMasterSizingGrpColPref", data: grpColState });
                    } else if (reqTbl === buySht_master_data_charts_mp.get("Tab_Name")) {
                        dispatch({ type: "BuyShtMasterDataChartsColPref", data: colState });
                        dispatch({ type: "BuyShtMasterDataChartsGrpColPref", data: grpColState });
                    } else if (reqTbl === comp_aps_master_storeecom_mp.get("Tab_Name")) {
                        dispatch({ type: "CompAPSMasterStoreEcomColPref", data: colState });
                    } else if (reqTbl === freight_master_mp.get("Tab_Name")) {
                        dispatch({ type: "FreightMasterColPref", data: colState });
                        dispatch({ type: "FreightMasterGrpColPref", data: grpColState });
                    } else if (reqTbl === freight_by_category_mp.get("Tab_Name")) {
                        dispatch({ type: "FreightbyCategoryColPref", data: colState });
                    } else if (reqTbl === delivery_master_mp.get("Tab_Name")) {
                        dispatch({ type: "DeliveryMasterColPref", data: colState });
                    } else if (reqTbl === fb_fty_database_mp.get("Tab_Name")) {
                        dispatch({ type: "FBFtyDatabaseColPref", data: colState });
                    } else if (reqTbl === size_scales_mp.get("Tab_Name")) {
                        dispatch({ type: "SizeScalesColPref", data: colState });
                    } else if (reqTbl === shopify_product_type_mapping_mp.get("Tab_Name")) {
                        dispatch({ type: "ShopifyProductTypeMapColPref", data: colState });
                    } else if (reqTbl === season_inquiry_mp.get("Tab_Name")) {
                        dispatch({ type: "SeasonInquiryColPref", data: colState });
                    } else if (reqTbl === master_lov_mp.get("Tab_Name")) {
                        dispatch({ type: "MasterLOVColPref", data: colState });
                        dispatch({ type: "MasterLOVGrpColPref", data: grpColState });
                    } else if (reqTbl === aid_info_mp.get("Tab_Name")) {
                        dispatch({ type: "ActionColPref", data: colState });
                    } else if (reqTbl === manage_user.get("Tab_Name")) {
                        dispatch({ type: "ManageUserColPref", data: colState });
                    } else if (reqTbl === manage_permission.get("Tab_Name")) {
                        dispatch({ type: "ManagePermissionColPref", data: colState });
                    } else if (reqTbl === merch_assortment_sandbox_mp.get("Tab_Name")) {
                        dispatch({ type: "MerchAssortmentSandboxColPref", data: colState });
                    }
                }
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            } catch (error) {
                toastMessage(`Something went wrong to Save Preferences`, "warning");
                dispatch({ type: "SET_PLEASE_WAIT", data: false });
            }
        } else {
            toastMessage(`No data found for ${reqTbl} Tab`, "warning");
            dispatch({ type: "SET_PLEASE_WAIT", data: false });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    }

    const summaryDlgOpen = (event) => {
        // console.log("handleTaskInputChange -> " + event.target.value)
        const dataVisibleRows = getVisibleRows()
        setVisiblerows(dataVisibleRows)
        setSummaryView(true)
    }

    const performLlAssortMent = async () => {
        if (llAssortedRowIds.length === 0) {
            toastMessage("Please first move rows from Merch_Assort_Sandbox to Linelist to perform assortment process.", "warning");
            return;
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const tbl_name = merch_assortment_sandbox_mp.get("Table_Name");
        const stateData = getStateDataByTblName(tbl_name);
        if (llAssortedRowIds.length > 0) {
            const filtered = stateData.filter((dt) => llAssortedRowIds.includes(dt.row_id));
            filtered.map((data) => {
                data["Upload To Linelist Check"] = "Yes";
            })
        }
        setStateDataByTblName(tbl_name, stateData);
        sendDataChangesBackend(tbl_name, false, "merch_assort");
    }
    const setMerchAssortedRowIds = (row_ids) => {
        setllAssortedRowIds(oldArray => [...oldArray, ...row_ids]);
    }
    const closeDownlodedTab = (tabName) => {
        let tableName = "";
        switch (tabName) {
            case "Chase":
                tableName = chase_mp.get("Table_Name");

                setShowChase(false);
                dispatch({ type: "clearChase", data: [] });
                break;
            case "Sizing":
                tableName = sizing_mp.get("Table_Name");
                setShowSizing(false);
                dispatch({ type: "clearSizing", data: [] });
                break;
            case "Linelist":
                tableName = linelist_mp.get("Table_Name");
                setShowLinelist(false);
                dispatch({ type: "clearLinelist", data: [] });
                break;
            case "DTC_Buy_Sheet":
                tableName = dtc_buy_sheet_mp.get("Table_Name");
                setShowDtc(false);
                dispatch({ type: "clearDtc", data: [] });
                break;
            case "Wholesale":
                tableName = whsl_buy_sheet_mp.get("Table_Name");
                setShowWhsl(false);
                dispatch({ type: "clearWholesale", data: [] });
                break;
            case "Manage User":
                tableName = manage_user.get("Table_Name");
                setShowManageUser(false);
                dispatch({ type: "clearManageUser", data: [] });
                break;
        }
        const curr_table = tabList[tabValue][1];
        let curr_tab = tabList[tabValue][0];
        setTimeout(() => {
            if (curr_table === tableName) {
                setValue(0);
                curr_tab = "Seasons";
            } else {
                setValue(tabValue)
            }
            setCurrentTab(dispatchMui, curr_tab)
        }, 100);
    }
    useEffect(() => {
        if (showDtc && buttonAccess("btnDownloadDtc", storedUseType)) {
            const index = tabList.findIndex((tab) => tab[1] === dtc_buy_sheet_mp.get("Table_Name"));
            if (index >= 0) {
                setValue(index);
                setCurrentTab(dispatchMui, tabList[index][0]);
            }
        }
    }, [showDtc])
    useEffect(() => {
        if (showWhsl && buttonAccess("btnDownloadWholesale", storedUseType)) {
            const index = tabList.findIndex((tab) => tab[1] === whsl_buy_sheet_mp.get("Table_Name"));
            if (index >= 0) {
                setValue(index);
                setCurrentTab(dispatchMui, tabList[index][0]);
            }
        }
    }, [showWhsl])
    useEffect(() => {
        if (showLinelist && buttonAccess("btnDownloadLinelist", storedUseType)) {
            const index = tabList.findIndex((tab) => tab[1] === linelist_mp.get("Table_Name"));
            if (index >= 0) {
                setValue(index);
                setCurrentTab(dispatchMui, tabList[index][0]);
            }
        }
    }, [showLinelist])
    useEffect(() => {
        if (showSizing && buttonAccess("btnDownloadSizing", storedUseType)) {
            const index = tabList.findIndex((tab) => tab[1] === sizing_mp.get("Table_Name"));
            if (index >= 0) {
                setValue(index);
                setCurrentTab(dispatchMui, tabList[index][0]);
            }
        }
    }, [showSizing])
    useEffect(() => {
        if (showChase && buttonAccess("btnDownloadChase", storedUseType)) {
            const index = tabList.findIndex((tab) => tab[1] === chase_mp.get("Table_Name"));
            if (index >= 0) {
                setValue(index);
                setCurrentTab(dispatchMui, tabList[index][0]);
            }
        }
    }, [showChase])
    const downloadBtn = (tabName) => {
        if (tabName !== "Manage User" && (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false')) {
            toastMessage("Please download Linelist from Season tab to perform download Chase.");
            return;
        }
        const tables = [];
        switch (tabName) {
            case "Chase":
                tables.push(chase_mp.get("Table_Name"));
                break;
            case "Sizing":
                tables.push(sizing_mp.get("Table_Name"));
                break;
            case "Linelist":
                tables.push(linelist_mp.get("Table_Name"));
                break;
            case "DTC_Buy_Sheet":
                tables.push(dtc_buy_sheet_mp.get("Table_Name"));
                break;
            case "Wholesale":
                tables.push(whsl_buy_sheet_mp.get("Table_Name"));
                tables.push(whsl_buy_sheet_nord_rp_mp.get("Table_Name"));
                break;
            case "Manage User":
                tables.push(manage_user.get("Table_Name"));
                tables.push(manage_permission.get("Table_Name"));
                break;
        }
        tables.map((reqTbl, index) => {
            let paramwhere = ""
            if (getDataFromMetaData(reqTbl, 'download_type').toLocaleLowerCase() !== "default") {
                paramwhere = sessionStorage.getItem('asRequiredDownloadQuery') === null ? "" : sessionStorage.getItem('asRequiredDownloadQuery');
            }
            const rowFilter = GetRowFilterBasedOnTableName(metaData.current, reqTbl);
            const postBody = {
                "param_email": sessionStorage.getItem("email"),
                "param_table_name": reqTbl,
                "param_is_row_level": rowFilter,
                "param_where": paramwhere,
                "param_locale_diff": utcDifference
            };
            dispatch({ type: "SET_PLEASE_WAIT", data: true });
            GetTableDataLocale(JSON.stringify(postBody)).then(res => {
                if (res === "invalid_token") {
                    toastMessage("Session Expired", "warning")
                    sessionStorage.clear()
                    setTimeout(() => {
                        nevigate("/login");
                    }, 5000)
                    dispatch({ type: "SET_PLEASE_WAIT", data: false });
                } else {
                    if (res) {
                        setResetAuditTrail(true)
                        resetUnsentCount(reqTbl);
                        setStateDataByTblName(reqTbl, JSON.parse(res.data));
                        setSessionDataByTblName(reqTbl, JSON.parse(res.data));
                        // sessionStorage.setItem(req_rng, res)// abhi
                        // GetLatestTX_OnDemandDownload(reqTbl, 1)
                        sessionStorage.setItem(reqTbl + '_' + 'Latest_Tx_Id', res.latestTxsId)
                        sessionStorage.setItem(reqTbl + '_' + 'Latest_Tx_Id_On_Demand_Download', res.latestTxsId)
                        sessionStorage.setItem(reqTbl + '_' + 'On_Demand_Download_Time', new Date())

                        if (index >= tables.length - 1) {
                            toastMessage(`Downloaded ${tabName} successfully.`, "success")

                            switch (tabName) {
                                case "Chase":
                                    setShowChase(true);
                                    break;
                                case "Sizing":
                                    setShowSizing(true);
                                    break;
                                case "Linelist":
                                    setShowLinelist(true);
                                    break;
                                case "DTC_Buy_Sheet":
                                    setShowDtc(true);
                                    break;
                                case "Wholesale":
                                    setShowWhsl(true);
                                    break;
                                case "Manage User":
                                    setShowManageUser(true);
                                    break;
                            }
                        }
                    }
                    if (index >= tables.length - 1) dispatch({ type: "SET_PLEASE_WAIT", data: false });
                }
            });
        })
    }
    const checkTabPermission = (tabName) => {
        const userRole = sessionStorage.getItem("user_type");
        let btnId = "";
        switch (tabName) {
            case "Chase":
                btnId = "btnDownloadChase";
                break;
            case "Sizing":
                btnId = "btnDownloadSizing";
                break;
            case "Linelist":
                btnId = "btnDownloadLinelist";
                break;
            case "DTC_Buy_Sheet":
                btnId = "btnDownloadDtc";
                break;
            case "Whsl_Buy_Sheet":
                btnId = "btnDownloadWholesale";
                break;
            case "Whsl_Buy_Sheet_Nord_RP":
                btnId = "btnDownloadWholesale";
                break;
            case "Manage_User":
                btnId = "btnDownloadManageUser";
                break;
            case "Manage_Permission":
                btnId = "btnDownloadManageUser";
                break;
        }
        let result = false;
        if (buttonAccess(btnId, userRole) === true || buttonAccess(btnId, userRole) === 'true') result = false;
        else result = tabPermission(tabName);
        return result;
    }
    const openCarryOver = () => {
        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
            toastMessage("Please download Linelist from Season tab to perform Carry Over.", "warning");
            return;
        }
        // const activeTab = tabList[tabValue][0];
        // if (activeTab === linelist_mp.get("Tab_Name"))
        setOpenCarryOverDg(true);
        // else
        // toastMessage("Carry Over process is not available for this tab.", "warning");
    }
    const ShowGenie = () => {
        setShowGenie(true)
    }
    const ShowHelpPopup = () => {
        setOpenHelpDialog(true)
    }
    const setCarryOverIdsSeason = (data) => {
        const tmp = carryOverData;
        tmp.push(data);
        setCarryOverData(tmp);
    }
    const removeCarryOverIdsSeason = (removedData) => {
        if (carryOverData.length === 0) return;
        const result = carryOverData.filter((dt) =>
            removedData.some((removed) => removed.row_id === dt.row_id)
        );
        result.map((res) => res["Carry Over"] = "");
        setCarryOverData(result);
    }
    const carryOverBtnClick = () => {
        const find = carryOverData.filter((dt) => dt["Carry Over"] === "");
        if (carryOverData.length === 0 || find.length > 0) {
            toastMessage("Please add new season name to perform assortment process.", "warning");
            return;
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: true });
        const req_tbl = linelist_mp.get("Table_Name");
        let UpdateJsonData = '';
        let updatedcellcount = 0;
        let UpdateJson = '';
        let FinalStr = '';
        // console.log("carryOverData", carryOverData);
        carryOverData.map((data) => {
            UpdateJsonData = '';
            UpdateJsonData = UpdateJsonData + CH6 + "Carry Over" + CH7 + CH1 + CH6 + data["Carry Over"] + CH7 + CH4;
            updatedcellcount++;
            if (UpdateJsonData.length > 0) {
                UpdateJsonData = UpdateJsonData.substring(0, UpdateJsonData.length - 1)
                UpdateJson = UpdateJson + CH2 + data.row_id + CH3 + UpdateJsonData
            }
        })
        if (UpdateJson.length > 0) {
            UpdateJson = UpdateJson.substring(1, UpdateJson.length);
            FinalStr = "t1" + CH1 + sessionStorage.getItem('email') + CH1 + req_tbl + CH5 + 'NoData' + CH5 + UpdateJson + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData' + CH5 + 'NoData'
        }
        // console.log("UpdateJson", UpdateJson);
        // console.log("FinalStr", FinalStr);
        // return;
        sessionStorage.setItem(req_tbl + '_' + 'Cell_Updates_send', updatedcellcount)
        const apiNm = "SendUserDataOptimized"
        fetch(restApiPath + apiNm, {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: (
                FinalStr
            )
        })
            .then(result => result.json())
            .then(resData => processingSendUserDataForHiddenTab(resData, req_tbl, false, "carry_over"))
    }
    const txtChangeEvent = (e) => {
        setDQData([]);
        setDQView([]);
        const { name, value } = e.target;
        let val = value || "";
        setDQColDropdown(prevValues => ({
            ...prevValues,
            [name]: val,
        }));
    };
    const cmbChange = (event, newselect, ids) => {
        setDQData([]);
        setDQView([]);
        let val = newselect || "";
        setDQColDropdown(prevValues => ({
            ...prevValues,
            [ids]: val,
        }));
    }
    const cmbTableChange = (event, newselect) => {
        // const values = metaData.current.map(i => i.sheet_name);
        // console.log([...new Set(values)]);
        let tbl = getFromMetaData("sheet_name", newselect, "table_name");
        let valuesToRemove = ["row_id", "user_inserted", "inserted_on", "user_modified", "modified_on"];
        let somefilterdata = columnMetaData.filter(i => i.table_name === tbl);
        somefilterdata = somefilterdata.filter(i => !valuesToRemove.includes(i.column_name));
        let colList = [];
        somefilterdata.map(i => colList.push(i.column_name));
        setDropDownColumn(colList);
        setDQField([]);
        setDQColDropdown({ "user_id": sessionStorage.getItem("email"), "table_name": tbl });
        // let selectCol = somefilterdata.filter(i => ["Year", "Season Code", "Department", "Style"].includes(i.column_name));
        // let precolList = [];
        // selectCol.map(i => precolList.push(i.column_name));
        // setDQselectCol(precolList)
    }
    const addFieldComponent = () => {
        console.log("DQField", DQField);
        let ids = DQField.length + 2;
        let newItem = <Grid container spacing={1} padding={0.5}>

            <Grid item sm={1}>
                {/* <ToggleButtonGroup
                    color="primary"
                    value={"And"}
                    exclusive
                    // onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="And">And</ToggleButton>
                    <ToggleButton value="Or">OR</ToggleButton>
                </ToggleButtonGroup> */}
                <Autocomplete
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddAndOr${ids}`)}
                    // defaultValue="and"
                    options={["and", "or"]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="And / Or"
                            size="small"
                        />
                    )}
                />
            </Grid>
            <Grid item sm={2}>
                <Autocomplete
                    options={DropDownColumn}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select column to filter."
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddColumn${ids}`)}
                />
            </Grid>
            <Grid item sm={1.5}>
                <Autocomplete
                    // defaultValue="equal"
                    options={[
                        "equal",
                        "not equal",
                        "contains",
                        "not contains",
                        "less than",
                        "less than or equal",
                        "grater than",
                        "grater than or equal",
                    ]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Filter Condition"
                            size="small"
                        />
                    )}
                    onChange={(event, newselect) => cmbChange(event, newselect, `ddCondition${ids}`)}
                />
            </Grid>
            <Grid item sm={2}>
                <TextField
                    size="small"
                    placeholder="Filter Value"
                    name={`textBox${ids}`}
                    style={{ width: "100%", height: "14px" }}
                    onChange={txtChangeEvent}
                />
            </Grid>
        </Grid>;
        setDQField(prevItems => [...prevItems, newItem]);
    }
    useEffect(() => {
        if (DQData.length > 0) {
            let colDef = Object.keys(DQData[0]).map(key => ({ field: key, filter: true }));
            let sadsf = <>
                <div className="ag-theme-quartz" id="agGridStl" style={{ height: "450px" }}>
                    <AgGridReact
                        columnDefs={colDef}
                        rowData={DQData}
                        statusBar={statusBar}
                        enableCharts={true}
                        enableRangeSelection={true}
                        rowSelection="multiple"
                    />
                </div>
            </>;
            setDQView(sadsf);
        }
        else setDQView([]);
    }, [DQData]);
    const btnDQApplyClick = () => {
        console.log(DQColDropdown);
        let loop = DQField.length + 1;
        for (let i = 1; i <= loop; i++) {
            let col = DQColDropdown[`ddColumn${i}`] || "";
            let select_columns = DQColDropdown[`select_columns`] || "";
            let ddandor = "0";
            let ddCondition = DQColDropdown[`ddCondition${i}`] || "";
            if (i > 1) ddandor = DQColDropdown[`ddAndOr${i}`] || "";
            if (col !== "" && ddandor === "") toastMessage("Please select And/Or !");
            else if (col !== "" && ddCondition == "") toastMessage("Please select Condition !");
            else if (select_columns == "" || select_columns.length === 0) toastMessage("Please select view columns !");
            else getDyanmicDataTable();
        }
    }
    const getDyanmicDataTable = async () => {
        const postBody = {
            "param_function": "ttd_dynamic_query",
            "param_body": DQColDropdown
        };
        const url = `${restApiPath}callPGFunction`;
        const requestbody = {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postBody),
        };
        try {
            const response = await fetch(url, requestbody);
            const json = await response.json();
            if (json.error === "invalid_token") {
                toastMessage("Session Expired", "error");
                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 5000);
            }
            if (json.response) {
                const resJsonData = JSON.parse(json.data);
                setDQData(resJsonData);
            }
        } catch (exception) { }
    };
    const tabPanelHeight = 124;
    const storedUseType = sessionStorage.getItem("user_type");
    const messageofgenie = <>Hey {ProperCase(sessionStorage.getItem("firstName"))} !
        <br />
        I'm your assistant. Let's play !</>;
    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: "agTotalAndFilteredRowCountComponent" },
                // { statusPanel: "agTotalRowCountComponent", align: "center" },
                // { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        };
    }, []);

    return (
        <DashboardLayout>
            <Dialog open={mySavePrefAlert}>
                <DialogContent>
                    <MDBox style={{ fontWeight: '400', fontSize: '15px', textAlign: 'center' }}>Save Preferences !
                    </MDBox>
                    <DialogActions style={{ justifyContent: 'center' }}>

                        <MDButton
                            size="small"
                            style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                            onClick={() => SavePreference('set')}>
                            Set
                        </MDButton>
                        <MDButton
                            size="small"
                            style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                            onClick={() => SavePreference('reset')}>
                            Reset
                        </MDButton>
                        <MDButton
                            size="small"
                            style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                            onClick={() => setMySavePrefAlert(false)}>
                            Close
                        </MDButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={useSelector((s) => s.pleaseWait)}>
                <DialogContent>
                    <MDBox style={{ textAlign: 'center' }}><CircularProgress />
                    </MDBox>
                    <MDBox style={{ fontWeight: '500', fontSize: '15px', textAlign: 'center' }}>Please wait
                    </MDBox>
                </DialogContent>
            </Dialog>
            <div style={{ height: windowDimensions.height - 36 }}>
                <MDBox id="TABLIST" pt={1.4} mb={4}>
                    <DefaultNavbar
                        ref={merchToPdMsg1Ref}
                        assortBtnCall={assortBtnCall}
                        btnCallAddRow={btnAddRow}
                        btnDeleteRow={btnDeleteRow}
                        btnSavePreference={btnSavePreference}
                        bulk_import_btn_click={bulk_import_btn_click}
                        calculateSizesBtnCall={() => Calculate_Sizes(currentTab)}
                        copyAndDropRowBtnCall={copyAndDropRowBtnCall}
                        copyRowBtnCall={copyRowBtnCall}
                        costingViewBtnCall={costingViewBtnCall}
                        defaultCostingViewBtnCall={defaultCostingViewBtnCall}
                        downloadLinelistBtnCall={downloadLinelistBtnCall}
                        downloadBtn={downloadBtn}
                        generateItemUpload={generateItemUpload}
                        getChangesBtnCall={getChangesBtnCall}
                        highlightStyle={highlightStyle}
                        importDataBtnCall={importDataBtnCall}
                        onQuickFilterChange={onQuickFilterChange}
                        quickFilterInputRef={quickFilterInputRef}
                        quickFilterText={quickFilterText}
                        redownloadTabBtnCall={redownloadTabBtnCall}
                        sendChangeMemoBtnCall={sendChangeMemoBtnCall}
                        sendChangesBtnCall={sendChangesBtnCall}
                        ShowChangesBtnCall={ShowChangesBtnCall}
                        upload_btn_click={upload_btn_click}
                        finalCostingView={finalCostingView}
                        btnCarryOver={openCarryOver}
                        ShowGenie={ShowGenie}
                        ShowHelpPopup={ShowHelpPopup}
                        summaryDlgOpen={summaryDlgOpen}
                        activeStyleActivityFilterBtnCall={activeStyleActivityFilterBtnCall}
                        droppedStyleActivityFilterBtnCall={droppedStyleActivityFilterBtnCall}
                        allStyleActivityFilterBtnCall={allStyleActivityFilterBtnCall}

                    />
                </MDBox>
                <Grid container spacing={0}>
                    <Grid item xs={closeShowChanges === "none" ? 12 : 10.30}>

                        <Grow in={true}>
                            <Tabs
                                variant="scrollable"
                                scrollButtons={true}
                                action={ref}
                                indicatorColor="primary"
                                onChange={handleChange}
                                textColor="primary"
                                value={tabValue}
                                TabIndicatorProps={{
                                    sx: {
                                        bgcolor: "#6696DD",
                                        height: "2px",
                                    }
                                }}
                                style={{ height: "40px" }} // NKS
                            >
                                {tabPermission("Seasons") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.SeasonUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.SeasonUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Seasons
                                    </StyledBadge>} {...a11yProps(0)} value={0} /> : null}
                                {tabPermission("PD_Initial_Costing") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.PDInitialCostingUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.PDInitialCostingUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">PD_Initial_Costing
                                    </StyledBadge>} {...a11yProps(1)} value={1} /> : null}
                                {tabPermission("Merch_Assortment_Sandbox") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.MerchAssortmentSandboxUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.MerchAssortmentSandboxUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Merch_Assortment_Sandbox
                                    </StyledBadge>} {...a11yProps(2)} value={2} /> : null}

                                {checkTabPermission("Linelist") || showLinelist ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.LineListUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.LineListUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Linelist{
                                            buttonAccess("btnDownloadLinelist", storedUseType) ? (
                                                <Tooltip title="Close Linelist">
                                                    {/* <IconButton size="small" onClick={() => closeDownlodedTab("Linelist")}>
                                                        <Close />
                                                    </IconButton> */}
                                                    <button style={closeXcss} onClick={() => closeDownlodedTab("Linelist")}>
                                                        Χ
                                                    </button>
                                                </Tooltip>
                                            ) : null
                                        }
                                    </StyledBadge>} {...a11yProps(3)} value={3} /> : null}
                                {checkTabPermission("Chase") || showChase ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.ChaseUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.ChaseUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info"><span>
                                            {'Chase'}
                                            <Tooltip title="Close Chase">
                                                <button style={closeXcss} onClick={() => closeDownlodedTab("Chase")}>
                                                    Χ
                                                </button>
                                            </Tooltip>
                                        </span>
                                    </StyledBadge>} {...a11yProps(4)} value={4} /> : null}
                                {checkTabPermission("DTC_Buy_Sheet") || showDtc ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.DtcButSheetUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.DtcButSheetUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">DTC_Buy_Sheet{
                                            buttonAccess("btnDownloadDtc", storedUseType) ? (
                                                <Tooltip title="Close DTC_Buy_Sheet">
                                                    <button style={closeXcss} onClick={() => closeDownlodedTab("DTC_Buy_Sheet")}>
                                                        Χ
                                                    </button>
                                                </Tooltip>
                                            ) : null
                                        }
                                    </StyledBadge>} {...a11yProps(5)} value={5} /> : null}
                                {checkTabPermission("Whsl_Buy_Sheet") || showWhsl ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.WhslBuySheetUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.WhslBuySheetUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Whsl_Buy_Sheet{
                                            buttonAccess("btnDownloadWholesale", storedUseType) ? (
                                                <Tooltip title="Close Wholesale">
                                                    <button style={closeXcss} onClick={() => closeDownlodedTab("Wholesale")}>
                                                        Χ
                                                    </button>
                                                </Tooltip>
                                            ) : null
                                        }
                                    </StyledBadge>} {...a11yProps(6)} value={6} /> : null}
                                {checkTabPermission("Whsl_Buy_Sheet_Nord_RP") || showWhsl ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.WhslBuySheetNordUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.WhslBuySheetNordUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Whsl_Buy_Sheet_Nord_RP{
                                            buttonAccess("btnDownloadWholesale", storedUseType) ? (
                                                <Tooltip title="Close Wholesale">
                                                    <button style={closeXcss} onClick={() => closeDownlodedTab("Wholesale")}>
                                                        Χ
                                                    </button>
                                                </Tooltip>
                                            ) : null}
                                    </StyledBadge>} {...a11yProps(7)} value={7} /> : null}
                                {checkTabPermission("Sizing") || showSizing ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.SizingUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.SizingUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info"><span>
                                            {'Sizing'}
                                            {
                                                buttonAccess("btnDownloadSizing", storedUseType) ? (
                                                    <Tooltip title="Close Sizing">
                                                        <button style={closeXcss} onClick={() => closeDownlodedTab("Sizing")}>
                                                            Χ
                                                        </button>
                                                    </Tooltip>
                                                ) : null
                                            }
                                        </span>
                                    </StyledBadge>} {...a11yProps(8)} value={8} /> : null}
                                {
                                    (sessionStorage.getItem("user_type") !== "vendor" && sessionStorage.getItem("user_type") !== "merch reference" && sessionStorage.getItem("user_type") !== "read-only")
                                        ?
                                        <Tab style={tabPadding} label="Summary_Report" {...a11yProps(9)} value={9} />
                                        :
                                        null
                                }
                                {tabPermission("Buy_Sheet_Master_Sizing_%") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.BuySheetMasterSizingPercentageUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.BuySheetMasterSizingPercentageUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Buy_Sheet_Master_Sizing_%
                                    </StyledBadge>} {...a11yProps(10)} value={10} /> : null}
                                {tabPermission("BuySht_Master_Data_Charts") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.BuyShtMasterDataChartsUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.BuyShtMasterDataChartsUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">BuySht_Master_Data_Charts
                                    </StyledBadge>} {...a11yProps(11)} value={11} /> : null}
                                {tabPermission("Comp_APS_Master_StoreEcom") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.CompApsMasterStoreEcomUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.CompApsMasterStoreEcomUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Comp_APS_Master_StoreEcom
                                    </StyledBadge>} {...a11yProps(12)} value={12} /> : null}
                                {tabPermission("Freight_Master") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.FreightMasterUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.FreightMasterUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Freight_Master
                                    </StyledBadge>} {...a11yProps(13)} value={13} /> : null}
                                {tabPermission("Freight_by_Category") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.FreightByCategoryUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.FreightByCategoryUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Freight_by_Category
                                    </StyledBadge>} {...a11yProps(14)} value={14} /> : null}
                                {tabPermission("Delivery_Master") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            DeliveryMasterUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{DeliveryMasterUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Delivery_Master
                                    </StyledBadge>} {...a11yProps(15)} value={15} /> : null}
                                {tabPermission("FB_Fty_Database") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.FbFtyDatabaseUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.FbFtyDatabaseUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">FB_Fty_Database
                                    </StyledBadge>} {...a11yProps(16)} value={16} /> : null}
                                {tabPermission("Size_Scales") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.SizeScalesUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.SizeScalesUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Size_Scales
                                    </StyledBadge>} {...a11yProps(17)} value={17} /> : null}
                                {tabPermission("Shopify_Product_Type_Map") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.ShopifyProductTypeMappingUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.ShopifyProductTypeMappingUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Shopify_Product_Type_Map
                                    </StyledBadge>} {...a11yProps(18)} value={18} /> : null}
                                {tabPermission("Season_Inquiry") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.SeasonInquiryUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.SeasonInquiryUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Season_Inquiry
                                    </StyledBadge>} {...a11yProps(19)} value={19} /> : null}
                                {tabPermission("Master_LOV") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.MasterLovUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.MasterLovUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Master_LOV
                                    </StyledBadge>} {...a11yProps(20)} value={20} /> : null}
                                {tabPermission("Airtable_Mapping_Data") ? <Tab style={tabPadding} label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.AirtableMappingUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.AirtableMappingUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Airtable_Mapping_Data
                                    </StyledBadge>} {...a11yProps(21)} value={21} /> : null}
                                {tabPermission("Action_Item") ? <Tab style={tabPadding} label="Action_Item" {...a11yProps(22)} value={22} /> : null}
                                {<Tab style={tabPadding} label="Pivot" {...a11yProps(23)} value={23} />}
                                {checkTabPermission("Manage_User") || showManageUser ? <Tab label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.ManageUserUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.ManageUserUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Manage_User{
                                            buttonAccess("btnDownloadManageUser", storedUseType) ? (
                                                <Tooltip title="Close Manage_User">
                                                    <button style={closeXcss} onClick={() => closeDownlodedTab("Manage User")}>
                                                        Χ
                                                    </button>
                                                </Tooltip>
                                            ) : null
                                        }
                                    </StyledBadge>} {...a11yProps(24)} value={24} /> : null}
                                {checkTabPermission("Manage_Permission") || showManageUser ? <Tab label={
                                    <StyledBadge
                                        badgeContent={
                                            reduxState.ManagePermissionUnsentCount > 0 ? (
                                                <Tooltip title="User's Unsent Changes Count">
                                                    <span>{reduxState.ManagePermissionUnsentCount}</span>
                                                </Tooltip>
                                            ) : null

                                        } size="small" color="info">Manage_Permission{
                                            buttonAccess("btnDownloadManageUser", storedUseType) ? (
                                                <Tooltip title="Close Manage_Permission">
                                                    <button style={closeXcss} onClick={() => closeDownlodedTab("Manage User")}>
                                                        Χ
                                                    </button>
                                                </Tooltip>
                                            ) : null
                                        }
                                    </StyledBadge>} {...a11yProps(25)} value={25} /> : null}
                            </Tabs>
                        </Grow>
                        <TabPanel id="aggridtab" value={tabValue} index={0}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <SeasonTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <PDInitialCostingTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <MerchAssortmentSandbox
                                    ref={myRef}
                                    setCurrentGridApi={setCurrentGridApi}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showLinelist ? (
                                        <LinelistTab
                                            ref={myRef}
                                            setCurrentGridApi={setCurrentGridApi}
                                            setActionItemDataFun={setActionItemDataFun} />) : null
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showChase ? (
                                        <ChaseTab
                                            refFBFtyDatabase={reduxState.FbFtyDatabaseData}
                                            setCurrentGridApi={setCurrentGridApi}
                                            ref={myRef}
                                            setActionItemDataFun={setActionItemDataFun}
                                        />
                                    ) : null
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showDtc ? (
                                        <DtcBuySheetTab
                                            setCurrentGridApi={setCurrentGridApi}
                                            ref={myRef}
                                            setActionItemDataFun={setActionItemDataFun}
                                        />
                                    ) : null
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showWhsl ? (
                                        <WhslBuySheetTab
                                            setCurrentGridApi={setCurrentGridApi}
                                            ref={myRef}
                                            setActionItemDataFun={setActionItemDataFun}
                                        />
                                    ) : null
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={7}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showWhsl ? (
                                        <WhslBuySheetNordRpTab
                                            setCurrentGridApi={setCurrentGridApi}
                                            ref={myRef}
                                            setActionItemDataFun={setActionItemDataFun}
                                        />) : null}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={8}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showSizing ? (
                                        <SizingTab
                                            setCurrentGridApi={setCurrentGridApi}
                                            ref={myRef}
                                            setActionItemDataFun={setActionItemDataFun}
                                        />
                                    ) : null
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={9}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <SummaryReportTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={10}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <BuySheetMasterSizingPercentageTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={11}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <BuyShtMasterDataChartsTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={12}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <CompApsMasterStoreEcomTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={13}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <FreightMasterTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={14}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <FreightByCategoryTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={15}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <DeliveryMasterTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={16}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <FbFtyDatabaseTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={17}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <SizeScalesTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={18}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <ShopifyProductTypeMappingTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={19}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <SeasonInquiryTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={20}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <MasterLovTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={21}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <Airtable_Mapping_Data
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={22}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                <ActionItemTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                    setActionItemDataFun={setActionItemDataFun}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={23}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight - 45 }}>
                                <PivotTab
                                    setCurrentGridApi={setCurrentGridApi}
                                    ref={myRef}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={24} >
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showManageUser ? (
                                        <ManageUsersTab
                                            setCurrentGridApi={setCurrentGridApi}
                                            sendDataChangesBackend={sendDataChangesBackend}
                                            ref={myRef}
                                        />
                                    ) : null
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={25}>
                            <div style={{ height: windowDimensions.height - tabPanelHeight }}>
                                {
                                    showManageUser ? (
                                        <ManagePermissionTab
                                            sendDataChangesBackend={sendDataChangesBackend}
                                            setCurrentGridApi={setCurrentGridApi}
                                            ref={myRef}
                                        />
                                    ) : null
                                }
                            </div>
                        </TabPanel>
                    </Grid>
                    <Grid item xs={closeShowChanges === "none" ? 12 : 1.70} sx={{ display: closeShowChanges }}>
                        {closeShowChanges === 'block' ?
                            <AuditTrailList
                                closeShowChangesBtn={closeShowChangesBtn}
                                tabList={tabList}
                                activeTab={activeTab}
                                showNotificationMsg={showNotificationMsg}
                                changeTab={changeTab}
                                resetAuditTrail={resetAuditTrail}
                            /> : null}
                    </Grid>

                </Grid>
            </div>
            <div>
                {showGenie ?
                    <div id="divgenie">
                        <div class="object">
                            {/* <div class="tooltip">
                                <img class="myimg" src="buddy.gif" width="55px" onClick={() => setOpenTTDDialog(true)} />
                                <span class="tooltiptext">
                                    Hey {ProperCase(sessionStorage.getItem("firstName"))}, I'm Genie,
                                    <br />
                                    How can I guide you ?
                                    <br />
                                    Just click me !
                                </span>
                            </div> */}
                            <Tooltip title={messageofgenie} placement="left-start">
                                {/* <img className="myimg" src="buddy.gif" width="55px" onClick={() => setOpenTTDDialog(true)} /> */}
                                <img className="myimg" src="buddy.gif" width="55px" style={{ cursor: 'pointer' }} onClick={() => {
                                    setOpenConfigurator(dispatchMui, !openConfigurator)
                                }} />
                            </Tooltip>
                        </div>
                    </div> : null}
                <Footer />
            </div>
            {/* helpui */}
            <Dialog
                fullScreen
                disableEscapeKeyDown
                maxWidth="97%"
                open={openHelpDialog}
            >
                <DialogTitle disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>
                    <div class="typeone">
                        <div class="typetwo">
                            Help / Tips
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DashboardLayoutBasic />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={() => {
                            setOpenHelpDialog(false);
                        }
                        }>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
            {/* talktodata */}
            <Dialog
                fullScreen
                disableEscapeKeyDown
                maxWidth="97%"
                open={openTTDDialog}
            >
                <DialogTitle disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>
                    <div class="typeone">
                        <div class="typetwo">
                            {/* I can support you with the tasks listed below that may need your action. */}
                            Analytics Summary
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        {ttdCards}
                    </Grid>
                    <Grid container spacing={1}>
                        <span style={{ fontSize: "12px", marginTop: "15px" }} >
                            <span style={{ color: "black", fontSize: "15px" }}>{ttdPopupDetail.current.title}</span> {ttdPopupDetail.current.sub_title}
                        </span>
                    </Grid>
                    <div style={{ height: "550px", marginTop: "15px" }}>
                        {ttdView}
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Tooltip title="You can bring back the Buddy from the profile icon in the top right corner!" placement="top">
                        <MDButton
                            size="small"
                            onClick={() => {
                                setShowGenie(false);
                                setOpenTTDDialog(false);
                                setTtdPopupData([]);
                                ttdPopupDetail.current = [];
                                toastMessage("You can bring back the Buddy from the profile icon in the top right corner!");
                            }
                            }>
                            Say Goodbye 👋 to Buddy
                        </MDButton>
                    </Tooltip>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={() => {
                            setOpenTTDDialog(false);
                            setTtdPopupData([]);
                            ttdPopupDetail.current = [];
                        }
                        }>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                // fullWidth={true}
                fullScreen
                disableEscapeKeyDown
                maxWidth="97%"
                open={summaryView}

            >
                <DialogTitle disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>
                    Summary View : {activeTab}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <SummarizedProductionData row_ids={visiblerows} />
                    </Grid>

                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={() => {
                            setSummaryView(false)
                        }
                        }>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth={true}
                disableEscapeKeyDown
                maxWidth="97%"
                open={openAssortDialog}
                aria-labelledby="classic-modal-slide-title-sync-data"
                aria-describedby="classic-modal-slide-description-sync-data"
            >
                <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>Assort - PD_Initial_Costing to Merch_Assortment_Sandbox</DialogTitle>
                <DialogContent id="classic-modal-slide-description-sync-data">
                    <PD_Assortment_Workflow setAssortedRowIds={setAssortedRowIds} setRevertedRowIds={setRevertedRowIds} />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={performAssortMent}>
                        Assort
                    </MDButton>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={() => setOpenAssortDialog(false)}>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                disableEscapeKeyDown
                maxWidth="95%"
                open={openCarryOverDg}
                aria-labelledby="classic-modal-slide-title-sync-data"
                aria-describedby="classic-modal-slide-description-sync-data"
            >
                <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>Carry Over</DialogTitle>
                <DialogContent id="classic-modal-slide-description-sync-data">
                    <CarryOver setCarryOverIdsSeason={setCarryOverIdsSeason} removeCarryOverIdsSeason={removeCarryOverIdsSeason} />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={carryOverBtnClick}
                    >
                        Assort
                    </MDButton>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                        onClick={() => setOpenCarryOverDg(false)}>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                disableEscapeKeyDown
                maxWidth="95%"
                open={openLlAssortDialog}
                aria-labelledby="classic-modal-slide-title-sync-data"
                aria-describedby="classic-modal-slide-description-sync-data"
            >
                <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>Assort - Merch_Assortment_Sandbox to Linelist</DialogTitle>
                <DialogContent id="classic-modal-slide-description-sync-data">
                    <Merch_Linelist_Assortment_Workflow setMerchAssortedRowIds={setMerchAssortedRowIds} />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Poppins', textTransform: 'none', color: "white" }}
                        onClick={performLlAssortMent}>
                        Assort
                    </MDButton>
                    <MDButton
                        size="small"
                        style={{ backgroundColor: 'black', fontFamily: 'Poppins', textTransform: 'none', color: "white" }}
                        onClick={() => setOpenLlAssortDialog(false)}>
                        Close
                    </MDButton>
                </DialogActions>
            </Dialog>
            {
                (tabList !== null && tabList !== undefined && tabList.length > 0)
                    ?
                    <Dialog
                        open={blSendDataStatistics}
                        onClose={closeSendDataSt}
                        aria-labelledby="classic-modal-slide-title-sync-data"
                        aria-describedby="classic-modal-slide-description-sync-data"
                    >
                        <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center' }}>Send Changes</DialogTitle>
                        <DialogContent id="classic-modal-slide-description-sync-data">
                            <SendDataStatistics table_name={tabList[tabValue][1]} />
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                                onClick={closeSendDataSt}>
                                Close
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    :
                    null
            }
            {
                (tabList !== null && tabList !== undefined && tabList.length > 0)
                    ?
                    <Dialog
                        open={blReceiveDataStatistics}
                        onClose={() => setBlReceiveDataStatistics(false)}
                        aria-labelledby="classic-modal-slide-title-sync-data"
                        aria-describedby="classic-modal-slide-description-sync-data"
                    >
                        <DialogTitle id="classic-modal-slide-title-sync-data" disableTypography style={{ fontWeight: '600', fontSize: '15px' }}>Get Changes</DialogTitle>
                        <DialogContent id="classic-modal-slide-description-sync-data">
                            <GetChangesStatistics table_name={tabList[tabValue][1]} TabNm={tabList[tabValue][0]} />
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <MDButton
                                size="small"
                                style={{ backgroundColor: 'black', fontFamily: 'Inter', textTransform: 'none', color: "white" }}
                                onClick={() => setBlReceiveDataStatistics(false)}>
                                Close
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    :
                    null}
            {
                blActionItem
                    ?
                    <Dialog
                        fullWidth={true}
                        maxWidth="lg"
                        open={blActionItem}
                        onClose={() => setBlActionItem(false)}
                        aria-labelledby="classic-modal-slide-title-action-item"
                        aria-describedby="classic-modal-slide-description-action-item"
                    >
                        <Grid container rowSpacing={1} >
                            {createNewActionItem.current ?
                                <Grid item xs={6} md={2}>
                                    <DialogTitle id="classic-modal-slide-title-action-item" disableTypography style={{ display: "flex", fontWeight: '600', fontSize: '15px' }}>

                                        {`Action Item`}
                                    </DialogTitle>
                                </Grid>
                                :
                                <Grid item xs={6} md={2.1}>
                                    <DialogTitle id="classic-modal-slide-title-action-item" disableTypography style={{ display: "flex", fontWeight: '600', fontSize: '15px' }}>

                                        {`Select Action Item`}
                                    </DialogTitle>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <div>
                                    {createNewActionItem.current ?
                                        null
                                        :
                                        <Autocomplete
                                            disablePortal
                                            autoSelect
                                            style={{ marginTop: "10px" }}
                                            disableClearable
                                            id="combo-box-demo"
                                            options={actionItemTaskTitlelist}
                                            getOptionLabel={(option) => option["TaskTitle"]}
                                            defaultValue={actionItemTaskTitlelist[0]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                />
                                            )}
                                            onChange={(event, newselect) => handelTaskTitleChange(event, newselect)}
                                        />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <DialogContent id="classic-modal-slide-description-action-item">
                            <Stack
                                direction="row" useFlexGap flexWrap="wrap" style={{ marginTop: "5px" }}
                            >
                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    fullWidth
                                    options={ActiveUserData.current}
                                    onChange={(event, newselect) => handelAssigned(event, newselect)}
                                    getOptionLabel={(option) => option["first_name"] + " " + option["last_name"] + " (" + option["email"] + ")"}
                                    defaultValue={actionItemAssigned}
                                    value={actionItemAssigned}
                                    renderInput={(params) => (
                                        <div>
                                            <TextField
                                                {...params}
                                                label="Assignee"
                                            />
                                        </div>
                                    )}
                                />
                            </Stack>
                            <div>
                                <TextField
                                    required
                                    // disabled={!isAiAssignee.current}
                                    margin="normal"
                                    id="outlined-required"
                                    label="Title"
                                    placeholder="Enter 'Action Item' Title here..."
                                    fullWidth
                                    value={aiTitleValue}
                                    onChange={(e) => setaiTitleValue(e.target.value)}
                                />

                            </div>
                            <div>
                                <TextField
                                    placeholder="Enter your 'Action Item' Description here..."
                                    label="Description"
                                    margin="normal"
                                    rows="4"
                                    multiline
                                    fullWidth
                                    required
                                    value={aiTxtTask}
                                    onChange={(e) => handleTaskInputChange(e)}
                                />
                            </div>
                            <div margin="normal">
                                <Grid container rowSpacing={1} >
                                    <Grid item xs={6}>
                                        <InputLabel style={{ marginTop: "15px", height: "15px" }}> Priority </InputLabel>
                                        <RadioGroup margin="normal"
                                            row
                                            style={{ marginTop: "5px" }}
                                            aria-labelledby="demo-form-control-label-placement"
                                            name="Priority"
                                            // defaultValue="High"
                                            value={priorityValue}
                                            onChange={handlePriorityRadioChange}
                                        >
                                            <FormControlLabel value="High" control={<Radio />} label="High" />
                                            <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                                            <FormControlLabel value="Low" control={<Radio />} label="Low" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel style={{ marginTop: "15px", height: "15px" }}> Cell timeline </InputLabel>
                                        <label style={{ marginTop: "15px", marginLeft: "0px", fontSize: "14px", fontWeight: "700", color: "#344767" }}> Start Date </label>
                                        <input //disabled={!isAiAssignee.current} 
                                            style={{ marginTop: "15px", "font-family": "inherit" }} id="startDate" value={aiStartDateValue} type="date" onChange={(e) => setaiStartDateValue(e.target.value)} />
                                        <label style={{ marginTop: "15px", marginLeft: "10px", fontSize: "14px", fontWeight: "700", color: "#344767" }}> End Date </label>
                                        <input //disabled={!isAiAssignee.current} 
                                            style={{ marginTop: "15px", "font-family": "inherit" }} id="EndDate" value={aiEndDateValue} type="date" onChange={(e) => setaiEndDateValue(e.target.value)} />
                                    </Grid>
                                </Grid>
                            </div>
                            <div margin="normal">
                                <Grid container rowSpacing={1} >
                                    <Grid item xs={6}>
                                        <InputLabel style={{ marginTop: "15px", height: "15px" }}> Status </InputLabel>
                                        <RadioGroup margin="normal"
                                            row
                                            style={{ marginTop: "5px" }}
                                            name="Status"
                                            defaultValue="Open"
                                            value={statusValue}
                                            onChange={handleStausRadioChange}
                                        >
                                            <FormControlLabel value="Open" control={<Radio />} label="Open" />
                                            <FormControlLabel value="Started" control={<Radio />} label="Started" />
                                            <FormControlLabel value="Completed" control={<Radio />} label="Completed" />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel style={{ marginTop: "15px", height: "15px" }}> Comments </InputLabel>
                                        <MDInput
                                            margin="normal"
                                            fullWidth
                                            required
                                            value={remarksValue}
                                            placeholder="Enter 'Action Item' Remarks here..."
                                            onChange={(e) => setremarkValue(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <MDButton
                                style={{ textTransform: 'none', backgroundColor: "#e8e8e8", color: "black" }}
                                onClick={() => handleAssignOK()}
                            >
                                Apply
                            </MDButton>
                            <MDButton
                                style={{ textTransform: 'none', backgroundColor: "#e8e8e8", color: "black" }}
                                onClick={() => { handelNewCancelClick() }}                            >
                                {createNewActionItem.current ? "Cancel" : "New"}
                            </MDButton>
                            <MDButton
                                style={{ textTransform: 'none', backgroundColor: "#e8e8e8", color: "black" }}
                                onClick={() => handleDeleteClick()}
                            >
                                Delete
                            </MDButton>
                            <MDButton
                                style={{ textTransform: 'none', backgroundColor: "#e8e8e8", color: "black" }}
                                onClick={() => { setBlActionItem(false); setAiTxtTask(""); setActionItemAssigned([]) }}
                            >
                                Close
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    :
                    null
            }
            <Dialog
                fullWidth={true}
                disableEscapeKeyDown
                maxWidth="xxxl"
                open={dataImportOpen}
            >
                <DialogTitle sx={{ paddingTop: "5px", paddingBottom: "0px", textAlign: "center", backgroundColor: '#fafafb', fontSize: '17px' }}>{importDataFromSheet}</DialogTitle>
                <DialogContent sx={{ paddingBottom: "12px" }}>
                    <ImportData
                        activeTabGridApi={gridApi.current}
                        req_tbl={tabList.filter((tab) => tab[0] === activeTab)[0][1]}
                        tabNm={activeTab}
                        importDataFromSheet={importDataFromSheet}
                        onImportDataCloseClick={onImportDataCloseClick}
                    />
                </DialogContent>
            </Dialog>
            <Configurator notificationData={notificationData}></Configurator>
        </DashboardLayout >
    )
}
export default Linelist;
//7366