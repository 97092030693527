import { ArrowRight, Close, FilterAlt } from "@mui/icons-material";
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";
import { CH1, CH2, ExcelDateToJsDate, ProperCase, getColumnDefByTblName, getHighlightByTblName } from "layouts/linelist/XL_Utils";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { restApiPath, utcDifference } from "utils/app.props";
import AuditTrailCard from "../AuditTrailCard";

const AuditTrailList = ({ closeShowChangesBtn, tabList, activeTab, showNotificationMsg, changeTab, resetAuditTrail }) => {
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [anchorEl, setAnchorEl] = useState(null);
    const [dateSubAnchorEl, setDateSubAnchorEl] = useState(null);

    const [selectedDate, setSelectedDate] = useState('Today'); // Set the default selected sub item
    const [openDateRange, setOpenDateRange] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [title, setTitle] = useState(activeTab);
    const [changedData, setChangedData] = useState([]);

    const [selectedColumn, setSelectedColumn] = useState([]);
    const [openColumnList, setOpenColumnList] = useState(false);

    const nevigate = useNavigate();
    const [isLoading, setIsLoaing] = useState(false);
    const offset = useRef(0);
    const [hasMoreData, setHasMoreData] = useState(true);

    const [colDef, setColDef] = useState([])

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDateMenuClick = (event) => {
        setDateSubAnchorEl(event.currentTarget);
    };

    const handleColumnMenuClick = (event) => {
        // setColumnSubAnchorEl(event.currentTarget);
        setOpenColumnList(true)
        setAnchorEl(null);
    };

    const handleDateMenuClose = (dt) => {
        if (dt === "Date Range") {
            setSelectedDate(dt)
            setOpenDateRange(true)
            setDateSubAnchorEl(null);
            setAnchorEl(null);
            return;
        }
        setDateSubAnchorEl(null);
        setAnchorEl(null);
        if (dt) {
            setSelectedDate(dt)

            //remove highlighted cells
            const curTab = tabList.filter((tab) => tab[0] === title)
            const table_name = curTab[0][1]

            let highlightCells = getHighlightByTblName(table_name);
            highlightCells = highlightCells.filter((item) => {
                let res = true;
                Object.entries(item).map(([key1, value]) => {
                    if (value === 4) res = false
                })
                return res;
            })
            setHighlightByTblName(table_name, highlightCells)
            offset.current = 0
            setChangedData([])
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
        handleDateMenuClose(); // Close submenu when main menu is closed
    };
    const handleToggle = (column) => {
        let cols = [...selectedColumn];
        if (column === "Select All") {
            if (selectedColumn.includes("Select All")) cols = [];
            else {
                cols = [];
                colDef.map((col) => {
                    if (!col.hide) cols.push(col.headerName)
                })
            }
        } else {
            if (selectedColumn.includes(column)) {
                cols = selectedColumn.filter((col) => col !== column && col !== "Select All");
            } else cols.push(column)
        }
        // console.log("cols", cols)
        setSelectedColumn(cols)
    };

    const getChanges = async () => {
        //for todays date
        setIsLoaing(true);
        let dt1 = new Date()
        let dt2 = new Date()

        if (selectedDate === "Yesterday") { dt1.setDate(dt1.getDate() - 1); dt2.setDate(dt2.getDate() - 1); }
        else if (selectedDate === "This Week") {
            dt1 = new Date(dt1.setDate(dt1.getDate() - dt1.getDay()));
            dt2 = new Date(dt2.setDate(dt2.getDate() - dt2.getDay() + 6));
        } else if (selectedDate === "Last Week") {
            dt1 = new Date(dt1.setDate(dt1.getDate() - dt1.getDay() - 7));
            dt2 = new Date(dt2.setDate(dt1.getDate() - dt1.getDay() + 6));
        } else if (selectedDate === "Date Range") {
            dt1 = startDate;
            dt2 = endDate;
        }

        const stDate = moment(dt1).format('DD-MMM-YYYY')
        const edDate = moment(dt2).format('DD-MMM-YYYY')
        const curTab = tabList.filter((tab) => tab[0] === title)

        const jsonReq = {
            "start_dt": stDate,
            "end_dt": edDate,
            "template_name": sessionStorage.getItem("Template_Name"),
            "param_agent_vendor": "",
            "param_user_id": sessionStorage.getItem("email"),
            "table_name_param": curTab[0][1],
            "time_diff_hours": utcDifference,
            "param_offset": offset.current,
            "param_is_row_level": sessionStorage.getItem("rowFilter"),
            "param_where": sessionStorage.getItem("asRequiredDownloadQuery") ? sessionStorage.getItem("asRequiredDownloadQuery") : "",
        }
        if (selectedColumn.length > 0) {
            const cols = selectedColumn.filter((col) => col !== "Select All")
            jsonReq["param_column_name"] = cols.join("||")
        }
        const response = await fetch(restApiPath + "getAuditTrailPagination", {
            method: "POST",
            headers: {
                Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonReq),
        }, null, null);
        const res = await response.json();

        if (res && res.response) {
            const { data } = res;
            if (data) {
                const changes = data.split(CH1)
                const updates = changes[0].split(CH2)
                const insert = changes[1].split(CH2)
                const deleted = changes[2].split(CH2)

                let myData = [];
                let hasData = false;
                //get updated data
                if (updates.length > 1) {
                    const updatedData = JSON.parse(updates[1])
                    console.log("updatedData", updatedData)
                    // if (updatedData.length > 0) myData = [...myData, ...updatedData]
                    if (updatedData.length > 0) {
                        myData.push({ type: "update", data: updatedData })
                        hasData = true;
                    }
                }

                //get inserted data
                if (insert.length > 1) {
                    const insertedData = JSON.parse(insert[1])
                    console.log("insertedData", insertedData)
                    // if (insertedData.length > 0) myData = [...myData, ...insertedData]
                    if (insertedData.length > 0) {
                        myData.push({ type: "insert", data: insertedData })
                        hasData = true
                    }
                }

                //get deleted data
                if (deleted.length > 1) {
                    const deletedData = JSON.parse(deleted[1])
                    console.log("deletedData", deletedData)
                    // if (deletedData.length > 0) myData = [...myData, ...deletedData]
                    if (deletedData.length > 0) {
                        myData.push({ type: "delete", data: deletedData })
                        hasData = true
                    }
                }

                // setChangedData(myData)
                setHasMoreData(hasData);

                setTimeout(() => {
                    setIsLoaing(false);
                    if (hasData) setChangedData(prevState => ([...prevState, ...myData]))
                }, 1000);

            }
        } else {
            setIsLoaing(false);
            if (res.error === "invalid_token") {
                showNotificationMsg("Session Expired", "error")

                sessionStorage.clear();
                setTimeout(() => {
                    nevigate("/login");
                }, 3000);
            }
        }
    }
    const loadMoreData = () => {
        // console.log("load more")
        offset.current = offset.current + 1;
        getChanges();
    }
    useEffect(() => {

        offset.current = 0;
        setChangedData([])
        setSelectedColumn([])
        setTitle(activeTab)

    }, [activeTab]);

    useEffect(() => {
        // console.log("call get changes from title useeffect")
        offset.current = 0;
        setChangedData([])

        const curTab = tabList.filter((tab) => tab[0] === title)
        const table_name = curTab[0][1]
        const tmp = getColumnDefByTblName(table_name);
        if (tmp) {
            let cols = [{ headerName: "Select All", hide: false }];
            cols = [...cols, ...tmp];

            setColDef(cols);
        }
        if (selectedDate !== "Date Range") getChanges();
    }, [title, selectedDate]);
    useEffect(() => {
        console.log("resetAuditTrail", resetAuditTrail)
        if (resetAuditTrail) {
            offset.current = 0;
            setSelectedDate('Today');
            setChangedData([]);
            setSelectedColumn([])
            setTitle(activeTab);
            setTimeout(() => {
                // console.log("call get changes from resetAuditTrail")
                getChanges();
            }, 2000);
        }
    }, [resetAuditTrail])
    useEffect(() => {
        const todayDate = moment(new Date()).format("YYYY-MM-DD");
        setStartDate(todayDate)
        setEndDate(todayDate)
        function handleResize() { setWindowDimensions(getWindowDimensions()); }
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const renderMenu = () => (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleDateMenuClick}>By Date <ArrowRight sx={{ width: "20px", height: "20px" }} /></MenuItem>
            <Menu
                anchorEl={dateSubAnchorEl}
                open={Boolean(dateSubAnchorEl)}
                onClose={handleDateMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => handleDateMenuClose("Today")} selected={selectedDate === 'Today'}>Today</MenuItem>
                <MenuItem onClick={() => handleDateMenuClose('Yesterday')} selected={selectedDate === 'Yesterday'}>Yesterday</MenuItem>
                <MenuItem onClick={() => handleDateMenuClose('This Week')} selected={selectedDate === 'This Week'}>This Week</MenuItem>
                <MenuItem onClick={() => handleDateMenuClose('Last Week')} selected={selectedDate === 'Last Week'}>Last Week</MenuItem>
                <MenuItem onClick={() => handleDateMenuClose('Date Range')} selected={selectedDate === 'Date Range'}>Date Range</MenuItem>
            </Menu>
            <MenuItem onClick={handleColumnMenuClick}>Select Column <ArrowRight sx={{ width: "20px", height: "20px" }} /></MenuItem>
        </Menu>
    )
    const calculateTimeDiff = (changeDate) => {
        const now = moment(new Date()).format('DD-MMM-YYYY HH:mm:ss')
        const then = moment(changeDate).format('DD-MMM-YYYY HH:mm:ss')

        const ms = moment(now, "DD-MMM-YYYY HH:mm:ss").diff(moment(then, "DD-MMM-YYYY HH:mm:ss"));
        const diff = moment.duration(ms);
        const days = Math.abs(parseInt(diff.asDays()));
        const hours = Math.abs(parseInt(diff.asHours()));
        const min = Math.abs(parseInt(diff.asMinutes()));
        // console.log("days",days)
        if (days == 0 && hours === 0 && min <= 1) return "Just Now"
        else if (days === 0 && hours >= 1) return hours + " hr ago"
        else if (days === 0 && hours < 1) return min + " min ago"
        else if (days >= 1 && days <= 7) return days + " days ago"
        else return moment(changeDate).format('MMM-YYYY')
    }
    const checkAndChangeTab = () => {
        if (activeTab !== title) {
            changeTab(title)
        }
    }
    const applyChanges = () => {
        setOpenDateRange(false);
        setOpenColumnList(false);

        offset.current = 0;
        setChangedData([]);
        getChanges();
    }
    return (
        <MDBox p={1} bgColor="light" style={{ overflowY: "hidden" }} borderRadius="lg" shadow="lg" opacity={1}>
            <Grid container>
                <Grid item xs={11}>
                    <MDTypography variant="h6">Changes</MDTypography>
                </Grid>
                <Grid item xs={1}>
                    <Close sx={{ cursor: "pointer" }} onClick={closeShowChangesBtn} />
                </Grid>
            </Grid>
            <MDBox sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} mb={1}>
                <FilterAlt sx={{ cursor: "pointer" }} id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleMenuClick} />
                <span style={{ marginLeft: "3px", fontSize: "13px" }}> {selectedDate !== "Date Range" ? selectedDate : `${startDate} To ${endDate}`} </span>
            </MDBox>
            <div style={{ height: windowDimensions.height - 165, overflowY: "scroll" }}>
                <Grid container rowSpacing={1}>
                    {
                        changedData.length > 0 ? (
                            changedData.map((dt) => {
                                const operation = dt.type
                                const data = dt.data
                                const ele = data.map((chData) => {
                                    // const operation = chData.operation
                                    let type = "";

                                    const time = calculateTimeDiff(chData.change_date);
                                    if (operation === "update") {
                                        type = "Edited ";
                                    }
                                    if (operation === "insert") {
                                        type = "Inserted ";
                                    }
                                    if (operation === "delete") {
                                        type = "Deleted ";
                                    }
                                    const tt = type + chData.col
                                    const name = chData.user_name ? ProperCase(chData.user_name) : "Guest User"
                                    const curTab = tabList.filter((tab) => tab[0] === title)
                                    const table_name = curTab[0][1]

                                    //formate value according to its type
                                    let newVal = chData.new_value;
                                    let oldVal = chData.old_value;

                                    const ColDefination = getColumnDefByTblName(table_name);
                                    if (ColDefination) {
                                        const colDef = ColDefination.find((element) => {
                                            return element.headerName === chData.col;
                                        })

                                        if (colDef && colDef.type === 'date' && !data.includes("/")) {
                                            if (!isNaN(newVal)) newVal = ExcelDateToJsDate(newVal)
                                            if (!isNaN(oldVal)) oldVal = ExcelDateToJsDate(oldVal)
                                        }
                                        else if (colDef && colDef.type === 'price' && data && data !== "") {
                                            if (newVal !== "" && newVal) newVal = "$" + parseFloat(newVal).toFixed(2)
                                            if (oldVal !== "" && oldVal) oldVal = "$" + parseFloat(oldVal).toFixed(2)
                                        }
                                        else if (colDef && colDef.type === 'percentage' && data && data !== "") {
                                            if (newVal !== "" && newVal) newVal = Math.round(newVal * 100) + "%"
                                            if (oldVal !== "" && oldVal) oldVal = Math.round(oldVal * 100) + "%"
                                        } else if (colDef && colDef.type === 'decimal' && data && data !== "") {
                                            if (newVal !== "" && newVal) newVal = parseFloat(newVal).toFixed(2)
                                            if (oldVal !== "" && oldVal) oldVal = parseFloat(oldVal).toFixed(2)
                                        }
                                    }
                                    if (operation === "update") {
                                        return (
                                            <Grid item xs={12}>
                                                <AuditTrailCard
                                                    name={name}
                                                    title={tt}
                                                    newVal={newVal}
                                                    oldVal={oldVal}
                                                    time={time}
                                                    operation={operation}
                                                    data={chData}
                                                    table_name={table_name}
                                                    checkAndChangeTab={checkAndChangeTab}
                                                />
                                            </Grid>
                                        )
                                    } else if (operation === "delete" || operation === "insert") {
                                        const tt = type + "Row"
                                        return (
                                            <Grid item xs={12}>
                                                <AuditTrailCard
                                                    name={name}
                                                    title={tt}
                                                    newVal={"Row_Id " + chData["row_id"] + " " + type}
                                                    oldVal="" time={time}
                                                    operation={operation}
                                                    data={chData}
                                                    table_name={table_name}
                                                    checkAndChangeTab={checkAndChangeTab}
                                                />
                                            </Grid>
                                        )
                                    }
                                    return el1;
                                })
                                return ele;
                            })) : null
                    }
                </Grid>
                {
                    changedData.length === 0 && !isLoading ? (
                        <MDBox sx={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "50%" }}>
                            <span style={{ fontSize: "10px" }}>There are no changes for {selectedDate}</span>
                        </MDBox>
                    ) : null
                }
                {
                    isLoading ?
                        <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }} mt={2}>
                            <CircularProgress size="1rem" color="info" />
                        </MDBox>
                        : null
                }
                {
                    !isLoading && changedData.length !== 0 && hasMoreData ?
                        <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%" }} mt={2} onClick={loadMoreData}>
                            <span style={{ fontSize: "11px", color: "#5B9BD5", cursor: "pointer" }}>Show More Changes</span>
                        </MDBox>
                        : null
                }
                {
                    !hasMoreData && changedData.length !== 0 ? (
                        <MDBox sx={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "50%" }}>
                            <span style={{ fontSize: "10px" }}>There are no more changes for {selectedDate}</span>
                        </MDBox>
                    ) : null
                }
            </div>
            {
                renderMenu()
            }
            <Dialog
                open={openDateRange}
                onClose={() => setOpenDateRange(false)}
                aria-labelledby="classic-modal-slide-title-sync-data"
                aria-describedby="classic-modal-slide-description-sync-data"
            >
                <DialogTitle>Select Start and End Date</DialogTitle>
                <DialogContent>
                    <MDBox mt={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <MDInput type="date" defaultValue={startDate} onChange={(event) => setStartDate(event.target.value)} />
                        &nbsp;&nbsp;&nbsp; To &nbsp;&nbsp;&nbsp;
                        <MDInput type="date" defaultValue={endDate} onChange={(event) => setEndDate(event.target.value)}
                            InputProps={{ inputProps: { min: startDate.toString(), max: new Date().toString() } }} />
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={applyChanges}> Apply </MDButton>
                    <MDButton onClick={() => setOpenDateRange(false)}> Close </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openColumnList}
                onClose={() => setOpenColumnList(false)}
                aria-labelledby="classic-modal-slide-title-sync-data"
                aria-describedby="classic-modal-slide-description-sync-data"
            >
                <DialogTitle>Select Column</DialogTitle>
                <DialogContent>
                    <MDBox mt={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', height: "150px" }}>
                            {colDef.map((col) => {
                                if (!col.hide) {
                                    return (<ListItem
                                        key={col.headerName}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleToggle(col.headerName)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedColumn.includes(col.headerName)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': col.headerName }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={col.headerName} primary={col.headerName} />
                                        </ListItemButton>
                                    </ListItem>)
                                }
                            })}
                        </List>
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={applyChanges}> Apply </MDButton>
                    <MDButton onClick={() => setOpenColumnList(false)}> Close </MDButton>
                </DialogActions>
            </Dialog>
        </MDBox>
    )
}
AuditTrailList.prototype = {
    closeShowChangesBtn: PropTypes.func.isRequired,
    tabList: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeTab: PropTypes.string.isRequired,
    showNotificationMsg: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    resetAuditTrail: PropTypes.bool.isRequired,
}
export default AuditTrailList;