import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { LinearProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { applicationTitle, restApiPath, customerBrandLogoPath } from "utils/app.props";
import { setMiniSidenav, setLayout, useMaterialUIController } from "context";
import bgImage from "assets/images/register.jpg";
import { useDispatch } from "react-redux";

// import { useNavigate } from "react-router-dom";

function Custom() {
  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailHelperText, setEmailHelterText] = useState("");
  const [showError, setShowError] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const [disableLogin, setDisaleLogin] = useState(false);
  const nevigate = useNavigate();
  const redDispatch = useDispatch();

  useEffect(() => {

    setLayout(dispatch, "login");
    // setMiniSidenav(dispatch, false);
    window.history.pushState(null, "Login", window.location.href);
    window.addEventListener("popstate", () => {
      window.history.pushState(null, document.title, window.location.href);
    });

  }, [pathname]);

  const verifyEmail = (value) => {
    const emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const onSetResultForOTP = (result) => {
    const response = [result];
    // console.log("IN -> onSetResultForOTP");
    // console.log(response);
    // console.log("response[0].response")
    // console.log(response[0].response);
    if (response[0].response === false) {
      setNotificationMessage(response.message);
      setShowNotification(true);
    } else if (response[0].response === true) {
      // console.log("nevigate -> otp")
      nevigate("/otp");
    }
    setLoading(false);
  };

  const onSetResult = (result) => {
    const response = [result];
    // console.log("IN -> onSetResult -> " + response);
    // console.log(response);
    // console.log(response[0].error);
    if (response[0].error === "unauthorized") {
      setNotificationMessage("Invalid user name or password");
      setShowNotification(true);
      setDisaleLogin(false);
      setLoading(false);
    } else if (response.error) {
      // console.log("IN -> onSetResult -> IF");
      if (response.error.loginresponse === "invalid_grant") {
        if (response.error_description === "Bad credentials") {
          setNotificationMessage("Invalid user name or password");
        } else {
          setNotificationMessage(response.error_description);
        }
        setShowNotification(true);
      } else if (response.error === "unauthorized") {
        setNotificationMessage("Invalid user name or password");
        setShowNotification(true);
      } else if (response.error === "access_denied") {
        setNotificationMessage(response.error_description);
        setShowNotification(true);
      }
      setDisaleLogin(false);
    } else {
      // console.log("IN -> onSetResult -> ELSE");
      try {
        sessionStorage.setItem("access_token", result.access_token);
        sessionStorage.setItem("email", result.User.userName);
        sessionStorage.setItem("firstName", result.User.firstname);
        sessionStorage.setItem("lastName", result.User.lastname);
        sessionStorage.setItem("userRole", result.User.userRole);
        sessionStorage.setItem("user_type", result.User.user_type);
        sessionStorage.setItem("userId", result.User.userId);
        sessionStorage.setItem("authOTP", result.User.authOTP);
        sessionStorage.setItem("otpmode", "login");
        redDispatch({ type: "SET_ALL_CLEAR", data: [] });
        if (result.User.authOTP) {
          fetch(`${restApiPath}setOTP`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: result.User.userName,
              processName: "login",
              filter: applicationTitle,
            }),
          })
            .then((response1) => response1.json())
            .then((result1) => onSetResultForOTP(result1));
        } else {
          fetch(`${restApiPath}AddUserActivity`, {
            method: "POST",
            headers: {
              Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: sessionStorage.getItem("email"),
              processName: "Login Where User OTP Disabled",
            }),
          });
          sessionStorage.setItem("verify", "No");
        }
      } catch (exception) {
        setLoading(false);
        setDisaleLogin(false);
      }
    }
  };

  const handleLogin = async () => {
    if (email.length === 0) {
      setEmailHelterText("Email required");
      setShowError(true);
      setLoading(false);
      return;
    }
    if (showError) {
      return;
    }
    setLoading(true);
    setDisaleLogin(true);
    try {
      fetch(
        `${restApiPath}oauth/token?grant_type=password&username=${email}&password=123&X-client=Web`,
        {
          method: "POST",
          headers: {
            Authorization: "Basic Y2ktc29odW0tY2xpZW50OmNpLXNvaHVtLXNlY3JldA==",
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((response) => response.json())
        .then((result) => onSetResult(result));
    } catch (exception) {
      setLoading(false);
      setDisaleLogin(false);
      setNotificationMessage("Opps! Something went wrong.");
      setShowNotification(true);
    }
  };

  const onEmailChange = (evt) => {
    const txt = (evt.target.value).toLowerCase();
    if (verifyEmail(txt)) {
      setEmailHelterText("");
      setShowError(false);
    } else {
      setEmailHelterText("Please include @ and . in email address");
      setShowError(true);
    }
    setEmail(txt);
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        handleLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email]);




  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography mt={1}>
            <img src={customerBrandLogoPath} height="50px" alt={applicationTitle} />
            <MDTypography display="block" variant="h4" fontWeight="medium" color="white" my={1}>
              {applicationTitle}
            </MDTypography>
          </MDTypography>
          {/* <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography> */}
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                inputRef={input => input && input.focus()}
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                placeholder="name@domain.com"
                InputLabelProps={{ shrink: true }}
                error={showError}
                success={!showError}
                helperText={emailHelperText}
                onChange={onEmailChange}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleLogin}
                disabled={disableLogin}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox sx={{ width: "100px", position: "absolute", left: "30%" }}>
              {loading ? <LinearProgress color="info" variant="indeterminate" /> : null}
            </MDBox>
          </MDBox>
        </MDBox>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Login"
          content={notificationMessage}
          dateTime=""
          open={showNotification}
          onClose={() => setShowNotification(false)}
          close={() => setShowNotification(false)}
        />
      </Card>
    </CoverLayout>
  );
}

export default Custom;
