import { store } from "store";
export const functionalityAccess = (btnId, req_tbl) => {
	let functionality_access = getFromMetaData("sheet_name", req_tbl, 'functionality_access').split("|");	
	return functionality_access.includes(btnId);
}
export const getDataFromMetaData = (table_name, column_name) => {
	let return_val = "";
	const redux = store.getState();
	redux.appMetaData.forEach((tm) => {
		if (tm.table_name === table_name) {
			return_val = tm[column_name];
		}
	});
	return return_val;
};
export const getFromMetaData = (colName, colValue, retColName) => {
	let return_val = "";
	const redux = store.getState();
	redux.appMetaData.forEach((tm) => {
		if (tm[colName] === colValue) {
			return_val = tm[retColName];
		}
	});
	return return_val;
};