import React, { useCallback, useMemo } from 'react';
import { Box, Checkbox, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

const CheckBoxList = ({ items, label, onSelectionChange, selectedValues, boxStyle }) => {
    const isItemSelected = useCallback((item) => selectedValues.includes(item), [selectedValues]);
    const selectAll = useMemo(() => selectedValues.length === items.length, [selectedValues, items]);

    const handleToggle = useCallback((item) => {
        const newChecked = isItemSelected(item)
            ? selectedValues.filter(i => i !== item)
            : [...selectedValues, item];
        onSelectionChange(newChecked);
    }, [isItemSelected, selectedValues, onSelectionChange]);

    const handleSelectAll = useCallback((event) => {
        onSelectionChange(event.target.checked ? items : []);
    }, [items, onSelectionChange]);

    const defaultBoxStyle = {
        border: '1px solid #ddd',
        borderRadius: '4px',
        boxShadow: 1,
        padding: 1,
        minWidth: '300px',
        backgroundColor: '#fff',
        minHeight: '150px',
        maxHeight: '150px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    };

    return (
        <Box sx={boxStyle || defaultBoxStyle}>
            <Typography
                component="div"
                sx={{
                    fontSize: '12px',
                    fontFamily: "Inter",
                    backgroundColor: "rgba(0, 0, 0, .07)",
                    color: "white",
                    fontWeight: "bold",
                    padding: '4px',
                    marginBottom: '4px'
                }}
            >
                {label}
            </Typography>

            <ListItemButton
                onClick={handleSelectAll}
                sx={{
                    textTransform: "none",
                    padding: '0',
                    height: 25,
                }}
            >
                <ListItemIcon sx={{
                    minWidth: 'auto',
                    '& .MuiCheckbox-root': {
                        paddingLeft: 1.4,
                        transform: 'scale(0.8)'
                    },
                }}>
                    <Checkbox
                        edge="start"
                        checked={selectAll}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText
                    primary="Select All"
                    primaryTypographyProps={{
                        sx: {
                            fontSize: '12px',
                            fontFamily: "Inter",
                            fontWeight: "bold",
                            padding: "0"
                        },
                    }}
                />
            </ListItemButton>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(95px, 1fr))',
                    gap: 1,
                    width: "100%",
                    overflowX: 'auto',
                    overflowY: 'auto',
                    flexGrow: 1,
                    padding: 0
                }}
            >
                {items.map((item) => (
                    <ListItemButton
                        key={item}
                        onClick={() => handleToggle(item)}
                        sx={{
                            textTransform: "none",
                            padding: '3px',
                            height: 27,
                            backgroundColor: isItemSelected(item) ? '#e0f7fa' : 'transparent',
                            '&:hover': {
                                backgroundColor: isItemSelected(item) ? '#b2ebf2' : '#f5f5f5'
                            },
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ListItemIcon sx={{
                            minWidth: 'auto',
                            '& .MuiCheckbox-root': {
                                paddingLeft: 1,
                                transform: 'scale(0.7)'
                            },
                        }}>
                            <Checkbox
                                edge="start"
                                checked={isItemSelected(item)}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                                sx: {
                                    fontSize: '10px',
                                    fontFamily: "Inter"
                                },
                            }}
                        />
                    </ListItemButton>
                ))}
            </Box>
        </Box>
    );
};

export default React.memo(CheckBoxList);