


import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_season_inquiry";
const rng_name = "Season_Inquiry_Rng";


const SeasonInquiryTab = forwardRef(({
    setCurrentGridApi,
    settimeLineDataFun, clearFilterBtnCall }, ref) => {

    const dispatch = useDispatch(); //to update redux state variable
    const SeasonInquiryData = useSelector(state => state.SeasonInquiryData);
    const SSNData = useSelector((s) => s.SeasonInquiryDataSSN);

    const SeasonInquiryHighlistCell = useSelector(state => state.SeasonInquiryHighlistCell);
    const tmpColDef = useSelector(state => state.SeasonInquiryColDef ? state.SeasonInquiryColDef : []);

    const SeasonInquirySorting = useSelector(state => state.SeasonInquirySorting ? state.SeasonInquirySorting : []);
    const SeasonInquiryFilters = useSelector(state => state.SeasonInquiryFilters ? state.SeasonInquiryFilters : {});

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);

    const [isGridReady, setIsGridReady] = useState(false);
    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

    const setResetFilter = (setReset) => {
        let sorting = SeasonInquirySorting
        let filtering = SeasonInquiryFilters

        if (setReset === "reset") {
            sorting = null
            filtering = null
        }
        gridApi.current.applyColumnState({
            state: sorting
        })
        gridApi.current.setFilterModel(filtering);

    }
    //Required for all tabs (For before send data functionalities)
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!SeasonInquiryData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                return (res)
            }
            cellValueChanged.current = [];
            removeHeihlight();
            return ({ status: true })
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, SeasonInquiryColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells

                dispatch({ type: "SEASON_INQUIRY_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");

            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = []
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
    };
    useEffect(() => {
        if (isGridReady) {
            if (SeasonInquirySorting !== null && SeasonInquirySorting !== undefined) {
                gridApi.current.applyColumnState({
                    state: SeasonInquirySorting
                })
            }
            if (SeasonInquiryFilters !== null && SeasonInquiryFilters !== undefined) {
                gridApi.current.setFilterModel(SeasonInquiryFilters);
            }
        }
    }, [isGridReady])

    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        setIsGridReady(true)
    }
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    const onSortChanged = () => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_SEASON_INQUIRY_SORTING", data: savedSortState });
    };
    const onFilterChanged = () => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "SET_SEASON_INQUIRY_FILTERS", data: savedFilterModel });
    };
    return (
        <CustomAgGrid
            setCurrentGridApi={onGridReady}
            filters={SeasonInquiryFilters}
            tab_name="Season_Inquiry"
            tbl_name={tbl_name}
            columnDefs={tmpColDef}
            rowData={SeasonInquiryData}
            SSNData={SSNData}
            tblHighlightCells={SeasonInquiryHighlistCell}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            autoChangeState={autoChangeState}
            resteAutoChangeState={resteAutoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            setCellValueChanged={setCellValueChanged}
        />
    )
})

SeasonInquiryTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,

};

export default SeasonInquiryTab;