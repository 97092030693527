import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_buy_sheet_master_sizing_percentage";
const rng_name = "Buy_Sheet_Master_Sizing_Rng";


const BuySheetMasterSizingPercentageTab = forwardRef(({
    setCurrentGridApi,
    settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {

    const dispatch = useDispatch(); //to update redux state variable
    // let allCol = useSelector(s => s.testColLst[0][tbl_name]);

    const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
    const BuySheetMasterSizingPercentageData = useSelector(state => state.BuySheetMasterSizingPercentageData);
    const SSNData = useSelector((s) => s.BuySheetMasterSizingPercentageDataSSN);
    const BuySheetMasterSizingPercentageHighLightCell = useSelector(state => state.BuySheetMasterSizingPercentageHighlistCell);
    const tmpColDef = useSelector(state => state.BuySheetMasterSizingPercentageColDef ? state.BuySheetMasterSizingPercentageColDef : []);

    const BuySheetMasterSizingPercentageSorting = useSelector(state => state.BuySheetMasterSizingPercentageSorting ? state.BuySheetMasterSizingPercentageSorting : []);
    const BuySheetMasterSizingPercentageFilters = useSelector(state => state.BuySheetMasterSizingPercentageFilters ? state.BuySheetMasterSizingPercentageFilters : {});

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);
    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

    const [isGridReady, setIsGridReady] = useState(false);

    const setResetFilter = (setReset) => {
        let sorting = BuySheetMasterSizingPercentageSorting
        let filtering = BuySheetMasterSizingPercentageFilters

        if (setReset === "reset") {
            sorting = null
            filtering = null
        }
        gridApi.current.applyColumnState({
            state: sorting
        })

        console.log("filtering", filtering)
        gridApi.current.setFilterModel(filtering);

    }
    //Required for all tabs (For before send data functionalities)
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!BuySheetMasterSizingPercentageData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                return (res)
            }
            removeHeihlight();
            cellValueChanged.current = [];
            return ({ status: true })
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells
                dispatch({ type: "BUY_SHT_SIZE_PER_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");

            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = []
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
    };

    useEffect(() => {
        if (isGridReady) {
            if (BuySheetMasterSizingPercentageSorting !== null && BuySheetMasterSizingPercentageSorting !== undefined) {
                gridApi.current.applyColumnState({
                    state: BuySheetMasterSizingPercentageSorting
                })
            }
            if (BuySheetMasterSizingPercentageFilters !== null && BuySheetMasterSizingPercentageFilters !== undefined) {
                gridApi.current.setFilterModel(BuySheetMasterSizingPercentageFilters);
            }
        }
    }, [BuySheetMasterSizingPercentageHighLightCell, isGridReady])

    const calculateTotal = (node) => {
        if (!node.data) return;
        const { data } = node;
        const columnsToCalculate = ["2T", "3T", "4T", "5", "6", "8", "10", "OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XS/S", "S/M", "M/L", "L/XL", "MT", "LT", "XLT", "XXLT", "00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42", "30T", "32T", "34T", "36T", "38T", "40T", "28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L", "1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT", "40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L", "36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"]

        let total = 0;

        columnsToCalculate.map((col) => {
            if (data[col] && data[col] !== "" && !isNaN(data[col])) {
                total = total + parseFloat(data[col])
            }
        })
        return total;
    }
    const fxcalc = (colnm) => {
        let retval = false;
        if (FormulaCalcColLst.includes(colnm)) retval = true;
        return retval;
    };
    const calculateAllFormulas = () => {
        if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
            gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
            gridApi.current.refreshCells({ force: true });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };
    const calculateReactFormula = (event) => {
        fxcalc("Total") ? event.data["Total"] = calculateTotal(event) : null;
    }
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        calculateAllFormulas();
        setIsGridReady(true)
    }
    const onSortChanged = (event) => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_BUY_SHT_SIZE_PER_SORTING", data: savedSortState });
    }
    const onFilterChanged = (event) => {
        const savedFilterModel = gridApi.current.getFilterModel();
        console.log("savedFilterModel", savedFilterModel)
        dispatch({ type: "SET_BUY_SHT_SIZE_PER_FILTERS", data: savedFilterModel });
    }
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    return (
        <CustomAgGrid
            setCurrentGridApi={onGridReady}
            filters={BuySheetMasterSizingPercentageFilters}
            setActionItemDataFun={setActionItemDataFun}
            tab_name="Buy_Sheet_Master_Sizing_%"
            tbl_name={tbl_name}
            columnDefs={tmpColDef}
            rowData={BuySheetMasterSizingPercentageData}
            SSNData={SSNData}
            tblHighlightCells={BuySheetMasterSizingPercentageHighLightCell}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            autoChangeState={autoChangeState}
            resteAutoChangeState={resteAutoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            setCellValueChanged={setCellValueChanged}
            calculateFormula={calculateReactFormula}
            isGrouppedHeader = {true}
        />
    )
})

BuySheetMasterSizingPercentageTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,

};

export default BuySheetMasterSizingPercentageTab;