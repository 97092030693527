function xlConcat(data, columnsList, delimiter) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  if (columnsList === null || columnsList === undefined || typeof columnsList === "undefined") {
    return retVal;
  }
  if (
    data !== null &&
    data !== undefined &&
    Object.keys(data).length > 0 &&
    columnsList.length > 0
  ) {
    const tmp = new Array();
    for (let i = 0; i < columnsList.length; i++) {
      let el = data[columnsList[i]];
      tmp.push(el);
      el = null;
    }
    if (tmp !== null && tmp !== undefined && tmp.length > 0) {
      retVal = tmp.join(delimiter);
    }
  }
  if (retVal === null || retVal === undefined || typeof retVal === "undefined") {
    retVal = "";
  }
  return retVal;
}

function xlEqualToThenReturn1Or0(data, colName, compareWithVal) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  if (colName === null || colName === undefined || typeof colName === "undefined") {
    return retVal;
  }
  if (
    compareWithVal === null ||
    compareWithVal === undefined ||
    typeof compareWithVal === "undefined"
  ) {
    return retVal;
  }
  if (data !== null && data !== undefined && Object.keys(data).length > 0) {
    if (data[colName].trim().toLowerCase() === compareWithVal.toLowerCase()) {
      retVal = "1";
    } else {
      retVal = "0";
    }
  }
  if (retVal === null || retVal === undefined || typeof retVal === "undefined") {
    retVal = "";
  }
  return retVal;
}

function xlEqualToThenReturnColValsCount(data, colName, compareWithVal, retColName) {
  let retVal = new Array();
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  if (colName === null || colName === undefined || typeof colName === "undefined") {
    return retVal;
  }
  if (
    compareWithVal === null ||
    compareWithVal === undefined ||
    typeof compareWithVal === "undefined"
  ) {
    return retVal;
  }
  if (data !== null && data !== undefined && data.length > 0) {
    let el = null;
    const tmp = new Array();
    for (let i = 0; i < data.length; i++) {
      if (data[i][colName].trim().toLowerCase() === compareWithVal.toLowerCase()) {
        el = data[i][retColName];
        tmp.push(el);
        el = null;
      }
    }
    if (tmp !== null && tmp !== undefined && tmp.length > 0) {
      retVal = tmp;
    }
  }
  if (retVal === null || retVal === undefined || typeof retVal === "undefined") {
    retVal = new Array();
  }
  return retVal.length;
}

function xlAddNumbers(data, columnsList) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  if (columnsList === null || columnsList === undefined || typeof columnsList === "undefined") {
    return retVal;
  }
  let sumVal = Number();
  for (let i = 0; i < columnsList.length; i++) {
    let el = data[columnsList[i]];
    sumVal += Number(el);
    el = null;
  }
  retVal = sumVal;
  if (retVal === null || retVal === undefined || typeof retVal === "undefined") {
    retVal = "";
  }
  return retVal;
}

function xlSubtractNumbers(data, firstCol, secondCol) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  const firstVal = data[firstCol];
  const secondVal = data[secondCol];
  if (firstVal === null || firstVal === undefined || typeof firstVal === "undefined") {
    return retVal;
  }
  if (secondVal === null || secondVal === undefined || typeof secondVal === "undefined") {
    return retVal;
  }
  retVal = Number(firstVal) - Number(secondVal);
  if (retVal === null || retVal === undefined || typeof retVal === "undefined") {
    retVal = "";
  }
  return retVal;
}

function xlMultipleNumbers(data, columnsList) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  if (columnsList === null || columnsList === undefined || typeof columnsList === "undefined") {
    return retVal;
  }
  let mplVal = Number(1);
  for (let i = 0; i < columnsList.length; i++) {
    let el = data[columnsList[i]];
    mplVal *= Number(el);
    el = null;
  }
  retVal = mplVal;
  if (
    retVal === null ||
    retVal === undefined ||
    typeof retVal === "undefined" ||
    Number.isNaN(retVal) === true
  ) {
    retVal = "";
  }
  return retVal.toString();
}

function xlDivideNumbers(numeratorVal, denominatorVal) {
  let retVal = "";
  if (numeratorVal === null || numeratorVal === undefined || typeof numeratorVal === "undefined") {
    return retVal;
  }
  if (
    denominatorVal === null ||
    denominatorVal === undefined ||
    typeof denominatorVal === "undefined"
  ) {
    return retVal;
  }
  if (denominatorVal === 0 || denominatorVal === "0") {
    return retVal;
  }
  if (numeratorVal === 0 || numeratorVal === "0") {
    retVal = "0";
    return retVal;
  }
  retVal = Number(numeratorVal) / Number(denominatorVal);
  if (
    retVal === null ||
    retVal === undefined ||
    typeof retVal === "undefined" ||
    Number.isNaN(retVal) === true
  ) {
    retVal = "";
  }
  return retVal.toString();
}

function xlAssignOneColValueToOtherCol(data, sourceCol) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  const sourceColVal = data[sourceCol];
  if (sourceColVal === null || sourceColVal === undefined || typeof sourceColVal === "undefined") {
    return retVal;
  }
  retVal = sourceColVal;
  return retVal.toString();
}

function xlEqualToThenReturnColVals(data, colName, compareWithVal, retColName) {
  let retVal = new Array();
  if (data === null || data === undefined || typeof data === "undefined") {
    return retVal;
  }
  if (colName === null || colName === undefined || typeof colName === "undefined") {
    return retVal;
  }
  if (
    compareWithVal === null ||
    compareWithVal === undefined ||
    typeof compareWithVal === "undefined"
  ) {
    return retVal;
  }
  if (data !== null && data !== undefined && data.length > 0) {
    let el = null;
    const tmp = new Array();
    for (let i = 0; i < data.length; i++) {
      if (data[i][colName].trim().toLowerCase() === compareWithVal.toLowerCase()) {
        el = data[i][retColName];
        tmp.push(el);
        el = null;
      }
    }
    if (tmp !== null && tmp !== undefined && tmp.length > 0) {
      retVal = tmp;
    }
  }
  if (retVal === null || retVal === undefined || typeof retVal === "undefined") {
    retVal = new Array();
  }
  return retVal;
}

function xlEqualToThenReturnColSum(data, colName, arrOfVals, sumColNm) {
  let retVal = "";
  if (data === null || data === undefined || typeof data === "undefined" || data.length === 0) {
    return retVal;
  }
  if (colName === null || colName === undefined || typeof colName === "undefined") {
    return retVal;
  }
  if (
    arrOfVals === null ||
    arrOfVals === undefined ||
    typeof arrOfVals === "undefined" ||
    arrOfVals.length === 0
  ) {
    return retVal;
  }
  if (sumColNm === null || sumColNm === undefined || typeof sumColNm === "undefined") {
    return retVal;
  }
  if (data !== null && data !== undefined && data.length > 0) {
    let el = null;
    const tmp = new Array();
    for (let i = 0; i < data.length; i++) {
      let vl = null;
      for (let j = 0; j < arrOfVals.length; j++) {
        vl = arrOfVals[j];
        if (data[i][colName].trim().toLowerCase() === vl.trim().toLowerCase()) {
          el = Number(data[i][sumColNm]);
          tmp.push(el);
          el = null;
        }
        vl = null;
      }
    }
    if (tmp !== null && tmp !== undefined && tmp.length > 0) {
      let sumVal = Number();
      sumVal = tmp.reduce((a, b) => a + b, 0);
      retVal = sumVal;
    }
  }
  if (
    retVal === null ||
    retVal === undefined ||
    typeof retVal === "undefined" ||
    retVal === "" ||
    parseInt(retVal, 10) === 0 ||
    retVal === "0"
  ) {
    retVal = "";
  }
  return retVal.toString();
}

function xlXlookup(lookupValue, trgtData, colsName, retColName) {
  let retVal = "";
  if (
    lookupValue === null ||
    lookupValue === undefined ||
    typeof lookupValue === "undefined" ||
    lookupValue.trim() === ""
  ) {
    return retVal;
  }
  if (
    colsName === null ||
    colsName === undefined ||
    typeof colsName === "undefined" ||
    colsName.trim() === ""
  ) {
    return retVal;
  }
  if (
    retColName === null ||
    retColName === undefined ||
    typeof retColName === "undefined" ||
    retColName.trim() === ""
  ) {
    return retVal;
  }
  if (
    trgtData === null ||
    trgtData === undefined ||
    typeof trgtData === "undefined" ||
    trgtData.length === 0
  ) {
    return retVal;
  }
  let arrCols = new Array();
  arrCols = colsName.split("|");
  let el = null;
  let fl = null;
  let tmp = new Array();
  for (let i = 0; i < trgtData.length; i++) {
    for (let j = 0; j < arrCols.length; j++) {
      el = trgtData[i][arrCols[j]];
      if (el === null || el === undefined) {
        tmp.push("");
      } else {
        tmp.push(el);
      }
      el = null;
    }
    fl = tmp.join("");
    if (fl === null || fl === undefined) {
      fl = "";
    }
    if (fl.trim().toLowerCase() === lookupValue.trim().toLowerCase()) {
      retVal = trgtData[i][retColName];
    }
    fl = null;
    tmp = new Array();
  }
  return retVal;
}

function xlAddDaysToDate(data, dateColNm, daysColNm) {
  let retVal;
  if (data === null || data === undefined || typeof data === "undefined" || data.length === 0) {
    return retVal;
  }
  if (
    dateColNm === null ||
    dateColNm === undefined ||
    typeof dateColNm === "undefined" ||
    dateColNm.trim() === ""
  ) {
    return retVal;
  }
  if (
    daysColNm === null ||
    daysColNm === undefined ||
    typeof daysColNm === "undefined" ||
    daysColNm.trim() === ""
  ) {
    return retVal;
  }
  const intDays = parseInt(data[daysColNm], 10);
  const daysOfDate = parseInt(
    Math.round((new Date(data[dateColNm]) - new Date(1899, 11, 30)) / 8.64e7),
    10
  );
  if (
    intDays === null ||
    intDays === undefined ||
    typeof intDays === "undefined" ||
    daysOfDate === null ||
    daysOfDate === undefined ||
    typeof daysOfDate === "undefined"
  ) {
    retVal = null;
  } else {
    retVal = daysOfDate + intDays;
  }
  if (Number.isNaN(retVal) === true) {
    retVal = null;
  }
  return retVal;
}

function xlSubtractDates(data, firstCol, secondCol) {
  let retVal;
  if (data === null || data === undefined || typeof data === "undefined" || data.length === 0) {
    return retVal;
  }
  if (
    firstCol === null ||
    firstCol === undefined ||
    typeof firstCol === "undefined" ||
    firstCol.trim() === ""
  ) {
    return retVal;
  }
  if (
    secondCol === null ||
    secondCol === undefined ||
    typeof secondCol === "undefined" ||
    secondCol.trim() === ""
  ) {
    return retVal;
  }
  return retVal;
}

// CHAT GPT
// const xlMround = (value, multiple) => {
//   if (multiple === 0) return 0;

//   const absMultiple = Math.abs(multiple);
//   const remainder = value % absMultiple;

//   if (Math.abs(remainder) < absMultiple / 2) {
//     // Round towards the lower multiple
//     var result = value - remainder;
//   } else {
//     // Round towards the higher multiple
//     result = value + absMultiple - remainder;
//   }

//   // Ensure result is non-negative
//   return result < 0 ? 0 : result;
// };
function xlMround(number_val, multiple_val){
  let retVal;
  let rslt1, rslt1_str, rslt2, final_rslt;

  if (number_val === null || number_val === undefined || typeof number_val === "undefined" || number_val.toString() === "NaN" || number_val === "") {
    return retVal;
  } 
  
  rslt1 = (number_val/multiple_val);
  rslt1_str = rslt1.toString().split('.')[1];
 
  if (rslt1_str === null || rslt1_str === undefined || typeof rslt1_str === "undefined" || rslt1_str.toString() === "NaN" || rslt1_str === "") {
    final_rslt = (multiple_val * rslt1);
  }
  else{
    if (number_val > 0 ){
      if (parseInt(rslt1_str[0]) >= 5) {
        rslt2 = Math.ceil(rslt1)
      }
      else { rslt2 = Math.floor(rslt1) }
    }
    else{
      if (parseInt(rslt1_str[0]) >= 5) {
        rslt2 = Math.floor(rslt1)
      }
      else { rslt2 = Math.ceil(rslt1) }          
    }    

    final_rslt = (multiple_val * rslt2);
  }

  return final_rslt < 0 ? 0 : final_rslt;
  
}
// OLD ROUND - 27-07-2024
// function xlRound(temp_number_val, num_digits){
//   // let num_digits = 6;
//   let final_rslt;

//   if (temp_number_val === null || temp_number_val === undefined || temp_number_val === "" || temp_number_val.toString() === "NaN") {
//     return temp_number_val;
//   }

//   let number_val = parseFloat(temp_number_val)
//   if (number_val !== Math.floor(number_val))
//   {
//       let numberPart = number_val.toString().split('.')[0]
//       let decimalPart = number_val.toString().split('.')[1]
//       // console.log("num_digits ->",num_digits);
//       // console.log(decimalPart.length);
//       // console.log(parseInt(decimalPart) + 1);
//       if (num_digits === 0){
//         if (number_val > 0 ){
//           if (parseInt(decimalPart[0]) >= 5){
//             final_rslt = Math.ceil(number_val)
//           }
//           else { final_rslt = Math.floor(number_val) }
//         }
//         else{
//           if (parseInt(decimalPart[0]) >= 5){
//             final_rslt = Math.floor(number_val)
//           }
//           else { final_rslt = Math.ceil(number_val) }          
//         }
//       }
//       else {
//         if (decimalPart?.length > num_digits){
//           // console.log(parseInt(decimalPart[num_digits]));
//           if (parseInt(decimalPart[num_digits]) >= 5){
//             // console.log(parseInt(decimalPart[num_digits-1]) + 1);
//             // let replaceChar = (parseInt(decimalPart[num_digits-1]) + 1).toString()
//             let replaceChar = (parseInt(decimalPart) + 1).toString()
//             replaceChar = replaceChar.padStart(decimalPart.length,"0")
//             let firstPart = decimalPart.slice(0, num_digits-1);
//             let lastPart = decimalPart.slice((num_digits-1) + 1);
//             // let newString = numberPart + "." + firstPart + replaceChar + lastPart;
//             // let newString = numberPart + "." + firstPart + replaceChar;
//             let newString = numberPart + "." + replaceChar;
    
//             // console.log("newString ->",numberPart, firstPart, replaceChar, lastPart)
//             // console.log("newString ->",parseFloat(newString))
//             // console.log("newString.toFixed(num_digits) ->",parseFloat(newString).toFixed(num_digits))
//             final_rslt = parseFloat(newString).toFixed(num_digits)
//           }
//           else
//           {
//             // console.log("else ->",parseFloat(number_val).toFixed(num_digits))
//             final_rslt = parseFloat(number_val).toFixed(num_digits)
//           }
//         }
//         else
//         {
//           final_rslt = number_val
//         }
//       }
//   }
//   else
//   {
//     final_rslt = number_val
//   }  
//   // console.log("nparseFloat(final_rslt) ->",parseFloat(final_rslt))
//   return parseFloat(final_rslt);

// }
function xlRound(temp_number_val, num_digits) {
  let final_rslt;
  if (temp_number_val === null || temp_number_val === undefined || temp_number_val === "" || temp_number_val.toString() === "NaN") {
    return temp_number_val;
  }
  let number_val = parseFloat(temp_number_val)
  if (number_val !== Math.floor(number_val)) {
    let decimalPart = number_val.toString().split('.')[1]
    if (num_digits === 0) {
      if (number_val > 0) {
        if (parseInt(decimalPart[0]) >= 5) {
          final_rslt = Math.ceil(number_val)
        }
        else { final_rslt = Math.floor(number_val) }
      }
      else {
        if (parseInt(decimalPart[0]) >= 5) {
          final_rslt = Math.floor(number_val)
        }
        else { final_rslt = Math.ceil(number_val) }
      }
    }
    else {
      if (decimalPart?.length > num_digits) final_rslt = parseFloat(number_val).toFixed(num_digits);
      else final_rslt = number_val;
    }
  } else final_rslt = number_val;
  return parseFloat(final_rslt);
}

export {
  xlConcat,
  xlEqualToThenReturn1Or0,
  xlAddNumbers,
  xlSubtractNumbers,
  xlMultipleNumbers,
  xlDivideNumbers,
  xlAssignOneColValueToOtherCol,
  xlEqualToThenReturnColVals,
  xlEqualToThenReturnColSum,
  xlXlookup,
  xlAddDaysToDate,
  xlSubtractDates,
  xlEqualToThenReturnColValsCount,
  xlMround,
  xlRound
};
