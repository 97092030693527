import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Paper, Typography } from '@mui/material';
import { AgCharts } from 'ag-charts-react';
import { toastMessage } from 'layouts/linelist/XL_Utils';
import { restApiPath } from 'utils/app.props';

const SummarizedProductionData = (props) => {
  const dispatch = useDispatch();
  const { row_ids } = props;

  const [chartData, setChartData] = useState({
    create_ll_trend_chart: null,
    create_ll_trend_chart_dl_data: null,
    create_ll_product_counts_by_season_chart: null,
    create_ll_product_counts_by_season_chart_dl_data: null,
    create_ll_product_counts_by_dept_chart: null,
    create_ll_product_counts_by_department_chart_dl_data: null,
    create_ll_units_by_season_chart: null,
    create_ll_units_by_season_chartDlDt: null,
    create_ll_units_by_department_chart: null,
    create_ll_units_by_department_chart_dl_data: null
  });

  const getChartdata = async (functionName, paramBody = '') => {
    const url = `${restApiPath}callPGFunction`;
    const postBody = {
      "param_function": functionName,
      "param_body": paramBody
    };
    const requestbody = {
      method: "POST",
      headers: {
        Authorization: `bearer ${sessionStorage.getItem("access_token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postBody),
    };

    try {
      const response = await fetch(url, requestbody);
      const json = await response.json();

      if (json.error === "invalid_token") {
        toastMessage("Session Expired", "error");
        sessionStorage.clear();
        setTimeout(() => {
          nevigate("/login");
        }, 5000);
        return null;
      }

      if (json.response) {
        return JSON.parse(json.data);
      } else {
        toastMessage("There is an error while getting data.", "warning");
        return null;
      }
    } catch (exception) {
      toastMessage("An exception occurred while fetching data.", "error");
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const jsonReq = {
        "param_user_id": sessionStorage.getItem("email"),
        "param_query": sessionStorage.getItem("asRequiredDownloadQuery") || "",
        "filtered_row_ids": row_ids
      };
      dispatch({ type: "SET_PLEASE_WAIT", data: true });
      const [
        create_ll_trend_chart,
        create_ll_product_counts_by_season_chart,
        create_ll_product_counts_by_dept_chart,
        create_ll_units_by_season_chart,
        create_ll_units_by_department_chart,
        create_ll_trend_chart_dl_data,
        create_ll_product_counts_by_season_chart_dl_data,
        create_ll_product_counts_by_department_chart_dl_data,
        create_ll_units_by_season_chartDlDt,
        create_ll_units_by_department_chart_dl_data
      ] = await Promise.all([
        getChartdata("create_ll_trend_chart", ""),
        getChartdata("create_ll_product_counts_by_season_chart", ""),
        getChartdata("create_ll_product_counts_by_department_chart", ""),
        getChartdata("create_ll_units_by_season_chart", ""),
        getChartdata("create_ll_units_by_department_chart", ""),
        getChartdata("create_ll_trend_chart_dl_data", jsonReq),
        getChartdata("create_ll_product_counts_by_season_chart_dl_data", jsonReq),
        getChartdata("create_ll_product_counts_by_department_chart_dl_data", jsonReq),
        getChartdata("create_ll_units_by_season_chart_dl_data", jsonReq),
        getChartdata("create_ll_units_by_department_chart_dl_data", jsonReq)
      ]);
      dispatch({ type: "SET_PLEASE_WAIT", data: false });
      setChartData({
        create_ll_trend_chart,
        create_ll_product_counts_by_season_chart,
        create_ll_product_counts_by_dept_chart,
        create_ll_units_by_season_chart,
        create_ll_units_by_department_chart,
        create_ll_trend_chart_dl_data,
        create_ll_product_counts_by_season_chart_dl_data,
        create_ll_product_counts_by_department_chart_dl_data,
        create_ll_units_by_season_chartDlDt,
        create_ll_units_by_department_chart_dl_data
      });

      dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };

    fetchData();
  }, [dispatch, row_ids]);

  const CreateAgChart = ({ optionsConfig }) => {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        {optionsConfig ? <AgCharts options={optionsConfig} /> : <p>Loading chart data...</p>}
      </div>
    );
  };

  return (
    <Box sx={{ padding: 3 }}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Sales by Season</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_trend_chart} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Sales by Downloaded Season</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_trend_chart_dl_data} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Product Counts by Season</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_product_counts_by_season_chart} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Product Counts by Downloaded Season</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_product_counts_by_season_chart_dl_data} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Units by Season</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_units_by_season_chart} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Units by Downloaded Season</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_units_by_season_chartDlDt} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Product Counts by Department</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_product_counts_by_dept_chart} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Product Counts by Downloaded Department</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_product_counts_by_department_chart_dl_data} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Units by Department</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_units_by_department_chart} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Typography variant="h6">Units by Downloaded Department</Typography>
          <CreateAgChart optionsConfig={chartData.create_ll_units_by_department_chart_dl_data} />
        </Paper>
      </Grid>
    </Grid>
  </Box>
);
 
};

export default SummarizedProductionData;
