import App from "App";
import { LicenseManager } from "ag-grid-enterprise";
import { MaterialUIControllerProvider } from "context";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { store } from "store";

LicenseManager.setLicenseKey(
  "CompanyName=Jump Operations, LLC,LicensedApplication=Jump Operations, LLC,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=9,LicensedProductionInstancesCount=1,AssetReference=AG-034534,SupportServicesEnd=5_October_2024_[v2]_MTcyODA4MjgwMDAwMA==83da3173fd67248dbb842458b3b96510"
);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <HashRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
          <App />
      </Provider>
    </MaterialUIControllerProvider>
  </HashRouter>
);
