import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {

  const checkLogin = () => {
    const token = sessionStorage.getItem("access_token");
    const isVerified = sessionStorage.getItem("isVerified");
    // console.log("token", token)

    if(token && (isVerified && isVerified === "yes")) {return true};
    return false;
  }

  const [isAuthenticated, setIsAuthenticated] = useState(checkLogin());
  return isAuthenticated
    ? children
    : <Navigate to='/login' replace />;
}
ProtectedRoute.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};
ProtectedRoute.defaultProps = {
  children: PropTypes.objectOf(PropTypes.any),
};
export default ProtectedRoute;
