import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import PropTypes from "prop-types";
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_merch_assortment_sandbox";
const rng_name = "Merch_Assortment_Sandbox_Rng";

const MerchAssortmentSandbox = forwardRef(({ setCurrentGridApi, setActionItemDataFun }, ref) => {
    const dispatch = useDispatch();
    const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
    const MerchAssortmentSandboxData = useSelector(s => s.MerchAssortmentSandboxData);
    const SSNData = useSelector((s) => s.MerchAssortmentSandboxDataSSN);
    const MASHighlistCell = useSelector(s => s.MASHighlistCell);
    const tmpColDef = useSelector(s => s.MerchAssortmentSandboxColDef || []);
    const varSorting = useSelector(s => s.MerchAssortmentSandboxSorting || []);
    const varFilters = useSelector(s => s.MerchAssortmentSandboxFilters || {});
    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);
    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
    const [isGridReady, setIsGridReady] = useState(false);

    const setResetFilter = (s) => {
        gridApi.current.applyColumnState({ state: s === "reset" ? null : varSorting });
        gridApi.current.setFilterModel(s === "reset" ? null : varFilters);
    }
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!MerchAssortmentSandboxData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                
                return (res)
            }
            cellValueChanged.current = [];
            removeHeihlight();
            return ({ status: true })
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, MerchAssortmentSandboxColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells
                dispatch({ type: "FB_FTY_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");
            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = []
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
    };
    useEffect(() => {
        if (isGridReady) {
            if (varSorting !== null && varSorting !== undefined) {
                gridApi.current.applyColumnState({ state: varSorting });
            }
            if (varFilters !== null && varFilters !== undefined) {
                gridApi.current.setFilterModel(varFilters);
            }
        }
    }, [isGridReady])
    useEffect(() => {
        if (MerchAssortmentSandboxData && MerchAssortmentSandboxData.length > 0) {
            if (isGridReady) calculateAllFormulas()
        }
    }, [MerchAssortmentSandboxData]);
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        setIsGridReady(true)
        calculateAllFormulas();
    }
    const onSortChanged = (event) => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_Merch_Assortment_Sandbox_SORTING", data: savedSortState });
    }
    const onFilterChanged = (event) => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "SET_Merch_Assortment_Sandbox_FILTERS", data: savedFilterModel });
    }
    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    const calculateSKU = (event) => {
        let Style = event.data["Style"] || "";
        let ColorCode = event.data["Color Code"] || "";
        return Style + "-" + ColorCode;
    };
    const fxcalc = (colnm) => {
        let retval = false;
        if (FormulaCalcColLst.includes(colnm)) retval = true;
        return retval;
    };
    const calculateAllFormulas = () => {
        if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
            gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
            gridApi.current.refreshCells({ force: true });
        }
        dispatch({ type: "SET_PLEASE_WAIT", data: false });
    };
    const calculateReactFormula = (event) => {
        if (!event.data) return "";
        fxcalc("SKU") ? event.data["SKU"] = calculateSKU(event) : null;
    };
    return (
        <CustomAgGrid
            autoChangeState={autoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            calculateFormula={calculateReactFormula}
            columnDefs={tmpColDef}
            filters={varFilters}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            resteAutoChangeState={resteAutoChangeState}
            rowData={MerchAssortmentSandboxData}
            setActionItemDataFun={setActionItemDataFun}
            setCellValueChanged={setCellValueChanged}
            setCurrentGridApi={onGridReady}
            SSNData={SSNData}
            tab_name="Merch_Assortment_Sandbox"
            tbl_name={tbl_name}
            tblHighlightCells={MASHighlistCell}
        />
    )
})

MerchAssortmentSandbox.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
};

export default MerchAssortmentSandbox;