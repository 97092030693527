import LinelistUserNotification from "./LinelistUserNotification";

const UserNotification = ({ selectedComponent, removeNotification/* , closeDialog */ }) => {
    const renderUserNotification = () => {
        // console.log("UserNotification -> selectedComponent -> renderUserNotification", selectedComponent);
        if (selectedComponent.table_name.includes("xl_faherty_linelist")) return <LinelistUserNotification selectedComponent={selectedComponent} removeNotification={removeNotification}/* closeDialog={closeDialog} */ />;
        else return <div>No Item Found</div>;
    }
    return (<>
        {renderUserNotification()}
    </>)
}

export default UserNotification;