import { checkHeihlight, executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_action_item_details";
const rng_name = "Action_Item_Rng";


const ActionItemTab = forwardRef(({
    setCurrentGridApi,
    settimeLineDataFun,
    setActionItemDataFun,
    clearFilterBtnCall }, ref) => {

    const dispatch = useDispatch(); //to update redux state variable
    const ActionItemData = useSelector(state => state.ActionItemData);
    const SSNData = useSelector(s => s.ActionItemDataSSN);
    const ActionItemColDef = useSelector(state => state.ActionItemColDef ? state.ActionItemColDef : []);
    
    const ActionItemHighlightCell = useSelector(state => state.ActionItemHighlightCell);

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);

    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

    //Required for all tabs (For before send data functionalities)
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!ActionItemData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                
                return (res)
            }

            return ({ status: true})
        },
        getChangesFunc: (masterData, metaData) => {
            removeHeihlight()
            const res = compareAndHighLightDataChanges(masterData, rng_name, tbl_name, ActionItemColDef, gridApi.current, highlightCells.current, cellValueChanged.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells

                dispatch({ type: "ACTION_ITEM_DATA", data: res.data });
                // dispatch({ type: "SET_MASTERLOV_HIGHLIGHT_CELL", data: highlightCells.current });
                dispatch({ type: "SET_ACTIONITEM_HIGHLIGHT_CELL", data: highlightCells.current });

                gridApi.current.refreshCells({ force: true })
            } else {
                console.log("content res.msg - ", res.msg)
                toastMessage(res.msg, "warning");

            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            removeHeihlight()
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));

    const removeHeihlight = () => {
        let arrTmp = []
        highlightCells.current = arrTmp
        dispatch({ type: "SET_ACTIONITEM_HIGHLIGHT_CELL", data: highlightCells.current });
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
    };


    function funCellStyle(params, col) {
        let greenColorColList = ["Var B/(W)", "Production Approval", "Channel Unit Approval", "Detail", "Units Short/ Over", "% Variance", "Can it be replenished", "Replenishment Date", "Size 1", "Ordered 1", "Shortage / Overage 1", "Size 2", "Ordered 2", "Shortage / Overage 2", "Size 3", "Ordered 3", "Shortage / Overage 3", "Size 4", "Ordered 4", "Shortage / Overage 4", "Size 5", "Ordered 5", "Shortage / Overage 5", "Size 6", "Ordered 6", "Shortage / Overage 6", "Size 7", "Ordered 7", "Shortage / Overage 7", "Size 8", "Ordered 8", "Shortage / Overage 8", "Size 9", "Ordered 9", "Shortage / Overage 9", "Size 10", "Ordered 10", "Shortage / Overage 10", "Size 11", "Ordered 11", "Shortage / Overage 11", "Size 12", "Ordered 12", "Shortage / Overage 12", "Size 13", "Ordered 13", "Shortage / Overage 13", "Size 14", "Ordered 14", "Shortage / Overage 14"];
        let orderColumns = ["Ordered 1", "Ordered 2", "Ordered 3", "Ordered 4", "Ordered 5", "Ordered 6", "Ordered 7", "Ordered 8", "Ordered 9", "Ordered 10", "Ordered 11", "Ordered 12", "Ordered 13", "Ordered 14"];
        let bgColor = "";
        let bdrColor = "";
        let txtDeco = "";
        let fontColor = "#000000";
        let boldTxt = "normal";
        const hdrNm = col.field;
        const value = params.value ? params.value : "";    // 
        // console.log("params -> ", params )
        if (col.editable) bgColor = "#FFFFFF";
        else bgColor = "#F0F0F0";
        if (col.type === "dropdown") bgColor = "#FDFCEC";
        let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
        let obj = checkHeihlight(key, highlightCells.current)
        if (obj.status === true) {
            if (obj.colorCode == 1) bgColor = "cyan";
            else if (obj.colorCode == 2) bgColor = "#d5a7f2";
            else if (obj.colorCode == 3) bdrColor = "#2196f3";
            else if (obj.colorCode == 4) bdrColor = "#9B6155";
        }
        if (["STYLE", "COLOR"].includes(hdrNm)) fontColor = "#0000FF";
        else if (greenColorColList.includes(hdrNm)) fontColor = "#008000";
        if (orderColumns.includes(hdrNm)) bgColor = "#C8D8E4";
        if (["Status"].includes(hdrNm)) {
            if (value === "Open") {
                bgColor = "#fccc86";
                fontColor = "#CC5500";
            } else if (["Started"].includes(value)) {
                bgColor = "#FFEB9C";
                fontColor = "#9C5700";
            } else if (["Completed"].includes(value)) {
                bgColor = "#C6EFCE";
                fontColor = "#006100";
            }
        }
        if (["Priority"].includes(hdrNm)) {
            if (value === "High") {
                bgColor = "#f54545";
                fontColor = "#7a0101";
            } else if (["Medium"].includes(value)) {
                bgColor = "#FFC7CE";
                fontColor = "#9C0000"
            }
        }
        if (["Linelist Current FOB", "NS PO Price"].includes(hdrNm)) {
            if (hdrNm === "NS PO Price") {
                if (value !== params?.data["Linelist Current FOB"] && params?.data["Linelist Costing Status"] === "Confirmed FOB") {
                    bgColor = "#00ACA8";
                    // fontColor = "#9C5700";
                }
            }
            else if (hdrNm === "Linelist Current FOB") {
                if (value !== params?.data["NS PO Price"] && params?.data["Linelist Costing Status"] === "Confirmed FOB") {
                    bgColor = "#FF9999";
                    fontColor = "#C00000";
                }
            }
        }
        if (["Vendor EXF Change Notes", "Replenishment Date"].includes(hdrNm)) bgColor = "#FDFCEC";
        if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold" }
        if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold" } // E0FFFF
        return { backgroundColor: bgColor, borderColor: bdrColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor }
    };

    const onGridReady = (params) => {        
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
    }

    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    return (
        <CustomAgGrid
            setCurrentGridApi={onGridReady}
            filters={[]}
            setActionItemDataFun={setActionItemDataFun}
            tab_name="Action_Item"
            tbl_name={tbl_name}
            columnDefs={ActionItemColDef}
            rowData={ActionItemData}
            SSNData={SSNData}
            tblHighlightCells={ActionItemHighlightCell}
            autoChangeState={autoChangeState}
            resteAutoChangeState={resteAutoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            setCellValueChanged={setCellValueChanged}
            customCellStyle = {funCellStyle}
        />
    )
})

ActionItemTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,
    clearFilterBtnCall: PropTypes.func.isRequired,
};

export default ActionItemTab;

