import MDBox from "components/MDBox";
import PropTypes from "prop-types";

function GetChangesStatistics({ table_name, TabNm }) {

    return (
        <MDBox>
            <div style={{ color: 'black' }}>{TabNm}
                <div style={{ display: 'grid', textAlign: 'center', margin: '10px' }}>
                    <span style={{ backgroundColor: 'black', color: 'white' }}>Duration</span>
                    <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Duration')}</span>
                </div>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                    <div style={{ width: '50%', float: 'left', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ backgroundColor: 'black', color: 'white' }}>Transactions</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Transactions')}</span>
                        </div>
                    </div>
                    <div style={{ width: '50%', float: 'right', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ backgroundColor: 'black', color: 'white' }}>Users</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Users')}</span>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', textAlign: 'center' }}>
                    <div style={{ width: '33%', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ whiteSpace: 'nowrap', backgroundColor: 'black', color: 'white', paddingRight: '10px', paddingLeft: '10px' }}>New Rows</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'New_Rows')}</span>
                        </div>
                    </div>
                    <div style={{ width: '33%', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ whiteSpace: 'nowrap', backgroundColor: 'black', color: 'white', paddingRight: '10px', paddingLeft: '10px' }}>Cell Updates</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Cell_Updates')}</span>
                        </div>
                    </div>
                    <div style={{ width: '33%', margin: '10px' }}>
                        <div style={{ display: 'grid' }}>
                            <span style={{ whiteSpace: 'nowrap', backgroundColor: 'black', color: 'white', paddingRight: '10px', paddingLeft: '10px' }}>Delete Rows</span>
                            <span style={{ border: '1px solid lightgray', height: '23px' }}>{sessionStorage.getItem(table_name + '_' + 'Delete_Rows')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </MDBox>
    )
}
GetChangesStatistics.prototype = {
    table_name: PropTypes.string.isRequired,
    TabNm: PropTypes.string.isRequired,
}
export default GetChangesStatistics;
