import { AccountTree, AdjustOutlined, Delete, FiberManualRecord, ModeEditOutlineOutlined } from "@mui/icons-material";
import { Avatar, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ProperCase, getHighlightByTblName } from "layouts/linelist/XL_Utils";
import { setHighlightByTblName } from "layouts/linelist/getChangesComman";
import PropTypes from "prop-types";
import { useState } from "react";

const AuditTrailCard = ({ name, title, newVal, oldVal, time, operation, data, table_name, checkAndChangeTab }) => {
    const [showMore, setShowMore] = useState(false);
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        // console.log("name........",name)
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 24,
                height: 24,
                fontSize: "11px",
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            // children: `${nm}`,
        };
    }
    const expandText = (val) => {
        //highlight cell
        setShowMore(val)
        if (!data || !val) return;
        let highlightCells = getHighlightByTblName(table_name);
        if (highlightCells) {
            highlightCells = highlightCells.filter((item) => {
                let res = true;
                Object.entries(item).map(([key1, value]) => {
                    if (value === 4) res = false
                })
                return res;
            })
        }
        if (operation === "update") {
            const key = table_name + "|" + data.col + "|" + data.row_id
            const check = highlightCells.filter((item) => item[key])
            if (check.length === 0) highlightCells.push({ [key]: 4 })
        } else if (operation === "insert") {
            Object.entries(data).map(([key1, value]) => {
                const key = table_name + "|" + key1 + "|" + data.row_id
                const check = highlightCells.filter((item) => item[key])
                if (check.length === 0) highlightCells.push({ [key]: 4 })
            })
        }
        // console.log("highlightCells", highlightCells)
        checkAndChangeTab();
        setHighlightByTblName(table_name, highlightCells, "audit trail")
    }
    return (
        <Card variant="outlined" onClick={() => expandText(true)} onMouseLeave={() => expandText(false)} sx={{ borderRadius: "1px" }}>
            <MDBox p={1}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={8.5}>
                                <MDBox mb={0.5} lineHeight={1}>
                                    <MDBox display="flex" flexDirection="row">
                                        <Avatar {...stringAvatar(name)} />
                                        <MDTypography variant="caption" fontWeight="dark" ml={1}>
                                            {ProperCase(name)}
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                            <Grid item xs={3.5}>
                                <MDBox width="100%" textAlign="right" lineHeight={0.25}>
                                    <MDTypography fontWeight="regular" mr={1} sx={{ fontSize: "10px" }}> {time} </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>

                        <MDBox lineHeight={1} p={1} className={showMore ? "show_changes_more" : "show_changes_less"}>
                            <MDBox>
                                {operation === "update" ? <ModeEditOutlineOutlined style={{ width: "13px", height: "13px" }} /> : null}
                                {operation === "insert" ? <AccountTree style={{ width: "13px", height: "13px" }} /> : null}
                                {operation === "delete" ? <Delete style={{ width: "13px", height: "13px" }} /> : null}

                                <span style={{ fontSize: "12px" }}> {title}</span>
                            </MDBox>
                            <MDBox ml={2} mt={1} >
                                <AdjustOutlined style={{ width: "12px", height: "12px" }} />
                                <span style={{ fontSize: "11px", marginLeft: "2px" }}>
                                    {newVal && newVal.length > 30 && !showMore ? newVal.slice(0, 30) : newVal}
                                </span>
                            </MDBox>
                            <MDBox ml={2}  >
                                <FiberManualRecord style={{ width: "10px", height: "10px" }} />
                                <span style={{ fontSize: "10px", marginLeft: "2px" }}>
                                    {oldVal && oldVal.length > 35 && !showMore ? oldVal.slice(0, 35) : oldVal}
                                </span>
                            </MDBox>
                            {/* <TimelineList title="Edited Size 1">
                                <TimelineItem 
                                    color="light"
                                    icon="circle"
                                    title=""
                                    dateTime="2T"
                                    description=""
                                    badges={["design"]}
                                />
                                <TimelineItem 
                                    color="light"
                                    icon="round"
                                    title=""
                                    dateTime="1T"
                                    description=""
                                    badges={["design"]}
                                />
                           </TimelineList> */}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    )
}
AuditTrailCard.prototype = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    newVal: PropTypes.string.isRequired,
    oldVal: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    table_name: PropTypes.string.isRequired,
    checkAndChangeTab: PropTypes.func.isRequired,
}
export default AuditTrailCard;