import { executeDataVerification, toastMessage } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_fb_fty_database";
const rng_name = "Fb_Fty_Database_Rng";


const FbFtyDatabaseTab = forwardRef(({
    setCurrentGridApi,
    settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {

    const dispatch = useDispatch(); //to update redux state variable
    const FbFtyDatabaseData = useSelector(state => state.FbFtyDatabaseData);
    const SSNData = useSelector((s) => s.FbFtyDatabaseDataSSN);
    const ActionItemData = useSelector(state => state.ActionItemData);
    const FbFtyDatabaseHighlistCell = useSelector(state => state.FbFtyDatabaseHighlistCell);
    const tmpColDef = useSelector(state => state.FbFtyDatabaseColDef ? state.FbFtyDatabaseColDef : []);

    const FbFtyDatabaseSorting = useSelector(state => state.FbFtyDatabaseSorting ? state.FbFtyDatabaseSorting : []);
    const FbFtyDatabaseFilters = useSelector(state => state.FbFtyDatabaseFilters ? state.FbFtyDatabaseFilters : {});

    const highlightCells = useRef([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const cellValueChanged = useRef([]);

    const [autoChangeState, setAutoChangeState] = useState(false)
    const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
    const [isGridReady, setIsGridReady] = useState(false);

    const setResetFilter = (setReset) => {
        let sorting = FbFtyDatabaseSorting
        let filtering = FbFtyDatabaseFilters

        if (setReset === "reset") {
            sorting = null
            filtering = null
        }
        gridApi.current.applyColumnState({
            state: sorting
        })
        gridApi.current.setFilterModel(filtering);

    }
    //Required for all tabs (For before send data functionalities)
    useImperativeHandle(ref, () => ({
        validateSendData: () => {
            if (!FbFtyDatabaseData) return false
            const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
            if (res.status === false) {
                highlightCells.current = res.highlightCells;
                setHighlightByTblName(tbl_name, highlightCells.current);
                return (res)
            }
            cellValueChanged.current = [];
            removeHeihlight();
            return ({ status: true})
        },
        getChangesFunc: async (masterData, metaData) => {
            const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, FbFtyDatabaseColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
            if (res.status) {
                highlightCells.current = res.highlightCells

                dispatch({ type: "FB_FTY_DATA", data: res.data });
                gridApi.current.refreshCells({ force: true })
            } else {
                toastMessage(res.msg, "warning");

            }
            return res
        },
        getAutoChanges: (data, downloadType) => {
            setResetFilter("reset")
            const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
            setAutoChangeState(true);
            setAutoGetChangeResult(res);
        }
    }));
    const removeHeihlight = () => {
        highlightCells.current = []
        if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
    };
    useEffect(() => {
        if (ActionItemData && ActionItemData.length > 0 && isGridReady) {

            toastMessage("New task assigned.");


        }
    }, [ActionItemData])
    useEffect(() => {
        if (isGridReady) {
            if (FbFtyDatabaseSorting !== null && FbFtyDatabaseSorting !== undefined) {
                gridApi.current.applyColumnState({
                    state: FbFtyDatabaseSorting
                })
            }
            if (FbFtyDatabaseFilters !== null && FbFtyDatabaseFilters !== undefined) {
                gridApi.current.setFilterModel(FbFtyDatabaseFilters);
            }
        }
    }, [isGridReady])
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
        setIsGridReady(true)
    }
    const onSortChanged = (event) => {
        const colState = gridApi.current.getColumnState();
        const savedSortState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        dispatch({ type: "SET_FB_FTY_SORTING", data: savedSortState });
    }
    const onFilterChanged = (event) => {
        const savedFilterModel = gridApi.current.getFilterModel();
        dispatch({ type: "SET_FB_FTY_FILTERS", data: savedFilterModel });
    }

    const resteAutoChangeState = () => {
        setResetFilter("set");
        setAutoChangeState(false);
        setAutoGetChangeResult({});
    }
    const setCellValueChanged = (data) => {
        cellValueChanged.current = data;
    }
    return (
        <CustomAgGrid
            setCurrentGridApi={onGridReady}
            filters={FbFtyDatabaseFilters}
            setActionItemDataFun={setActionItemDataFun}
            tab_name="FB_Fty_Database"
            tbl_name={tbl_name}
            columnDefs={tmpColDef}
            rowData={FbFtyDatabaseData}
            SSNData={SSNData}
            tblHighlightCells={FbFtyDatabaseHighlistCell}
            onFilterChanged={onFilterChanged}
            onSortChanged={onSortChanged}
            autoChangeState={autoChangeState}
            resteAutoChangeState={resteAutoChangeState}
            autoGetChangeResult={autoGetChangeResult}
            setCellValueChanged={setCellValueChanged}
        />
    )
})

FbFtyDatabaseTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired,
    settimeLineDataFun: PropTypes.func.isRequired,

};

export default FbFtyDatabaseTab;