import { useMemo } from "react";

const Summary = () => {

    const summaryData = useMemo(() => [
        "Seasons", "Linelist"
    ], []);

    return(<>
        Summary
    </>)
}

export default Summary;