import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

function CellTimeLine({ data }) {
    const [timeLineData, setTimeLineData] = useState(data);
    const [timeLineColsDef, setTimeLineColsDef] = useState([]);
    useEffect(() => {
        const oneRowData = data[0]
        let ColsDef = []
        if (oneRowData !== null && oneRowData !== undefined) {
            Object.keys(oneRowData).map(cn => {
                let mpCol
                let hdrNm = cn
                let fldNm = cn
                let clStl = ""
                let vlFrmt = ""
                if (hdrNm === "col") {
                    hdrNm = "Column Name"
                    mpCol = { width: 150, headerName: hdrNm, field: fldNm, colId: fldNm, cellStyle: { backgroundColor: '#2B65AC', color: 'white' }, resizable: true, suppressMenu: true }

                } else if (hdrNm === "old_value") {
                    // hdrNm = "Old Value"
                    hdrNm = "Previous Value"

                    vlFrmt = function (params) {
                        let ct = params.node.data.change_type

                        if (ct === "price") {
                            if (params.value === null || params.value === undefined || params.value.trim() === "" || params.value.toLowerCase() === "na" || params.value.toLowerCase() === "n/a" || params.value.toLowerCase() === "false" || params.value.toLowerCase() === "true") {
                                return params.value
                            } else {
                                const formattedNumber = params.value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                                return "$" + formattedNumber;
                            }
                        } else if (ct === "percentage") {
                            if (params.value === null || params.value === undefined || params.value.trim() === "" || params.value.toLowerCase() === "na" || params.value.toLowerCase() === "n/a" || params.value.toLowerCase() === "false" || params.value.toLowerCase() === "true") {
                                return params.value
                            } else {
                                if (params.value.includes(".")) {
                                    if (params.value > 1) {
                                        return parseFloat(params.value).toFixed(2) + "%"
                                    } else {
                                        return parseFloat(params.value * 100).toFixed(2) + "%"
                                    }
                                } else {
                                    return parseFloat(params.value).toFixed(2) + "%"
                                }
                            }
                        } else {
                            return params.value
                        }
                    }

                    mpCol = { width: 120, headerName: hdrNm, field: fldNm, colId: fldNm, valueFormatter: vlFrmt, resizable: true, suppressMenu: true }

                } else if (hdrNm === "new_value") {
                    hdrNm = "New Value"

                    vlFrmt = function (params) {
                        let ct = params.node.data.change_type

                        if (ct === "price") {
                            if (params.value === null || params.value === undefined || params.value.trim() === "" || params.value.toLowerCase() === "na" || params.value.toLowerCase() === "n/a" || params.value.toLowerCase() === "false" || params.value.toLowerCase() === "true") {
                                return params.value
                            } else {
                                const formattedNumber = params.value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                                return "$" + formattedNumber;
                            }
                        } else if (ct === "percentage") {
                            if (params.value === null || params.value === undefined || params.value.trim() === "" || params.value.toLowerCase() === "na" || params.value.toLowerCase() === "n/a" || params.value.toLowerCase() === "false" || params.value.toLowerCase() === "true") {
                                return params.value
                            } else {
                                if (params.value.includes(".")) {
                                    if (params.value > 1) {
                                        return parseFloat(params.value).toFixed(2) + "%"
                                    } else {
                                        return parseFloat(params.value * 100).toFixed(2) + "%"
                                    }
                                } else {
                                    return parseFloat(params.value).toFixed(2) + "%"
                                }
                            }
                        } else {
                            return params.value
                        }
                    }

                    mpCol = { width: 120, headerName: hdrNm, field: fldNm, colId: fldNm, cellStyle: { backgroundColor: '#FFFFBC' }, valueFormatter: vlFrmt, resizable: true, suppressMenu: true }

                } else if (hdrNm === "difference") {
                    hdrNm = "Difference"

                    clStl = function (params) {
                        var operation = params.node.data.operation;
                        var background = ''
                        if (operation === 'decrease') {
                            background = "#FFCDD5"
                        } else if (operation === 'increase') {
                            background = "#00FF99"
                        } else {
                            background = "#EAEAEA"
                        }
                        return { background: background }
                    }

                    vlFrmt = function (params) {
                        let ct = params.node.data.change_type

                        if (ct === "price") {
                            if (params.value === null || params.value === undefined || params.value.trim() === "" || params.value.toLowerCase() === "na" || params.value.toLowerCase() === "n/a" || params.value.toLowerCase() === "false" || params.value.toLowerCase() === "true") {
                                return params.value
                            } else {
                                const formattedNumber = params.value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                                return "$" + formattedNumber;
                            }
                        } else if (ct === "percentage") {
                            if (params.value === null || params.value === undefined || params.value.trim() === "" || params.value.toLowerCase() === "na" || params.value.toLowerCase() === "n/a" || params.value.toLowerCase() === "false" || params.value.toLowerCase() === "true") {
                                return params.value
                            } else {
                                if (params.value.includes(".")) {
                                    if (params.value > 1) {
                                        return parseFloat(params.value).toFixed(2) + "%"
                                    } else {
                                        return parseFloat(params.value * 100).toFixed(2) + "%"
                                    }
                                } else {
                                    return parseFloat(params.value).toFixed(2) + "%"
                                }
                            }
                        } else {
                            return params.value
                        }
                    }

                    mpCol = { width: 100, headerName: hdrNm, field: fldNm, colId: fldNm, cellStyle: clStl, valueFormatter: vlFrmt, resizable: true, suppressMenu: true }

                } else if (hdrNm === "change_type") {
                    hdrNm = "Change Type"
                    mpCol = { width: 100, headerName: hdrNm, field: fldNm, colId: fldNm, resizable: true, suppressMenu: true }
                } else if (hdrNm === "operation") {
                    hdrNm = "Operation"
                    mpCol = { width: 100, headerName: hdrNm, field: fldNm, colId: fldNm, resizable: true, suppressMenu: true }
                } else if (hdrNm === "user_id") {
                    hdrNm = "User"
                    mpCol = { width: 150, headerName: hdrNm, field: fldNm, colId: fldNm, resizable: true, suppressMenu: true }
                } else if (hdrNm === "created_on") {
                    hdrNm = "Date & Time"
                    mpCol = { width: 150, headerName: hdrNm, field: fldNm, colId: fldNm, resizable: true, suppressMenu: true }
                } else {
                    mpCol = { width: 90, headerName: hdrNm, field: fldNm, colId: fldNm, resizable: true, suppressMenu: true }
                }

                ColsDef.push(mpCol);
            })
            setTimeLineData(timeLineData)
            setTimeLineColsDef(ColsDef)
        }
    }, [])
    const ontimeLineGridReady = (params) => {
        // var capi = params.api
        // capi.autoSizeAllColumns()
    };
    return (
        <MDBox>
            <div style={{ height: '475px', width: '100%', textAlign: 'left' }} className="ag-theme-quartz">
                <AgGridReact
                    onGridReady={(event) => ontimeLineGridReady(event)}
                    suppressContextMenu={true}
                    rowSelection="single"
                    enableColResize={true}
                    columnDefs={timeLineColsDef}
                    rowData={timeLineData}
                    suppressRowClickSelection={true}
                    suppressFieldDotNotation
                    getRowHeight={25}
                />
            </div>
        </MDBox>
    )
}
CellTimeLine.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CellTimeLine;