


import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { applicationTitle } from 'utils/app.props';
// import data from "./SampleAsText.json"
// import data from "./SampleAsNumeric.json"
// import colDefs from "./xl_faherty_sample.json"

import { SummaryReportColDef, generateColumnGrouping, prepareSummaryReportData } from 'layouts/linelist/XL_Utils';
import { colGrpSummaryReport } from "layouts/linelist/colGrpVariable";
// import { Scrollbars } from 'react-custom-scrollbars';
// import clearDataFilterPic from "assets/images/clear-filter.png";
import { groupHeaderHeight } from 'utils/app.props';
import { getColumnIndex } from "layouts/linelist/XL_Utils";

const styles = {
    left: { width: '100%', height: '80%', border: '2px solid #000', 'border-style': 'dashed', 'min-height': '293px' },
    td10: { "width": "11%", "border": "1px solid #d2d2d2", "padding": "1px", "background-color": "#eeeeee", "font-weight": "500", "font-size": "12px", "text-align": "center" },
    td30: { "width": "18%", "border": "1px solid #eeeeee", "padding": "1px", "text-align": "center" },
    container: { 'position': 'relative', 'text-align': 'center' },
    bookmarkdiv: { 'position': 'absolute', 'top': '-10px', 'right': '-10px', },
    mygriditem: { "margin-left": "0px", "max-width": "90%" },
    CP_Attributes_Span_Product_Type_Style: { 'margin-left': '5px', 'margin-right': '5px', 'text-transform': 'capitalize', 'font-size': '12px' },
    CP_Attributes_Span_Style: { 'margin-left': '5px', 'margin-right': '5px', 'font-size': '12px' },
    CP_Attributes_Td_Style: { 'border': '1px solid #d2d2d2', 'font-weight': '500', 'background': '#e1e1e1', 'white-space': 'nowrap', 'width': '10%', 'font-size': '12px' },
    CP_Close_Button_Style: { 'margin': '0 5px' },
    CP_Dialog_Actions_Style: { 'margin': '5px 5px' },
    CP_Dialog_Content_Style: { 'padding-bottom': '5px' },
    CP_Dialog_Title_Style: { 'padding': '5px 5px 5px', 'text-align': 'center', 'font-size': 'large', 'font-weight': 'bold' },
    CP_Image_Card_Header_Style: { 'align-self': 'center', 'padding': '0px', 'margin-top': '5px' },
    CP_Image_Style: { 'max-height': '125px', 'max-width': '125px', 'width': 'auto', 'height': 'auto' },
    CP_Remove_Product_Button: { 'cursor': 'pointer', 'text-align': 'right', 'margin-right': '3px', 'margin-top': '2px' },
    CP_Table_Style: { 'width': '100%', 'border-collapse': 'collapse' },
    CP_Td_Name_Style: { 'border': '1px solid #d2d2d2', 'text-align': 'center', 'font-weight': '500', 'font-size': '12px' },
    CP_Td_Style: { 'border': '1px solid #d2d2d2', 'text-align': 'center', 'font-size': '12px' },
    CP_Td_Style_Description: { 'border': '1px solid #d2d2d2', 'text-align': 'justify', 'padding': '5px', 'vertical-align': 'top', 'font-size': '12px' },
    filterDivStyle: { overflowY: 'auto', height: '130px', marginTop: '-15px', "font-size": "10px" },
    filterListItemStyle: { height: '24px', borderRadius: '4px', marginBottom: '2px', border: '1px solid #EEEEEE' },
    filterListItemTextStyle: { marginLeft: '-10px', "font-size": "12px" },
    CardTableTrStyle: { "line-height": "15px" }
}
const SummaryReportTab = forwardRef(({ setCurrentGridApi }, ref) => {
    const dispatch = useDispatch();
    const [selectedSeasonList, setSelectedSeasonList] = useState([]);
    const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
    const [selectedCatList, setSelectedCatList] = useState([]);
    const [selectedFactoryList, setSelectedFactoryList] = useState([]);
    const [selectedShippingList, setSelectedShippingList] = useState([]);

    const [newColDef, setNewColDef] = useState([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const SsnData = useSelector(state => state.SummaryReportAggDataSSN);
    const LinelistData = useSelector(state => state.LinelistData);
    useEffect(() => {
        let tmpColDef = SummaryReportColDef
        if (tmpColDef && tmpColDef.length > 0) {
            let temp = tmpColDef;
            temp = temp.map((col) => {
                let mpCol = col
                mpCol = {
                    suppressSpanHeaderHeight: true,
                    headerName: col.headerName,
                    field: col.field,
                    filter: col.filter,

                    width: col.width,
                    autoHeaderHeight: true,
                    resizable: col.resizable,
                    valueFormatter: col.valueFormatter,
                    suppressKeyboardEvent: params => {
                        if (params.event.ctrlKey && params.event.key === "ArrowUp") {
                            // console.log(params)
                            gridApi.current.ensureIndexVisible(0, "top");
                            const element = document.querySelector("div[col-id='" + params.column.colId + "']");
                            if (element) {
                                element.setAttribute('tabindex', '1');
                                element.focus();
                                const colElement1 = element.querySelector(".ag-header-cell-comp-wrapper");
                                const colElement2 = colElement1.querySelector(".ag-cell-label-container");
                                if (colElement2) {
                                    colElement2.focus()
                                    return true;
                                }
                            }
                            return true
                        }
                        return false
                    }
                }
                return mpCol
            })
            setNewColDef(generateColumnGrouping(colGrpSummaryReport, temp))
        }
    }, []);
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi;
        setCurrentGridApi(params)
    };
    const getRowStyle = (params) => {
        if (params.node.lastChild) {
            return { "font-weight": "bold", "border-top": "1px solid #4472C4", "border-bottom": "2px solid #4472C4" }
        } else {
            return null
        }
    };
    const clearFilter = (colNm) => {
        if (colNm = "Season Code") {
            setSelectedSeasonList([])
        } else if (colNm = "Department") {
            setSelectedDepartmentList([])
        } else if (colNm = "Category") {
            setSelectedCatList([])
        } else if (colNm = "Factory") {
            setSelectedFactoryList([])
        } else if (colNm = "Shipping Terms (FOB/DDP/LDP)") {
            setSelectedShippingList([])
        }
    }
    const handleSeasonCodeToggle = (sc) => {
        let tmpVals = [...selectedSeasonList]
        if (selectedSeasonList.includes(sc)) {
            tmpVals = selectedSeasonList.filter((v) => v !== sc)
        } else {
            tmpVals.push(sc)
        }
        setSelectedSeasonList(tmpVals)

        let filterMp = new Map()
        filterMp.set("Season Code", tmpVals)
        // filterMp.set("Department", ["Mens"])
        // filterMp.set("Category", ["Sweaters"])
        // filterMp.set("Factory", ["AFFINA"])
        // filterMp.set("Shipping Terms (FOB/DDP/LDP)", ["FOB"])
        let retVal = prepareSummaryReportData(LinelistData, filterMp)
        dispatch({ type: "SUMMARY_REPORT_AGG_SSN_DATA", data: retVal })
    };
    const handleDepartmentToggle = (dp) => {
        let tmpVals = [...selectedDepartmentList]
        if (selectedDepartmentList.includes(dp)) {
            tmpVals = selectedDepartmentList.filter((v) => v !== dp)
        } else {
            tmpVals.push(dp)
        }
        setSelectedDepartmentList(tmpVals)

        let filterMp = new Map()
        // filterMp.set("Season Code", [])
        filterMp.set("Department", tmpVals)
        // filterMp.set("Category", ["Sweaters"])
        // filterMp.set("Factory", ["AFFINA"])
        // filterMp.set("Shipping Terms (FOB/DDP/LDP)", ["FOB"])
        let retVal = prepareSummaryReportData(LinelistData, filterMp)
        dispatch({ type: "SUMMARY_REPORT_AGG_SSN_DATA", data: retVal })
    };
    const handleCategoryToggle = (ct) => {
        let tmpVals = [...selectedCatList]
        if (selectedCatList.includes(ct)) {
            tmpVals = selectedCatList.filter((v) => v !== ct)
        } else {
            tmpVals.push(ct)
        }
        setSelectedCatList(tmpVals)

        let filterMp = new Map()
        // filterMp.set("Season Code", [])
        // filterMp.set("Department", tmpVals)
        filterMp.set("Category", tmpVals)
        // filterMp.set("Factory", ["AFFINA"])
        // filterMp.set("Shipping Terms (FOB/DDP/LDP)", ["FOB"])
        let retVal = prepareSummaryReportData(LinelistData, filterMp)
        dispatch({ type: "SUMMARY_REPORT_AGG_SSN_DATA", data: retVal })
    };
    const handleFactoryToggle = (fc) => {
        let tmpVals = [...selectedFactoryList]
        if (selectedFactoryList.includes(fc)) {
            tmpVals = selectedFactoryList.filter((v) => v !== fc)
        } else {
            tmpVals.push(fc)
        }
        setSelectedFactoryList(tmpVals)

        let filterMp = new Map()
        // filterMp.set("Season Code", [])
        // filterMp.set("Department", tmpVals)
        // filterMp.set("Category", tmpVals)
        filterMp.set("Factory", tmpVals)
        // filterMp.set("Shipping Terms (FOB/DDP/LDP)", ["FOB"])
        let retVal = prepareSummaryReportData(LinelistData, filterMp)
        dispatch({ type: "SUMMARY_REPORT_AGG_SSN_DATA", data: retVal })
    };
    const handleShippingTermsFobDdpLdpToggle = (st) => {
        let tmpVals = [...selectedShippingList]
        if (selectedShippingList.includes(st)) {
            tmpVals = selectedShippingList.filter((v) => v !== st)
        } else {
            tmpVals.push(st)
        }
        setSelectedShippingList(tmpVals)

        let filterMp = new Map()
        // filterMp.set("Season Code", [])
        // filterMp.set("Department", tmpVals)
        // filterMp.set("Category", tmpVals)
        // filterMp.set("Factory", tmpVals)
        filterMp.set("Shipping Terms (FOB/DDP/LDP)", tmpVals)
        let retVal = prepareSummaryReportData(LinelistData, filterMp)
        dispatch({ type: "SUMMARY_REPORT_AGG_SSN_DATA", data: retVal })
    };
    const suppressHeaderKeyboardEvent = ({ event }) => {
        if (!event) return false;
        let tmpColDef = SummaryReportColDef
        if (event.altKey && event.key === "ArrowDown") {
            const column = tmpColDef.filter((col) => event.srcElement.innerText === col.headerName);
            const colIndex = getColumnIndex(column[0], gridApi) + 1;
            const colElement1 = document.querySelector("div[aria-colindex='" + (colIndex) + "']");

            const wrapper = colElement1.querySelector(".ag-header-cell-comp-wrapper");
            if (wrapper) {
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            } else {
                const colElement1 = document.querySelector(".ag-header-container");
                const colElement2 = colElement1.querySelector(".ag-header-row.ag-header-row-column");
                const colElement3 = colElement2.querySelector("div[aria-colindex='" + (colIndex) + "']");

                const wrapper = colElement3.querySelector(".ag-header-cell-comp-wrapper");
                const container = wrapper.querySelector(".ag-cell-label-container");
                const colElement = container.querySelector('[data-ref="eFilterButton"]');
                colElement?.dispatchEvent(new Event("click"));
            }
            return true;
        } else if (event.ctrlKey && event.key === "ArrowLeft") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const firstColumnm = cols[0];
            gridApi.current.setFocusedCell(0, firstColumnm.colId);
            gridApi.current.ensureColumnVisible(firstColumnm.colId);
            const element = document.querySelector("div[col-id='" + firstColumnm.colId + "']");
            if (element) element.focus();
            return true;
        } else if (event.ctrlKey && event.key === "ArrowRight") {
            const cols = tmpColDef.filter((col) => !col.hide);
            const lastColumn = cols[cols.length - 1];
            gridApi.current.ensureColumnVisible(lastColumn.colId);
            const element = document.querySelector("div[col-id='" + lastColumn.colId + "']");
            if (element) {
                element.focus();
                return true;
            }
        } else if (event.ctrlKey && event.key === "ArrowDown") {
            const column = tmpColDef.filter((col) => col.headerName === event.srcElement.innerText);
            gridApi.current.ensureIndexVisible(SsnData.length - 1);
            gridApi.current.setFocusedCell(SsnData.length - 1, column[0].colId);
            return true;
        }
        return false;
    };
    return (
        <>
            {/* <div> */}
            {/*
                <MDBox mt={1} sx={{ display: 'flex'}}>
                    <div>
                        <div style={{ height: "25px", display: "flex" }}>
                            <div style={{ textAlign: 'left', backgroundColor: "white", width: "50%" }}>
                                <span>Season Code</span>
                            </div>
                            <div style={{ textAlign: 'right', backgroundColor: "white", width: "50%" }}>
                                <img src={clearDataFilterPic} alt="Clear" style={{ cursor: 'pointer' }} title='Clear Filter' onClick={() => clearFilter("Season Code")}/>
                            </div>
                        </div>
                        <div style={{ height: "2px", borderBottom: "2px solid darkgray" }}></div>
                        <MDBox>
                            <List sx={{ width: '100%', maxWidth: 360, height: "150px", bgcolor: 'background.paper' }}>
                                {JSON.parse(sessionStorage.getItem("srUniqueSeasonCode")).map((sc) => {
                                    return (<ListItem
                                        key={sc}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleSeasonCodeToggle(sc)} dense>
                                            <ListItemText id={sc} primary={sc} />
                                        </ListItemButton>
                                    </ListItem>)
                                })}
                            </List>
                        </MDBox>
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div>
                        <div style={{ height: "25px", display: "flex" }}>
                            <div style={{ float: 'left' }}>
                                Department
                            </div>
                            <div style={{ float: 'right' }}>
                                <img src={clearDataFilterPic} alt="Clear" style={{ cursor: 'pointer' }} title='Clear Filter' onClick={() => clearFilter("Department")}/>
                            </div>
                        </div>
                        <MDBox>
                            <List sx={{ width: '100%', maxWidth: 360, height: "150px", bgcolor: 'background.paper' }}>
                                {JSON.parse(sessionStorage.getItem("srUniqueDepartment")).map((dp) => {
                                    return (<ListItem
                                        key={dp}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleDepartmentToggle(dp)} dense>
                                            <ListItemText id={dp} primary={dp} />
                                        </ListItemButton>
                                    </ListItem>)
                                })}
                            </List>
                        </MDBox>
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div>
                        <div style={{ height: "25px", display: "flex" }}>
                            <div style={{ float: 'left' }}>
                                Category
                            </div>
                            <div style={{ float: 'right' }}>
                                <img src={clearDataFilterPic} alt="Clear" style={{ cursor: 'pointer' }} title='Clear Filter' onClick={() => clearFilter("Category")}/>
                            </div>
                        </div>
                        <MDBox>
                            <List sx={{ width: '100%', maxWidth: 360, height: "150px", bgcolor: 'background.paper' }}>
                                {JSON.parse(sessionStorage.getItem("srUniqueCategory")).map((ct) => {
                                    return (<ListItem
                                        key={ct}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleCategoryToggle(ct)} dense>
                                            <ListItemText id={ct} primary={ct} />
                                        </ListItemButton>
                                    </ListItem>)
                                })}
                            </List>
                        </MDBox>
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div>
                        <div style={{ height: "25px", display: "flex" }}>
                            <div style={{ float: 'left' }}>
                                Factory
                            </div>
                            <div style={{ float: 'right' }}>
                                <img src={clearDataFilterPic} alt="Clear" style={{ cursor: 'pointer' }} title='Clear Filter' onClick={() => clearFilter("Factory")}/>
                            </div>
                        </div>
                        <MDBox>
                            <List sx={{ width: '100%', maxWidth: 360, height: "150px", bgcolor: 'background.paper', overflowY: "auto" }}>
                                {JSON.parse(sessionStorage.getItem("srUniqueFactory")).map((fc) => {
                                    return (<ListItem
                                        key={fc}
                                        disablePadding
                                        style={{ "white-space": "nowrap"}}
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleFactoryToggle(fc)} dense>
                                            <ListItemText id={fc} primary={fc} />
                                        </ListItemButton>
                                    </ListItem>)
                                })}
                            </List>
                        </MDBox>
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div>
                        <div style={{ height: "25px", display: "flex" }}>
                            <div style={{ float: 'left' }}>
                                Shipping Terms (FOB/DDP/LDP)
                            </div>
                            <div style={{ float: 'right' }}>
                                <img src={clearDataFilterPic} alt="Clear" style={{ cursor: 'pointer' }} title='Clear Filter' onClick={() => clearFilter("Shipping Terms (FOB/DDP/LDP)")}/>
                            </div>
                        </div>
                        <MDBox>
                            <List sx={{ width: '100%', maxWidth: 360, height: "150px", bgcolor: 'background.paper' }}>
                                {JSON.parse(sessionStorage.getItem("srUniqueShippingTermsFobDdpLdp")).map((st) => {
                                    return (<ListItem
                                        key={st}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={() => handleShippingTermsFobDdpLdpToggle(st)} dense>
                                            <ListItemText id={st} primary={st} />
                                        </ListItemButton>
                                    </ListItem>)
                                })}
                            </List>
                        </MDBox>
                    </div>
                </MDBox>
                */}
            <div className="ag-theme-quartz" id="agGridStl">
                <AgGridReact
                    suppressFieldDotNotation
                    onGridReady={(event) => onGridReady(event)}
                    enableColResize={true}
                    // columnDefs={SummaryReportColDef}
                    columnDefs={newColDef}
                    rowData={SsnData}
                    suppressRowClickSelection={true}
                    suppressHorizontalScroll={false}
                    suppressMenuHide={true}
                    // rowHeight={rowHeight}
                    headerHeight={65}
                    groupHeaderHeight={groupHeaderHeight}
                    getRowStyle={getRowStyle}
                    defaultColDef={{
                        // sortable: true,
                        filter: true,
                        // unSortIcon: true,
                        menuTabs: ['filterMenuTab', 'generalMenuTab'],
                        wrapHeaderText: true,
                        suppressHeaderKeyboardEvent
                    }}
                    allowContextMenuWithControlKey={true}
                    enterNavigatesVertically={true}
                />
            </div>
            {/* </div> */}
        </>
    );
})

SummaryReportTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired
};

export default SummaryReportTab;