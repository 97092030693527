import { xlMround, xlRound } from 'layouts/linelist/XL_Calculation';
import { CH1, checkActionItemAssigned, executeDataVerification, getRowNodeFromId, toastMessage, getColumnStyle, checkHeihlight } from 'layouts/linelist/XL_Utils';
import { GetAutoChanges, compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import PropTypes from "prop-types";
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_dtc_buy_sheet";
const rng_name = "Dtc_Buy_Sheet_Rng";

const DtcBuySheetTab = forwardRef(({ setCurrentGridApi, setActionItemDataFun }, ref) => {
  const dispatch = useDispatch();
  const ActionItemData = useSelector(state => state.ActionItemData);
  const whenplaninglocksetlock = ["Buying Decision", "Buy Method", "Comp SKU", "Retail ST%", "Ecomm ST%", "Stores 2: MIN-MID-MAX", "Store Comp APS", "Calc APS - Store", "APS Adj - Store", "APS Override - Store", "Final APS - Store", "STORES Total FP Sales", "STORES Total MD Sales", "Total Need", "BOP", "Total Store Needs (Less BOP)", "Total Calculated Store Buy (Incl. Min)", "Store Buy Override", "Store Final Buy", "Store Final Buy - LOCKED", "Store Chase Buy", "Store Chase Buy + Store Final Buy", "Min Units Per Store", "MIN STORE BUY", "size runs/store", "AUPS", "Notes RANKS", "ECOMM 2: MIN-MID-MAX", "ECOMM COMP APS", "CALC APS - Ecomm", "APS ADJ - Ecomm", "APS Override", "FINAL APS - Ecomm", "ECOMM TOTAL FP SALES", "ECOMM TOTAL MD SALES", "TOTAL NEEDS ECOMM", "ECOMM BOP", "TOTAL NEEDS ECOMM (LESS BOP)", "Buy Override - Ecomm", "Final Buy - Ecomm", "Final Buy - Ecomm - LOCKED", "Ecomm Chase Buy", "Ecomm Chase Buy + Ecomm Final Buy", "Notes", "PAID", "DTC TOTAL BUY"];
  const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
  const DtcBuySheetData = useSelector(s => s.DtcBuySheetData);
  const [resultData, setResultData] = useState([]);
  const SSNData = useSelector((s) => s.DtcBuySheetDataSSN);
  const rdxHighlistCell = useSelector(s => s.DtcButSheetHighlistCell);
  const [DtcBuySheetColDef, setColumnDef] = useState([]);
  const ColumnDef = useSelector(s => s.DtcBuySheetColDef ? s.DtcBuySheetColDef : []);
  const rdxSorting = useSelector(s => s.DtcBuySheetSorting ? s.DtcBuySheetSorting : []);
  const rdxFilters = useSelector(s => s.DtcBuySheetFilters ? s.DtcBuySheetFilters : {});
  const refBuyShtMasterDataCharts = useSelector(s => s.BuyShtMasterDataChartsData);
  const refBuyShtMasterDataChartsChange = useSelector(s => s.BuyShtMasterDataChartsHighlistCell);
  const refCompApsMasterStoreEcom = useSelector(s => s.CompApsMasterStoreEcomData);
  const refCompApsMasterStoreEcomChange = useSelector(s => s.CompApsMasterStoreEcomHighlistCell);
  const refWhslBuySheet = useSelector(s => s.WhslBuySheetData);
  const refWhslBuySheetChange = useSelector(s => s.WhslBuySheetHighlistCell);
  const refLinelist = useSelector(s => s.LinelistData);
  const refLinelistChange = useSelector(s => s.LineListHighlistCell);
  const refSizeScales = useSelector(s => s.SizeScalesData);
  const refSizeScalesChange = useSelector(s => s.SizeScalesHighlistCell);
  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);
  const footerRowCalCols = ["Store Final Buy", "Final Buy - Ecomm", "DTC TOTAL BUY", "DTC CHASE BUY", "DTC CHASE BUY + DTC TOTAL BUY"]
  const [bottomRowData, setBottomRowData] = useState([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

  const setResetFilter = (setReset) => {
    let sorting = rdxSorting;
    let filtering = rdxFilters;
    if (setReset === "reset") {
      sorting = null;
      filtering = null;
    }
    gridApi.current.applyColumnState({ state: sorting });
    gridApi.current.setFilterModel(filtering);
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!DtcBuySheetData) return false;
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        return (res)
      }
      removeHeihlight();
      cellValueChanged.current = [];
      return ({ status: true })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, DtcBuySheetColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells
        dispatch({ type: "SET_DTC_BY_SHT_DATA", data: res.data });
        gridApi.current.refreshCells({ force: true })
      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      setResetFilter("reset");
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType);
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };
  const calculateStoreCompAPS = (event) => {
    let resultVal = 0;
    let CompSKU = event.data["Comp SKU"] || "";
    let Year = event.data["Year"] || "";
    let SeasonCode = event.data["Season Code"] || "";
    let Department = event.data["Department"] || "";
    if (CompSKU !== "" && Year !== "" && SeasonCode !== "" && Department !== "") {
      const filtredData = refCompApsMasterStoreEcom.find((i) => (`${i["SKU"]}${i["Channel"]}${i["Year"]}${i["Season Code"]}${i["Department"]}`).trim().toLowerCase() === (CompSKU + 'Stores' + Year + SeasonCode + Department).trim().toLowerCase());
      if (filtredData) resultVal = xlRound(parseFloat(filtredData["APS"] || 0), 6);
    }
    return resultVal;
  };
  const calculateCalcAPSStore = (event) => {
    let resultVal = 0;
    let Stores2MINMIDMAX = (event.data["Stores 2: MIN-MID-MAX"] || "").toUpperCase();
    let Category = event.data["Category"] || "";
    let Year = event.data["Year"] || "";
    let SeasonCode = event.data["Season Code"] || "";
    let Department = event.data["Department"] || "";
    if (Stores2MINMIDMAX !== "" && Category !== "" && SeasonCode !== "" && Department !== "" && Year !== "") {
      const filtredData = refBuyShtMasterDataCharts.find((i) => (`${i["Channel"]}${i["Category"]}${i["Season Code"]}${i["Department"]}${i["Year"]}`).trim().toLowerCase() === ('Stores' + Category + SeasonCode + Department + Year).trim().toLowerCase());
      if (Stores2MINMIDMAX === "MIN" && filtredData)
        resultVal = xlRound(parseFloat(filtredData["Min"] || 0), 6);
      else if (Stores2MINMIDMAX === "MID" && filtredData)
        resultVal = xlRound(parseFloat(filtredData["Mid"] || 0), 6);
      else if (Stores2MINMIDMAX === "MAX" && filtredData)
        resultVal = xlRound(parseFloat(filtredData["Max"] || 0), 6);
    }
    return resultVal;
  };
  const calculateFinalAPSStore = (event) => {
    let resultVal = 0;
    let BuyMethod = event.data["Buy Method"] || "";
    let APSOverrideStore = event.data["APS Override - Store"] || "";
    let StoreCompAPS = parseFloat(event.data["Store Comp APS"] || 0);
    let APSAdjStore = parseFloat(event.data["APS Adj - Store"] || 0);
    let CalcAPSStore = parseFloat(event.data["Calc APS - Store"] || 0);

    if (APSOverrideStore !== "") resultVal = xlRound(parseFloat(APSOverrideStore), 6);
    else {
      if (BuyMethod === "1: Comp") resultVal = xlRound((StoreCompAPS * (1 + APSAdjStore)), 6);
      else resultVal = xlRound((CalcAPSStore * (1 + APSAdjStore)), 6);
    }
    return resultVal;
  };
  const calculateSTORESTotalFPSales = (event) => {
    let PlanningLock = event.data["Planning Lock"] || "";
    let WeeksBoughtLocked = parseFloat(event.data["Weeks Bought - Locked"] || 0);
    let WeeksBought = parseFloat(event.data["Weeks Bought"] || 0);
    let FinalAPSStore = parseFloat(event.data["Final APS - Store"] || 0);
    let DoorsBasedonStoreTierLocked = parseFloat(event.data["Doors Based on Store Tier - Locked"] || 0);
    let DoorsBasedonStoreTier = parseFloat(event.data["Doors Based on Store Tier"] || 0);
    if (PlanningLock === "Locked") WeeksBought = WeeksBoughtLocked;
    if (PlanningLock === "Locked") DoorsBasedonStoreTier = DoorsBasedonStoreTierLocked;

    return xlMround(WeeksBought * FinalAPSStore * DoorsBasedonStoreTier, 1);
  };
  const calculateTotalNeed = (event) => {
    let STORESTotalFPSales = parseInt(event.data["STORES Total FP Sales"] || 0);
    let RetailST = parseFloat(event.data["Retail ST%"] || 0);
    return RetailST !== 0 ? xlMround(STORESTotalFPSales / RetailST, 1) : 0;
  };
  const calculateSTORESTotalMDSales = (event) => {
    return xlMround(parseInt(event.data["Total Need"] || 0) - parseInt(event.data["STORES Total FP Sales"] || 0), 1);
  };
  const calculateTotalStoreNeedsLessBOP = (event) => {
    return xlMround(parseFloat(event.data["Total Need"] || 0) - parseFloat(event.data["BOP"] || 0), 1)
  };
  const calculateMinUnitsPerStore = (event) => {
    let resultVal = 0;
    let Category = event.data["Category"] || "";
    let Year = event.data["Year"] || "";
    let SeasonCode = event.data["Season Code"] || "";
    let Department = event.data["Department"] || "";
    if (Category !== "" && Year !== "" && SeasonCode !== "" && Department !== "") {
      const filtredData = refBuyShtMasterDataCharts.find((i) => (`${i["Minimum Category"]}${i["Year"]}${i["Season Code"]}${i["Department"]}${i["Channel"]}`).trim().toLowerCase() === (Category + Year + SeasonCode + Department + 'Stores').trim().toLowerCase());
      if (filtredData) resultVal = xlMround(parseInt(filtredData["Minimum Units"] || 0), 1);
    }
    return resultVal;
  };
  const calculateTotalCalculatedStoreBuyInclMin = (event) => {
    let resultVal = 0;
    let PlanningLock = event.data["Planning Lock"] || "";
    let MinUnitsPerStore = parseFloat(event.data["Min Units Per Store"] || 0);
    let TotalStoreNeedsLessBOP = parseFloat(event.data["Total Store Needs (Less BOP)"] || 0);
    let DoorsBasedonStoreTierLocked = parseFloat(event.data["Doors Based on Store Tier - Locked"] || 0);
    let DoorsBasedonStoreTier = parseFloat(event.data["Doors Based on Store Tier"] || 0);
    let BOP = parseFloat(event.data["BOP"] || 0);
    if (PlanningLock === "Locked") DoorsBasedonStoreTier = DoorsBasedonStoreTierLocked;
    if ((MinUnitsPerStore * DoorsBasedonStoreTier) > TotalStoreNeedsLessBOP)
      resultVal = ((MinUnitsPerStore * DoorsBasedonStoreTier) - BOP);
    else
      resultVal = TotalStoreNeedsLessBOP;

    resultVal = xlMround(resultVal, 1);
    return resultVal;
  };
  const calculateStoreFinalBuy = (event) => {
    const StoreBuyOverride = event.data["Store Buy Override"] || "";
    const TotalCalculatedStoreBuyInclMin = parseInt(event.data["Total Calculated Store Buy (Incl. Min)"] || 0);
    return StoreBuyOverride !== "" ? xlMround(StoreBuyOverride, 1) : xlMround(TotalCalculatedStoreBuyInclMin, 1);
  };
  const calculateStoreChaseBuyFinalBuy = (event) => {
    return parseInt(event.data["Store Final Buy"] || 0) + parseInt(event.data["Store Chase Buy"] || 0)
  };
  const calculateMINSTOREBUY = (event) => {
    const PlanningLock = event.data["Planning Lock"] || "";
    const MinUnitsPerStore = parseInt(event.data["Min Units Per Store"] || 0);
    let DoorsBasedonStoreTier = parseInt(event.data["Doors Based on Store Tier"] || 0);
    const DoorsBasedonStoreTierLocked = parseInt(event.data["Doors Based on Store Tier - Locked"] || 0);
    if (PlanningLock === "Locked") DoorsBasedonStoreTier = DoorsBasedonStoreTierLocked;
    return xlMround(MinUnitsPerStore * DoorsBasedonStoreTier, 1);
  };
  const calculateDoorsBasedonStoreTier = (event) => {
    let resultVal = 0;
    let StoreTier = event.data["Store Tier"] || "";
    let SeasonCode = event.data["Season Code"] || "";
    let Department = event.data["Department"] || "";
    if (StoreTier !== "" && SeasonCode !== "" && Department !== "") {
      const filtredData = refBuyShtMasterDataCharts.find((i) => (`${i["STORE TIER"]}${i["Season Code"]}${i["Department"]}`).trim().toLowerCase() === (StoreTier + SeasonCode + Department).trim().toLowerCase());
      if (filtredData) resultVal = parseInt(filtredData["Doors"] || 0);
    }
    return resultVal;
  };
  const calculateAUPS = (event) => {
    const MINSTOREBUY = parseInt(event.data["MIN STORE BUY"] || 0);
    const DoorsBasedonStoreTier = parseInt(event.data["Doors Based on Store Tier"] || 0);
    return DoorsBasedonStoreTier !== 0 ? xlMround(MINSTOREBUY / DoorsBasedonStoreTier, 1) : 0;
  };
  const calculateFPREVENUE = (event) => {
    return xlRound(parseFloat(event.data["STORES Total FP Sales"] || 0) * parseFloat(event.data["MSRP"] || 0) * parseFloat(event.data["PPN"] || 0), 6);
  };
  const calculateMKDREVENUE = (event) => {
    const STORESTotalMDSales = parseFloat(event.data["STORES Total MD Sales"] || 0);
    const MSRP = parseFloat(event.data["MSRP"] || 0);
    const SeasonCode = event.data["Season Code"] || "";
    const Department = event.data["Department"] || "";
    let Markdown = "";
    if (SeasonCode !== "" && Department !== "") {
      const filtredData = refBuyShtMasterDataCharts.find((i) => (`${i["Season Code"]}${i["Department"]}${i["Markdown Channel"]}`).trim().toLowerCase() === (SeasonCode + Department + 'Stores').trim().toLowerCase());
      if (filtredData) Markdown = parseFloat(filtredData["Markdown %"] || 0);
    }
    return xlRound(STORESTotalMDSales * MSRP * Markdown, 6);
  };
  const calculateSTORESTOTALSALES = (event) => {
    const FPREVENUE = parseFloat(event.data["FP REVENUE"] || 0);
    const MKDREVENUE = parseFloat(event.data["MKD REVENUE"] || 0);
    return xlRound((FPREVENUE + MKDREVENUE).toFixed(10), 6);
  };
  const calculateSTORESTOTALCOST = (event) => {
    const LDP = parseFloat(event.data["LDP"] || 0);
    const StoreFinalBuy = parseFloat(event.data["Store Final Buy"] || 0);
    return xlRound((LDP * StoreFinalBuy).toFixed(10), 6);
  };
  const calculateSTORESTOTALMARGINDLR = (event) => {
    let STORESTOTALSALES = parseFloat(event.data["STORES TOTAL SALES"] || 0);
    let STORESTOTALCOST = parseFloat(event.data["STORES TOTAL COST"] || 0);
    return xlRound((STORESTOTALSALES - STORESTOTALCOST).toFixed(10), 6);
  };
  const calculateSTORESTOTALMARGINPER = (event) => {
    let STORESTOTALSALES = parseFloat(event.data["STORES TOTAL SALES"] || 0);
    let STORESTOTALCOST = parseFloat(event.data["STORES TOTAL COST"] || 0);
    return STORESTOTALSALES !== 0 ? xlRound(((STORESTOTALSALES - STORESTOTALCOST) / STORESTOTALSALES).toFixed(10), 6) : "";
  };
  const calculateECOMMCOMPAPS = (event) => {
    let resultVal = 0;
    const CompSKU = event.data["Comp SKU"] || "";
    const Year = event.data["Year"] || "";
    const SeasonCode = event.data["Season Code"] || "";
    const Department = event.data["Department"] || "";
    if (CompSKU !== "" && Year !== "" && SeasonCode !== "" && Department !== "") {
      const filtredData = refCompApsMasterStoreEcom.find((i) => (`${i["SKU"]}${i["Channel"]}${i["Year"]}${i["Season Code"]}${i["Department"]}`).trim().toLowerCase() === (CompSKU + 'Ecomm' + Year + SeasonCode + Department).trim().toLowerCase());
      if (filtredData) resultVal = xlRound(parseFloat(filtredData["APS"] || 0), 6);
    }
    return resultVal;
  };
  const calculateCALCAPSEcomm = (event) => {
    let resultVal = 0;
    const ECOMM2MINMIDMAX = event.data["ECOMM 2: MIN-MID-MAX"] || "";
    const Category = event.data["Category"] || "";
    const Year = event.data["Year"] || "";
    const SeasonCode = event.data["Season Code"] || "";
    const Department = event.data["Department"] || "";
    if (ECOMM2MINMIDMAX !== "" && Category !== "" && SeasonCode !== "" && Department !== "" && Year !== "") {
      const filtredData = refBuyShtMasterDataCharts.find((i) => (`${i["Channel"]}${i["Category"]}${i["Season Code"]}${i["Department"]}${i["Year"]}`).trim().toLowerCase() === ('Ecomm' + Category + SeasonCode + Department + Year).trim().toLowerCase());
      if (ECOMM2MINMIDMAX.trim().toUpperCase() === "MIN" && filtredData) resultVal = parseFloat(filtredData["Min"] || 0);
      else if (ECOMM2MINMIDMAX.trim().toUpperCase() === "MID" && filtredData) resultVal = parseFloat(filtredData["Mid"] || 0);
      else if (ECOMM2MINMIDMAX.trim().toUpperCase() === "MAX" && filtredData) resultVal = parseFloat(filtredData["Max"] || 0);
      resultVal = xlRound((resultVal).toFixed(10), 6);
    }
    return resultVal;
  };
  const calculateFINALAPSEcomm = (event) => {
    let resultVal = 0;
    const APSOverride = event.data["APS Override"] || "";
    const BuyMethod = event.data["Buy Method"] || "";
    const ECOMMCOMPAPS = parseFloat(event.data["ECOMM COMP APS"] || 0);
    const CALCAPSEcomm = parseFloat(event.data["CALC APS - Ecomm"] || 0);
    const APSADJEcomm = parseFloat(event.data["APS ADJ - Ecomm"] || 0);
    let aps_value = 0;
    if (APSOverride === "") {
      if (BuyMethod === "1: Comp") { aps_value = ECOMMCOMPAPS } else { aps_value = CALCAPSEcomm }
      resultVal = xlRound(((aps_value * (1 + APSADJEcomm))).toFixed(10), 6);
    }
    else resultVal = xlRound(parseFloat(APSOverride), 6);
    return resultVal;
  };
  const calculateECOMMTOTALFPSALES = (event) => {
    const PlanningLock = event.data["Planning Lock"] || "";
    const FINALAPSEcomm = parseFloat(event.data["FINAL APS - Ecomm"] || 0);
    const WeeksBoughtLocked = parseFloat(event.data["Weeks Bought - Locked"] || 0);
    let WeeksBought = parseFloat(event.data["Weeks Bought"] || 0);
    if (PlanningLock === "Locked") WeeksBought = WeeksBoughtLocked;
    return xlRound(FINALAPSEcomm * WeeksBought, 6);
  };
  const calculateTOTALNEEDSECOMM = (event) => {
    let ECOMMTOTALFPSALES = parseFloat(event.data["ECOMM TOTAL FP SALES"] || 0);
    let EcommST = parseFloat(event.data["Ecomm ST%"] || 0);
    return EcommST !== 0 ? xlMround(ECOMMTOTALFPSALES / EcommST, 1) : 0;
  };
  const calculateECOMMTOTALMDSALES = (event) => {
    return xlMround(parseFloat(event.data["TOTAL NEEDS ECOMM"] || 0) - parseFloat(event.data["ECOMM TOTAL FP SALES"] || 0), 1);
  };
  const calculateTOTALNEEDSECOMMLESSBOP = (event) => {
    return xlRound(parseFloat(event.data["TOTAL NEEDS ECOMM"] || 0) - parseFloat(event.data["ECOMM BOP"] || 0), 6);
  };
  const calculateFinalBuyEcomm = (event) => {
    const BuyOverrideEcomm = event.data["Buy Override - Ecomm"] || "";
    const TOTALNEEDSECOMMLESSBOP = parseFloat(event.data["TOTAL NEEDS ECOMM (LESS BOP)"] || 0);
    return xlMround(BuyOverrideEcomm === "" ? TOTALNEEDSECOMMLESSBOP : BuyOverrideEcomm, 1);
  };
  const calculateEcommChaseBuyEcommFinalBuy = (event) => {
    return xlRound(parseFloat(event.data["Final Buy - Ecomm"] || 0) + parseFloat(event.data["Ecomm Chase Buy"] || 0), 0);
  };
  const calculateECOMMFPREVENUE = (event) => {
    const ECOMMTOTALFPSALES = parseFloat(event.data["ECOMM TOTAL FP SALES"] || 0);
    const MSRP = parseFloat(event.data["MSRP"] || 0);
    const COLORFAMILY = parseFloat(event.data["COLOR FAMILY"] || 0);
    return xlRound(ECOMMTOTALFPSALES * MSRP * COLORFAMILY, 6);
  };
  const calculateECOMMMKDREVENUE = (event) => {
    let ECOMMTOTALMDSALES = parseFloat(event.data["ECOMM TOTAL MD SALES"] || 0);
    let MSRP = parseFloat(event.data["MSRP"] || 0);
    let SeasonCode = event.data["Season Code"] || "";
    let Department = event.data["Department"] || "";
    let Markdown = 0;
    if (SeasonCode !== "" && Department !== "") {
      const filtredData = refBuyShtMasterDataCharts.find((i) => (`${i["Season Code"]}${i["Department"]}${i["Markdown Channel"]}`).trim().toLowerCase() === (SeasonCode + Department + 'Ecomm').trim().toLowerCase());
      if (filtredData) Markdown = parseFloat(filtredData["Markdown %"] || 0);
    }
    return xlRound((Markdown * MSRP * ECOMMTOTALMDSALES).toFixed(10), 6);
  };
  const calculateECOMMTOTALSALES = (event) => {
    return xlRound((parseFloat(event.data["ECOMM MKD REVENUE"] || 0) + parseFloat(event.data["ECOMM FP REVENUE"] || 0)).toFixed(10), 6)
  };
  const calculateECOMMTOTALCOST = (event) => {
    return xlRound((parseFloat(event.data["Final Buy - Ecomm"] || 0) * parseFloat(event.data["LDP"] || 0)).toFixed(10), 6);
  };
  const calculateECOMMTOTALMARGINDLR = (event) => {
    return xlRound((parseFloat(event.data["ECOMM TOTAL SALES"] || 0) - parseFloat(event.data["ECOMM TOTAL COST"] || 0)).toFixed(10), 6);
  };
  const calculateECOMMTOTALMARGINPER = (event) => {
    const ECOMMTOTALSALES = parseFloat(event.data["ECOMM TOTAL SALES"] || 0);
    const ECOMMTOTALCOST = parseFloat(event.data["ECOMM TOTAL COST"] || 0);
    return ECOMMTOTALSALES !== 0 ? xlRound(((ECOMMTOTALSALES - ECOMMTOTALCOST) / ECOMMTOTALSALES).toFixed(10), 6) : "";
  };
  const calculateDTCTOTALBUY = (event) => {
    return xlMround(parseFloat(event.data["Store Final Buy"] || 0) + parseFloat(event.data["Final Buy - Ecomm"] || 0), 1);
  };
  const calculateWHOLESALEBUY = (event) => {
    let resultVal = 0;
    const Year = event.data["Year"] || "";
    const SeasonCode = event.data["Season Code"] || "";
    const Department = event.data["Department"] || "";
    const DeliveryMonth = event.data["Delivery Month"] || "";
    const Category = event.data["Category"] || "";
    const Subcategory = event.data["Subcategory"] || "";
    const SKU = event.data["SKU"] || "";
    if (Year !== "" && SeasonCode !== "" && Department !== "" && Category !== "" && Subcategory !== "" && SKU !== "") {
      const filtredData = refWhslBuySheet.find((i) => (`${i["Year"]}${i["Season Code"]}${i["Department"]}${i["Delivery Month"]}${i["Category"]}${i["Subcategory"]}${i["SKU"]}`).trim().toLowerCase() === (Year + SeasonCode + Department + DeliveryMonth + Category + Subcategory + SKU).trim().toLowerCase());
      if (filtredData) resultVal = xlRound(parseFloat(filtredData["Total Wholesale Units"] || 0), 6);
    }
    return resultVal;
  };
  const calculateTOTALBRANDBUY = (event) => {
    return xlRound(parseFloat(event.data["DTC TOTAL BUY"] || 0) + parseFloat(event.data["WHOLESALE BUY"] || 0), 6);
  };
  const calculateDTCCHASEBUYDTCTOTALBUY = (event) => {
    return xlRound(parseFloat(event.data["DTC CHASE BUY"] || 0) + parseFloat(event.data["DTC TOTAL BUY"] || 0), 0);
  };
  // const calculateMOQ = (event) => {
  //   let resultVal = 0;
  //   const Year = event.data["Year"] || "";
  //   const SeasonCode = event.data["Season Code"] || "";
  //   const Department = event.data["Department"] || "";
  //   const DeliveryMonth = event.data["Delivery Month"] || "";
  //   const Style = event.data["Style"] || "";
  //   const ColorCode = event.data["Color Code"] || "";
  //   if (Style !== "" && ColorCode !== "" && Department !== "" && SeasonCode !== "" && Year !== "") {
  //     const filtredData = refLinelist.find((i) => (`${i["Style"]}${i["Color Code"]}${i["Department"]}${i["Delivery Month"]}${i["Season Code"]}${i["Year"]}`).trim().toLowerCase() === (Style + ColorCode + Department + DeliveryMonth + SeasonCode + Year).trim().toLowerCase());
  //     if (filtredData) resultVal = xlRound(parseFloat(filtredData["MOQ"] || 0), 0);
  //   }
  //   return resultVal;
  // };
  const calculateADDITIONALNEEDTOHITMOQ = (event) => {
    const MOQ = parseInt(event.data["MOQ"] || 0);
    const TOTALBRANDBUY = parseInt(event.data["TOTAL BRAND BUY"] || 0);
    return xlRound(MOQ > TOTALBRANDBUY ? MOQ - TOTALBRANDBUY : 0, 6);
  };
  const calculateTTLDTC = (event) => {
    return xlRound(parseFloat(event.data["MSRP"] || 0) * parseFloat(event.data["DTC TOTAL BUY"] || 0), 0);
  };
  const calculateTTLWHSL = (event) => {
    return xlRound(parseFloat(event.data["WHOLESALE BUY"] || 0) * parseFloat(event.data["WHLS"] || 0), 0);
  };
  const calculateTTLBRAND = (event) => {
    return xlRound(parseFloat(event.data["TTL DTC $"] || 0) + parseFloat(event.data["TTL WHSL $"] || 0), 6);
  };
  const calculateTOTALDTCCOST = (event) => {
    return xlRound((parseFloat(event.data["DTC TOTAL BUY"] || 0) * parseFloat(event.data["LDP"] || 0)).toFixed(10), 6);
  };
  const calculateTOTALWHSLCOST = (event) => {
    return xlRound((parseFloat(event.data["WHOLESALE BUY"] || 0) * parseFloat(event.data["LDP"] || 0)).toFixed(10), 6);
  };
  const calculateTOTALBRANDCOST = (event) => {
    return xlRound((parseFloat(event.data["TOTAL BRAND BUY"] || 0) * parseFloat(event.data["LDP"] || 0)).toFixed(10), 6);
  };
  const calculateTOTALDTCMARGIN = (event) => {
    const TTLDTC = parseFloat(event.data["TTL DTC $"] || 0);
    const TOTALDTCCOST = parseFloat(event.data["TOTAL DTC COST"] || 0);
    return TTLDTC !== 0 ? xlRound(((TTLDTC - TOTALDTCCOST) / TTLDTC).toFixed(10), 6) : 0;
  };
  const calculateTOTALWHSLMARGIN = (event) => {
    const TTLWHSL = parseFloat(event.data["TTL WHSL $"] || 0);
    const TOTALWHSLCOST = parseFloat(event.data["TOTAL WHSL COST"] || 0);
    return TTLWHSL !== 0 ? xlRound(((TTLWHSL - TOTALWHSLCOST) / TTLWHSL).toFixed(10), 6) : 0;
  };
  const calculateTOTALBRANDMARGIN = (event) => {
    const TTLBRAND = parseFloat(event.data["TTL BRAND $"] || 0);
    const TOTALBRANDCOST = parseFloat(event.data["TOTAL BRAND COST"] || 0);
    return TTLBRAND !== 0 ? xlRound(((TTLBRAND - TOTALBRANDCOST) / TTLBRAND).toFixed(10), 6) : 0;
  };
  const calculatesizerunsstore = (event) => {
    const MINSTOREBUY = parseFloat(event.data["MIN STORE BUY"] || 0);
    const SizeScale = (event.data["Size Scale"] || "").trim().toLowerCase();
    const PlanningLock = event.data["Planning Lock"] || "";
    const DoorsBasedonStoreTierLocked = parseFloat(event.data["Doors Based on Store Tier - Locked"] || 0);
    let DoorsBasedonStoreTier = parseFloat(event.data["Doors Based on Store Tier"] || 0);
    let rslt = 0
    let ttl_sz = 0;
    let sz_value = "";
    let sz_rslt = 0;
    if (PlanningLock === "Locked") { DoorsBasedonStoreTier = DoorsBasedonStoreTierLocked }
    const filtredData = refSizeScales.find((i) => (`${i["Range"]}`).trim().toLowerCase() === SizeScale);
    for (let i = 1; i <= 36; i++) {
      let valSize = ''
      if (i <= 16) {
        if (i < 10) valSize = 'Valid Size 0' + i
        else valSize = 'Valid Size ' + i
      }
      else {
        if ((i - 16) < 10) valSize = 'Associated Size Scale 0' + (i - 16)
        else valSize = 'Associated Size Scale ' + (i - 16)
      }
      if (filtredData) sz_value = filtredData[valSize] || "";
      if (sz_value !== "") {
        ttl_sz = ttl_sz + 1
      }
    }
    if (parseFloat(ttl_sz) !== 0) {
      sz_rslt = (MINSTOREBUY / parseFloat(ttl_sz));
      rslt = (parseFloat(sz_rslt) / DoorsBasedonStoreTier)
      if (rslt !== 0) { rslt = (parseFloat(sz_rslt) / DoorsBasedonStoreTier).toFixed(10) }
      rslt = xlRound(rslt, 6);
      if (rslt === null || rslt === undefined || rslt === "" || isNaN(rslt)) { rslt = 0 }
    }
    return rslt;
  };
  const calculateStoreTierLocked = (event) => {
    const StoreTier = (event.data["Store Tier"] || "");
    const PlanningLock = (event.data["Planning Lock"] || "").toLowerCase();
    return ["master lock - buy", "locked"].includes(PlanningLock) ? StoreTier : "";
  };
  const calculateDoorsBasedOnStoreTier = (event) => {
    const DoorsBasedonStoreTier = parseInt(event.data["Doors Based on Store Tier"] || 0);
    const PlanningLock = (event.data["Planning Lock"] || "").toLowerCase();
    return ["master lock - buy", "locked"].includes(PlanningLock) ? xlMround(DoorsBasedonStoreTier, 1) : "";
  };
  const calculateWeeksBoughtLocked = (event) => {
    const WeeksBought = (event.data["Weeks Bought"] || "");
    const PlanningLock = (event.data["Planning Lock"] || "").toLowerCase();
    return ["master lock - buy", "locked"].includes(PlanningLock) ? WeeksBought : "";
  };
  const isEditableDTCBuySheet = function (params, field, editable, insertEditable) {
    if (params.node.data["Planning Lock"] === "Master Lock - Buy" && whenplaninglocksetlock.includes(field)) return false;
    else return editable;
  };
  const funCellStyle = (params, col) => {
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    const editable = isEditableDTCBuySheet(params, col.field, col.editable, col.insert_editable);
    if (editable) bgColor = "#FFFFFF";
    else bgColor = "#F0F0F0";
    if (col.type === "dropdown" && editable) bgColor = "#FDFCEC";

    // custom code
    const customCode = getColumnStyle(ColumnDef, value, params, col);
    if (customCode.status) {
      if (customCode.bgColor && customCode.bgColor !== "") bgColor = customCode.bgColor;
      if (customCode.bdrColor && customCode.bdrColor !== "") bdrColor = customCode.bdrColor;
      if (customCode.txtDeco && customCode.txtDeco !== "") txtDeco = customCode.txtDeco;
      if (customCode.fontColor && customCode.fontColor !== "") fontColor = customCode.fontColor;
      if (customCode.boldTxt && customCode.boldTxt !== "") boldTxt = customCode.boldTxt;
      if (customCode.borderBottom && customCode.borderBottom !== "") borderBottom = customCode.borderBottom;
      if (customCode.fontStyle && customCode.fontStyle !== "") fontStyle = customCode.fontStyle;
    }

    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold" }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold" }

    let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
    let obj = checkHeihlight(key, highlightCells.current)
    if (obj.status === true) {
      if (obj.colorCode == 1) bgColor = "cyan";
      else if (obj.colorCode == 2) bgColor = "#d5a7f2";
      else if (obj.colorCode == 3) bdrColor = "#2196f3";
      else if (obj.colorCode === 4) bdrColor = "#9B6155";
    }
    if (params.node.data["Planning Lock"] === "Master Lock - Buy") bgColor = "#A9D08E";
    if (params.node.data["Working Line"] === "Working") bgColor = "#FFE699";

    if (checkActionItemAssigned(ActionItemData, params, tbl_name)) {
      borderBottom = "purple"; boldTxt = "bold";
    }
    if (bdrColor !== "")
      return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor }
    else
      return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom }
  };
  useEffect(() => {
    if (resultData.length !== 0 && resultData.length >= DtcBuySheetData.length) {
      dispatch({ type: "SET_DTC_BY_SHT_DATA", data: JSON.parse(JSON.stringify(resultData)) });
    }
  }, [resultData])
 
  useEffect(() => {
    if (!refBuyShtMasterDataCharts || Object.keys(gridApi.current).length <= 0) return
    if (!refBuyShtMasterDataChartsChange || refBuyShtMasterDataChartsChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refBuyShtMasterDataChartsChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refBuyShtMasterDataCharts.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      const data = event.data
      if (columns.includes("Min") || columns.includes("Mid") || columns.includes("Max")) {
        if (data["Category"] && data["Season Code"] && data["Department"] && data["Year"]) {
          const ch = changes.filter((element) => element["Category"].toString().toLowerCase() === data["Category"].toString().toLowerCase() &&
            element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === data["Department"].toLowerCase() &&
            element["Year"].toLowerCase() === data["Year"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Calc APS - Store")) {
              event.data["Calc APS - Store"] = calculateCalcAPSStore(event);
              arr.push({ [tbl_name + "|Calc APS - Store|" + row_id]: 1 });
            }
            if (fxcalc("CALC APS - Ecomm")) {
              event.data["CALC APS - Ecomm"] = calculateCALCAPSEcomm(event);
              arr.push({ [tbl_name + "|CALC APS - Ecomm|" + row_id]: 1 });
            }
          }
        }
      }
      if (columns.includes("Minimum Units") && fxcalc("Min Units Per Store")) {
        if (data["Category"] && data["Season Code"] && data["Department"] && data["Year"]) {
          const ch = changes.filter((element) => element["Minimum Category"].toString().toLowerCase() === data["Category"].toString().toLowerCase() &&
            element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === data["Department"].toLowerCase() &&
            element["Year"].toLowerCase() === data["Year"].toLowerCase())
          if (ch.length > 0) {
            event.data["Min Units Per Store"] = calculateMinUnitsPerStore(event);
            arr.push({ [tbl_name + "|Min Units Per Store|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Doors") && fxcalc("Doors Based on Store Tier")) {
        if (data["Store Tier"] && data["Season Code"] && data["Department"]) {
          const ch = changes.filter((element) => element["STORE TIER"].toString().toLowerCase() === data["Store Tier"].toString().toLowerCase() &&
            element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === data["Department"].toLowerCase())
          if (ch.length > 0) {
            event.data["Doors Based on Store Tier"] = calculateDoorsBasedonStoreTier(event);
            arr.push({ [tbl_name + "|Doors Based on Store Tier|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Markdown %")) {
        if (data["Season Code"] && data["Department"]) {
          const ch = changes.filter((element) => element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === data["Department"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("MKD REVENUE")) {
              event.data["MKD REVENUE"] = calculateMKDREVENUE(event);
              arr.push({ [tbl_name + "|MKD REVENUE|" + row_id]: 1 });
            }
            if (fxcalc("ECOMM MKD REVENUE")) {
              event.data["ECOMM MKD REVENUE"] = calculateECOMMMKDREVENUE(event);
              arr.push({ [tbl_name + "|ECOMM MKD REVENUE|" + row_id]: 1 });
            }
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refBuyShtMasterDataCharts, refBuyShtMasterDataChartsChange]);
  useEffect(() => {
    if (!refCompApsMasterStoreEcom || Object.keys(gridApi.current).length <= 0) return
    if (!refCompApsMasterStoreEcomChange || refCompApsMasterStoreEcomChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refCompApsMasterStoreEcomChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refCompApsMasterStoreEcom.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      const data = event.data
      if (columns.includes("APS")) {
        if (data["SKU"] && data["Season Code"] && data["Department"] && data["Year"]) {
          const ch = changes.filter((element) => element["SKU"].toString().toLowerCase() === data["SKU"].toString().toLowerCase() &&
            element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === data["Department"].toLowerCase() &&
            element["Year"].toLowerCase() === data["Year"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Store Comp APS")) {
              event.data["Store Comp APS"] = calculateStoreCompAPS(event);
              arr.push({ [tbl_name + "|Store Comp APS|" + row_id]: 1 });
            }
            if (fxcalc("ECOMM COMP APS")) {
              event.data["ECOMM COMP APS"] = calculateECOMMCOMPAPS(event);
              arr.push({ [tbl_name + "|ECOMM COMP APS|" + row_id]: 1 });
            }
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refCompApsMasterStoreEcom, refCompApsMasterStoreEcomChange]);
  useEffect(() => {
    if (!refWhslBuySheet || Object.keys(gridApi.current).length <= 0) return
    if (!refWhslBuySheetChange || refWhslBuySheetChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refWhslBuySheetChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refWhslBuySheet.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      const data = event.data
      if (columns.includes("Total Wholesale Units") && fxcalc("WHOLESALE BUY")) {
        if (data["Category"] && data["Season Code"] && data["Department"] && data["Year"] && data["Delivery Month"] && data["Subcategory"] && data["SKU"]) {
          const ch = changes.filter((element) => element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
            element["Department"].toLowerCase() === data["Department"].toLowerCase() &&
            element["Year"].toLowerCase() === data["Year"].toLowerCase() &&
            element["SKU"].toLowerCase() === data["SKU"].toLowerCase() &&
            element["Category"].toLowerCase() === data["Category"].toLowerCase() &&
            element["Subcategory"].toLowerCase() === data["Subcategory"].toLowerCase() &&
            element["Delivery Month"].toLowerCase() === data["Delivery Month"].toLowerCase())
          if (ch.length > 0) {
            event.data["WHOLESALE BUY"] = calculateWHOLESALEBUY(event);
            arr.push({ [tbl_name + "|WHOLESALE BUY|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refWhslBuySheet, refWhslBuySheetChange]);
  // useEffect(() => {
  //   if (!refLinelist || Object.keys(gridApi.current).length <= 0) return
  //   if (!refLinelistChange || refLinelistChange.length <= 0) return
  //   setResetFilter("reset")
  //   const arr = highlightCells.current
  //   const changes = []
  //   const columns = []
  //   refLinelistChange.map((element) => {
  //     const keys = Object.keys(element);
  //     const key = keys[0]
  //     const tmp = key.split("|")
  //     const dt = refLinelist.filter((item) => item.row_id === tmp[2])
  //     changes.push(dt[0])
  //     columns.push(tmp[1])
  //   })
  //   gridApi.current.forEachNode((event) => {
  //     const row_id = event.data.row_id
  //     const data = event.data
  //     if (columns.includes("MOQ")) {
  //       if (data["Style"] && data["Season Code"] && data["Department"] && data["Year"] && data["Delivery Month"] && data["Color Code"] && data["SKU"]) {
  //         const ch = changes.filter((element) => element["Season Code"].toLowerCase() === data["Season Code"].toLowerCase() &&
  //           element["Department"].toLowerCase() === data["Department"].toLowerCase() &&
  //           element["Year"].toLowerCase() === data["Year"].toLowerCase() &&
  //           element["SKU"].toLowerCase() === data["SKU"].toLowerCase() &&
  //           element["Style"].toLowerCase() === data["Style"].toLowerCase() &&
  //           element["Color Code"].toLowerCase() === data["Color Code"].toLowerCase() &&
  //           element["Delivery Month"].toLowerCase() === data["Delivery Month"].toLowerCase())
  //         if (ch.length > 0) {
  //           event.data["MOQ"] = calculateMOQ(event);
  //           arr.push({ [tbl_name + "|MOQ|" + row_id]: 1 })
  //         }
  //       }
  //     }
  //   })
  //   setResetFilter("set")
  //   highlightCells.current = arr
  //   gridApi.current.refreshCells({ force: true });
  // }, [refLinelist, refLinelistChange]);
  useEffect(() => {
    if (!refSizeScales || Object.keys(gridApi.current).length <= 0) return
    if (!refSizeScalesChange || refSizeScalesChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refSizeScalesChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refSizeScales.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("MOQ") && fxcalc("size runs/store")) {
        if (data["Size Scale"]) {
          const ch = changes.filter((element) => element["Range"].toString().toLowerCase() === data["Size Scale"].toString().toLowerCase())
          if (ch.length > 0) {
            event.data["size runs/store"] = calculatesizerunsstore(event);
            arr.push({ [tbl_name + "|size runs/store|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refSizeScales, refSizeScalesChange]);
  useEffect(() => {
    if (isGridReady) {
      if (rdxSorting !== null && rdxSorting !== undefined) {
        gridApi.current.applyColumnState({
          state: rdxSorting
        })
      }
      if (rdxFilters !== null && rdxFilters !== undefined) {
        gridApi.current.setFilterModel(rdxFilters);
      }
      calcBottomRow();
    }
  }, [isGridReady])
  useEffect(() => {
    console.log("DtcBuySheetData", DtcBuySheetData);
    console.log("ColumnDef", ColumnDef);
    if (ColumnDef && ColumnDef.length > 0 && DtcBuySheetColDef.length === 0) {
      let temp = ColumnDef;
      temp = temp.map((col) => {
        let mpCol = col
        mpCol = {
          headerName: col.headerName,
          field: col.field,
          colId: col.colId,
          width: col.wdth,
          editable: params => isEditableDTCBuySheet(params, col.field, col.editable, col.insert_editable),
          hide: col.hide,
          myType: col.type,
          autoHeaderHeight: true,
          cellEditor: col.type === "dropdown" || col.type === "date" ? col.cellEditor : "",
          cellEditorParams: col.type === "dropdown" || col.type === "date" ? col.cellEditorParams : "",
          suppressKeyboardEvent: col.suppressKeyboardEvent,
          filter: col.filter,
          filterParams: col.filterParams,
          width: col.width,
          resizable: col.resizable,
          wrapText: col.wrapText,
          cellStyle: params => { return funCellStyle(params, col) },
          valueFormatter: col.valueFormatter,
          suppressColumnsToolPanel: col.hide,
          valueParser: col.valueParser,
          cellClass: col.type === "dropdown" ? "" : col.type
        }

        return mpCol;
      })

      setColumnDef(temp)
    }
    if (isGridReady) {
      calculateAllFormulas();
      calcBottomRow();
    }
  }, [DtcBuySheetData])
  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params)

    calculateAllFormulas()
    gridApi.current.refreshCells({ force: true })
    setIsGridReady(true)
  };
  const fxcalc = (event, colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    if (event.data["Planning Lock"] === "Master Lock - Buy" && whenplaninglocksetlock.includes(colnm)) retval = false;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (sessionStorage.getItem("user_type") === "vendor" || sessionStorage.getItem("user_type") === "read-only") {
      dispatch({ type: "SET_PLEASE_WAIT", data: false });
      return;
    }
    gridApi.current.forEachNode((event) => {
      calculateReactFormula(event)
    });
  };
  const calculateReactFormula = (event) => {
    if (!event.data) return "";
    fxcalc(event, "Store Comp APS") ? event.data["Store Comp APS"] = calculateStoreCompAPS(event) : null;
    fxcalc(event, "Calc APS - Store") ? event.data["Calc APS - Store"] = calculateCalcAPSStore(event) : null;
    fxcalc(event, "Final APS - Store") ? event.data["Final APS - Store"] = calculateFinalAPSStore(event) : null;
    fxcalc(event, "STORES Total FP Sales") ? event.data["STORES Total FP Sales"] = calculateSTORESTotalFPSales(event) : null;
    fxcalc(event, "Total Need") ? event.data["Total Need"] = calculateTotalNeed(event) : null;
    fxcalc(event, "STORES Total MD Sales") ? event.data["STORES Total MD Sales"] = calculateSTORESTotalMDSales(event) : null;
    fxcalc(event, "Total Store Needs (Less BOP)") ? event.data["Total Store Needs (Less BOP)"] = calculateTotalStoreNeedsLessBOP(event) : null;
    fxcalc(event, "Min Units Per Store") ? event.data["Min Units Per Store"] = calculateMinUnitsPerStore(event) : null;
    fxcalc(event, "Total Calculated Store Buy (Incl. Min)") ? event.data["Total Calculated Store Buy (Incl. Min)"] = calculateTotalCalculatedStoreBuyInclMin(event) : null;
    fxcalc(event, "Store Final Buy") ? event.data["Store Final Buy"] = calculateStoreFinalBuy(event) : null;
    fxcalc(event, "Store Chase Buy + Store Final Buy") ? event.data["Store Chase Buy + Store Final Buy"] = calculateStoreChaseBuyFinalBuy(event) : null;
    fxcalc(event, "MIN STORE BUY") ? event.data["MIN STORE BUY"] = calculateMINSTOREBUY(event) : null;
    fxcalc(event, "AUPS") ? event.data["AUPS"] = calculateAUPS(event) : null;
    fxcalc(event, "ECOMM COMP APS") ? event.data["ECOMM COMP APS"] = calculateECOMMCOMPAPS(event) : null;
    fxcalc(event, "CALC APS - Ecomm") ? event.data["CALC APS - Ecomm"] = calculateCALCAPSEcomm(event) : null;
    fxcalc(event, "FINAL APS - Ecomm") ? event.data["FINAL APS - Ecomm"] = calculateFINALAPSEcomm(event) : null;
    fxcalc(event, "ECOMM TOTAL FP SALES") ? event.data["ECOMM TOTAL FP SALES"] = calculateECOMMTOTALFPSALES(event) : null;
    fxcalc(event, "TOTAL NEEDS ECOMM") ? event.data["TOTAL NEEDS ECOMM"] = calculateTOTALNEEDSECOMM(event) : null;
    fxcalc(event, "ECOMM TOTAL MD SALES") ? event.data["ECOMM TOTAL MD SALES"] = calculateECOMMTOTALMDSALES(event) : null;
    fxcalc(event, "TOTAL NEEDS ECOMM (LESS BOP)") ? event.data["TOTAL NEEDS ECOMM (LESS BOP)"] = calculateTOTALNEEDSECOMMLESSBOP(event) : null;
    fxcalc(event, "Final Buy - Ecomm") ? event.data["Final Buy - Ecomm"] = calculateFinalBuyEcomm(event) : null;
    fxcalc(event, "Ecomm Chase Buy + Ecomm Final Buy") ? event.data["Ecomm Chase Buy + Ecomm Final Buy"] = calculateEcommChaseBuyEcommFinalBuy(event) : null;
    fxcalc(event, "DTC TOTAL BUY") ? event.data["DTC TOTAL BUY"] = calculateDTCTOTALBUY(event) : null;
    // fxcalc(event, "MOQ") ? event.data["MOQ"] = calculateMOQ(event) : null;
    fxcalc(event, "size runs/store") ? event.data["size runs/store"] = calculatesizerunsstore(event) : null;
    //
    fxcalc(event, "Doors Based on Store Tier") ? event.data["Doors Based on Store Tier"] = calculateDoorsBasedonStoreTier(event) : null;
    fxcalc(event, "FP REVENUE") ? event.data["FP REVENUE"] = calculateFPREVENUE(event) : null;
    fxcalc(event, "MKD REVENUE") ? event.data["MKD REVENUE"] = calculateMKDREVENUE(event) : null;
    fxcalc(event, "STORES TOTAL SALES") ? event.data["STORES TOTAL SALES"] = calculateSTORESTOTALSALES(event) : null;
    fxcalc(event, "STORES TOTAL COST") ? event.data["STORES TOTAL COST"] = calculateSTORESTOTALCOST(event) : null;
    fxcalc(event, "STORES TOTAL MARGIN $") ? event.data["STORES TOTAL MARGIN $"] = calculateSTORESTOTALMARGINDLR(event) : null;
    fxcalc(event, "STORES TOTAL MARGIN %") ? event.data["STORES TOTAL MARGIN %"] = calculateSTORESTOTALMARGINPER(event) : null;
    fxcalc(event, "ECOMM FP REVENUE") ? event.data["ECOMM FP REVENUE"] = calculateECOMMFPREVENUE(event) : null;
    fxcalc(event, "ECOMM MKD REVENUE") ? event.data["ECOMM MKD REVENUE"] = calculateECOMMMKDREVENUE(event) : null;
    fxcalc(event, "ECOMM TOTAL SALES") ? event.data["ECOMM TOTAL SALES"] = calculateECOMMTOTALSALES(event) : null;
    fxcalc(event, "ECOMM TOTAL COST") ? event.data["ECOMM TOTAL COST"] = calculateECOMMTOTALCOST(event) : null;
    fxcalc(event, "ECOMM TOTAL MARGIN $") ? event.data["ECOMM TOTAL MARGIN $"] = calculateECOMMTOTALMARGINDLR(event) : null;
    fxcalc(event, "ECOMM TOTAL MARGIN %") ? event.data["ECOMM TOTAL MARGIN %"] = calculateECOMMTOTALMARGINPER(event) : null;
    fxcalc(event, "WHOLESALE BUY") ? event.data["WHOLESALE BUY"] = calculateWHOLESALEBUY(event) : null;
    fxcalc(event, "TOTAL BRAND BUY") ? event.data["TOTAL BRAND BUY"] = calculateTOTALBRANDBUY(event) : null;
    fxcalc(event, "DTC CHASE BUY + DTC TOTAL BUY") ? event.data["DTC CHASE BUY + DTC TOTAL BUY"] = calculateDTCCHASEBUYDTCTOTALBUY(event) : null;
    fxcalc(event, "ADDITIONAL NEED TO HIT MOQ") ? event.data["ADDITIONAL NEED TO HIT MOQ"] = calculateADDITIONALNEEDTOHITMOQ(event) : null;
    fxcalc(event, "TTL DTC $") ? event.data["TTL DTC $"] = calculateTTLDTC(event) : null;
    fxcalc(event, "TTL WHSL $") ? event.data["TTL WHSL $"] = calculateTTLWHSL(event) : null;
    fxcalc(event, "TTL BRAND $") ? event.data["TTL BRAND $"] = calculateTTLBRAND(event) : null;
    fxcalc(event, "TOTAL DTC COST") ? event.data["TOTAL DTC COST"] = calculateTOTALDTCCOST(event) : null;
    fxcalc(event, "TOTAL WHSL COST") ? event.data["TOTAL WHSL COST"] = calculateTOTALWHSLCOST(event) : null;
    fxcalc(event, "TOTAL BRAND COST") ? event.data["TOTAL BRAND COST"] = calculateTOTALBRANDCOST(event) : null;
    fxcalc(event, "TOTAL DTC  MARGIN") ? event.data["TOTAL DTC  MARGIN"] = calculateTOTALDTCMARGIN(event) : null;
    fxcalc(event, "TOTAL WHSL MARGIN") ? event.data["TOTAL WHSL MARGIN"] = calculateTOTALWHSLMARGIN(event) : null;
    fxcalc(event, "TOTAL BRAND MARGIN") ? event.data["TOTAL BRAND MARGIN"] = calculateTOTALBRANDMARGIN(event) : null;
    fxcalc(event, "Store Tier - Locked") ? event.data["Store Tier - Locked"] = calculateStoreTierLocked(event) : null;
    fxcalc(event, "Doors Based on Store Tier - Locked") ? event.data["Doors Based on Store Tier - Locked"] = calculateDoorsBasedOnStoreTier(event) : null;
    fxcalc(event, "Weeks Bought - Locked") ? event.data["Weeks Bought - Locked"] = calculateWeeksBoughtLocked(event) : null;

  };
  const onSortChanged = (event) => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_DTC_BY_SHT_SORTING", data: savedSortState });
  };
  const onFilterChanged = (event) => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_DTC_BY_SHT_FILTERS", data: savedFilterModel });
    calcBottomRow();
  };
  const calcBottomRow = (latest = []) => {
    // set default blank/0 value       
    const result = {};
    footerRowCalCols.forEach(column => {
      if (!result[column]) result[column] = { btmRow1: 0, btmRow2: 0 };
    });
    let filtredData = [];
    try {
      gridApi.current.forEachNodeAfterFilter(node => {
        filtredData.push(node.data);
      });
    } catch { filtredData = DtcBuySheetData; }
    // filtredData = filtredData.filter((i) => i["Style Activity"] === "ACTIVE");
    filtredData = filtredData.filter((i) => (i["Style Activity"]).trim().toLowerCase() === ("ACTIVE").trim().toLowerCase());
    const activeRows = DtcBuySheetData.filter((i) => i["Style Activity"] === "ACTIVE");
    footerRowCalCols.forEach(column => {
      result[column].btmRow1 = activeRows.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue[column] ? currentValue[column] : 0), 0)
      result[column].btmRow2 = filtredData.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue[column] ? currentValue[column] : 0), 0)
    });
    if (Object.keys(result).length === 0) setBottomRowData([]);
    else {
      let row1 = []
      let row2 = []
      footerRowCalCols.forEach(column => {
        row1[column] = `${result[column]["btmRow1"]}`
        row2[column] = `${result[column]["btmRow2"]}`
      });
      setBottomRowData([row1, row2]);
    }
  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  }
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  }
  return (
    <CustomAgGrid
      setCurrentGridApi={onGridReady}
      filters={rdxFilters}
      setActionItemDataFun={setActionItemDataFun}
      tab_name="DTC_Buy_Sheet"
      tbl_name={tbl_name}
      columnDefs={ColumnDef}
      rowData={DtcBuySheetData}
      SSNData={SSNData}
      tblHighlightCells={rdxHighlistCell}
      onFilterChanged={onFilterChanged}
      onSortChanged={onSortChanged}
      autoChangeState={autoChangeState}
      resteAutoChangeState={resteAutoChangeState}
      autoGetChangeResult={autoGetChangeResult}
      setCellValueChanged={setCellValueChanged}
      calculateFormula={calculateReactFormula}
      isGrouppedHeader={true}
      calculateBottomRow={calcBottomRow}
      pinnedBottomRowData={bottomRowData}
      customColumnDef={DtcBuySheetColDef}
    />
  )
})
DtcBuySheetTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired
};
export default DtcBuySheetTab;
// 2556