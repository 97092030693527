export const getGroupListByTableName = (tbl_name) => {
  switch (tbl_name) {
    case "xl_faherty_linelist": return colGrpLinelist;
    case "xl_faherty_chase": return colGrpChase;
    case "xl_faherty_dtc_buy_sheet": return colGrpDTC_Buy_Sheet;
    case "xl_faherty_whsl_buy_sheet": return colGrpWhsl_Buy_Sheet;
    case "xl_faherty_whsl_buy_sheet_nord_rp": return colGrpWhsl_Buy_Sheet_Nord_RP;
    case "xl_faherty_sizing": return colGrpSizing;
    case "xl_faherty_buy_sheet_master_data_charts": return colGrpBuySht_Master_Data_Charts;
    case "xl_faherty_buy_sheet_master_sizing_percentage": return colGrpBuy_Sheet_Master_Sizing_per;
    case "xl_faherty_freight_kg_rates": return colGrpFreight_Master;
    case "xl_faherty_master_lov": return colGrpMaster_LOV;
    case "Summary_Report": return colGrpSummaryReport;
  }
}
const colGrpLinelist = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id"]
}, {
  id: 57,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC"
},
{
  id: 1,
  pid: 57,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "image", "Season Code", "Department"]
}, {
  id: 2,
  pid: 57,
  columnGroupShow: 'closed',
  headerName: "Master Line Data",
  headerClass: "aboveheader FFB3C6"
}, {
  id: 3,
  pid: 2,
  headerName: "Timing",
  headerClass: "aboveheader FAF1F5",
  children: ["Style Season", "Delivery Month", "Delivery Period", "Delivery Date/In DC", "In Store", "Half Year"]
}, {
  id: 4,
  pid: 2,
  headerName: "Categorization + Attributing",
  headerClass: "aboveheader FFE5EC",
  children: ["Category", "Subcategory"],
  columnGroupShow: 'closed',
}, {
  id: 5,
  pid: 2,
  headerName: "Style",
  headerClass: "aboveheader F7CAD0",
  children: ["SKU", "Style", "Style Description", "Color Code", "Color Description"],
  columnGroupShow: 'closed',
}, {
  id: 6,
  pid: 2,
  headerName: "Pricing",
  headerClass: "aboveheader FDE9D9",
  children: ["MSRP", "WHLS", "Seasonal Life Cycle"],
  columnGroupShow: 'closed',
},
{
  id: 7,
  pid: 57,
  columnGroupShow: 'closed',
  headerName: "Buying Intent",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 8,
  pid: 7,
  headerName: "Buying",
  headerClass: "aboveheader F5F2FF",
  children: ["Store Tier", "Weeks Bought", "Commit Coding", "Commit Date", "Ship Mode", "Channels Purchasing"],
}, {
  id: 9,
  pid: 7,
  headerName: "Chase",
  headerClass: "aboveheader E1D9FF",
  children: ["DTC Chase", "Wholesale Chase"],
  columnGroupShow: 'closed',
}, {
  id: 10,
  pid: 7,
  columnGroupShow: 'closed',
  headerName: "Style Status",
  headerClass: "aboveheader CDC1FF",
  children: ["Style Activity"],
}, {
  id: 11,
  pid: 57,
  columnGroupShow: 'closed',
  headerName: "Grouping",
  headerClass: "aboveheader FFB3C6",
}, {
  id: 12,
  pid: 11,
  headerName: "Size",
  headerClass: "aboveheader FFC2D1",
  children: ["Size Scale", "Line Grouping", "Product Segmentation", "Print / Pattern", "Color", "Silhouette", "Fit"],
}, {
  id: 14,
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "Collection",
  headerClass: "aboveheader FFC2D1",
  children: ["Collection"],
}, {
  id: 58,
  headerName: "Merch or PD",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 59,
  pid: 58,
  headerName: "",
  headerClass: "aboveheader FFB3C6",
},
{
  id: 60,
  pid: 59,
  headerName: "",
  headerClass: "aboveheader FFC2D1",
  children: ["Fabric Program"],
},
{
  id: 61,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
},
{
  id: 15,
  pid: 61,
  headerName: "Misc",
  headerClass: "aboveheader a90DBF4",
  children: ["Dev Category", "Previous Bulk Style # (If Applicable)"],
}, {
  id: 16,
  pid: 61,
  columnGroupShow: 'closed',
  headerName: "Change Management",
  headerClass: "aboveheader a90DBF4",
}, {
  id: 17,
  pid: 16,
  headerName: "Change Memo",
  headerClass: "aboveheader D2F0FA",
  children: ["Change Memo - Status", "Change Memo - Notes", "Change Memo - Date"],
}, {
  id: 18,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Upload",
  headerClass: "aboveheader B1E5F7",
  children: ["Item Upload Status"],
}, {
  id: 62,
  pid: 61,
  columnGroupShow: 'closed',
  headerName: "Buying / Units (Merch View)",
  headerClass: "aboveheader FFE6C9",
},
{
  id: 63,
  pid: 62,
  headerName: "BOP (Merch View)",
  headerClass: "aboveheader FFF6ED",
  children: ["DTC BOP (Merch View)", "DTC BOP + DTC Total Units (Merch View)"],
}, {
  id: 64,
  pid: 62,
  headerName: "Main Buy (Merch View)",
  columnGroupShow: 'closed',
  headerClass: "aboveheader FFF6ED",
  children: ["Stores (Merch View)", "Ecomm (Merch View)", "DTC Units Total (Merch View)"],
}, {
  id: 65,
  pid: 62,
  headerName: "Chase Buys (Merch View)",
  columnGroupShow: 'closed',
  headerClass: "aboveheader FFEEDB",
  children: ["DTC Chase Units (Merch View)", "DTC Units Total + DTC Chase Units (Merch View)"],
}, {
  id: 17,
  pid: 61,
  columnGroupShow: 'closed',
  headerName: "Costing",
  headerClass: "aboveheader a52B788",
}, {
  id: 18,
  pid: 17,
  headerName: "Sales $",
  headerClass: "aboveheader ECF8F1",
  children: ["Total DTC SLS $", "Total WHSL SLS $", "Total Brand SLS $"],
}, {
  id: 66,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 67,
  pid: 66,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 19,
  pid: 67,
  headerName: "Cost Control",
  headerClass: "aboveheader E7F6ED",
  children: ["Carry Over", "Costing Status", "Issue Override"],
},
{
  id: 68,
  headerName: "Vendor",
  headerClass: "aboveheader D0CECE",
}, {
  id: 69,
  pid: 68,
  headerName: "",
  headerClass: "aboveheader a52B788",
},
{
  id: "19a",
  pid: 69,
  columnGroupShow: 'closed',
  headerName: "Comments",
  headerClass: "aboveheader E1F4E8",
  children: ["Vendor to Faherty Costing Comments"],
},
{
  id: 70,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 71,
  pid: 70,
  headerName: "",
  headerClass: "aboveheader a52B788",
},
{
  id: "19b",
  pid: 71,
  headerName: "Comments",
  headerClass: "aboveheader E1F4E8",
  children: ["Faherty to Vendor Costing Comments"],
},
{
  id: 20,
  pid: 71,
  headerName: "Pre Initial Cost",
  headerClass: "aboveheader C5E9D2",
  children: ["Previous FOB", "Placeholder/Flash FOB"],
},
{
  id: 21,
  pid: 71,
  columnGroupShow: 'closed',
  headerName: "Key - Negotiation",
  headerClass: "aboveheader a95D5B2",
  children: ["Initial Cost"],
},
{
  id: 72,
  headerName: "PD & Vendor",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 73,
  pid: 72,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 22,
  pid: 73,
  columnGroupShow: 'closed',
  headerName: "FOB - Estimate",
  headerClass: "aboveheader E1F4E8",
  children: ["300 - FOB", "500 - FOB", "1000 - FOB"],
}, {
  id: 74,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 23,
  pid: 74,
  headerName: "Key - Negotiation",
  headerClass: "aboveheader a95D5B2",
  children: ["Bulk Cost"],
},
{
  id: 24,
  pid: 74,
  columnGroupShow: 'closed',
  headerName: "Minimums",
  headerClass: "aboveheader C5E9D2",
  children: ["Minimum Buy", "Minimum Met", "Upcharge for under Minimum", "Cost Under Min", "Cost Under Min - Estimated"],
},
{
  id: 75,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 76,
  pid: 75,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 25,
  pid: 76,
  columnGroupShow: 'closed',
  headerName: "Key - Negotiation",
  headerClass: "aboveheader a95D5B2",
  children: ["Faherty Target FOB"],
},
{
  id: 77,
  headerName: "",
  headerClass: "aboveheader E4DFEC",
},
{
  id: 78,
  pid: 77,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 79,
  pid: 78,
  headerName: "",
  headerClass: "aboveheader a95D5B2",
  children: ["Factory Counter"],
},
{
  id: 26,
  pid: 78,
  columnGroupShow: 'closed',
  headerName: "Working Cost",
  headerClass: "aboveheader D1EDDB",
  children: ["Working Cost"],
}, {
  id: 80,
  headerName: "PD & Vendor",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 81,
  pid: 80,
  headerName: "",
  headerClass: "aboveheader a52B788",
},
{
  id: 27,
  pid: 81,
  columnGroupShow: 'closed',
  headerName: "Key - Negotiation",
  headerClass: "aboveheader a95D5B2",
  children: ["Final FOB"],
}, {
  id: 82,
  headerName: "",
  headerClass: "aboveheader E4DFEC",
},
{
  id: 83,
  pid: 82,
  headerName: "",
  headerClass: "aboveheader a52B788",
},
{
  id: 28,
  pid: 83,
  columnGroupShow: 'closed',
  headerName: "Scenario Cost",
  headerClass: "aboveheader D1EDDB",
  children: ["Scenario Cost"],
}, {
  id: 84,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 85,
  pid: 84,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
},
{
  id: 29,
  pid: 85,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader D1EDDB",
  children: ["PO Override"],
}, {
  id: 30,
  pid: 85,
  columnGroupShow: 'closed',
  headerName: "FOB Calculation",
  headerClass: "aboveheader D1EDDB",
  children: ["Current FOB", "Current FOB Status"],
}, {
  id: 86,
  headerName: "Admin & PD",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 87,
  pid: 86,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
},
{
  id: 31,
  pid: 87,
  columnGroupShow: 'closed',
  headerName: "Duty Rate",
  headerClass: "aboveheader C5E9D2",
  children: ["Duty Rate"],
}, {
  id: 88,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 89,
  pid: 88,
  headerName: "",
  // columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
}, {
  id: 31,
  pid: 89,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader C5E9D2",
  children: ["Merch Fee", "Harbor Maintenance Fee", "Section 301", "Duty Total"],
}, {
  id: 90,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 91,
  pid: 90,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
}, {
  id: 31,
  pid: 91,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader C5E9D2",
  children: ["Agent Commission Rate"],
}, {
  id: 92,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 93,
  pid: 92,
  headerName: "",
  // columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
},
{
  id: 33,
  pid: 93,
  columnGroupShow: 'closed',
  headerName: "Freight",
  headerClass: "aboveheader D8F3DC",
  children: ["Air Freight", "Sea Freight"],
},
{
  id: 94,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 95,
  pid: 94,
  headerName: "",
  // columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
}, {
  id: 33,
  pid: 95,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader D8F3DC",
  children: ["Air %"],
}, {
  id: 96,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 97,
  pid: 96,
  headerName: "",
  // columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
},
{
  id: 98,
  pid: 97,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader D8F3DC",
  children: ["Blended Freight"],
}, {
  id: 99,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 100,
  pid: 99,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
},
{
  id: 34,
  pid: 100,
  columnGroupShow: 'closed',
  headerName: "LDP",
  headerClass: "aboveheader a95D5B2",
  children: ["300 - LDP", "500 - LDP", "1000 - LDP", "Scenario LDP", "LDP", "Current LDP - Status"],
}, {
  id: 101,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 102,
  pid: 101,
  headerName: "",
  // columnGroupShow: 'closed',
  headerClass: "aboveheader a52B788",
}, {
  id: 35,
  pid: 102,
  columnGroupShow: 'closed',
  headerName: "IMU %",
  headerClass: "aboveheader E2EFDA",
  children: ["300 - IMU%", "500 - IMU%", "1000 - IMU%", "RTL IMU%", "WHLS IMU%"],
}, {
  id: 103,
  headerName: "",
  headerClass: "aboveheader E4DFEC",
},
{
  id: 104,
  pid: 103,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 35,
  pid: 104,
  headerName: "",
  headerClass: "aboveheader E2EFDA",
  children: ["Scenario RTL IMU%", "Scenario WHLS IMU%", "Working Vs Scenario RTL IMU%", "Working Vs Scenario WHSL IMU%"],
}, {
  id: 105,
  headerName: "Planning",
  headerClass: "aboveheader E5D9F2",
}, {
  id: 106,
  pid: 105,
  headerName: "Buying / Units",
  columnGroupShow: 'closed',
  headerClass: "aboveheader FFE6C9",
},
{
  id: 37,
  pid: 106,
  headerName: "Projections",
  headerClass: "aboveheader FFF6ED",
  children: ["DTC Projections", "WHSL Projections", "Total Projections"],
},
{
  id: 38,
  pid: 106,
  columnGroupShow: 'closed',
  headerName: "BOP",
  headerClass: "aboveheader FFF6ED",
  children: ["DTC BOP", "DTC BOP + DTC Total Units"],
}, {
  id: 39,
  pid: 106,
  columnGroupShow: 'closed',
  headerName: "Main Buy",
  headerClass: "aboveheader FFEEDB",
  children: ["Stores", "Ecomm", "DTC Units Total"],
}, {
  id: 107,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 108,
  pid: 107,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader FFE6C9",
}, {
  id: 39,
  pid: 108,
  columnGroupShow: 'closed',
  headerName: "Main Buy",
  headerClass: "aboveheader FFEEDB",
  children: ["International Wholesale Units", "Wholesale Units Total"],
}, {
  id: 109,
  headerName: "Merch, Planning, Wholesale",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 110,
  pid: 109,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader FFE6C9",
}, {
  id: 111,
  pid: 110,
  headerName: "Main Buy",
  headerClass: "aboveheader FFEEDB",
  children: ["Brand Units Total"],
}, {
  id: 112,
  headerName: "Planning, Global Planning, Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 113,
  pid: 112,
  headerName: "",
  columnGroupShow: 'closed',
  headerClass: "aboveheader FFE6C9",
},
{
  id: 40,
  pid: 113,
  columnGroupShow: 'closed',
  headerName: "Chase Buys",
  headerClass: "aboveheader FFEEDB",
  children: ["DTC Chase Units", "Wholesale Chase Units", "Brand Chase Units", "DTC Units Total + DTC Chase Units", "Wholesale Units Total + Wholesale Chase Units", "Brand Total Including Chase"],
}, {
  id: 114,
  headerName: "Planning",
  headerClass: "aboveheader E5D9F2",
}, {
  id: 41,
  pid: 114,
  headerName: "Unit Confirmation",
  headerClass: "aboveheader E2EFDA",
}, {
  id: 115,
  pid: 41,
  headerName: "DTC Confirmation",
  headerClass: "aboveheader E2EFDA",
  children: ["DTC Confirmation"],
}, {
  id: 116,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 117,
  pid: 116,
  headerName: "",
  headerClass: "aboveheader E2EFDA",
}, {
  id: 118,
  pid: 117,
  headerName: "Wholesale Confirmation",
  headerClass: "aboveheader E2EFDA",
  children: ["Wholesale Confirmation"],
},
{
  id: 119,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
},
{
  id: 42,
  pid: 119,
  headerName: "Production Controlled Lock",
  headerClass: "aboveheader BDD7EE",
},
{
  id: 43,
  pid: 42,
  headerName: "Lock Controls",
  columnGroupShow: 'closed',
  headerClass: "aboveheader DDEBF7",
  children: ["Production Lock", "Change Flag"],
},
{
  id: 44,
  pid: 42,
  headerName: "Locked Core Data",
  headerClass: "aboveheader FFF2CC",
  children: ["Change Type", "SKU - Lock", "Stores - Lock", "Ecomm - Lock", "DTC Units Total - Lock", "Wholesale Units Total - Lock", "Brand Units Total - Lock", "Locked Date"],
}, {
  id: 120,
  headerName: "Merch, Wholesale",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 121,
  pid: 120,
  headerName: "Cost Log",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 45,
  pid: 121,
  headerName: "",
  headerClass: "aboveheader FFEEDB",
  children: ["DTC SMS Units"],
}, {
  id: 122,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 123,
  pid: 122,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 45,
  pid: 123,
  headerName: "",
  headerClass: "aboveheader FFEEDB",
  children: ["Wholesale SMS Units"],
}, {
  id: 124,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 125,
  pid: 124,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 46,
  pid: 125,
  headerName: "Ownership",
  headerClass: "aboveheader BAEEFF",
  children: ["PD Owner"],
},
{
  id: 47,
  pid: 125,
  headerName: "Allocation",
  headerClass: "aboveheader B5E4FF",
  children: ["Sales Tier", "Factory Type", "Factory", "Brand", "Corporate/Agent", "Mill", "COO", "Shipping Terms (FOB/DDP/LDP)"],
}, {
  id: 48,
  pid: 125,
  headerName: "Classification",
  headerClass: "aboveheader AFD9FF",
  children: ["Fabric Content", "Knit or Woven"],
}, {
  id: 126,
  headerName: "",
  headerClass: "aboveheader E4DFEC",
}, {
  id: 127,
  pid: 126,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 128,
  pid: 127,
  headerName: "Classification",
  headerClass: "aboveheader AFD9FF",
  children: ["Special Characteristic", "Fish and Wildlife", "Production COO", "Material Mill COO", "Fabric / Yarn COO"],
}, {
  id: 129,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 130,
  pid: 129,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 131,
  pid: 130,
  headerName: "Classification",
  headerClass: "aboveheader AFD9FF",
  children: ["HTS Code"],
}, {
  id: 132,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 133,
  pid: 132,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 49,
  pid: 133,
  headerName: "",
  headerClass: "aboveheader A4C5FF",
  children: ["MOQ", "Mandatory MOQ"],
},
{
  id: 50,
  pid: 133,
  headerName: "Control",
  headerClass: "aboveheader AACFFF",
  children: ["Delivery Status"],
},
{
  id: 51,
  pid: 133,
  headerName: "Delivery",
  headerClass: "aboveheader A4C5FF",
  children: ["X-fty Date", "Target X-fty Date"],
},
{
  id: 52,
  pid: 133,
  headerName: "Transit",
  headerClass: "aboveheader A4C5FF",
  children: ["Day of Week"],
}, {
  id: 134,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 135,
  pid: 134,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 136,
  pid: 135,
  headerName: "Transit",
  headerClass: "aboveheader A4C5FF",
  children: ["Transit Days", "CFS Cut Off (Day of week)", "In DC Date (Ocean)", "Days Late", "Length (CM)", "Height (CM)", "Width (CM)", "Quantity Per Carton", "CBM Per Carton",
    "CBM Per Unit", "KG Per Unit", "Global PO Units", "Global vs Final"],
}, {
  id: 137,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 138,
  pid: 137,
  headerName: "Internal Notes",
  headerClass: "aboveheader CDB4DB",
},
{
  id: 54,
  pid: 138,
  headerName: "",
  headerClass: "aboveheader E6BEDC",
  children: ["Faherty Internal Merch"],
}, {
  id: 139,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 140,
  pid: 139,
  headerName: "",
  headerClass: "aboveheader CDB4DB",
},
{
  id: 141,
  pid: 140,
  headerName: "",
  headerClass: "aboveheader E6BEDC",
  children: ["Faherty Internal PD"],
}, {
  id: 142,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 143,
  pid: 142,
  headerName: "",
  headerClass: "aboveheader CDB4DB",
},
{
  id: 144,
  pid: 143,
  headerName: "",
  headerClass: "aboveheader E6BEDC",
  children: ["Faherty Internal Wholesale"],
}, {
  id: 145,
  headerName: "Planning",
  headerClass: "aboveheader E5D9F2",
}, {
  id: 146,
  pid: 145,
  headerName: "",
  headerClass: "aboveheader CDB4DB",
},
{
  id: 147,
  pid: 146,
  headerName: "",
  headerClass: "aboveheader E6BEDC",
  children: ["Faherty Internal Planning"],
}, {
  id: 148,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 149,
  pid: 148,
  headerName: "",
  headerClass: "aboveheader CDB4DB",
},
{
  id: 150,
  pid: 149,
  headerName: "",
  headerClass: "aboveheader E6BEDC",
  children: ["SKU Count"],
},
{
  id: 55,
  pid: 148,
  headerName: "",
  headerClass: "",
  children: ["Linelist User Assigned 1", "Linelist User Assigned 2", "Linelist User Assigned 3", "Linelist User Assigned 4", "Linelist User Assigned 5", "Linelist User Assigned 6", "Linelist User Assigned 7", "Linelist User Assigned 8", "Linelist User Assigned 9", "Linelist User Assigned 10", "Suite Line ID", "Merch Highlight", "user_inserted", "inserted_on", "user_modified", "modified_on"],
}
];
const colGrpBuySht_Master_Data_Charts = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id"]
}, {
  id: 1,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "Season Code", "Department"]
}, {
  id: 2,
  headerName: "DTC",
  headerClass: "aboveheader a8991FF",
}, {
  id: 3,
  pid: 2,
  headerName: "Min Mid Max APS",
  headerClass: "aboveheader BAEEFF",
  children: ["Channel", "Category", "Min", "Mid", "Max"]
}, {
  id: 4,
  pid: 2,
  headerName: "Store Tier",
  headerClass: "aboveheader B5E4FF",
  columnGroupShow: 'closed',
  children: ["STORE TIER", "Doors"]
}, {
  id: 5,
  pid: 2,
  headerName: "Minumum Per Store",
  headerClass: "aboveheader A4C5FF",
  columnGroupShow: 'closed',
  children: ["Minimum Category", "Minimum Units"]
}, {
  id: 6,
  pid: 2,
  headerName: "Markdown %",
  headerClass: "aboveheader a9FBAFF",
  columnGroupShow: 'closed',
  children: ["Markdown Channel", "Markdown %"]
}, {
  id: 7,
  headerName: "Wholesale",
  headerClass: "aboveheader CDB4DB",
}, {
  id: 8,
  pid: 7,
  headerName: "ATS Wholesale",
  headerClass: "aboveheader E6BEDC",
  children: ["ATS Level", "ATS %"]
},
{
  id: 9,
  headerName: "",
  headerClass: "",
  children: ["user_inserted", "inserted_on", "user_modified", "modified_on"]
}
];
const colGrpDTC_Buy_Sheet = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id", "Working Line Status", "linelist_row_id"]
}, {
  id: 1,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 2,
  pid: 1,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "Season Code", "Department"]
}, {
  id: 3,
  pid: 1,
  headerName: "Master Line Data",
  headerClass: "aboveheader FFB3C6"
}, {
  id: 4,
  pid: 3,
  headerName: "Timing",
  headerClass: "aboveheader FAF1F5",
  children: ["Delivery Month", "Delivery Date/In DC", "In Store"]
}, {
  id: 5,
  pid: 3,
  headerName: "Categorization + Attributing",
  headerClass: "aboveheader FFE5EC",
  children: ["Category", "Subcategory"],
  columnGroupShow: 'closed',
}, {
  id: 6,
  pid: 3,
  headerName: "Style",
  headerClass: "aboveheader F7CAD0",
  children: ["SKU", "Locked SKU", "Style", "Style Description", "Color Code", "Color Description"],
  columnGroupShow: 'closed',
}, {
  id: 7,
  pid: 3,
  headerName: "Pricing",
  headerClass: "aboveheader FDE9D9",
  children: ["MSRP", "WHLS"],
  columnGroupShow: 'closed',
}, {
  id: 8,
  pid: 1,
  headerName: "Buying Intent",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 9,
  pid: 8,
  headerName: "Buying",
  headerClass: "aboveheader F5F2FF",
  children: ["Seasonal Life Cycle", "Store Tier"],
}, {
  id: 10,
  headerName: "Planning",
  headerClass: "aboveheader E5D9F2",
}, {
  id: 11,
  pid: 10,
  headerName: "Buying Intent",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 12,
  pid: 11,
  headerName: "Buying",
  headerClass: "aboveheader F5F2FF",
  children: ["Doors Based on Store Tier"],
}, {
  id: 13,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 14,
  pid: 13,
  headerName: "Buying Intent",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 15,
  pid: 14,
  headerName: "Buying",
  headerClass: "aboveheader F5F2FF",
  children: ["Weeks Bought", "Working Line", "Planning Lock", "Change Flag", "Store Tier - Locked", "Doors Based on Store Tier - Locked", "Weeks Bought - Locked", "Commit Coding", "Commit Date", "Channels Purchasing"],
}, {
  id: 16,
  pid: 14,
  columnGroupShow: 'closed',
  headerName: "Style Status",
  headerClass: "aboveheader CDC1FF",
  children: ["Style Activity"],
}, {
  id: 17,
  pid: 13,
  headerName: "Grouping",
  headerClass: "aboveheader FFB3C6",
}, {
  id: 18,
  pid: 17,
  headerName: "Size",
  headerClass: "aboveheader FFC2D1",
  children: ["Size Scale"],
}, {
  id: 19,
  pid: 17,
  columnGroupShow: 'closed',
  headerName: "Collection",
  headerClass: "aboveheader FFC2D1",
  children: ["Product Segmentation", "Collection", "Fabric Program"],
}, {
  id: 20,
  pid: 13,
  headerName: "Costing",
  headerClass: "aboveheader B1E5F7",
  children: ["LDP", "RTL IMU%", "WHLS IMU%"],
}, {
  id: 21,
  headerName: "Planning",
  headerClass: "aboveheader E5D9F2",
}, {
  id: 22,
  pid: 21,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 23,
  pid: 22,
  headerName: "Core Buy Data",
  headerClass: "aboveheader BAEEFF",
  children: ["Buying Decision"],
}, {
  id: 24,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 25,
  pid: 24,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 26,
  pid: 25,
  headerName: "Core Buy Data",
  headerClass: "aboveheader BAEEFF",
  children: ["Buy Method", "Comp SKU"],
}, {
  id: 27,
  headerName: "",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 28,
  pid: 27,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 29,
  pid: 28,
  headerName: "",
  headerClass: "aboveheader BAEEFF",
  children: ["Retail ST%", "Ecomm ST%"],
}, {
  id: 30,
  headerName: "Merch, Planning",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 31,
  pid: 30,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 32,
  pid: 31,
  columnGroupShow: 'closed',
  headerName: "STORES",
  headerClass: "aboveheader BAEEFF",
  children: ["Stores 2: MIN-MID-MAX"],
}, {
  id: 33,
  headerName: "Planning",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 34,
  pid: 33,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 35,
  pid: 34,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader BAEEFF",
  children: ["Store Comp APS", "Calc APS - Store", "APS Adj - Store", "APS Override - Store", "Final APS - Store", "STORES Total FP Sales", "STORES Total MD Sales", "Total Need", "BOP", "Total Store Needs (Less BOP)", "Total Calculated Store Buy (Incl. Min)", "Store Buy Override", "Store Final Buy", "Store Final Buy - LOCKED", "Store Chase Buy", "Store Chase Buy + Store Final Buy", "Min Units Per Store", "MIN STORE BUY", "size runs/store", "AUPS"],
}, {
  id: 36,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 37,
  pid: 36,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 38,
  pid: 37,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader BAEEFF",
  children: ["Notes RANKS"],
}, {
  id: 39,
  headerName: "Planning",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 40,
  pid: 39,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 41,
  pid: 40,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader BAEEFF",
  children: ["FP REVENUE", "MKD REVENUE", "STORES TOTAL SALES", "STORES TOTAL COST", "STORES TOTAL MARGIN $", "STORES TOTAL MARGIN %"],
}, {
  id: 42,
  headerName: "Merch, Planning",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 43,
  pid: 42,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 44,
  pid: 43,
  columnGroupShow: 'closed',
  headerName: "ECOMM",
  headerClass: "aboveheader B5E4FF",
  children: ["ECOMM 2: MIN-MID-MAX"],
}, {
  id: 45,
  headerName: "Planning",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 46,
  pid: 45,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 47,
  pid: 46,
  columnGroupShow: 'closed',
  headerName: "ECOMM",
  headerClass: "aboveheader B5E4FF",
  children: ["ECOMM COMP APS", "CALC APS - Ecomm", "APS ADJ - Ecomm", "APS Override", "FINAL APS - Ecomm", "ECOMM TOTAL FP SALES", "ECOMM TOTAL MD SALES", "TOTAL NEEDS ECOMM", "ECOMM BOP", "TOTAL NEEDS ECOMM (LESS BOP)", "Buy Override - Ecomm", "Final Buy - Ecomm", "Final Buy - Ecomm - LOCKED", "Ecomm Chase Buy", "Ecomm Chase Buy + Ecomm Final Buy", "Notes", "PAID", "ECOMM FP REVENUE", "ECOMM MKD REVENUE", "ECOMM TOTAL SALES", "ECOMM TOTAL COST", "ECOMM TOTAL MARGIN $", "ECOMM TOTAL MARGIN %"],
}, {
  id: 48,
  pid: 46,
  columnGroupShow: 'closed',
  headerName: "TOTAL",
  headerClass: "aboveheader AFD9FF",
  children: ["DTC TOTAL BUY", "DTC TOTAL BUY - LOCKED"],
}, {
  id: 49,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 50,
  pid: 49,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 51,
  pid: 50,
  columnGroupShow: 'closed',
  headerName: "TOTAL",
  headerClass: "aboveheader AFD9FF",
  children: ["WHOLESALE BUY"],
}, {
  id: 52,
  headerName: "Planning",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 53,
  pid: 52,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 54,
  pid: 53,
  columnGroupShow: 'closed',
  headerName: "TOTAL",
  headerClass: "aboveheader AFD9FF",
  children: ["TOTAL BRAND BUY", "DTC CHASE BUY", "DTC CHASE BUY + DTC TOTAL BUY", "Buy Status", "MOQ", "ADDITIONAL NEED TO HIT MOQ", "TTL DTC $", "TTL WHSL $", "TTL BRAND $", "TOTAL DTC COST", "TOTAL WHSL COST", "TOTAL BRAND COST", "TOTAL DTC  MARGIN", "TOTAL WHSL MARGIN", "TOTAL BRAND MARGIN"],
}, {
  id: 55,
  headerName: "",
  headerClass: "aboveheader",
}, {
  id: 56,
  pid: 55,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 57,
  pid: 56,
  headerName: "Attributing",
  headerClass: "aboveheader AACFFF",
  children: ["PPN", "COLOR FAMILY", "PLATFORM", "NEW/REODER", "sea cost", "sea cost receipts", "SEA imU", "Status"],
}, {
  id: 58,
  headerName: "",
  headerClass: "",
  children: ["User Assigned 1", "User Assigned 2", "User Assigned 3", "Linelist User Assigned 1", "Linelist User Assigned 2", "Linelist User Assigned 3", "Linelist User Assigned 4", "Linelist User Assigned 5", "Linelist User Assigned 6", "Linelist User Assigned 7", "Linelist User Assigned 8", "Linelist User Assigned 9", "Linelist User Assigned 10", "user_inserted", "inserted_on", "user_modified", "modified_on"],
}];
const colGrpWhsl_Buy_Sheet = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id", "Working Line Status", "linelist_row_id"]
}, {
  id: 23,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 1,
  pid: 23,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "Season Code", "Department"]
}, {
  id: 2,
  pid: 23,
  headerName: "Master Line Data",
  headerClass: "aboveheader FFB3C6"
}, {
  id: 3,
  pid: 2,
  headerName: "Timing",
  headerClass: "aboveheader FAF1F5",
  children: ["Delivery Month", "Delivery Date/In DC", "In Store"]
}, {
  id: 4,
  pid: 2,
  headerName: "Categorization + Attributing",
  headerClass: "aboveheader FFE5EC",
  children: ["Category", "Subcategory"],
  columnGroupShow: 'closed',
}, {
  id: 5,
  pid: 2,
  headerName: "Style",
  headerClass: "aboveheader F7CAD0",
  children: ["SKU", "Locked SKU", "Style", "Style Description", "Color Code", "Color Description"],
  columnGroupShow: 'closed',
}, {
  id: 6,
  pid: 2,
  headerName: "Pricing",
  headerClass: "aboveheader FDE9D9",
  children: ["MSRP", "WHLS"],
  columnGroupShow: 'closed',
}, {
  id: 7,
  pid: 23,
  headerName: "Buying Intent",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 8,
  pid: 7,
  headerName: "Buying",
  headerClass: "aboveheader F5F2FF",
  children: ["Seasonal Life Cycle", "Store Tier"],
}, {
  id: 24,
  headerName: "Planning",
  headerClass: "aboveheader E5D9F2",
}, {
  id: 25,
  pid: 24,
  headerName: "",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 26,
  pid: 25,
  headerName: "",
  headerClass: "aboveheader F5F2FF",
  children: ["Doors Based on Store Tier"],
}, {
  id: 27,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 28,
  pid: 27,
  headerName: "",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 29,
  pid: 28,
  headerName: "",
  headerClass: "aboveheader F5F2FF",
  children: ["Weeks Bought", "Working Line", "Commit Coding", "Commit Date", "Channels Purchasing"],
}, {
  id: 9,
  pid: 28,
  columnGroupShow: 'closed',
  headerName: "Style Status",
  headerClass: "aboveheader CDC1FF",
  children: ["Style Activity"],
}, {
  id: 10,
  pid: 27,
  headerName: "Costing",
  headerClass: "aboveheader B1E5F7",
  children: ["LDP", "RTL IMU%", "WHLS IMU%"],
}, {
  id: 30,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 11,
  pid: 30,
  headerName: "Buy Sheet",
  headerClass: "aboveheader a52B788",
}, {
  id: 12,
  pid: 11,
  headerName: "",
  headerClass: "aboveheader ECF8F1",
  children: ["BOP", "Customer in Store", "Universal Flag", "Ranking", "ATS Level"],
}, {
  id: 13,
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "Specialty",
  headerClass: "aboveheader D1EDDB",
  children: ["Active Acc / Total Specialty", "Specialty Avg"],
}, {
  id: 14,
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "Nordstrom",
  headerClass: "aboveheader C5E9D2",
  children: ["Nordstrom Doors", "Nordstrom Avg"],
},
{
  id: "14a",
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "Key Account",
  headerClass: "aboveheader D8F3DC",
  children: ["Key Acct", "Key Acct Avg"],
}, {
  id: 15,
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "Corp Acct",
  headerClass: "aboveheader B7E4C7",
  children: ["Corp Acct", "Corp Acct Avg"],
}, {
  id: 16,
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "International",
  headerClass: "aboveheader a95D5B2",
  children: ["Intr Acc", "Intr Acc Avg"],
}, {
  id: 17,
  pid: 11,
  columnGroupShow: 'closed',
  headerName: "Units",
  headerClass: "aboveheader a74C69D",
  children: ["Ttl Active Acc / Specialty", "Ttl Nord", "Ttl Nord RP", "Ttl Key Acct", "Ttl Corp", "Evereve", "Ttl Intr", "ATS", "Total Units for US ONLY", "Total Units for INTERNATIONAL", "Wholesale Total Unit Override", "Total Wholesale Units", "Total Wholesale Units - Locked", "Wholesale Chase Units", "Wholesale Chase Units + Total Wholesale Units"],
}, {
  id: 18,
  pid: 11,
  headerName: "",
  headerClass: "aboveheader a74C69D",
  children: ["Buy Status"],
}, {
  id: 19,
  pid: 11,
  headerName: "$",
  headerClass: "aboveheader a74C69D",
  children: ["Total $ for US ONLY", "Total $ for CAD", "Total Wholesale $"],
}, {
  id: 20,
  pid: 30,
  headerName: "Units by Month",
  headerClass: "aboveheader a9FBAFF",
  children: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", "Total"],
}, {
  id: 21,
  pid: 30,
  headerName: "$ By Month",
  headerClass: "aboveheader CDB4DB",
  children: ["$ JAN", "$ FEB", "$ MAR", "$ APR", "$ MAY", "$ JUN", "$ JUL", "$ AUG", "$ SEP", "$ OCT", "$ NOV", "$ DEC"],
}, {
  id: 22,
  headerName: "",
  headerClass: "",
  children: ["User Assigned 1", "User Assigned 2", "User Assigned 3", "user_inserted", "inserted_on", "user_modified", "modified_on"],
}];
const colGrpWhsl_Buy_Sheet_Nord_RP = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id"]
}, {
  id: 20,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 1,
  pid: 20,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "Season Code", "Department"]
}, {
  id: 2,
  pid: 20,
  headerName: "Master Line Data",
  headerClass: "aboveheader FFB3C6"
}, {
  id: 3,
  pid: 2,
  headerName: "Categorization + Attributing",
  headerClass: "aboveheader FFE5EC",
  children: ["Category", "Subcategory"],
}, {
  id: 4,
  pid: 2,
  headerName: "Style",
  headerClass: "aboveheader F7CAD0",
  children: ["SKU", "Style", "Style Description", "Color Code", "Color Description"],
  columnGroupShow: 'closed',
}, {
  id: 5,
  pid: 2,
  headerName: "Pricing",
  headerClass: "aboveheader FDE9D9",
  children: ["MSRP", "WHLS"],
  columnGroupShow: 'closed',
}, {
  id: 6,
  headerName: "Costing",
  pid: 20,
  headerClass: "aboveheader B1E5F7",
  children: ["LDP"]
}, {
  id: 7,
  headerName: "Buying Intent",
  pid: 20,
  headerClass: "aboveheader B8ADE5",
}, {
  id: 8,
  pid: 7,
  headerName: "Buying",
  headerClass: "aboveheader E1D9FF",
  children: ["Commit Coding", "Commit Date"],
}, {
  id: 9,
  headerName: "Buy Sheet",
  pid: 20,
  headerClass: "aboveheader B7E4C7",
}, {
  id: 10,
  pid: 9,
  headerName: "Flag",
  headerClass: "aboveheader ECF8F1",
  children: ["Nord Flag"],
}, {
  id: 21,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 11,
  pid: 21,
  headerName: "Notes",
  headerClass: "aboveheader a52B788",
  children: ["WRITE", "Notes with Mer", "Notes"],
}, {
  id: 12,
  pid: 21,
  headerName: "",
  headerClass: "",
  children: ["WHSL GM%"],
}, {
  id: 13,
  pid: 21,
  headerName: "RP Data",
  headerClass: "aboveheader a52B788",
}, {
  id: 14,
  pid: 13,
  headerName: "Timing",
  headerClass: "aboveheader C5E9D2",
  children: ["Target IDC", "FB Ship", "Nord on Fl"],
}, {
  id: 15,
  pid: 13,
  columnGroupShow: 'closed',
  headerName: "US Buy Calc",
  headerClass: "aboveheader D8F3DC",
  children: ["RP US Nords Doors", "Avg Per Door - US", "RP US Total Units"],
}, {
  id: 16,
  pid: 13,
  columnGroupShow: 'closed',
  headerName: "International By Calc",
  headerClass: "aboveheader B7E4C7",
  children: ["RP International Nords Doors", "Avg Per Door - Int", "RP Int Total Units"],
}, {
  id: 17,
  pid: 13,
  columnGroupShow: 'closed',
  headerName: "Total",
  headerClass: "aboveheader a74C69D",
  children: ["Total Nords Replen Units"],
}, {
  id: 18,
  headerName: "Roll Up",
  headerClass: "aboveheader BDD7EE",
  children: ["Total RP US Receipts", "Total RP Int. Receipts", "Total RP Receipts WHSL", "Total RP Receipts MSRP"],
}, {
  id: 19,
  headerName: "",
  headerClass: "",
  children: ["user_inserted", "inserted_on", "user_modified", "modified_on"],
}];
const colGrpSizing = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id", "dtc_buy_sheet_row_id", "whsl_buy_sheet_row_id", "Sizing Split", "Apply Sizing Formula"]
}, {
  id: 39,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 1,
  pid: 39,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "Season Code", "Department"]
}, {
  id: 2,
  pid: 39,
  headerName: "Master Line Data",
  headerClass: "aboveheader FFB3C6"
}, {
  id: 3,
  pid: 2,
  headerName: "Timing",
  headerClass: "aboveheader FAF1F5",
  children: ["Delivery Month", "Delivery Period", "Delivery Date/In DC", "In Store"]
}, {
  id: 4,
  pid: 2,
  headerName: "Categorization + Attributing",
  headerClass: "aboveheader FFE5EC",
  children: ["Category", "Subcategory"],
  columnGroupShow: 'closed',
}, {
  id: 5,
  pid: 2,
  headerName: "Style",
  headerClass: "aboveheader F7CAD0",
  children: ["SKU", "Style", "Style Description", "Color Code", "Color Description"],
  columnGroupShow: 'closed',
}, {
  id: 7,
  pid: 39,
  headerName: "Buying Intent",
  headerClass: "aboveheader B8ADE5",
}, {
  id: 8,
  pid: 7,
  headerName: "Buying",
  headerClass: "aboveheader F5F2FF",
  children: ["Store Tier", "Doors Based on Store Tier", "Weeks Bought", "Commit Coding", "Commit Date", "Channels Purchasing"],
}, {
  id: 9,
  pid: 7,
  columnGroupShow: 'closed',
  headerName: "Style Status",
  headerClass: "aboveheader CDC1FF",
  children: ["Style Activity"],
}, {
  id: 10,
  pid: 39,
  headerName: "Grouping",
  headerClass: "aboveheader FFB3C6",
}, {
  id: 11,
  pid: 10,
  headerName: "Size",
  headerClass: "aboveheader FFC2D1",
  children: ["Size Scale"],
}, {
  id: 12,
  pid: 10,
  columnGroupShow: 'closed',
  headerName: "Collection",
  headerClass: "aboveheader FFC2D1",
  children: ["Collection", "Fabric Program"],
}, {
  id: 13,
  pid: 39,
  headerName: "Sizing Detail",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 14,
  pid: 13,
  headerName: "Location",
  headerClass: "aboveheader B5E4FF",
  children: ["Location"],
}, {
  id: 40,
  headerName: "Wholesale, Planning, Global Planning",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 41,
  pid: 40,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 15,
  pid: 41,
  headerName: "Delivery",
  headerClass: "aboveheader AFD9FF",
  children: ["Delivery"],
}, {
  id: 42,
  headerName: "System",
  headerClass: "aboveheader FFFFFF",
}, {
  id: 43,
  pid: 42,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
},
{
  id: 44,
  pid: 43,
  headerName: "Delivery",
  headerClass: "aboveheader AFD9FF",
  children: ["Delivery In DC"],
}, {
  id: 45,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 46,
  pid: 45,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 16,
  pid: 46,
  columnGroupShow: 'closed',
  headerName: "Attributes",
  headerClass: "aboveheader AACFFF",
  children: ["Customer"],
}, {
  id: 47,
  headerName: "Wholesale, Planning, Global Planning",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 48,
  pid: 47,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 49,
  pid: 48,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader AACFFF",
  children: ["Sales Tier"],
},
{
  id: 17,
  pid: 48,
  columnGroupShow: 'closed',
  headerName: "Sizing Controls",
  headerClass: "aboveheader A4C5FF",
  children: ["Sizing Override", "Sizing Confirmed"],
}, {
  id: 49,
  headerName: "System",
  headerClass: "aboveheader FFFFFF",
}, {
  id: 50,
  pid: 49,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 51,
  pid: 50,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader A4C5FF",
  children: ["Date Unit Confirmation Sent"],
}, {
  id: 52,
  headerName: "Wholesale, Planning, Global Planning",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 53,
  pid: 52,
  headerName: "",
  headerClass: "aboveheader a9FBAFF",
}, {
  id: 54,
  pid: 53,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader A4C5FF",
  children: ["Buy Sheet Total", "Line Total Units", "Variance"],
},
{
  id: 55,
  headerName: "PD",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 19,
  pid: 55,
  headerName: "Purchase Order",
  headerClass: "aboveheader a52B788",
}, {
  id: 18,
  pid: 19,
  headerName: "",
  headerClass: "",
  children: ["Production Approval Status"],
},
{
  id: 20,
  pid: 19,
  headerName: "Factory",
  headerClass: "aboveheader ECF8F1",
  children: ["Factory"],
}, {
  id: 56,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 57,
  pid: 56,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 58,
  pid: 57,
  headerName: "",
  headerClass: "aboveheader ECF8F1",
  children: ["Factory Override", "Country of Origin"],
}, {
  id: 59,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 60,
  pid: 59,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 22,
  pid: 59,
  columnGroupShow: 'closed',
  headerName: "Ship Mode",
  headerClass: "aboveheader E1F4E8",
  children: ["Commit Ship Mode"],
}, {
  id: 61,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 62,
  pid: 61,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 63,
  pid: 62,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader E1F4E8",
  children: ["Ship Mode Override"],
}, {
  id: 64,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 65,
  pid: 64,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 23,
  pid: 65,
  columnGroupShow: 'closed',
  headerName: "Delivery",
  headerClass: "aboveheader D1EDDB",
  children: ["X-fty Date", "Delivery Status"],
}, {
  id: 66,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 67,
  pid: 66,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 68,
  pid: 67,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader D1EDDB",
  children: ["X-fty Date Override"],
}, {
  id: 69,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 70,
  pid: 69,
  headerName: "",
  headerClass: "aboveheader a52B788",
},
{
  id: 24,
  pid: 70,
  columnGroupShow: 'closed',
  headerName: "Pricing",
  headerClass: "aboveheader B7E4C7",
  children: ["Current FOB", "Current FOB Status", "Costing Status", "Issue Override"],
}, {
  id: 71,
  headerName: "Admin",
  headerClass: "aboveheader BAEEFF",
}, {
  id: 72,
  pid: 71,
  headerName: "",
  headerClass: "aboveheader a52B788",
}, {
  id: 73,
  pid: 72,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader B7E4C7",
  children: ["Current FOB Override"],
},
{
  id: 25,
  pid: 72,
  columnGroupShow: 'closed',
  headerName: "PO Issuance",
  headerClass: "aboveheader C5E9D2",
  children: ["PO Status", "External ID"],
},
{
  id: 26,
  headerName: "Total",
  headerClass: "aboveheader BDD7EE",
}, {
  id: 27,
  pid: 26,
  headerName: "Kids",
  headerClass: "aboveheader FFF2CC",
  children: ["2T", "3T", "4T", "5", "6", "8", "10"],
}, {
  id: 28,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Standard Sizing",
  headerClass: "aboveheader DDEBF7",
  children: ["OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
}, {
  id: 29,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Combo Sizing",
  headerClass: "aboveheader FCE4D6",
  children: ["XS/S", "S/M", "M/L", "L/XL"],
}, {
  id: 30,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Tall Sizing",
  headerClass: "aboveheader EDEDED",
  children: ["MT", "LT", "XLT", "XXLT"],
}, {
  id: 31,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Bottoms Sizing 00-16",
  headerClass: "aboveheader EDEDED",
  children: ["00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16"],
}, {
  id: 32,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Bottoms Sizing",
  headerClass: "aboveheader E2EFDA",
  children: ["24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42"],
}, {
  id: 33,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Tall Sizing",
  headerClass: "aboveheader F2F7EF",
  children: ["30T", "32T", "34T", "36T", "38T", "40T"],
}, {
  id: 34,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Inseam Bottoms Sizing",
  headerClass: "aboveheader C6E0B4",
  children: ["28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L"],
}, {
  id: 35,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "DXL Shirt Sizing",
  headerClass: "aboveheader CCCCFF",
  children: ["1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT"],
}, {
  id: 36,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "DXL Bottoms Sizing",
  headerClass: "aboveheader BDD7EE",
  children: ["40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L"],
}, {
  id: 37,
  pid: 26,
  columnGroupShow: 'closed',
  headerName: "Mens Jacket Sizing",
  headerClass: "aboveheader FFCC99",
  children: ["36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"],
}, {
  id: 38,
  headerName: "",
  headerClass: "",
  children: ["User Assigned 1", "User Assigned 2", "User Assigned 3", "user_inserted", "inserted_on", "user_modified", "modified_on"],
}
];
const colGrpBuy_Sheet_Master_Sizing_per = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id"]
}, {
  id: 1,
  headerName: "",
  headerClass: "",
  children: ["Year", "Season Code", "Department", "Channel", "Customer / account type", "Size Scale", "Category", "Subcat", "Collection"]
}, {
  id: 2,
  headerName: "Kids",
  headerClass: "aboveheader FFF2CC",
  children: ["2T", "3T", "4T", "5", "6", "8", "10"]
}, {
  id: 3,
  headerName: "Standard Sizing",
  headerClass: "aboveheader DDEBF7",
  children: ["OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
}, {
  id: 4,
  headerName: "Combo Sizing",
  headerClass: "aboveheader FCE4D6",
  children: ["XS/S", "S/M", "M/L", "L/XL"],
}, {
  id: 5,
  headerName: "Tall Sizing",
  headerClass: "aboveheader EDEDED",
  children: ["MT", "LT", "XLT", "XXLT"],
}, {
  id: 6,
  headerName: "Bottoms Sizing 00-16",
  headerClass: "aboveheader EDEDED",
  children: ["00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16"],
}, {
  id: 7,
  headerName: "Bottoms Sizing",
  headerClass: "aboveheader E2EFDA",
  children: ["24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42"],
}, {
  id: 8,
  headerName: "Tall Sizing",
  headerClass: "aboveheader F2F7EF",
  children: ["30T", "32T", "34T", "36T", "38T", "40T"],
}, {
  id: 9,
  headerName: "Inseam Bottoms Sizing",
  headerClass: "aboveheader C6E0B4",
  children: ["28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L"],
}, {
  id: 10,
  headerName: "DXL Shirt Sizing",
  headerClass: "aboveheader CCCCFF",
  children: ["1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT"],
}, {
  id: 11,
  headerName: "DXL Bottoms Sizing",
  headerClass: "aboveheader BDD7EE",
  children: ["40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L"],
}, {
  id: 12,
  headerName: "Mens Jacket Sizing",
  headerClass: "aboveheader FFCC99",
  children: ["36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"],
}, {
  id: 13,
  headerName: "",
  headerClass: "",
  children: ["Total", "user_inserted", "inserted_on", "user_modified", "modified_on"],
}];
const colGrpFreight_Master = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id"]
}, {
  id: 1,
  headerName: "",
  headerClass: "",
  children: ["Season", "COO"]
}, {
  id: 2,
  headerName: "Cost",
  headerClass: "aboveheader E4DFEC",
  children: ["AIR", "BOAT", "EXPRESS OCEAN", "LDP", "BLENDED"]
}, {
  id: 3,
  headerName: "Transit",
  headerClass: "aboveheader DAEEF3",
  children: ["Ocean Transit Days", "Air Transit Days", "Express Ocean Transit Days", "CFS Cut Off (Day of Week)"],
}, {
  id: 4,
  headerName: "",
  headerClass: "",
  children: ["user_inserted", "inserted_on", "user_modified", "modified_on"],
}];
const colGrpMaster_LOV = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id"]
}, {
  id: 0.1,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC"
}, {
  id: 1,
  pid: 0.1,
  headerName: "Linelist",
  headerClass: "aboveheader DAEEF3",
  children: ["Style Activity", "Category", "Sub Category", "Size Scale", "Collection", "Fabric Program", "Delivery Month", "Costing Status", "Issue Override", "Commit Coding", "Dev Category",
    "Item Upload Status", "Delivery Status", "Change Memo - Status", "Ship Mode", "Store Tier", "Seasonal Life Cycle", "Product Segmentation"]
}, {
  id: 0.2,
  headerName: "Merch, Planning",
  headerClass: "aboveheader FFE5EC"
}, {
  id: 1.1,
  pid: 0.2,
  headerName: "",
  headerClass: "aboveheader DAEEF3",
  children: ["Production Lock"]
}, {
  id: 0.3,
  headerName: "",
  headerClass: "aboveheader FFE5EC"
}, {
  id: 1.2,
  pid: 0.3,
  headerName: "",
  headerClass: "aboveheader DAEEF3",
  children: ["DTC/Wholesale Chase", "Print / Pattern", "Color", "Silhouette", "Fit"]
}, {
  id: 0.4,
  headerName: "Merch",
  headerClass: "aboveheader FFE5EC"
}, {
  id: 2,
  pid: 0.4,
  headerName: "Buy Sheet - DTC",
  headerClass: "aboveheader FDE9D9",
  children: ["Buying Decision", "Buy Method", "MMM", "Status", "Buy Status", "Working Line", "Planning Lock"]
}, {
  id: 2.1,
  headerName: "Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 3,
  pid: 2.1,
  headerName: "Buy Sheet - Wholesale",
  headerClass: "aboveheader E4DFEC",
  children: ["Customer in Store", "Universal Flag", "ATS Level", "Ranking"],
}, {
  id: 3.1,
  headerName: "Wholesale, Planning, Global Planning",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 4,
  pid: 3.1,
  headerName: "Sizing File",
  headerClass: "aboveheader C5D9F1",
  children: ["Customer", "Sales Tier", "Sizing Override", "Sizing Confirmed"],
}, {
  id: 5,
  pid: 3.1,
  headerName: "PO (On Sizing)",
  pid: 2.1,
  headerClass: "aboveheader F2DCDB",
  children: ["PO Status"],
}, {
  id: 5.1,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
}, {
  id: 6,
  pid: 5.1,
  headerName: "Cost Log (on Linelist)",
  headerClass: "aboveheader EBF1DE",
  children: ["Shipping Terms", "Factory Type", "PD Owner", "Production Owner", "Production COO / Material Mill COO", "Mill", "A/S/B", "Bulk PO Status"],
}, {
  id: 6.1,
  headerName: "Wholesale, Planning, Global Planning",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 7,
  pid: 6.1,
  headerName: "Master Sizing %",
  headerClass: "aboveheader DDD9C4",
  children: ["Customer / Account Type"],
}, {
  id: 7.1,
  headerName: "Admin",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 8,
  pid: 7.1,
  headerName: "PD Initial Costing",
  headerClass: "aboveheader DDD9C4",
  children: ["Bucket Projection (For Costing)"],
}, {
  id: 9,
  headerName: "",
  headerClass: "",
  children: ["user_inserted", "inserted_on", "user_modified", "modified_on"],
}];
const colGrpChase = [{
  id: 0,
  headerName: "",
  headerClass: "",
  children: ["row_id", "linelist_row_id", "Apply Sizing Formula"]
}, {
  id: 29,
  headerName: "Wholesale, Merch, Planning, Global Planning",
  headerClass: "aboveheader FFE5EC",
}, {
  id: 1,
  pid: 29,
  headerName: "System Core Defining Data",
  headerClass: "aboveheader FAE1DD",
  children: ["Year", "Season Code", "Department"]
}, {
  id: 2,
  pid: 29,
  headerName: "Master Line Data",
  headerClass: "aboveheader FFB3C6"
}, {
  id: 3,
  pid: 2,
  headerName: "Timing",
  headerClass: "aboveheader F7CAD0",
  children: ["Style Season", "Location", "Delivery Month", "Delivery Period", "Delivery Date/In DC", "In Store", "Half Year"]
}, {
  id: 4,
  pid: 2,
  headerName: "Style",
  headerClass: "aboveheader FFE5EC",
  children: ["Category", "Subcategory", "SKU", "Style", "Style Description", "Color Code", "Color Description", "Style Activity", "Size Scale"],
  columnGroupShow: 'closed',
}, {
  id: 5,
  pid: 29,
  headerName: "Buying",
  headerClass: "aboveheader a95D5B2",
}, {
  id: 6,
  pid: 5,
  headerName: "Delivery Timing",
  headerClass: "aboveheader ECF8F1",
  children: ["Chase Delivery", "Ship Mode"],
}, {
  id: 30,
  headerName: "",
  headerClass: "aboveheader F5F2FF",
}, {
  id: 31,
  pid: 30,
  headerName: "",
  headerClass: "aboveheader a95D5B2",
}, {
  id: 32,
  pid: 31,
  headerName: "Delivery Timing",
  headerClass: "aboveheader ECF8F1",
  children: ["Boat Timing", "Air Timing", "Ex. Ocean Timing", "Boat Freight CPU", "Air Frieght CPU", "Ex. Ocean Freight CPU"],
},
{
  id: 7,
  pid: 31,
  headerName: "Confirmation",
  headerClass: "aboveheader E1F4E8",
  children: ["Unit and Sizing Confirmed"],
},
{
  id: 8,
  pid: 31,
  headerName: "Units",
  headerClass: "aboveheader D8F3DC",
  children: ["Chase Unit Stores", "Chase Unit Ecomm", "Chase Unit DTC", "Chase Unit Wholesale", "Total Chase Units"],
},
{
  id: 8.1,
  pid: 31,
  headerName: "Sizing",
  headerClass: "aboveheader B7E4C7",
  children: ["Line Total Units", "Line Variance"],
}, {
  id: 32,
  headerName: "Planning, Global Planning, Wholesale",
  headerClass: "aboveheader a98F5E1",
}, {
  id: 33,
  pid: 32,
  headerName: "",
  headerClass: "aboveheader a95D5B2",
}, {
  id: 34,
  pid: 33,
  headerName: "Sizing",
  headerClass: "aboveheader B7E4C7",
  children: ["Sizing Override"],
}, {
  id: 35,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
},
{
  id: 9,
  headerName: "Chase Details",
  pid: 35,
  headerClass: "aboveheader a8EA9DB",
}, {
  id: 10,
  pid: 9,
  headerName: "Ownership",
  headerClass: "aboveheader B5E4FF",
  children: ["PD Owner", "Production Owner"],
},
{
  id: 11,
  pid: 9,
  columnGroupShow: 'closed',
  headerName: "Costing",
  headerClass: "aboveheader AFD9FF",
  children: ["Chase Unit Cost"],
},
{
  id: 12,
  pid: 9,
  columnGroupShow: 'closed',
  headerName: "Delivery",
  headerClass: "aboveheader AACFFF",
  children: ["X-fty Date", "Delivery Status"],
}, {
  id: 13,
  pid: 9,
  columnGroupShow: 'closed',
  headerName: "",
  headerClass: "aboveheader AACFFF",
  children: ["Sales Tier"],
},
{
  id: 14,
  pid: 9,
  columnGroupShow: 'closed',
  headerName: "Vendor Details",
  headerClass: "aboveheader A4C5FF",
  children: ["Factory", "Brand", "Corporate/Agent", "Mill", "COO", "Shipping Terms (FOB/DDP/LDP)"],
},
{
  id: 36,
  headerName: "PD",
  headerClass: "aboveheader E1F4E8",
},
{
  id: 15,
  pid: 36,
  headerName: "",
  headerClass: "aboveheader E2EFDA",
  children: ["Line Number", "PO Status", "External ID"],
},
{
  id: 16,
  headerName: "Total",
  headerClass: "aboveheader BDD7EE",
}, {
  id: 17,
  pid: 16,
  headerName: "Kids",
  headerClass: "aboveheader FFF2CC",
  children: ["2T", "3T", "4T", "5", "6", "8", "10"],
}, {
  id: 18,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Standard Sizing",
  headerClass: "aboveheader DDEBF7",
  children: ["OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"],
}, {
  id: 19,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Combo Sizing",
  headerClass: "aboveheader FCE4D6",
  children: ["XS/S", "S/M", "M/L", "L/XL"],
}, {
  id: 20,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Tall Sizing",
  headerClass: "aboveheader EDEDED",
  children: ["MT", "LT", "XLT", "XXLT"],
}, {
  id: 21,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Bottoms Sizing 00-16",
  headerClass: "aboveheader EDEDED",
  children: ["00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16"],
}, {
  id: 22,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Bottoms Sizing",
  headerClass: "aboveheader E2EFDA",
  children: ["24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42"],
}, {
  id: 23,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Tall Sizing",
  headerClass: "aboveheader F2F7EF",
  children: ["30T", "32T", "34T", "36T", "38T", "40T"],
}, {
  id: 24,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Inseam Bottoms Sizing",
  headerClass: "aboveheader C6E0B4",
  children: ["28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L"],
}, {
  id: 25,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "DXL Shirt Sizing",
  headerClass: "aboveheader CCCCFF",
  children: ["1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT"],
}, {
  id: 26,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "DXL Bottoms Sizing",
  headerClass: "aboveheader BDD7EE",
  children: ["40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L"],
}, {
  id: 27,
  pid: 16,
  columnGroupShow: 'closed',
  headerName: "Mens Jacket Sizing",
  headerClass: "aboveheader FFCC99",
  children: ["36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"],
}, {
  id: 28,
  headerName: "",
  headerClass: "",
  children: ["user_inserted", "inserted_on", "user_modified", "modified_on"],
}
];
const colGrpSummaryReport = [
  {
    id: 1,
    headerName: "",
    headerClass: "",
    children: ["Row Labels", "Count of Style"]
  },
  {
    id: 2,
    headerName: "Actuals",
    headerClass: "aboveheader A9D08E"
  },
  {
    id: 3,
    pid: 2,
    headerName: "Non-Weighted",
    headerClass: "aboveheader C9C9C9",
    children: ["Retail Margin (Actual)", "Wholesale Margin (Actual)", "Blended Brand Margin (Actual)"]
  }, {
    id: 4,
    pid: 2,
    headerName: "Weighted",
    headerClass: "aboveheader BDD7EE",
    children: ["Retail Gross Margin (Actual)", "Wholesale Gross Margin (Actual)", "Blended Brand Gross Margin (Actual)"],
    columnGroupShow: 'closed'
  },
  {
    id: 5,
    headerName: "Scenario",
    headerClass: "aboveheader FFD966"
  },
  {
    id: 6,
    pid: 5,
    headerName: "Non-Weighted",
    headerClass: "aboveheader C9C9C9",
    children: ["Retail Margin (Scenario)", "Wholesale Margin (Scenario)", "Blended Brand Margin (Scenario)"]
  },
  {
    id: 7,
    pid: 5,
    headerName: "Weighted",
    headerClass: "aboveheader BDD7EE",
    children: ["Retail Gross Margin (Scenario)", "Wholesale Gross Margin (Scenario)", "Blended Brand Gross Margin (Scenario)"],
    columnGroupShow: 'closed'
  },
  {
    id: 8,
    headerName: "Actuals vs. Scenario",
    headerClass: "aboveheader F8CBAD"
  },
  {
    id: 9,
    pid: 8,
    headerName: "Non-Weighted",
    headerClass: "aboveheader C9C9C9",
    children: ["Retail Margin Variance", "Wholesale Margin Variance", "Blended Brand Margin Variance"]
  },
  {
    id: 10,
    pid: 8,
    headerName: "Weighted",
    headerClass: "aboveheader BDD7EE",
    children: ["Retail Gross Margin Variance", "Wholesale Gross Margin Variance", "Blended Brand Gross Margin Variance"],
    columnGroupShow: 'closed',
  },
  {
    id: 11,
    headerName: "",
    headerClass: "",
    children: ["DTC Units Total", "Wholesale Units Total", "Brand Units Total", "Total DTC SLS $", "Total WHSL SLS $", "Total Brand SLS $"]
  }
];

export {
  colGrpLinelist,
  colGrpSummaryReport
}
