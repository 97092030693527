import _, { filter } from "lodash";
import { getSessionDataByTblName, getStateDataByTblName } from "./getChangesComman";
import { restApiPath } from "utils/app.props";
import { store } from "store";
import { TextField } from "@mui/material";
import { toast } from 'react-toastify';
import { utcDifference } from "utils/app.props";
import { xlEqualToThenReturnColValsCount, xlEqualToThenReturnColSum } from "./XL_Calculation"
import React from "react";
import ReactDOM from "react-dom";

export const getColumnPrefByTblName = (req_tbl) => {
  const redux = store.getState();
  if (req_tbl === "app_user")
    return redux.ManageUserColPref;
  else if (req_tbl === "xl_action_item_details")
    return redux.ActionColPref;
  else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts")
    return redux.BuyShtMasterDataChartsColPref;
  else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage")
    return redux.BuyShtMasterDataChartsColPref;
  else if (req_tbl === "xl_faherty_chase")
    return redux.ChaseColPref;
  else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm")
    return redux.CompAPSMasterStoreEcomColPref;
  else if (req_tbl === "xl_faherty_delivery_master")
    return redux.DeliveryMasterColPref;
  else if (req_tbl === "xl_faherty_dtc_buy_sheet")
    return redux.DTCBuySheetColPref;
  else if (req_tbl === "xl_faherty_fb_fty_database")
    return redux.FBFtyDatabaseColPref;
  else if (req_tbl === "xl_faherty_freight_by_category")
    return redux.FreightbyCategoryColPref;
  else if (req_tbl === "xl_faherty_freight_kg_rates")
    return redux.FreightMasterColPref;
  else if (req_tbl === "xl_faherty_linelist")
    return redux.LinelistColPref;
  else if (req_tbl === "xl_faherty_master_lov")
    return redux.MasterLOVColPref;
  else if (req_tbl === "xl_faherty_merch_assortment_sandbox")
    return redux.MerchAssortmentSandboxColPref;
  else if (req_tbl === "xl_faherty_season")
    return redux.SeasonColPref;
  else if (req_tbl === "xl_faherty_season_inquiry")
    return redux.SeasonInquiryColPref;
  else if (req_tbl === "xl_faherty_shopify_product_type_mapping")
    return redux.ShopifyProductTypeMapColPref;
  else if (req_tbl === "xl_faherty_size_scales")
    return redux.SizeScalesColPref;
  else if (req_tbl === "xl_faherty_sizing")
    return redux.SizingColPref;
  else if (req_tbl === "xl_faherty_whsl_buy_sheet")
    return redux.WhslBuySheetColPref;
  else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp")
    return redux.WhslBuySheetNordRPColPref;
  else if (req_tbl === "xl_pd_initial_costing")
    return redux.PDInitialCostingColPref;
  else if (req_tbl === "xl_user_data_access")
    return redux.ManagePermissionColPref;
  else if (req_tbl === "xl_faherty_airtable_mapping_data")
    return redux.AirtableMappingColPref;
}
export const getGroupColumnPrefByTblName = (req_tbl) => {
  const redux = store.getState();
  if (req_tbl === "xl_faherty_buy_sheet_master_data_charts")
    return redux.BuyShtMasterDataChartsGrpColPref;
  else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage")
    return redux.BuySheetMasterSizingGrpColPref;
  else if (req_tbl === "xl_faherty_chase")
    return redux.ChaseGrpColPref;
  else if (req_tbl === "xl_faherty_dtc_buy_sheet")
    return redux.DTCBuySheetGrpColPref;
  else if (req_tbl === "xl_faherty_freight_by_category")
    return redux.MasterLOVGrpColPref;
  else if (req_tbl === "xl_faherty_freight_kg_rates")
    return redux.FreightMasterGrpColPref;
  else if (req_tbl === "xl_faherty_linelist")
    return redux.LinelistGrpColPref;
  else if (req_tbl === "xl_faherty_sizing")
    return redux.SizingGrpColPref;
  else if (req_tbl === "xl_faherty_whsl_buy_sheet")
    return redux.WhslBuySheetGrpColPref;
  else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp")
    return redux.WhslBuySheetNordRPGrpColPref;
}
export const toastMessage = (message, color) => {

  if (message && message !== "") {
    const toastConfig = {
      // toastId: Date.now(),
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: 'toastify-custom',
      theme: "colored",
    }
    // console.log("json", toastConfig)

    switch (color) {
      case 'error':
        toast.error(message, toastConfig);
        break;
      case 'warning':
        toast.warning(message, toastConfig);
        break;
      case 'success':
        toast.success(message, toastConfig);
        break;
      default:
        toast.info(message, toastConfig);
    }
  }
};
const giveMeColData = (listCol, colDef) => {
  let i = 0;
  const listofcol = colDef.filter((c) => {
    if (listCol && listCol.includes(c.field)) {
      let mpCol = c;
      if (i !== 0) mpCol["columnGroupShow"] = "closed";
      i++;
      return mpCol
    }
  });
  return listofcol;
}
export const getReduxByTableName = (req_tbl) => {
  const reduxState = store.getState();
  if (req_tbl === "xl_faherty_linelist") {
    return ({
      "ssnData": reduxState.LinelistDataSSN,
      "stateData": reduxState.LinelistData,
      "colDef": reduxState.LinelistColDef,
      "unsentCount": reduxState.LineListUnsentCount,
      "highlightCell": reduxState.LineListHighlistCell,
      "sorting": reduxState.LinelistSorting,
      "filtering": reduxState.LinelistFilters,
      "colPref": []
    })
  }
  else if (req_tbl === "xl_faherty_chase") { return reduxState.ChaseDataSSN; }
  else if (req_tbl === "xl_faherty_dtc_buy_sheet") { return reduxState.DtcBuySheetDataSSN; }
  else if (req_tbl === "xl_faherty_whsl_buy_sheet") { return reduxState.WhslBuySheetDataSSN; }
  else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp") { return reduxState.WhslBuySheetNordRpDataSSN; }
  else if (req_tbl === "xl_faherty_sizing") { return reduxState.SizingDataSSN; }
  else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage") { return reduxState.BuySheetMasterSizingPercentageDataSSN; }
  else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts") { return reduxState.BuyShtMasterDataChartsDataSSN; }
  else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm") { return reduxState.CompApsMasterStoreEcomDataSSN; }
  else if (req_tbl === "xl_faherty_freight_kg_rates") { return reduxState.FreightMasterDataSSN; }
  else if (req_tbl === "xl_faherty_freight_by_category") { return reduxState.FreightByCategoryDataSSN; }
  else if (req_tbl === "xl_faherty_delivery_master") {
    return ({
      "ssnData": reduxState.DeliveryMasterDataSSN,
      "stateData": reduxState.DeliveryMasterData,
      "colDef": reduxState.DeliveryMasterColDef,
      "unsentCount": reduxState.DeliveryMasterUnsentCount,
      "highlightCell": reduxState.DeliveryMasterHighlistCell,
      "sorting": reduxState.DeliveryMasterSorting,
      "filtering": reduxState.DeliveryMasterFilters,
      "colPref": []
    })
  }
  else if (req_tbl === "xl_faherty_fb_fty_database") { return reduxState.FbFtyDatabaseDataSSN; }
  else if (req_tbl === "xl_faherty_size_scales") { return reduxState.SizeScalesDataSSN; }
  else if (req_tbl === "xl_faherty_shopify_product_type_mapping") { return reduxState.ShopifyProductTypeMappingDataSSN; }
  else if (req_tbl === "xl_faherty_season_inquiry") { return reduxState.SeasonInquiryDataSSN; }
  else if (req_tbl === "xl_faherty_master_lov") { return reduxState.MasterLovDataSSN; }
  else if (req_tbl === "xl_faherty_season") { return reduxState.SeasonDataSSN; }
  else if (req_tbl === "xl_action_item_details") { return reduxState.ActionItemDataSSN; }
  else if (req_tbl === "app_user") { return reduxState.ManageUserDataSSN; }
  else if (req_tbl === "xl_user_data_access") { return reduxState.ManagePermissionDataSSN; }
}
export const checkActionItemAssigned = (ActionItemData, params, tbl_name) => {
  const useremail = sessionStorage.getItem('email');
  const ActionItem = ActionItemData.find((c) => c.tab_name === tbl_name && c.data_table_row_id === params.node.data.row_id && (c.user_inserted === useremail || (c["Assigned To"] && c["Assigned To"].includes(useremail))));
  if (ActionItem) {
    return true;
  }
  return false;
}
export const getColumnStyle = (tmpColDef, value, params, col) => {
  let bgColor = "";
  let bdrColor = "";
  let txtDeco = "";
  let fontColor = "#000000";
  let boldTxt = "normal";
  let borderBottom = "";
  let isChangeFound = false;

  const colDef = tmpColDef.find((cl) => cl.field === col.field);
  if (colDef) {

    //get row format rule from db
    const tmpRule = colDef.row_highlight_rules ? colDef.row_highlight_rules : "";
    let isRowLevelFormat = false;

    if (tmpRule && tmpRule !== "") {
      const rule = JSON.parse(tmpRule);
      const { rules } = rule;

      const { data } = params.node;
      rules.some((format) => {
        let status = true;
        const cols = format.columnname ? format.columnname.split("|") : [];
        const ignoreCols = format.ignorecolumns ? format.ignorecolumns.split("|") : [];
        const values = format.value ? format.value.split("|") : [];
        const style = format.format;

        if ((ignoreCols.length === 0 || !ignoreCols.includes(col.headerName)) && data) {
          cols.map((cl, index) => {
            if (data[cl] && data[cl].toLowerCase() !== values[index].toLowerCase()) {
              status = false;
              return false;
            } else if (!data[cl]) {
              status = false;
              return false;
            }
          })
          if (status) {
            if (style.fontcolor && style.fontcolor !== "") fontColor = style.fontcolor;
            if (style.bgcolor && style.bgcolor !== "") bgColor = style.bgcolor;
            if (style.fontstrikethrough && style.fontstrikethrough !== "") txtDeco = "line-through";
            if (style.fontbold && style.fontbold !== "") boldTxt = 'bold';
            if (style.fontitalic && style.fontitalic !== "") fontStyle = 'italic';
            if (style.textDecoration && style.textDecoration !== "") txtDeco = style.textDecoration;
            isChangeFound = true;

            isRowLevelFormat = true;
            return true;
          }
        }
      })
    }
    if (!isRowLevelFormat) {
      const { conditional_format } = colDef;
      const { format } = colDef;

      if (format && format === "fontbold") boldTxt = 'bold';
      if (conditional_format && conditional_format !== "" && conditional_format !== "cellbar") {
        const tmp = JSON.parse(conditional_format);
        const { conditions } = tmp;
        conditions.map((condition) => {
          const { formula } = condition;
          let conValue = formula.replace("=RC=", "");
          conValue = conValue.replaceAll('"', '');

          if (value && value.toLowerCase() === conValue.toLowerCase()) {
            isChangeFound = true;
            if (condition.fontcolor && condition.fontcolor !== "") fontColor = condition.fontcolor;
            if (condition.bgcolor && condition.bgcolor !== "") bgColor = condition.bgcolor;
            if (condition.textDecoration && condition.textDecoration !== "") txtDeco = condition.textDecoration;
            if (condition.fontbold && condition.fontbold !== "") boldTxt = 'bold';
            if (condition.fontItalic && condition.fontItalic !== "") fontStyle = 'italic';
          }
        })
      }
    }
  }

  return { status: isChangeFound, bgColor: bgColor, bdrColor: bdrColor, txtDeco: txtDeco, fontColor: fontColor, boldTxt: boldTxt, borderBottom: borderBottom }
}
export const getRowNodeFromId = (id, gridApi) => {
  let rowNode = null;
  gridApi.current.forEachNode((node, index) => {
    if (node.data.row_id === id) {
      rowNode = node;
      return node;
    }
  })
  return rowNode;
};
export const getHighlightByTblName = (req_tbl) => {
  const reduxState = store.getState();
  if (req_tbl === "xl_faherty_linelist") { return reduxState.LineListHighlistCell; }
  else if (req_tbl === "xl_faherty_chase") { return reduxState.ChaseHighlistCell; }
  else if (req_tbl === "xl_faherty_dtc_buy_sheet") { return reduxState.DtcButSheetHighlistCell; }
  else if (req_tbl === "xl_faherty_whsl_buy_sheet") { return reduxState.WhslBuySheetHighlistCell; }
  else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp") { return reduxState.WhslBuySheetNordHighlistCell; }
  else if (req_tbl === "xl_faherty_sizing") { return reduxState.SizingHighlistCell; }
  else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage") { return reduxState.BuySheetMasterSizingPercentageHighlistCell; }
  else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts") { return reduxState.BuyShtMasterDataChartsHighlistCell; }
  else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm") { return reduxState.CompApsMasterStoreEcomHighlistCell; }
  else if (req_tbl === "xl_faherty_freight_kg_rates") { return reduxState.FreightMasterHighlistCell; }
  else if (req_tbl === "xl_faherty_freight_by_category") { return reduxState.FreightByCategoryHighlistCell; }
  else if (req_tbl === "xl_faherty_delivery_master") { return reduxState.DeliveryMasterHighlistCell; }
  else if (req_tbl === "xl_faherty_fb_fty_database") { return reduxState.FbFtyDatabaseHighlistCell; }
  else if (req_tbl === "xl_faherty_size_scales") { return reduxState.SizeScalesHighlistCell; }
  else if (req_tbl === "xl_faherty_shopify_product_type_mapping") { return reduxState.ShopifyProductTypeMappingHighlistCell; }
  else if (req_tbl === "xl_faherty_season_inquiry") { return reduxState.SeasonInquiryHighlistCell; }
  else if (req_tbl === "xl_faherty_master_lov") { return reduxState.MasterLovHighlistCell; }
  else if (req_tbl === "xl_faherty_season") { return reduxState.SeasonHighlistCell; }
  else if (req_tbl === "xl_pd_initial_costing") { return reduxState.PDInitialCostingHighlistCell; }
  else if (req_tbl === "xl_faherty_merch_assortment_sandbox") { return reduxState.MASHighlistCell; }
  else if (req_tbl === "xl_faherty_airtable_mapping_data") { return reduxState.AirtableMappingHighlistCell; }
}
export const getColumnDefByTblName = (req_tbl) => {
  const reduxState = store.getState();
  if (req_tbl === "xl_faherty_linelist") { return reduxState.LinelistColDef; }
  else if (req_tbl === "xl_faherty_chase") { return reduxState.ChaseColDef; }
  else if (req_tbl === "xl_faherty_dtc_buy_sheet") { return reduxState.DtcBuySheetColDef; }
  else if (req_tbl === "xl_faherty_whsl_buy_sheet") { return reduxState.WhslBuySheetColDef; }
  else if (req_tbl === "xl_faherty_whsl_buy_sheet_nord_rp") { return reduxState.WhslBuySheetNordRpColDef; }
  else if (req_tbl === "xl_faherty_sizing") { return reduxState.SizingColDef; }
  else if (req_tbl === "xl_faherty_buy_sheet_master_sizing_percentage") { return reduxState.BuySheetMasterSizingPercentageColDef; }
  else if (req_tbl === "xl_faherty_buy_sheet_master_data_charts") { return reduxState.BuyShtMasterDataChartsColDef; }
  else if (req_tbl === "xl_faherty_comp_aps_master_stores_ecomm") { return reduxState.CompApsMasterStoreEcomColDef; }
  else if (req_tbl === "xl_faherty_freight_kg_rates") { return reduxState.FreightMasterColDef; }
  else if (req_tbl === "xl_faherty_freight_by_category") { return reduxState.FreightByCategoryColDef; }
  else if (req_tbl === "xl_faherty_delivery_master") { return reduxState.DeliveryMasterColDef; }
  else if (req_tbl === "xl_faherty_fb_fty_database") { return reduxState.FbFtyDatabaseColDef; }
  else if (req_tbl === "xl_faherty_size_scales") { return reduxState.SizeScalesColDef; }
  else if (req_tbl === "xl_faherty_shopify_product_type_mapping") { return reduxState.ShopifyProductTypeMappingColDef; }
  else if (req_tbl === "xl_faherty_season_inquiry") { return reduxState.SeasonInquiryColDef; }
  else if (req_tbl === "xl_faherty_master_lov") { return reduxState.MasterLovColDef; }
  else if (req_tbl === "xl_faherty_season") { return reduxState.SeasonColDef; }
  else if (req_tbl === "xl_action_item_details") { return reduxState.ActionItemColDef; }
  else if (req_tbl === "app_user") { return reduxState.ManageUserColDef; }
  else if (req_tbl === "xl_user_data_access") { return reduxState.ManagePermissionColDef; }
  else if (req_tbl === "xl_pd_initial_costing") { return reduxState.PDInitialCostingColDef; }
  else if (req_tbl === "Linelist_Import") { return reduxState.LinelistImportColDef; }
  else if (req_tbl === "PD_Initial_Costing_Import") { return reduxState.PdInitialCostingImportColDef; }
  else if (req_tbl === "Sizing_Import") { return reduxState.SizingImportColDef; }
  else if (req_tbl === "Comp_APS_Master_StoreEco_Import") { return reduxState.CompApsMasterStoreEcomImportColDef; }
  else if (req_tbl === "xl_data_transfer_rules") { return reduxState.DataTransferRulesColDef; }
  else if (req_tbl === "xl_faherty_merch_assortment_sandbox") { return reduxState.MerchAssortmentSandboxColDef; }
  else if (req_tbl === "Merch_Assortment_Sandbox_Import") { return reduxState.MerchAssortmentSandboxImportColDef; }
  else if (req_tbl === "xl_faherty_airtable_mapping_data") { return reduxState.AirtableMappingColDef; }
}
export function generateColumnGrouping(data, temp) {
  let tree = [];
  let childrenOf = {};
  data.forEach((item) => {
    let newItem = { ...item, children: [] }; // Make sure each item can have children
    childrenOf[item.id] = childrenOf[item.id] || [];
    newItem.children = item.children ? giveMeColData(item.children, temp) : childrenOf[item.id];
    if (item.pid === undefined) {
      tree.push(newItem);
    } else {
      childrenOf[item.pid] = childrenOf[item.pid] || [];
      childrenOf[item.pid].push(newItem);
    }
  });
  return tree;
}
function hasOnlyDigits(value) {
  return /^\d+$/.test(value);
}
const fillZeros = (a) => (Number(a) < 10 ? "0" + a : a);
function padValue(value) {
  return value < 10 ? "0" + value : value;
}
let div = "";
let textInput = null;
export function ExcelDateToJsDate(serial) {
  if (typeof serial === 'string' && serial.includes("/")) return serial
  else if (serial === undefined || serial === null || serial === "") return ""

  let utc_days = Math.floor(serial - 25569);
  let utc_value = utc_days * 86400;
  let dt1 = new Date(utc_value * 1000);
  let dt = new Date(dt1.getTime() + Math.abs(dt1.getTimezoneOffset() * 60000));
  const retDate = ((dt.getMonth() > 8) ? (dt.getMonth() + 1) : ('0' + (dt.getMonth() + 1))) + '/' + ((dt.getDate() > 9) ? dt.getDate() : ('0' + dt.getDate())) + '/' + dt.getFullYear();
  return retDate;
}
export const CH1 = String.fromCharCode(1);
export const CH2 = String.fromCharCode(2);
export const CH3 = String.fromCharCode(3);
export const CH4 = String.fromCharCode(4);
export const CH5 = String.fromCharCode(5);
export const CH6 = String.fromCharCode(6);
export const CH7 = String.fromCharCode(7);
export const doubleQuote = String.fromCharCode(34);
export const PipeDel = String.fromCharCode(124);
export const ignoreColumnsInCacheCompare = [
  "row_id",
  "user_inserted",
  "inserted_on",
  "user_modified",
  "modified_on",
];
export const editableCriteriaColumns = ["Year", "Season Code", "Department"];
export const cstFilterOptions = [
  "empty",
  {
    displayKey: "blanks",
    displayName: "Blanks",
    test: function (filterValue, cellValue) {
      return cellValue === undefined || cellValue === "" || cellValue === null;
    },
    hideFilterInput: true,
  },
  {
    displayKey: "notblanks",
    displayName: "Not Blanks",
    test: function (filterValue, cellValue) {
      return cellValue !== "";
    },
    hideFilterInput: true,
  },
  "equals",
  "notEqual",
  "lessThan",
  "lessThanOrEqual",
  "greaterThan",
  "greaterThanOrEqual",
  "inRange",
];
export const dateValueFormatter = function (data) {
  if (data !== null && data !== undefined && data !== "") {
    const value = data.value;
    // console.log("value: "+value)
    if (hasOnlyDigits(value)) {
      return ExcelDateToJsDate(value);
    } else {
      return value;
    }
  } else {
    return null;
  }
};
export const dateFilterParams = {
  filters: [
    // {
    //   filter: "agDateColumnFilter",
    //   filterParams: {
    //     comparator: function (filterLocalDateAtMidnight, cellValue) {
    //       if (cellValue === null || cellValue === undefined || cellValue === "") return -1;
    //       const dt = filterLocalDateAtMidnight.getTime();
    //       const lcldt = new Date(dt);
    //       const dd = lcldt.getDate();
    //       const mm = lcldt.getMonth() + 1;
    //       const yyyy = lcldt.getFullYear();
    //       const retVal = fillZeros(mm) + "/" + fillZeros(dd) + "/" + yyyy.toString().substr(2, 2);
    //       if (hasOnlyDigits(cellValue)) cellValue = ExcelDateToJsDate(cellValue);
    //       if (cellValue === retVal) return 0;
    //       const myDate = new Date(cellValue);
    //       if (myDate < lcldt) return -1;
    //       if (myDate > lcldt) return 1;
    //     },
    //     browserDatePicker: true,
    //     minValidYear: 2000,
    //     maxValidYear: 2100,
    //     valueFormatter: dateValueFormatter,    
    //     filterOptions: cstFilterOptions,
    //   },
    // },
    {
      filter: "agSetColumnFilter",
      filterParams: {
        comparator: function (a, b) {
          return getDate(a) - getDate(b);
        },
        valueFormatter: dateValueFormatter,
      },
    },
  ],
};
export const isEditable = function (params, fldNm, edt, insert_editable) {
  if (!params?.node?.data) return false;
  if (params.node.data.row_id === "total") { return false }
  if (fldNm.includes("Assort") || fldNm.includes("Upload To Linelist Check")) return false;
  else if (params.node.rowPinned) return false;
  else if (params.node.data && (!params.node.data.row_id || params.node.data.row_id === "")) {
    if (insert_editable && insert_editable !== "") return insert_editable;
    return edt;
  }
  return edt
};
export function checkHeihlight(key, highlightCells) {
  if (!highlightCells) return false;
  if (highlightCells.length <= 0) {
    return false;
  }
  const arr = highlightCells;
  let check = false;
  let colorCode = 0;
  arr.map((e) => {
    if (e[key]) {
      colorCode = e[key];
      check = true;
      return true;
    }
  });
  const obj = {
    colorCode: colorCode,
    status: check,
  };
  return obj;
}
// async function getAllActiveUserData() {
//   const response = await fetch(restApiPath + "getAllUser", {
//     method: "POST",
//     headers: {
//       Authorization: "bearer " + sessionStorage.getItem("access_token")
//     }
//   });
//   const json = await response.json();
//   // console.log("getAllActiveUserData -> json")
//   // console.log(json)
//   if (json.error === "invalid_token") {
//     return "invalid_token";
//   } else if (json.response === true) {
//     return json.data;
//   }
// }
async function getAllActiveUserData() {
  let current_user_type = sessionStorage.getItem("user_type")

  console.log("current_user_type ", current_user_type)
  const response = await fetch(restApiPath + "getAllUser", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token")
    }
  });
  const json = await response.json();
  if (json.error === "invalid_token") {
    return "invalid_token";
  } else if (json.response === true) {
    if (current_user_type !== 'vendor') {
      return json.data;
    }
    else {
      const filteredUsers = json.data.filter(user => user.user_type !== 'vendor')
      return filteredUsers
    }
  }
}
async function GetTableDataLocale(postBody) {
  // console.log("GetTableDataLocale -> postBody -> " + postBody)
  const response = await fetch(restApiPath + "getTableDataLocale", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: postBody,
  });
  const json = await response.json();
  if (json.error === "invalid_token") {
    return "invalid_token";
  } else if (json.response === true) {
    // return json.data;
    return json;
  }
}
async function GetColsDataSource(postBody) {
  const response = await fetch(restApiPath + "getColumnsDataSource", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: postBody,
  });
  const json = await response.json();
  if (json.error === "invalid_token") {
    return "invalid_token";
  } else if (json.response === true) {
    return json.data;
  }
}
async function GetTableColumnsDefinition(postBody) {
  const response = await fetch(restApiPath + "getTableColumnsDefinition", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: postBody,
  });
  const json = await response.json();
  return JSON.parse(json.data);
}
export function createAsRequiredDownloadQuery(data) {
  let finalQry = "";
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const qry =
      "Year" +
      CH1 +
      row.Year +
      CH2 +
      "Season Code" +
      CH1 +
      row["Season Code"] +
      CH2 +
      "Department" +
      CH1 +
      row.Department;
    if (finalQry === "") {
      finalQry = qry;
    } else {
      finalQry = finalQry + CH3 + qry;
    }
  }
  return finalQry;
}
function getDate(value) {
  if (value === "" || value === null || value === undefined)
    return "";
  var dateParts = value.split('/');

  return new Date(
    Number(dateParts[2]),
    Number(dateParts[1]),
    Number(dateParts[0])
  );
}
export const uniqueValues = (field, data) => {
  const lowerCaseSet = new Set(
    data
      .map(item => item[field])
      .filter(value => value !== null && value !== undefined && value.trim() !== '')
      .map(value => value.toLowerCase())
  );
  return Array.from(lowerCaseSet).map(lowerCaseItem =>
    data.find(item => item[field].toLowerCase() === lowerCaseItem)[field]
  );
};

export function getDatePicker() {
  function Datepicker() { }
  Datepicker.prototype.init = function (params) {
    textInput = React.createRef();
    const eInput = React.createElement(TextField, {
      type: "date",
      defaultValue: ExcelDateToJsDate(params.value),
      ref: textInput,
      id: "myText",
    });
    div = document.createElement("div");
    div.setAttribute("id", "id-dp-div");
    div.className = "ag-cell-parent-append";
    ReactDOM.render(eInput, div);
  };
  Datepicker.prototype.getGui = function () {
    return div;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    if (!textInput.current) return true;
    textInput.current.focus();
  };
  Datepicker.prototype.getValue = function () {
    const def = textInput.current.querySelector("input").defaultValue;
    let dpVal = textInput.current.querySelector("input").value;
    dpVal = dpVal.replace("undefined-", "").replace("-undefined", "");
    dpVal = dpVal.replace("-", "/");
    dpVal = dpVal.replace("-", "/");
    let retDate = def;
    // console.log(def + "...." + dpVal)
    if (dpVal !== null && dpVal !== undefined && dpVal.trim() !== "") {
      const dt = new Date(dpVal);
      retDate = ((dt.getMonth() > 8) ? (dt.getMonth() + 1) : ("0" + (dt.getMonth() + 1))) + "/" + ((dt.getDate() > 9) ? dt.getDate() : ("0" + dt.getDate())) + "/" + dt.getFullYear();
    }
    // else {
    //    retDate = "";
    // }
    return retDate;
  };
  Datepicker.prototype.destroy = function () { };
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
}
export const getColumnIndex = (column, gridColumnApi) => {
  return (
    gridColumnApi.current
      .getColumns()
      ?.findIndex(col => col.colId === column.colId) ?? -1
  );
};
export const findColumnPosOnName = (clmNm, gridApi) => {
  return (
    gridApi
      .getColumns()
      ?.findIndex(col => col.colDef.field === clmNm) ?? -1
  );
};
async function getCellTimeline(TemplateName, ColName, RowId, TableName, EmailId) {
  const postBody = {
    "template_name": TemplateName,
    "column_name": ColName,
    "row_id": RowId,
    "table_name_param": TableName,
    "email_param": EmailId,
    "time_diff_hours": utcDifference
  };
  const response = await fetch(restApiPath + "getCellHistoryNew", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postBody),
  });
  const json = await response.json();
  return json.data;
}
async function GetEvolutionStatisticsData(postBody) {
  const response = await fetch(restApiPath + "getEvolutionStatistics", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: postBody,
  });
  const json = await response.json();
  return json.data;
}
async function GetLatestTxData(reqTbl) {
  const postbody = '{"table_name":"' + reqTbl + '"}';
  const response = await fetch(restApiPath + "getLatestTx", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: postbody,
  });
  const json = await response.json();
  if (json.error === "invalid_token") {
    return "invalid_token";
  } else if (json.response === true) {
    return json.data;
  }
}
async function getKpiMetricsData(postBody) {
  const response = await fetch(restApiPath + "getKpiMetrics", {
    method: "POST",
    headers: {
      Authorization: "bearer " + sessionStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: postBody,
  });
  const json = await response.json();
  return json.data;
}
function formatDateToString(date) {
  if (date !== null && date !== undefined && date !== "") {
    const myDate = new Date(date);
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
    const str = ("0" + myDate.getDate()).slice(-2) + "-" + month + "-" + myDate.getFullYear();
    return str;
  }
  return "";
}
function formatDateToFullString(date) {
  if (date !== null && date !== undefined && date !== "") {
    const dt = new Date(date);
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][dt.getMonth()];
    const str = ("0" + dt.getDate()).slice(-2) + "-" + month + "-" + dt.getFullYear() + " " + dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    return str;
  }
  return "";
}
function formatDateToMmDdYyyyHhMmSsAmPm(dateVal) {
  if (dateVal !== null && dateVal !== undefined && dateVal !== "") {
    const sMonth = padValue(dateVal.getMonth() + 1);
    const sDay = padValue(dateVal.getDate());
    const sYear = dateVal.getFullYear();
    let sHour = dateVal.getHours();
    const sMinute = padValue(dateVal.getMinutes());
    const sSecond = padValue(dateVal.getSeconds());
    let sAMPM = "AM";
    const iHourCheck = parseInt(sHour);
    if (iHourCheck > 12) {
      sAMPM = "PM";
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = "12";
    }
    sHour = padValue(sHour);
    const str =
      sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + ":" + sSecond + " " + sAMPM;
    return str;
  }
  return "";
}
function getCurrentDateOnlyAsYyyyMmDd() {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  const formattedToday = yyyy + "/" + mm + "/" + dd;
  return formattedToday;
}
function GetDbTableNameBasedOnSheetName(data, SheetName) {
  let tblName = "";
  if (data.length > 0) {
    data.map((row) => {
      if (row.sheet_name === SheetName) {
        tblName = row.table_name;
      }
    });
  }
  return tblName;
}
function GetRowFilterBasedOnTableName(data, tblName) {
  let rowFilter = "";
  if (data.length > 0) {
    data.map((row) => {
      if (row.table_name === tblName) {
        rowFilter = row.filter_row_access;
      }
    });
  }
  return rowFilter;
}
function getTableAsRequired(data) {
  let tblRow;
  if (data.length > 0) {
    data.map((row) => {
      if (row.on_demand_download_sheet !== null && row.on_demand_download_sheet !== undefined && row.on_demand_download_sheet.toLowerCase() === "yes") {
        tblRow = row;
      }
    });
  }
  return tblRow;
}
export const ProperCase = (str) => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substr(1);
  }
  return splitStr.join(" ");
}
export function getDataForRowId(data, rowIdVl) {
  let retData;
  for (let i = 0; i < data.length; i++) {
    const tmp = data[i];
    if (tmp.row_id === rowIdVl) {
      retData = data[i];
      return retData;
    }
  }
  return "";
}
export const getDataForRowIdFromSession = (data, rowIdVl) => {
  let retData;
  for (let i = 0; i < data.length; i++) {
    const tmp = data[i];
    if (tmp.row_id === rowIdVl) {
      retData = data[i];
      return retData;
    }
  }
  return "";
};
export function JsDateToExcelDateNumber(dateString) {
  let retVal = "";
  if (dateString !== null && dateString !== undefined && dateString.toString().trim() !== "") {
    if (!dateString.toString().includes("/")) return dateString;
    const daysVal = Math.round((new Date(dateString) - new Date(1899, 11, 30)) / 8.64e7);
    retVal = parseInt(daysVal);
  }
  return retVal;
}
const myValueFormatter = function (params, fldNm, ct) {
  const value = params.value;
  if (fldNm === "Department" && value !== "") {
    return ProperCase(value);
  } else if (fldNm === "Season Code" && value !== "") {
    return value.toUpperCase();
  }
  if (params.node.data.row_id === "total" && typeof value === "number") {
    return parseFloat(value).toFixed(2) + "%";
  } else if (ct !== null && ct !== undefined && ct !== "" && params.value !== null && params.value !== undefined && params.value !== "" && params.value !== true && params.value !== false && params.value.toString().toLowerCase() !== "true" && params.value.toString().toLowerCase() !== "false" && params.value.toString().toLowerCase() !== "na") {
    if (ct === "price") {
      if (params.value === null || params.value === undefined || params.value === "") {
        return params.value;
      } else {
        const formattedNumber = parseFloat(params.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return "$" + formattedNumber;
      }
    } else if (ct === "percentage") {
      if (params.value === null || params.value === undefined || params.value === "") {
        return params.value;
      } else {
        if (value.toString().includes(".")) {
          if (params.value > 1) {
            return parseFloat(params.value).toFixed(2) + "%";
          } else {
            return parseFloat(params.value * 100).toFixed(2) + "%";
          }
        } else {
          return parseFloat(params.value).toFixed(2) + "%";
        }
      }
    } else {
      return params.value;
    }
  }
  return true;
};
const myValueParser = function (params, ct) {
  const newVal = params.newValue;
  if (ct !== null && ct !== undefined && ct !== "" && params.newValue !== null && params.newValue !== undefined && params.newValue !== "" && params.newValue !== true && params.newValue !== false && params.newValue.toLowerCase() !== "true" && params.newValue.toLowerCase() !== "false" && params.newValue.toLowerCase() !== "na") {
    if (ct === "percentage") {
      if (params.oldValue !== newVal) return (parseFloat(newVal) / 100).toFixed(6) + "";
      else return params.oldValue;
    } else return newVal;
  } else return newVal;
};
export function getColumnDefination(res, tblName, mpAllTblsColsDataSource, highlightCells) {
  const ColsDef = [];
  res.forEach((cn) => {
    let mpCol;
    const hdrNm = cn.headerName;
    const fldNm = cn.field;
    const clId = cn.colId;
    const wdth = cn.width;
    const edt = cn.editable;
    const hd = cn.hide;
    const ct = cn.changeType;
    if (edt !== null && edt !== undefined && edt !== "" && edt !== false) {
      // editble cell stlye and formatter
      const clDsList = mpAllTblsColsDataSource.get(clId);
      if (clDsList !== undefined && clDsList !== null && clDsList.length > 0) {
        mpCol = {
          headerName: hdrNm,
          field: fldNm,
          colId: clId,
          width: wdth,
          editable: (params) => {
            if (params.node.data.row_id === "total") return false;
            return edt;
          },
          hide: hd,
          cellStyle: (params) => {
            if (params.node.data.row_id === "total") { return { backgroundColor: "#f0f0f0", fontWeight: "bold", fontSize: "13px" } }
            const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
            const obj = checkHeihlight(key, highlightCells.current);
            if (obj.status) {
              // to heighlight changes
              if (obj.colorCode === 1) return { backgroundColor: "cyan" };
              else return { backgroundColor: "#d5a7f2" };
            } else {
              return { backgroundColor: "white" };
            }
          },
          cellEditor: "agSelectCellEditor",
          cellEditorParams: () => {
            return { values: mpAllTblsColsDataSource.get(clId) };
          },
          suppressKeyboardEvent: (params) => {
            if (!params.editing) {
              const isBackspaceKey = params.event.keyCode === 8;
              const isDeleteKey = params.event.keyCode === 46;
              if (isBackspaceKey || isDeleteKey) {
                const colId = params.colDef.colId;
                const rowNode = params.node;
                rowNode.setDataValue(colId, "");
                return true;
              }
            }
            return false;
          },
        };
      } else {
        if (ct !== null && ct !== undefined && ct !== "" && ct === "date") {
          mpCol = {
            headerName: hdrNm,
            field: fldNm,
            colId: clId,
            width: wdth,
            filter: "agSetColumnFilter",
            filterParams: dateFilterParams,
            hide: hd,
            cellStyle: (params) => {
              if (params.node.data.row_id === "total") { return { backgroundColor: "#f0f0f0", fontWeight: "bold", fontSize: "13px" } }
              const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
              const obj = checkHeihlight(key, highlightCells.current);
              if (obj.status) {
                if (obj.colorCode === 1) return { backgroundColor: "cyan" };
                else return { backgroundColor: "#d5a7f2" };
              } else if (sessionStorage.getItem("Template_Type") === "vendor") {
                return { backgroundColor: "white" };
              } else return { backgroundColor: "#FFFFFF" };
            },
            // cellEditor: "datePicker",
            cellEditor: "Datepicker",
            editable: (params) => {
              if (params.node.data.row_id === "total") return false;
              return edt;
            },
            valueFormatter: dateValueFormatter,
            suppressKeyboardEvent: (params) => {
              if (!params.editing) {
                const isBackspaceKey = params.event.keyCode === 8;
                const isDeleteKey = params.event.keyCode === 46;
                if (isBackspaceKey || isDeleteKey) {
                  const colId = params.colDef.colId;
                  const rowNode = params.node;
                  rowNode.setDataValue(colId, "");
                  return true;
                }
              }
              return false;
            },
          };
        } else {
          if (fldNm.includes("Email Recipients")) {
            mpCol = {
              headerName: hdrNm,
              field: fldNm,
              colId: clId,
              width: wdth,
              hide: hd,
              editable: edt,
              cellStyle: (params) => {
                const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
                const obj = checkHeihlight(key, highlightCells.current);
                if (obj.status) {
                  if (obj.colorCode === 1) return { backgroundColor: "cyan" };
                  else if (obj.colorCode === 2) return { backgroundColor: "#d5a7f2" };
                  else return { backgroundColor: "white" };
                }
                return { backgroundColor: "white" };
              },
            };
          } else {
            mpCol = {
              headerName: hdrNm,
              field: fldNm,
              colId: clId,
              width: wdth,
              hide: hd,
              editable: (params) => {
                if (params.node.data.row_id === "total") return false;
                return edt;
              },
              cellStyle: (params) => {
                if (params.node.data.row_id === "total") return { backgroundColor: "#f0f0f0", fontWeight: "bold", fontSize: "13px" };
                const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
                const obj = checkHeihlight(key, highlightCells.current);
                if (obj.status === true) {
                  if (obj.colorCode === 1) return { backgroundColor: "cyan" };
                  else return { backgroundColor: "#d5a7f2" };
                } else return { backgroundColor: "white" };
              },
              valueParser: (params) => myValueParser(params, ct),
              valueFormatter: (params) => myValueFormatter(params, fldNm, ct),
            };
          }
        }
      }
    } else if (ct !== null && ct !== undefined && ct !== "" && ct === "date") {
      {
        mpCol = {
          headerName: hdrNm,
          field: fldNm,
          colId: clId,
          width: wdth,
          filter: "agSetColumnFilter",
          filterParams: dateFilterParams,
          cellEditor: "Datepicker",
          editable: (params) => isEditable(params, fldNm, edt),
          hide: hd,
          cellStyle: (params) => {
            if (sessionStorage.getItem("Template_Type") === "admin" && editableCriteriaColumns.includes(fldNm)) {
              const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
              const obj = checkHeihlight(key, highlightCells.current);
              if (obj.status === true) {
                if (obj.colorCode === 1) return { backgroundColor: "cyan" };
                else return { backgroundColor: "#d5a7f2" };
              } else if (params.node.data.row_id === null || params.node.data.row_id === undefined || params.node.data.row_id === "") {
                return { backgroundColor: "white" };
              } else return { backgroundColor: "#f0f0f0" };
            } else {
              const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
              const obj = checkHeihlight(key, highlightCells.current);
              if (obj.status === true) {
                if (obj.colorCode === 1) return { backgroundColor: "cyan" };
                else return { backgroundColor: "#d5a7f2" };
              } else return { backgroundColor: "#f0f0f0" };
            }
          },
          valueFormatter: dateValueFormatter,
          suppressKeyboardEvent: (params) => {
            if (!params.editing && editableCriteriaColumns.includes(fldNm)) {
              const isBackspaceKey = params.event.keyCode === 8;
              const isDeleteKey = params.event.keyCode === 46;
              if (isBackspaceKey || isDeleteKey) {
                const colId = params.colDef.colId;
                const rowNode = params.node;
                rowNode.setDataValue(colId, "");
                return true;
              }
            }
            return false;
          },
        };
      }
    } else {
      mpCol = {
        headerName: hdrNm,
        field: fldNm,
        colId: clId,
        width: wdth,
        // editable: edt,
        editable: (params) => isEditable(params, fldNm, edt),
        hide: hd,
        cellStyle: (params) => {
          if (params.node.data.row_id === "total") { return { backgroundColor: "#f0f0f0", fontWeight: "bold", fontSize: "13px" } }
          if (sessionStorage.getItem("Template_Type") === "admin" && editableCriteriaColumns.includes(fldNm)) {
            const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
            const obj = checkHeihlight(key, highlightCells.current);
            if (obj.status === true) {
              if (obj.colorCode === 1) return { backgroundColor: "cyan" };
              else return { backgroundColor: "#d5a7f2" };
            } else if (params.node.data.row_id === null || params.node.data.row_id === undefined || params.node.data.row_id === "") {
              return { backgroundColor: "white" };
            } else return { backgroundColor: "#f0f0f0" };
          } else {
            const key = tblName + "|" + hdrNm + "|" + params.node.data.row_id;
            const obj = checkHeihlight(key, highlightCells.current);
            if (obj.status === true) {
              if (obj.colorCode === 1) return { backgroundColor: "cyan" };
              else return { backgroundColor: "#d5a7f2" };
            } else return { backgroundColor: "#f0f0f0" };
          }
        },
        valueParser: (params) => myValueParser(params, ct),
        valueFormatter: (params) => myValueFormatter(params, fldNm, ct),
      };
    }
    if (ct === "qty" || ct === "price" || ct === "percentage" || ct === "qty") {
      mpCol = {
        ...mpCol,
        filter: "agMultiColumnFilter",
        filterParams: {
          buttons: ["reset", "apply"],
          filters: [
            {
              filter: "agNumberColumnFilter",
            },
            {
              filter: "agSetColumnFilter",
              filterParams: {
                valueFormatter: (params) => {
                  const value = params.value;
                  if (ct === "price") {
                    if (value === null || value === undefined || value === "") return value;
                    else {
                      const formattedNumber = parseFloat(value).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                      return "$" + formattedNumber;
                    }
                  } else if (ct === "percentage") {
                    if (value === null || value === undefined || value === "") return value;
                    else {
                      if (typeof value !== "number" && value.includes(".")) {
                        if (value > 1) return parseFloat(value).toFixed(2) + "%";
                        else return parseFloat(value * 100).toFixed(2) + "%";
                      } else return parseFloat(value).toFixed(2) + "%";
                    }
                  } else return params.value;
                },
              },
            },
          ],
        },
      };
    } else if (ct === null || ct === "") {
      mpCol = {
        ...mpCol,
        filter: "agMultiColumnFilter",
        filterParams: {
          filters: [
            {
              filter: "agTextColumnFilter",
            },
            {
              filter: "agSetColumnFilter",
              // filterParams: {}
            },
          ],
        },
      };
    }
    mpCol = { ...mpCol, type: ct };
    if (mpCol.width === null || mpCol.width === undefined || mpCol.width === 0) {
      mpCol = { ...mpCol, unSortIcon: true, width: 125, resizable: true, wrapText: true };
    } else {
      mpCol = { ...mpCol, unSortIcon: true, resizable: true, wrapText: true };
    }
    ColsDef.push(mpCol);
  });
  return ColsDef;
}
const getUserTypes = async () => {
  const jsonReq = {
    "param_body": "",
    "param_function": "ca_get_user_type"
  };
  return fetch(restApiPath + 'callPGFunction', {
    method: 'POST',
    headers: {
      'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'is-excel-app-supported': "yes"
    },
    body: JSON.stringify(jsonReq),
  })
    .then(response => response.json())
    .then(result => {
      if (result.error === "invalid_token") {
        setSnackBarNotiMsg("Session Expired");
        setSnackBarNotiColor("warning");
        openSnackBarNoti();
        sessionStorage.clear()
        setTimeout(() => {
          nevigate("/login");
        }, 5000)
      } else {
        if (result.data) {
          const type = JSON.parse(result.data)
          console.log("type --> ", type)
          return type.filter((tt) => tt !== "");
        }
      }
    });
}
const getUserData = async (rowId, table_name) => {
  let current_user_type = sessionStorage.getItem("user_type")
  let userTypes = await getUserTypes()
  // console.log("userTypes : ", userTypes)
  const jsonReq = {
    "param_body": { "param_user_type": userTypes?.join(","), "requested_by": sessionStorage.getItem("email"), "row_id": rowId, "table_name": table_name },
    "param_function": "ca_get_all_users"
  };
  return fetch(restApiPath + 'callPGFunction', {
    method: 'POST',
    headers: {
      'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
      'Content-Type': 'application/json',
      'is-excel-app-supported': "yes"
    },
    body: JSON.stringify(jsonReq),
  })
    .then(response => response.json())
    .then(result => {
      if (result.error === "invalid_token") {
        setSnackBarNotiMsg("Session Expired");
        setSnackBarNotiColor("warning");
        openSnackBarNoti();
        sessionStorage.clear()
        setTimeout(() => {
          nevigate("/login");
        }, 5000)
      } else {
        if (result.data) {
          if (current_user_type !== 'vendor') {
            // console.log(JSON.parse(result.data))
            return JSON.parse(result.data);
          }
          else {
            const filteredUsers = JSON.parse(result.data).filter(user => user.user_type !== '')
            return filteredUsers
          }
        }
      }
      // dispatch({ type: "SET_PLEASE_WAIT", data: false });
    });
}
export const getExcelStyle = () => {
  return [
    {
      id: "percentage",
      numberFormat: {
        format: "0.00%",
      },
    },
    {
      id: "qty",
      numberFormat: {
        format: "#,##0",
      },
    },
    {
      id: "price",
      numberFormat: {
        format: '$#,##0.00',
      },
    },
    {
      id: "date",
      numberFormat: { format: 'dd-MM-yyyy' }
    },
    {
      id: "aboveheader a8991FF",
      interior: {
        color: "#a8991FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader F3F4FF',
      interior: {
        color: "#F3F4FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader E7E9FF',
      interior: {
        color: "#E7E9FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader DBDEFF',
      interior: {
        color: "#DBDEFF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader CFD3FF',
      interior: {
        color: "#CFD3FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader C4C8FF',
      interior: {
        color: "#C4C8FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader a90DBF4',
      interior: {
        color: "#a90DBF4",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader a9FBAFF',
      interior: {
        color: "#a9FBAFF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader a52B788',
      interior: {
        color: "#a52B788",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader a8991FF',
      interior: {
        color: "#a8991FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader FAE1DD',
      interior: {
        color: "#FAE1DD",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader FFB3C6',
      interior: {
        color: "#FFB3C6",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader FAF1F5',
      interior: {
        color: "#FAF1F5",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'aboveheader FFE5EC',
      interior: {
        color: "#FFE5EC",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader D0CECE',
      interior: {
        color: "#D0CECE",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader CDB4DB',
      interior: {
        color: "#CDB4DB",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader DAEEF3',
      interior: {
        color: "#DAEEF3",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader F7CAD0',
      interior: {
        color: "#F7CAD0",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E4DFEC',
      interior: {
        color: "#E4DFEC",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FDE9D9',
      interior: {
        color: "#FDE9D9",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E0FFFF',
      interior: {
        color: "#E0FFFF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FFFFFF',
      interior: {
        color: "#FFFFFF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader F5F2FF',
      interior: {
        color: "#F5F2FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader CDC1FF',
      interior: {
        color: "#CDC1FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FFC2D1',
      interior: {
        color: "#FFC2D1",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader D2F0FA',
      interior: {
        color: "#D2F0FA",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader B1E5F7',
      interior: {
        color: "#B1E5F7",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E7F6ED',
      interior: {
        color: "#E7F6ED",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E1F4E8',
      interior: {
        color: "#E1F4E8",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader BAEEFF',
      interior: {
        color: "#BAEEFF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader B5E4FF',
      interior: {
        color: "#B5E4FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader AFD9FF',
      interior: {
        color: "#AFD9FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader AACFFF',
      interior: {
        color: "#AACFFF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader F8CBAD',
      interior: {
        color: "#F8CBAD",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FFD966',
      interior: {
        color: "#FFD966",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader BDD7EE',
      interior: {
        color: "#BDD7EE",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader F8CBAD',
      interior: {
        color: "#F8CBAD",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader C9C9C9',
      interior: {
        color: "#C9C9C9",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader B8ADE5',
      interior: {
        color: "#B8ADE5",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FFF6ED',
      interior: {
        color: "#FFF6ED",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FFEEDB',
      interior: {
        color: "#FFEEDB",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader ECF8F1',
      interior: {
        color: "#ECF8F1",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader C5E9D2',
      interior: {
        color: "#C5E9D2",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E2EFDA',
      interior: {
        color: "#E2EFDA",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader DDEBF7',
      interior: {
        color: "#DDEBF7",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader FFF2CC',
      interior: {
        color: "#FFF2CC",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader A4C5FF',
      interior: {
        color: "#A4C5FF",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E6BEDC',
      interior: {
        color: "#E6BEDC",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader E5D9F2',
      interior: {
        color: "#E5D9F2",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader C5D9F1',
      interior: {
        color: "#C5D9F1",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader F2DCDB',
      interior: {
        color: "#F2DCDB",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader EBF1DE',
      interior: {
        color: "#EBF1DE",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    }, {
      id: 'aboveheader DDD9C4',
      interior: {
        color: "#DDD9C4",
        pattern: "Solid",
      },
      font: {
        bold: true,
      },
    },
  ];
}
export const freezePane = (params, ColumnDef, gridColumnApi, pinnedDirection) => {
  // const ColumnDef = getColumnDefByTblName(tbl_name);
  const colId = params.column.colId;
  const colIndex = ColumnDef.findIndex((element) => element.colId === colId)
  let leftColumnsAraay = [];

  if (pinnedDirection === "left")
    leftColumnsAraay = ColumnDef.filter((el, index) => index < colIndex && !el.hide);
  else
    leftColumnsAraay = ColumnDef.filter((el, index) => index > colIndex && !el.hide);

  const colState = []
  leftColumnsAraay.map((col) => {
    colState.push({ colId: col.colId, pinned: pinnedDirection })
  })
  gridColumnApi.current.applyColumnState({
    state: colState,
    defaultState: { pinned: null },
  });
}
export const unFreezePane = (gridColumnApi) => {
  gridColumnApi.current.applyColumnState({ defaultState: { pinned: null } });
}
export {
  getCellTimeline,
  GetTableDataLocale,
  GetEvolutionStatisticsData,
  formatDateToString,
  GetDbTableNameBasedOnSheetName,
  GetTableColumnsDefinition,
  getTableAsRequired,
  formatDateToFullString,
  getKpiMetricsData,
  GetRowFilterBasedOnTableName,
  GetColsDataSource,
  GetLatestTxData,
  formatDateToMmDdYyyyHhMmSsAmPm,
  getCurrentDateOnlyAsYyyyMmDd,
  getAllActiveUserData,
  getPivotConfig,
  getUserData,
  isValueBlank
};
// Function to find differences between two objects
const findDifferences = (obj1, obj2) => {
  const differences = [];
  _.forOwn(obj1, (value, key) => {
    try {
      if (!_.isEqual(value, obj2[key])) {
        differences.push(key);
      }
    } catch (e) { console.log(e) }
  });
  return differences;
};
export const getColumnDropDownValue = (column_data_source) => {
  if (!column_data_source) return [];
  let dropdownValues = [];
  if (column_data_source.includes(">>")) {
    const dataSource = column_data_source.split(">>");
    const refrenceData = getStateDataByTblName(dataSource[0]);
    const refColumn = dataSource[1];
    // dropdownValues = _.compact(_.map(refrenceData, refColumn));
    const refCol = item => item[refColumn];
    const sortedData = refrenceData.sort((a, b) => a.row_id - b.row_id);
    dropdownValues = sortedData.map(item => refCol(item)).filter(value => value);
  } else dropdownValues = column_data_source.split("|");
  dropdownValues = [...new Set(dropdownValues)];
  return dropdownValues;
}
const isValueBlank = (value) => {
  return _.isNil(value) || value === '';
};
export function executeDataVerification(event, reqTbl, highlightCells, gridApi, from = "") {
  //get column source of given table
  const columnDefination = getColumnDefByTblName(reqTbl);
  const tmpValidation = _.map(columnDefination, ({ field, validation, editable, column_data_source, is_formula_col, insert_editable }) => ({ field, validation, editable, column_data_source, is_formula_col, insert_editable }));

  const validations = _.filter(tmpValidation, obj => {
    return obj.editable === true || obj.is_formula_col === true || obj.insert_editable === true;
  });

  if (validations.length === 0) return ({ "status": true, "msg": "" });

  const highlightArr = highlightCells;
  let validationStatus = true;
  let message = "";
  let key = "";

  if (event) {
    const { node } = event;
    const column = event.colDef.field;
    const data = node.data[column];
    key = reqTbl + "|" + column + "|" + node.data.row_id;

    const validationRules = _.filter(validations, obj => {
      return obj.field === column
    });
    if (validationRules.length === 0) return ({ "status": true, "msg": "" });

    const validationRule = validationRules[0];

    const checkValidation = validationRule.validation ? validationRule.validation.split("|") : [];
    if (checkValidation.length > 0) {
      checkValidation.some((validate) => {
        if (reqTbl !== "xl_faherty_merch_assortment_sandbox" && validate.toUpperCase() === "MANDATORY") {
          if (!data || data === "") {
            validationStatus = false;
            message = "Please enter data at highlighted cells. Mandatory columns should not be blank.";
            return true;
          }
          return false;
        } else if (data && data !== "") {
          switch (validate.toUpperCase()) {
            case "DROP_DOWN": {
              const dropdownValues = getColumnDropDownValue(validationRule.column_data_source);

              if (!dropdownValues.includes(data)) {
                validationStatus = false;
                message = "Please select value from drop-down list at highlighted cells.";
                return true;
              }
              return false;
            }
            case "INTEGER": {
              const isInteger = /^-?\d+$/.test(data);
              if (!isInteger) {
                validationStatus = false;
                message = "Please enter integer in this column.";
                return true;
              }
              return false;
            }
            case "NUMERIC": {
              const isNumeric = /^-?\d*\.?\d+$/.test(data);
              if (!isNumeric) {
                validationStatus = false;
                message = "Please enter numeric value in this column.";
                return true;
              }
              return false;
            }
            case "DATE": {
              // const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(\d{2})$/;
              const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(\d{4})$/;

              const isDate = dateRegex.test(data);
              if (!isDate) {
                validationStatus = false;
                message = "Please enter proper date value in this column.";
                return true;
              }
              return false;
            }
            case "EMAIL_ADDRESS": {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              const isEmail = emailRegex.test(data);
              if (!isEmail) {
                validationStatus = false;
                message = "Please enter valid email.";
                return true;
              }
              return false;
            }
            case "NON_KEY_BOARD_CHARACTERS": {
              const nonKeyboardRegex = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZa-z 0123456789 \.,\?'""!@#\$%\^&\\n\t\r\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]/;
              const isNonKeyBoard = nonKeyboardRegex.test(data);
              if (!isNonKeyBoard) {
                validationStatus = false;
                message = "Please enter proper data in this column.";
                return true;
              }
              return false;
            }
            case "ALPHA_NUMERIC": {
              const alphanumericRegex = /^[a-zA-Z0-9]+$/;
              const isAlphaNumeric = alphanumericRegex.test(data);
              if (!isAlphaNumeric) {
                validationStatus = false;
                message = "Space and Special Characters not allowed.";
                return true;
              }
              return false;
            }
            case "STRING": {
              const stringRegex = /^[a-zA-Z ]+$/;
              const isString = stringRegex.test(data);
              if (!isString) {
                validationStatus = false;
                message = "Space and Special Characters not allowed.";
                return true;
              }
              return false;
            }
            case "DATA_CRITERIA": {
              const asRequiredDownloadQuery = sessionStorage.getItem("asRequiredDownloadQuery")
              if (asRequiredDownloadQuery && asRequiredDownloadQuery !== "" && asRequiredDownloadQuery.includes(column)) {
                if (!asRequiredDownloadQuery.includes(data)) {
                  validationStatus = false;
                  message = "Please enter the data as per your downloaded criteria at highlighted cells.";
                  return true;
                }
              }
              return false;
            }
            default: {
              validationStatus = true;
              message = "";
              return false;
            }
          }
        }
      })
    } else if (data && data !== "") {
      //if no validation define then check for non key board chacter
      const nonKeyboardRegex = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZa-z 0123456789 \.,\?'""!@#\$%\^&\\n\t\r\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]/;
      const isNonKeyBoard = nonKeyboardRegex.test(data);
      if (!isNonKeyBoard) {
        validationStatus = false;
        message = "Please enter proper data in this column.";
      }
    }
    if (!validationStatus) highlightArr.push({ [key]: 2 });
  } else {
    console.log("inside check")
    let gridData = [];
    if (reqTbl === "xl_faherty_merch_assortment_sandbox" && from === "merch_assort") {
      const stateData = getStateDataByTblName(reqTbl);
      gridData = [...stateData];
    } else {
      gridData = [];
      gridApi.forEachNode((node) => { gridData.push(node.data) });
    }
    const testData = _.sortBy(gridData, [function (o) { return o.row_id; }]);

    const tmpSessionData = getSessionDataByTblName(reqTbl);
    if (!tmpSessionData) return ({ "status": false, "msg": "No Session data found to compare." })
    const sessionData = _.sortBy(tmpSessionData, [function (o) { return o.row_id; }]);

    //compare session data with grid data
    // const updatedData = _.differenceWith(testData, sessionData, _.isEqual);

    // Find differences between objects in the arrays
    const differences = _.flatMap(sessionData, (obj1, index) => {
      const obj2 = testData[index];
      return findDifferences(obj1, obj2);
    });
    const isDeletePerformed = sessionData.length > testData.length;
    const updatedColumns = [...new Set(differences)];
    const BreakException = {}
    try {
      if (isDeletePerformed) return ({ "status": validationStatus, "msg": message, "highlightCells": highlightArr });
      updatedColumns.map((column) => {
        const validationRules = _.filter(validations, obj => {
          return obj.field === column || obj.validation.includes("MANDATORY")
        });
        if (validationRules.length === 0) return ({ "status": true, "msg": "no validation" });

        const validationRule = validationRules[0];
        if (validationRule.validation && validationRule.validation.includes("CONTIGUOUS_CELLS_VALUE")) {

          const colValues = _.map(gridData, column);
          const rowIds = _.map(gridData, "row_id");
          const lastIndex = _.findLastIndex(colValues, function (chr) {
            return chr && chr !== "";
          });

          const colValuesExBlanks = colValues.slice(0, lastIndex);
          const checkBlanks = colValuesExBlanks.filter((dt) => dt === "" || !dt)

          if (checkBlanks.length > 0) {
            validationStatus = false;
            message = "Put value continuously in a column, Do not keep blank cell in-between cells.";
            colValuesExBlanks.map((dt, index) => {
              if (dt === "" || !dt) {
                key = reqTbl + "|" + column + "|" + rowIds[index];
                highlightArr.push({ [key]: 2 });
              }
              return dt;
            })
            throw BreakException;
          }
        }
        if (validationStatus && validationRule.validation && validationRule.validation.includes("DUP")) {
          const duplicateVal = [];
          validations.map((val) => {
            if (val && val.validation) {
              const arr = val.validation.split("|");
              const dupArr = _.filter(arr, (val) => val.includes("DUP"));
              dupArr.map((dt) => duplicateVal.push({ column: val.field, validation: dt }))
            }
          })
          const groupVal = _.groupBy(duplicateVal, "validation");

          Object.entries(groupVal).map(([key, value]) => {
            const columns = _.map(value, "column");
            // Function to generate a unique key for each object based on specified keys
            const generateKey = obj => columns.map(key => obj[key]).join('|');
            // Group objects by the generated key
            const grouped = _.groupBy(gridData, generateKey);
            // Filter out groups that have more than one item (i.e., duplicates)
            const duplicates = _.filter(grouped, group => group.length > 1);

            if (duplicates.length > 0) {
              validationStatus = false;
              message = "Duplicate value found. Please correct it.";
              duplicates[0].map((dp) => {
                Object.entries(dp).map(([key, value]) => {
                  const key1 = reqTbl + "|" + key + "|" + dp.row_id;
                  highlightArr.push({ [key1]: 2 });

                })
              })
              throw BreakException;
            }
          })
        }
        if (validationStatus && validationRule.validation && validationRule.validation.includes("MANDATORY")) {
          const mandatory = []
          validations.map((val) => {
            if (val && val.validation) {
              const arr = val.validation.split("|");
              const dupArr = _.filter(arr, (val) => val.includes("MANDATORY"));
              dupArr.map((dt) => mandatory.push({ column: val.field, validation: dt }))
            }
          })
          // console.log("mandatory", mandatory)
          let filterValues = [];
          if (reqTbl === "xl_faherty_merch_assortment_sandbox") {
            // const stateData = getStateDataByTblName(reqTbl);
            filterValues = gridData.filter((dt) => dt["Upload To Linelist Check"] === 'Yes');
          } else filterValues = gridData;
          
        
          const columnsForMandatory = _.filter(validations, obj => {
            return obj.validation.includes("MANDATORY")
          });

          //add column in mandatory list if validation present 
          const checkForColumn =  _.find(validations, obj => {
            return obj.field === column && obj.validation && obj.validation.includes("MANDATORY")
          });
          if(checkForColumn) columnsForMandatory.push(checkForColumn);

          // console.log("columnsForMandatory", columnsForMandatory)
          columnsForMandatory.map((dt) => {
            const col = dt.field;
            const colValues = _.map(filterValues, col);
            const rowIds = _.map(filterValues, "row_id");
            const blankIndices = colValues
              .map((value, index) => (isValueBlank(value) ? index : null))
              .filter(index => index !== null);


            if (blankIndices && blankIndices.length > 0) {
              validationStatus = false;
              message = "Please enter data at highlighted cells. Mandatory columns should not be blank.";

              blankIndices.map((index) => {
                key = reqTbl + "|" + col + "|" + rowIds[index];
                highlightArr.push({ [key]: 2 });
              })
            }
          })

        }
      })
    } catch (error) {
      if (error !== BreakException) {
        validationStatus = false;
        message = error.message;
      }
    }
    if (updatedColumns.length > 0) {
      //check for blank row validation
      // Function to check if an object is blank
      const isBlankRow = obj => {
        // Exclude 'row_id' property and check if all other values are null, undefined, or empty strings
        return _.every(_.omit(obj, 'row_id'), value => value === null || value === undefined || value === '');
      };

      // Find blank rows
      const newArray = _.map(gridData, obj => _.omit(obj, ["inserted_on", "modified_on", "user_inserted", "user_modified"]));

      const blankRows = _.filter(newArray, isBlankRow);
      // const blankRowNew = _.filter(newArray, isBlankRowNew);

      // console.log("blankRows", blankRows)

      if (blankRows && blankRows.length > 0) {
        validationStatus = false;
        message = "Entire row can not be blank. Please enter value in few cells."

        blankRows.map((row) => {
          Object.entries(row).map(([key, value]) => {
            const key1 = reqTbl + "|" + key + "|" + row.row_id;
            highlightArr.push({ [key1]: 2 });
          })
        })
      }

    }
  }
  return ({ "status": validationStatus, "msg": message, "highlightCells": highlightArr });
}
async function getPivotConfig() {
  // console.log("In XL_Utils -> getPivotConfig")
  let headersVal = {
    'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  let bodyVal = {
    "userInserted": sessionStorage.getItem("email"),
    "permissionGroup": (sessionStorage.getItem("user_type") === "vendor" ? "vendor" : "faherty"),
    "publishedWithUserName": sessionStorage.getItem("email")
  }
  const res = await fetch(restApiPath + 'getPivotTable', {
    method: 'POST',
    headers: headersVal,
    body: JSON.stringify(bodyVal)
  })
  const json = await res.json();
  // console.log("json")
  // console.log(json)
  if (json.error === "invalid_token") {
    return []
  } else if (json.response === true && json.message === "SUCCESS") {
    let dt = json.data
    if (dt === null || dt === undefined || dt.length === 0) {
      return []
    } else {
      return dt
    }
  } else {
    return []
  }
}
export const SummaryReportColDef = [
  {
    "headerName": "Row Labels",
    "field": "Row Labels",
    "colId": "Row Labels",
    "width": 150,
    "resizable": true,
  },
  {
    "headerName": "Count of Style",
    "field": "Count of Style",
    "colId": "Count of Style",
    "width": 90,
    "resizable": true,
    valueFormatter: (params) => params.value.toLocaleString('en-US')
  },
  {
    "headerName": "Retail Margin (Actual)",
    "field": "Retail Margin (Actual)",
    "colId": "Retail Margin (Actual)",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Retail Margin (Actual)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Wholesale Margin (Actual)",
    "field": "Wholesale Margin (Actual)",
    "colId": "Wholesale Margin (Actual)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Wholesale Margin (Actual)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Blended Brand Margin (Actual)",
    "field": "Blended Brand Margin (Actual)",
    "colId": "Blended Brand Margin (Actual)",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Blended Brand Margin (Actual)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Retail Gross Margin (Actual)",
    "field": "Retail Gross Margin (Actual)",
    "colId": "Retail Gross Margin (Actual)",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Retail Gross Margin (Actual)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Wholesale Gross Margin (Actual)",
    "field": "Wholesale Gross Margin (Actual)",
    "colId": "Wholesale Gross Margin (Actual)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Wholesale Gross Margin (Actual)", "percentage"),
    "resizable": true,
  },
  {
    "headerName": "Blended Brand Gross Margin (Actual)",
    "field": "Blended Brand Gross Margin (Actual)",
    "colId": "Blended Brand Gross Margin (Actual)",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Blended Brand Gross Margin (Actual)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Retail Margin (Scenario)",
    "field": "Retail Margin (Scenario)",
    "colId": "Retail Margin (Scenario)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Retail Margin (Scenario)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Wholesale Margin (Scenario)",
    "field": "Wholesale Margin (Scenario)",
    "colId": "Wholesale Margin (Scenario)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Wholesale Margin (Scenario)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Blended Brand Margin (Scenario)",
    "field": "Blended Brand Margin (Scenario)",
    "colId": "Blended Brand Margin (Scenario)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Blended Brand Margin (Scenario)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Retail Gross Margin (Scenario)",
    "field": "Retail Gross Margin (Scenario)",
    "colId": "Retail Gross Margin (Scenario)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Retail Gross Margin (Scenario)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Wholesale Gross Margin (Scenario)",
    "field": "Wholesale Gross Margin (Scenario)",
    "colId": "Wholesale Gross Margin (Scenario)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Wholesale Gross Margin (Scenario)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Blended Brand Gross Margin (Scenario)",
    "field": "Blended Brand Gross Margin (Scenario)",
    "colId": "Blended Brand Gross Margin (Scenario)",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Blended Brand Gross Margin (Scenario)", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Retail Margin Variance",
    "field": "Retail Margin Variance",
    "colId": "Retail Margin Variance",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Retail Margin Variance", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Wholesale Margin Variance",
    "field": "Wholesale Margin Variance",
    "colId": "Wholesale Margin Variance",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Wholesale Margin Variance", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Blended Brand Margin Variance",
    "field": "Blended Brand Margin Variance",
    "colId": "Blended Brand Margin Variance",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Blended Brand Margin Variance", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Retail Gross Margin Variance",
    "field": "Retail Gross Margin Variance",
    "colId": "Retail Gross Margin Variance",
    "width": 100,
    // valueFormatter: (params) => myValueFormatter(params, "Retail Gross Margin Variance", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Wholesale Gross Margin Variance",
    "field": "Wholesale Gross Margin Variance",
    "colId": "Wholesale Gross Margin Variance",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Wholesale Gross Margin Variance", "percentage"),
    "resizable": true
  },
  {
    "headerName": "Blended Brand Gross Margin Variance",
    "field": "Blended Brand Gross Margin Variance",
    "colId": "Blended Brand Gross Margin Variance",
    "width": 110,
    // valueFormatter: (params) => myValueFormatter(params, "Blended Brand Gross Margin Variance", "percentage"),
    "resizable": true
  },
  {
    "headerName": "DTC Units Total",
    "field": "DTC Units Total",
    "colId": "DTC Units Total",
    "width": 80,
    "resizable": true,
    valueFormatter: (params) => params.value.toLocaleString('en-US')
  },
  {
    "headerName": "Wholesale Units Total",
    "field": "Wholesale Units Total",
    "colId": "Wholesale Units Total",
    "width": 110,
    "resizable": true,
    valueFormatter: (params) => params.value.toLocaleString('en-US')
  },
  {
    "headerName": "Brand Units Total",
    "field": "Brand Units Total",
    "colId": "Brand Units Total",
    "width": 80,
    "resizable": true,
    valueFormatter: (params) => params.value.toLocaleString('en-US')
  },
  {
    "headerName": "Total DTC SLS $",
    "field": "Total DTC SLS $",
    "colId": "Total DTC SLS $",
    "width": 140,
    "resizable": true,
    valueFormatter: (params) => ("$" + params.value.toLocaleString('en-US'))
  },
  {
    "headerName": "Total WHSL SLS $",
    "field": "Total WHSL SLS $",
    "colId": "Total WHSL SLS $",
    "width": 150,
    "resizable": true,
    valueFormatter: (params) => ("$" + params.value.toLocaleString('en-US'))
  },
  {
    "headerName": "Total Brand SLS $",
    "field": "Total Brand SLS $",
    "colId": "Total Brand SLS $",
    "width": 150,
    "resizable": true,
    valueFormatter: (params) => ("$" + params.value.toLocaleString('en-US'))
  }
]
const convertIntoPercentage = (vl) => {
  // console.log("convertIntoPercentage -> typeof(vl) -> " + typeof(vl))
  // console.log("convertIntoPercentage -> vl -> " + vl)
  if (vl === null || vl === undefined || vl === "") {
    // return vl
    return "0.00%"
  } else {
    if (vl.toString().includes(".")) {
      if (vl > 1) {
        return parseFloat(vl).toFixed(2) + "%"
      } else {
        return parseFloat(vl * 100).toFixed(2) + "%"
      }
    } else {
      return parseFloat(vl).toFixed(2) + "%"
    }
  }
}
export const prepareSummaryReportData = (tmpLlData, filterMp) => {
  // console.log("prepareSummaryReportData -> S -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
  // console.log("tmpLlData")
  // console.log(tmpLlData)
  // console.log("filterMp")
  // console.log(filterMp)

  if (sessionStorage.getItem("user_type") === "vendor" || sessionStorage.getItem("user_type") === "merch reference" || sessionStorage.getItem("user_type") === "read-only") {
    console.log("prepareSummaryReportData -> E-0 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
    return (new Array())
  }

  if (tmpLlData === null || tmpLlData === undefined || tmpLlData.length === 0) {
    console.log("prepareSummaryReportData -> E-1 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
    return (new Array())
  }

  if (tmpLlData !== null && tmpLlData !== undefined && tmpLlData.length > 0) {
    console.log("prepareSummaryReportData -> tmpLlData.length -> " + tmpLlData.length)
  }

  const summaryReportSrcDataCols = ["row_id", "Year", "Season Code", "Department", "Category", "Factory", "Shipping Terms (FOB/DDP/LDP)", "Style", "MSRP", "WHLS", "LDP", "DTC Units Total", "Wholesale Units Total", "Brand Units Total", "Scenario LDP", "Total DTC SLS $", "Total WHSL SLS $", "Total Brand SLS $", "DTC Units Total", "DTC Chase Units", "Wholesale Units Total", "Wholesale Chase Units"]
  const summaryReportNumericCols = ["MSRP", "WHLS", "LDP", "DTC Units Total", "Wholesale Units Total", "Brand Units Total", "Scenario LDP", "Total DTC SLS $", "Total WHSL SLS $", "Total Brand SLS $", "DTC Units Total", "DTC Chase Units", "Wholesale Units Total", "Wholesale Chase Units"]

  let flCatLst = filterMp.get("Category")
  // console.log("flCatLst")
  // console.log(flCatLst)
  let catVal = new Array()
  if (flCatLst === null || flCatLst === undefined || flCatLst.length === 0) {
    // catVal = [...new Set(tmpLlData.map(({ Category }) => Category))]
    catVal = [...new Set(tmpLlData.map(({ Category }) => (Category === null || Category === undefined || Category.toString().trim() === "" ? "" : Category)))]
  } else {
    catVal = flCatLst
  }
  catVal.sort()
  // console.log("catVal")
  // console.log(catVal)

  if (catVal === null || catVal === undefined || catVal.length === 0) {
    console.log("prepareSummaryReportData -> E0 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
    return (new Array())
  }

  let flSeasonCodeLst = filterMp.get("Season Code")
  if (flSeasonCodeLst === null || flSeasonCodeLst === undefined || flSeasonCodeLst.length === 0) {
    // flSeasonCodeLst = [...new Set(tmpLlData.map(({ "Season Code": Season_Code }) => Season_Code))]
    flSeasonCodeLst = [...new Set(tmpLlData.map(({ "Season Code": Season_Code }) => (Season_Code === null || Season_Code === undefined || Season_Code.toString().trim() === "" ? "" : Season_Code)))]
  }
  // console.log("flSeasonCodeLst")
  // console.log(flSeasonCodeLst)

  let flDepartmentLst = filterMp.get("Department")
  if (flDepartmentLst === null || flDepartmentLst === undefined || flDepartmentLst.length === 0) {
    // flDepartmentLst = [...new Set(tmpLlData.map(({ Department }) => Department))]
    flDepartmentLst = [...new Set(tmpLlData.map(({ Department }) => (Department === null || Department === undefined || Department.toString().trim() === "" ? "" : Department)))]
  }
  // console.log("flDepartmentLst")
  // console.log(flDepartmentLst)

  let flFactoryLst = filterMp.get("Factory")
  if (flFactoryLst === null || flFactoryLst === undefined || flFactoryLst.length === 0) {
    // flFactoryLst = [...new Set(tmpLlData.map(({ Factory }) => Factory))]
    flFactoryLst = [...new Set(tmpLlData.map(({ Factory }) => (Factory === null || Factory === undefined || Factory.toString().trim() === "" ? "" : Factory)))]
  }
  // console.log("flFactoryLst")
  // console.log(flFactoryLst)

  let flShippingTermsFobDdpLdpLst = filterMp.get("Shipping Terms (FOB/DDP/LDP)")
  if (flShippingTermsFobDdpLdpLst === null || flShippingTermsFobDdpLdpLst === undefined || flShippingTermsFobDdpLdpLst.length === 0) {
    // flShippingTermsFobDdpLdpLst = [...new Set(tmpLlData.map(({ "Shipping Terms (FOB/DDP/LDP)": Shipping_Terms_FOB_DDP_LDP }) => Shipping_Terms_FOB_DDP_LDP))]
    flShippingTermsFobDdpLdpLst = [...new Set(tmpLlData.map(({ "Shipping Terms (FOB/DDP/LDP)": Shipping_Terms_FOB_DDP_LDP }) => (Shipping_Terms_FOB_DDP_LDP === null || Shipping_Terms_FOB_DDP_LDP === undefined || Shipping_Terms_FOB_DDP_LDP.toString().trim() === "" ? "" : Shipping_Terms_FOB_DDP_LDP)))]
  }
  // console.log("flShippingTermsFobDdpLdpLst")
  // console.log(flShippingTermsFobDdpLdpLst)

  let srUniqueSeasonCode = new Array()
  let srUniqueDepartment = new Array()
  let srUniqueCategory = new Array()
  let srUniqueFactory = new Array()
  let srUniqueShippingTermsFobDdpLdp = new Array()

  let srData = new Array()
  let tLlCatVl = ""
  let tLlScVl = ""
  let tLlDepVl = ""
  let tLlFctVl = ""
  let tLlShTermsVl = ""
  for (let i = 0; i < tmpLlData.length; i++) {
    tLlScVl = tmpLlData[i]["Season Code"]
    tLlDepVl = tmpLlData[i]["Department"]
    tLlCatVl = tmpLlData[i]["Category"]
    tLlFctVl = tmpLlData[i]["Factory"]
    tLlShTermsVl = tmpLlData[i]["Shipping Terms (FOB/DDP/LDP)"]

    tLlCatVl = (tLlCatVl === null || tLlCatVl === undefined || tLlCatVl.trim() === "") ? "" : tLlCatVl
    tLlScVl = (tLlScVl === null || tLlScVl === undefined || tLlScVl.trim() === "") ? "" : tLlScVl
    tLlDepVl = (tLlDepVl === null || tLlDepVl === undefined || tLlDepVl.trim() === "") ? "" : tLlDepVl
    tLlFctVl = (tLlFctVl === null || tLlFctVl === undefined || tLlFctVl.trim() === "") ? "" : tLlFctVl
    tLlShTermsVl = (tLlShTermsVl === null || tLlShTermsVl === undefined || tLlShTermsVl.trim() === "") ? "" : tLlShTermsVl

    for (let k = 0; k < catVal.length; k++) {
      if (tLlCatVl.toLowerCase() === catVal[k].toLowerCase()) {
        for (let m = 0; m < flSeasonCodeLst.length; m++) {
          if (tLlScVl.toLowerCase() === flSeasonCodeLst[m].toLowerCase()) {
            for (let n = 0; n < flDepartmentLst.length; n++) {
              if (tLlDepVl.toLowerCase() === flDepartmentLst[n].toLowerCase()) {
                for (let p = 0; p < flFactoryLst.length; p++) {
                  if (tLlFctVl.toLowerCase() === flFactoryLst[p].toLowerCase()) {
                    for (let t = 0; t < flShippingTermsFobDdpLdpLst.length; t++) {
                      if (tLlShTermsVl.toLowerCase() === flShippingTermsFobDdpLdpLst[t].toLowerCase()) {
                        let dt = new Object()
                        for (let j = 0; j < summaryReportSrcDataCols.length; j++) {
                          if (summaryReportNumericCols.includes(summaryReportSrcDataCols[j])) {
                            let ov = tmpLlData[i][summaryReportSrcDataCols[j]]
                            // console.log("summaryReportSrcDataCols[j] -> " + summaryReportSrcDataCols[j])
                            // console.log("ov -> " + ov)
                            if (ov === null || ov === undefined || ov.toString().trim() === "") {
                              dt[summaryReportSrcDataCols[j]] = 0
                            } else {
                              dt[summaryReportSrcDataCols[j]] = parseFloat(ov)
                            }
                          } else {
                            dt[summaryReportSrcDataCols[j]] = tmpLlData[i][summaryReportSrcDataCols[j]]
                          }
                        }
                        srData.push(dt)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    if (srUniqueDepartment.includes(tLlDepVl) === false) {
      srUniqueDepartment.push(tLlDepVl)
    }
    if (srUniqueSeasonCode.includes(tLlScVl) === false) {
      srUniqueSeasonCode.push(tLlScVl)
    }
    if (srUniqueCategory.includes(tLlCatVl) === false) {
      srUniqueCategory.push(tLlCatVl)
    }
    if (srUniqueFactory.includes(tLlFctVl) === false) {
      srUniqueFactory.push(tLlFctVl)
    }
    if (srUniqueShippingTermsFobDdpLdp.includes(tLlShTermsVl) === false) {
      srUniqueShippingTermsFobDdpLdp.push(tLlShTermsVl)
    }

    tLlCatVl = null, tLlScVl = null, tLlDepVl = null, tLlFctVl = null, tLlShTermsVl = null
  }

  // console.log("srData")
  // console.log(srData)
  if (srData === null || srData === undefined || srData.length === 0) {
    console.log("prepareSummaryReportData -> E0.5 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
    return (new Array())
  }

  srUniqueDepartment.sort()
  // console.log("srUniqueDepartment")
  // console.log(srUniqueDepartment)
  sessionStorage.setItem("srUniqueDepartment", JSON.stringify(srUniqueDepartment))
  srUniqueSeasonCode.sort()
  // console.log("srUniqueSeasonCode")
  // console.log(srUniqueSeasonCode)
  sessionStorage.setItem("srUniqueSeasonCode", JSON.stringify(srUniqueSeasonCode))
  srUniqueCategory.sort()
  // console.log("srUniqueCategory")
  // console.log(srUniqueCategory)
  sessionStorage.setItem("srUniqueCategory", JSON.stringify(srUniqueCategory))
  srUniqueFactory.sort()
  // console.log("srUniqueFactory")
  // console.log(srUniqueFactory)
  sessionStorage.setItem("srUniqueFactory", JSON.stringify(srUniqueFactory))
  srUniqueShippingTermsFobDdpLdp.sort()
  // console.log("srUniqueShippingTermsFobDdpLdp")
  // console.log(srUniqueShippingTermsFobDdpLdp)
  sessionStorage.setItem("srUniqueShippingTermsFobDdpLdp", JSON.stringify(srUniqueShippingTermsFobDdpLdp))

  let SummaryReportAggData = new Array()
  let gtCountofStyle = 0
  let gtRetailMarginActual = 0.0, gtWholesaleMarginActual = 0.0, gtBlendedBrandMarginActual = 0.0, gtRetailGrossMarginActual = 0.0, gtWholesaleGrossMarginActual = 0.0, gtBlendedBrandGrossMarginActual = 0.0
  let gtRetailMarginScenario = 0.0, gtWholesaleMarginScenario = 0.0, gtBlendedBrandMarginScenario = 0.0, gtRetailGrossMarginScenario = 0.0, gtWholesaleGrossMarginScenario = 0.0, gtBlendedBrandGrossMarginScenario = 0.0
  let gtRetailMarginVariance = 0.0, gtWholesaleMarginVariance = 0.0, gtBlendedBrandMarginVariance = 0.0, gtRetailGrossMarginVariance = 0.0, gtWholesaleGrossMarginVariance = 0.0, gtBlendedBrandGrossMarginVariance = 0.0
  let gtMsrpSum = 0.0, gtWhlsSum = 0.0, gtLdpSum = 0.0, gtScenarioLdp = 0.0
  let gtDtcUnitsTotalSum = 0, gtWholesaleUnitsTotal = 0, gtBrandUnitsTotal = 0
  let gtTotalDtcSlsSum = 0.0, gtTotalWhslSlsSum = 0.0, gtTotalBrandSlsSum = 0.0
  let gtDtcChaseUnitsSum = 0.0

  for (let i = 0; i < catVal.length; i++) {
    let styleCnt = xlEqualToThenReturnColValsCount(srData, "Category", catVal[i], "Style")
    // console.log(catVal[i] + " styleCnt -> " + styleCnt)
    let msrpSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "MSRP")
    // console.log(catVal[i] + " msrpSum -> " + msrpSum)
    let ldpSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "LDP")
    // console.log(catVal[i] + " ldpSum -> " + ldpSum)
    let retailMarginActual = ((parseFloat(msrpSum) - parseFloat(ldpSum)) / parseFloat(msrpSum))
    // console.log(catVal[i] + " retailMarginActual -> " + retailMarginActual)
    let whlsSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "WHLS")
    // console.log(catVal[i] + " whlsSum -> " + whlsSum)
    let wholesaleMarginActual = ((parseFloat(whlsSum) - parseFloat(ldpSum)) / parseFloat(whlsSum))
    // console.log(catVal[i] + " wholesaleMarginActual -> " + wholesaleMarginActual)
    let blendedBrandMarginActual = ((retailMarginActual + wholesaleMarginActual) / 2)
    // console.log(catVal[i] + " blendedBrandMarginActual -> " + blendedBrandMarginActual)
    let dtcUnitsTotalSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "DTC Units Total")
    // console.log(catVal[i] + " dtcUnitsTotalSum -> " + dtcUnitsTotalSum)
    let dtcChaseUnitsSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "DTC Chase Units")
    // console.log(catVal[i] + " dtcChaseUnitsSum -> " + dtcChaseUnitsSum)
    let dtcUnitsTotalPlusDtcChaseUnits = (parseInt(dtcUnitsTotalSum) + parseInt(dtcChaseUnitsSum))
    // console.log(catVal[i] + " dtcUnitsTotalPlusDtcChaseUnits -> " + dtcUnitsTotalPlusDtcChaseUnits)
    let totalMsrpDollar = (parseFloat(msrpSum) * dtcUnitsTotalPlusDtcChaseUnits)
    // console.log(catVal[i] + " totalMsrpDollar -> " + totalMsrpDollar)
    let totalDtcLdp = (dtcUnitsTotalPlusDtcChaseUnits * parseFloat(ldpSum))
    // console.log(catVal[i] + " totalDtcLdp -> " + totalDtcLdp)
    // let retailGrossMarginActual = (parseFloat(msrpSum)-parseFloat(ldpSum))/(parseFloat(msrpSum)*parseInt(dtcUnitsTotalSum))
    let retailGrossMarginActual = ((totalMsrpDollar - parseFloat(totalDtcLdp)) / totalMsrpDollar)
    // console.log(catVal[i] + " retailGrossMarginActual -> " + retailGrossMarginActual)
    let scenarioLdpSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Scenario LDP")
    // console.log(catVal[i] + " scenarioLdpSum -> " + scenarioLdpSum)
    let retailMarginScenario = ((parseFloat(msrpSum) - parseFloat(scenarioLdpSum)) / parseFloat(msrpSum))
    // console.log(catVal[i] + " retailMarginScenario -> " + retailMarginScenario)
    let wholesaleMarginScenario = ((parseFloat(whlsSum) - parseFloat(ldpSum)) / parseFloat(whlsSum))
    // console.log(catVal[i] + " wholesaleMarginScenario -> " + wholesaleMarginScenario)
    let blendedBrandMarginScenario = ((retailMarginScenario + wholesaleMarginScenario) / 2)
    // console.log(catVal[i] + " blendedBrandMarginScenario -> " + blendedBrandMarginScenario)
    let retailGrossMarginScenario = (parseFloat(msrpSum) - parseFloat(scenarioLdpSum)) / (parseFloat(msrpSum) * parseInt(dtcUnitsTotalSum))
    // console.log(catVal[i] + " retailGrossMarginScenario -> " + retailGrossMarginScenario)
    let wholesaleUnitsTotal = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Wholesale Units Total")
    // console.log(catVal[i] + " wholesaleUnitsTotal -> " + wholesaleUnitsTotal)
    let wholesaleChaseUnits = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Wholesale Chase Units")
    // console.log(catVal[i] + " wholesaleChaseUnits -> " + wholesaleChaseUnits)
    // let wholesaleUnitsTotalPlusWholesaleChaseUnits = (parseInt(wholesaleUnitsTotal)+parseInt(wholesaleChaseUnits))
    let wholesaleUnitsTotalPlusWholesaleChaseUnits = ((dtcUnitsTotalPlusDtcChaseUnits / 2) * 1.5)
    // console.log(catVal[i] + " wholesaleUnitsTotalPlusWholesaleChaseUnits -> " + wholesaleUnitsTotalPlusWholesaleChaseUnits)
    let totalWhslDollar = (parseFloat(whlsSum) * wholesaleUnitsTotalPlusWholesaleChaseUnits)
    // console.log(catVal[i] + " totalWhslDollar -> " + totalWhslDollar)
    let totalWhslLdp = (wholesaleUnitsTotalPlusWholesaleChaseUnits * parseFloat(ldpSum))
    // console.log(catVal[i] + " totalWhslLdp -> " + totalWhslLdp)
    // let wholesaleGrossMarginActual = (parseFloat(whlsSum)-parseFloat(ldpSum))/(parseFloat(whlsSum)*parseInt(wholesaleUnitsTotal))
    let wholesaleGrossMarginActual = ((totalWhslDollar - parseFloat(totalWhslLdp)) / totalWhslDollar)
    // console.log(catVal[i] + " wholesaleGrossMarginActual -> " + wholesaleGrossMarginActual)
    let blendedBrandGrossMarginActual = ((retailGrossMarginActual + wholesaleGrossMarginActual) / 2)
    // console.log(catVal[i] + " blendedBrandGrossMarginActual -> " + blendedBrandGrossMarginActual)
    let wholesaleGrossMarginScenario = ((parseFloat(whlsSum) - parseFloat(ldpSum)) / (parseFloat(whlsSum) * parseInt(wholesaleUnitsTotal)))
    // console.log(catVal[i] + " wholesaleGrossMarginScenario -> " + wholesaleGrossMarginScenario)
    let blendedBrandGrossMarginScenario = ((retailGrossMarginScenario + wholesaleGrossMarginScenario) / 2)
    // console.log(catVal[i] + " blendedBrandGrossMarginScenario -> " + blendedBrandGrossMarginScenario)
    let retailMarginVariance = (retailMarginScenario - retailMarginActual)
    // console.log(catVal[i] + " retailMarginVariance -> " + retailMarginVariance)
    let wholesaleMarginVariance = (wholesaleMarginScenario - wholesaleMarginActual)
    // console.log(catVal[i] + " wholesaleMarginVariance -> " + wholesaleMarginVariance)
    let blendedBrandMarginVariance = (blendedBrandMarginScenario - blendedBrandMarginActual)
    // console.log(catVal[i] + " blendedBrandMarginVariance -> " + blendedBrandMarginVariance)
    let retailGrossMarginVariance = (retailGrossMarginScenario - retailGrossMarginActual)
    // console.log(catVal[i] + " retailGrossMarginVariance -> " + retailGrossMarginVariance)
    let wholesaleGrossMarginVariance = (wholesaleGrossMarginScenario - wholesaleGrossMarginActual)
    // console.log(catVal[i] + " wholesaleGrossMarginVariance -> " + wholesaleGrossMarginVariance)
    let blendedBrandGrossMarginVariance = (blendedBrandGrossMarginScenario - blendedBrandGrossMarginActual)
    // console.log(catVal[i] + " blendedBrandGrossMarginVariance -> " + blendedBrandGrossMarginVariance)
    let brandUnitsTotal = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Brand Units Total")
    // console.log(catVal[i] + " brandUnitsTotal -> " + brandUnitsTotal)
    let totalDtcSlsSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Total DTC SLS $")
    // console.log(catVal[i] + " totalDtcSlsSum -> " + totalDtcSlsSum)
    let totalWhslSlsSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Total WHSL SLS $")
    // console.log(catVal[i] + " totalWhslSlsSum -> " + totalWhslSlsSum)
    let totalBrandSlsSum = xlEqualToThenReturnColSum(srData, "Category", [catVal[i]], "Total Brand SLS $")
    // console.log(catVal[i] + " totalBrandSlsSum -> " + totalBrandSlsSum)

    SummaryReportAggData.push({
      "Row Labels": catVal[i],
      "Count of Style": Number.isNaN(styleCnt) ? 0 : styleCnt,
      "Retail Margin (Actual)": Number.isNaN(retailMarginActual) ? "0.00%" : convertIntoPercentage(retailMarginActual),
      "Wholesale Margin (Actual)": Number.isNaN(wholesaleMarginActual) ? "0.00%" : convertIntoPercentage(wholesaleMarginActual),
      "Blended Brand Margin (Actual)": Number.isNaN(blendedBrandMarginActual) ? "0.00%" : convertIntoPercentage(blendedBrandMarginActual),
      "Retail Gross Margin (Actual)": Number.isNaN(retailGrossMarginActual) ? "0.00%" : convertIntoPercentage(retailGrossMarginActual),
      "Wholesale Gross Margin (Actual)": Number.isNaN(wholesaleGrossMarginActual) ? "0.00%" : convertIntoPercentage(wholesaleGrossMarginActual),
      "Blended Brand Gross Margin (Actual)": Number.isNaN(blendedBrandGrossMarginActual) ? "0.00%" : convertIntoPercentage(blendedBrandGrossMarginActual),
      "Retail Margin (Scenario)": Number.isNaN(retailMarginScenario) ? "0.00%" : convertIntoPercentage(retailMarginScenario),
      "Wholesale Margin (Scenario)": Number.isNaN(wholesaleMarginScenario) ? "0.00%" : convertIntoPercentage(wholesaleMarginScenario),
      "Blended Brand Margin (Scenario)": Number.isNaN(blendedBrandMarginScenario) ? "0.00%" : convertIntoPercentage(blendedBrandMarginScenario),
      "Retail Gross Margin (Scenario)": Number.isNaN(retailGrossMarginScenario) ? "0.00%" : convertIntoPercentage(retailGrossMarginScenario),
      "Wholesale Gross Margin (Scenario)": Number.isNaN(wholesaleGrossMarginScenario) ? "0.00%" : convertIntoPercentage(wholesaleGrossMarginScenario),
      "Blended Brand Gross Margin (Scenario)": Number.isNaN(blendedBrandGrossMarginScenario) ? "0.00%" : convertIntoPercentage(blendedBrandGrossMarginScenario),
      "Retail Margin Variance": Number.isNaN(retailMarginVariance) ? "0.00%" : convertIntoPercentage(retailMarginVariance),
      "Wholesale Margin Variance": Number.isNaN(wholesaleMarginVariance) ? "0.00%" : convertIntoPercentage(wholesaleMarginVariance),
      "Blended Brand Margin Variance": Number.isNaN(blendedBrandMarginVariance) ? "0.00%" : convertIntoPercentage(blendedBrandMarginVariance),
      "Retail Gross Margin Variance": Number.isNaN(retailGrossMarginVariance) ? "0.00%" : convertIntoPercentage(retailGrossMarginVariance),
      "Wholesale Gross Margin Variance": Number.isNaN(wholesaleGrossMarginVariance) ? "0.00%" : convertIntoPercentage(wholesaleGrossMarginVariance),
      "Blended Brand Gross Margin Variance": Number.isNaN(blendedBrandGrossMarginVariance) ? "0.00%" : convertIntoPercentage(blendedBrandGrossMarginVariance),
      "DTC Units Total": Number.isNaN(parseInt(dtcUnitsTotalSum)) ? 0 : parseInt(dtcUnitsTotalSum),
      "Wholesale Units Total": Number.isNaN(parseInt(wholesaleUnitsTotal)) ? 0 : parseInt(wholesaleUnitsTotal),
      "Brand Units Total": Number.isNaN(parseInt(brandUnitsTotal)) ? 0 : parseInt(brandUnitsTotal),
      "Total DTC SLS $": Number.isNaN(parseFloat(totalDtcSlsSum)) ? 0 : parseFloat(totalDtcSlsSum),
      "Total WHSL SLS $": Number.isNaN(parseFloat(totalWhslSlsSum)) ? 0 : parseFloat(totalWhslSlsSum),
      "Total Brand SLS $": Number.isNaN(parseFloat(totalBrandSlsSum)) ? 0 : parseFloat(totalBrandSlsSum)
    })
    gtCountofStyle = gtCountofStyle + styleCnt
    gtMsrpSum = gtMsrpSum + parseFloat(msrpSum)
    gtWhlsSum = gtWhlsSum + parseFloat(whlsSum)
    gtLdpSum = gtLdpSum + parseFloat(ldpSum)
    gtScenarioLdp = gtScenarioLdp + parseFloat(scenarioLdpSum)
    gtDtcUnitsTotalSum = gtDtcUnitsTotalSum + parseInt(dtcUnitsTotalSum)
    gtDtcChaseUnitsSum = gtDtcChaseUnitsSum + parseInt(dtcChaseUnitsSum)
    gtWholesaleUnitsTotal = gtWholesaleUnitsTotal + parseInt(wholesaleUnitsTotal)
    gtBrandUnitsTotal = gtBrandUnitsTotal + parseInt(brandUnitsTotal)
    gtTotalDtcSlsSum = gtTotalDtcSlsSum + parseFloat(totalDtcSlsSum)
    gtTotalWhslSlsSum = gtTotalWhslSlsSum + parseFloat(totalWhslSlsSum)
    gtTotalBrandSlsSum = gtTotalBrandSlsSum + parseFloat(totalBrandSlsSum)
    styleCnt = null
    msrpSum = null
    ldpSum = null
    retailMarginActual = null
  }

  // console.log("gtMsrpSum -> " + gtMsrpSum)
  // console.log("gtLdpSum -> " + gtLdpSum)
  // console.log("gtWhlsSum -> " + gtWhlsSum)
  gtRetailMarginActual = ((gtMsrpSum - gtLdpSum) / gtMsrpSum)
  // console.log("gtRetailMarginActual -> " + gtRetailMarginActual)
  gtWholesaleMarginActual = ((gtWhlsSum - gtLdpSum) / gtWhlsSum)
  // console.log("gtWholesaleMarginActual -> " + gtWholesaleMarginActual)
  gtBlendedBrandMarginActual = ((gtRetailMarginActual + gtWholesaleMarginActual) / 2)
  // console.log("gtBlendedBrandMarginActual -> " + gtBlendedBrandMarginActual)
  gtRetailGrossMarginActual = ((gtMsrpSum - gtLdpSum) / (gtMsrpSum * gtDtcUnitsTotalSum))
  // console.log("gtRetailGrossMarginActual -> " + gtRetailGrossMarginActual)
  gtWholesaleGrossMarginActual = ((gtWhlsSum - gtLdpSum) / (gtWhlsSum * gtWholesaleUnitsTotal))
  // console.log("gtWholesaleGrossMarginActual -> " + gtWholesaleGrossMarginActual)
  gtBlendedBrandGrossMarginActual = ((gtRetailGrossMarginActual + gtWholesaleGrossMarginActual) / 2)
  // console.log("gtBlendedBrandGrossMarginActual -> " + gtBlendedBrandGrossMarginActual)
  gtRetailMarginScenario = ((gtMsrpSum - gtScenarioLdp) / gtMsrpSum)
  // console.log("gtRetailMarginScenario -> " + gtRetailMarginScenario)
  gtWholesaleMarginScenario = ((gtWhlsSum - gtLdpSum) / gtWhlsSum)
  // console.log("gtWholesaleMarginScenario -> " + gtWholesaleMarginScenario)
  gtBlendedBrandMarginScenario = ((gtRetailMarginScenario + gtWholesaleMarginScenario) / 2)
  // console.log("gtBlendedBrandMarginScenario -> " + gtBlendedBrandMarginScenario)
  gtRetailGrossMarginScenario = ((gtMsrpSum - gtScenarioLdp) / (gtMsrpSum * gtDtcUnitsTotalSum))
  // console.log("gtRetailGrossMarginScenario -> " + gtRetailGrossMarginScenario)
  gtWholesaleGrossMarginScenario = ((gtWhlsSum - gtLdpSum) / (gtWhlsSum * gtWholesaleUnitsTotal))
  // console.log("gtWholesaleGrossMarginScenario -> " + gtWholesaleGrossMarginScenario)
  gtBlendedBrandGrossMarginScenario = ((gtRetailGrossMarginScenario + gtWholesaleGrossMarginScenario) / 2)
  // console.log("gtBlendedBrandGrossMarginScenario -> " + gtBlendedBrandGrossMarginScenario)
  gtRetailMarginVariance = (gtRetailMarginScenario - gtRetailMarginActual)
  // console.log("gtRetailMarginVariance -> " + gtRetailMarginVariance)
  gtWholesaleMarginVariance = (gtWholesaleMarginScenario - gtWholesaleMarginActual)
  // console.log("gtWholesaleMarginVariance -> " + gtWholesaleMarginVariance)
  gtBlendedBrandMarginVariance = (gtBlendedBrandMarginScenario - gtBlendedBrandMarginActual)
  // console.log("gtBlendedBrandMarginVariance -> " + gtBlendedBrandMarginVariance)
  gtRetailGrossMarginVariance = (gtRetailGrossMarginScenario - gtRetailGrossMarginActual)
  // console.log("gtRetailGrossMarginVariance -> " + gtRetailGrossMarginVariance)
  gtWholesaleGrossMarginVariance = (gtWholesaleGrossMarginScenario - gtWholesaleGrossMarginActual)
  // console.log("gtWholesaleGrossMarginVariance -> " + gtWholesaleGrossMarginVariance)
  gtBlendedBrandGrossMarginVariance = (gtBlendedBrandGrossMarginScenario - gtBlendedBrandGrossMarginActual)
  // console.log("gtBlendedBrandGrossMarginVariance -> " + gtBlendedBrandGrossMarginVariance)

  SummaryReportAggData.push({
    "Row Labels": "Grand Total",
    "Count of Style": Number.isNaN(gtCountofStyle) ? 0 : gtCountofStyle,
    "Retail Margin (Actual)": Number.isNaN(gtRetailMarginActual) ? "0.00%" : convertIntoPercentage(gtRetailMarginActual),
    "Wholesale Margin (Actual)": Number.isNaN(gtWholesaleMarginActual) ? "0.00%" : convertIntoPercentage(gtWholesaleMarginActual),
    "Blended Brand Margin (Actual)": Number.isNaN(gtBlendedBrandMarginActual) ? "0.00%" : convertIntoPercentage(gtBlendedBrandMarginActual),
    "Retail Gross Margin (Actual)": Number.isNaN(gtRetailGrossMarginActual) ? "0.00%" : convertIntoPercentage(gtRetailGrossMarginActual),
    "Wholesale Gross Margin (Actual)": Number.isNaN(gtWholesaleGrossMarginActual) ? "0.00%" : convertIntoPercentage(gtWholesaleGrossMarginActual),
    "Blended Brand Gross Margin (Actual)": Number.isNaN(gtBlendedBrandGrossMarginActual) ? "0.00%" : convertIntoPercentage(gtBlendedBrandGrossMarginActual),
    "Retail Margin (Scenario)": Number.isNaN(gtRetailMarginScenario) ? "0.00%" : convertIntoPercentage(gtRetailMarginScenario),
    "Wholesale Margin (Scenario)": Number.isNaN(gtWholesaleMarginScenario) ? "0.00%" : convertIntoPercentage(gtWholesaleMarginScenario),
    "Blended Brand Margin (Scenario)": Number.isNaN(gtBlendedBrandMarginScenario) ? "0.00%" : convertIntoPercentage(gtBlendedBrandMarginScenario),
    "Retail Gross Margin (Scenario)": Number.isNaN(gtRetailGrossMarginScenario) ? "0.00%" : convertIntoPercentage(gtRetailGrossMarginScenario),
    "Wholesale Gross Margin (Scenario)": Number.isNaN(gtWholesaleGrossMarginScenario) ? "0.00%" : convertIntoPercentage(gtWholesaleGrossMarginScenario),
    "Blended Brand Gross Margin (Scenario)": Number.isNaN(gtBlendedBrandGrossMarginScenario) ? "0.00%" : convertIntoPercentage(gtBlendedBrandGrossMarginScenario),
    "Retail Margin Variance": Number.isNaN(gtRetailMarginVariance) ? "0.00%" : convertIntoPercentage(gtRetailMarginVariance),
    "Wholesale Margin Variance": Number.isNaN(gtWholesaleMarginVariance) ? "0.00%" : convertIntoPercentage(gtWholesaleMarginVariance),
    "Blended Brand Margin Variance": Number.isNaN(gtBlendedBrandMarginVariance) ? "0.00%" : convertIntoPercentage(gtBlendedBrandMarginVariance),
    "Retail Gross Margin Variance": Number.isNaN(gtRetailGrossMarginVariance) ? "0.00%" : convertIntoPercentage(gtRetailGrossMarginVariance),
    "Wholesale Gross Margin Variance": Number.isNaN(gtWholesaleGrossMarginVariance) ? "0.00%" : convertIntoPercentage(gtWholesaleGrossMarginVariance),
    "Blended Brand Gross Margin Variance": Number.isNaN(gtBlendedBrandGrossMarginVariance) ? "0.00%" : convertIntoPercentage(gtBlendedBrandGrossMarginVariance),
    "DTC Units Total": Number.isNaN(gtDtcUnitsTotalSum) ? 0 : gtDtcUnitsTotalSum,
    "Wholesale Units Total": Number.isNaN(gtWholesaleUnitsTotal) ? 0 : gtWholesaleUnitsTotal,
    "Brand Units Total": Number.isNaN(gtBrandUnitsTotal) ? 0 : gtBrandUnitsTotal,
    "Total DTC SLS $": Number.isNaN(gtTotalDtcSlsSum) ? 0 : gtTotalDtcSlsSum,
    "Total WHSL SLS $": Number.isNaN(gtTotalWhslSlsSum) ? 0 : gtTotalWhslSlsSum,
    "Total Brand SLS $": Number.isNaN(gtTotalBrandSlsSum) ? 0 : gtTotalBrandSlsSum
  })

  // console.log("SummaryReportAggData")
  // console.log(SummaryReportAggData)
  // console.log("prepareSummaryReportData -> E2 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
  return SummaryReportAggData
}