import Otp from "layouts/authentication/sign-in/otp";
import SignInCustom from "layouts/authentication/sign-in/custom";
import Linelist from "layouts/linelist";
import ProtectedRoute from "ProtectedRoute";

const routes = [
  {
    name: "Login",
    route: "/login",
    key: "login",
    component: <SignInCustom />,
  },
  {
    name: "OTP",
    route: "/otp",
    key: "otp",
    component: <Otp />,
  },
  {
    name: "LineList",
    route: "/linelist",
    key: "linelist",
    component: (
      <ProtectedRoute>
        <Linelist />
      </ProtectedRoute>
    ),
  },
];

export default routes;
