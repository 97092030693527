//***************Test**********************
const appUrl = "https://test.linelist.faherty.sohum.tech/";
const restApiPath = "https://test.linelist.faherty.sohum.tech/faherty_linelist_restapi_test/";
const webScoketUrl = "wss://test.linelist.faherty.sohum.tech/faherty_linelist_restapi_test/";

const applicationTitle = "Linelist";
const afterOtpVerifiedNevigateTo = "linelist";
const customerBrandName = 'Faherty Brand';
const customerBrandLogoPath = `${appUrl}Faherty-Brand.png`;
const groupHeaderHeight = 16;
const HeaderHeight = 50;
const rowHeight = 22;
const currentDate = new Date();
const utcDifference = ((-1) * (currentDate.getTimezoneOffset() / 60));
const isActionItemEnabled = true;
const isSideBarEnabled = true;

export {
  appUrl,
  restApiPath,
  applicationTitle,
  afterOtpVerifiedNevigateTo,
  customerBrandLogoPath,
  customerBrandName,
  webScoketUrl,
  groupHeaderHeight,
  HeaderHeight,
  rowHeight,
  utcDifference,
  isActionItemEnabled,
  isSideBarEnabled
};