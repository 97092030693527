import { useMaterialUIController } from "context";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Buffer } from 'buffer';
import { Box, Divider } from "@mui/material";
import { CH1 } from "layouts/linelist/XL_Utils";
import { getColumnDefByTblName } from "layouts/linelist/XL_Utils";
import MDButton from "components/MDButton";
import { getStateDataByTblName } from "layouts/linelist/getChangesComman";
import { setStateDataByTblName } from "layouts/linelist/getChangesComman";
import { getColumnDropDownValue } from "layouts/linelist/XL_Utils";
import { setCurrentTab } from "context";
import { toastMessage } from "layouts/linelist/XL_Utils";
import { getUnsentCount } from "layouts/linelist/getChangesComman";
import { setUnsentCount } from "layouts/linelist/getChangesComman";

const ll_PD_Owner = {
    headerName: 'PD Owner', field: 'PD Owner', width: 120, sortable: true, filter: true, editable: true, cellStyle: { "backgroundColor": "#FDFCEC" },
    cellEditor: "agRichSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: (params) => {
        return {
            values: getColumnDropDownValue('xl_faherty_master_lov>>PD Owner'),
            filterList: true,
            searchType: "match",
            allowTyping: true,
        }
    }
};

const ll_Factory = {
    headerName: 'Factory', field: 'Factory', width: 140, sortable: true, filter: true, editable: true, cellStyle: { "backgroundColor": "#FDFCEC" },
    cellEditor: "agRichSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: (params) => {
        return {
            values: getColumnDropDownValue('xl_faherty_fb_fty_database>>Factory'),
            filterList: true,
            searchType: "match",
            allowTyping: true,
        }
    }
};

const ll_Delivery_Status = {
    headerName: 'Delivery Status', field: 'Delivery Status', width: 140, sortable: true, filter: true, editable: true, cellStyle: { "backgroundColor": "#FDFCEC" },
    cellEditor: "agRichSelectCellEditor",
    cellEditorPopup: true,
    cellEditorParams: (params) => {
        return {
            values: getColumnDropDownValue('xl_faherty_master_lov>>Delivery Status'),
            filterList: true,
            searchType: "match",
            allowTyping: true,
        }
    }
};

const merchToPdMsg1ColDef = [
    { headerName: 'row_id', field: 'row_id', hide: true, editable: false, cellStyle: { "backgroundColor": "#F0F0F0" } },
    { headerName: 'Season Code', field: 'Season Code', width: 115, sortable: true, filter: true, editable: false, cellStyle: { "backgroundColor": "#F0F0F0" } },
    { headerName: 'Department', field: 'Department', width: 115, sortable: true, filter: true, editable: false, cellStyle: { "backgroundColor": "#F0F0F0" } },
    { headerName: 'Category', field: 'Category', width: 145, sortable: true, filter: true, editable: false, cellStyle: { "backgroundColor": "#F0F0F0" } },
    { headerName: 'SKU', field: 'SKU', width: 130, sortable: true, filter: true, editable: false, cellStyle: { "backgroundColor": "#F0F0F0" } },
    // {
    //     headerName: 'PD Owner', field: 'PD Owner', width: 120, sortable: true, filter: true, editable: true, cellStyle: { "backgroundColor": "#FDFCEC" },
    //     cellEditor: "agRichSelectCellEditor",
    //     cellEditorPopup: true,
    //     cellEditorParams: (params) => {
    //         return {
    //             values: getColumnDropDownValue('xl_faherty_master_lov>>PD Owner'),
    //             filterList: true,
    //             searchType: "match",
    //             allowTyping: true,
    //         }
    //     }
    // },
    // {
    //     headerName: 'Factory', field: 'Factory', width: 140, sortable: true, filter: true, editable: true, cellStyle: { "backgroundColor": "#FDFCEC" },
    //     cellEditor: "agRichSelectCellEditor",
    //     cellEditorPopup: true,
    //     cellEditorParams: (params) => {
    //         return {
    //             values: getColumnDropDownValue('xl_faherty_fb_fty_database>>Factory'),
    //             filterList: true,
    //             searchType: "match",
    //             allowTyping: true,
    //         }
    //     }
    // },
    { headerName: '__isDataChanged', field: "__isDataChanged", hide: true, editable: false, cellStyle: { "backgroundColor": "#F0F0F0" } }
];

const LinelistUserNotification = ({ selectedComponent, removeNotification /* , closeDialog */ }) => {
    // console.log("LinelistUserNotification -> selectedComponent -> ", selectedComponent);
    const tbl_name = "xl_faherty_linelist";
    const gridApi = useRef(null);
    const [columnDefs, setColumnDefs] = useState(merchToPdMsg1ColDef);
    const [controller, dispatch] = useMaterialUIController();
    const {
        asRequiredDownloadQuery,
    } = controller;
    // const colDef = useMemo(() => getColumnDefByTblName(tbl_name), []);
    useEffect(() => {
        const ll_lst_cols = [ll_PD_Owner, ll_Factory, ll_Delivery_Status];
        // console.log("ll_lst_cols -> ", ll_lst_cols);
        // console.log("selectedComponent.user_input_needed_in_columns -> ", selectedComponent.user_input_needed_in_columns);
        if (selectedComponent.table_name === tbl_name && selectedComponent.user_input_needed_in_columns) {
            let colArr = selectedComponent.user_input_needed_in_columns.split('|')
            // console.log(colArr)
            const cd = [...merchToPdMsg1ColDef];
            for (let i = 0; i < colArr.length; i++) {
                // console.log("colArr[i] -> ", colArr[i]);
                const result = ll_lst_cols.find(item => item.headerName === colArr[i]);
                if (result) {
                    cd.push(result);
                }
            }
            // console.log("cd -> ", cd);
            setColumnDefs(cd);
        }
        // console.log("colDef -> ", colDef);
        // const tmpList = ["PD Owner", "Factory"];
        // const columnDefination = [...merchToPdMsg1ColDef];
        // tmpList.map((col) => {
        //     const tmpColDef = colDef.find((cl) => cl.headerName === col && cl.editable);
        //     console.log("tmpColDef -> ", tmpColDef);
        //     if (tmpColDef) {
        //         tmpColDef["cellStyle"] = { "backgroundColor": "#FDFCEC" };
        //         columnDefination.push(tmpColDef);
        //     }
        // });
        // console.log("columnDefination -> ", columnDefination);
        // setColumnDefs(columnDefination);
    }, []);
    const onCellFocused = (event) => {
        // console.log("onCellFocused -> event -> ", event);
        if (event === null || event === undefined) return;
        const focusedCell = event.api.getFocusedCell();
        // console.log("onCellFocused -> focusedCell -> ", focusedCell);
        if (focusedCell === null || focusedCell === undefined) return;
        const rIdx = focusedCell.rowIndex;
        // console.log("onCellFocused -> rIdx -> ", rIdx);
        if (rIdx !== null && rIdx !== undefined) {
            const row = gridApi.current.getDisplayedRowAtIndex(rIdx);
            // console.log("onCellFocused -> row -> ", row);
            if (row !== null && row !== undefined && Object.keys(row).length > 0) {
                // console.log("onCellFocused -> row.data.row_id -> ", row.data.row_id);
                const cid = (selectedComponent.table_name + CH1 + 'SKU')
                // console.log("onCellFocused -> cid -> ", cid);
                // api.ensureIndexVisible(rowNode.rowIndex, 'middle');
                // api.ensureColumnVisible(cid);
                // api.setFocusedCell(rowNode.rowIndex, cid);
                // setTimeout(() => {
                //     api.flashCells({ rowNodes: [rowNode], columns: [cid], flashDuration: 2000, fadeDuration: 1000 });
                // }, 1000);
            }
        }
    };
    const onGridReady = (params) => {
        gridApi.current = params.api;
    };
    const updateArray = (arr1, arr2) => {
        _.forEach(arr1, (item1) => {
            const match = _.find(arr2, { row_id: item1.row_id });
            if (match) {
                _.assign(item1, match);
            }
        });
    };
    const updateAction = () => {
        // How to check tbl_name is downloaded ?
        // How to check notification data is of downloaded tab's data ?
        // console.log("isDownloadLinelistPerformed -> ", sessionStorage.getItem("isDownloadLinelistPerformed"));
        // console.log("asRequiredDownloadQuery -> ", asRequiredDownloadQuery);

        if (sessionStorage.getItem("isDownloadLinelistPerformed") === false || sessionStorage.getItem("isDownloadLinelistPerformed") === 'false') {
            toastMessage("Please perform download data from Season tab.", "warning");
            return;
        }
        if (!asRequiredDownloadQuery || asRequiredDownloadQuery.trim() === "") {
            toastMessage("Please perform download data from Season tab.", "warning");
            return;
        }

        const stateData = getStateDataByTblName(tbl_name);
        let unsentCount = getUnsentCount(tbl_name);

        const updatedRows = [];
        gridApi.current.forEachNode((node) => {
            if (node.data.__isDataChanged) {
                updatedRows.push(node.data);
            }
        });
        // console.log("In updateAction -> updatedRows -> ", updatedRows);

        if (!updatedRows || updatedRows.length === 0) {
            toastMessage("Please first input data.", "warning");
            return;
        }

        let matchedRowIds = []
        _.forEach(stateData, (item1) => {
            const match = _.find(updatedRows, { row_id: item1.row_id });
            // console.log("match -> ", match);
            if (match) {
                matchedRowIds.push(item1.row_id);
            }
        });
        // console.log("matchedRowIds -> ", matchedRowIds);

        if (!matchedRowIds || matchedRowIds.length === 0) {
            toastMessage("Please download data as per notication from Season tab.", "warning");
            return;
        }

        updateArray(stateData, updatedRows);
        unsentCount += updatedRows.length;
        setUnsentCount(tbl_name, unsentCount);
        setStateDataByTblName(tbl_name, stateData);
        setCurrentTab(dispatch, "Linelist");

        toastMessage("Please perform send changes from Linelist tab.", "success");
        removeNotification(selectedComponent.id);
        // closeDialog();
    };
    const onCellEditingStopped = useCallback((event) => {
        const { data } = event;
        data.__isDataChanged = true;
    }, []);
    return (<>
        <div style={{ fontSize: '14px' }}>{selectedComponent.msg}</div>
        <div className="ag-theme-quartz" style={{ width: '770px', height: '250px', marginTop: '4px', marginBottom: '4px' }}>
            <AgGridReact
                onGridReady={(event) => onGridReady(event)}
                onCellEditingStopped={onCellEditingStopped}
                rowData={JSON.parse(Buffer.from(selectedComponent.cell_changes, 'base64').toString('utf8'))}
                // columnDefs={merchToPdMsg1ColDef}
                enableFillHandle={true}
                enableRangeSelection={true}
                enterNavigatesVertically={true}
                enterNavigatesVerticallyAfterEdit={true}
                columnDefs={columnDefs}
            // onCellFocused={(event) => onCellFocused(event)}
            />
        </div>
        <div style={{ marginTop: '4px', fontSize: '14px' }}>{selectedComponent.follow_up_actions}</div>
        <div style={{ display: "flex", justifyContent: "flex-end", flex: 1, }}>
            <MDButton onClick={updateAction} sx={{ textTransform: "none" }} size="small" variant="gradient" color="info">Update</MDButton>
        </div>
    </>)
}

export default LinelistUserNotification;