import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// npm install @mui/x-tree-view
// import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
// import { TreeItem } from '@mui/x-tree-view/TreeItem';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function DashboardLayoutBasic() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const toLabelNode = (str) => {
        return <span style={{ fontSize: "13px" }}>{str}</span>;
    };

    return (
        <></>
        // <Box
        //     sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 200 }}
        // >
        //     <Tabs
        //         orientation="vertical"
        //         variant="scrollable"
        //         value={value}
        //         onChange={handleChange}
        //         aria-label="Vertical tabs example"
        //     // sx={{ borderRight: 1, borderColor: 'divider' }}
        //     >
        //         <Tab label="Cell Timeline" {...a11yProps(0)} />
        //         <Tab label="Save Preference" {...a11yProps(1)} />
        //         <Tab label="Final FOB" {...a11yProps(2)} />
        //         <Tab label="MSRP" {...a11yProps(3)} />
        //         <Tab label="WHLS" {...a11yProps(4)} />
        //         {/* <Tab label="Carry Over" {...a11yProps(6)} />
        //         <Tab label="Functionality 2" {...a11yProps(4)} />
        //         <Tab label="Functionality 3" {...a11yProps(5)} />
        //         <Tab label="Functionality 4" {...a11yProps(6)} />
        //         <Tab label="Functionality 5" {...a11yProps(3)} />
        //         <Tab label="Functionality 6" {...a11yProps(4)} />
        //         <Tab label="Functionality 7" {...a11yProps(5)} /> */}
        //     </Tabs>
        //     <TabPanel value={value} index={0}>
        //         <h4>Cell Timeline</h4>
        //         <p>● You can track the changes of a particular cell, which will show you the changes from the beginning to the present date.</p>
        //         <p>● To trace changes for a specific cell, right-click on the cell and select the desired option from the context menu. </p>
        //         <img class="myimg imground" src="helpui/image/1.png"></img>
        //         <p>● You will see a popup displaying data that represents all the rows as transactions.</p>
        //         <img class="myimg imground" src="helpui/image/2.png"></img>
        //     </TabPanel>
        //     <TabPanel value={value} index={1}>
        //         <h4>Save Preference</h4>
        //         <p>● You can adjust the column width and position as needed, from the right hand side table menu of <b>Columns</b></p>
        //         <img class="myimg imground" src="helpui/image/5.png"></img>
        //         <p>● Below are the details you can change</p>
        //         <p>▶ To Hide column, just uncheck </p>
        //         <p>▶ Resize column width</p>
        //         <p>▶ Drag and move to left right</p>
        //         <p>▶ Set pinned to left or right by selecting three dots and than <b>Pin Column</b></p>
        //         <img class="myimg imground" src="helpui/image/6.png"></img>
        //         <p>Select from the top menu in utility sections</p>
        //         <img class="myimg imground" src="helpui/image/3.png"></img>
        //         <p>You will get popup and you can choose set or reset for revert back to default setting </p>
        //         <img class="myimg imground" src="helpui/image/4.png"></img>
        //     </TabPanel>
        //     <TabPanel value={value} index={2}>
        //         <h4>Final FOB</h4>
        //         <p>Your final fob impact will below column in linelist</p>
        //         <Box sx={{ minHeight: 352, minWidth: 250 }}>
        //             <SimpleTreeView>
        //                 <TreeItem itemId="grid1" label={toLabelNode("Final FOB")}>
        //                     <TreeItem itemId="pickers" label={toLabelNode("Current FOB")}>
        //                         <TreeItem itemId="CostUnderMin-Estimated" label={toLabelNode("Cost Under Min - Estimated")} />
        //                         <TreeItem itemId="CurrentLDP-Status" label={toLabelNode("Current LDP - Status")} />
        //                         <TreeItem itemId="LDP" label={toLabelNode("LDP")} >
        //                             <TreeItem itemId="RTLIMU" label={toLabelNode("RTL IMU%")}>
        //                                 <TreeItem itemId="WorkingVsScenarioRTLIMU" label={toLabelNode("Working Vs Scenario RTL IMU%")} />
        //                             </TreeItem>
        //                             <TreeItem itemId="WHLSIMU" label={toLabelNode("WHLS IMU%")}>
        //                                 <TreeItem itemId="WorkingVsScenarioWHSL" label={toLabelNode("Working Vs Scenario WHSL IMU%")} />
        //                             </TreeItem>
        //                         </TreeItem>
        //                     </TreeItem>
        //                 </TreeItem>
        //             </SimpleTreeView>
        //         </Box>
        //     </TabPanel>
        //     <TabPanel value={value} index={3}>
        //         <h4>MSRP</h4>
        //         <p>Your MSRP impact will below column in linelist</p>
        //         <Box sx={{ minHeight: 352, minWidth: 250 }}>
        //             <SimpleTreeView>
        //                 <TreeItem itemId="grid2" label={toLabelNode("MSRP")}>
        //                     <TreeItem itemId="1000IMU" label={toLabelNode("1000 - IMU%")} />
        //                     <TreeItem itemId="300IMU" label={toLabelNode("300 - IMU%")} />
        //                     <TreeItem itemId="500IMU" label={toLabelNode("500 - IMU%")} />
        //                     <TreeItem itemId="RTLIMU" label={toLabelNode("RTL IMU%")}>
        //                         <TreeItem itemId="WorkingVsScenarioRTLIMU" label={toLabelNode("Working Vs Scenario RTL IMU%")} />
        //                     </TreeItem>
        //                     <TreeItem itemId="WHLSIMU" label={toLabelNode("WHLS IMU%")}>
        //                         <TreeItem itemId="WorkingVsScenarioWHSL" label={toLabelNode("Working Vs Scenario WHSL IMU%")} />
        //                     </TreeItem>
        //                     <TreeItem itemId="TotalDTCSLS" label={toLabelNode("Total DTC SLS $")}>
        //                         <TreeItem itemId="Total Brand SLS $" label={toLabelNode("Total Brand SLS $")} />
        //                     </TreeItem>
        //                 </TreeItem>
        //             </SimpleTreeView>
        //         </Box>
        //     </TabPanel>
        //     <TabPanel value={value} index={4}>
        //         <h4>WHLS</h4>
        //         <p>Your WHLS impact will below column in linelist</p>
        //         <Box sx={{ minHeight: 352, minWidth: 250 }}>
        //             <SimpleTreeView >
        //                 <TreeItem itemId="grid3" label={toLabelNode("WHLS")}>
        //                     <TreeItem itemId="ScenarioWHLSIMU1" label={toLabelNode("Scenario WHLS IMU%")} >
        //                         <TreeItem itemId="WorkingVsScenarioWHSLIMU1" label={toLabelNode("Working Vs Scenario WHSL IMU%")} />
        //                     </TreeItem>
        //                     <TreeItem itemId="TotalWHSLSLS1" label={toLabelNode("Total WHSL SLS $")} >
        //                         <TreeItem itemId="TotalBrandSLS1" label={toLabelNode("Total Brand SLS $")} />
        //                     </TreeItem>
        //                     <TreeItem itemId="WHLSIMU" label={toLabelNode("WHLS IMU%")} >
        //                         <TreeItem itemId="WorkingVsScenarioWHSLIMU2" label={toLabelNode("Working Vs Scenario WHSL IMU%")} />
        //                     </TreeItem>
        //                     <TreeItem itemId="WorkingVsScenarioWHSLIMU3" label={toLabelNode("Working Vs Scenario WHSL IMU%")} />
        //                 </TreeItem>
        //             </SimpleTreeView>
        //         </Box>
        //     </TabPanel>
        // </Box >
    );
}
