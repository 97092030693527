


import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { AgGridReact } from "ag-grid-react";
import MDButton from "components/MDButton";
import { ExcelDateToJsDate, getAllActiveUserData, toastMessage } from 'layouts/linelist/XL_Utils';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { restApiPath } from "utils/app.props";

const ignoreColsInPvt = ['row_id', 'user_inserted', 'inserted_on', 'user_modified', 'modified_on', 'linelist_row_id']
const numericColType = ['price', 'qty', 'percentage', 'decimal', '1decimal', '4decimal']

const PivotTab = forwardRef(({ setCurrentGridApi }, ref) => {
    const dispatch = useDispatch();
    const nevigate = useNavigate();
    const [colDef, setColDef] = useState([]);
    const [srcData, setSrcData] = useState([]);
    const gridApi = useRef({});
    const gridColumnApi = useRef({});
    const LinelistColDef = useSelector(s => s.LinelistColDef ? s.LinelistColDef : []);
    const LinelistData = useSelector(s => s.LinelistData ? s.LinelistData : []);
    const DtcBuySheetColDef = useSelector(s => s.DtcBuySheetColDef ? s.DtcBuySheetColDef : []);
    const DtcBuySheetData = useSelector(s => s.DtcBuySheetData ? s.DtcBuySheetData : []);
    const SizingColDef = useSelector(s => s.SizingColDef ? s.SizingColDef : []);
    const SizingData = useSelector(s => s.SizingData ? s.SizingData : []);
    const CompApsMasterStoreEcomData = useSelector(s => s.CompApsMasterStoreEcomData ? s.CompApsMasterStoreEcomData : []);
    const CompApsMasterStoreEcomColDef = useSelector(s => s.CompApsMasterStoreEcomColDef ? s.CompApsMasterStoreEcomColDef : []);
    const FreightMasterData = useSelector(s => s.FreightMasterData ? s.FreightMasterData : []);
    const FreightMasterColDef = useSelector(s => s.FreightMasterColDef ? s.FreightMasterColDef : []);
    const FreightByCategoryData = useSelector(s => s.FreightByCategoryData ? s.FreightByCategoryData : []);
    const FreightByCategoryColDef = useSelector(s => s.FreightByCategoryColDef ? s.FreightByCategoryColDef : []);
    const WhslBuySheetData = useSelector(s => s.WhslBuySheetData ? s.WhslBuySheetData : []);
    const WhslBuySheetColDef = useSelector(s => s.WhslBuySheetColDef ? s.WhslBuySheetColDef : []);
    const WhslBuySheetNordRpData = useSelector(s => s.WhslBuySheetNordRpData ? s.WhslBuySheetNordRpData : []);
    const WhslBuySheetNordRpColDef = useSelector(s => s.WhslBuySheetNordRpColDef ? s.WhslBuySheetNordRpColDef : []);
    const BuySheetMasterSizingPercentageData = useSelector(s => s.BuySheetMasterSizingPercentageData ? s.BuySheetMasterSizingPercentageData : []);
    const BuySheetMasterSizingPercentageColDef = useSelector(s => s.BuySheetMasterSizingPercentageColDef ? s.BuySheetMasterSizingPercentageColDef : []);
    const BuyShtMasterDataChartsData = useSelector(s => s.BuyShtMasterDataChartsData ? s.BuyShtMasterDataChartsData : []);
    const BuyShtMasterDataChartsColDef = useSelector(s => s.BuyShtMasterDataChartsColDef ? s.BuyShtMasterDataChartsColDef : []);
    const ChaseData = useSelector(s => s.ChaseData ? s.ChaseData : []);
    const ChaseColDef = useSelector(s => s.ChaseColDef ? s.ChaseColDef : []);
    const [isPublishEnabled, setIsPublishEnabled] = useState(false);
    const [pivotName, setPivotName] = useState("");
    const [selectedPivot, setSelectedPivot] = useState("");
    const [selectedTabName, setSelectedTabName] = useState("");
    const [selectedTable, setSelectedTable] = useState("");
    const [displayPublishButton, setDisplayPublishButton] = useState(false);
    const [openDlg, setOpenDlg] = useState(false)
    const [selectedStateRowId, setSelectedStateRowId] = useState("");
    const [checkedUserList, setCheckedUserList] = useState([]);
    const [pvtNamesList, setPvtNamesList] = useState([]);
    // const [pvtsData, setPvtsData] = useState([]);
    const pvtsData = useSelector(s => s.pvtsData ? s.pvtsData : []);
    const [userList, setUserList] = useState([]);
    const [activeUsersData, setActiveUsersData] = useState([]);
    const [isGridReady, setIsGridReady] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const [openPublishDlg, setPublishDlg] = useState(false);
    const [savePvtEnabled, setSavePvtEnabled] = useState(false);
    const [saveAsPvtEnabled, setSaveAsPvtEnabled] = useState(false);
    const [deletePvtEnabled, setDeletePvtEnabled] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const mpForTabs = useSelector(s => s.mpForTabs ? s.mpForTabs : []);
    const [pvtForTabs, setPvtForTabs] = useState([]);

    // const [lstPublishedUsers, setLstPublishedUsers] = useState([]);
    const lstPublishedUsers = useRef([]);

    const getColDefAndData = (tabName) => {
        // console.log("getColDefAndData -> tabName -> " + tabName)
        let retMp = new Map()
        if (tabName === null || tabName === undefined || tabName.trim() === "") {
            retMp.set("colDef", [])
            retMp.set("data", [])
            return retMp
        }
        if (tabName === "Linelist") {
            let tmpData = LinelistData
            tmpData = getNumericData(LinelistColDef, tmpData)
            retMp.set("colDef", LinelistColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "DTC_Buy_Sheet") {
            let tmpData = DtcBuySheetData
            tmpData = getNumericData(DtcBuySheetColDef, tmpData)
            retMp.set("colDef", DtcBuySheetColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Sizing") {
            let tmpData = SizingData
            tmpData = getNumericData(SizingColDef, tmpData)
            retMp.set("colDef", SizingColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Comp_APS_Master_StoreEcom") {
            let tmpData = CompApsMasterStoreEcomData
            tmpData = getNumericData(CompApsMasterStoreEcomColDef, tmpData)
            retMp.set("colDef", CompApsMasterStoreEcomColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Freight_by_Category") {
            let tmpData = FreightByCategoryData
            tmpData = getNumericData(FreightByCategoryColDef, tmpData)
            retMp.set("colDef", FreightByCategoryColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Freight_Master") {
            let tmpData = FreightMasterData
            tmpData = getNumericData(FreightMasterColDef, tmpData)
            retMp.set("colDef", FreightMasterColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Whsl_Buy_Sheet") {
            let tmpData = WhslBuySheetData
            tmpData = getNumericData(WhslBuySheetColDef, tmpData)
            retMp.set("colDef", WhslBuySheetColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Whsl_Buy_Sheet_Nord_RP") {
            let tmpData = WhslBuySheetNordRpData
            tmpData = getNumericData(WhslBuySheetNordRpColDef, tmpData)
            retMp.set("colDef", WhslBuySheetNordRpColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Buy_Sheet_Master_Sizing_%") {
            let tmpData = BuySheetMasterSizingPercentageData
            tmpData = getNumericData(BuySheetMasterSizingPercentageColDef, tmpData)
            retMp.set("colDef", BuySheetMasterSizingPercentageColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "Chase") {
            let tmpData = ChaseData
            tmpData = getNumericData(ChaseColDef, tmpData)
            retMp.set("colDef", ChaseColDef)
            retMp.set("data", tmpData)
        } else if (tabName === "BuySht_Master_Data_Charts") {
            let tmpData = BuyShtMasterDataChartsData
            tmpData = getNumericData(BuyShtMasterDataChartsColDef, tmpData)
            retMp.set("colDef", BuyShtMasterDataChartsColDef)
            retMp.set("data", tmpData)
        } else {
            retMp.set("colDef", [])
            retMp.set("data", [])
        }
        return retMp
    };
    const getNumericData = (colDef, tmpData) => {
        colDef.map((col) => {
            if (numericColType.includes(col.type)) {
                for (let i = 0; i < tmpData.length; i++) {
                    let ov = tmpData[i][col.field]
                    if (ov === null || ov === undefined || ov.toString().trim() === "") {
                        tmpData[i][col.field] = 0
                    } else {
                        tmpData[i][col.field] = parseFloat(ov)
                    }
                    ov = null
                }
            }
            if (col.type === "date") {
                for (let i = 0; i < tmpData.length; i++) {
                    let ov = tmpData[i][col.field]
                    if (ov === null || ov === undefined || ov.toString().trim() === "" || ov.toString() === "0") {
                        tmpData[i][col.field] = null
                    } else {
                        tmpData[i][col.field] = ExcelDateToJsDate(ov)
                    }
                    ov = null
                }
            }
        })
        return tmpData
    };
    const percentageVF = (vl) => {
        if (vl === null || vl === undefined || vl.toString().trim() === "") {
            return "0.00%";
        }
        if (vl.toString().includes(".")) {
            if (vl > 1) {
                return parseFloat(vl).toFixed(2) + "%";
            } else {
                return parseFloat(vl * 100).toFixed(2) + "%";
            }
        } else {
            return parseFloat(vl).toFixed(2) + "%";
        }
    };
    const setEnablePivot = (tabName, mp) => {
        mp.enablePivot = true
        mp.enableRowGroup = true
        mp.enableValue = true
        if (mp.type === "price") {
            mp.cellRenderer = (params) => ((params.value === null || params.value === undefined || params.value === "") ? "$0" : ("$" + params.value.toLocaleString('en-US')))
        }
        if (mp.type === "qty") {
            mp.cellRenderer = (params) => params.value.toLocaleString('en-US')
        }
        if (mp.type === "percentage") {
            mp.cellRenderer = (params) => percentageVF(params.value)
        }
        if (mp.type === "date") {
            mp.cellRenderer = (params) => ExcelDateToJsDate(params.value)
        }
        if (mp.type === "decimal") {
            mp.cellRenderer = (params) => ((params.value === null || params.value === undefined || params.value === "") ? "0.00" : params.value.toLocaleString('en-US'))
        }
        if (mp.type === "1decimal") {
            mp.cellRenderer = (params) => ((params.value === null || params.value === undefined || params.value === "") ? "0.0" : params.value.toLocaleString('en-US'))
        }
        if (mp.type === "4decimal") {
            mp.cellRenderer = (params) => ((params.value === null || params.value === undefined || params.value === "") ? "0.0000" : params.value.toLocaleString('en-US'))
        }

    };
    const handleOnChangeName = (event, newValue) => {
        // console.log("handleOnChangeName -> newValue -> " + newValue)
        // console.log("pvtsData")
        // console.log(pvtsData)

        sessionStorage.setItem("savedSelectedTabName", "")

        if (newValue === null || newValue === undefined || newValue.trim() === "") {
            gridApi.current.setFilterModel(null)
            gridApi.current.applyColumnState({ state: null })
            // gridApi.current.setPivotMode(true)
            gridApi.current.setRowGroupColumns([])
            gridApi.current.setPivotColumns([])
            gridApi.current.setValueColumns([])
            setSelectedTabName("")
            setSelectedStateRowId("")
            setSelectedPivot("")
            setSavePvtEnabled(false)
            // setSaveAsPvtEnabled(false)
            setDeletePvtEnabled(false)
            setIsPublishEnabled(false)
            setColDef([])
            setSrcData([])
            return
        }

        getPivotConfig()

        let mp = getColDefAndData(newValue)

        let dt = mp.get("data")
        // console.log("handleOnChangeName -> dt")
        // console.log(dt)
        if (dt === null || dt === undefined || dt.length === 0) {
            toastMessage("No data found for '" + newValue + "' tab." + " Please first download its data.", "warning");

            return
        }

        let temp = mp.get("colDef")
        // console.log("handleOnChangeName -> temp")
        // console.log(temp)
        temp = temp.map((col) => {
            let mpCol = col
            if (ignoreColsInPvt.includes(col.field)) {
                mpCol = { headerName: col.headerName, field: col.field, hide: true, enablePivot: false, rowGroup: false, enableRowGroup: false, enableValue: false }
                return mpCol
            } else {
                mpCol = { headerName: col.headerName, field: col.field, enablePivot: true, enableValue: true, type: col.type }
                setEnablePivot(newValue, mpCol)
                return mpCol
            }
        })
        // setSelectedTabName(newValue)

        if (pvtsData === null || pvtsData === undefined || pvtsData.length === 0) {
            setColDef(temp)
            setSrcData(mp.get("data"))
            setSelectedPivot("")
            setSelectedTable("")
            setPvtNamesList([])

            //
            sessionStorage.setItem("savedSelectedPivotName", "")
            gridApi.current.setFilterModel(null)
            gridApi.current.applyColumnState({ state: null })
            // gridApi.current.setPivotMode(true)
            gridApi.current.setRowGroupColumns([])
            gridApi.current.setPivotColumns([])
            gridApi.current.setValueColumns([])
            //

            setSelectedTabName(newValue)
            sessionStorage.setItem("savedSelectedTabName", newValue)
            // setSaveAsPvtEnabled(true)
        } else {
            setSelectedPivot("")
            setPvtNamesList([])

            //
            sessionStorage.setItem("savedSelectedPivotName", "")
            gridApi.current.setFilterModel(null)
            gridApi.current.applyColumnState({ state: null })
            // gridApi.current.setPivotMode(true)
            gridApi.current.setRowGroupColumns([])
            gridApi.current.setPivotColumns([])
            gridApi.current.setValueColumns([])
            //

            let lst = new Array()
            for (let i = 0; i < pvtsData.length; i++) {
                if (pvtsData[i]["srcTableName"] === mpForTabs.get(newValue)) {
                    if (lst.includes(pvtsData[i]["stateName"]) === false) {
                        lst.push(pvtsData[i]["stateName"])
                    }
                }
            }
            lst.sort()
            // console.log("lst")
            // console.log(lst)
            setSelectedTable(mpForTabs.get(newValue))
            setPvtNamesList(lst)
            setColDef(temp)
            setSrcData(mp.get("data"))
            setSelectedTabName(newValue)
            sessionStorage.setItem("savedSelectedTabName", newValue)
            // setSaveAsPvtEnabled(true)
        }
    };
    const handleOnPivotSelect = (event, pvtNm) => {
        // console.log("handleOnPivotSelect -> pvtNm -> " + pvtNm)
        sessionStorage.setItem("savedSelectedPivotName", "")

        if (pvtNm === null || pvtNm === undefined || pvtNm.trim() === "") {
            gridApi.current.setFilterModel(null)
            gridApi.current.applyColumnState({ state: null })
            // gridApi.current.setPivotMode(true)
            gridApi.current.setRowGroupColumns([])
            gridApi.current.setPivotColumns([])
            gridApi.current.setValueColumns([])
            setSelectedStateRowId("")
            setSelectedPivot("")
            setSavePvtEnabled(false)
            // setSaveAsPvtEnabled(true)
            setDeletePvtEnabled(false)
            setIsPublishEnabled(false)
            return
        }

        // console.log("pvtsData")
        // console.log(pvtsData)

        if (pvtsData === null || pvtsData === undefined || pvtsData.length === 0) {
            gridApi.current.setFilterModel(null)
            gridApi.current.applyColumnState({ state: null })
            // gridApi.current.setPivotMode(true)
            gridApi.current.setRowGroupColumns([])
            gridApi.current.setPivotColumns([])
            gridApi.current.setValueColumns([])
            setSelectedStateRowId("")
            setSelectedPivot("")
            setSavePvtEnabled(false)
            // setSaveAsPvtEnabled(true)
            setDeletePvtEnabled(false)
            setIsPublishEnabled(false)
            return
        } else {
            try {
                // console.log("handleOnPivotSelect -> pvtNm -> try")
                let tmpConfig = new Object()
                for (let i = 0; i < pvtsData.length; i++) {
                    if (pvtsData[i]["stateName"] === pvtNm) {
                        tmpConfig = pvtsData[i]
                    }
                }
                // console.log("tmpConfig")
                // console.log(tmpConfig)

                let userInsertedVl = tmpConfig["userInserted"]
                // console.log("userInsertedVl -> " + userInsertedVl)
                if (userInsertedVl === null || userInsertedVl === undefined || userInsertedVl.trim() === "") {
                    setIsPublishEnabled(false)
                } else {
                    if (userInsertedVl === sessionStorage.getItem('email')) {
                        setIsPublishEnabled(true)
                    } else {
                        setIsPublishEnabled(false)
                    }
                }

                // let mp = getColDefAndData(selectedTabName)
                let mp = getColDefAndData(sessionStorage.getItem("savedSelectedTabName"))
                setSrcData(mp.get("data"))

                if (tmpConfig !== null && tmpConfig !== undefined && Object.keys(tmpConfig).length > 0) {
                    gridApi.current.setFilterModel(JSON.parse(tmpConfig["filterModel"]))
                    gridApi.current.applyColumnState({ state: JSON.parse(tmpConfig["columnState"]), applyOrder: true })
                    gridApi.current.setColumnGroupState(JSON.parse(tmpConfig["groupState"]))
                    setSelectedStateRowId(tmpConfig["rowId"])
                    setSelectedPivot(pvtNm)
                    sessionStorage.setItem("savedSelectedPivotName", pvtNm)
                    if (tmpConfig["userInserted"] === sessionStorage.getItem("email")) {
                        setDeletePvtEnabled(true)
                        setSavePvtEnabled(true)
                    }
                    // setSaveAsPvtEnabled(false)
                }
            } catch (error) {
                console.log(error)
                toastMessage("Error in changing pivot", "error")

            }
        }
    };
    const onGridReady = (params) => {
        gridApi.current = params.api
        gridColumnApi.current = params.columnApi
        // setCurrentGridApi(params)
        setIsGridReady(true)
    };
    const onFirstDataRendered = (params) => {
        setColsBack()
        setPublishBack()
        setDeleteBack()
    };
    const onBtnSaveAsClick = () => {
        // console.log("In -> onBtnSaveAsClick -> selectedPivot -> " + selectedPivot)
        // console.log("In -> onBtnSaveAsClick -> selectedTabName -> " + selectedTabName)
        if (selectedPivot !== null && selectedPivot !== undefined && selectedPivot.trim() !== "") {
            toastMessage("'Save As' is disabled for existing pivot.")


            return
        }

        // console.log("gridApi.current.getAllDisplayedColumns()")
        // console.log(gridApi.current.getAllDisplayedColumns())
        let allDispCols = gridApi.current.getAllDisplayedColumns()
        // console.log("typeof(allDispCols) -> " + typeof(allDispCols))
        // console.log(allDispCols)
        if (allDispCols === null || allDispCols === undefined || allDispCols.length === 0) {
            toastMessage("Please first configure pivot.")


            return
        }
        setOpenDlg(true)
    };
    const onBtnSaveClick = () => {
        // console.log("In -> onBtnSaveClick -> selectedTabName -> " + selectedTabName)
        // console.log("In -> onBtnSaveClick -> selectedPivot -> " + selectedPivot)
        if (selectedTabName === null || selectedTabName === undefined || selectedTabName.trim() === "") {
            toastMessage("Please first select tab name.")


            return
        }
        if (selectedPivot === null || selectedPivot === undefined || selectedPivot.trim() === "") {
            toastMessage("Please first select pivot name.")


            return
        }

        const colState = gridApi.current.getColumnState();
        let sortingState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        let headersVal = {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let bodyVal = {
            "rowId": selectedStateRowId,
            "columnState": "start=" + JSON.stringify(gridApi.current.getColumnState()),
            "filterModel": "start=" + JSON.stringify(gridApi.current.getFilterModel()),
            "groupState": "start=" + JSON.stringify(gridApi.current.getColumnGroupState()),
            "pivotMode": gridApi.current.isPivotMode(),
            "sortModel": "start=" + JSON.stringify(sortingState),
            "userModified": sessionStorage.getItem("email")
        }
        // console.log("headersVal")
        // console.log(headersVal)
        // console.log("bodyVal")
        // console.log(bodyVal)

        fetch(restApiPath + 'updatePivotTable', {
            method: 'POST',
            headers: headersVal,
            body: JSON.stringify(bodyVal)
        })
            .then(result => result.json())
            .then(response => savePivotStateResponseActions(response, bodyVal))
    };
    const savePivotStateResponseActions = (res, reqParam) => {
        if (res.response === true && res.message === "SUCCESS") {
            // console.log("reqParam")
            // console.log(reqParam)
            const colState = gridApi.current.getColumnState();
            let sortingState = colState
                .filter(function (s) {
                    return s.sort != null;
                })
                .map(function (s) {
                    return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
                });
            // console.log("pvtsData")
            // console.log(pvtsData)
            for (let i = 0; i < pvtsData.length; i++) {
                if (pvtsData[i]["rowId"] === reqParam["rowId"]) {
                    pvtsData[i]["columnState"] = JSON.stringify(gridApi.current.getColumnState());
                    pvtsData[i]["filterModel"] = JSON.stringify(gridApi.current.getFilterModel());
                    pvtsData[i]["groupState"] = JSON.stringify(gridApi.current.getColumnGroupState());
                    pvtsData[i]["sortModel"] = JSON.stringify(sortingState);
                }
            }

            toastMessage("'" + selectedPivot + "' saved successfully !!!", "success");

        } else {
            toastMessage("Error in saving '" + selectedPivot + "' pivot", "error")

        }
    };
    const onBtnConfirmDeleteClick = () => {
        // console.log("In -> onBtnConfirmDeleteClick -> selectedTabName -> " + selectedTabName)
        // console.log("In -> onBtnConfirmDeleteClick -> selectedPivot -> " + selectedPivot)
        if (selectedTabName === null || selectedTabName === undefined || selectedTabName.trim() === "") {
            toastMessage("Please first select tab name.")


            return
        }
        if (selectedPivot === null || selectedPivot === undefined || selectedPivot.trim() === "") {
            toastMessage("Please first select pivot name.")


            return
        }

        setShowDeletePopup(true)
    };
    const getUsersToWhomPivotPublishedList = () => {
        // console.log("IN -> getUsersToWhomPivotPublishedList")
        let headersVal = {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let bodyVal = {
            "srcTableName": selectedTable,
            "stateName": selectedPivot,
            "rowId": parseInt(selectedStateRowId)
        }

        fetch(restApiPath + 'getUsersToWhomPivotPublished', {
            method: 'POST',
            headers: headersVal,
            body: JSON.stringify(bodyVal)
        })
            .then(result => result.json())
            .then(response => postGetUsersToWhomPivotPublishedList(response))
    };
    const postGetUsersToWhomPivotPublishedList = (res) => {
        if (res.response === true && res.message === "SUCCESS") {
            lstPublishedUsers.current = res.data;
        } else {
            lstPublishedUsers.current = [];
        }
    };
    const onBtnPublishClick = () => {
        // console.log("In -> onBtnPublishClick -> selectedTabName -> " + selectedTabName)
        // console.log("In -> onBtnPublishClick -> selectedPivot -> " + selectedPivot)
        // console.log("In -> onBtnPublishClick -> selectedStateRowId -> " + selectedStateRowId)
        // console.log("In -> onBtnPublishClick -> selectedTable -> " + selectedTable)
        if (selectedTabName === null || selectedTabName === undefined || selectedTabName.trim() === "") {
            toastMessage("Please first select tab name.")


            return
        }
        if (selectedPivot === null || selectedPivot === undefined || selectedPivot.trim() === "") {
            toastMessage("Please first select pivot name.")


            return
        }

        // console.log("onBtnPublishClick -> pvtsData")
        // console.log(pvtsData)

        getUsersToWhomPivotPublishedList()
        // console.log("onBtnPublishClick -> lstPublishedUsers")
        // console.log(lstPublishedUsers)

        getAllActiveUserData().then(res => {
            if (res === "invalid_token") {
                toastMessage("Session Expired", "warning")

                sessionStorage.clear()
                setTimeout(() => { nevigate("/login") }, 5000)
            } else {
                // console.log("onBtnPublishClick -> getAllActiveUserData -> res")
                // console.log(res)
                if (res === null || res === undefined || res === "") {
                } else {
                    let userList = new Array()
                    for (let i = 0; i < res.length; i++) {
                        if (userList.includes(res[i]["userName"]) === false && res[i]["user_type"] !== "vendor") {
                            if (res[i]["userName"] !== sessionStorage.getItem("email")) {
                                userList.push(res[i]["userName"])
                            }
                        }
                    }
                    userList.sort()
                    // console.log("onBtnPublishClick -> userList")
                    // console.log(userList)
                    setUserList(userList)
                    setPublishDlg(true)
                    setActiveUsersData(res)

                    let ul = lstPublishedUsers.current
                    let lst = new Array()
                    if (ul !== null && ul !== undefined && ul.length > 0) {
                        if (userList !== null && userList !== undefined && userList.length > 0) {
                            for (let i = 0; i < ul.length; i++) {
                                if (userList.includes(ul[i])) {
                                    lst.push(ul[i])
                                }
                            }
                        }
                    }
                    // console.log("onBtnPublishClick -> ul")
                    // console.log(ul)
                    // setCheckedUserList(lstPublishedUsers.current)
                    setCheckedUserList(ul)
                }
            }
        });
    };
    const handleClose = () => {
        setOpenDlg(false)
    };
    const handlePublishClose = () => {
        setPublishDlg(false)
    };
    const publishPivot = () => {
        // console.log("In publishPivot")
        // console.log("checkedUserList")
        // console.log(checkedUserList)
        let headersVal = {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (checkedUserList.length > 0) {
            let pg = (sessionStorage.getItem("user_type") === "vendor" ? "vendor" : "faherty")
            let paramsVal = "rowId=" + selectedStateRowId + "&userInserted=" + sessionStorage.getItem("email") + "&permissionGroup=" + pg + "&userList=" + checkedUserList.join(',')
            let reqVl = restApiPath + 'publishPivotTable?' + paramsVal
            console.log("publishPivot -> reqVl")
            console.log(reqVl)
            fetch(reqVl, {
                method: 'POST',
                headers: headersVal
            })
                .then(result => result.json())
                .then(response => postPublishPivotStateActions(response))
        } else {
            toastMessage("Please select atleast one user.")


        }
    };
    const postPublishPivotStateActions = (res) => {
        if (res.response === true && res.message === "SUCCESS") {
            toastMessage("'" + selectedPivot + "' published successfully !!!", "success");

            setPublishDlg(false)
        } else {
            toastMessage("Error in publishing '" + selectedPivot + "' pivot", "error")

            setPublishDlg(false)
        }
    };
    const handleListItemToggle = (vl) => {
        // console.log("In -> handleListItemToggle -> vl -> " + vl)
        let tmpLst = checkedUserList
        if (tmpLst === null || tmpLst === undefined || tmpLst.length === 0) {
            tmpLst.push(vl)
        } else {
            if (tmpLst.includes(vl) === false) {
                tmpLst.push(vl)
            }
        }
        setCheckedUserList(tmpLst)
    };
    const handelUserSelect = (event, newValue) => {
        // console.log("In -> handelUserSelect -> newValue")
        // console.log(newValue)
        setCheckedUserList(newValue)
    };
    const onPivotNameChange = (e) => {
        // console.log("In -> onPivotNameChange -> e.target.value -> " + e.target.value)
        setPivotName(e.target.value)
    };
    const createNewState = () => {
        if (!pivotName) {
            toastMessage("State Name should not be blank", "error");

        } else if (pivotName.toLowerCase() === 'new') {
            toastMessage("State Name = 'New' is not allowed", "error");

        } else {
            savePivotGeneric("button")
        }
    };
    const onDeletePivotConfirm = () => {
        // console.log("In onDeletePivotConfirm")
        let headersVal = {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let bodyVal = {
            "rowId": selectedStateRowId,
            "stateName": selectedPivot,
            "userModified": sessionStorage.getItem("email")
        }
        fetch(restApiPath + 'deletePivotTable', {
            method: 'POST',
            headers: headersVal,
            body: JSON.stringify(bodyVal)
        })
            .then(result => result.json())
            .then(response => postDeletePivotStateActions(response))
    };
    const postDeletePivotStateActions = (res) => {
        if (res.response === true && res.message === "SUCCESS") {
            let lst = pvtNamesList
            // console.log(lst)
            const idx = lst.indexOf(selectedPivot);
            // console.log("postDeletePivotStateActions -> idx -> " + idx)
            if (idx > -1) {
                lst.splice(idx, 1)
            }
            // console.log(lst)
            setPvtNamesList(lst)

            toastMessage("'" + selectedPivot + "' deleted successfully !!!", "success");

            gridApi.current.setFilterModel(null)
            gridApi.current.applyColumnState({ state: null })
            // gridApi.current.setPivotMode(true)
            gridApi.current.setRowGroupColumns([])
            gridApi.current.setPivotColumns([])
            gridApi.current.setValueColumns([])
            setSelectedStateRowId("")
            setSrcData([])
            setSelectedPivot("")
            setShowDeletePopup(false)
        } else {
            toastMessage("Error in deleting '" + selectedPivot + "' pivot", "error")

            setShowDeletePopup(false)
        }
    };
    const onDeletePivotCancel = () => {
        // console.log("In onDeletePivotCancel")
        setShowDeletePopup(false)
    };
    const savePivotGeneric = (from) => {
        // console.log("In savePivotGeneric")
        const colState = gridApi.current.getColumnState();
        let sortingState = colState
            .filter(function (s) {
                return s.sort != null;
            })
            .map(function (s) {
                return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
            });
        let headersVal = {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let bodyVal = {
            "srcTableName": mpForTabs.get(selectedTabName),
            "stateName": pivotName,
            "columnState": "start=" + JSON.stringify(gridApi.current.getColumnState()),
            "filterModel": "start=" + JSON.stringify(gridApi.current.getFilterModel()),
            "groupState": "start=" + JSON.stringify(gridApi.current.getColumnGroupState()),
            "pivotMode": gridApi.current.isPivotMode(),
            "sortModel": "start=" + JSON.stringify(sortingState),
            "userType": sessionStorage.getItem("user_type"),
            "permissionGroup": (sessionStorage.getItem("user_type") === "vendor" ? "vendor" : "faherty"),
            "userInserted": sessionStorage.getItem("email")
        }
        // console.log("headersVal")
        // console.log(headersVal)
        // console.log("bodyVal")
        // console.log(bodyVal)

        fetch(restApiPath + 'addPivotTable', {
            method: 'POST',
            headers: headersVal,
            body: JSON.stringify(bodyVal)
        })
            .then(result => result.json())
            .then(response => createNewStateResponseActions(response, from))
    };
    const createNewStateResponseActions = (res, from) => {
        console.log("createNewStateResponseActions -> from -> " + from)
        console.log("res")
        console.log(res)
        if (res.response === true && res.message === "SUCCESS") {
            if (from === "default") {
            } else {
                toastMessage("'" + pivotName + "' created successfully !!!", "success");

                setOpenDlg(false)
                setSelectedPivot(pivotName)
            }
            let dt = JSON.parse(res.data)
            console.log("dt")
            console.log(dt)
            if (pvtNamesList.includes(dt["stateName"]) === false) {
                let newPvtLst = pvtNamesList
                newPvtLst.push(dt["stateName"])
                // console.log("newPvtLst")
                // console.log(newPvtLst)
                setPvtNamesList(newPvtLst)
            }

            // console.log("pvtsData")
            // console.log(pvtsData)
            let newPvtsData = pvtsData
            newPvtsData.push(JSON.parse(res.data))
            // console.log("newPvtsData")
            // console.log(newPvtsData)
            dispatch({ type: "pvtsData", data: newPvtsData })

            let userInsertedVl = dt["userInserted"]
            if (userInsertedVl === null || userInsertedVl === undefined || userInsertedVl.trim() === "") {
                setIsPublishEnabled(false)
            } else {
                if (userInsertedVl === sessionStorage.getItem('email') && dt["userType"] !== "vendor") {
                    setIsPublishEnabled(true)
                } else {
                    setIsPublishEnabled(false)
                }
            }
            setSelectedPivot(dt["stateName"])
            setSelectedStateRowId(dt["rowId"])
            setSelectedTable(dt["srcTableName"])
            setDeletePvtEnabled(true)
            setSavePvtEnabled(true)
            sessionStorage.setItem("savedSelectedPivotName", dt["stateName"])
        } else if (res.response === false && res.message === "Duplicate Name") {
            toastMessage("Duplicate Name found. Please try another name.", "warning");

        } else {
            toastMessage("Error in creating '" + pivotName + "'", "warning");

        }
    };
    const setDataBack = () => {
        let mp = getColDefAndData(sessionStorage.getItem("savedSelectedTabName"))
        let temp = mp.get("colDef")
        temp = temp.map((col) => {
            let mpCol = col
            if (ignoreColsInPvt.includes(col.field)) {
                mpCol = { headerName: col.headerName, field: col.field, hide: true, enablePivot: false, rowGroup: false, enableRowGroup: false, enableValue: false }
                return mpCol
            } else {
                mpCol = { headerName: col.headerName, field: col.field, enablePivot: true, enableValue: true, type: col.type }
                setEnablePivot(sessionStorage.getItem("savedSelectedTabName"), mpCol)
                return mpCol
            }
        })
        setSelectedTabName(sessionStorage.getItem("savedSelectedTabName"))

        let lst = new Array()
        for (let i = 0; i < pvtsData.length; i++) {
            if (pvtsData[i]["srcTableName"] === mpForTabs.get(sessionStorage.getItem("savedSelectedTabName"))) {
                if (lst.includes(pvtsData[i]["stateName"]) === false) {
                    lst.push(pvtsData[i]["stateName"])
                }
            }
        }
        lst.sort()
        setSelectedTable(mpForTabs.get(sessionStorage.getItem("savedSelectedTabName")))
        setPvtNamesList(lst)
        setColDef(temp)
        setSrcData(mp.get("data"))
        // setSaveAsPvtEnabled(true)
    };
    const setColsBack = () => {
        setSelectedPivot(sessionStorage.getItem("savedSelectedPivotName"))

        let mp = getColDefAndData(sessionStorage.getItem("savedSelectedTabName"))
        setSrcData(mp.get("data"))

        let tmpConfig = new Object()
        for (let i = 0; i < pvtsData.length; i++) {
            if (pvtsData[i]["stateName"] === sessionStorage.getItem("savedSelectedPivotName")) {
                tmpConfig = pvtsData[i]
            }
        }

        if (tmpConfig !== null && tmpConfig !== undefined && Object.keys(tmpConfig).length > 0) {
            // console.log("setColsBack -> columnState")
            // console.log(JSON.parse(tmpConfig["columnState"]))
            // console.log("filterModel")
            // console.log(JSON.parse(tmpConfig["filterModel"]))
            // console.log("groupState")
            // console.log(JSON.parse(tmpConfig["groupState"]))

            gridApi.current.setFilterModel(JSON.parse(tmpConfig["filterModel"]))
            gridApi.current.applyColumnState({ state: JSON.parse(tmpConfig["columnState"]), applyOrder: true })
            gridApi.current.setColumnGroupState(JSON.parse(tmpConfig["groupState"]))
            // setSelectedStateRowId(tmpConfig["rowId"])

            // gridApi.current.refreshCells()
            setSavePvtEnabled(true)
        }
    };
    const setDeleteBack = () => {
        let vl = sessionStorage.getItem("savedSelectedPivotName")
        if (vl === null || vl === undefined || vl.trim() === "") {
            setDeletePvtEnabled(false)
            return
        }

        let tmpConfig = new Object()
        for (let i = 0; i < pvtsData.length; i++) {
            if (pvtsData[i]["stateName"] === sessionStorage.getItem("savedSelectedPivotName")) {
                tmpConfig = pvtsData[i]
            }
        }

        if (tmpConfig !== null && tmpConfig !== undefined && Object.keys(tmpConfig).length > 0) {
            if (tmpConfig["userInserted"] === sessionStorage.getItem("email") && deletePvtEnabled === false) {
                setDeletePvtEnabled(true)
            }
        }
    };
    const setPublishBack = () => {
        let vl = sessionStorage.getItem("savedSelectedPivotName")
        if (vl === null || vl === undefined || vl.trim() === "") {
            setIsPublishEnabled(false)
            return
        }

        if (sessionStorage.getItem("user_type") !== "vendor" && isPublishEnabled === false) {
            setIsPublishEnabled(true)
        }
    };
    useEffect(() => {
        // console.log("useEffect -> mpForTabs")
        // console.log(mpForTabs)
        let lst = new Array()
        for (let [k, v] of mpForTabs) {
            // console.log("k -> " + k)
            // console.log("v -> " + v)
            if (lst.includes(k) === false) {
                lst.push(k)
            }
        }
        lst.sort()
        // console.log("useEffect -> lst")
        // console.log(lst)
        setPvtForTabs(lst)
    }, []);
    useEffect(() => {
        // console.log("useEffect -> isGridReady -> " + isGridReady)
        if (!isGridReady) {
            return
        }
        setDataBack()
    }, [isGridReady]);
    const getPivotConfig = () => {
        // console.log("In getPivotConfig")
        let headersVal = {
            'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        let bodyVal = {
            "userInserted": sessionStorage.getItem("email"),
            "permissionGroup": (sessionStorage.getItem("user_type") === "vendor" ? "vendor" : "faherty"),
            "publishedWithUserName": sessionStorage.getItem("email")
        }
        fetch(restApiPath + 'getPivotTable', {
            method: 'POST',
            headers: headersVal,
            body: JSON.stringify(bodyVal)
        })
            .then(result => result.json())
            .then(res => setPivotsConfigInUI(res))
    };
    const setPivotsConfigInUI = (res) => {
        // console.log("setPivotsConfigInUI -> res")
        // console.log(res)
        if (res.response === true && res.message === "SUCCESS") {
            let dt = res.data
            if (dt === null || dt === undefined || dt.length === 0) {
                setPvtNamesList([])
                dispatch({ type: "pvtsData", data: [] })
            } else {
                let tmpLst = new Array()
                for (let i = 0; i < dt.length; i++) {
                    if (tmpLst.includes(dt[i]["stateName"]) === false) {
                        tmpLst.push(dt[i]["stateName"])
                    }
                }
                // setPvtsData(dt)
                dispatch({ type: "pvtsData", data: dt })

                let lst = new Array()
                for (let i = 0; i < dt.length; i++) {
                    if (dt[i]["srcTableName"] === mpForTabs.get(sessionStorage.getItem("savedSelectedTabName"))) {
                        if (lst.includes(dt[i]["stateName"]) === false) {
                            lst.push(dt[i]["stateName"])
                        }
                    }
                }
                lst.sort()
                // console.log("setPivotsConfigInUI -> lst")
                // console.log(lst)
                setPvtNamesList(lst)
            }
        } else {
            setPvtNamesList([])
            dispatch({ type: "pvtsData", data: [] })
        }
    };
    return (
        <>
            <div style={{ backgroundColor: "white", padding: "5px", display: "flex", width: "100%" }}>
                <Autocomplete
                    id="id-ac-pvt-tbs-nms"
                    sx={{ width: 300 }}
                    style={{ marginRight: "15px" }}
                    disablePortal
                    options={pvtForTabs}
                    onChange={(event, newSelect) => handleOnChangeName(event, newSelect)}
                    value={selectedTabName || ""}
                    renderInput={(params) => (<TextField {...params} size="small" label={`Select Tab`} />)}
                />
                <Autocomplete
                    id="id-ac-pvt-available"
                    style={{ marginRight: "25px" }}
                    sx={{ width: 230 }}
                    disablePortal
                    options={pvtNamesList}
                    onChange={(event, newSelect) => handleOnPivotSelect(event, newSelect)}
                    value={selectedPivot || ""}
                    renderInput={(params) => (<TextField {...params} size="small" label={`Select Pivot`} />)}
                />
                <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none', marginRight: "15px" }} onClick={onBtnSaveAsClick}>Save As</MDButton>
                {
                    savePvtEnabled
                        ?
                        <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none', marginRight: "15px" }} onClick={onBtnSaveClick}>Save</MDButton>
                        :
                        null
                }
                {
                    deletePvtEnabled
                        ?
                        <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none', marginRight: "15px" }} onClick={onBtnConfirmDeleteClick}>Delete</MDButton>
                        :
                        null
                }
                {
                    (sessionStorage.getItem("user_type") !== "vendor" && isPublishEnabled === true)
                        ?
                        <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none' }} onClick={onBtnPublishClick}>Publish</MDButton>
                        :
                        null
                }
            </div>
            <div className="ag-theme-quartz" id="agGridStl">
                <AgGridReact
                    enableCharts={true}
                    enableRangeSelection={true}
                    rowSelection="multiple"
                    // suppressContextMenu={true}
                    pivotMode={true}
                    suppressFieldDotNotation
                    enableColResize={true}
                    enableSorting={true}
                    columnDefs={colDef}
                    rowData={srcData}
                    suppressRowClickSelection={true}
                    suppressHorizontalScroll={false}
                    suppressMenuHide={true}
                    rowHeight={20}
                    groupHeaderHeight={25}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        unSortIcon: true,
                        menuTabs: ['filterMenuTab', 'generalMenuTab'],
                        wrapHeaderText: true,
                        resizable: true
                    }}
                    sideBar={true}
                    rowGroupPanelShow={true}
                    pivotPanelShow={true}
                    onGridReady={(event) => onGridReady(event)}
                    onFirstDataRendered={(event) => onFirstDataRendered(event)}
                />
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openPublishDlg}
                onClose={() => setPublishDlg(false)}
                aria-labelledby="classic-modal-slide-title-publish"
                aria-describedby="classic-modal-slide-description-publish"
            >
                <DialogTitle id="classic-modal-slide-title-pivot" disableTypography style={{ fontWeight: '600', fontSize: '15px' }}>{"Publish Pivot : " + selectedPivot}</DialogTitle>
                <DialogContent>
                    <Stack direction="row" useFlexGap flexWrap="wrap" style={{ marginTop: "5px" }}>
                        <Autocomplete
                            multiple
                            id="size-small-standard-multi"
                            size="small"
                            required
                            fullWidth
                            options={userList}
                            onChange={(event, newselect) => handelUserSelect(event, newselect)}
                            renderInput={(params) => (<TextField {...params} label={`Select Users`} />)}
                            defaultValue={checkedUserList}
                            value={checkedUserList}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none', marginRight: "15px" }} onClick={() => publishPivot()}>Publish</MDButton>
                    <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none' }} onClick={() => handlePublishClose()}>Close</MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDlg}
                onClose={() => setOpenDlg(false)}
                aria-labelledby="classic-modal-slide-title-pivot"
                aria-describedby="classic-modal-slide-description-pivot"
            >
                <DialogTitle id="classic-modal-slide-title-pivot" disableTypography style={{ fontWeight: '600', fontSize: '15px' }}>Create Pivot</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        margin="normal"
                        id="outlined-required"
                        label="Pivot Name"
                        placeholder="Enter 'Pivot Name' here..."
                        onChange={(e) => onPivotNameChange(e)}
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none', marginRight: "15px" }} onClick={() => createNewState()}>Create</MDButton>
                    <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none' }} onClick={() => handleClose()}>Close</MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDeletePopup}
                onClose={() => setShowDeletePopup(false)}
                aria-labelledby="classic-modal-slide-title-delete-pivot"
                aria-describedby="classic-modal-slide-description-delete-pivot"
            >
                <DialogTitle id="classic-modal-slide-title-pivot" disableTypography style={{ fontWeight: '600', fontSize: '15px' }}>{"Delete '" + selectedPivot + "' pivot"}</DialogTitle>
                <DialogContent>Are you sure ?</DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <MDButton size="small" variant="gradient" color="error" style={{ textTransform: 'none', marginRight: "15px" }} onClick={() => onDeletePivotConfirm()}>Yes</MDButton>
                    <MDButton size="small" variant="gradient" color="info" style={{ textTransform: 'none' }} onClick={() => onDeletePivotCancel()}>No</MDButton>
                </DialogActions>
            </Dialog>
        </>
    );
})

PivotTab.propTypes = {
    setCurrentGridApi: PropTypes.func.isRequired
};

export default PivotTab;