const listofAccess = [
  {
    buttons: "downloadLinelist",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": true,
  },
  {
    buttons: "redownloadTab",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": true,
  },
  {
    buttons: "sendChanges",
    admin: true,
    "read-only": false,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": true,

  },
  {
    buttons: "getChanges",
    admin: false,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "merch reference": true,
  },
  {
    buttons: "timeLine",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": true,
  },
  {
    buttons: "documentUpload",
    admin: false,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "merch reference": false,
  },
  {
    buttons: "bulkUpload",
    admin: false,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "sendChangeMemo",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "calculateSizes",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": false,
    accessories: true,
    "global planning": true,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "clearFilter",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": true,

  },
  {
    buttons: "activeStyleActivityFilter",
    admin: true,
    "read-only": true,
    vendor: false,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": false,
  },
  {
    buttons: "droppedStyleActivityFilter",
    admin: true,
    "read-only": true,
    vendor: false,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": false,
  },
  {
    buttons: "allStyleActivityFilter",
    admin: true,
    "read-only": true,
    vendor: false,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "merch reference": false,
  },
  {
    buttons: "copyRow",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": true,
    "merch reference": false,
  },
  {
    buttons: "copyAndDropRow",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "defaultCostingView",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": true,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "costingView",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": true,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "showChanges",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": true,
    "merch reference": true,
  },
  {
    buttons: "addRow",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": true,
    "merch reference": false,
  },
  {
    buttons: "delRow",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": true,
    "merch reference": false,
  },
  {
    buttons: "btnDownloadChase",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": false,
    "merch reference": false,
    "merch reference": false,
  },
  {
    buttons: "btnDownloadSizing",
    admin: false,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: true,
    wholesale: true,
    "product development": false,
    accessories: false,
    "global planning": true,
    "supply chain": false,
    "merch reference": false,
  },{
    buttons: "btnDownloadLinelist",
    admin: false,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: true,
    wholesale: true,
    "product development": false,
    accessories: false,
    "global planning": true,
    "supply chain": false,
    "merch reference": false,
  },{
    buttons: "btnDownloadDtc",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: false,
    wholesale: true,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },{
    buttons: "btnDownloadWholesale",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "btnDownloadManageUser",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "highlightStyle",
    admin: false,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
  {
    buttons: "generateItemUpload",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: true,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,

  },
  {
    buttons: "importData",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": false,
  },
  {
    buttons: "btnAssort",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
  },
  {
    buttons: "btnCarryOver",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: true,
    planning: false,
    wholesale: false,
    "product development": false,
    accessories: false,
    "global planning": false,
    "supply chain": false,
  },
  {
    buttons: "btnSavePreference",
    admin: true,
    "read-only": true,
    vendor: true,
    merch: true,
    planning: true,
    wholesale: true,
    "product development": true,
    accessories: true,
    "global planning": true,
    "supply chain": true,
  },
  {
    buttons: "finalCostingView",
    admin: true,
    "read-only": false,
    vendor: false,
    merch: false,
    planning: false,
    wholesale: false,
    "product development": true,
    accessories: false,
    "global planning": false,
    "supply chain": false,
    "merch reference": false,
  },
];
function buttonAccess(btnId, userRole) {
  const item = listofAccess.find(item => item.buttons === btnId);
  return item ? item[userRole] : false;
}
export default buttonAccess;
