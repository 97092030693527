/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components

// Material Dashboard 2 PRO React components

// Material Dashboard 2 PRO React examples

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";

// Material Dashboard 2 PRO React context
import styled from "@emotion/styled";
import { KeyboardArrowUp } from "@mui/icons-material";
import { AccordionDetails, Fade, Grid, Typography } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import Ag_Charts from "layouts/linelist/components/Ag_Charts";
import DashboardCard from "layouts/linelist/components/DashboardCard";
import { isSideBarEnabled, restApiPath } from "utils/app.props";

function Sidenav({ color, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, currentTab, asRequiredDownloadQuery, sideNavBtnClick } = controller;
  const location = useLocation();

  const [expandedLinelist, setExpandedLinelist] = useState(true);
  const [expandedWorkingLine, setExpandedWorkingLine] = useState(true);
  const [expandedChase, setExpandedChase] = useState(true);
  const [expandedSizing, setExpandedSizing] = useState(true);

  const [dashboardData, setDashboardData] = useState({});

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<KeyboardArrowUp sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .6)'
        : 'rgba(0, 0, 0, .05)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const fetchData = async () => {
    const url1 = `${restApiPath}callPGFunction`;
    const jsonReq = {
      "param_body": {
        "param_user_id": sessionStorage.getItem("email"),
        "param_query": asRequiredDownloadQuery ? asRequiredDownloadQuery : ""
      },
      param_function: "ca_get_dashboard_points"
    };
    const requestbody = {
      method: "POST",
      headers: {
        'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
        'Accept': "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonReq),
    };
    try {
      const response = await fetch(url1, requestbody);
      const json = await response.json();
      if (json.response) {
        const mydata = JSON.parse(json.data);
        setDashboardData(mydata);
      }
    } catch (error) {
      setDashboardData({});
    }
  };
  // donot open until user open by clicking button
  useEffect(() => {
    if (!isSideBarEnabled || sessionStorage.getItem('user_type') === "vendor") setMiniSidenav(dispatch, true);
    else if (miniSidenav && sideNavBtnClick) return
    else if (currentTab === "Seasons") {
      if (asRequiredDownloadQuery && asRequiredDownloadQuery !== "" && !miniSidenav) {
        fetchData();
        setMiniSidenav(dispatch, false);
      } else { setMiniSidenav(dispatch, true); }
    }
  }, [asRequiredDownloadQuery, currentTab, miniSidenav])

  useEffect(() => {
    // console.log("currentTab", currentTab)
    const chartTabs = ["Linelist", "Seasons", "DTC_Buy_Sheet", "Whsl_Buy_Sheet", "Sizing", "Chase"]
    if (!isSideBarEnabled || sessionStorage.getItem('user_type') === "vendor") setMiniSidenav(dispatch, true);
    else if (miniSidenav && sideNavBtnClick) return
    else if (!chartTabs.includes(currentTab)) setMiniSidenav(dispatch, true);
    // else if (asRequiredDownloadQuery && asRequiredDownloadQuery !== "" && chartTabs.includes(currentTab)) setMiniSidenav(dispatch, false);
  }, [currentTab])


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {
        currentTab === "Seasons" ? (
          <>

            <Accordion
              expanded={expandedLinelist}
              onChange={() => setExpandedLinelist(!expandedLinelist)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                '& .MuiAccordion-region': { height: expandedLinelist ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expandedLinelist ? 'block' : 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowUp />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: "14px" }}>Linelist</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.production_approval_pending} title="Lock Controls" subTitle="Allow Changes Pending" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.change_memo_total} title="Change Memo" subTitle="Change Memo Status Pending" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.fob_status_working} title="FOB Calculation" subTitle="Current FOB Status In Working" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.fob_status_confirmed} title="FOB Calculation" subTitle="Current FOB Status In Confirmed" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.style_activity_active} title="Style Status" subTitle="Style Activity Active" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.style_activity_dropped} title="Style Status" subTitle="Style Activity Dropped" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedWorkingLine}
              onChange={() => setExpandedWorkingLine(!expandedWorkingLine)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                '& .MuiAccordion-region': { height: expandedWorkingLine ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expandedWorkingLine ? 'block' : 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowUp />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: "14px" }}>Working Line</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.dtc_working_count} title="DTC" subTitle="Working Line = Working" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.whsl_working_count} title="Wholesale" subTitle="Working Line = Working" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedChase}
              onChange={() => setExpandedChase(!expandedChase)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                '& .MuiAccordion-region': { height: expandedChase ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expandedChase ? 'block' : 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowUp />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: "14px" }}>Chase</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.chase_po_generated} title="PO" subTitle="PO Generated" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.chase_po_planning} title="PO" subTitle="PO Planning" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.chase_po_ready} title="PO" subTitle="PO Ready" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedSizing}
              onChange={() => setExpandedSizing(!expandedSizing)}
              slots={{ transition: Fade }}
              slotProps={{ transition: { timeout: 400 } }}
              sx={{
                '& .MuiAccordion-region': { height: expandedSizing ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: expandedSizing ? 'block' : 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<KeyboardArrowUp />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: "14px" }}>Sizing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.sizing_po_generated} title="PO" subTitle="PO Generated" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.sizing_po_planning} title="PO" subTitle="PO Planning" />
                  </Grid>
                  <Grid item xs={6}>
                    <DashboardCard count={dashboardData.sizing_po_ready} title="PO" subTitle="PO Ready" />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        ) : null
      }
      {
        currentTab === "Linelist" ? (<>
          <Ag_Charts url="ca_get_ll_total_sales" title="Total Sales" formatTooltip={true} />
          <Ag_Charts url="ca_get_ll_total_confirm" title="Confirmation Variance" />
          <Ag_Charts url="ca_get_ll_days_late" title="Days Status" />
          <Ag_Charts url="ca_get_ll_item_upload_status" title="Item Upload Status" />
          <Ag_Charts url="ca_get_ll_delivery_status" title="Delivery Status" />
        </>) : null
      }
      {
        currentTab === "DTC_Buy_Sheet" ? (<>
          <Ag_Charts url="ca_get_dtc_buy_status" title="Buy Status" />
        </>) : null
      }
      {
        currentTab === "Whsl_Buy_Sheet" ? (<>
          <Ag_Charts url="ca_get_whsl_buy_status" title="Buy Status" />
        </>) : null
      }
      {
        currentTab === "Sizing" ? (<>
          <Ag_Charts url="ca_get_sizing_po_status" title="Category Wise PO Status" />
          <Ag_Charts url="ca_get_sizing_confirm" title="Sizing Confirm" />
        </>) : null
      }
      {
        currentTab === "Chase" ? (<>
          <Ag_Charts url="ca_get_chase_po_status" title="Category Wise PO Status" />
        </>) : null
      }
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
};

export default Sidenav;
