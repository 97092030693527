const CH1 = String.fromCharCode(1);
const CH3 = String.fromCharCode(3);
export const setDropDown = (tblNm, mpATCDS, tblData, DropDownMapping) => {
	DropDownMapping.forEach(i => {
		let trgKey = Object.keys(i)[0];
		if (Object.values(i)[0].includes(CH3)) {
			let srcTbl = Object.values(i)[0].split(CH3)[0];
			let srcCol = Object.values(i)[0].split(CH3)[1];
			if (srcTbl === tblNm) {
				let newDDVal = []
				tblData.map((i) => {
					let x = i[srcCol] ? i[srcCol] : "";
					if (x !== "") if (!newDDVal.includes(x)) newDDVal.push(x);
				})
				mpATCDS.set(trgKey, newDDVal);
			}
		} else {
			let srcVal = Object.values(i)[0].split(CH1);
			mpATCDS.set(trgKey, srcVal);
		}
	})
};
export const setDropDownBlank = (mpATCDS, DropDownMapping) => {
	DropDownMapping.forEach(i => {
		let trgKey = Object.keys(i)[0];
		mpATCDS.set(trgKey, [""]);
	})
};