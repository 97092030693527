import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import typography from "assets/theme/base/typography";
import { customerBrandName } from "utils/app.props";

const footerFontSize = "12px"
function Footer({ company }) {
  const { href, name } = company;
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={footerFontSize}
        px={1.5}
      >
        {"We ❤️ " + customerBrandName + " !!!"}
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        <span style={{ fontSize: footerFontSize }}>&copy; {new Date().getFullYear()}&nbsp;{" "}</span>
        <Link href="https://www.sohum.tech/" target="_blank" style={{ fontSize: footerFontSize }}>
          <MDTypography variant="button" fontWeight="small" color="primary" style={{ fontSize: footerFontSize }}>
            Sohum Tech
          </MDTypography>
        </Link>{" "}
        <span style={{ fontSize: footerFontSize }}>, Your Digital Transformation Partner</span>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.sohum.tech/", name: "Sohum Tech" },
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
};

export default Footer;
